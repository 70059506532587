/* eslint-disable */ 
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState,useRef, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { LeadBoardModal } from "../../../../_partials/widgets";
import { ScoreModal } from "../../../../_partials/widgets";
import { useDispatch, useSelector } from "react-redux";
import { REQUEST_LEADERBOARD_DATA, REQUEST_MEETING_MASTERY_INFO } from "../../../../../redux/duck/reports/leaderboard/leaderboard.action";
import { authUserId } from "../../../../_helpers/functions";
import _ from "lodash";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { AlertPopup, ConfirmAlert } from "../../../../_helpers/Alerts";
import Axios from "axios";
import { Apistore } from "../../../../../redux/apiStore";
import { GET_LOCALSTORAGE_USER_DETAIL } from "../../../../../redux/duck/userProfile/userProfile.action";
import { logActivity, sendApiError } from "../../../../../app/modules/Auth/_redux/authCrud";


export function AsideMenuList({ layoutProps }) {
  const [leadBoardModal, setLeadBoardModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false,isModalOpened) => {
    return (checkIsActive(location, url) && !isModalOpened)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };
  let didMount = useRef();
  let unratedRef = useRef();
  let organizedRef = useRef();
  let attendedRef = useRef();
  let companyRef = useRef();
  let coachingRef = useRef();
  let meetingMasteryRef = useRef();
  let TimeMachineRef = useRef();
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [scoreModal, setScoreModal] = useState(false);
  
  const localUserData = useSelector((state) => state.profile.getLocalStorageUserData);
  const { getUnratedMeetingsData, count } = useSelector(state => state.unrated)
  const { timePeriod } = useSelector(state => state.picker);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    show: null,
    title: null,
    message: null,
    errType: null
  })


const neutralizeLinksStyle = {
  "color": "#0A2129"
}

  const clickHandler = (e) => {
    if(localUserData && localUserData.user_data && localUserData.user_data.user_write_scope == 0){
       e.preventDefault();
    }
  }

  const [confirmAlertOptions,setConfirmAlertOptions]=useState({
    show:null,
    title:'Pleas Confirm',
    description:"Are you sure you want to uninstall Zoom ? Don't worry, you can come back and install it again anytime.",
  })


  const closeConfirmAlert = () => {
    setLoading(false);
    setConfirmAlertOptions({
      ...confirmAlertOptions,
      show:false
    })
  }




  const popover = (
    <Popover id="popover-basic" className="mr-4">
      <Popover.Content className="p-2">
        <div className="badge--card">
          <div className="badge--card-body">
          <span className="">Meeting Shortener is not active as necessary permissions are not granted to MeetingScience app on your Google Account. Please visit <Link to='/user-profile/privacy'>Privacy</Link> Settings page to grant the permissions.</span>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );

const submitRequestForZoomUninstall = () => {
  closeConfirmAlert();
  Axios.post(Apistore.RemoveZoomApp, {
    user_id: authUserId()
  })
    .then((res) => {
      setAlert({
        show: true,
        message: res.data.msg,
        title: 'Zoom App',
        errType: "success"
      })
      setLoading(false)
      dispatch({ type: GET_LOCALSTORAGE_USER_DETAIL, data: {  } })
    }).catch((err) => {
        sendApiError(500,err,Apistore.RemoveZoomApp)
        setAlert({
          show: true,
          message: err.data.msg,
          title: 'Zoom App',
          errType: "failure"
      })
      setLoading(false)
    })
}

  const openConfirmAlert = () => {
    setConfirmAlertOptions({
      ...confirmAlertOptions,
      show:true
    })
  }

  const handleClose = () => {
    setAlert({
      ...alert,
      show: false
    })
  };

  const handleScoreModal = () => {
    logActivity('modal','/score');
    if (active) {
      setActive(true)
    } else {
      setActive(false)
    }
    setScoreModal(true)
  }
  const handleLeadboardModal = () => {
    logActivity('modal','/leaderboard');
    setLeadBoardModal(true)
    dispatch({ type: REQUEST_LEADERBOARD_DATA, payload: {
    
  //  user_id:authUserId(),
      sort:'percent_rating_submitted',
      order:'DESC'      
    }})
    dispatch({
      type: REQUEST_MEETING_MASTERY_INFO, payload: {
        // user_id: authUserId(),
        start_date: timePeriod.sdate,
        end_date: timePeriod.edate,
      }
    })
  }

  const handleZoom = (e) => {
    e.preventDefault();
    if (localUserData && localUserData.user_data.install_zoom == 0) {
      window.open(process.env.REACT_APP_ZOOM_URL);
    } else {
      setLoading(true);
      openConfirmAlert();
    }
  }

// useEffect(() => {
//   console.log('render')
//   setConfirmAlertOptions({
//     ...confirmAlertOptions,
//     show:true
//   })
// }, [])  

// useEffect(() => {
//   if(!didMount.current && location.pathname == '/score'){
//     handleScoreModal();
//   }
//   if(!didMount.current && location.pathname == '/leaderboard'){
//     handleLeadboardModal();
//   }
//   didMount.current = true;

// },[didMount.current])

useEffect(() => {
  let organized = organizedRef.current
  let unrated = unratedRef.current
  let attended = attendedRef.current
  let company = companyRef.current
  let coaching = coachingRef.current
  let meetingMastery = meetingMasteryRef.current
  let TimeMachine = TimeMachineRef.current
  if (organized !== '' && organized !== undefined && organized !== null) {
    try {
      organized.className = 'menu-link'
    } catch (error) {
      console.log(error);
    }
  }
  if (unrated !== '' && unrated !== undefined && unrated !== null) {
    try {
      unrated.className = 'menu-link'
    } catch (error) {
      console.log(error);
    }
  }
  if (attended !== '' && attended !== undefined && attended !== null) {
    try {
      attended.className = 'menu-link'
    } catch (error) {
      console.log(error);
    }
  }
  if (company !== '' && company !== undefined && company !== null) {
    try {
      company.className = 'menu-link'
    } catch (error) {
      console.log(error);
    }
  }
  if (coaching !== '' && coaching !== undefined && coaching !== null) {
    try {
      coaching.className = 'menu-link'
    } catch (error) {
      console.log(error);
    }
  }
  if (meetingMastery !== '' && meetingMastery !== undefined && meetingMastery !== null) {
    try {
      meetingMastery.className = 'menu-link'
    } catch (error) {
      console.log(error);
    }
  }
  if (TimeMachine !== '' && TimeMachine !== undefined && TimeMachine !== null) {
    try {
      TimeMachine.className = 'menu-link'
    } catch (error) {
      console.log(error);
    }
  }


},[scoreModal,leadBoardModal])

  return (
    <>
      <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />
      <div className="nav-header">Meetings</div>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/unrated", false,leadBoardModal == true || scoreModal == true ? true : false)}`}
          aria-haspopup="true"
        >
          <NavLink ref={unratedRef} className="menu-link"  to="/unrated" title='Unrated'>
            <span className="svg-icon menu-icon">
              <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/unrated-ico.svg")} />
              <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/unrated-ico-hover.svg")} />
            </span>
            <span className="menu-text font-weight-bolder">Unrated <span className="no">{getUnratedMeetingsData && getUnratedMeetingsData.data && getUnratedMeetingsData.data.attended && getUnratedMeetingsData.data.organized ? getUnratedMeetingsData.data.organized.length + getUnratedMeetingsData.data.attended.length : (count && count.status === true ? count.data : 0)} </span></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/attended", false,leadBoardModal == true || scoreModal == true ? true : false)}`}
          aria-haspopup="true"
        >
          <NavLink ref={attendedRef} className="menu-link"  to="/attended" title='Attended'>
            <span className="svg-icon menu-icon">
              <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/attended-ico.svg")} />
              <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/attended-ico-hover.svg")} />
            </span>
            <span className="menu-text font-weight-bolder">Attended</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/organized", false,leadBoardModal == true || scoreModal == true ? true : false)}`}
          aria-haspopup="true"
        >
          <NavLink ref={organizedRef} className="menu-link"  to="/organized" title='Organized'>
            <span className="svg-icon menu-icon">
              <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/organized-ico.svg")} />
              <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/organized-ico-hover.svg")} />
            </span>
            <span className="menu-text font-weight-bolder">Organized</span>
            {/* <span className="menu-text font-weight-bolder">Organized<span className="no">New</span></span> */}
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/team", false,leadBoardModal == true || scoreModal == true ? true : false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link"  to="/team">
            <span className="svg-icon menu-icon">
              <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/team-ico.svg")} />
              <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/team-ico-hover.svg")} />
            </span>
            <span className="menu-text font-weight-bolder">Team</span>
          </NavLink>
        </li> */}

       { (localUserData && localUserData.user_data && localUserData.user_data.user_executive) || (localUserData && localUserData.user_data && localUserData.user_data.company_domain == 'zoom.us' || localUserData && localUserData.user_data && localUserData.user_data.company_domain == 'zoomappsec.us' || localUserData && localUserData.user_data && localUserData.user_data.email == 'development@meetingscience.io') ?  <li
          className={`menu-item ${getMenuItemActive("/company", false,leadBoardModal == true || scoreModal == true ? true : false)}`}
          aria-haspopup="true"
        >
          <NavLink ref={companyRef} className="menu-link"  to="/company" title='Company'>
            <span className="svg-icon menu-icon">
              <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/company-ico.svg")} />
              <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/company-ico-hover.svg")} />
            </span>
            <span className="menu-text font-weight-bolder">Company <span className="no">New</span></span>
          </NavLink>
        </li> : null}

      </ul>

      <div className="nav-header">Reports</div>
      <ul className={`menu-nav menu--reports ${layoutProps.ulClasses}`}>
        <li
          className={ scoreModal == true ? "menu-item  menu-item-active" : "menu-item "}
          aria-haspopup="true"
        >
          <button className="menu-link" title='Score' onClick={handleScoreModal}>
            <span className="svg-icon menu-icon">
              <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/score-ico.svg")} />
              <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/score-ico-hover.svg")} />
            </span>
            <span className="menu-text">Score</span>
          </button>
        </li>
        {!active ? <ScoreModal show={scoreModal} handleClose={() => setScoreModal(false)} /> : ''}
        <li
          className={`menu-item ${getMenuItemActive("/coaching", false,leadBoardModal == true || scoreModal == true ? true : false)}`}
          aria-haspopup="true"
        >
          <NavLink ref={coachingRef} title='Coaching' className="menu-link" to="/coaching">
            <span className="svg-icon menu-icon">
              <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/coaching-ico.svg")} />
              <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/coaching-ico-hover.svg")} />
            </span>
            <span className="menu-text">Coaching</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/meeting-mastery", false,leadBoardModal == true || scoreModal == true ? true : false)}`}
          aria-haspopup="true"
        >
          <NavLink ref={meetingMasteryRef} title="Meeting Mastery" className="menu-link" to="/meeting-mastery">
            <span className="svg-icon menu-icon">
              <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/MeetingMastery-ico.svg")} />
              <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/MeetingMastery-ico-hover.svg")} />
            </span>
            <span className="menu-text">Meeting Mastery</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/time-machine", false,leadBoardModal == true || scoreModal == true ? true : false)}`}
          aria-haspopup="true"
        >
          <NavLink ref={TimeMachineRef} title='Meeting Shortener' className={localUserData && localUserData.user_data && localUserData.user_data.user_write_scope ? "menu-link" : "menu-link disabled"} onClick={clickHandler}  to="/time-machine" >
            <span className="svg-icon menu-icon">
              <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/TimeMachine-ico.svg")} />
              <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/TimeMachine-ico-hover.svg")} />
            </span>
            <span className="menu-text">Meeting Shortener  {localUserData && localUserData.user_data && localUserData.user_data.user_write_scope == 0 &&    <OverlayTrigger trigger="click"   placement="top" overlay={popover}>
            <span className="no">?</span>
                                                </OverlayTrigger>}  </span>
          </NavLink>
        </li>

        <li
          className={ leadBoardModal == true ? "menu-item  menu-item-active" : "menu-item "}
          aria-haspopup="true"
        >
          <button className="menu-link" title='Leaderboard' onClick={handleLeadboardModal}>
            <span className="svg-icon menu-icon">
              <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/leadboard-ico.svg")} />
              <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/leadboard-ico-hover.svg")} />
            </span>
            <span className="menu-text">Leaderboard</span>
          </button>
        </li>
        {<LeadBoardModal show={leadBoardModal} handleClose={() => setLeadBoardModal(false)} />}
        {/* <li
          className={`menu-item ${getMenuItemActive("/trends", false,leadBoardModal == true || scoreModal == true ? true : false)}`}
            aria-haspopup="true"
          >
          <NavLink className="menu-link"  to="/trends">
              <span className="svg-icon menu-icon">
                <SVG className="default-icn" src={toAbsoluteUrl("/media/ms-media/trends-ico.svg")} />
                <SVG className="hover-icn" src={toAbsoluteUrl("/media/ms-media/trends-ico-hover.svg")} />
              </span>
              <span className="menu-text">Trends</span>
            </NavLink>
          </li> */}

      </ul>

      { (localUserData && localUserData.user_data && localUserData.user_data.company_domain == 'zoom.us' || localUserData && localUserData.user_data && localUserData.user_data.email == 'development@meetingscience.io') && process.env.REACT_APP_PUBLIC_URL !== 'https://app2.meetingscience.io' ?
        <>
          <div className="nav-header">Zoom</div>
          <ul className={`menu-nav menu--reports ${layoutProps.ulClasses}`}>
            <li
              className={`menu-item ${getMenuItemActive("/zoom", false,leadBoardModal == true || scoreModal == true ? true : false)}`}
              aria-haspopup="true"
            >
              <button className="menu-link" onClick={(e) => handleZoom(e)} to='/zoom'>
                <span className="menu-text">{localUserData && localUserData.user_data && localUserData.user_data.install_zoom == '1' ? 'Remove MS from Zoom' : 'Add MS to Zoom'}</span>
                {loading && <Spinner animation="border" role="status">
                  <span className="visually-hidden"></span>
                </Spinner>}
              </button>
            </li>
          </ul>
        </>
        : null
      }

      {/* end::Menu Nav */}
        <ConfirmAlert data={confirmAlertOptions} close={closeConfirmAlert} save={submitRequestForZoomUninstall}/>      
    </>
  );
}
