/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import _ from "lodash";

export function MeetingStrainPerDayChart() {
  const getMcrGraphData = useSelector(state => state.coaching.getCoachingMcrGraphData);
  // Set Initial State
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        toolbar: {
          show: true,
          tools:{
            download:true // <== line to add
          }
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: 'top',
          },
        }
      },
      colors: [function ({ value }) {
        if (value > 0 && value <= 2) {
          return '#00ACBF'
        } else if (value > 2 && value <= 5) {
          return '#F6F062'
        } else if (value > 5 && value <= 7) {
          return '#FCAC34'
        } else {
          return "#E34F4F"
        }
      }],
      dataLabels: {
        enabled: true,
        style: {
          fontWeight: '400',
          colors: ['#333']
        },
        offsetY: -20
      },
      title: {
        text: '# of meetings',
        align: 'left',
        style: {
          fontSize: '12px',
          color: '#BFC2C7'
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box p-2">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] + " meetings with " +
            w.config.series[seriesIndex].name[dataPointIndex] + " people" +
            "</span>" +
            "</div>"
          );
        },
        y: {
          show: true,
        },
        x: {
          show: false
        }
      },
      grid: {
        show: true,
        borderColor: '#F4F5F8',
        strokeDashArray: 6,
        position: 'back',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
        row: {
          colors: undefined,
          opacity: 0.5
        },
        column: {
          colors: undefined,
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [],
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
          borderType: 'dotted',
          color: '#F4F5F8',
          height: 25,
        },
        labels: {
          style: {
            colors: "#A2A7AD",
            whiteSpace: "nowrap"
          },
        },
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          align: 'center',
          style: {
            colors: "#A2A7AD",
          },
          formatter: function (val) {
            return (val).toFixed(0);
          }
        },
        axisTicks: {
          show: true,
          borderType: 'dotted',
          color: '#F4F5F8',
        },
      }
    },
  });

  useEffect(() => {
    if (getMcrGraphData && _.has(getMcrGraphData, "graphData") && _.has(getMcrGraphData, "graph_levels") && _.has(getMcrGraphData, "graphMeetingsWithPeople") && getMcrGraphData.code === 200) {
      const graphMeetingsWithPeople = getMcrGraphData.graphMeetingsWithPeople.length > 0 ? getMcrGraphData.graphMeetingsWithPeople : [];
      const graphData = getMcrGraphData.graphData.length > 0 ? getMcrGraphData.graphData : [];
      const graphXaxisData = getMcrGraphData.graph_levels.length > 0 ? getMcrGraphData.graph_levels.map(data => {
          let newArr = [data.date, data.day];
          return newArr;
      }) : []

      const yaxisMaxValue =  graphData.length ? Math.max(...graphData) : 5;

      setState({ ...state, 
        series: [{ name: graphMeetingsWithPeople, data: graphData }],
        options: { ...state.options, 
          xaxis: { ...state.options.xaxis, 
            categories: graphXaxisData },
          yaxis:{
            ...state.options.yaxis,
            max:parseInt(yaxisMaxValue) + 5,
            min:0
          }  
          
          }
        
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMcrGraphData]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={300}
      />
    </>
  );
}
