/* eslint-disable */ 
import React,{useRef,useEffect,useState} from 'react';
import { MeetingWithMostPeopleChart, MeetingWithMostPeopleList } from '.';
import { FilterRange } from '../../layout/components/header/FilterLabel';
import './widgets.css'

export const MeetingsWithMostPeople = () => {

    const chartSection = useRef(null);
    const [toggleCharts, setToggleCharts] = useState(false);

    return (

        <div className="card card-custom " ref={chartSection}>
                            <div className="card-header align-items-center border-0 justify-content-between">
                                <h3 className="card-title align-items-center">
                                    <span className="font-weight-bolder text-dark">Which meetings have the most people? </span>
                                </h3>
                                <div className="card-subtitle d-flex justify-content-between">
                                   <h5>Average number of people at meetings per day for the  {<FilterRange/>}</h5>
                                   <div className="d-inline-flex justify-content-between">
                                    <div className="card-subtitle-btn">
                                        <a href="#list" className={toggleCharts === true ? "btn btn-sm btn-light-primary" : "btn btn-sm btn-light"} onClick={() => { setToggleCharts(true) }}>List</a>
                                        <a href="#chart" className={toggleCharts === false ? "btn btn-sm btn-light-primary" : "btn btn-sm btn-light"} onClick={() => { setToggleCharts(false) }}>Chart</a>
                                    </div>
                                </div>
                                </div>
                                
                            </div>
                          

                            <div className="card-body position-relative overflow-hidden">
                                {toggleCharts === true ?
                                        <MeetingWithMostPeopleList />
                                    :
                                    <div id="chart">
                                        <MeetingWithMostPeopleChart />
                                    </div>
                                }
                            </div>
                        </div>
    )
}