/* eslint-disable */ 
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React,{useEffect, useState} from "react";
import ReactApexChart from 'react-apexcharts';

export function GenderBalanceAttendeesChart({ className,data }) {

    const [state,setState] = useState({

        series: [],
        options: {
            tooltip:{
                enabled: false,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      theme: false,
      style: {
        fontSize: '16px',
        fontFamily: undefined,
        color:"#fff"
      },
      onDatasetHover: {
          highlightDataSeries: false,
      },
            },
            chart: {
                height: 250,
                type: 'radialBar',
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
                floating: false,
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 400,
                onItemHover: {
                    highlightDataSeries: true
                },
                offsetX: -10,
                offsetY: -10,
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    radius: 0,
                    offsetX: 0,
                    offsetY: 0
                },
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '70%',
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            fontSize: '16px',
                            fontFamily: undefined,
                            fontWeight: 600,
                            color: undefined,
                            offsetY: -10
                        },
                        value: {
                            show: true,
                            fontSize: '14px',
                            fontFamily: undefined,
                            fontWeight: 400,
                            color: undefined,
                            offsetY: 16,
                            formatter: function (val) {
                                return val + '%'
                              }
                        },
                        total: {
                            show: true,
                            label: 'Attendees',
                            color: '#A4A8AF',
                            fontWeight: 200,
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => {
                                  return parseInt(a) + parseInt(b)
                                }, 0) + '%'
                              }
                        }
                    }
                }
            },
            colors: ['#00626C', '#4ACBDA','#12ACBF'],
            labels: ['Male', 'Female','Others'],
        },

    });

    const calcPercentage = (count,total) => {
        return ((count/total)*100).toFixed(0);
    }

useEffect(() => {

    let maleCount = 0;
    let femaleCount = 0;
    let othersCount = 0;
    let accepted_ids = data.event_accepted_ids;    
    //let accepted_ids_count = data.event_accepted_ids_count;
    let users_list = data.event_users;
    let accepted_ids_arr = accepted_ids.split(',');


    for (let key in users_list){
                accepted_ids_arr.map(i => {
                    if(i === key){
                            if(users_list[key].user_gender === 1){
                                ++maleCount;
                            }else if(users_list[key].user_gender === 2){
                                ++femaleCount
                            }else if(users_list[key].user_gender === 0){
                                ++othersCount
                            }
                    }
                })
                setState({
                    ...state,
                    series:[calcPercentage(maleCount,data.total_attendees),calcPercentage(femaleCount,data.total_attendees),calcPercentage(othersCount,data.total_attendees)],
                    options:{
                        ...state.options,
                            labels:["Male " + calcPercentage(maleCount,data.total_attendees) + "%","Female " + calcPercentage(femaleCount,data.total_attendees) + "%","Others " + calcPercentage(othersCount,data.total_attendees) + "%"]
                    }
                })

                
    }    

}, [data])

    return (
        <>
            <div className={`${className}`}>
                <div>
                    <ReactApexChart options={state.options} series={state.series} type="radialBar" height={250} />
                </div>
            </div>
        </>
    );
}