/* eslint-disable */ 
import React from 'react';
import { Html5Entities } from 'html-entities';
import {useSelector} from 'react-redux';


export const UserCurrency = () => {
const {getLocalStorageUserData} = useSelector(state => state.profile)
const htmlEntities = new Html5Entities();
let _ = htmlEntities.decode(getLocalStorageUserData.user_data.currency.currency_symbol);

return _ ; 

}

