import { put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { ERROR_GET_COACHING_MCR_DATA, ERROR_GET_COACHING_MCR_GRAPH_DATA, getCoachingMcrDataResponse, getCoachingMcrGraphDataResponse, GET_COACHING_MCR_DATA, GET_COACHING_MCR_GRAPH_DATA, SUCCESS_GET_COACHING_MCR_DATA, SUCCESS_GET_COACHING_MCR_GRAPH_DATA } from './coaching.action';
import { getCoachingMcrDataApi, getCoachingMcrGraphDataApi } from './coaching.api';
import { sendApiError } from '../../../../app/modules/Auth/_redux/authCrud';
import { Apistore } from '../../../apiStore';

// Get Coaching Mcr Data
function* getCoachingMcrDataRequest(data) {
    let getData = yield getCoachingMcrDataApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(getCoachingMcrDataResponse(SUCCESS_GET_COACHING_MCR_DATA, getData.data));
    } else {
        yield put(getCoachingMcrDataResponse(ERROR_GET_COACHING_MCR_DATA, getData.data));
        sendApiError(500,getData.data,Apistore.GetCoachingMcrData)
    }
}

export function* getCoachingMcrDataWatcher() {
    yield takeLatest(GET_COACHING_MCR_DATA, getCoachingMcrDataRequest);
}

function* getCoachingMcrGraphDataRequest(data) {
    let getData = yield getCoachingMcrGraphDataApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(getCoachingMcrGraphDataResponse(SUCCESS_GET_COACHING_MCR_GRAPH_DATA, getData.data));
    } else {
        yield put(getCoachingMcrGraphDataResponse(ERROR_GET_COACHING_MCR_GRAPH_DATA, getData.data));
        sendApiError(500,getData.data,Apistore.GetCoachingMcrGraphData)
    }
}

export function* getCoachingMcrGraphDataWatcher() {
    yield takeLatest(GET_COACHING_MCR_GRAPH_DATA, getCoachingMcrGraphDataRequest);
}