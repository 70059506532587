/* eslint-disable */ 
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { FilterRange } from "../../layout/components/header/FilterLabel";
import { Html5Entities } from 'html-entities';


export function MeetingsPerDay() {

    
  const { companyData } = useSelector(state => state.company)
  const {timePeriod} = useSelector(state => state.picker)
    
  let seriesData = []

  
  const popover =  () => (
    <Popover id="popover-basic" className="mr-4">
      <Popover.Content className="p-2">
        <div className="badge--card">
          <div className="badge--card-body">
          <div className="" style={{'fontSize':'14px'}}>Meeting Size: </div>  
          <div className="color-label-wrap">
                <div className="color-label" style={{'fontSize':'14px'}}><span className="color_box primary" style={{background:'#8FE0E9'}}></span> 0 - 2</div>
                <div className="color-label" style={{'fontSize':'14px'}}><span className="color_box primary-light" style={{background:'#00ACBF'}}></span> 3 - 4</div>
                <div className="color-label" style={{'fontSize':'14px'}}><span className="color_box primary-light" style={{background:'#008E9D'}}></span> 5 - 7</div>
                <div className="color-label" style={{'fontSize':'14px'}}><span className="color_box primary-light" style={{background:'#00626C'}}></span> 8+</div>
         </div>
    </div>
        </div>
      </Popover.Content>
    </Popover>
  );

    const [state, setState] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true,
                    tools:{
                      download:true // <== line to add
                    }
                  },
            },
            states: {

                hover: {
                    filter: {
                        type: 'none',

                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    colors: {
                        backgroundBarColors: ["#f8f9fb"],
                    }
                },
            },
            stroke: {
                width: 1,
            },
            title: {
                text: '# of meetings',
                align: 'left',
                style: {
                    fontSize: '12px',
                    color: '#BFC2C7'
                },
            },
            colors: [function({ value, seriesIndex, w }) {
             
              if (w.config.series[seriesIndex].attendeeLesser == '2') {
             
                return "#8FE0E9";
              }else if (w.config.series[seriesIndex].attendeeLesser == '4') {
                
                return "#00ACBF";
              }else if (w.config.series[seriesIndex].attendeeLesser == '7') {
               
                return "#008E9D";
              }else if (w.config.series[seriesIndex].attendeeLesser == '500') {
              
                return "#00626C";
              }
              else{
       return '#02444C'
     }}],
            dataLabels: {
                enabled: false
            },
            legends: {
                show: false
            },
            states: {

                hover: {
                    filter: {
                        type: 'none',

                    }
                }
            },
            grid: {
                show: true,
                borderColor: '#F8F9FB',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                row: {
                    colors: undefined,
                    opacity: 0.5
                },
                column: {
                    colors: undefined,
                    opacity: 0.5
                },
            },
            xaxis: {
                title: {
          text: 'Total meeting count of the day',
          offsetX: 0,
          offsetY: 0,
          style: {
              color: '#999EA5',
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-title',
          },
        },
                categories: [],
                labels: {
                    style: {
                        colors: "#999EA5",
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                    formatter: function (val) {
                        return val;
                    }
                },
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true,
                    borderType: 'dotted',
                    color: '#F4F5F8',
                    height: 25,
                },
            },
            yaxis: {
                show: true,
                labels: {
                    style: {
                        colors: "#999EA5",
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                }
            },
           
            tooltip: {
                fillSeriesColor: false,
                y: {
                    show: true,
                    formatter: function(val) {
                        return val;
                      }
                    },
                x: {
                    show: false,
                },
                marker: {
                    show: false,
                },
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: "top",
                show: false,
                horizontalAlign: "left",
                offsetX: 40
                }
            }
    });

    const addFunc = (total,num) => {
        return total + num;
    }

    const getTotal = (day) => {
       

    const filteredEvents = companyData && companyData.events && companyData.events.length && companyData.events.filter(i => moment(i.event_start_datetime).format('dddd') == day && i.total_attendees > 0);
        
        // console.log('------arr['+ index+']:' )
        // console.log(arr);
        return companyData && filteredEvents!==undefined && filteredEvents.length ? filteredEvents.length : 0 ;
    }

    const renderLabels = () => {
        let finalData = [];
            finalData.push('Mon | ' + getTotal('Monday'));
            finalData.push('Tue | ' + getTotal('Tuesday'));
            finalData.push('Wed | ' + getTotal('Wednesday'));
            finalData.push('Thu | ' + getTotal('Thursday'));
            finalData.push('Fri | ' + getTotal('Friday'));
            finalData.push('Sat | ' + getTotal('Saturday'));
            finalData.push('Sun | ' + getTotal('Sunday'));

          seriesData.length && setState({
              ...state,
                options:{
                  ...state.options,
                        xaxis:{
                          ...state.options.xaxis,
                            categories:finalData
                        },
                        colors: [function({ value, seriesIndex, w }) {
             
                            if (w.config.series[seriesIndex].attendeeLesser == '2') {
                           
                              return "#8FE0E9";
                            }else if (w.config.series[seriesIndex].attendeeLesser == '4') {
                              
                              return "#00ACBF";
                            }else if (w.config.series[seriesIndex].attendeeLesser == '7') {
                             
                              return "#008E9D";
                            }else if (w.config.series[seriesIndex].attendeeLesser == '500') {
                            
                              return "#00626C";
                            }
                            else{
                     return '#02444C'
                   }}],
                },
              series:seriesData
          })
      }


      const {getLocalStorageUserData} = useSelector(state => state.profile)
      const htmlEntities = new Html5Entities();
      let currency = htmlEntities.decode(getLocalStorageUserData.user_data.currency.currency_symbol)

    const countCost = (day,attendeeGraterthan,attendeeLessthanEq)  => {
        let arr = [];
        let filteredEvents = companyData &&  companyData.events && companyData.events.length && companyData.events.filter(i => moment(i.event_start_datetime).format('dddd') == day && i.total_attendees >= attendeeGraterthan && i.total_attendees <=attendeeLessthanEq )
            

         if(companyData && filteredEvents !== undefined && filteredEvents.length)(filteredEvents.map(i => {
            if(i.total_event_cost !== "" && i.total_event_cost!== null) {
                arr.push(i.total_event_cost)
            }
        })) 

        // arr.length &&  console.log(day,attendeeGraterthan,attendeeLessthanEq);
        //  arr.length &&   console.log(arr)
        //  arr.length &&  console.log(arr.reduce(addFunc));
        //  arr.length && console.log('-------------------------------------------------------------------------------')
        
        return  arr.length ? ' ' + arr.reduce(addFunc) + ' ' + currency  : ' ' +  0 + ' ' + currency ; 
    }

    const countHours = (day,attendeeGraterthan,attendeeLessthanEq)  => {
        // console.log(eventStartDate,'start date');
        // console.log(attendeeGraterthan,'greater than')
        // console.log(attendeeLessthanEq,'less than eq')
        let arr = [];
        let filteredEvents =companyData && companyData.events && companyData.events.length && companyData.events.filter(i => moment(i.event_start_datetime).format('dddd') == day && i.total_attendees >= attendeeGraterthan && i.total_attendees <=attendeeLessthanEq  )
       
        

        if(companyData && filteredEvents !== undefined && filteredEvents.length)(filteredEvents.map(i => {
            if(i.event_duration !== "" && i.event_duration!== null) {
                arr.push(i.event_duration)
            }
        })) 

        //  console.log(filteredEvents);
        //  console.log(arr)
        return  arr.length ? ' ' + (arr.reduce(addFunc)).toFixed(2) + ' hr(s)'  : ' ' +  0 + ' hr(s)';
    }

    const countPersons = (day,attendeeGraterthan,attendeeLessthanEq)  => {
        // console.log(eventStartDate,'start date');
        let arr = [];
        let filteredEvents =companyData && companyData.events && companyData.events.length && companyData.events.filter(i => moment(i.event_start_datetime).format('dddd') == day && i.total_attendees >= attendeeGraterthan && i.total_attendees <=attendeeLessthanEq )

         if(companyData && filteredEvents !== undefined && filteredEvents.length)(filteredEvents.map(i => {
            if(i.total_attendees !== "" && i.total_attendees!== null) {
                arr.push(i.total_attendees)
            }
         }))       
        //  console.log(filteredEvents);
        //  console.log(arr)
         return  arr.length ? arr.reduce(addFunc) : 0;
    }


    const countMeetings = (day,attendeeGraterthan,attendeeLessthanEq)  => {
        // console.log(eventStartDate,'start date');
        let filteredEvents = companyData && companyData.events && companyData.events.length && companyData.events.filter(i => moment(i.event_start_datetime).format('dddd') == day && i.total_attendees >= attendeeGraterthan && i.total_attendees <=attendeeLessthanEq  )
             
        //  console.log(arr)
         return filteredEvents &&  filteredEvents!==undefined && filteredEvents.length ? filteredEvents.length : 0;
    }

    useEffect(() => {
        setState({
            ...state,
            series:[]
        })
        seriesData = [];
    },[timePeriod.filter,timePeriod.sdate,timePeriod.edate,timePeriod.custom])
    

    useEffect(() => {
    
       seriesData =[

        { name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Monday',0,2) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Monday',0,2) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Monday',0,2) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
        data:[countMeetings('Monday',0,2),0,0,0,0,0,0],
        attendeeLesser:2
    },
    { name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Monday',3,4) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Monday',3,4) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Monday',3,4) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
    data:[countMeetings('Monday',3,4),0,0,0,0,0,0],
    attendeeLesser:4
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Monday',5,7) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Monday',5,7) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Monday',5,7) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[countMeetings('Monday',5,7),0,0,0,0,0,0],
attendeeLesser:7
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Monday',8,500) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Monday',8,500) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Monday',8,500) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[countMeetings('Monday',8,500),0,0,0,0,0,0],
attendeeLesser:500,
},




{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Tuesday',0,2) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Tuesday',0,2) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Tuesday',0,2) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,countMeetings('Tuesday',0,2),0,0,0,0,0],
attendeeLesser:2
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Tuesday',3,4) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Tuesday',3,4) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Tuesday',3,4) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,countMeetings('Tuesday',3,4),0,0,0,0,0],
attendeeLesser:4
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Tuesday',5,7) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Tuesday',5,7) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Tuesday',5,7) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,countMeetings('Tuesday',5,7),0,0,0,0,0],
attendeeLesser:7
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Tuesday',8,500) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Tuesday',8,500) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Tuesday',8,500) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,countMeetings('Tuesday',8,500),0,0,0,0,0],
attendeeLesser:500
},


{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Wednesday',0,2) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Wednesday',0,2) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Wednesday',0,2) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,countMeetings('Wednesday',0,2),0,0,0,0],
attendeeLesser:2
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Wednesday',3,4) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Wednesday',3,4) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Wednesday',3,4) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,countMeetings('Wednesday',3,4),0,0,0,0],
attendeeLesser:4
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Wednesday',5,7) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Wednesday',5,7) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Wednesday',5,7) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,countMeetings('Wednesday',5,7),0,0,0,0],
attendeeLesser:7
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Wednesday',8,500) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Wednesday',8,500) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Wednesday',8,500) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,countMeetings('Wednesday',8,500),0,0,0,0],
attendeeLesser:500
},

{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Thursday',0,2) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Thursday',0,2) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Thursday',0,2) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,countMeetings('Thursday',0,2),0,0,0],
attendeeLesser:2
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Thursday',3,4) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Thursday',3,4) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Thursday',3,4) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,countMeetings('Thursday',3,4),0,0,0],
attendeeLesser:4
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Thursday',5,7) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Thursday',5,7) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Thursday',5,7) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,countMeetings('Thursday',5,7),0,0,0],
attendeeLesser:7
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Thursday',8,500) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Thursday',8,500) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Thursday',8,500) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,countMeetings('Thursday',8,500),0,0,0],
attendeeLesser:500
},


{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Friday',0,2) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Friday',0,2) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Friday',0,2) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,countMeetings('Friday',0,2),0,0],
attendeeLesser:2
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Friday',3,4) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Friday',3,4) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Friday',3,4) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,countMeetings('Friday',3,4),0,0],
attendeeLesser:4
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Friday',5,7) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Friday',5,7) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Friday',5,7) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,countMeetings('Friday',5,7),0,0],
attendeeLesser:7
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Friday',8,500) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Friday',8,500) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Friday',8,500) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,countMeetings('Friday',8,500),0,0],
attendeeLesser:500
},


{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Saturday',0,2) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Saturday',0,2) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Saturday',0,2) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,0,countMeetings('Saturday',0,2),0],
attendeeLesser:2
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Saturday',3,4) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Saturday',3,4) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Saturday',3,4) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,0,countMeetings('Saturday',3,4),0],
attendeeLesser:4
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Saturday',5,7) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Saturday',5,7) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Saturday',5,7) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,0,countMeetings('Saturday',5,7),0],
attendeeLesser:7
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Saturday',8,500) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Saturday',8,500) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Saturday',8,500) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,0,countMeetings('Saturday',8,500),0],
attendeeLesser:500
},


{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Sunday',0,2) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Sunday',0,2) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Sunday',0,2) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,0,0,countMeetings('Sunday',0,2)],
attendeeLesser:2
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Sunday',3,4) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Sunday',3,4) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Sunday',3,4) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,0,0,countMeetings('Sunday',3,4)],
attendeeLesser:4
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Sunday',5,7) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Sunday',5,7) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Sunday',5,7) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,0,0,countMeetings('Sunday',5,7)],
attendeeLesser:7
},
{ name:'<span style="width:100px"> <strong>Persons: </strong>'+ countPersons('Sunday',8,500) +'</span>' + '<br> <strong>Total Hours:</strong>' + countHours('Sunday',8,500) + '</span>' + '<br> <strong>Total Cost:</strong>'+ countCost('Sunday',8,500) +'</span>' + '<br> <strong>Meetings<gg/strong></span>',
data:[0,0,0,0,0,0,countMeetings('Sunday',8,500)],
attendeeLesser:500
},

];


setTimeout(() => {
    renderLabels();
}, 2500);


      },[companyData && companyData.events && companyData.events.length])
    

    return (
        <div className="col-md-6">

        <div className="card mb-4 card-custom">
          {/* Header */}
          <div className="card-header align-items-center border-0">
            <h3 className="card-title align-items-start flex-column w-100">
              <span className="d-flex justify-content-between font-weight-bolder text-dark w-100">Meetings per Day <OverlayTrigger  placement="left" overlay={popover()}>
                                                <img  src="/media/ms-media/info-circle-icn.svg" alt="info" style={{cursor:"pointer"}} />
                                    </OverlayTrigger> </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">Average # of meetings per day for the <FilterRange/></span>
            </h3>
          </div>

          {/* Body */}
          <div className="card-body position-relative overflow-hidden">

{companyData &&  companyData.events && companyData.events.length > 0 && state.series.length ? <ReactApexChart options={state.options} series={state.series} type="bar" height={350} /> :
                   <div className='loader-container'>
                      { companyData == null ?  <Spinner animation="border" role="status">
                                <span className="visually-hidden"></span>
                            </Spinner> : (companyData && companyData.events.length == 0 ? "We're sorry, for the time you requested, we don't have any data to share with you." : null)}
                   </div>
}
            </div>
          </div>

        </div> 

    );
}
