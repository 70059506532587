import React from "react";
import {
  MeetingMasteryBadges,
  MeetingMasteryLevel,
  MeetingMasteryProgress,
} from "../widgets";

export function MeetingMastery() {
  
  // const updateUserProfileImage = useSelector(
  //   (state) => state.profile.saveUserProfileImageData
  // );

  // useEffect(() => {
  //   if (updateUserProfileImage && updateUserProfileImage.status === "success") {
  //     dispatch(getLocalStorageUserDetail({}));
  //     dispatch(getProfileInfoDetail({}));
  //   }
  // }, [updateUserProfileImage]);

  // useEffect(() => {
  //   // setLoader(true);
  //   dispatch(getProfileInfoDetail({}));
  //   dispatch(getLocalStorageUserDetail({}));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <div className="row col-card-gutter">
        <MeetingMasteryLevel />
        <MeetingMasteryProgress />
        <MeetingMasteryBadges />
      </div>
    </>
  );
}
