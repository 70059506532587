import { call, put, takeLatest } from 'redux-saga/effects';
import { sendApiError } from '../../../../app/modules/Auth/_redux/authCrud';
import { Apistore } from '../../../apiStore';
import { RECEIVE_LEADERBOARD_DATA, RECEIVE_LEADERBOARD_FOR_MASTERY, RECEIVE_MEETING_MASTERY_INFO, REQUEST_LEADERBOARD_DATA, REQUEST_LEADERBOARD_FOR_MASTERY, REQUEST_MEETING_MASTERY_INFO } from './leaderboard.action';
import { fetchLeaderboard, fetchMasteryinfo, fetchMasteryLeaderboard } from './leaderboard.crud';

function* getLeaderboard(action) { 
    try{
            const result = yield call (fetchLeaderboard,action.payload);
            yield put({type:RECEIVE_LEADERBOARD_DATA,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetLeaderboards)
    }
}

function* getMasteryInfo(action) { 
    try{
            const result = yield call (fetchMasteryinfo,action.payload);
            yield put({type:RECEIVE_MEETING_MASTERY_INFO,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetMeetingMasterySummary)
    }
}

function* getMasteryLeaderboard(action) {
    try{
        const result = yield call (fetchMasteryLeaderboard,action.payload);
        yield put({type:RECEIVE_LEADERBOARD_FOR_MASTERY,data:result.data})

} catch (e) {
    sendApiError(500,e,Apistore.GetMeetingMasterySummary)
}
}

export function* leaderBoardWatcher() {
    yield takeLatest(REQUEST_LEADERBOARD_DATA, getLeaderboard);
    yield takeLatest(REQUEST_MEETING_MASTERY_INFO, getMasteryInfo);
    yield takeLatest(REQUEST_LEADERBOARD_FOR_MASTERY, getMasteryLeaderboard) 
}