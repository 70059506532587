/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navbar } from "./Navbar";
import { fieldValidator, usePrevious } from "../../../_helpers/Custom";
import { validateInputs } from "../../../_helpers/validation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getTimezones } from "../../../../app/modules/Auth/_redux/authCrud";
import { LayoutSplashScreen } from "../../../layout";
import { Prompt, useHistory} from "react-router-dom";
import { AlertPopup } from "../../../_helpers/Alerts";
import { authUserId, checkPhoneValidity, checkPlusSymbol, letterCount } from "../../../_helpers/functions";
import Select from 'react-select';
import { checkUserUniqueNickname, getLocalStorageUserDetail, getProfileInfoDetail, RESEND_OTP, RESET_OTP_KEYS, saveUserProfileImage, saveUserProfileImageResponse, SEND_OTP, SUCCESS_UPDATE_USER_PHONE_NUMBER, updateProfileInfoDetail, updateUserPhoneNumber, updateUserPhoneNumberResponse, VERIFY_OTP } from "../../../../redux/duck/userProfile/userProfile.action";
import _ from "lodash";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import Axios from "axios";
import { Apistore } from "../../../../redux/apiStore";
import { useRef } from "react";
import './editPersonalInfo.css'
import './dashboard.css'
import jobTitlesList from "../../../../app/datasets/jobTitles";

export const EditPersonalInfo = (props) => {
  // Loader State
  const [loader, setLoader] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [otp_response_message, setMessage] = useState(null);
  const [otp_response_message2, setMessage2] = useState(null);
  const [erroStyle , setErroStyle] = useState({})
  const [otpToggel , SetOtpToggel]= useState(false)
  const [toggelDisable,setToggelDisable]=useState(false)
  const [jobTitleValArr, setJobTitleValArr] = useState([])
  const [genderStyle,setGenderStyle]=useState({})
  const [genderStyle2,setGenderStyle2]=useState({})
  const [genderStyle3,setGenderStyle3]=useState({})
  const [genderStyle4,setGenderStyle4]=useState({})
  const [toggel,setToggel]=useState(false)
  const [toggel1,setToggel1]=useState(true)
  const [toggel2,setToggel2]=useState(false)
  const [imageErrCount, setImageErrCount] = useState(0)
  const [timeZones, setTimeZones] = useState();
  const [options, setOptions] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [isBlocking,setIsBlocking] =useState(false)
  const [yearExpValue, setYearExpValue] = useState()
  const [OTPValiuesToggel,setOTPValiuesToggel]=useState(false) //new
  const [alert, setAlert] = useState({ show: false, title: null, message: null, errType: null });
  const [nicknameCheckedMessage, setNicknameCheckedMessage] = useState("");
  const [count, setCount] = useState({
    one:false,
    two:false,
    three:false,
    four:false,
    five:false,
    six:false
  })
  const [SelectData,setSelectData] = useState({
    jobTitle:null,
    jobLevel:null,
    yearsExp:null,
    job_title_array:[] ,
  });
  const [prop, setProp] = useState({
    phone:'',
    code:''
  })

  // Initial State
  const user_id = authUserId();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.getProfileInfoDetailData);

  const { send_otp_succ } = useSelector((state) => state.profile);
  const { verify_otp_succ } = useSelector((state) => state.profile);
  const { resend_otp_succ } = useSelector((state) => state.profile);

  const userPhone = useSelector(
    (state) => state.profile.getUserPhoneNumberDetail
  );
  const localUserData = useSelector((state) => state.profile.getLocalStorageUserData);

  const prevProfileData = usePrevious({ profileData });
  const updateProfileData = useSelector((state) => state.profile.updateProfileInfoDetailData);
  const prevUpdateProfileData = usePrevious({ updateProfileData });
  const saveProfileImageData = useSelector((state) => state.profile.saveUserProfileImageData);
  const prevSaveProfileImageData = usePrevious({ saveProfileImageData });
  const checkNicknameData = useSelector((state) => state.profile.checkUniqueNicknameData);


  const [state, setState] = useState({
    userImage: "",
    userImageSrc: "",
    firstName: "",
    lastName: "",
    nickName: "",
    yearExp:'',
    jobLevelVal:'',
    jobTitleVal:'',
    email: "",
    phone: "",
    phoneAreaCode: "",
    countryName: "",
    gender: undefined,
    user_timezone_array: [],
    timeZoneValue: undefined,
    userImageCls: "",
    firstNameCls: "",
    lastNameCls: "",
    emailCls: "",
    genderCls: "",
    genderErr: "",
    timeZoneCls: "",
    timeZoneErr: "",
    userImageErr: "",
    firstNameErr: "",
    lastNameErr: "",
    emailErr: "",
    phoneCls: "",
    phoneErr: "",
    otp_digit1: "",
    otp_digit2: "",
    otp_digit3: "",
    otp_digit4: "",
    otp_digit5: "",
    otp_digit6: "",
    otpMessageClass: null,
    otpMessageClass2: null,
    isPhoneValid: true,
    countryFormat: null

  });

  const digit1Ref = useRef(null);
  const digit2Ref = useRef(null);
  const digit3Ref = useRef(null);
  const digit4Ref = useRef(null);
  const digit5Ref = useRef(null);
  const digit6Ref = useRef(null);
  const saveButton = useRef(null);



  const { firstName, lastName, nickName, email, phone, gender, timeZoneValue ,yearExp,jobTitleVal,jobLevelVal} = state;

  const filterOption = (data) => {
    return data
  };

  const history =useHistory()


  const popover = () => (
    <Popover id="popover-basic" className="mr-4">
      <Popover.Content className="p-2">
        <div className="badge--card">
          <div className="badge--card-body">
            <span className="">Your nickname is unique to you. If you choose not to disclose your real name in company reports or leader lists like the leaderboard, we'll use your nickname instead. You can then share your nickname with some of your colleagues to showcase your meeting mastery.
            </span>
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );

  useEffect(() => {
    if(imageErrCount > 1000){
      window. location. reload()
    }
  }, [imageErrCount])


useEffect(()=>{
if (state.firstName != profileData?.user_data?.user_firstname) {
  setIsBlocking(true)
}else if(state.lastName != profileData?.user_data?.user_lastname){
  setIsBlocking(true)
}else if( state.gender != profileData?.user_data?.user_gender){
  setIsBlocking(true)
}else if( state.phone != profileData?.user_data?.country_code + profileData?.user_data?.user_phone){
  setIsBlocking(true)
}else if( state.nickName != profileData?.user_data?.user_nickname){
  setIsBlocking(true)
}else if( state.timeZoneValue != profileData?.user_data?.timezone){
  setIsBlocking(true)
}
else{
  setIsBlocking(false)
}
},[state.firstName,profileData,state.lastName,state.gender,state.phone,state.nickName ,state.timeZoneValue])

  // Check Validation Function
  const checkValidation = (field, value, type, maxLength, minLength, fieldType) => {
    return fieldValidator(field, value, type, null, maxLength, minLength, fieldType);
  };
  // Set The Input Values
  const setInputValue = (e, type, maxLength, minLength) => {
    let error = checkValidation(e.target.name, e.target.value, type, maxLength, minLength);
    if (e.target.name === 'nickName') {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        [error.fieldNameErr]: error.errorMsg,
        [error.fieldCls]: error.setClassName,
      });
      const data = { user_email: email, user_nickname: e.target.value };
        dispatch(checkUserUniqueNickname(data))
    }
    else{
      setState({
        ...state,
        [e.target.name]: e.target.value,
        [error.fieldNameErr]: error.errorMsg,
      });
    }
  };

  useEffect(() => {
    setLoader(true);
    dispatch(getProfileInfoDetail({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch user profile data and set it into state
  useEffect(() => {
    
    if (prevProfileData && prevProfileData.profileData !== profileData) {
      if (profileData && _.has(profileData, "user_data") && profileData.status === "success") {
        const userData = profileData.user_data;
        const userPrefData = profileData?.user_pref;
        setNicknameCheckedMessage("");
        setState({
          ...state,
          userName: userData.user_name,
          firstName: userData.user_firstname,
          lastName: userData.user_lastname,
          nickName: userData.user_nickname,
          email: userData.email,
          userImageSrc: userData.user_photourl,
          phone: userData && userData.user_phone ? (userData.country_code + userData.user_phone) : (userData.country_code + userData.user_phone),
          phoneAreaCode: userData.country_code,
          gender: userData.user_gender,
          timeZoneValue: userData.timezone,
          yearExp: userPrefData.pref_yearsExp,
          jobTitleVal:userPrefData.pref_jobTitle,
          jobLevelVal:userPrefData.pref_jobLevel
        });
        setLoader(false);
      }
      if (profileData && _.has(profileData, "message") && profileData.status === "error") {
        setNicknameCheckedMessage("");
        if (profileData.open_popup === false) {
          setAlert({
            show: true,
            message: profileData.message,
            title: "Error",
            errType: "failure",
          });
          setLoader(false);
        }
      }
    }
    if (prevUpdateProfileData && prevUpdateProfileData.updateProfileData !== updateProfileData) {
      if (updateProfileData && _.has(updateProfileData, "data_saved_for") && updateProfileData.status === "success") {
        setNicknameCheckedMessage("");
        props.history.push("/user-profile/personal-info");
        setLoader(false);
        dispatch(getLocalStorageUserDetail({  }))
      }
      if (updateProfileData && _.has(updateProfileData, "message") && updateProfileData.status === "error") {
        if (updateProfileData.open_popup === false) {
          setNicknameCheckedMessage("");
          setAlert({
            show: true,
            message: "Server Error:" + updateProfileData.message,
            title: "Error",
            errType: "failure",
          });
          setLoader(false);
        }
      }
    }
    if (prevSaveProfileImageData && prevSaveProfileImageData.saveProfileImageData !== saveProfileImageData) {
      if (saveProfileImageData && _.has(saveProfileImageData, "message") && saveProfileImageData.status === "error") {
        if (saveProfileImageData.open_popup === false) {
          setNicknameCheckedMessage("");
          setAlert({
            show: true,
            message: "Server Error:" + saveProfileImageData.message,
            title: "Error",
            errType: "failure",
          });
          setLoader(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProfileData, profileData, prevUpdateProfileData, updateProfileData, prevSaveProfileImageData, saveProfileImageData]);

  // Fetch Time Zone List Data
  useEffect(() => {
    getTimezones()
      .then((response) => {
        setTimeZones(response.data.timezones);
      })
      .catch((err) => {
        setAlert({
          show: true,
          message: err,
          title: "Error",
          errType: "failure",
        });
      });
  }, []);

  useEffect(() => {
    if (userPhone && userPhone?.user_data && userPhone?.user_data.is_phone_verified == 1 ) {
      setErroStyle({
        fontSize:'13px',
        marginTop:'10px'
        })
      setMessage("")
      setToggel(false)
    } else if (userPhone && userPhone?.user_data && userPhone?.user_data.is_phone_verified == 0){
        setToggel(false)
        setToggel1(true)
        setMessage("")
      }
      
    }, [userPhone && userPhone?.user_data])



  useEffect(() => {
    if (send_otp_succ && send_otp_succ.status == "success") {
      setLoading3(false);
      setErroStyle({
        fontSize:'13px',
        position:'relative',
        top:'3px',
        color:'#00B1D4'
        })
      setMessage(send_otp_succ.message);
    } else if (send_otp_succ && send_otp_succ.status == "error") {
      setLoading3(false);
      setErroStyle({
        fontSize:'13px',
          color:'rgb(227,79,79)'
        })
      setMessage(send_otp_succ.message);
    }
  }, [send_otp_succ])


  useEffect(() => {
    if (verify_otp_succ && verify_otp_succ.status == "success") {
      setState({
        ...state,
        otpMessageClass2: null
      })
      setErroStyle({
        fontSize:'13px',
       })
      setMessage2(verify_otp_succ.message);
      setTimeout(() => {
        setLoading4(false);
        dispatch({ type: RESET_OTP_KEYS });
      }, 2000);
      setToggel1(false)

    } else if (verify_otp_succ && verify_otp_succ.status == "error") {
      setLoading4(false);
      setErroStyle({
        fontSize:'13px',
        color:'rgb(227,79,79)',
        marginTop:'23px'
        })
      setMessage2(verify_otp_succ.message);
    }
  }, [verify_otp_succ])

  useEffect(() => {
    if (resend_otp_succ && resend_otp_succ.status == "success") {
      setLoading3(false);
      setState({
        ...state,
        otpMessageClass: null
      })
      setErroStyle({
        fontSize:'13px',
        position:'relative',
        top:'3px',
        color:'rgb(0, 177, 212)'
        })
      setMessage(resend_otp_succ.message);
    } else if (resend_otp_succ && resend_otp_succ.status == "error") {
      setLoading3(false);
      setErroStyle({
        fontSize:'13px',
        color:'rgb(227,79,79)'
        })
      setMessage(resend_otp_succ.message);
    }
  }, [resend_otp_succ])

  useEffect(() => {
    let newOptions = [];
    for(let i = 0; i< jobTitlesList.length ; i++){
      newOptions.push({
        value:i,
        label:jobTitlesList[i]
      })
    }

    setJobTitles(newOptions);
  
  },[jobTitlesList])

  useEffect(() => {
    if (options.length && profileData) {
      const userPrefferedTimezoneIndex = options.length && profileData && profileData.status == "success" && options.findIndex(i => i.value === profileData.user_data.timezone)
      if (userPrefferedTimezoneIndex !== -1) {
        setState({
          ...state,
          user_timezone_array: options[userPrefferedTimezoneIndex]
        })
      }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, timeZones])

  useEffect(() => {
    let newOptions = [];

    timeZones && timeZones.map((i) => {
      return newOptions.push({
        value: i.id,
        label: i.text
      })
    })
    setOptions(newOptions);

  }, [timeZones])



  useEffect(()=>{
    if (props?.location.param1 !== undefined && props?.location.param2 !== undefined) {
      setProp({
        phone:props.location.param1,
        code: props.location.param2
      })
    }
    else{
      if (localUserData && localUserData.user_data) {
        setProp({
          phone:localUserData.user_data.user_phone,
          code: localUserData?.user_data.country_code
        })
      }
    }
  },[])


  useEffect(()=>{
    if (prop.phone.length > 6 ) {
      const profileData = {phone_number:prop.code+prop.phone, country_code: prop.code };
      dispatch(updateUserPhoneNumber(profileData));
    }
  },[prop && prop.phone && prop.code ])

  useEffect(() => {

    if (yearExp) {
    setYearExpValue([{value:yearExp,label:yearExp}])
    }
    if (jobTitleVal) {
    setJobTitleValArr([{value:jobTitleVal,label:jobTitleVal}])
    }
    if (jobLevelVal) {
    setSelectData({...SelectData,jobLevel:[{value:jobLevelVal,label:jobLevelVal}]})
    }
  
}, [yearExp,jobTitleVal,jobLevelVal])

  useEffect(() => {
    setMessage("");
    setMessage2("")
    setState({
      ...state,
      otpMessageClass: null,
      otpMessageClass2: null
    })
  }, [])

  useEffect(() => {
    if (nickName !== "") {
      if (checkNicknameData && _.has(checkNicknameData, "message") && checkNicknameData.status === "success") {
        setNicknameCheckedMessage(checkNicknameData.message);
      }
      if (checkNicknameData && _.has(checkNicknameData, "message") && checkNicknameData.status === "error") {
        setNicknameCheckedMessage(checkNicknameData.message);
      }
    }else {
      setNicknameCheckedMessage('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkNicknameData]);

  const handleSearchCountryChange = (country) => {
    if (country.length && country.length >= 1) {
      setLoading2(true);
      getTimezones(country)
        .then((response) => {
          setTimeZones(response.data.timezones);
          setLoading2(false);
        })
        .catch((err) => {
          console.log(err)
        });
    }
  }
  
  // handel check button of phone number 
  const handelPhoneCheck = () => {
    
    setToggel2(false)
    setLoader(true)
    setOTPValiuesToggel(false)
    let phone = state.phone,
      phoneAreaCode = state.phoneAreaCode,
      phoneErr = "",
      phoneCls = "";
      
    Axios.post(Apistore.CheckIsPhoneUnique, {
      user_phone_number: checkPlusSymbol(state.phone),
      user_phone_number_area_code: checkPlusSymbol(state.phoneAreaCode)
    }).then(res => {
      if (res.data?.is_phone_verified === 1) {
        setErroStyle({
          fontSize:'13px',
          color:'#00B1D4'
          })
        setMessage("This number is verified.")
        setTimeout(() => {
          setLoader(false)
        }, 1000);
      }
      else if (res.data?.status !== 'error') {
        if (phone === "") {
          setMessage("Please enter phone number.")
        }
        setState({
          ...state,
          phoneCls,
          phoneErr,
        });
          const profileData = {phone_number: checkPlusSymbol(phone), country_code: checkPlusSymbol(phoneAreaCode) };
          dispatch(updateUserPhoneNumber(profileData));
          setTimeout(() => {
            setLoader(false)
          }, 2000);
        }else{
          setErroStyle({
            fontSize:'13px',
              color:'rgb(227,79,79)'
            })
          setMessage(res.data?.message)
          setLoader(false)
        }
        }).catch(err => {
      console.log(err);
    })
  };

  //handling onKeydown event of phone number (react-phone-input-2)
  const handelPhoneKeyDown = (e)=>{
    if (e.key === 'Tab') {
      setToggelDisable(true)
      setTimeout(() => {
        setToggelDisable(false)
      }, 4000);
    }
  }

  const handlePhoneInput = (phone, country) => {
    const isValid = checkPhoneValidity(phone, country);
    setMessage2('');
    setToggelDisable(false)
    let notAllowedNum = '0000000000'
    if (isValid) {
      //----------new start---------
      let checkNum = phone.slice(-10)
      if (checkNum !== notAllowedNum) {
        setToggel(true)
        setMessage(null);
      }else{
        setErroStyle({
          fontSize:'13px',
          color:'rgb(227,79,79)'
        })
        setMessage("Please enter valid phone number.")
      }
      //----------new end---------
      setToggel2(false)
      setState({
        ...state,
        isPhoneValid: true,
        otpMessageClass: "",
        phone: phone,
        countryName: country.name,
        phoneAreaCode: country.dialCode,
        countryFormat: country
      })
    } else {
      setToggel(false)
      setErroStyle({
        fontSize:'13px',
        color:'rgb(227,79,79)'
      })

      setState({
        ...state,
        isPhoneValid: false,
        phone: phone,
        countryName: country.name,
        phoneAreaCode: country.dialCode,
        countryFormat: country,
      })
      if (phone == "") {
        setErroStyle({
          fontSize:'13px',
          color:'rgb(227,79,79)'
          })
        setMessage("Please enter phone number.")
      }else{
        setErroStyle({
          fontSize:'13px',
          color:'rgb(227,79,79)'
          })
        setMessage("Please enter valid phone number.")
      }
    }
    setToggel1(false)
    SetOtpToggel(false)
  }

  //handel Save Changes button 
  const saveProfileData = () => {
    setIsBlocking(false)
    setLoader(true);
      let error = "error";
      let firstName = state.firstName,
      lastName = state.lastName,
      nickName = state.nickName,
      email = state.email,
      phone = state.phone,
      phoneAreaCode = state.phoneAreaCode,
      userImage = state.userImage,
      firstNameCls = "",
      lastNameCls = "",
      emailCls = "",
      genderCls = "",
      timeZoneCls = "",
      firstNameErr = "",
      lastNameErr = "",
      emailErr = "",
      phoneErr = "",
      userImageErr = "",
      userImageCls = "",
      phoneCls = "",
      genderErr = "",
      timeZoneErr = "",
      getError = false;

      // check phone number validity
      Axios.post(Apistore.CheckIsPhoneUnique, {
      user_phone_number: state.phone.length>3? checkPlusSymbol(state.phone) : '',
      user_phone_number_area_code: state.phoneAreaCode.length? checkPlusSymbol(state.phoneAreaCode) :''
    }).then(res => {
      if (res.data.status == "error") {
        setLoader(false);
        setState({
          ...state,
          otpMessageClass: "otpError",
          isPhoneValid: false
        })
        setErroStyle({
          fontSize:'13px',
          color:'rgb(227,79,79)'
        })
        setMessage(res.data.message);
      } else {
        // check other fields validity
        if (validateInputs("string", firstName) === "empty") {
          firstNameErr = "Please enter first name.";
          getError = true;
        } else if (validateInputs("string", firstName) === false) {
          firstNameErr = "Please enter valid first name.";
          getError = true;
        } else if (firstName.length > 20) {
          firstNameErr = "Please enter maximum 20 characters.";
          getError = true;
        }

        if (validateInputs("string", lastName) === "empty") {
          lastNameErr = "Please enter last name.";
          getError = true;
        } else if (validateInputs("string", lastName) === false) {
          lastNameErr = "Please enter valid last name.";
          getError = true;
        } else if (lastName.length > 20) {
          lastNameErr = "Please enter maximum 20 characters.";
          getError = true;
        }
        
        if (validateInputs("email", email) === "empty") {
          emailErr = "Please enter email.";
          emailCls = error;
          getError = true;
        } else if (validateInputs("email", email) === false) {
          emailErr = "You have entered the wrong email. Example: johnsnow@example.com";
          emailCls = error;
          getError = true;
        }
        if (timeZoneValue === undefined || "") {
          timeZoneErr = "Please select your timezone.";
          timeZoneCls = error;
          getError = true;
        }
        if (userImage && !userImage.type.match(/image/g)) {
          userImageErr = 'Please select valid image.';
          userImageCls = error
          getError = true;
        } else if (userImage && userImage.size / 1024 / 1024 > 2) {
          userImageErr = 'Image size is greater than 2 MB.';
          userImageCls = error
          getError = true;
        }

        setState({
          ...state,
          firstNameCls,
          lastNameCls,
          emailCls,
          phoneCls,
          userImageCls,
          genderCls,
          genderErr,
          userImageErr,
          timeZoneCls,
          timeZoneErr,
          firstNameErr,
          lastNameErr,
          phoneErr,
          emailErr,
        });

        if (getError === false && emailErr === "" && firstNameErr === "" && lastNameErr === ""  && userImageErr === "" ) {
            const profileData = { user_firstname: firstName, user_lastname: lastName, user_nickname: nickName, user_phone_number: checkPlusSymbol(phone), user_phone_number_area_code:checkPlusSymbol(phoneAreaCode), user_gender: gender, user_timezone: timeZoneValue,yearsExp:yearExp,jobTitle:jobTitleVal,jobLevel:jobLevelVal };
            const imageData = new FormData();
            imageData.append('profile_image', userImage)
            if (userImage !== "") {
              dispatch(saveUserProfileImage(imageData));
            }
            setLoader(true);
            dispatch(updateProfileInfoDetail(profileData));
          } else {
            setLoader(false);
          }
        }
    })
    .catch(err => {
      console.log(err);
    })
  };

  const saveProfileData1 = () => {
    setIsBlocking(false)
    setLoader(true);
      let error = "error";
      let firstName = state.firstName,
      lastName = state.lastName,
      nickName = state.nickName,
      email = state.email,
      userImage = state.userImage,
      firstNameCls = "",
      lastNameCls = "",
      emailCls = "",
      genderCls = "",
      timeZoneCls = "",
      firstNameErr = "",
      lastNameErr = "",
      emailErr = "",
      phoneErr = "",
      userImageErr = "",
      userImageCls = "",
      phoneCls = "",
      genderErr = "",
      timeZoneErr = "",
      getError = false;

        // check other fields validity
        if (validateInputs("string", firstName) === "empty") {
          firstNameErr = "Please enter first name.";
          getError = true;
        } else if (validateInputs("string", firstName) === false) {
          firstNameErr = "Please enter valid first name.";
          getError = true;
        } else if (firstName.length > 20) {
          firstNameErr = "Please enter maximum 20 characters.";
          getError = true;
        }

        if (validateInputs("string", lastName) === "empty") {
          lastNameErr = "Please enter last name.";
          getError = true;
        } else if (validateInputs("string", lastName) === false) {
          lastNameErr = "Please enter valid last name.";
          getError = true;
        } else if (lastName.length > 20) {
          lastNameErr = "Please enter maximum 20 characters.";
          getError = true;
        }
        
        if (validateInputs("email", email) === "empty") {
          emailErr = "Please enter email.";
          emailCls = error;
          getError = true;
        } else if (validateInputs("email", email) === false) {
          emailErr = "You have entered the wrong email. Example: johnsnow@example.com";
          emailCls = error;
          getError = true;
        }
        if (timeZoneValue === undefined || "") {
          timeZoneErr = "Please select your timezone.";
          timeZoneCls = error;
          getError = true;
        }
        if (userImage && !userImage.type.match(/image/g)) {
          userImageErr = 'Please select valid image.';
          userImageCls = error
          getError = true;
        } else if (userImage && userImage.size / 1024 / 1024 > 2) {
          userImageErr = 'Image size is greater than 2 MB.';
          userImageCls = error
          getError = true;
        }

        setState({
          ...state,
          firstNameCls,
          lastNameCls,
          emailCls,
          phoneCls,
          userImageCls,
          genderCls,
          genderErr,
          userImageErr,
          timeZoneCls,
          timeZoneErr,
          firstNameErr,
          lastNameErr,
          phoneErr,
          emailErr,
        });

        if (getError === false && emailErr === "" && firstNameErr === "" && lastNameErr === ""  && userImageErr === "" ) {
            const profileData = { user_firstname: firstName, user_lastname: lastName, user_nickname: nickName, user_phone_number: '', user_phone_number_area_code:'', user_gender: gender, user_timezone: timeZoneValue,yearsExp:yearExp,jobTitle:jobTitleVal,jobLevel:jobLevelVal };
            const imageData = new FormData();
            imageData.append('profile_image', userImage)
            if (userImage !== "") {
              dispatch(saveUserProfileImage(imageData));
            }
            setLoader(true);
            dispatch(updateProfileInfoDetail(profileData));
          } else {
            setLoader(false);
          }
  };

  // Select Image file
  const onSelectFile = (e) => {
    if (e && e.target.value.length !== 0) {
      setState({ ...state, userImage: e.target.files[0], userImageSrc: URL.createObjectURL(e.target.files[0]), userImageErr: "", userImageCls: "" });
    }
  };

  // handle close button of alert
  const handleClose = () => {
    setAlert({
      show: false,
    });
  };

  const timeZoneHandler = (event) => {
    setState({
      ...state,
      user_timezone_array: event,
      timeZoneValue: event.value
    })
  }

  //handel send otp button 
  const handleSendOtp = () => {
    setState({
      ...state,
      otp_digit1: "",
      otp_digit2: "",
      otp_digit3: "",
      otp_digit4: "",
      otp_digit5: "",
      otp_digit6: "",
    })
    setMessage(null);
    SetOtpToggel(true)
    if (send_otp_succ == null || (send_otp_succ && send_otp_succ.status == "error")) {
      setOTPValiuesToggel(true)
      setLoading3(true);
      const phone = checkPlusSymbol(state.phone);
      setTimeout(() => {
        dispatch({
          type: SEND_OTP, data: {
            user_phone: phone,
            country_code: checkPlusSymbol(state.phoneAreaCode)
          }
        })
      }, 2000);
      setToggel2(true)
    } else if (send_otp_succ && send_otp_succ.status == "success" && toggel2 ) {
      handleResendOTP();
    }else if (send_otp_succ && send_otp_succ.status == "success" && !toggel2 ){
      setState({
        ...state,
        otpMessageClass: null,
        otp_digit1: "",
        otp_digit2: "",
        otp_digit3: "",
        otp_digit4: "",
        otp_digit5: "",
        otp_digit6: ""
      })
      setOTPValiuesToggel(true)
      setLoading3(true);
      const phone = checkPlusSymbol(state.phone);
      setTimeout(() => {
        dispatch({
          type: SEND_OTP, data: {
            user_phone: phone,
            country_code: checkPlusSymbol(state.phoneAreaCode)
          }
        })
      setToggel2(true)
      }, 2000);
    }
  }

  // handel otp resend button
  const handleResendOTP = () => {
    setOTPValiuesToggel(true)
    setMessage2('')
    setMessage(null);
    setLoading3(true);
    setState({
      ...state,
      otpMessageClass: null,
      otp_digit1: "",
      otp_digit2: "",
      otp_digit3: "",
      otp_digit4: "",
      otp_digit5: "",
      otp_digit6: ""
    })

    const phone = checkPlusSymbol(state.phone);
    setTimeout(() => {
      dispatch({
        type: RESEND_OTP, data: {
          user_phone: phone,
          country_code: checkPlusSymbol(state.phoneAreaCode)
        }
      })
    }, 2000);
  }

  const handleVerifyOtp = () => {
    setMessage(null);
    setMessage2(null);
    setLoading4(true);
    const phone = checkPlusSymbol(state.phone);
    const otp = state.otp_digit1 + state.otp_digit2 + state.otp_digit3 + state.otp_digit4 + state.otp_digit5 + state.otp_digit6
    dispatch({
      type: VERIFY_OTP,
      data: {
        user_phone: phone,
        country_code: checkPlusSymbol(state.phoneAreaCode),
        otp: otp
      }
    })
  }

  const otpInputHandler = (e)=>{
    setState({ ...state, otp_digit1: e.target.value })
     if (e.target.value.length > 0){
        digit2Ref.current.focus() } 
  }
  const otpInputHandler2 = (e)=>{
    setState({ ...state, otp_digit2: e.target.value })
     if (e.target.value.length > 0){
        digit3Ref.current.focus() } 
        else if(count.two && e.target.value.length == 0 ){
          digit1Ref.current.focus()
        }
        else if (e.target.value.length == 0){
          setCount({
            ...count,
            two:true
          })
          digit2Ref.current.focus()
        }
  }
  const otpInputHandler3 = (e)=>{
    setState({ ...state, otp_digit3: e.target.value })
     if (e.target.value.length > 0){
        digit4Ref.current.focus() } 
        else if(count.three && e.target.value.length == 0 ){
          digit2Ref.current.focus()
        }
        else if (e.target.value.length == 0){
          setCount({
            ...count,
            three:true
          })
          digit3Ref.current.focus()
        }
  }
  const otpInputHandler4 = (e)=>{
    setState({ ...state, otp_digit4: e.target.value })
     if (e.target.value.length > 0){
        digit5Ref.current.focus() } 
        else if(count.four && e.target.value.length == 0 ){
          digit3Ref.current.focus()
        }
        else if (e.target.value.length == 0){
          setCount({
            ...count,
            four:true
          })
          digit4Ref.current.focus()
        }
  }
  const otpInputHandler5 = (e)=>{
    setState({ ...state, otp_digit5: e.target.value })
     if (e.target.value.length > 0){
        digit6Ref.current.focus() } 
        else if(count.five && e.target.value.length == 0 ){
          digit4Ref.current.focus()
        }
        else if (e.target.value.length == 0){
          setCount({
            ...count,
            five:true
          })
          digit5Ref.current.focus()
        }
  }
  const otpInputHandler6= (e)=>{
    setState({ ...state, otp_digit6: e.target.value })
     if(count.six && e.target.value.length == 0 ){
          digit5Ref.current.focus()
        }
        else if (e.target.value.length == 0){
          setCount({
            ...count,
            six:true
          })
          digit6Ref.current.focus()
        }
  } 

  const handleKeyDown1 = event => {
 if ( event.key === 'Delete'){
  setState({ ...state, otp_digit1: '' })
 }else if ( event.key === 'ArrowRight'){
  digit2Ref.current.focus()
 }
  };

  const handleKeyDown2 = event => {
    if (count.two && event.key === 'Backspace') {
      setCount({
        one:false,
        two:false,
        three:false,
        four:false,
        five:false,
        six:false
      })
       digit1Ref.current.focus()
    }else if (state.otp_digit2 =='' && event.key === 'Backspace' || event.key === 'Delete' ){
      setState({ ...state, otp_digit2: '' })
      digit1Ref.current.focus()
     }else if ( event.key === 'ArrowLeft'){
      digit1Ref.current.focus()
     }else if ( event.key === 'ArrowRight'){
      digit3Ref.current.focus()

     }
  };

    const handleKeyDown3 = event => {
      if (count.three && event.key === 'Backspace') {
        setCount({
          one:false,
          two:false,
          three:false,
          four:false,
          five:false,
          six:false
        })
        digit2Ref.current.focus()
      }else if (state.otp_digit3 =='' && event.key === 'Backspace' || event.key === 'Delete'){
      setState({ ...state, otp_digit3: '' })
      digit2Ref.current.focus()
      }else if ( event.key === 'ArrowLeft'){
      digit2Ref.current.focus()
      }else if ( event.key === 'ArrowRight'){
      digit4Ref.current.focus()
      }
    }
    const handleKeyDown4 = event => {
      if (count.four && event.key === 'Backspace') {
        setCount({
          one:false,
          two:false,
          three:false,
          four:false,
          five:false,
          six:false
        })
        digit3Ref.current.focus()
      
      }else if (state.otp_digit4 =='' && event.key === 'Backspace' || event.key === 'Delete'){
      setState({ ...state, otp_digit4: '' })
      digit3Ref.current.focus()
      }else if ( event.key === 'ArrowLeft'){
      digit3Ref.current.focus()
      }else if ( event.key === 'ArrowRight'){
      digit5Ref.current.focus()
      }
    }

    const handleKeyDown5 = event => {
      if (count.five && event.key === 'Backspace') {
        setCount({
          one:false,
          two:false,
          three:false,
          four:false,
          five:false,
          six:false
        })
        digit4Ref.current.focus()
     }else if (state.otp_digit5 =='' && event.key === 'Backspace' || event.key === 'Delete'){
      setState({ ...state, otp_digit5: '' })
      digit4Ref.current.focus()
     }
     else if ( event.key === 'ArrowLeft'){
      digit4Ref.current.focus()
     }else if ( event.key === 'ArrowRight'){
      digit6Ref.current.focus()
     }else if( state.otp_digit5 !=='' && event.key === 'Delete' ){
       digit5Ref.current.focus()
      setState({ ...state, otp_digit5: '' })
    }
    }
    const handleKeyDown6= event => {
      if (count.six && event.key === 'Backspace') {
        setCount({
          one:false,
          two:false,
          three:false,
          four:false,
          five:false,
          six:false
        })
        setState({ ...state, otp_digit6: '' })
        digit5Ref.current.focus()
      
      }else if (state.otp_digit6 =='' && event.key === 'Backspace' || event.key === 'Delete'){
      setState({ ...state, otp_digit6: '' })
      digit5Ref.current.focus()
      }else if ( event.key === 'ArrowLeft'){
      digit5Ref.current.focus()
    }
    }
  
  // react select box custom styles
  const dropdownIndicatorStyles = (base, state) => {
    let changes = {
      // all your override styles
      backgroundColor: '#F8F9FB',
      height:'56.85px',
    borderRadius:'3px'
    };
    return Object.assign(base, changes);
  };
  
  //handel on blur event of time zone
  const handelTimeSelect2 =()=>{
    setTimeout(() => {
      
      saveButton.current.focus()
    }, 2);
  }

  // handel on blur event of nick name
  const handelBlur =(event)=>{
    if (event.key==='Tab') {
      
      if (gender === 2) {
        setGenderStyle2({background:'#008D9D',color:'white'})
      }
      else if (gender === 3){
        setGenderStyle3({background:'#008D9D',color:'white'})
      }
      else if (gender === 1){
        setGenderStyle({background:'#008D9D',color:'white'})
        setState({ ...state, gender: 1 })
      }
      else{
        setGenderStyle4({background:'#008D9D',color:'white'})
        setState({ ...state, gender: 4 })
        
      }
    }
  }
  const handelGenderKeyDown =(e)=>{
    if (e.key === 'Enter') {
      setGenderStyle({})
      setGenderStyle2({})
      setGenderStyle3({})
      setGenderStyle4({})
    }
  }

  const colourStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? "1px solid rgb(204, 204, 204)" : "1px solid rgb(204, 204, 204)",
      boxShadow: state.isFocused ? " 0 0 0 1px #00ACBF" : "none",
      "&:hover": {
      border: " 1px solid rgb(179, 179, 179)",
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? '#00ACBF'
          : isFocused
          ? '#bdf0f6'
          : undefined,
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? '#00ACBF'
              : 'none'
            : undefined,
        },
      }
    },
  };

  const handelRemovePhoto =()=>{
    setState({...state,userImageSrc:"/media/ms-media/user-avatar.jpg" ,userImage:null})
  }
  const handelCancel =()=>{
    setIsBlocking(false)
    setTimeout(() => {
      history.push('/user-profile/personal-info')
    }, 2);
  }

  const exp_years =[
    {value:'1-4',label:'1-4'},
    {value:'5-7',label:'5-7'},
    {value:'7-10',label:'7-10'},
    {value:'11-15',label:'11-15'},
    {value:'16-20',label:'16-20'},
    {value:'20+',label:'20+'},
  ]

const jobLevels =[
  {value:'Executive',label:'Executive'},
  {value:'VP',label:'VP'},
  {value:'Director',label:'Director'},
  {value:'Supervisor',label:'Supervisor'},
  {value:'Manager',label:'Manager'},
  {value:'Other',label:'Other'},
]
  
  return (
    <>
     <Prompt
        when={isBlocking}
        message={(location) => `You're about to leave this page without saving your changes. Are you sure you want to leave?.`}
      />
      <LayoutSplashScreen visible={loader} />
      {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />}
      <div className="profile-container flex-column h-100 d-flex">
        <div className="nav-container d-flex justify-content-between editpersonalinfo_navigation">
          <Navbar getMainRoute={"personalInfo"} />
          <div className="edit-button">
            <input type='button' onClick={handelCancel} className="btn btn-edit mr-2" 
             value="Cancel"
             />
            <input type="button" ref={saveButton} className="btn btn-primary" onClick={state.phone.replace(state.phoneAreaCode,'').replace('+','').length > 0 ? saveProfileData : saveProfileData1}
             value='Save Changes'
            />
          </div>
        </div>
        <div className="tab-content bg-transparent flex-grow-1 editpersonalinfo_profileTab" id="profileTab">
          <div className="card card-custom">
            <div className="card-body">
              {/* TAB-1 PERSONAL INFORMATION */}

              <div className="tab-pane fade show active" id="personalInfo" role="tabpanel" aria-labelledby="personalInfo-tab">
                {/* PERSONAL INFORMATION VIEW */}

                <div className="user-personal-info info-edit">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-6">
                      <div className="user-pic--name d-flex">
                        <div className={"add-pic text-center " + state.userImageCls}>
                          <div className="profilePic-wrap"><img width="152" height="152" src={state.userImageSrc && state.userImageSrc !='https://client.meetingscience.io/storage/null' ?  state.userImageSrc : "/media/ms-media/user-avatar.jpg"} onError={({ currentTarget }) => {currentTarget.src="https://admin-stage.meetingscience.io/images/user-avatar.jpg";setImageErrCount(imageErrCount + 1); currentTarget.onerror = null}} className="rounded-circle" alt="" /></div>
                          <div className="upload-btn">
                          <button onClick={()=>{document.getElementById('browse-file').click()}} className="btn"> {state.userImageSrc != 'https://client.meetingscience.io/storage/null'  && state.userImage != null ?'Change photo': 'Upload photo' } </button>
                            <input type="file" onChange={(e) => onSelectFile(e)} className="" id="browse-file" accept="image/*" />
                            {state.userImageErr ? <p  className="errorValidationMessage">{state.userImageErr}</p> : ""}
                          </div>
                        {
                          state.userImageSrc != 'https://client.meetingscience.io/storage/null'  && state.userImage != null ?
                          <button className='remove_photo_cross_icon upload-btn btn' title="Remove Photo" onClick={handelRemovePhoto}>&#10006;</button>
                          :''
                        }
                        </div>
                        <div className="user-name-wrap flex-grow-1 ml-6">
                          <div className={"form-group " + state.firstNameCls}>
                            <label>First name <span className="required-astrik-sing" >*</span></label>
                            <input type="text" className="form-control form-control-solid form-control-lg" name="firstName" value={firstName} placeholder="First Name" onChange={(e) => setInputValue(e, "string", 20, null)} />
                            {state.firstNameErr ? <span style={{fontSize:'13px',color:'rgb(227,79,79)'}} className="errorValidationMessage">{state.firstNameErr}</span> : ""}
                          </div>
                          <div className={"form-group " + state.lastNameCls}>
                            <label>Last name <span className="required-astrik-sing" >*</span></label>
                            <input type="text" className="form-control form-control-solid form-control-lg" name="lastName" value={lastName} placeholder="Last Name" onChange={(e) => setInputValue(e, "string", 20, null)} />
                            {state.lastNameErr ? <span style={{fontSize:'13px',color:'rgb(227,79,79)'}} className="errorValidationMessage">{state.lastNameErr}</span> : ""}
                          </div>
                          <div className="form-group">
                            <label>Nick name (Optional) <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover()}>
                              <img src="/media/ms-media/info-circle-icn.svg" alt="info" style={{ cursor: "pointer" }} />
                            </OverlayTrigger></label>
                            <input type="text" className="form-control form-control-solid form-control-lg"  onKeyDown={handelBlur} name="nickName" value={nickName} placeholder="Nick Name" onChange={(e) => setInputValue(e, "string", 20, null)} />
                            {nicknameCheckedMessage !== "" ? checkNicknameData && checkNicknameData.status === "success" ? <span className="text-success">{nicknameCheckedMessage}</span> : <span className="text-danger">{nicknameCheckedMessage}</span> : ""}
                          </div>
                        </div>
                      </div>
                      <div className={"form-group " + state.emailCls}>
                        <label>email <span className="required-astrik-sing" >*</span> </label>
                        <input style={{opacity:'0.5',pointerEvents:'none'}} type="email" disabled className="form-control form-control-solid form-control-lg" name="email" value={email} placeholder="Enter Email" onChange={(e) => setState({ ...state, email: e.target.value, emailCls: "", emailErr: "" })} />
                        {state.emailErr ? <span className="errorValidationMessage">{state.emailErr}</span> : ""}
                      </div>
                      <div onKeyDown={handelGenderKeyDown} className={"form-group mb-0 " + state.genderCls}>
                        <p>How should we address you?</p>
                        <div className="auth-selectgroup">
                          <label  className="auth-selectgroup-item " >
                            <input onClick={()=>{setGenderStyle({})}} onFocus={()=>{setGenderStyle({background:'#008D9D',color:'white'})}}  onBlur={()=>{setGenderStyle({})}} className="selectgroup-input" onChange={() => setState({ ...state, gender: 1 })} type="radio" name="gender" checked={state.gender === 1 ? true : false} id="Male" value={gender ? gender : ''} />
                            <span style={genderStyle} className="selectgroup-button">he/him/his</span>
                          </label>
                          <label  className="auth-selectgroup-item">
                            <input onClick={()=>{setGenderStyle2({})}} onFocus={()=>{setGenderStyle2({background:'#008D9D',color:'white'})}}  onBlur={()=>{setGenderStyle2({})}} className="selectgroup-input" onChange={() => setState({ ...state, gender: 2 })} type="radio" name="gender" checked={state.gender === 2 ? true : false} id="Female" value={gender ? gender : ''} />
                            <span style={genderStyle2} className="selectgroup-button">she/her/hers</span>
                          </label>
                          <label  className="auth-selectgroup-item">
                            <input onClick={()=>{setGenderStyle3({})}} onFocus={()=>{setGenderStyle3({background:'#008D9D',color:'white'})}}  onBlur={()=>{setGenderStyle3({})}} className="selectgroup-input" onChange={() => setState({ ...state, gender: 3 })} type="radio" name="gender" checked={state.gender === 3 ? true : false} id="Company" value={gender ? gender : ''} />
                            <span style={genderStyle3} className="selectgroup-button">they/them/their</span>
                          </label>
                          <label  className="auth-selectgroup-item">
                            <input onClick={()=>{setGenderStyle4({})}}  onFocus={()=>{setGenderStyle4({background:'#008D9D',color:'white'})}}  onBlur={()=>{setGenderStyle4({})}} className="selectgroup-input" onChange={() => setState({ ...state, gender: 4 })} type="radio" name="gender" checked={state.gender === 4 ? true : false} id="na" value={gender ? gender : ''} />
                            <span style={genderStyle4} className="selectgroup-button">Prefer not to say</span>
                          </label>
                        </div>
                        {state.genderErr && gender === 0 || undefined ? <p className="errorValidationMessage">{state.genderErr}</p> : ""}
                      </div>

                    </div>
                  <div className="col-xl-6 col-lg-6">
                      
                    <div className="form-row">
                        <div className="col-12">
                          <p>Mobile Number (We will only send you messages via SMS if you opt-in to receive them):</p>
                        </div>

                        <div className={"col-md-7 form-group mb-1 send_otp" + state.phoneCls}>
                          <label>PHONE NUMBER (Optional)</label>
                          <PhoneInput style={{marginBottom:'-25px'}} inputClass="country-code-input" enableSearch="true" dropdownClass="dropdown-country-code" name="phone" country={"us"} value={phone} placeholder="+1" onChange={(phone, country) =>  handlePhoneInput(phone, country)} onKeyDown={handelPhoneKeyDown} disableDropdown={toggelDisable} />
                          {state.phoneErr ? <span className="errorValidationMessage"  >{state.phoneErr}</span> : ""}
                        </div>

                        {toggel && 
                         <div className="phone-no-check-btn col-md-5" >
                           <button type="button" style={{textAlign:'start',alignContent:'start',alignItems:'start',left:'0px'}}  className="m-2 px-5 btn btn-primary " onClick={handelPhoneCheck} >
                             <span className="d-inline-block px-5">Check</span>
                           </button>
                         </div>
                        }

                        {state.phone.replace(state.phoneAreaCode,'').length>1 && toggel1 === true && userPhone && userPhone?.user_data && userPhone?.user_data.is_phone_verified == 0 ? 
                          <div className='col-md-6 send_otp_button'>
                            <div className={"text-center form-group mb-0" + state.phoneCls}>
                              <div><label>Validate Phone Number</label></div>
                              <button className="m-2 px-5 btn btn-primary send_OTP_Custom " onClick={handleSendOtp}>
                                <span className="d-inline-block px-5">{send_otp_succ == null ? "Send OTP" : (send_otp_succ && send_otp_succ.status == "success" && otpToggel ? "Resend" : "Send OTP")}</span></button>
                              {loading3 ? <Spinner animation="border" role="status" style={{ verticalAlign: 'middle' }}>
                                <span className="visually-hidden"></span>
                              </Spinner> : null}
                            </div>
                          </div>
                           :
                           null
                        } 
                        
                        <div  className='col-md-12 error_massage'><span style={erroStyle} href="#" className={"bck_btn my-3 d-inline-block "}>{otp_response_message}</span></div>
                        {state.phone.replace(state.phoneAreaCode,'').length>1 && toggel1 === true && userPhone && userPhone?.user_data && userPhone?.user_data.is_phone_verified == 0 ?
                          <div className="col-12 form-group mb-1">
                            {verify_otp_succ == null || verify_otp_succ && verify_otp_succ.status == "error" ?
                              <div className='row'>
                                <div className='col-12'><label>OTP Verification</label></div>
                                <div className='col-md-8 d-flex text-center edit-profile-text-center'>
                                  <input placeholder="" ref={digit1Ref} className="m-2  form-control form-control-solid" type="text" min='0' max='9' disabled={send_otp_succ == null || !OTPValiuesToggel ? true : false} value={OTPValiuesToggel ?state.otp_digit1 : ''} onChange={otpInputHandler}    onKeyDown={handleKeyDown1}  maxLength="1" />
                                  <input placeholder="" ref={digit2Ref} className="m-2  form-control form-control-solid" type="text" min='0' max='9' disabled={send_otp_succ == null || !OTPValiuesToggel ? true : false} value={OTPValiuesToggel ?state.otp_digit2 : ''}   onChange={otpInputHandler2} onKeyDown={handleKeyDown2}   maxLength="1" />
                                  <input placeholder="" ref={digit3Ref} className="m-2  form-control form-control-solid" type="text" min='0' max='9' disabled={send_otp_succ == null || !OTPValiuesToggel ? true : false} value={OTPValiuesToggel ? state.otp_digit3 : ''}  onChange={otpInputHandler3} onKeyDown={handleKeyDown3}  maxLength="1" />
                                  <input placeholder="" ref={digit4Ref} className="m-2  form-control form-control-solid" type="text" min='0' max='9' disabled={send_otp_succ == null || !OTPValiuesToggel ? true : false} value={OTPValiuesToggel ?state.otp_digit4 : ''}   onChange={otpInputHandler4} onKeyDown={handleKeyDown4}  maxLength="1" />
                                  <input placeholder="" ref={digit5Ref} className="m-2  form-control form-control-solid" type="text" min='0' max='9' disabled={send_otp_succ == null || !OTPValiuesToggel ? true : false} value={OTPValiuesToggel ?state.otp_digit5 : ''}   onChange={otpInputHandler5} onKeyDown={handleKeyDown5}  maxLength="1" />
                                  <input placeholder="" ref={digit6Ref} className="m-2  form-control form-control-solid" type="text" min='0' max='9' disabled={send_otp_succ == null || !OTPValiuesToggel ? true : false} value={OTPValiuesToggel ?state.otp_digit6 : ''} onChange={otpInputHandler6}  onKeyDown={handleKeyDown6}    maxLength="1" />
                                </div>
                                <div className='col-md-2 edit-profile-verify-button'>
                                  <button className="m-2 px-5 btn btn-primary " type="button" onClick={handleVerifyOtp} disabled={state.otp_digit1 == "" || state.otp_digit2 == "" || state.otp_digit3 == "" || state.otp_digit4 == "" || state.otp_digit5 == "" || state.otp_digit6 == "" || !OTPValiuesToggel ? true : false}>
                                    <span style={{cursor:'pointer'}} className="d-inline-block px-5">{verify_otp_succ == null ? "Verify" : (verify_otp_succ && verify_otp_succ.status == "success" ? "Verified" : "Verify")}</span>
                                  </button>
                                </div>
                                {loading4 ?
                                  <div className='col-md-2 text-right mt-2'> <Spinner animation="border" role="status" style={{ marginTop: '4px' }}>
                                    <span className="visually-hidden"></span>
                                  </Spinner>
                                  </div>
                                  : null}
                              </div>
                              : null
                            }
                          </div>
                          : null
                        }
                        <div className='msgClass'><span href="#" style={erroStyle} className={"bck_btn d-inline-block  " }>{otp_response_message2}</span></div>
                      </div>

                      <div className="form-row mb-2">
                        <div className="col-12">
                          <p>Please select the timezone you work in most often:</p>
                        </div>
                        <div className={"col-12 form-group timezone-container" + state.timeZoneCls}>
                          <label>Your timezone <span className="required-astrik-sing" >*</span></label>
                          <Select  value={state.user_timezone_array} onBlur={handelTimeSelect2} styles={{dropdownIndicator: dropdownIndicatorStyles,...colourStyles}} classNamePrefix="react-select1" className="react-select" options={options} filterOption={filterOption}  name="user_timezone" onInputChange={(value) => handleSearchCountryChange(value)} onChange={(e) => timeZoneHandler(e)} />
                          {loading2 ? <div className='loading-timezones'>
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden"></span>
                            </Spinner>
                          </div> : null}
                          {state.timeZoneErr ? <span className="errorValidationMessage">{state.timeZoneErr}</span> : ""}
                          <div></div>
                        </div>
                      </div>

                      <div className="form-row">
                      <div className="form-group col-sm-12 mb-4  autocomplete-container" >
                      <h3 style={{fontSize:'16px',fontWeight:'600'}}>Professional Details</h3>
                          <p className="m-0">We use your Professional Details to optimize recommendations and benchmark reports for you.</p>
                      </div>
                      </div>

                      <div className="form-row">
                      <div className="form-group col-xl-12 col-md-12 autocomplete-container" >
                      <label>Job title <span className="required-astrik-sing" >*</span></label>
                      <Select className="react-select" styles={{dropdownIndicator: dropdownIndicatorStyles,...colourStyles}} classNamePrefix="react-select1" options={jobTitles} name="user_timezone" placeholder='Select' value={jobTitleValArr} onChange={(e) => setState({...state,jobTitleVal:e.label})}/>  
                      <div className="fv-plugins-message-container">
                      </div>       
                      </div>
                      </div>

                      <div className="form-row">
                      <div className="col-xl-6 col-md-6 form-group ">                        
                        <label>Your level <span className="required-astrik-sing" >*</span></label>                        
                      <Select value={SelectData.jobLevel} isSearchable={false} styles={{dropdownIndicator: dropdownIndicatorStyles,...colourStyles}} classNamePrefix="react-select1" options={jobLevels} placeholder='Select' onChange={(e)=>{setState({...state,jobLevelVal:e.value})}}  />  
                        <div className="fv-plugins-message-container">
                      </div>
                      </div>  

                      <div className="col-xl-6 col-md-6 form-group year-of-experience-field ">                        
                        <label>Years Experience <span className="required-astrik-sing" >*</span></label>
                      <Select value={yearExpValue} isSearchable={false}  styles={{dropdownIndicator: dropdownIndicatorStyles,...colourStyles}} classNamePrefix="react-select1" options={exp_years} placeholder='Select' onChange={(e)=>{setState({...state,yearExp:e.value})}}  />  
                        <div className="fv-plugins-message-container">
                      </div>
                      </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};