/* eslint-disable */ 

// ES6 Modules or TypeScript
import React,{useState} from 'react';
import {Modal } from 'react-bootstrap';
import './alert.css'

export const AlertPopup = ({show,title,message,errType,handleClose}) => {
  return(
          <>  
        <Modal className='error-page' show={show} onHide={() => handleClose()}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert-modal">
                        <div className="modal-alert-icon"> {errType == 'success' ? <img class="cross-img" src="/media/ms-media/meeting-tick-icn.svg" alt=""/> : <img class="cross-img" src="/media/ms-media/meeting-cross-icn.svg" alt=""/>} </div>
                        <p className={ errType == 'success' ? 'msg-success' :'msg-text'} >{message}</p>
                    </div>

                </Modal.Body>
            </Modal>
     </>
)
}



export const ConfirmAlert = (props) => {
 return(
  <Modal className="time_machine-modal-small"
  show={props.data.show} onHide={props.close}
  size="md"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
  <Modal.Body className="text-center">
      <h4 className="modal-sm-title">{props.data.title}</h4>
      <img src="/media/ms-media/wait-icn.svg" alt=""/>
      <p>{props.data.description}</p>
      <div className="modal_btns d-flex align-items-center">
          <button className="btn btn-bordred flex-grow-1" onClick={props.close}>No</button>
          <button className="btn btn-primary flex-grow-1" onClick={props.save}>Yes</button>
      </div>
  </Modal.Body>
</Modal>
 ) 
}


export function ShowCookieFail(){
  
const [show,setShow] = useState(true);
const handleClose = () => setShow(false);

const [activeTab,setActive] = useState(1)

const handleTab = e => {
   const {id} = e.target

   if(id == 'profile-tab'){
        setActive(2)
   }else if (id == 'home-tab')
      setActive(1)
}

  return(
  <> 
    <Modal className="cookie-modal" size='lg' show={show} onHide={handleClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered>
        <Modal.Header closeButton>
            <Modal.Title>Hi there, MeetingScience requires cookies!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="alert-modal">
              <div className="w3-section">    
              
                <div className="centerArea" >
                  <div className="cookie-loader "><img src="/media/ms-media/loading.gif" width="200"  alt="Logo" /></div> 
                    <div className="logo" >
                        <img src="/media/logos/meeting-science-logo-2.svg" width="200"  alt="Logo" /> 
                    </div>    
                    <div className="textblock" > 
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="text-justify pt-3">
                                        Hi, unfortunately, MeetingScience won't work in incognito or private mode (or equivalent). To enable cookies to enable MeetingScience, please follow these steps (in Chrome):
                                    </p>
                                </div>
                            </div>
                      <div className="cookies-tabs">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                            <a className={ activeTab == 1 ? 'nav-link active' : "nav-link"} onClick={(e) => handleTab(e)} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"><img src="/media/cookie-popup/chrome-icn-11.jpg" /> Chrome Browser</a>
                          </li>
                          <li className="nav-item" role="presentation">
                            <a className={ activeTab == 2 ? 'nav-link active' : "nav-link"} onClick={(e)  => handleTab(e)} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><img src="/media/cookie-popup/chrome-icn-11.jpg" /> Chrome Browser <strong>(Alternate)</strong></a>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div className={activeTab == 1 ? "tab-pane fade show active" : "tab-pane"} id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="row align-items-center">                              
                              <div className="col-lg-6">
                                <div className="cookies-content d-flex">
                                  <span>1.</span>
                                  <p>In your Chrome browser, at the top right, click on the button to the right of the Incognito icon that looks like 3 vertical buttons on the shirt:</p>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="cookies-img"><img src="/media/cookie-popup/cookies-img-1.jpg" /></div>
                              </div>                              
                            </div>
                            <div className="row align-items-center">                              
                              <div className="col-lg-6">
                                <div className="cookies-content d-flex">
                                  <span>2.</span>
                                  <p>Click on Settings.</p>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-first text-lg-right">
                                <div className="cookies-img icnright"><img src="/media/cookie-popup/cookies-img-2.jpg" /></div>
                              </div>                              
                            </div>
                            <div className="row align-items-center">                              
                              <div className="col-lg-6">
                                <div className="cookies-content d-flex">
                                  <span>3.</span>
                                  <p>Click on 'Privacy and security' in the Settings menu.</p>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="cookies-img"><img src="/media/cookie-popup/cookies-img-3.jpg" /></div>
                              </div>                              
                            </div>                            
                            <div className="row align-items-center">                              
                              <div className="col-lg-6">
                                <div className="cookies-content d-flex">
                                  <span>4.</span>
                                  <p>Click on 'Cookies and other site data' in the 'Privacy and security menu.</p>
                                </div>
                              </div>
                              <div className="col-lg-6 order-lg-first text-lg-right">
                                <div className="cookies-img icnright"><img src="/media/cookie-popup/cookies-img-4.jpg" /></div>
                              </div>                              
                            </div>
                            
                            <div className="row align-items-center">                              
                              <div className="col-lg-6">
                                <div className="cookies-content d-flex">
                                  <span>5.</span>
                                  <p>Click on 'Allow all cookies' in the 'Cookies and other site data' menu.
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="cookies-img"><img src="/media/cookie-popup/cookies-img-5.jpg"/></div>
                              </div>                              
                            </div> 
                          
                          
                          </div>
                          <div className={activeTab == 2 ? "tab-pane fade show active" : "tab-pane"} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                          
                            <div className="row align-items-center">                              
                                <div className="col-lg-6">
                                  <div className="cookies-content d-flex">
                                    <span>1.</span>
                                    <p>In Chrome, in the top right of the Address bar, click on the icon for Third-Party cookie blocking (it looks like an eye with a slash through it).</p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="cookies-img"><img src="/media/cookie-popup/cookies-img-6.jpg" /></div>
                                </div>                              
                              </div>
                              <div className="row align-items-center">                              
                                <div className="col-lg-6">
                                  <div className="cookies-content d-flex">
                                    <span>2.</span>
                                    <p>Click on the 'Site not working?' link.</p>
                                  </div>
                                </div>
                                <div className="col-lg-6 order-lg-first text-lg-right">
                                  <div className="cookies-img icnright"><img src="/media/cookie-popup/cookies-img-7.jpg" /></div>
                                </div>                              
                              </div>
                              <div className="row align-items-center">                              
                                <div className="col-lg-6">
                                  <div className="cookies-content d-flex">
                                    <span>3.</span>
                                    <p>Click on 'Allow cookies.'</p>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="cookies-img"><img src="/media/cookie-popup/cookies-img-8.jpg" /></div>
                                </div>                              
                              </div> 
                          
                          </div>
                        </div>
                      </div>
                            <div className="row d-none">
                                <div className="col-md-12">
                                    <h5>1.Chrome Browser:</h5>
                                </div>
                                <div className="col-md-6">
                                    <p>
                                    1. In Google Chrome browser, at the top right click on Nav Bar.
                                    </p>
                                    <div className="img-responsive">
                                        <img className="cokies_popup_img" src="/media/cookie-popup/setp_cr1.jpg" />
                                    </div>
                                    <p>
                                    2. Than click on Settings.
                                    </p>
                                    <div className="img-responsive">
                                      <img className="cokies_popup_img" src="/media/cookie-popup/setp_cr2.jpg"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    3. Than click on Privacy and security under Settings menu.
                                    </p>
                                    <div className="img-responsive ccokies_popup_img">
                                      <img className="cokies_popup_img" src="/media/cookie-popup/setp_cr3.jpg"  />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    4. Than click on Cookies and other site data under Privacy and security menu.
                                    </p>
                                    <div className="img-responsive ccokies_popup_img">
                                      <img className="cokies_popup_img" src="/media/cookie-popup/setp_cr4.jpg" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    5. Than click on Allow all cookies under Cookies and other site data menu.
                                    </p>
                                    <div className="img-responsive ccokies_popup_img">
                                      <img className="cokies_popup_img" src="/media/cookie-popup/setp_cr5.jpg"  /> 
                                    </div>
                                </div>
                                
                            </div> 
                            <div className="row d-none">
                                <div className="col-md-12">
                                    <h5>2.Chrome Browser(Alternate):</h5>
                                </div>
                                <div className="col-md-6">
                                    <p>
                                    1. In Google Chrome browser, at the top right on Address bar, click on Third Party cookie blocking.
                                    </p>
                                    <div className="img-responsive">
                                        <img className="cokies_popup_img" src="/media/cookie-popup/browser.png" />
                                    </div>
                                    <p>
                                    2. Than click on Site not Working?.
                                    </p>
                                    <div className="img-responsive">
                                      <img className="cokies_popup_img" src="/media/cookie-popup/browse1.jpg" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    3. Than click on ALlow Cookies.
                                    </p>
                                    <div className="img-responsive ccokies_popup_img">
                                      <img className="cokies_popup_img" src="/media/cookie-popup/browse2.jpg" />
                                    </div>
                                </div>
                                
                            </div>   
                    </div> 
                </div>  
                <input className="currentEmail" name="currentEmail" type="hidden" />  
              </div>  
            </div>
            <div className="close-btn-footer">
              <button onClick={() => handleClose()} className="btn btn-close p-0 ml-auto">Close</button>
            </div>
          </Modal.Body>
        </Modal>
     </>
)
}
  