import { put, takeLatest } from 'redux-saga/effects';
import { ERROR_GET_UNRATED_MEETINGS_COUNT, ERROR_GET_UNRATED_MEETINGS_DETAIL, ERROR_SEND_UNRATED_MEETINGS_DETAIL, getUnratedMeetingsCountResponse, getUnratedMeetingsDetailResponse, GET_UNRATED_MEETINGS_COUNT, GET_UNRATED_MEETINGS_DETAIL, sendUnratedMeetingDetailResponse, SEND_UNRATED_MEETINGS_DETAIL, SUCCESS_GET_UNRATED_MEETINGS_COUNT, SUCCESS_GET_UNRATED_MEETINGS_DETAIL, SUCCESS_SEND_UNRATED_MEETINGS_DETAIL } from './unrated.action';
import { getUnratedMeetingsApi, sendUnratedMeetingDataApi, getUnratedMeetingsCountApi } from './unrated.api';
import _ from 'lodash';
import { sendApiError } from '../../../../app/modules/Auth/_redux/authCrud';
import { Apistore } from '../../../apiStore';

// Get Unrated Meetings Info Detail
function* getUnratedMeetingsRequest(data) {
    let getData = yield getUnratedMeetingsApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(getUnratedMeetingsDetailResponse(SUCCESS_GET_UNRATED_MEETINGS_DETAIL, getData.data));
    } else {
        yield put(getUnratedMeetingsDetailResponse(ERROR_GET_UNRATED_MEETINGS_DETAIL, getData.data));
        sendApiError(500,getData.data,Apistore.GetUnratedEvents);
    }
}

export function* getUnratedMeetingsWatcher() {
    yield takeLatest(GET_UNRATED_MEETINGS_DETAIL, getUnratedMeetingsRequest);
}
// Send unrated Meetings Detail
function* sendUnratedMeetingsRequest(data) {
    let getData = yield sendUnratedMeetingDataApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(sendUnratedMeetingDetailResponse(SUCCESS_SEND_UNRATED_MEETINGS_DETAIL, getData.data));
    } else {
        yield put(sendUnratedMeetingDetailResponse(ERROR_SEND_UNRATED_MEETINGS_DETAIL, getData.data));
        sendApiError(500,getData.data,Apistore.SendUnratedMeetingData);
    }
}

export function* sendUnratedMeetingsWatcher() {
    yield takeLatest(SEND_UNRATED_MEETINGS_DETAIL, sendUnratedMeetingsRequest);
}

// Get unrated Meetings Count Detail
function* getUnratedMeetingsCountRequest(data) {
    let getData = yield getUnratedMeetingsCountApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(getUnratedMeetingsCountResponse(SUCCESS_GET_UNRATED_MEETINGS_COUNT, getData.data));
    } else {
        yield put(getUnratedMeetingsCountResponse(ERROR_GET_UNRATED_MEETINGS_COUNT, getData.data));
        sendApiError(500,getData.data,Apistore.GetUnratedCount);
    }
}

export function* getUnratedMeetingsCountWatcher() {
    yield takeLatest(GET_UNRATED_MEETINGS_COUNT, getUnratedMeetingsCountRequest);
}