import { ERROR_LIST_ATTENDED_MEETINGS_DETAIL, ERROR_LIST_ORGANIZED_MEETINGS_DETAIL, ERROR_SAVE_ORGANIZED_MEETINGS_DETAIL, ERROR_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME, SUCCESS_LIST_ATTENDED_MEETINGS_DETAIL, SUCCESS_LIST_ORGANIZED_MEETINGS_DETAIL, SUCCESS_SAVE_ORGANIZED_MEETINGS_DETAIL, SUCCESS_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME } from "./timeMachine.action";
import { DEFAULT_STATE } from "./timeMachine.state";

export const timeMachineReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case SUCCESS_LIST_ORGANIZED_MEETINGS_DETAIL:
            const listOrganizedMeetingsData = action.data;
            return { ...state, listOrganizedMeetingsData }
        case ERROR_LIST_ORGANIZED_MEETINGS_DETAIL:
            const errorListOrganizedMeetingsData = action.data;
            return { ...state, listOrganizedMeetingsData: errorListOrganizedMeetingsData }
        case SUCCESS_LIST_ATTENDED_MEETINGS_DETAIL:
            const listAttendedMeetingsData = action.data;
            return { ...state, listAttendedMeetingsData }
        case ERROR_LIST_ATTENDED_MEETINGS_DETAIL:
            const errorListAttendedMeetingsData = action.data;
            return { ...state, listAttendedMeetingsData: errorListAttendedMeetingsData }
        case SUCCESS_SAVE_ORGANIZED_MEETINGS_DETAIL:
            const saveOrganizedMeetingsData = action.data;
            return { ...state, saveOrganizedMeetingsData }
        case ERROR_SAVE_ORGANIZED_MEETINGS_DETAIL:
            const errorSaveOrganizedMeetingsData = action.data;
            return { ...state, saveOrganizedMeetingsData: errorSaveOrganizedMeetingsData }
        case SUCCESS_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME:
            const sendAttendedMeetingsProposalTimeData = action.data;
            return { ...state, sendAttendedMeetingsProposalTimeData }
        case ERROR_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME:
            const errorSendAttendedMeetingsProposalTimeData = action.data;
            return { ...state, sendAttendedMeetingsProposalTimeData: errorSendAttendedMeetingsProposalTimeData }
        default:
            return state;
    }
}