/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */

import React, { useEffect, useState } from "react";
import "../../_metronic/_assets/sass/pages/login/classic/sign-in.scss";
import ReactLoginMS from "react-ms-login";
import { getLastLocation, getStorage, setStorage } from "../../_metronic/_helpers";
import { connect, useDispatch, useSelector, shallowEqual } from "react-redux";
import * as auth from "../modules/Auth/_redux/authRedux";
import { Link, useHistory, useLocation } from "react-router-dom";
// import ApiCalendar from "react-google-calendar-api";
import moment from "moment";
import {
  logActivity,
  sendApiError,
  tryLogin,
} from "../modules/Auth/_redux/authCrud";
import { AlertPopup, ShowCookieFail } from "../../_metronic/_helpers/Alerts";
import {
  authTimezone,
  checkCookie,
  clearAllStorage,
  setZoomStatus,
} from "../../_metronic/_helpers/functions";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { Apistore } from "../../redux/apiStore";
import GoogleLoginButton from "./GoogleLoginButton";
import { Modal } from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google";

function LoginPage(props) {
  const [alert, setAlert] = useState({
    show: null,
    title: null,
    message: null,
    errType: null,
  });
  const [redirection, setRedirection] = useState(false);
  const handleClose = () => {
    setAlert({
      show: false,
    });
  };

  const minDate = moment()
    .subtract(14, "d")
    .format();
  const maxDate = moment()
    .add(7, "d")
    .format();

  const { authToken, isRegistered, isAllowedLogin } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
      isRegistered: auth.isRegistered,
      isAllowedLogin: auth.isAllowedLogin,
    }),
    shallowEqual
  );

  const { getLocalStorageUserData } = useSelector((state) => state.profile);

  const [cookieDisabled, setCookieDisabled] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [hoverMSColor, setHoverMSColor] = useState({background:'',borderColor:''})
  const [loading, setLoading] = useState(false);
  const [confirmAlertOptions, setConfirmAlertOptions] = useState({
    show: null,
    title: "Welcome Back",
    description:"You have previously chosen to remove your account credentials and information from MeetingScience. Did you mean to sign-up again?",
    access_token:''
  });
  //initial state

  async function fetchMicrosoftEventData(accessToken) {
    const res = await fetch(
      `https://graph.microsoft.com/v1.0/me/calendar/calendarView?startDateTime=${moment(
        minDate
      ).format("YYYY-MM-DD")}&endDateTime=${moment(maxDate).format(
        "YYYY-MM-DD"
      )}&$top=50&orderBy=createdDateTime DESC`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // Prefer:'outlook.timezone'
        },
      }
    );
    return res.json();
  }

//   const updateUserProfileImage = useSelector(state => state.profile.saveUserProfileImageData);


//   useEffect(() => {
//     if(updateUserProfileImage &&  updateUserProfileImage.status == 'success'){
//         dispatch(getLocalStorageUserDetail({  }))
//         dispatch(getProfileInfoDetail({}))
//     }
// },[updateUserProfileImage])

  // const getMicrosoftEvents = (accessToken,serviceProvider,user_id) => {
  //     fetchMicrosoftEventData(accessToken).then(response => {
  //         const {value} = response
  //         if(value.length){
  //                           setStorage('EVENT_COUNT',value.length);
  //                           //runApiCalculations(serviceProvider,user_id)
  //                           history.push('/signup-page1');
  //                           //handleCalendarEvents(value,serviceProvider);

  //         }else {

  //         setLoading(false);
  //         setAlert({
  //         show:true,
  //         message:"Hi, it doesn't look like you use this calendar to manage meetings as you don't have any meetings in the past 60 days. Please revisit the application when you're using this calendar to create or attend meetings,thanks!",
  //         title:'No Events',
  //         errType:"failure"
  //       })
  //     }
  //   })
  //     .catch((error) => {
  //       sendApiError(500,error,'fetch_microsoft_events');
  //     });
  // }

  // const getGoogleEvents = (serviceProvider,user_id) => {

  //   return  ApiCalendar.listEvents({
  //            timeMin: minDate,
  //            timeMax: maxDate,
  //            showDeleted: false,
  //            maxResults: 50,
  //            orderBy: 'updated'
  //        }).then(( response) => {
  //                 const {items} = response.result;
  //                 if(items.length){
  //                   setStorage('EVENT_COUNT',items.length);
  //                   //runApiCalculations(serviceProvider,user_id);
  //                   history.push('/signup-page1');
  //                   //handleCalendarEvents(items,serviceProvider);
  //                 }else {
  //                   setLoading(false);
  //                   setAlert({
  //                     show:true,
  //                     message:"Hi, it doesn't look like you use this calendar to manage meetings as you don't have any meetings in the past 60 days. Please revisit the application when you're using this calendar to create or attend meetings,thanks!",
  //                     title:'No Events',
  //                     errType:"failure"
  //                   })
  //                 }

  //        }).catch((error) =>{
  //           getGoogleEvents(serviceProvider,user_id);
  //        });
  // }

  const handleAuthentication = (authCode, serviceProvider, masterToken) => {
    // fetchBearerToken(process.env.REACT_APP_LOGIN_EMAIL,process.env.REACT_APP_LOGIN_PASSWORD)
    // .then((res) => {
    //   if(res.status){
    // const userVerifiedToken = null;
    //  const userVerifiedToken = Apistore.BearerTokenUrl
    //       setStorage('ACCESS_TOKEN',userVerifiedToken);
    // dispatch(props.login(userVerifiedToken, "false", "false")); // access_token
    if(masterToken){
      dispatch(props.login(masterToken, "true", "true")); // access_token
  
      }
    checkUserAccess(authCode, serviceProvider, masterToken);
    logActivity("landing_page", "/");
    //   }
    // }).catch((err) => {
    //   setLoading(false);
    //   setAlert({
    //     show:true,
    //     message:"You encountered a network error, there might be an issue on the internet, please try again",
    //     title:'Error',
    //     errType:"failure"
    //   })
    //   sendApiError(500,err,Apistore.BearerTokenUrl)
    // })
  };

  const checkUserAccess = (
    authCode,
    serviceProvider,
    masterToken
  ) => {

    tryLogin(authCode, serviceProvider, masterToken)
      .then((res) => {
        setLoading(false);
        const {
          code,
          open_popup,
          redirect,
          access_token,
          popup_message,
          firstName,
          company_id,
          message,
          lastName,
          timezone,
          email,
          is_recomm_present,
          welcome_back,
        } = res.data;
        setStorage("ACCESS_TOKEN", access_token);
        setStorage("WELCOME_BACK", welcome_back);

        // dispatch(props.login(access_token, "false", "false")); // access_token

    if (welcome_back == 1 && code == 200 && redirect !== "signup") {
      setConfirmAlertOptions({
          ...confirmAlertOptions,
          show: true,
          access_token:access_token
      });

      setStorage("COMPANY_ID", window.btoa(company_id));
      setStorage("USER_NAME", window.btoa(firstName));
      setStorage("USER_LASTNAME", window.btoa(lastName));
      setStorage("USER_EMAIL", window.btoa(email));
      setStorage("PROVIDER", serviceProvider);
      setStorage("TIMEZONE", timezone);
      setStorage("MCR", is_recomm_present);
      setStorage("FRESH_SIGN_IN", 1);
      setStorage("VISIT_TIME", moment());

    } else {
          switch (code) {
            case 403:
              {
                if (open_popup == true) {
                  setAlert({
                    show: true,
                    message: popup_message,
                    title: "Signin not allowed",
                    errType: "failure",
                  });
                  clearAllStorage();
                } else if (open_popup == false) {
                  setAlert({
                  show: true,
                  message: message,
                  title: "Invalid code",
                  errType: "failure",
                });
                clearAllStorage();
              }
            }
            break;
            case 200:
            {
              // setStorage('USER_ID',window.btoa(user_id));
              setStorage("COMPANY_ID", window.btoa(company_id));
              setStorage("USER_NAME", window.btoa(firstName));
              setStorage("USER_LASTNAME", window.btoa(lastName));
              setStorage("USER_EMAIL", window.btoa(email));
              setStorage("PROVIDER", serviceProvider);
              setStorage("TIMEZONE", timezone);
              setStorage("MCR", is_recomm_present);
              setStorage("FRESH_SIGN_IN", 1);
              setStorage("VISIT_TIME", moment());
              if (redirect == "signup") {
                setStorage(
                  "SIGNUP_START_TIME",
                  moment().format("YYYY-MM-DD hh:mm:ss")
                  );
                  setStorage("IS_NEW_USER", 1);
                  dispatch(props.startSignup(1));
                  dispatch(props.login(access_token, "false", "true")); // access_token , isRegistered, isAllowedLogin
                  history.push("/signup-page1");
                } else if (redirect == "dashboard") {
                  if(masterToken){
                    dispatch(props.login(masterToken, "true", "true")); // access_token
                  }else{
                    dispatch(props.login(access_token, "true", "true")); // access_token , isRegistered, isAllowedLogin
                  }
                  // dispatch({type:GET_LOCALSTORAGE_USER_DETAIL,data:{}})                  // eslint-disable-next-line react-hooks/exhaustive-deps  
                  setTimeout(() => {
                    history.push("/attended");
                  }, 2000);
                  // pass id as param
                  
                  // dispatch(getProfileInfoDetail({}));
                  
                } else if (redirect == "meeting-mastery") {
                  if(masterToken){
                    dispatch(props.login(masterToken, "true", "true")); // access_token
                  }else{
                    dispatch(props.login(access_token, "true", "true")); // access_token , isRegistered, isAllowedLogin                      
                  }
                  // dispatch({type:GET_LOCALSTORAGE_USER_DETAIL,data:{}})                  // eslint-disable-next-line react-hooks/exhaustive-deps  
                  
                  history.push(getLastLocation().pathname);   
                  
                  
                }
              }
              break;
              case 500:
                setAlert({
                  show: true,
                  message:
                  "You encountered a network error, there might be an issue on the internet, please try again",
                  title: "Error",
                  errType: "failure",
                });
                
                default:
                  return 0;
                }
              }
              })
              .catch((err) => {
                setLoading(false);

        if (serviceProvider == 1) {
          sendApiError(500, err, Apistore.GoogleLoginURL);
        } else if (serviceProvider == 2) {
          sendApiError(500, err, Apistore.MicrosoftLoginURL);
        } else if (serviceProvider == null) {
          sendApiError(500, err, Apistore.MasterLogin);
        }

        setAlert({
          show: true,
          message:
            "You encountered a network error, there might be an issue on the internet, please try again",
          title: "Error",
          errType: "failure",
        });
      });
  };

  //  response from sign in with google
  const responseGoogle = (response) => {
    setLoading(true);
    const authCode = response;
    const query = new URLSearchParams(location.search);
    const masterToken = query.get("adminUserToken");
    if (response.error) {
      setLoading(false);
      if (response.error == "idpiframe_initialization_failed") {
        if (
          response.details ==
            "Cookies are not enabled in current environment." &&
          !masterToken
        ) {
          setCookieDisabled(true);
        }
      }
    }
    if (authCode) {
      handleAuthentication(authCode, 1);
    }
  };

  // response f mmrom sign in with msft
  const responseMicrosoft = (data) => {
    setLoading(true);
    handleAuthentication(data.code, 2);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const masterToken = query.get("adminUserToken");
    const isCookieEnabled = checkCookie();
    if (!isCookieEnabled && !masterToken) {
      setCookieDisabled(true);
    }
  }, []);

  useEffect(() => {
    if (location && location.search) {
      const query = new URLSearchParams(location.search);
      const status = query.get("zoom_status");
      const masterToken = query.get("adminUserToken");

      if (status === "success") {
        setAlert({
          show: true,
          message:
            "Welcome! Thanks for adding the MeetingScience Zoom App. Now you can request and give meeting feedback directly in Zoom. We've done all of the work for you. Simply launch the poll in your meeting and we'll take care of the rest.",
          title: "Zoom App",
          errType: "success",
        });
        setZoomStatus(1);
      } else if (status === "fail") {
        setAlert({
          show: true,
          message:
            "Sorry! Due to a technical issue You can not install MeetingScience Zoom App Now. We will fix it soon. Please try again after some time.",
          title: "Zoom App",
          errType: "failure",
        });
        setZoomStatus(0);
      }
                                                                                                                                                                  
      if (masterToken) {
        setLoading(true);
        handleAuthentication(null, null, masterToken);
      }
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(props.startSignup(0));
    dispatch(props.fulfillUser(0));
    dispatch(props.eventPullCompleted(0));
    dispatch(props.masteryCompleted(0));
    dispatch(props.scoreCompleted(0));
    dispatch(props.recommendationCompleted(0));
    dispatch(props.updateRecommendation(0));
  }, []);

  useEffect(() => {
    if (
      location.search &&
      alert.show === false &&
      authToken &&
      isAllowedLogin &&
      isRegistered
    ) {
      setRedirection(true);
    } else {
      setRedirection(false);
    }
  }, [alert.show]);

  useEffect(() => {
    if (redirection === true) {
      getLocalStorageUserData &&
      getLocalStorageUserData.redirect == "meeting-mastery"
        ? history.push("/meeting-mastery")
        : location?.pathname?.length>1 ? history.push(location?.pathname) : history.push("/attended");
    }
  }, [redirection]);

  useEffect(() => {
    if (
      !location.search &&
      authToken &&
      isAllowedLogin == "true" &&
      isRegistered == "true"
    ) {
      getLocalStorageUserData &&
      getLocalStorageUserData.redirect == "meeting-mastery"
        ? history.push("/meeting-mastery")
        : location?.pathname?.length >1 ? history.push(location?.pathname) : history.push("/attended");
    }
  }, []);

  const handelClick =(confirmation)=>{
    if (confirmation) {
      setConfirmAlertOptions({
        ...confirmAlertOptions,
        show:false
      })
      setLoading(true)
      setTimeout(() => {
        dispatch(props.login(confirmAlertOptions.access_token, "true", "true")); // access_token , isRegistered, isAllowedLogin    
        history.push(getLastLocation().pathname);
      }, 2000);
    }else{
      try {
        window.google.accounts.id.revoke(window.atob(getStorage('USER_EMAIL')), done => {
          console.log('consent revoked');
          window.location.reload(true)
        });
      } catch (error) {
        window.location.reload(true)
        console.log(error);
      }
    }
  }

  return (
    <>
      <div style={{visibility:'hidden',height:'0px'}}>
        <GoogleLogin
          onSuccess={credentialResponse => {
          console.log(credentialResponse);
          }}
          onError={() => {
          console.log('Login Failed');
        }}
        />
      </div>
      {/* Sign In */}
      <Modal className="time_machine-modal-small"
        show={confirmAlertOptions.show} 
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body className="text-center">
          <h4 className="modal-sm-title">{confirmAlertOptions.title}</h4>
          <p> {confirmAlertOptions.description} </p>
          <div className="modal_btns d-flex align-items-center">
            <button className="btn flex-grow-1 yes_delete" onClick={()=>handelClick(0)} >No, I do not want to sign-up again</button>
            <button className="btn flex-grow-1" style={{color:'#008a99'}} onClick={()=>handelClick(1)} >Yes I want to sign-up again</button>
          </div>
          </Modal.Body>
      </Modal>

      {<LayoutSplashScreen visible={loading} />}
      {cookieDisabled && <ShowCookieFail />}
      {alert.show && (
        <AlertPopup
          show={alert.show}
          handleClose={handleClose}
          title={alert.title}
          message={alert.message}
          errType={alert.errType}
        />
      )}

      <div className="auth-container sign-in-pg row m-0">
        <div className="left_col col-xl-5 col-lg-6">
          <div className="auth_section">
            <div className="auth_logo">
              <Link to='/'>
                <img
                height='50px'
                  src="/media/logos/meetingscience-logo-mobile1.png"
                  alt="Meeting Science"
                />
              </Link>
            </div>
            <div className="auth_content">
              <div className="auth_title">
                Welcome to <span>MeetingScience!</span>
              </div>
              <p>
                Please note that as of this time, we optimized MeetingScience
                for use with Chrome (preferred) or Safari. Thank you for saving
                humanity from meeting insanity. <strong>Let's go!</strong>
              </p>
              <div className="auth_buttons">
                <GoogleLoginButton responseGoogle={responseGoogle} />
                <span>{<LayoutSplashScreen visible={loading} />}</span>

                <div  className="btn-container">
                  <div className="btn btn-auth">
                    <img
                      src="/media/logos/microsoft-icon.svg"
                      alt="Microsoft"
                    />{" "}
                    Sign In With Microsoft
                  </div>
                  <ReactLoginMS
                    clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID} // required: 'application id/client id' obtained from https://apps.dev.microsoft.com for your app
                    redirectUri={process.env.REACT_APP_MICROSOFT_REDIRECT_URI} // required: redirectUri registered in https://apps.dev.microsoft.com for your app
                    scopes={[process.env.REACT_APP_MICROSOFT_SCOPES]} //optional: defaults to "user.read" full list is present https://developer.microsoft.com/en-us/graph/docs/concepts/permissions_reference
                    responseType={process.env.REACT_APP_MICROSOFT_RESPONSE_TYPE} //optional: valid values are "token" for `Implicite OAuth flow` and "code" for `Authorization Code flow` defaults to "token"
                    handleLogin={(data) => responseMicrosoft(data)} // required: callback to receive token/code after successful login
                    // prompt="consent" is set into file nodemodules/react-ms-login/dist/react-ms-login.js
                  />
                </div>
              </div>
            </div>
            <div className="auth_note">
              <img src="/media/ms-media/note-icon.svg" alt="note" />
              Please enable pop-ups as sign-up requires you to authenticate your
              login credentials with Google or Microsoft and both use pop-ups to
              do so.
            </div>
          </div>
        </div>
        <div className="right_col col-xl-7 col-lg-6">
          <div className="step_view">
            <h1>
              Elevate your meeting fitness like teams from these companies
            </h1>
            <div className="step_view-img">
              <img src="/media/ms-media/sign-in-img.png" alt="note" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(null, auth.actions)(LoginPage);
