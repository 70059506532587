/* eslint-disable */ 
/* eslint-disable eqeqeq, no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from 'react-apexcharts'
import { Spinner } from "react-bootstrap";
import { FilterRange } from "../../layout/components/header/FilterLabel";
import { authUserId, countNoOfDays } from "../../_helpers/functions";

export function WhoseTimeDoIRequestTheMost({ className }) {

  const {organized,status} = useSelector(state => state.organized)  
  const {timePeriod} = useSelector(state => state.picker)
  const [state,setState] = useState({
    series:[],
    options: {
      states: {

        hover: {
            filter: {
                type: 'none',

            }
        }
    },
      grid: {
        show: true,
        borderColor: '#f8f9fb',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
            lines: {
                show: true
            }
        },   
        yaxis: {
            lines: {
                show: true
            }
        },  
        row: {
            colors: undefined,
            opacity: 0.5
        },  
        column: {
            colors: undefined,
            opacity: 0.5
        },  
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        },  
    },
      noData: {
        text: undefined,
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      },
      title: {
        text: 'Attendees',
        align: 'left',
        style: {
          fontSize: '12px',
          color: '#BFC2C7'
        },
      },
      dataLabels:{
        enabled:true,
        style: {
          colors: ['#000']
      },
      formatter: function(val, opt) {
       return getPercent(val)
    },
      offsetX: 45
      },
      legends:{
        show:false
      },
       
      chart: {
        type: 'bar',
        stacked: false,
        toolbar: {
          show: true,
          tools:{
            download:true // <== line to add
          }
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top'
          }
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      yaxis:{
        min:0,
        labels: {
          formatter: function(val) {
            return val
          }
        },
        title: { 
          text: '',
          style: {
            fontSize:  '12px',
            fontWeight:  'normal',
            fontFamily:  undefined,
            color:  '#ddd'
          },
        }
      },
      xaxis: {
        categories:[],
        labels: {
          formatter: function(val) {
            return val.toFixed(1);
          }
        },
        title: { 
          text: 'hrs',
          style: {
            fontSize:  '13px',
            fontWeight:  '500',
            fontFamily:  undefined,
            color:  '#7b7b7bf0'
          },
        }
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontWeight: 'normal',
        },
        y: {
          formatter: function(val) {
            return (val) + " hr";
          },
          title: {
            formatter: (seriesName) => '',
        },
        },
        x:{
          show: true,
        },
        marker: {
          show: false,
        },
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "top",
        show:false,
        horizontalAlign: "left",
        offsetX: 40
      },
      colors: [function({ value, seriesIndex, w }) {
     if(value <= 1) {
         return '#D3F5F9'
     } else if (value>1 && value <= 2) {
         return '#8FE0E9'
     }else if (value>2 && value <= 3) {
       return '#4ACBDA'
   }
   else if (value>3 && value <= 4) {
     return '#00ACBF'
 }
 else if (value>4 && value <= 5) {
   return '#008E9D'
}
else if (value>5 && value <= 6) {
 return '#00626C'
}else if (value>6 && value <= 7) {
return '#02444C'
}else{
if(value>7){
 return '#02444C'
}

}
   }],
      },
  });


  const getPercent = (num) => {
    
    let total = 8 ;
     
    if(timePeriod.filter == 'today' || timePeriod.filter == 'yesterday'){
        total = 8;
    }else{
        
        let date1 = new Date(timePeriod.sdate);
        let date2 = new Date(timePeriod.edate);
        // console.log(timePeriod.filter)
        let noOfDays = countNoOfDays(date1,date2);
        
          total = noOfDays*8;
        //   console.log('value',num);
        //   console.log('total',total);
        //   console.log('percentage',((num/total)*100).toFixed(0));
        //   console.log('--------------end----------')
        }        
    return ((num/total)*100).toFixed(1) + ' %';
  }

// const calcData  = (arrLength,eventDuration,index) => {

//   let tempData = [];

//   for(let i = 0; i < arrLength ; i++){
//     tempData.push(0);
//   }

// tempData[index] = eventDuration;

// return tempData;
// }
//   const getNamesAndRender = (data) => {


//       console.log(data.length);
//       console.log(data);


// console.log(finalData);
// console.log(categories);

//   }


const populateCategories = (data) => {


  let sortedData =  data.sort( function ( a, b ) { return  b.data - a.data   } );

  let categories = [];
  let series = [{
    data:[]
  }];

  // for (let i = 0; i < data.length ;i++){
  //     categories.push('');
  // }
  // console.log(categories);

  // for (let element in data){

  //   data[element].data.map((i,j) => {
  //     if(i  !== 0  ){
  //       console.log(i);
  //      categories[j] = data[element].name;
  //     }
  // })
  // }
  

  for (let element in sortedData){
    categories.push(sortedData[element].name)
  }

  for (let element in sortedData){
    series[0].data.push(sortedData[element].data)
  }



  // console.log(categories);
  // console.log(series);
  

  setState({
    ...state,
      options:{
        ...state.options,
              xaxis:{
                ...state.options.xaxis,
                  categories:categories
              },
              yaxis:{
               ...state.options.yaxis,
               max:Math.max(...series[0].data) + 10 
              },
              dataLabels:{
                ...state.options.dataLabels,
                formatter: function(val, opt) {
                  return getPercent(val)
                },
            }
      },
    series:series
     
})
}
 
const sortDataInDesc = (data) => {
  let sortedData =  data.sort( function ( a, b ) { return  b.duration - a.duration   } );
  let slicedArr = sortedData.slice(0,10);


  let finalData = [];

  organized && organized.organized_events && organized.organized_events.map(key => {


      for (let data in key.event_users){
          // console.log(key.event_users[data].user_id)

          slicedArr.map((keyToMatch,keyIndex) => {
            if(key.event_users[data].user_id == keyToMatch.user_id){
  
                  let existing_index = finalData.findIndex(i => i.user_id == key.event_users[data].user_id);
                  if(existing_index == -1){
                    finalData.push({
                                    user_id:key.event_users[data].user_id ,
                                    name:key.event_users[data].user_firstname !==null && key.event_users[data].user_firstname !== '' ? key.event_users[data].user_firstname + " " + key.event_users[data].user_lastname : key.event_users[data].user_email,
                                    data:(keyToMatch.duration).toFixed(1)})
                  } 
            }
          })

      }
                if(finalData.length == slicedArr.length){
                  populateCategories(finalData);
                }

  })

}


let user = organized?.userData?.user_id  
useEffect(() => {
  
  let attendeesData = [];

  setState({
    ...state,
      options:{
        ...state.options,
              xaxis:{
                ...state.options.xaxis,
                  categories:[]
              },
              yaxis:{
               ...state.options.yaxis,
               max:10 
              },
      },
    series:[]
     
})

    organized && organized.organized_events_all && organized.organized_events.map(key => {
            
            let accepted_users = key.event_accepted_ids;
            let accepted_ids_arr = accepted_users.split(',');
            let duration = key.event_duration;

              accepted_ids_arr.map(i => {

                    let user_id_index = attendeesData.findIndex(j => j.user_id == i);
                      if(user_id_index !== -1){
                          attendeesData[user_id_index].duration = attendeesData[user_id_index].duration + duration 
                      }else{
                        attendeesData.push({user_id:i,duration:duration})
                      }

              })  
    })

    let filteredData = attendeesData.filter(element => element.user_id != user)
    attendeesData && attendeesData.length && sortDataInDesc(filteredData);



      },[organized]);


  return (
    <>
      <div className={`card card-custom  ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">Whose Time Do I Request The Most?</span>
          </h3>
          <div className="card-subtitle d-flex justify-content-between">
            <h5>Time's percent spent on meetings for each person in relation to working time for the {<FilterRange/>}</h5>
          </div>
        </div>
          <div className="card-body">
            {organized !== null  ?  
              organized.status == 'success' && state.series && state.series[0] && state.series[0].data.length ?
                 <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />     
                 : <div style={{height:'250px'}}>We're sorry, for the time you requested, we don't have any data to share with you.</div>


                 : 
                   ( status !== 'error' ? <div className='loader-container'>
                                 <Spinner animation="border" role="status" >
                                       <span className="visually-hidden"></span>
                                 </Spinner> 
                     </div> 
                     :
                     <div style={{height:'250px'}}>We're sorry, for the time you requested, we don't have any data to share with you.</div>
                     )
          }
       
           </div>
       </div> 
    </>
  );
}
