import axios from 'axios';
import { Apistore } from '../../../apiStore';

export  function fetchCompanyData (data){
   return  axios.post(Apistore.GetCompanyEvents,data) 
}

export function fetchCompanyRatingsData(data) {
   return axios.post(Apistore.GetCompanyRatingAndAttributes, data)
}

export function fetchCompanyDifferences(data) {
   return axios.post(Apistore.GetCompanyKeyDifferences, data)
}

export function fetchCompanyOverallFeedback(data){
   return axios.post(Apistore.GetCompanyAttendeesFeedback, data)
}