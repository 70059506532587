import { RECEIVE_BADGE_SUMMARY, RECEIVE_HIGHEST_LEVEL_ACHEIVED, RECEIVE_MASTERY_LEVELS_LIST, RECEIVE_USER_PROGRESS_SUMMARY, RESET_USER_PROGRESS_STATE } from "./mastery.action";
import { DEFAULT_STATE } from "./mastery.state";

export const meetingMasteryReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case RECEIVE_MASTERY_LEVELS_LIST:
            return {
                ...state,
                mastery_levels:action.data
            }
        case RECEIVE_HIGHEST_LEVEL_ACHEIVED:
            return {
                ...state,
                level_acheived:action.data
            }
        case RECEIVE_BADGE_SUMMARY:
            return {
                ...state,
                badge_summary:action.data
            }
        case RECEIVE_USER_PROGRESS_SUMMARY:
            return {
                ...state,
                user_progress:action.data
            }
        case RESET_USER_PROGRESS_STATE:
            return {
                ...state,
                user_progress:action.data
            }         
        default:
            return state;
    }
}