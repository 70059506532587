import moment from "moment";

export const DEFAULT_STATE = {
    timePeriod:{
        filter:'last_week',
        sdate: moment().subtract(1,'week').startOf('week').format('YYYY-MM-DD'),
        edate: moment().subtract(1,'week').endOf('week').format('YYYY-MM-DD'),
        order:'DESC',
        custom: false
    }
}