import { put, takeLatest } from 'redux-saga/effects';
import { getUserScoreComparisonDataApi, getUserScoreDataApi, getUserScoreGraphDataApi, getUserScoreHowToImproveDataApi } from './score.api';
import { ERROR_GET_USER_SCORE_COMPARISON_DETAIL, ERROR_GET_USER_SCORE_DETAIL, ERROR_GET_USER_SCORE_GRAPH_DETAIL, ERROR_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL, getUserScoreComparisonDetailResponse, getUserScoreDetailResponse, getUserScoreGraphDetailResponse, getUserScoreHowToImproveDetailResponse, GET_USER_SCORE_COMPARISON_DETAIL, GET_USER_SCORE_DETAIL, GET_USER_SCORE_GRAPH_DETAIL, GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL, SUCCESS_GET_USER_SCORE_COMPARISON_DETAIL, SUCCESS_GET_USER_SCORE_DETAIL, SUCCESS_GET_USER_SCORE_GRAPH_DETAIL, SUCCESS_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL } from './score.action';
import _ from 'lodash';
import { sendApiError } from '../../../../app/modules/Auth/_redux/authCrud';
import { Apistore } from '../../../apiStore';

// Get User Score Details
function* getUserScoreDataRequest(data) {
    let getData = yield getUserScoreDataApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(getUserScoreDetailResponse(SUCCESS_GET_USER_SCORE_DETAIL, getData.data));
    } else {
        yield put(getUserScoreDetailResponse(ERROR_GET_USER_SCORE_DETAIL, getData.data));
        sendApiError(500,getData.data,Apistore.GetUserScoreData)
    }
}

export function* getUserScoreDataWatcher() {
    yield takeLatest(GET_USER_SCORE_DETAIL, getUserScoreDataRequest);
}

// Get User Score Comparison Details
function* getUserScoreComparisonDataRequest(data) {
    let getData = yield getUserScoreComparisonDataApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(getUserScoreComparisonDetailResponse(SUCCESS_GET_USER_SCORE_COMPARISON_DETAIL, getData.data));
    } else {
        yield put(getUserScoreComparisonDetailResponse(ERROR_GET_USER_SCORE_COMPARISON_DETAIL, getData.data));
        sendApiError(500,getData.data,Apistore.GetUserScoreComparisonData)
    }
}

export function* getUserScoreComparisonDataWatcher() {
    yield takeLatest(GET_USER_SCORE_COMPARISON_DETAIL, getUserScoreComparisonDataRequest);
}

// Get User Score Graph Details
function* getUserScoreGraphDataRequest(data) {
    let getData = yield getUserScoreGraphDataApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(getUserScoreGraphDetailResponse(SUCCESS_GET_USER_SCORE_GRAPH_DETAIL, getData.data));
    } else {
        yield put(getUserScoreGraphDetailResponse(ERROR_GET_USER_SCORE_GRAPH_DETAIL, getData.data));
        sendApiError(500,getData.data,Apistore.GetUserScoreGraphData)
    }
}

export function* getUserScoreGraphDataWatcher() {
    yield takeLatest(GET_USER_SCORE_GRAPH_DETAIL, getUserScoreGraphDataRequest);
}

// Get User Score Graph Details
function* getUserScoreHowToImproveDataRequest(data) {
    let getData = yield getUserScoreHowToImproveDataApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(getUserScoreHowToImproveDetailResponse(SUCCESS_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL, getData.data));
    } else {
        yield put(getUserScoreHowToImproveDetailResponse(ERROR_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL, getData.data));
        sendApiError(500,getData.data,Apistore.GetUserScoreHowToImproveData)
    }
}

export function* getUserScoreHowToImproveDataWatcher() {
    yield takeLatest(GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL, getUserScoreHowToImproveDataRequest);
}