import {
    SUCCESS_GET_PROFILE_INFO_DETAIL, ERROR_GET_PROFILE_INFO_DETAIL,
    SUCCESS_UPDATE_PROFILE_INFO_DETAIL, ERROR_UPDATE_PROFILE_INFO_DETAIL, SUCCESS_SAVE_USER_PROFILE_IMAGE, ERROR_SAVE_USER_PROFILE_IMAGE, SUCCESS_UPDATE_USER_RELATIONS_DETAIL, ERROR_UPDATE_USER_RELATIONS_DETAIL, SUCCESS_UPDATE_USER_NOTIFICATIONS_DETAIL, ERROR_UPDATE_USER_NOTIFICATIONS_DETAIL, SUCCESS_UPDATE_USER_PRIVACY_DETAIL, ERROR_UPDATE_USER_PRIVACY_DETAIL, SUCCESS_GET_LOCALSTORAGE_USER_DETAIL, ERROR_GET_LOCALSTORAGE_USER_DETAIL, SUCCESS_CHECK_USER_UNIQUE_NICKNAME, ERROR_CHECK_USER_UNIQUE_NICKNAME, SUCCESS_UPDATE_FEEDBACK_SETTINGS, ERROR_UPDATE_FEEDBACK_SETTINGS, ERROR_SEND_OTP, SUCCESS_VERIFY_OTP, ERROR_VERIFY_OTP, SUCCESS_SEND_OTP,RESET_OTP_KEYS, SUCCESS_RESEND_OTP, ERROR_RESEND_OTP, SUCCESS_FETCH_MEETING_MASTERY_NOTIFICATIONS, ERROR_FETCH_MEETING_MASTERY_NOTIFICATIONS,
    SUCCESS_CUSTOM_UPDATE_USER_RELATIONS_DETAIL,ERROR_CUSTOM_UPDATE_USER_RELATIONS_DETAIL, SUCCESS_UPDATE_USER_PHONE_NUMBER, ERROR_UPDATE_USER_PHONE_NUMBER
} from "./userProfile.action";
import { DEFAULT_STATE } from "./userProfile.state";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const userProfileReducer = persistReducer(
    { storage, key: "userData", whitelist: ["getLocalStorageUserData"] },
(state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case SUCCESS_GET_PROFILE_INFO_DETAIL:
            const getProfileInfoDetailData = action.data;
            return { ...state, getProfileInfoDetailData }
        case ERROR_GET_PROFILE_INFO_DETAIL:
            const errorGetProfileInfoDetailData = action.data;
            return { ...state, getProfileInfoDetailData: errorGetProfileInfoDetailData }
//-----------------------------------------------------------------------------------------------------------------------------------            
            case SUCCESS_CUSTOM_UPDATE_USER_RELATIONS_DETAIL:
                const getCustomProfileInfoDetailData = action.data;
                return { ...state, getCustomProfileInfoDetailData }
            case ERROR_CUSTOM_UPDATE_USER_RELATIONS_DETAIL:
                const errorGetCustomProfileInfoDetailData = action.data;
                return { ...state, getCustomProfileInfoDetailData: errorGetCustomProfileInfoDetailData }

//-------------------------------------------------------------------------------------------------------------------------------------


//-----------------------------------------------------------------------------------------------------------------------------------            
            case SUCCESS_UPDATE_USER_PHONE_NUMBER:
                const getUserPhoneNumberDetail = action.data;
                return { ...state, getUserPhoneNumberDetail }
            case ERROR_UPDATE_USER_PHONE_NUMBER:
                const errorGetUserPhoneNumberDetail = action.data;
                return { ...state, getUserPhoneNumberDetail: errorGetUserPhoneNumberDetail }

//-------------------------------------------------------------------------------------------------------------------------------------
        case SUCCESS_UPDATE_PROFILE_INFO_DETAIL:
            const updateProfileInfoDetailData = action.data;
            return { ...state, updateProfileInfoDetailData }
        case ERROR_UPDATE_PROFILE_INFO_DETAIL:
            const errorUpdateProfileInfoDetailData = action.data;
            return { ...state, updateProfileInfoDetailData: errorUpdateProfileInfoDetailData }
        case SUCCESS_SAVE_USER_PROFILE_IMAGE:
            const saveUserProfileImageData = action.data;
            return { ...state, saveUserProfileImageData }
        case ERROR_SAVE_USER_PROFILE_IMAGE:
            const errorSaveUserProfileImageData = action.data;
            return { ...state, saveUserProfileImageData: errorSaveUserProfileImageData }
        case SUCCESS_UPDATE_USER_RELATIONS_DETAIL:
            const updateUserRelationsData = action.data;
            return { ...state, updateUserRelationsData }
        case ERROR_UPDATE_USER_RELATIONS_DETAIL:
            const errorUpdateUserRelationsData = action.data;
            return { ...state, updateUserRelationsData: errorUpdateUserRelationsData }
        case SUCCESS_UPDATE_USER_NOTIFICATIONS_DETAIL:
            const updateUserNotificationsData = action.data;
            return { ...state, updateUserNotificationsData }
        case ERROR_UPDATE_USER_NOTIFICATIONS_DETAIL:
            const errorUpdateUserNotificationsData = action.data;
            return { ...state, updateUserNotificationsData: errorUpdateUserNotificationsData }
        case SUCCESS_UPDATE_FEEDBACK_SETTINGS:
            const updateFeedbackSettingsData = action.data;
            return { ...state, updateFeedbackSettingsData }
        case ERROR_UPDATE_FEEDBACK_SETTINGS:
            const errorUpdateFeedbackSettingsData = action.data;
            return { ...state, updateFeedbackSettingsData: errorUpdateFeedbackSettingsData }
        case SUCCESS_UPDATE_USER_PRIVACY_DETAIL:
            const updateUserPrivacyData = action.data;
            return { ...state, updateUserPrivacyData };
        case ERROR_UPDATE_USER_PRIVACY_DETAIL:
            const errorUpdateUserPrivacyData = action.data;
            return { ...state, updateUserPrivacyData: errorUpdateUserPrivacyData }
        case SUCCESS_CHECK_USER_UNIQUE_NICKNAME:
            const checkUniqueNicknameData = action.data;
            return { ...state, checkUniqueNicknameData };
        case ERROR_CHECK_USER_UNIQUE_NICKNAME:
            const errorcheckUniqueNicknameData = action.data;
            return { ...state, checkUniqueNicknameData: errorcheckUniqueNicknameData };
        case SUCCESS_GET_LOCALSTORAGE_USER_DETAIL:
            const getLocalStorageUserData = action.data;
            return { ...state, getLocalStorageUserData };
        case ERROR_GET_LOCALSTORAGE_USER_DETAIL:
            const errorGetLocalStorageUserData = action.data;
            return { ...state, error: errorGetLocalStorageUserData }
        case SUCCESS_SEND_OTP:
            const succResponse = action.data;
            return { ...state, send_otp_succ:succResponse }    
            case ERROR_SEND_OTP:
            const errorResponse = action.data;
            return { ...state, send_otp_err:errorResponse }    
        case SUCCESS_VERIFY_OTP:
            const successVerification = action.data;
            return { ...state, verify_otp_succ:successVerification }   
        case ERROR_VERIFY_OTP:
            const errorVerification = action.data;
            return { ...state, verify_otp_err:errorVerification }    
            case SUCCESS_RESEND_OTP:
                const successResend = action.data;
                return { ...state, resend_otp_succ:successResend }   
            case ERROR_RESEND_OTP:
                const errorResend = action.data;
                return { ...state, resend_otp_err:errorResend }
            case SUCCESS_FETCH_MEETING_MASTERY_NOTIFICATIONS:
                return { ...state, new_badge_notify: action.data }   
            case ERROR_FETCH_MEETING_MASTERY_NOTIFICATIONS:
                return { ...state, new_badge_notify: action.data }        
        case RESET_OTP_KEYS:
            return{
                ...state,
                send_otp_succ:null,
                verify_otp_succ:null,
                resend_otp_succ:null
            }
        default:
            return state;    
    }
});