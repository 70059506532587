/* eslint-disable */ 

import React, { useEffect, useState } from 'react';
import { Popover, Spinner } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { requestCompanyRatingAttributes } from '../../../redux/duck/dashboard/company/company.action';
import { authUserId } from '../../_helpers/functions';
import { CompanyRatingsSliderComponent } from "../widgets/widget18";

export const CompanyRatings = () => {
    const user_id = authUserId();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        companyRatingsAttendeesValue: '',
        companyRatingsOrganizersValue: '',
    });
    const [positiveAttr, setPositiveAttr] = useState([]);
    const [negetiveAttr, setNegetiveAttr] = useState([]);
    const { companyData, companyRatings } = useSelector(state => state.company);
    const CompanyRatingMarks = [
        {
            value: 0,
            label: 0
        },
        {
            value: data.companyRatingsOrganizersValue,
            label: <span><i className="fas fa-caret-up" style={{ color: "#00626C" }}></i><span>{data.companyRatingsOrganizersValue}</span></span>
        },
        {
            value: 5,
            label: 5
        }
    ];
    const positivePopover = (
        <Popover id="popover-basic" className="mr-4">
            <Popover.Content className="p-2">
                <div className="badge--card">
                    <div className="badge--card-body">
                        {positiveAttr.length > 5 && positiveAttr.slice(5, positiveAttr.length).map((value, key) =>
                            <span key={key} className="label label-lg label-light-primary mr-2 label-inline">{value}</span>
                        )}
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    );

    const negativePopover = (
        <Popover id="popover-basic" className="mr-4">
          <Popover.Content className="p-2">
            <div className="badge--card">
              <div className="badge--card-body">
                        {negetiveAttr.length > 5 && negetiveAttr.slice(5, negetiveAttr.length).map((value, key) =>
                            <span key={key} className="label label-lg label-light-danger mr-2 label-inline">{value}</span>
                        )}
              </div>
            </div>
          </Popover.Content>
        </Popover>
      );
    useEffect(() => {
        setPositiveAttr([]);
        setNegetiveAttr([]);
        if (companyData && companyData.event_ids && companyData.event_ids.length !== 0) {
            const data = { event_ids:companyData.event_ids };
            dispatch(requestCompanyRatingAttributes(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyData && companyData.event_ids]);

    useEffect(() => {

        companyRatings && companyRatings.status === 'success' && companyRatings.organizer.avg_rating !== "" && companyRatings.attendees.avg_rating !== "" &&
            setData({ ...data, companyRatingsOrganizersValue: companyRatings.organizer.avg_rating, companyRatingsAttendeesValue: companyRatings.attendees.avg_rating });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyRatings]);

    useEffect(() => {

        companyRatings && companyRatings.status === 'success' && companyRatings.negative_attributes !== 0 &&
            setNegetiveAttr(companyRatings.negative_attributes);

        companyRatings && companyRatings.status === 'success' && companyRatings.positive_attributes !== 0 &&
            setPositiveAttr(companyRatings.positive_attributes);

    }, [companyRatings])
    return (
        <div className="col-xl-4 col-md-5">
            
        <div className="card mb-4 card-custom">
            {/* Header */}
            <div className="card-header align-items-center border-0">
                      <h3 className="card-title align-items-center d-flex w-100 justify-content-between"><span className="font-weight-bolder text-dark">Company Feedback</span></h3>
            </div>

            {/* Body */}
            <div className="card-body position-relative overflow-hidden">
                <div className="companyRatings-chart">
                    <div className="companyRatings-tilte d-flex align-items-center justify-content-between">
                        <p className="mb-0">Average Mood</p>
                        <div className="color-label-wrap">
                            <div className="color-label"><span className="color_box primary"></span> Organizers</div>
                            <div className="color-label"><span className="color_box primary-light"></span> Attendees</div>
                        </div>
                    </div>
                    <div className="companyRatings-body">
                            {!companyRatings && companyData == null  && <Spinner animation="border" role="status" ><span className="visually-hidden"></span></Spinner>}
                            {companyRatings && companyRatings.status === 'success' && <CompanyRatingsSliderComponent values={data.companyRatingsAttendeesValue} marks={CompanyRatingMarks} />}
                            {companyData && companyData.events && companyData.events.length == 0 && <strong>N/A</strong>}
                    </div>
                </div>
                {/* <table className="table feedback--list mt-3">
                    <tbody>
                        <tr>
                                <td>% of Eligible Meetings Rated {companyRatings && companyRatings.status === 'success' ? "(" + companyRatings.organizer.total_rating + "/" + companyRatings.organizer.total_events + ")" : "(0/0)"} {events && events.length == 0 && <strong>N/A</strong>}</td>
                                <td className="value">
                                    {!companyRatings && !events  && <Spinner animation="border" role="status" ><span className="visually-hidden"></span></Spinner>}
                                    {companyRatings && companyRatings.status === 'error' && 'N/A'}
                                    {companyRatings && companyRatings.status === 'success' && companyRatings.organizer.rating_percentage}
                                </td>
                        </tr>
                        <tr>
                                <td>% of Participants Who Rated {companyRatings && companyRatings.status === 'success' ? "(" + companyRatings.attendees.total_rating + "/" + companyRatings.attendees.total_attendees + ")" : "(0/0)"}  {events && events.length == 0 && <strong>N/A</strong>}  </td>
                                <td className="value">
                                    {!companyRatings && !events  && <Spinner animation="border" role="status" ><span className="visually-hidden"></span></Spinner>}
                                    {companyRatings && companyRatings.status === 'error' && 'N/A'}
                                    {companyRatings && companyRatings.status === 'success' && companyRatings.attendees.rating_percentage}
                                </td>
                        </tr>
                    </tbody>
                </table> */}
                <div className="badge--card mt-4">
                    <div className="badge--card-title">Positive Meeting Attributes</div>
                    <div className="badge--card-body">
                            {positiveAttr.length === 0 && !companyRatings && companyData == null ? <Spinner animation="border" role="status" >
                                <span className="visually-hidden"></span>
                            </Spinner> :
                                null}
                                  { companyData && companyData.events && companyData.events.length == 0 && <strong>N/A</strong>}
                            {companyRatings && companyRatings.status === 'success' && positiveAttr && positiveAttr !== "N/A" ? positiveAttr && positiveAttr.slice(0, 5).map((value, key) => {
                                return (
                                    <span key={key} className="label label-lg label-light-primary mr-2 label-inline">{value}</span>
                                )
                            }) : companyRatings && companyRatings.status === 'success' && <strong>{positiveAttr}</strong>}
                            {companyRatings && companyRatings.status === 'success' && positiveAttr && positiveAttr.length > 5 && <OverlayTrigger trigger="hover" placement={positiveAttr.length <= 20 ?"top" : 'bottom'} overlay={positivePopover}>
                                <span className="label label-lg label-light mr-2 label-inline custm_popover">+ {positiveAttr.length - 5 + ' more'}</span>
                            </OverlayTrigger>}
                    </div>
                </div>
                <div className="badge--card mt-4">
                    <div className="badge--card-title">Negative Meeting Attributes</div>
                    <div className="badge--card-body">
                            {negetiveAttr.length === 0 && !companyRatings && companyData == null ? <Spinner animation="border" role="status" >
                                <span className="visually-hidden"></span>
                            </Spinner> :
                                null}
                                  {companyData && companyData.events && companyData.events.length == 0 && <strong>N/A</strong>}
                            {companyRatings && companyRatings.status === 'success' && negetiveAttr && negetiveAttr !== "N/A" ? negetiveAttr && negetiveAttr.slice(0, 5).map((value, key) => {
                                return (
                                    <span key={key} className="label label-lg label-light-danger mr-2 label-inline">{value}</span>
                                )
                            }) : companyRatings && companyRatings.status === 'success' && <strong>{negetiveAttr}</strong>}
                            {companyRatings && companyRatings.status === 'success' && negetiveAttr && negetiveAttr.length > 5 && <OverlayTrigger trigger="hover" placement={negetiveAttr.length < 25 ?"top" : 'bottom'} overlay={negativePopover}>
                                <span className="label label-lg label-light mr-2 label-inline custm_popover">+ {negetiveAttr.length - 5 + ' more'}</span>
                            </OverlayTrigger>}
                    </div>
                </div>
            </div>
        </div>


    </div>

    )
}