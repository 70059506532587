/* eslint-disable */ 
import React,{useState,useEffect} from 'react';
import { Modal, Spinner, Tab, Tabs } from "react-bootstrap";
import {useSelector,useDispatch } from 'react-redux';
import DataTable from "react-data-table-component";
import { badgesUrl } from '../../_helpers';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { REQUEST_LEADERBOARD_DATA, REQUEST_LEADERBOARD_FOR_MASTERY } from '../../../redux/duck/reports/leaderboard/leaderboard.action';
import { authUserId } from '../../_helpers/functions';
import { toUpper } from 'lodash';
import moment from 'moment';

export const LeadBoardModal = (props) => {

    const {data,mastery_data, mastery_leaderboard} = useSelector(state => state.leaderboard)
    const [showDetails,setShow]=useState(true);
    const [showMeetingDetails,setMeetingShow]=useState(true);
    const [columns,setColumns] = useState();
    const [meetingColumns,setMeetingColumns] = useState();
    const handleShowDetails = () => {
        setShow(!showDetails);  
        setMeetingShow(!showMeetingDetails)
    }
    
    const dispatch = useDispatch();

    const badgeStyles = {
       container:{"display":"flex"},
       single_badge:{
           width:"30px",
           height:"30px"
       }
    }


    useEffect(() => {
        if (showMeetingDetails) {
            
      setMeetingColumns([
        {
            name: "User Rank",
            selector:row=>row["rank"] ,
            sortable: false,
            cell: row =>  <div className='leadeboard-user-info'><span className='leaderboard-rank'>{row.rank + '.'}</span><span className='leaderboard-initials'>{row.initial}</span>  <span className='leaderboard-name'> {row.name } {row.self == true ? <strong>(You)</strong> : null } </span></div>,
        },
          {
            name: "Badges Earned Recently",
            center:true,
            selector:row=>row["badges"]  ,
            sortable: false,
            cell: row => <div className='badge_icons_container' style={badgeStyles.container}>{row.badges && row.badges.map((badge,i) => {
                return <div key={i} className="badge_icon"> <img title={badge.badge_category_title  + "(" + badge.title + ")"} style={badgeStyles.single_badge} src={badgesUrl(badge.icon_file)} alt={badge.icon_file} /> </div>
            })}</div>,
           
          },
          {
            name: "Total badges Earned",
            center:true,
            selector:row=>row["badge_count"]  ,
            sortable: true,
            right: true,
            },
          {
            name: "Last Badge Earned on",
            center:true,
            selector: row=>row["last_badge_earn_date"] ,
            sortable: false,  
            cell: row => moment(row.last_badge_earn_date).format("MMM DD, YYYY")         
          }
    ])
    }else{
        setMeetingColumns([
            {
                name: "User Rank",
                selector: row=>row["rank"] ,
                sortable: false,
                cell: row =>  <div className='leadeboard-user-info'><span className='leaderboard-rank'>{row.rank + '.'}</span><span className='leaderboard-initials'>{row.initial}</span>  <span className='leaderboard-name'> {row.name } {row.self == true ? <strong>(You)</strong> : null } </span></div>,
            },
            {
                name: "Last Badge Earned on",
                center:true,
                selector:row=>row[ "last_badge_earn_date"] ,
                sortable: false,  
                cell: row => moment(row.last_badge_earn_date).format("MMM DD, YYYY")         
            }
        ])
    }
    
    
    }, [showMeetingDetails])
    

    // const masteryColumns = [
    //     {
    //         name: "User Rank",
    //         selector: "rank",
    //         sortable: false,
    //         cell: row =>  <div className='leadeboard-user-info'><span className='leaderboard-rank'>{row.rank + '.'}</span><span className='leaderboard-initials'>{row.initial}</span>  <span className='leaderboard-name'> {row.name } {row.self == true ? <strong>(You)</strong> : null } </span></div>,
    //     },
    //       {
    //         name: "Badges Earned Recently",
    //         center:true,
    //         selector: "badges",
    //         sortable: false,
    //         cell: row => <div className='badge_icons_container' style={badgeStyles.container}>{row.badges && row.badges.map(badge => {
    //             return <div className="badge_icon"> <img title={badge.badge_category_title  + "(" + badge.title + ")"} style={badgeStyles.single_badge} src={badgesUrl(badge.icon_file)} alt={badge.icon_file} /> </div>
    //         })}</div>,
           
    //       },
    //       {
    //         name: "Total badges Earned",
    //         center:true,
    //         selector: "badge_count",
    //         sortable: true 
    //         },
    //       {
    //         name: "Last Badge Earned on",
    //         center:true,
    //         selector: "last_badge_earn_date",
    //         sortable: false,  
    //         cell: row => moment(row.last_badge_earn_date).format("MMM DD, YYYY")         
    //       }
    // ]

    const handleBtnClick = () => {
        props.handleClose();
    }
   
    const handleSort = async (column, sortDirection) => {
            // console.log(column,sortDirection);
            dispatch({ type: REQUEST_LEADERBOARD_DATA, payload: {
                // user_id:authUserId(),
                sort:column.selector,
                order:toUpper(sortDirection)   
              }})
        };
        
useEffect(() => {
    dispatch({ type: REQUEST_LEADERBOARD_FOR_MASTERY, payload: {
        // user_id:authUserId()  
      }})
},[])

useEffect(() => {

},[])

useEffect(() => {

    if(showDetails){
        setColumns([
            {
                name: "User Rank",
                selector:row => row["name"],
                sortable: false,
                cell: row =>  <div className='leadeboard-user-info'><span className='leaderboard-rank'>{row.rank + '.'}</span><span className='leaderboard-initials'>{row.initial}</span>  <span className='leaderboard-name'> {row.name } {row.self == true ? <strong>(You)</strong> : null } </span></div>,
            },
              {
                name: "# of Meetings Attended	",
                center:true,
                selector:row =>row[ "number_of_meeting_attended"],
                sortable: true,
                right: true,
               
              },
              {
                name: "# of Reviews Given	",
                center:true,
                selector:row => row[ "number_of_rating_submitted"],
                sortable: true,
                right: true,
                cell: row => row.number_of_rating_submitted
               
              },
              {
                  name: "% Reviewed / Attended",
                  center:true,
                  selector:row =>row[ "percent_rating_submitted"],
                  sortable: true,
                  right: true,
                  cell: row => <span>{row.percent_rating_submitted + '%'}</span>,
                 
                }
          
        ])
    }else{
        setColumns([
            {
                name: "User Rank",
                selector:row => row["name"],
                sortable: false,
                cell: row =>  <div  className='leadeboard-user-info'><span className='leaderboard-rank'>{row.rank + '.'}</span><span className='leaderboard-initials'>{row.initial}</span>  <span className='leaderboard-name'> {row.name } {row.self == true ? <strong>(You)</strong> : null }  </span></div>
              },
              {
                name: "# of Reviews Given	",
                center:true,
                selector:row => row[ "number_of_rating_submitted"],
                sortable: true,
                right: true,
                cell: row =>row.number_of_rating_submitted
              }
            
        ])
    }


    
}, [showDetails])

    return (
        <>
            <Modal className="leaderBoardModal"
                show={props.show} onHide={props.handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        30-Day Leaderboard
                    </Modal.Title>
                    <button className="btn btn-close p-0 ml-auto" onClick={props.handleClose}><img src="/media/ms-media/modal-close-icn.svg" alt="" /></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="leaderBoard-modal">
                        <div className="row col--innercard-gutter">
                            <div className="col-md-6">
                                <div className="card modal-card">
                                    <div className="card-body">
                                        <h5 className="m-0"><strong>Thanks for your recent feedback!</strong> Keep up the good work and climb the leaderboard higher.</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card modal-card">                                    
                                    <div className="card-body">                                        
                                      {   mastery_data ?  <div className="efficiency-pro d-flex justify-content-between align-items-center"><p className="mb-0"><img src={badgesUrl(mastery_data && mastery_data.data.icon_file)} alt="" />{mastery_data && mastery_data.data.title}</p> <Link to="/meeting-mastery" onClick={handleBtnClick} className="btn btn-sm btn-bordered">More</Link></div>
                                        :   <Spinner animation="border" role="status">
                                        <span className="visually-hidden"></span>
                                      </Spinner> 
                                    }
                                        <p className="mb-0">{mastery_data && parse(mastery_data.data.message)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row col--innercard-gutter">
                            <div className="col-md-4">
                                <div className="card modal-card leaderboard-cards">
                                    <div className="card-body d-flex justify-content-between align-items-center">  
                                        <p className="mb-0">Attended Meetings</p>
                                        <span>{data ? data.data.map(i => {
                                                    if(i.self == true){
                                                        return i.number_of_meeting_attended
                                            }}): 0}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card modal-card leaderboard-cards">                                    
                                    <div className="card-body d-flex justify-content-between align-items-center"> 
                                        <p className="mb-0">Your Contributions</p>
                                        <span>{data ? data.data.map(i => {
                                                    if(i.self == true){
                                                        return i.number_of_rating_submitted
                                            }}): 0}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card modal-card leaderboard-cards">
                                    <div className="card-body d-flex justify-content-between align-items-center"> 
                                        <p className="mb-0">% Reviewed / Attended</p>
                                        <span>{data ? data.data.map(i => {
                                                    if(i.self == true){
                                                        return i.percent_rating_submitted + '%'
                                            }}) : 0 }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="leaderBoard-table-container">
                                <Tabs defaultActiveKey="feedback" id="uncontrolled-tab-example" className="mb-3">
                                        <Tab eventKey="feedback" title="Feedback">
                                        <div className="leaderBoard-table-header d-flex justify-content-between align-items-center">
                                        <h4 className="m-0">Top-10 Leaderboard</h4>
                                        <Link to="#" className="btn btn-sm btn-bordered" onClick={handleShowDetails}>{showDetails ? "Hide Details" : "Show Details"}</Link>
                                    </div>
                                    <div className="leaderBoard-table-body">

                                   {data && data.data ? 
                                   <DataTable
                                        columns={columns}
                                        data={data && data.data}
                                        defaultSortField="percent_rating_submitted"
                                        onSort={handleSort}
                                        sortServer
                                        />
                                    :   <div className='d-flex justify-content-center'><Spinner animation="border" role="status">
                                    <span className="visually-hidden"></span>
                                  </Spinner> </div>
                                    }
                                    </div>
                                        </Tab>
                                        <Tab eventKey="mastery" title="Meeting Mastery">
                                        <div className="leaderBoard-table-header d-flex justify-content-between align-items-center">
                                        <h4 className="m-0">Top-10 Leaderboard</h4>
                                        <Link to="#" className="btn btn-sm btn-bordered" onClick={handleShowDetails}>{showDetails ? "Hide Details" : "Show Details"}</Link>
                                    </div>
                                    <div className="leaderBoard-table-body">

                                   {mastery_leaderboard && mastery_leaderboard.data ? 
                                   <DataTable
                                        columns={meetingColumns}
                                        // columns={masteryColumns}
                                        data={mastery_leaderboard && mastery_leaderboard.data}
                                        defaultSortField="badge_count"
                                        // onSort={handleSort}
                                        // sortServer
                                        />
                                    :   <div className='d-flex justify-content-center'><Spinner animation="border" role="status">
                                    <span className="visually-hidden"></span>
                                  </Spinner> </div>
                                    }
                                    </div>
                                        </Tab>
                                    </Tabs>
                              
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};