/* eslint-disable */ 

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FilterRange } from "../../layout/components/header/FilterLabel";


export const YouVsAttendeesChart = () => {

  const {memberFeedback} = useSelector(state => state.organized);


    const [state, setState] = useState({
        series: [0,0],
        options: {
          chart: {
            type: 'radialBar',
            offsetY: 0,
            sparkline: {
              enabled: false
            },
            toolbar: {
              show: true,
              tools:{
                download:true // <== line to add
              }
            },
          },
          colors: ['#008E9D', 'rgba(102, 236, 251, 0.85)'],
          legend: {
            show: true,
            showForSingleSeries: true,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'bottom',
            horizontalAlign: 'center', 
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            formatter: function(label, series) {
                let index = series.seriesIndex;

                return label + " - " + series.w.globals.series[index] + "%"
              },
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: undefined,
                useSeriesColors: false
            },
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 5,
                vertical: 0
            },
            onItemClick: {
                toggleDataSeries: false
            },
            onItemHover: {
                highlightDataSeries: false
            },
        },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#fff",
                strokeWidth: '97%',
                margin: 10, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#ddd',
                  opacity: 1,
                  blur: 2
                }
              },
              dataLabels: {
                name: {
                  show: true
                },
                value: {
                  offsetY: -40,
                  fontSize: '18px'
                }
              }
            }
          },
          grid: {
            padding: {
              top: 0
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              shadeIntensity: 0.4,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              //stops: [0, 20, 40, 60, 80]
            },
          },
          labels: ['You', 'Attendees'],
        }
    })

    useEffect(() => {

      let series = [];

      if(memberFeedback && memberFeedback.status == "success"){

        let seriesA = memberFeedback.comparison_data.user_rating_percentage.positive_percentage;
        let seriesB = memberFeedback.comparison_data.attended_rating_percentage.positive_percentage
        
        if(seriesA !== null && seriesA.includes("%")){
          seriesA = seriesA.split("%");
          seriesA = seriesA[0];
        }else{
          seriesA = 0
        }

        if(seriesB !== null && seriesB.includes("%")){
          seriesB = seriesB.split("%");
          seriesB = seriesB[0];
        }else{
          seriesB = 0
        }

        series.push(seriesA);
        series.push(seriesB);

      }

      setState({
        ...state,
        series:series
      })

    },[memberFeedback])

    return (
        <div className="card mb-4 card-custom">
        {/* Header */}
        <div className="card-header align-items-center border-0">
            <h3 className="card-title align-items-center d-flex w-100 justify-content-between"><span className="font-weight-bolder text-dark"> Meeting Effectiveness: You vs. Attendees </span></h3>
            <div className="card-subtitle">
                <h5>How do your meeting attendees perceive your meeting effectiveness compared to how you see yourself?</h5>
                <div className="card-subtitle-btn">
                    {/* <a className="btn btn-sm btn-light">You</a>
                    <a className="btn btn-sm btn-light-primary">Other people</a> */}
                </div>
            </div>
        </div>

        {/* Body */}
        <div className="card-body">    
            {memberFeedback && memberFeedback.status == "success" ? (
              memberFeedback.rated_meetings_count >= memberFeedback.min_rating_count ? <ReactApexChart options={state.options} series={state.series} type="radialBar" height="400" />
              : <div> Please encourage your meeting attendees to provide feedback at the end of your meetings from <Link to={"/unrated"}>unrated meetings</Link>. The more feedback they share, the more you will learn and improve. </div>
            )

            :

            <div className='loader-container'>
            { !memberFeedback ? <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner>
            : <div>We're sorry, we don't have any data to share with you.</div>  
          }
            </div>
          
            }
        </div>
    </div>

    )
}
