import * as React from "react";
import './logo.css'

const SVGComponent = (props) => (
  <svg
  className="gear"
    xmlns="http://www.w3.org/2000/svg"
    width={138.82}
    height={137.221}
    viewBox="0 0 138.82 137.221"
    {...props}
  >
    <g id="meeting-science-logo-sm" transform="translate(-0.045)">
      <path
        id="Path_4896"
        data-name="Path 4896"
        d="M24.759,5.718A12.151,12.151,0,0,1,23,12.052a52.329,52.329,0,0,1,15.833,6.685l-3.87,2.815c-2.111,1.759-2.815,4.574-1.056,6.685a5.029,5.029,0,0,0,7.037,1.056l9.148-6.685c1.407-1.056,1.759-2.111,2.111-3.87a5.65,5.65,0,0,0-1.759-3.87A62.051,62.051,0,0,0,24.407,2.2,11.97,11.97,0,0,1,24.759,5.718Z"
        transform="translate(57.811 5.541)"
        fill="#00acbf"
      />
      <path
        id="Path_4897"
        data-name="Path 4897"
        d="M88.22,104.588,20.314,19.441A51.525,51.525,0,0,1,37.2,12.052a14.337,14.337,0,0,1-1.759-6.333c0-1.056.352-2.463.352-3.518A62.814,62.814,0,0,0,10.11,14.867a4.6,4.6,0,0,0-.7,6.685L77.313,106.7a51.525,51.525,0,0,1-16.889,7.389,12.15,12.15,0,0,1,1.759,6.333c0,1.056-.352,2.463-.352,3.518a64.146,64.146,0,0,0,26.037-12.667A5.291,5.291,0,0,0,88.22,104.588Z"
        transform="translate(20.739 5.541)"
        fill="#00acbf"
      />
      <path
        id="Path_4898"
        data-name="Path 4898"
        d="M27.871,55.118a11.259,11.259,0,1,0,0-22.518A11.115,11.115,0,0,0,16.612,43.859C16.26,50.192,21.538,55.118,27.871,55.118Z"
        transform="translate(41.681 82.102)"
        fill="#00acbf"
      />
      <path
        id="Path_4899"
        data-name="Path 4899"
        d="M27.871,22.518A11.115,11.115,0,0,0,39.13,11.259,11.115,11.115,0,0,0,27.871,0,11.115,11.115,0,0,0,16.612,11.259C16.26,17.241,21.538,22.518,27.871,22.518Z"
        transform="translate(41.681)"
        fill="#00acbf"
      />
      <path
        id="Path_4900"
        data-name="Path 4900"
        d="M44.359,38.818A11.115,11.115,0,0,0,55.618,27.559a11.259,11.259,0,0,0-22.518,0A11.337,11.337,0,0,0,44.359,38.818Z"
        transform="translate(83.248 41.051)"
        fill="#fff"
      />
      <path
        id="Path_4901"
        data-name="Path 4901"
        d="M21.7,31.919a11.379,11.379,0,0,0-5.981-14.778A11.273,11.273,0,0,0,6.923,37.9,11.379,11.379,0,0,0,21.7,31.919Z"
        transform="translate(0 40.913)"
        fill="#fff"
      />
      <path
        id="Path_4902"
        data-name="Path 4902"
        d="M35.644,54.6A12.15,12.15,0,0,1,37.4,48.263a47.252,47.252,0,0,1-16.185-7.037l4.926-4.574a4.588,4.588,0,0,0,.352-6.685,4.989,4.989,0,0,0-7.037-.352l-9.5,8.444A5.65,5.65,0,0,0,8.2,41.93a4.33,4.33,0,0,0,1.759,3.518A62.051,62.051,0,0,0,36,58.115C36,57.059,35.644,55.652,35.644,54.6Z"
        transform="translate(20.537 71.365)"
        fill="#00acbf"
      />
      <path
        id="Path_4903"
        data-name="Path 4903"
        d="M68.458,36.925a13.168,13.168,0,0,1,6.333-1.759c1.056,0,2.463.352,3.518.352A62.814,62.814,0,0,0,65.643,9.833c-1.759-1.759-4.574-2.463-6.685-.7L23.069,34.111A4.619,4.619,0,0,0,22.014,40.8a5.029,5.029,0,0,0,7.037,1.056L61.069,19.333A58.642,58.642,0,0,1,68.458,36.925Z"
        transform="translate(52.816 20.426)"
        fill="#fff"
      />
      <path
        id="Path_4904"
        data-name="Path 4904"
        d="M49.195,24.459A12.15,12.15,0,0,1,42.862,22.7,47.352,47.352,0,0,1,32.306,43.107a4.572,4.572,0,0,0,.7,6.685,5.675,5.675,0,0,0,3.167,1.056,5.65,5.65,0,0,0,3.87-1.759A55.154,55.154,0,0,0,52.713,23.4,5.944,5.944,0,0,1,49.195,24.459Z"
        transform="translate(78.412 57.169)"
        fill="#fff"
      />
      <path
        id="Path_4905"
        data-name="Path 4905"
        d="M55.429,50.07,22.707,9.959A5.65,5.65,0,0,0,18.837,8.2a5.65,5.65,0,0,0-3.87,1.759A55.154,55.154,0,0,0,2.3,35.644a10.847,10.847,0,0,1,3.518-.352,12.151,12.151,0,0,1,6.333,1.759,45.591,45.591,0,0,1,7.037-15.833L48.04,56.4a5.019,5.019,0,0,0,7.037.7C56.836,55,57.188,52.181,55.429,50.07Z"
        transform="translate(5.678 20.652)"
        fill="#fff"
      />
      <path
        id="Path_4906"
        data-name="Path 4906"
        d="M12.052,22.7a13.168,13.168,0,0,1-6.333,1.759c-1.056,0-2.463-.352-3.518-.352A62.815,62.815,0,0,0,14.867,49.792a5.65,5.65,0,0,0,3.87,1.759A5.675,5.675,0,0,0,21.9,50.5a5.484,5.484,0,0,0,.7-7.389A47.352,47.352,0,0,1,12.052,22.7Z"
        transform="translate(5.427 57.169)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SVGComponent;
