// View Unrated Meetings Info Detail
export const GET_UNRATED_MEETINGS_DETAIL = "GET_UNRATED_MEETINGS_DETAIL";
export const getUnratedMeetingsDetail = (data) => ({ type: GET_UNRATED_MEETINGS_DETAIL, data })
export const SUCCESS_GET_UNRATED_MEETINGS_DETAIL = 'SUCCESS_GET_UNRATED_MEETINGS_DETAIL';
export const ERROR_GET_UNRATED_MEETINGS_DETAIL = 'ERROR_GET_UNRATED_MEETINGS_DETAIL';
export const getUnratedMeetingsDetailResponse = (type, data) => ({ type, data });

// Send Unrated Meetings Detail
export const SEND_UNRATED_MEETINGS_DETAIL = "SEND_UNRATED_MEETINGS_DETAIL";
export const sendUnratedMeetingDetail = (data) => ({ type: SEND_UNRATED_MEETINGS_DETAIL, data })
export const SUCCESS_SEND_UNRATED_MEETINGS_DETAIL = 'SUCCESS_SEND_UNRATED_MEETINGS_DETAIL';
export const ERROR_SEND_UNRATED_MEETINGS_DETAIL = 'ERROR_SEND_UNRATED_MEETINGS_DETAIL';
export const sendUnratedMeetingDetailResponse = (type, data) => ({ type, data });

// Unrated Count Details
export const GET_UNRATED_MEETINGS_COUNT = "GET_UNRATED_MEETINGS_COUNT";
export const getUnratedMeetingsCount = (data) => ({ type: GET_UNRATED_MEETINGS_COUNT, data })
export const SUCCESS_GET_UNRATED_MEETINGS_COUNT = 'SUCCESS_GET_UNRATED_MEETINGS_COUNT';
export const ERROR_GET_UNRATED_MEETINGS_COUNT = 'ERROR_GET_UNRATED_MEETINGS_COUNT';
export const getUnratedMeetingsCountResponse = (type, data) => ({ type, data });
