import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { takeLatest } from "redux-saga/effects";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  // Register: "[Register] Action",
  // SetUser: "[Set User] Action",
  InitateSignup:"[Signup Status]",
  UserLoaded: "[Signup Complete] Action",
  EventsPulled:"[Events Pull Action]",
  ScoreCalculated:"[Score Calculation Action]",
  MasteryCalculated:"[Mastery Calculation Action]",
  RecommendationCalculated:"[ Recommendation Calculation Action]",
  RecommendationUpdate:"[ Recommendation Status Update Action]",
  Step6Complete:"[ Step6 Status ]"
};

const initialAuthState = {

};


export const reducer = persistReducer(
  { storage, key: "v726-demo1-auth", whitelist: ["authToken","isRegistered","isAllowedLogin","areEventsPulled","isMasteryCalculated","isScoreCalculated","isRecommendationCalculated","isRecommendationPresent","isSignupComplete","isSignupInitiated","mcrData"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken,isRegistered,isAllowedLogin } = action.payload;

        return {...state, authToken,isRegistered,isAllowedLogin };
      }

      // case actionTypes.Register: {
      //   const { areEventsPulled,isMasteryCalculated,isScoreCalculated } = action.payload;

      //   return { ...state,areEventsPulled,isMasteryCalculated,isScoreCalculated };
      // }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { isSignupComplete } = action.payload;
        return { ...state, isSignupComplete };
      }

      case actionTypes.InitateSignup: {
        const { isSignupInitiated } = action.payload;
        return { ...state, isSignupInitiated };
      }

      // case actionTypes.SetUser: {
      //   const { user } = action.payload;
      //   return { ...state, user };
      // }

      case actionTypes.EventsPulled: {
        const { areEventsPulled } = action.payload;
        return { ...state, areEventsPulled };
      }

      case actionTypes.ScoreCalculated: {
        const { isScoreCalculated } = action.payload;
        return { ...state, isScoreCalculated };
      }

      case actionTypes.RecommendationCalculated: {
        const { isRecommendationCalculated } = action.payload;
        return { ...state, isRecommendationCalculated };
      }

      case actionTypes.RecommendationUpdate: {
        const { isRecommendationPresent } = action.payload;
        return { ...state, isRecommendationPresent };
      }

      case actionTypes.Step6Complete: {
        const { isRegistered } = action.payload;
        return { ...state, isRegistered };
      }

      case actionTypes.MasteryCalculated: {
        const { isMasteryCalculated } = action.payload;
        return { ...state, isMasteryCalculated };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken,isRegistered,isAllowedLogin) => ({ type: actionTypes.Login, payload: { authToken,isAllowedLogin,isRegistered } }),
  startSignup: (isSignupInitiated) => ({ type: actionTypes.InitateSignup, payload: { isSignupInitiated } }),
  register: (isRegistered) => ({ type: actionTypes.Step6Complete, payload: { isRegistered } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  updateRecommendation: (isRecommendationPresent) => ({ type: actionTypes.RecommendationUpdate, payload: { isRecommendationPresent } }),
  scoreCompleted: (isScoreCalculated) => ({ type: actionTypes.ScoreCalculated, payload: { isScoreCalculated } }),
  eventPullCompleted: (areEventsPulled) => ({ type: actionTypes.EventsPulled, payload: { areEventsPulled } }),
  masteryCompleted: (isMasteryCalculated) => ({ type: actionTypes.MasteryCalculated, payload: { isMasteryCalculated } }),
  recommendationCompleted: (isRecommendationCalculated) => ({ type: actionTypes.RecommendationCalculated, payload: { isRecommendationCalculated } }),
  fulfillUser: (isSignupComplete) => ({ type: actionTypes.UserLoaded, payload: { isSignupComplete } }),
  // setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
  
  });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  
  // });

}
