/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import _ from "lodash";

export function MeetingStrainPerDayLineal() {
  const getMcrGraphData = useSelector(state => state.coaching.getCoachingMcrGraphData);
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        toolbar: {
          show: true,
          tools:{
            download:true // <== line to add
          }
        },
      },
      colors: ["#00ACBF"],
      stroke: {
        width: 1,
        curve: "smooth"
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.65,
          opacityTo: 0
        }
      },
      markers: {
        size: 4,
        colors: undefined,
        fillColor: '#00ACBF',
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 10,
        shape: "circle",
        hover: {
          size: undefined,
          sizeOffset: 1
        }
      },
      title: {
        text: '# of meetings',
        align: 'left',
        style: {
          fontSize: '12px',
          color: '#BFC2C7'
        },
      },
      grid: {
        show: true,
        borderColor: '#F4F5F8',
        strokeDashArray: 6,
        position: 'back',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
        row: {
          colors: undefined,
          opacity: 0.5
        },
        column: {
          colors: undefined,
          opacity: 0.5
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box p-2">' +
            "<span>" +
            series[seriesIndex][dataPointIndex] + " meetings with " +
            w.config.series[seriesIndex].name[dataPointIndex] + " people" +
            "</span>" +
            "</div>"
          );
        },
        y: {
          show: true,
        },
        x: {
          show: false
        }
      },
      xaxis: {
        categories: [],
        tooltip: {
          enabled: false,
        },
        crosshairs: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true,
          borderType: 'dotted',
          color: '#F4F5F8',
          height: 25,
        },
        tickPlacement: 'between',
        labels: {
          show: true,
          style: {
            colors: "#A2A7AD",
          },
        },
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          align: 'center',
          style: {
            colors: "#A2A7AD",
          },
          formatter: function (val) {
            return (val).toFixed(0);
          }
        },
        axisTicks: {
          show: true,
          borderType: 'dotted',
          color: '#F4F5F8',
        },
      }
    },
  });

  useEffect(() => {
    if (getMcrGraphData && _.has(getMcrGraphData, "graphData") && _.has(getMcrGraphData, "graph_levels") && _.has(getMcrGraphData, "graphMeetingsWithPeople") && getMcrGraphData.code === 200) {
      const graphMeetingsWithPeople = getMcrGraphData.graphMeetingsWithPeople.length > 0 ? getMcrGraphData.graphMeetingsWithPeople : [];
      const graphData = getMcrGraphData.graphData.length > 0 ? getMcrGraphData.graphData : [];
      const graphXaxisData = getMcrGraphData.graph_levels.length > 0 ? getMcrGraphData.graph_levels.map(data => {
        let newArr = [data.date, data.day];
        return newArr;
      }) : []
      setState({ ...state, series: [{ name: graphMeetingsWithPeople, data: graphData }], options: { ...state.options, xaxis: { ...state.options.xaxis, categories: graphXaxisData } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMcrGraphData]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="area"
        height={300}
      />
    </>
  );
}
