import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CoachingObservationAndChartSection, CoachingRecommendationSection } from "../widgets";
import { getCoachingMcrData, getCoachingMcrGraphData } from '../../../redux/duck/reports/coaching/coaching.action';
import { LayoutSplashScreen } from "../../layout";
import { AlertPopup } from "../../_helpers/Alerts";

export function CoachingDashboard() {
    const dispatch = useDispatch();
    // const user_id = authUserId();
    // Redux State
    const { getLocalStorageUserData } = useSelector(state => state.profile);
    // Loader State
    const [loader, setLoader] = useState(false);
    // Set Initial State
    const [alert, setAlert] = useState({ show: false, title: null, message: null, errType: null });

    useEffect(() => {
        setLoader(true);
        // setLoader(false);
        getLocalStorageUserData && getLocalStorageUserData.user_data && dispatch(getCoachingMcrData({  timezone: getLocalStorageUserData.user_data.timezone }));
        getLocalStorageUserData && getLocalStorageUserData.user_data && dispatch(getCoachingMcrGraphData({email: getLocalStorageUserData.user_data.email, timezone: getLocalStorageUserData.user_data.timezone }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLocalStorageUserData.user_data]);

    // handle close button of alert
    const handleClose = () => {
        setAlert({
            show: false,
        });
    };

    return (
        <>
            <LayoutSplashScreen visible={loader} />
            {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />}
            <div className="row col-card-gutter coaching-page">
                <CoachingRecommendationSection userId={getLocalStorageUserData?.user_data?.user_id} loader={(data) => setLoader(data)} alert={(data) => setAlert(data)} />
                {/* <CoachingRecommendationSection loader={(data) => setLoader(data)} alert={(data) => setAlert(data)} /> */}
                <CoachingObservationAndChartSection loader={(data) => setLoader(data)} alert={(data) => setAlert(data)} />
            </div>
        </>
    );
};
