/* eslint-disable */ 
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProfileInfoDetail,getLocalStorageUserDetail } from '../../../../redux/duck/userProfile/userProfile.action';
import { usePrevious } from '../../../_helpers/Custom';
import { authUserId } from '../../../_helpers/functions';
import { Navbar } from './Navbar';
import _ from 'lodash';

import { AlertPopup } from "../../../_helpers/Alerts";
import { LayoutSplashScreen } from "../../../layout";
import { OverlayTrigger, Popover } from "react-bootstrap";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Popover from 'react-bootstrap/Popover'

import { toAbsoluteUrl } from "../../../_helpers";
export const PersonalInfo = () => {
    // set initial states and values
    const user_id = authUserId();
    const dispatch = useDispatch();
    const profileData = useSelector(state => state.profile.getProfileInfoDetailData);
    const updateUserProfileImage = useSelector(state => state.profile.saveUserProfileImageData);

    const prevProfileData = usePrevious({profileData})
    // Loader State
    const [loader, setLoader] = useState(false);
    const [alert,setAlert] = useState({ show:false, title:null, message:null, errType:null });
    const [state, setState] = useState({ user_name : '', user_firstname: '', user_lastname: '', user_nickname: '', email: '', user_photourl: '', user_phone: '', country_code: '', user_gender: '', timezone: '',yearExp:'',jobLevel:'',jobTitle:'' })
    const [imageErrCount, setImageErrCount] = useState(0)

    useEffect(() => {
        if(imageErrCount > 1000){
          window. location. reload()
        }
      }, [imageErrCount])

    // pass id as param
    useEffect(() => {
        setLoader(true);
        dispatch(getProfileInfoDetail({}))
        dispatch(getLocalStorageUserDetail({ }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
            if(updateUserProfileImage &&  updateUserProfileImage.status == 'success'){
                dispatch(getLocalStorageUserDetail({  }))
                dispatch(getProfileInfoDetail({}))
            }
    },[updateUserProfileImage])

    // fetch user profile data and set it into state
    useEffect(() => {
        if (prevProfileData && prevProfileData.profileData !== profileData) {
            if (profileData && _.has(profileData, 'user_data') && profileData.status === "success") {
                const userData = profileData.user_data;
                const userPrefData = profileData?.user_pref;
                let user_gender;
                if(userData.user_gender === 1) {
                    user_gender = 'he/him/his';
                } else if(userData.user_gender === 2) {
                   user_gender = 'she/her/hers';
                }
                else if(userData.user_gender === 3){
                    user_gender = 'they/them/their';
                }
                else{
                    user_gender = '-';
                }
                setState({...state, user_name: userData.user_name, user_firstname: userData.user_firstname, user_lastname: userData.user_lastname, user_nickname: userData.user_nickname, email: userData.email, user_photourl: userData.user_photourl, user_phone: userData.user_phone, country_code: userData.country_code,
                user_gender, timezone: userData.timezone,yearExp:userPrefData.pref_yearsExp,jobTitle:userPrefData.pref_jobTitle,jobLevel:userPrefData.pref_jobLevel
                })
                setLoader(false);           
            }
            if (profileData && _.has(profileData, 'message') && profileData.status === "error") {
                if(profileData.open_popup === false){
                    setAlert({
                      show:true,
                      message:"Something went wrong!",
                      title:'Error',
                      errType:"failure"
                    });
                    setLoader(false);
                  }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevProfileData, profileData]);

    const newTo = { 
        pathname: '/user-profile/edit-personal-info', 
        param1: state.user_phone,
        param2: state.country_code,
      };

    // handle Close button on popup
    const handleClose = () =>{
        setAlert({
          show:false
        })
      };



      const popover =  (
        <Popover id="popover-basic" className="mr-4">
          <Popover.Content className="p-2">
            <div className="badge--card">
              <div className="badge--card-body">
              <span className="">Your nickname is unique to you. If you choose not to disclose your real name in company reports or leader lists like the leaderboard, we'll use your nickname instead. You can then share your nickname with some of your colleagues to showcase your meeting mastery.</span>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      );




    return (
        <>
        <LayoutSplashScreen visible={loader} />
        {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType}/>}
        <div className="profile-container flex-column h-100 d-flex">
            <div className="nav-container d-flex justify-content-between personalInfo_navigation">
                <Navbar getMainRoute={'personalInfo'}/>
                <div className="edit-button"><Link className="btn btn-edit" to={newTo}>Edit</Link></div>
            </div>  
            <div className="tab-content bg-transparent flex-grow-1 personalInfo_profileTab" id="profileTab">
                <div className="card card-custom">
                    <div className="card-body">
    
                        {/* TAB-1 PERSONAL INFORMATION */}
    
                        <div className="tab-pane fade show active" id="personalInfo" role="tabpanel" aria-labelledby="personalInfo-tab">
    
                            {/* PERSONAL INFORMATION VIEW */}
    
                            <div className="user-personal-info info-view">
                                <div className="row">
                                    <div className="col-lg-5 col-md-6">
                                        <div className="form-group">
                                            <label>Name and Photo</label>
                                            <div className="form-content align-items-center d-flex">
                                                <div className="user--pic mr-4"><img src={state.user_photourl && state.user_photourl != 'https://client.meetingscience.io/storage/null' ? state.user_photourl : "/media/ms-media/user-avatar.jpg"} onError={({ currentTarget }) => {currentTarget.src="https://client.meetingscience.io/images/profile_images/ph_profile.png";setImageErrCount(imageErrCount + 1); currentTarget.onerror = null}} className="rounded-circle" alt=""/></div>
                                                {/* <div className="user--info">{state.user_name ? state.user_name : state.user_firstname + ' ' + state.user_lastname}</div> */}
                                                <div className="user--info">{state.user_name ?? state.user_firstname + ' ' + state.user_lastname}</div>
                                            </div>                            
                                        </div>
                                        <div className="form-group">
                                            <label>Nick Name (optional)   <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                                <img  src="/media/ms-media/info-circle-icn.svg" alt="info" style={{cursor:"pointer"}} />
                                    </OverlayTrigger></label>
                                            <div className="form-content d-flex">
                                                <div className="user--info">{state.user_nickname ? state.user_nickname : '-'}</div>
                                            </div>                            
                                        </div>
                                        <div className="form-group">
                                            <label>email</label>
                                            <div className="form-content d-flex">
                                                <div className="user--info">{state.email ? state.email : ''}</div>
                                            </div>                            
                                        </div>
                                        <div className="form-group mb-8">
                                            <label>I identify as...</label>
                                            <div className="form-content d-flex">
                                                <div className="user--info">{state.user_gender ? state.user_gender : ''}</div>
                                            </div>                            
                                        </div>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="col-lg-5 col-md-6">
                                        <div className="form-group">
                                            <label>PHONE NUMBER</label>
                                            <div className="form-content d-flex">
                                                <div className="user--info d-flex">{state.user_phone.length >3 && state.country_code ? state.country_code+state.user_phone : ''} {" " + profileData && profileData.user_data && profileData.user_data.is_phone_verified ?
                                                    <div className="info-purpose d-flex align-items-center mt-0"><span className="d-flex align-items-center justify-content-center"><img title="Verified" src={toAbsoluteUrl("/media/ms-media/small-tick.svg")} alt="" /></span></div>
                                                : 
                                                state.user_phone.length > 0 ?
                                                <div className="info-purpose d-flex align-items-center mt-0"><span className="d-flex align-items-center justify-content-center"><img title="Not Verified" src={toAbsoluteUrl("/media/ms-media/imbalanced-icn.svg")} alt="" /></span></div>
                                                :'-'
                                                }</div>
                                            </div>                            
                                        </div>
                                        <div className="form-group">
                                            <label>Your timezone</label>
                                            <div className="form-content d-flex">
                                                <div className="user--info">{state.timezone ? state.timezone : ''}</div>
                                            </div>                            
                                        </div>
                                        <div className="form-group mb-9">
                                            <label>Your Job Title</label>
                                            <div className="form-content d-flex">
                                                <div className="user--info">{state.jobTitle ? state.jobTitle : '-'}</div>
                                            </div>                            
                                        </div>
                                        <div className="form-group mb-9">
                                            <label>Your Job Level</label>
                                            <div className="form-content d-flex">
                                                <div className="user--info">{state.jobLevel ? state.jobLevel : '-'}</div>
                                            </div>                            
                                        </div>
                                        <div className="form-group mb-0">
                                            <label>Your Experience</label>
                                            <div className="form-content d-flex">
                                                <div className="user--info">{state.yearExp ? state.yearExp + " years" : '-'}</div>
                                            </div>                            
                                        </div>
                                    </div>
                                </div>                                                      
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </>

    );
};