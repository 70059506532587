/* eslint-disable */ 

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React,{useEffect} from "react";
import {Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { logActivity, sendSignupNotifications } from "./modules/Auth/_redux/authCrud";
import Axios from "axios";
import { Apistore } from "../redux/apiStore";
import { saveLastLocation, setStorage } from "../_metronic/_helpers";
import { actions } from "./modules/Auth";
import { authTimezone, authUserId, getEnvUrl, getEventCount, getServiceProvider, getSuccessfulEvents, getUserAgent } from "../_metronic/_helpers/functions";
import { routeDef } from "./routes/routeDef";


export function Routes() {
  const { authToken,isRegistered,isAllowedLogin,isSignupInitiated,areEventsPulled,isSignupComplete,isMasteryCalculated,isScoreCalculated,isRecommendationCalculated } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
      isRegistered:auth.isRegistered,
      isAllowedLogin:auth.isAllowedLogin,
      isSignupInitiated:auth.isSignupInitiated,
      areEventsPulled:auth.areEventsPulled,
     isSignupComplete:auth.isSignupComplete,

      isMasteryCalculated:auth.isMasteryCalculated,
      isScoreCalculated:auth.isScoreCalculated,
      isRecommendationCalculated:auth.isRecommendationCalculated
    }),
    shallowEqual
  );
 

    const location = useLocation();
    const dispatch = useDispatch();

useEffect(() => {
    let path = location.pathname;
  if(path !=='/logout' && path!=='/'){
    switch(path){
    case '/signup-page1' :
      logActivity('signup_step1',path)
    break;
    // case '/signup-page2' :
    //   logActivity('signup_step2',path)
    // break;
    // case '/signup-page3' :
    //   logActivity('signup_step3',path)
    // break;
    // case '/signup-page4' :
    //   logActivity('signup_step4',path)
    // break;
    // case '/signup-page5' :
    //   logActivity('signup_step5',path)
    // break;
    default:
      logActivity('page_visit',path)
    break;    
  }}

},[location.pathname])


useEffect(() => {
  if(isSignupInitiated == 1){

    if(!isRecommendationCalculated && areEventsPulled){
      
      Axios.post(Apistore.GetMcrData,{
        // user_id:authUserId(),
        timezone:authTimezone()
    
      }).then(res => {
          
        if((res.data.data.recc_add_agenda_events && res.data.data.recc_add_agenda_events.length) || (res.data.data.recc_shorten_this_meeting_events && res.data.data.recc_shorten_this_meeting_events.length)){
          dispatch(actions.updateRecommendation(1))
          dispatch(actions.recommendationCompleted(1));
      }else{
        dispatch(actions.updateRecommendation(0))
        dispatch(actions.recommendationCompleted(1));
      }
          dispatch(actions.recommendationCompleted(1));
      }).catch(err => {
        
      })

    }
    
    else if(!areEventsPulled){
       Axios.post(getServiceProvider() == 1 ? Apistore.TriggerGoogleEventPull : Apistore.TriggerMsftEventPull,null,{
        params:{
          // user_id:authUserId()
        }
      }).then(res => {
        dispatch(actions.eventPullCompleted(1));
        setStorage('EVENTS_FETCHED',res.data.data.total_events);
        setStorage('EVENTS_SAVED',res.data.data.saved_events);
      }).catch(err => {
        console.log(err)
      })
    }

   else if(!isMasteryCalculated && areEventsPulled){

      Axios.post(Apistore.TriggerMeetingMasteryCalculation,null,{
        params:{
          // user_id:authUserId()
        }
      }).then(res => {
        dispatch(actions.masteryCompleted(1));
      }).catch(err => {

      })

    }

   else if(!isScoreCalculated && areEventsPulled){
        
      Axios.post(Apistore.TriggerScoreCalculation,null,{
        params:{
          // user_id:authUserId()
        }
      }).then(res => {
        dispatch(actions.scoreCompleted(1));
      }).catch(err => {
        
      })

    }

   

  }
  
},[isSignupInitiated,areEventsPulled,isRecommendationCalculated,isScoreCalculated,isMasteryCalculated])

useEffect(() => {
  saveLastLocation(location);
},[location])

// useEffect(()=>{
//   if (areEventsPulled && isSignupComplete) {
//     sendSignupNotifications({
//       // user_id:authUserId(),
//       event_saved:getSuccessfulEvents(),
//       total_events:getEventCount(),
//       environment_url:getEnvUrl(),
//       service_provider:getServiceProvider(),
//       user_agent:getUserAgent(),
//       signup_start_time:localStorage.getItem('SIGNUP_START_TIME'),
//       signup_end_time:localStorage.getItem('SIGNUP_END_TIME')
//     })
//   }
// },[areEventsPulled && isSignupComplete])

  return (  
    <>
    <Switch> 

    
    { /* private routes (pages) */}
      
        { 
        authToken && 
        isRegistered == "true" && 
        isAllowedLogin == "true" &&
        window.location.search == "" && 
        <Layout> 
            <BasePage /> 
        </Layout> }    



    {/* private routes (registration) */}
        
          {
          routeDef.map(route => {
            return (
              route.private.map(privateRoute => {
                return(
                  privateRoute.registration.map(routes => {
                    return <Route path={routes.name} component={ authToken && isAllowedLogin == "true" && isRegistered == "false" ? routes.component : routes.default} />
                  })
                )
              })
            )
          })
        }

    {/* public routes */}

          {
          routeDef.map(route => {
            return (
              route.public.map(publicRoute => {
                 return <Route path={publicRoute.name} component={publicRoute.component} />
              })
            )
          })
        }

    </Switch>
    </>
  );
}
