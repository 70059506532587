/* eslint-disable */ 

import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

export function NoRefresh() {

    const [loader,setLoader] = useState(false);    
    const {areEventsPulled,isMasteryCalculated,isScoreCalculated,isRecommendationCalculated} = useSelector(state => state.auth)

    useEffect(() => {
        if(areEventsPulled && isMasteryCalculated && isScoreCalculated && isRecommendationCalculated){
            setLoader(false)
        }else{
            setLoader(true);
        }
    },[areEventsPulled,isMasteryCalculated,isScoreCalculated,isRecommendationCalculated])

    return (
    loader ? <div style={{position:'fixed',zIndex:'200'}} className='no-refresh-bar'>
                <p>We are fetching your calendar data. Please do not refresh the page. 
                    <span className='ml-2'>
                        {/* <Spinner animation="border" role="status" size='sm' variant='warning'>
                                <span className="visually-hidden"></span>
                        </Spinner> */}
                    </span>
                </p>
             </div>
        :
        <div></div> 
  )
}
