import axios from 'axios';
import { Apistore } from '../../../apiStore';

export  function fetchOrganizedData (data){
   return  axios.post(Apistore.GetOrganizedEvents,data) 
}

export  function fetchUserRating (data){
   return  axios.post(Apistore.GetUserRating,data) 
}

export  function fetchUserRatingAttributes (data){
   return  axios.post(Apistore.GetUserRatingAttributes,data) 
}

export  function fetchUserCompanyRanking (data){
   return  axios.post(Apistore.GetUserCompanyRank,null,{
      params:{
         // user_id:data.user_id,
         sdate:data.sdate,
         edate:data.edate,
         company_id:data.company_id
      }
   }) 
}

export  function fetchUserFeedback (data){
   return  axios.post(Apistore.GetFeedback,null,{
      params:{
         // user_id:data.user_id,
         start_date:data.sdate,
         end_date:data.edate
      }
   }) 
}

export function fetchDifferences(data) {
   return axios.post(Apistore.GetOrganizedKeyDifferences, data)
}

export function fetchNewFeedbackApi (data){
   return axios.post(Apistore.GetNewFeedback, data);
}

export function fetchFeedbackTrendApi(data){
   return axios.post(Apistore.GetFeedbackTrendOverTime, data)
}