/* eslint-disable */ 
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect , useState} from "react";
import ReactApexChart from 'react-apexcharts';
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

export function TopOrganizersUsingCompanyTime({ className }) {

    const {companyData} = useSelector(state => state.company);
    const height = 280;
    const [state,setState] = useState({
        series: [],
        options: {
            chart: {
                toolbar: {
                    show: false
                },
                dropShadow: {
                    enabled: false,
                },
            },
            plotOptions: {
                pie: {
                    startAngle: 0,
                    endAngle: 360,
                    expandOnClick: false,
                    customScale: 1,
                }
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
                position: 'bottom',
                horizontalAlign: 'left',
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    radius: 0,
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 15
                },
                formatter: function(seriesName, opts) {
                    return '<span class="d-flex justify-content-space-between"> <span> ' + seriesName + '</div>' + '<div><span style="color:#999ea5">' + opts.w.globals.series[opts.seriesIndex] + '  meetings |</span> ' + " " + calcPercentage(opts.w.globals.series[opts.seriesIndex],opts.w.globals.series.reduce(addFunc)) + '</span></span>'; 
                }
            },
            states: {
                hover: {
                    filter: {
                        type: 'none',
                    }
                },
            },
            stroke: {
                width: 0,
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex,w}) {
                                 
                    return '<div class="arrow_box p-2" style="width:auto">' +
                      '<span>' + w.config.labels[seriesIndex] + " : " + series[seriesIndex] + ' meetings</span>' +
                     '</div>'
                  }
            },
            colors: ['#F6F062', '#008E9D', '#46BB3B', '#00626C', '#FCAC34', '#00ACBF', '#D3F5F9', '#E34F4F', '#4ACBDA', '#8FE0E9'],
            labels: [],
        }
    });


    const calcPercentage = (num,total) => {
        return ((num/total)*100).toFixed() + "%";
    }

    const addFunc = (total,num) => {
        return total + num;
    }

    const LabelsData = () => {
    
     return  state.series.map((series,index) => 
                       
                     <div key={index}>
                            <div className='d-flex justify-content-between align-items-center py-2'>
                                    <div>
                                        <div className="color-label-wrap">
                                        <div className="color-label" style={{fontSize:'14px'}}>
                                            <span className="color_box primary" style={{background:state.options.colors[index]}}></span> {state.options.labels[index]}
                                        </div>
                                    </div>
                                    </div>
                                    <div>
                                        <div> <span style={{color:'#999ea5'}}>{state.series[index]} meetings | </span> {calcPercentage(state.series[index],state.series.reduce(addFunc))}</div>
                                    </div>
                            </div>
                     </div>           
                    
                
        )
      
    }

    useEffect(() => {

        let organizers = [];

        companyData && companyData.events && companyData.events.length && companyData.events.map(event => {
            let organizer_id = event.event_organizerid;
            let users_list = event.event_users;
            let organizer_data = users_list[organizer_id];

            let user_exists_index = organizers.findIndex(element => element.user_id == organizer_id);

                if(user_exists_index == -1){
                    if(organizer_data !== undefined){
                        organizers.push({
                            user_id:organizer_data.user_id,
                            user_name:organizer_data.user_fullname,
                            meetings:1
                        })
                    }
                   
                }else{
                    organizers[user_exists_index].meetings = ++organizers[user_exists_index].meetings; 
                }
                
        })


        let labels = [];
        let series = [];
        let total = 0;
        let dataArrayForTotal = [];
        let sortedData  = organizers.sort( function ( a, b ) { return  b.meetings - a.meetings });
        let topTenData = sortedData.slice(0,10);

        // switch(topTenData.length){
        //     case 1:
        //          setHeight(300);
        //          break;
        //     case 2:
        //          setHeight(300);
        //          break;
        //     case 3:
        //          setHeight(400);
        //          break;
        //     case 4:
        //          setHeight(400);
        //          break;
        //     case 5:
        //          setHeight(550);
        //          break;
        //     case 6:
        //          setHeight(550);
        //          break;
        //     case 7:
        //          setHeight(630);
        //          break;
        //     case 8:
        //          setHeight(630);
        //          break;
        //     case 9:
        //          setHeight(740);
        //          break;                        
        //     case 10:
        //          setHeight(740);
        //          break;  
                          
        // }

        topTenData.length && topTenData.map(element => {
            dataArrayForTotal.push(element.meetings);
    })

    if(dataArrayForTotal.length){
        total = dataArrayForTotal.reduce(addFunc);
    }

        topTenData.length && topTenData.map(element => {
                labels.push(element.user_name);
                series.push(element.meetings);
        })

        topTenData.length &&  
        setState({
            ...state,
              options:{
                ...state.options,
                      labels:labels
              },
            series:series
             
        })

    },[companyData && companyData.events])


    return (
        <>
          {companyData && companyData.events && companyData.events.length > 0 ?
            <div>
                <div className='d-flex justify-content-center'> 
                    <ReactApexChart options={state.options} series={state.series} type="pie" width={300} height={height} />
                </div>
                  {state.series.length > 1  &&  <LabelsData/> }
            </div>
            
            :
            <div className='loader-container'>
          { companyData == null ?  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                </Spinner> : (companyData && companyData.events.length == 0 ? "We're sorry, for the time you requested, we don't have any data to share with you." : null)}
       </div>
        }
        </>
    );
}
