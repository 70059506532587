import { ERROR_GET_COACHING_MCR_DATA, ERROR_GET_COACHING_MCR_GRAPH_DATA, SUCCESS_GET_COACHING_MCR_DATA, SUCCESS_GET_COACHING_MCR_GRAPH_DATA } from "./coaching.action";
import { DEFAULT_STATE } from "./coaching.state";

export const coachingReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case SUCCESS_GET_COACHING_MCR_DATA:
            const getCoachingMcrData = action.data;
            return { ...state, getCoachingMcrData };
        case ERROR_GET_COACHING_MCR_DATA:
            const errorGetCoachingMcrData = action.data;
            return { ...state, getCoachingMcrData: errorGetCoachingMcrData };
        case SUCCESS_GET_COACHING_MCR_GRAPH_DATA:
            const getCoachingMcrGraphData = action.data;
            return { ...state, getCoachingMcrGraphData };
        case ERROR_GET_COACHING_MCR_GRAPH_DATA:
            const errorGetCoachingMcrGraphData = action.data;
            return { ...state, getCoachingMcrGraphData: errorGetCoachingMcrGraphData };
        default:
            return state;
    }
}