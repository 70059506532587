import axios from 'axios';
import { Apistore } from '../../apiStore';

export function getProfileInfoDetailApi(data) {
    return axios.post(Apistore.GetUserProfileData, data)
}
export function updateProfileInfoDetailApi(data) {
    return axios.post(Apistore.SaveUserProfileData, data)
}
export function saveUserProfileImageApi(data) {
    return axios.post(Apistore.SaveUserProfileImage, data)
}
export function saveUserRelationDataApi(data) {
    return axios.post(Apistore.SaveUserRelationData, data)
}
export function saveUserNotificationDataApi(data) {
    return axios.post(Apistore.SaveUserNotificationData, data)
}
export function saveUserPrivacyDataApi(data) {
    return axios.post(Apistore.SaveUserPrivacyData, data)
}
export function saveCustomNotificationDataApi(data) {                //newChange 
    return axios.post(Apistore.updateCustomNotificationEmail, data) 
}
export function saveUserPhoneNumber(data) {                //newChange 
    return axios.post(Apistore.CheckIsPhoneVerified, data) 
}
export function checkUserUniqueNicknameDataApi(data) {
    return axios.post(Apistore.CheckUniqueNickName, data)
}
export function getLocalUserDataApi(data) {
    return axios.post(Apistore.GetUserData, data)
}
export function updateFeedbackSettingsApi(data) {
    return axios.post(Apistore.SaveUserFeedbackSettings, data)
}
export function sendOtpApi(data) {
    return axios.post(Apistore.SendOtp, data)
}
export function verifyOtpApi(data) {
    return axios.post(Apistore.VerifyOtp, data)
}

export function resendOtpApi(data) {
    return axios.post(Apistore.ResendOtp, data)
}

export function fetchMeetingMasteryNotificationsApi(data){
    return axios.post(Apistore.GetMasteryNotification, data)
}

export function updateMeetingMasteryNotificationsApi(data){
    return axios.post(Apistore.UpdateMeetingMasteryNotifications, data)
}


