import axios from 'axios';
import { Apistore } from '../../../apiStore';

export function listOrganizedMeetingsDataApi(data) {
    return axios.post(Apistore.ListTimeMachineOrganizedMeetingsData, data)
}
export function listAttendedMeetingsDataApi(data) {
    return axios.post(Apistore.ListTimeMachineAttendedMeetingsData, data)
}
export function saveOrganizedMeetingsDataApi(data) {
    return axios.post(Apistore.SaveTimeMachineOrganizedMeetingsData, data)
}
export function sendAttendedMeetingsProposalTimeApi(data) {
    return axios.post(Apistore.SendTimeMachineAttendedProposeTime, data)
}