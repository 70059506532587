import { put, takeLatest } from 'redux-saga/effects';
import { GET_FILTER_DAYS, SET_FILTER_DAYS } from './picker.action';

// Get attended page data
function* getFilterDays(action) { 
    try{
            yield put({type:SET_FILTER_DAYS,data:action.data})

    } catch (e) {
        console.log(e);
    }
}


export function* pickerWatcher() {
    yield takeLatest(GET_FILTER_DAYS, getFilterDays);
}