import axios from 'axios';
import { Apistore } from '../../../apiStore';

export  function fetchLeaderboard (data){
   return  axios.post(Apistore.GetLeaderboards,data) 
}

export  function fetchMasteryinfo (data){
   return  axios.post(Apistore.GetMeetingMasterySummary,null,{
      params:{
         // user_id:data.user_id,
         start_date:data.start_date,
         end_date:data.end_date
      }
   }) 
}

export  function fetchMasteryLeaderboard (data){
   return  axios.post(Apistore.GetMasteryLeaderboard,null,{
      params:{
         // user_id:data.user_id
      }
   }) 
}

