var salaries = [
    ["Anesthesiologists", "267,020", "#", "#"],
    ["Surgeons", "255,110", "199,920", "#"],
    ["Oral and Maxillofacial Surgeons", "242,370", "177,650", "#"],
    ["Obstetricians and Gynecologists", "238,320", "172,130", "#"],
    ["Orthodontists", "225,760", "142,470", "#"],
    ["Psychiatrists", "220,380", "149,900", "#"],
    ["Family and General Practitioners", "211,780", "142,960", "#"],
    ["Physicians and Surgeons", "210,980", "122,930", "#"],
    ["Physicians and Surgeons, All Other", "203,880", "111,440", "#"],
    ["Chief Executives", "200,140", "115,960", "#"],
    ["Internists, General", "196,490", "88,630", "#"],
    ["Prosthodontists", "191,400", "118,390", "#"],
    ["Pediatricians, General", "183,240", "126,690", "#"],
    ["Dentists", "180,590", "108,580", "#"],
    ["Dentists, All Other Specialists", "178,800", "111,110", "#"],
    ["Dentists, General", "175,840", "107,440", "#"],
    ["Nurse Anesthetists", "174,790", "143,870", "198,470"],
    ["Airline Pilots, Copilots, and Flight Engineers", "169,560", "94,380", "#"],
    ["Petroleum Engineers", "156,370", "103,610", "191,780"],
    ["Computer and Information Systems Managers", "152,860", "110,110", "180,190"],
    ["Architectural and Engineering Managers", "148,970", "112,400", "173,180"],
    ["Podiatrists", "148,220", "87,980", "182,690"],
    ["Marketing Managers", "147,240", "95,770", "181,780"],
    ["Financial Managers", "146,830", "91,420", "178,840"],
    ["Aircraft Pilots and Flight Engineers", "146,660", "77,910", "197,540"],
    ["Lawyers", "144,230", "79,160", "182,490"],
    ["Marketing and Sales Managers", "143,000", "89,160", "177,260"],
    ["Lawyers and Judicial Law Clerks", "142,130", "77,200", "179,720"],
    ["Advertising, Marketing, Promotions, Public Relations, and Sales Managers", "141,500", "88,240", "174,540"],
    ["Sales Managers", "140,320", "84,790", "173,510"],
    ["Lawyers, Judges, and Related Workers", "139,850", "76,370", "174,920"],
    ["Natural Sciences Managers", "139,680", "92,430", "165,900"],
    ["Advertising and Promotions Managers", "133,090", "82,200", "163,370"],
    ["Compensation and Benefits Managers", "132,860", "91,480", "160,450"],
    ["Operations Specialties Managers", "132,680", "86,700", "160,160"],
    ["Public Relations and Fundraising Managers", "131,570", "84,120", "158,760"],
    ["Law Teachers, Postsecondary", "130,710", "59,250", "179,140"],
    ["Top Executives", "128,240", "66,000", "163,930"],
    ["Human Resources Managers", "126,700", "85,750", "152,760"],
    ["Purchasing Managers", "125,630", "90,460", "149,960"],
    ["Physicists", "125,280", "85,090", "158,350"],
    ["General and Operations Managers", "123,880", "65,650", "157,120"],
    ["Computer and Information Research Scientists", "123,850", "91,650", "149,470"],
    ["Astronomers and Physicists", "123,730", "83,440", "157,440"],
    ["Pharmacists", "123,670", "111,340", "145,870"],
    ["Health Specialties Teachers, Postsecondary", "122,320", "61,280", "160,580"],
    ["Personal Financial Advisors", "121,770", "57,290", "157,710"],
    ["Training and Development Managers", "121,730", "83,160", "149,010"],
    ["Management Occupations", "121,560", "70,880", "151,700"],
    ["Judges, Magistrate Judges, and Magistrates", "121,130", "71,430", "163,050"],
    ["Air Traffic Controllers", "120,830", "86,900", "152,830"],
    ["Optometrists", "119,980", "86,260", "135,660"],
    ["Computer Hardware Engineers", "117,840", "86,760", "146,020"],
    ["Economics Teachers, Postsecondary", "117,180", "72,430", "141,690"],
    ["Aerospace Engineers", "117,100", "89,830", "143,340"],
    ["Actuaries", "116,250", "76,720", "141,760"],
    ["Economists", "116,020", "77,270", "141,780"],
    ["Miscellaneous Managers", "115,590", "75,460", "143,230"],
    ["Managers, All Other", "115,590", "75,460", "143,230"],
    ["Political Scientists", "115,300", "86,220", "148,950"],
    ["Chemical Engineers", "114,470", "81,900", "133,320"],
    ["Software Developers, Systems Software", "114,000", "85,610", "139,550"],
    ["Medical and Health Services Managers", "113,730", "76,050", "130,820"],
    ["Engineering Teachers, Postsecondary", "113,680", "74,810", "140,030"],
    ["Health Teachers, Postsecondary", "113,370", "59,690", "143,460"],
    ["Industrial Production Managers", "113,370", "80,530", "134,060"],
    ["Engineering and Architecture Teachers, Postsecondary", "111,450", "72,490", "137,430"],
    ["Education Administrators, Postsecondary", "111,210", "69,600", "132,350"],
    ["Computer Network Architects", "111,130", "81,960", "137,820"],
    ["Astronomers", "111,090", "74,300", "147,710"],
    ["Nuclear Engineers", "110,790", "85,840", "129,000"],
    ["Miscellaneous Physical Scientists", "110,090", "75,830", "136,930"],
    ["Physical Scientists, All Other", "110,090", "75,830", "136,930"],
    ["Nurse Practitioners", "110,030", "90,760", "125,440"],
    ["Judges, Magistrates, and Other Judicial Workers", "109,170", "62,020", "153,020"],
    ["Industrial-Organizational Psychologists", "109,030", "61,950", "138,180"],
    ["Legal Occupations", "108,690", "52,970", "141,700"],
    ["Sales Engineers", "108,610", "75,410", "133,340"],
    ["Physician Assistants", "108,430", "90,150", "127,220"],
    ["Software Developers, Applications", "108,080", "79,340", "130,460"],
    ["Electronics Engineers, Except Computer", "107,930", "79,640", "130,150"],
    ["Geoscientists, Except Hydrologists and Geographers", "107,800", "64,390", "128,580"],
    ["Nurse Midwives", "106,910", "87,070", "124,240"],
    ["Administrative Services Managers", "106,050", "71,850", "127,100"],
    ["Biochemists and Biophysicists", "105,940", "64,230", "129,950"],
    ["Veterinarians", "105,240", "73,580", "122,180"],
    ["Mathematicians", "104,870", "73,490", "126,070"],
    ["Art Directors", "104,590", "67,930", "126,500"],
    ["Software Developers and Programmers", "104,480", "75,020", "128,330"],
    ["Electrical and Electronics Engineers", "104,250", "77,030", "126,080"],
    ["Financial Analysts and Advisors", "104,050", "60,340", "121,460"],
    ["Physics Teachers, Postsecondary", "103,830", "65,360", "126,910"],
    ["Business Teachers, Postsecondary", "103,330", "55,220", "130,800"],
    ["Construction Managers", "103,110", "70,670", "123,720"],
    ["Transportation, Storage, and Distribution Managers", "102,850", "72,340", "123,550"],
    ["Information Security Analysts", "102,470", "73,890", "126,870"],
    ["Materials Scientists", "102,450", "70,400", "129,470"],
    ["Health Diagnosing and Treating Practitioners", "101,960", "62,450", "115,290"],
    ["Air Transportation Workers", "101,910", "53,090", "124,850"],
    ["Atmospheric, Earth, Marine, and Space Sciences Teachers, Postsecondary", "101,890", "65,400", "126,110"],
    ["Electrical Engineers", "101,600", "75,430", "123,160"],
    ["Administrative Law Judges, Adjudicators, and Hearing Officers", "101,210", "67,400", "133,850"],
    ["Air Traffic Controllers and Airfield Operations Specialists", "101,100", "62,230", "143,470"],
    ["Financial Analysts", "100,990", "65,350", "116,360"],
    ["Other Management Occupations", "100,900", "62,200", "126,040"],
    ["Political Science Teachers, Postsecondary", "99,480", "60,980", "117,590"],
    ["Miscellaneous Engineers", "99,410", "69,890", "126,200"],
    ["Engineers, All Other", "99,410", "69,890", "126,200"],
    ["Architecture Teachers, Postsecondary", "99,320", "62,440", "125,020"],
    ["Engineers", "99,230", "71,820", "120,500"],
    ["Marine Engineers and Naval Architects", "98,970", "74,670", "118,980"],
    ["Securities, Commodities, and Financial Services Sales Agents", "98,770", "42,650", "123,320"],
    ["Education Administrators, Elementary and Secondary School", "98,750", "75,680", "119,540"],
    ["Mining and Geological Engineers, Including Mining Safety Engineers", "98,420", "70,290", "122,020"],
    ["Biological Science Teachers, Postsecondary", "97,340", "59,510", "119,400"],
    ["Physical Sciences Teachers, Postsecondary", "97,280", "61,330", "119,850"],
    ["Education Administrators", "97,030", "67,050", "118,620"],
    ["Materials Engineers", "96,930", "71,960", "119,360"],
    ["Commercial Pilots", "96,530", "61,950", "115,280"],
    ["Medical Scientists, Except Epidemiologists", "96,420", "59,580", "118,040"],
    ["Computer Science Teachers, Postsecondary", "96,200", "58,250", "119,690"],
    ["Law, Criminal Justice, and Social Work Teachers, Postsecondary", "96,110", "50,770", "121,000"],
    ["Life Sciences Teachers, Postsecondary", "96,110", "59,640", "118,420"],
    ["Psychologists, All Other", "95,610", "72,540", "113,440"],
    ["Atmospheric and Space Scientists", "95,580", "69,380", "116,090"],
    ["Nuclear Power Reactor Operators", "95,310", "80,290", "111,070"],
    ["Medical Scientists", "95,270", "59,210", "116,560"],
    ["Biomedical Engineers", "95,090", "67,830", "114,930"],
    ["Computer and Information Analysts", "94,990", "69,390", "115,970"],
    ["Management Analysts", "94,390", "62,500", "112,140"],
    ["Anthropology and Archeology Teachers, Postsecondary", "94,080", "61,610", "118,390"],
    ["Funeral Service Managers", "93,820", "59,970", "110,870"],
    ["Database and Systems Administrators and Network Architects", "93,780", "67,160", "116,820"],
    ["Civil Engineers", "93,720", "67,430", "112,850"],
    ["Health and Safety Engineers, Except Mining Safety Engineers and Inspectors", "93,630", "67,420", "116,220"],
    ["Computer Systems Analysts", "93,610", "68,730", "113,460"],
    ["Miscellaneous Computer Occupations", "93,350", "66,410", "117,070"],
    ["Computer Occupations, All Other", "93,350", "66,410", "117,070"],
    ["First-Line Supervisors of Police and Detectives", "93,100", "66,920", "116,300"],
    ["Mathematical Science Occupations", "92,830", "64,360", "114,590"],
    ["Mechanical Engineers", "92,800", "69,410", "110,520"],
    ["Environmental Engineers", "92,640", "66,590", "112,230"],
    ["Statisticians", "92,600", "67,440", "113,670"],
    ["Forestry and Conservation Science Teachers, Postsecondary", "92,550", "64,830", "114,890"],
    ["Social Sciences Teachers, Postsecondary", "92,440", "56,430", "113,020"],
    ["Chemistry Teachers, Postsecondary", "92,360", "58,700", "111,180"],
    ["Database Administrators", "92,030", "66,200", "116,060"],
    ["Broadcast News Analysts", "91,990", "37,970", "111,610"],
    ["Sales Representatives, Wholesale and Manufacturing, Technical and Scientific Products", "91,830", "55,630", "115,900"],
    ["Industrial Engineers, Including Health and Safety", "91,800", "69,520", "109,280"],
    ["Industrial Engineers", "91,630", "69,690", "108,560"],
    ["Computer and Mathematical Occupations", "91,530", "61,640", "116,440"],
    ["Computer Occupations", "91,480", "61,520", "116,520"],
    ["Environmental Science Teachers, Postsecondary", "91,330", "58,240", "113,460"],
    ["Agents and Business Managers of Artists, Performers, and Athletes", "90,930", "45,340", "107,720"],
    ["Agricultural Sciences Teachers, Postsecondary", "90,890", "59,390", "115,460"],
    ["Math and Computer Teachers, Postsecondary", "90,650", "54,850", "111,280"],
    ["Physical Scientists", "90,470", "58,540", "113,040"],
    ["Financial Examiners", "90,310", "57,910", "111,080"],
    ["Sociologists", "90,290", "63,610", "110,680"],
    ["Producers and Directors", "89,840", "48,520", "110,350"],
    ["Computer Programmers", "89,580", "64,410", "109,120"],
    ["Education Administrators, All Other", "89,570", "59,310", "111,550"],
    ["Miscellaneous Life Scientists", "89,150", "56,500", "109,060"],
    ["Life Scientists, All Other", "89,150", "56,500", "109,060"],
    ["Geography Teachers, Postsecondary", "88,950", "60,070", "106,810"],
    ["Physical Therapists", "88,880", "72,680", "102,530"],
    ["Architects, Except Landscape and Naval", "88,860", "61,280", "104,510"],
    ["Psychology Teachers, Postsecondary", "88,490", "55,880", "107,700"],
    ["Operations Research Analysts", "88,350", "61,280", "109,670"],
    ["Social Sciences Teachers, Postsecondary, All Other", "87,950", "48,220", "107,870"],
    ["Fashion Designers", "87,610", "50,710", "100,780"],
    ["Architecture and Engineering Occupations", "87,370", "59,750", "107,760"],
    ["Mathematical Science Teachers, Postsecondary", "87,140", "53,030", "104,810"],
    ["Network and Computer Systems Administrators", "87,070", "64,010", "104,970"],
    ["Athletes and Sports Competitors", "87,030", "25,500", "103,130"],
    ["Film and Video Editors", "86,830", "43,230", "100,020"],
    ["Radiation Therapists", "86,730", "67,490", "102,380"],
    ["Psychologists", "86,600", "58,680", "103,650"],
    ["Architects, Except Naval", "86,480", "59,690", "102,330"],
    ["Life Scientists", "86,450", "55,590", "104,730"],
    ["Biological Scientists", "86,390", "56,730", "103,540"],
    ["First-Line Supervisors of Law Enforcement Workers", "86,350", "59,790", "107,670"],
    ["Social Scientists and Related Workers", "85,900", "58,870", "103,440"],
    ["Chiropractors", "85,870", "51,890", "99,290"],
    ["Social Scientists and Related Workers, All Other", "85,750", "64,090", "103,880"],
    ["Miscellaneous Health Diagnosing and Treating Practitioners", "85,600", "56,180", "109,610"],
    ["Health Diagnosing and Treating Practitioners, All Other", "85,600", "56,180", "109,610"],
    ["Environmental Scientists and Geoscientists", "85,480", "55,990", "102,510"],
    ["Area, Ethnic, and Cultural Studies Teachers, Postsecondary", "85,450", "51,780", "103,140"],
    ["Chemists and Materials Scientists", "85,400", "57,090", "106,400"],
    ["Occupational Therapists", "85,350", "68,760", "100,890"],
    ["Power Distributors and Dispatchers", "85,340", "69,440", "100,810"],
    ["Clinical, Counseling, and School Psychologists", "85,340", "58,220", "100,760"],
    ["Miscellaneous Social Scientists and Related Workers", "85,320", "61,230", "105,200"],
    ["Gaming Managers", "85,260", "58,980", "97,770"],
    ["Postsecondary Teachers", "85,190", "47,630", "104,000"],
    ["Detectives and Criminal Investigators", "85,020", "57,560", "107,000"],
    ["Artists and Related Workers", "84,930", "52,800", "104,950"],
    ["First-Line Supervisors of Non-Retail Sales Workers", "84,600", "54,670", "101,920"],
    ["History Teachers, Postsecondary", "83,990", "55,410", "99,860"],
    ["Chemists", "83,850", "56,290", "103,820"],
    ["Biological Scientists, All Other", "83,600", "60,250", "98,040"],
    ["Sociology Teachers, Postsecondary", "83,310", "54,350", "100,020"],
    ["Hydrologists", "82,790", "61,280", "100,090"],
    ["Emergency Management Directors", "82,570", "53,580", "104,800"],
    ["Art, Drama, and Music Teachers, Postsecondary", "82,560", "51,550", "98,690"],
    ["Financial Specialists", "82,550", "53,090", "97,010"],
    ["Philosophy and Religion Teachers, Postsecondary", "82,420", "53,410", "98,160"],
    ["Captains, Mates, and Pilots of Water Vessels", "82,380", "48,190", "100,730"],
    ["Credit Analysts", "82,300", "54,540", "98,180"],
    ["Audiologists", "82,210", "63,400", "94,900"],
    ["Healthcare Practitioners and Technical Occupations", "82,000", "47,650", "95,020"],
    ["Power Plant Operators, Distributors, and Dispatchers", "81,760", "66,370", "98,400"],
    ["Actors, Producers, and Directors", "81,610", "38,490", "100,430"],
    ["Nursing Instructors and Teachers, Postsecondary", "81,350", "55,620", "97,390"],
    ["Microbiologists", "81,150", "51,740", "105,890"],
    ["Genetic Counselors", "80,860", "68,030", "96,060"],
    ["Speech-Language Pathologists", "80,700", "60,570", "97,770"],
    ["Arts, Communications, and Humanities Teachers, Postsecondary", "80,670", "50,950", "97,230"],
    ["Geographers", "80,530", "63,270", "96,980"],
    ["First-Line Supervisors of Fire Fighting and Prevention Workers", "80,310", "57,940", "98,970"],
    ["Ship and Boat Captains and Operators", "80,310", "47,010", "97,960"],
    ["Architects, Surveyors, and Cartographers", "80,300", "55,470", "96,770"],
    ["Electrical and Electronics Repairers, Powerhouse, Substation, and Relay", "80,040", "67,480", "94,770"],
    ["Nuclear Technicians", "79,970", "61,090", "97,120"],
    ["Farmers, Ranchers, and Other Agricultural Managers", "79,940", "50,130", "100,070"],
    ["Budget Analysts", "79,830", "60,720", "96,250"],
    ["Therapists", "79,530", "60,410", "96,690"],
    ["Elevator Installers and Repairers", "79,370", "57,290", "100,680"],
    ["Foreign Language and Literature Teachers, Postsecondary", "79,160", "50,890", "96,850"],
    ["Agricultural Engineers", "79,090", "59,940", "95,110"],
    ["Real Estate Brokers", "78,940", "36,580", "92,790"],
    ["Nuclear Medicine Technologists", "78,870", "65,720", "92,080"],
    ["Accountants and Auditors", "78,820", "55,070", "92,910"],
    ["Logisticians", "78,730", "57,450", "95,980"],
    ["Multimedia Artists and Animators", "78,230", "54,400", "97,100"],
    ["English Language and Literature Teachers, Postsecondary", "78,150", "48,860", "94,790"],
    ["Communications Teachers, Postsecondary", "78,090", "49,570", "95,250"],
    ["Power Plant Operators", "78,030", "62,330", "95,330"],
    ["Environmental Scientists and Specialists, Including Health", "77,580", "53,580", "94,590"],
    ["Miscellaneous Financial Specialists", "77,580", "51,100", "94,580"],
    ["Financial Specialists, All Other", "77,580", "51,100", "94,580"],
    ["Social Work Teachers, Postsecondary", "77,520", "51,320", "93,900"],
    ["Home Economics Teachers, Postsecondary", "77,170", "52,930", "96,460"],
    ["Miscellaneous Media and Communication Equipment Workers", "77,080", "51,300", "97,780"],
    ["Media and Communication Equipment Workers, All Other", "77,080", "51,300", "97,780"],
    ["Postmasters and Mail Superintendents", "77,040", "68,410", "84,810"],
    ["Postsecondary Teachers, All Other", "76,990", "46,150", "97,640"],
    ["Miscellaneous Business Operations Specialists", "76,960", "52,200", "94,890"],
    ["Business Operations Specialists, All Other", "76,960", "52,200", "94,890"],
    ["Business and Financial Operations Occupations", "76,910", "51,020", "92,870"],
    ["Insurance Underwriters", "76,880", "53,200", "93,550"],
    ["Supervisors of Protective Service Workers", "76,380", "50,480", "97,890"],
    ["Loan Officers", "76,270", "44,500", "92,240"],
    ["Urban and Regional Planners", "76,240", "56,970", "92,180"],
    ["Life, Physical, and Social Science Occupations", "76,160", "47,310", "95,210"],
    ["Television, Video, and Motion Picture Camera Operators and Editors", "76,090", "40,130", "87,400"],
    ["Ship Engineers", "75,710", "51,540", "92,870"],
    ["Epidemiologists", "75,690", "54,930", "89,950"],
    ["Web Developers", "75,580", "50,990", "95,020"],
    ["Registered Nurses", "75,510", "58,770", "88,350"],
    ["Technical Writers", "75,500", "55,500", "92,070"],
    ["Dental Hygienists", "75,500", "62,490", "89,610"],
    ["Library Science Teachers, Postsecondary", "75,450", "56,550", "90,550"],
    ["Transportation Inspectors", "75,330", "50,610", "98,970"],
    ["Occupational Health and Safety Specialists", "74,940", "56,060", "91,030"],
    ["Transit and Railroad Police", "74,450", "61,020", "90,010"],
    ["Business Operations Specialists", "73,890", "49,950", "90,610"],
    ["Orthotists and Prosthetists", "73,860", "52,790", "88,010"],
    ["Diagnostic Medical Sonographers", "73,860", "60,060", "86,190"],
    ["Education and Library Science Teachers, Postsecondary", "73,800", "48,150", "89,420"],
    ["Sales Representatives, Wholesale and Manufacturing", "73,680", "42,800", "91,190"],
    ["Education Teachers, Postsecondary", "73,680", "47,350", "89,300"],
    ["Credit Counselors and Loan Officers", "73,490", "42,890", "88,320"],
    ["Landscape Architects", "73,160", "53,080", "89,450"],
    ["Writers and Authors", "73,090", "44,890", "85,580"],
    ["Arbitrators, Mediators, and Conciliators", "72,760", "45,380", "91,430"],
    ["Food Scientists and Technologists", "72,570", "50,660", "89,150"],
    ["Compliance Officers", "72,520", "50,620", "90,980"],
    ["Miscellaneous Mathematical Science Occupations", "72,390", "37,570", "89,030"],
    ["Criminal Justice and Law Enforcement Teachers, Postsecondary", "72,390", "46,910", "87,620"],
    ["Magnetic Resonance Imaging Technologists", "72,230", "59,460", "83,460"],
    ["Recreation and Fitness Studies Teachers, Postsecondary", "72,190", "44,890", "89,610"],
    ["Makeup Artists, Theatrical and Performance", "72,030", "32,620", "104,040"],
    ["Writers and Editors", "71,920", "46,230", "86,300"],
    ["Property, Real Estate, and Community Association Managers", "71,730", "41,210", "85,120"],
    ["Social and Community Service Managers", "71,670", "51,100", "86,170"],
    ["Occupational Health and Safety Specialists and Technicians", "71,610", "51,920", "88,060"],
    ["Sales Representatives, Services", "71,490", "37,220", "82,450"],
    ["Gas Plant Operators", "71,470", "58,070", "83,210"],
    ["Commercial and Industrial Designers", "71,430", "51,670", "87,620"],
    ["Legal Support Workers, All Other", "71,420", "44,700", "93,630"],
    ["Agricultural and Food Scientists", "71,230", "49,240", "85,830"],
    ["Market Research Analysts and Marketing Specialists", "70,960", "46,360", "88,680"],
    ["Labor Relations Specialists", "70,730", "43,560", "93,530"],
    ["Petroleum Pump System Operators, Refinery Operators, and Gaugers", "70,630", "54,910", "86,060"],
    ["Soil and Plant Scientists", "70,630", "48,910", "84,060"],
    ["Supervisors of Construction and Extraction Workers", "70,540", "52,380", "84,240"],
    ["First-Line Supervisors of Construction Trades and Extraction Workers", "70,540", "52,380", "84,240"],
    ["Electrical Power-Line Installers and Repairers", "70,240", "52,340", "87,990"],
    ["Cost Estimators", "69,710", "49,850", "84,000"],
    ["Sales Representatives, Wholesale and Manufacturing, Except Technical and Scientific Products", "69,480", "40,770", "83,980"],
    ["Editors", "69,480", "42,690", "82,160"],
    ["Supervisors of Installation, Maintenance, and Repair Workers", "69,320", "51,430", "83,980"],
    ["First-Line Supervisors of Mechanics, Installers, and Repairers", "69,320", "51,430", "83,980"],
    ["Locomotive Firers", "69,030", "57,570", "82,720"],
    ["Aerospace Engineering and Operations Technicians", "68,970", "53,180", "81,030"],
    ["Designers, All Other", "68,610", "39,500", "87,400"],
    ["Public Relations Specialists", "68,440", "44,490", "81,550"],
    ["First-Line Supervisors of Correctional Officers", "68,350", "47,860", "86,590"],
    ["Cartographers and Photogrammetrists", "68,340", "51,180", "82,810"],
    ["Computer Network Support Specialists", "68,050", "48,160", "82,290"],
    ["Compensation, Benefits, and Job Analysis Specialists", "67,910", "48,830", "80,590"],
    ["Insurance Sales Agents", "67,890", "36,040", "77,930"],
    ["Signal and Track Switch Repairers", "67,800", "57,530", "79,170"],
    ["Zoologists and Wildlife Biologists", "67,760", "50,460", "79,070"],
    ["Artists and Related Workers, All Other", "67,700", "38,620", "93,940"],
    ["Animal Scientists", "67,690", "45,350", "78,230"],
    ["Claims Adjusters, Examiners, and Investigators", "67,540", "50,020", "81,390"],
    ["Buyers and Purchasing Agents", "67,530", "47,900", "82,430"],
    ["Instructional Coordinators", "67,490", "49,280", "82,860"],
    ["Claims Adjusters, Appraisers, Examiners, and Investigators", "67,440", "50,170", "81,170"],
    ["Human Resources Workers", "67,240", "46,310", "81,290"],
    ["Locomotive Engineers", "66,920", "54,630", "79,460"],
    ["Surveyors, Cartographers, and Photogrammetrists", "66,810", "47,540", "82,290"],
    ["Human Resources Specialists", "66,790", "46,460", "80,120"],
    ["Other Healthcare Practitioners and Technical Occupations", "66,590", "45,280", "83,500"],
    ["Surveyors", "66,440", "46,590", "82,140"],
    ["Miscellaneous Plant and System Operators", "66,430", "51,360", "80,980"],
    ["Historians", "66,380", "40,670", "85,700"],
    ["Railroad Conductors and Yardmasters", "66,080", "53,490", "78,640"],
    ["Water Transportation Workers", "66,040", "37,690", "79,580"],
    ["Media and Communication Workers", "65,980", "40,400", "79,780"],
    ["Engineering Technicians, Except Drafters, All Other", "65,720", "47,500", "80,670"],
    ["Insurance Appraisers, Auto Damage", "65,510", "52,200", "77,410"],
    ["Police Officers", "65,460", "45,810", "81,800"],
    ["Police and Sheriff's Patrol Officers", "65,400", "45,740", "81,730"],
    ["Avionics Technicians", "65,330", "52,790", "79,270"],
    ["Conservation Scientists", "65,320", "46,210", "79,600"],
    ["Special Education Teachers, Secondary School", "65,320", "48,630", "77,820"],
    ["Anthropologists and Archeologists", "65,310", "48,020", "80,230"],
    ["Diagnostic Related Technologists and Technicians", "65,260", "51,010", "78,220"],
    ["Real Estate Brokers and Sales Agents", "65,240", "33,570", "77,810"],
    ["Locomotive Engineers and Operators", "65,240", "53,340", "76,940"],
    ["Aircraft Mechanics and Service Technicians", "65,230", "49,900", "80,410"],
    ["Training and Development Specialists", "65,120", "44,480", "80,870"],
    ["Electrical and Electronics Engineering Technicians", "65,050", "49,630", "77,660"],
    ["Miscellaneous Sales Representatives, Services", "64,860", "36,610", "78,890"],
    ["Sales Representatives, Services, All Other", "64,860", "36,610", "78,890"],
    ["Conservation Scientists and Foresters", "64,720", "47,590", "78,260"],
    ["Line Installers and Repairers", "64,540", "44,180", "82,420"],
    ["Electrical and Electronics Drafters", "64,400", "46,330", "78,200"],
    ["Special Education Teachers, Middle School", "64,390", "48,820", "77,080"],
    ["Rail Transportation Workers", "64,380", "52,020", "76,950"],
    ["Pile-Driver Operators", "64,360", "43,440", "85,520"],
    ["Supervisors of Production Workers", "64,340", "46,290", "78,060"],
    ["First-Line Supervisors of Production and Operating Workers", "64,340", "46,290", "78,060"],
    ["Secondary School Teachers, Except Special and Career/Technical Education", "64,340", "47,980", "77,720"],
    ["Secondary School Teachers", "64,230", "48,070", "77,500"],
    ["Fire Inspectors and Investigators", "64,140", "46,440", "77,480"],
    ["Special Education Teachers", "63,890", "47,570", "76,620"],
    ["Stationary Engineers and Boiler Operators", "63,690", "47,780", "76,550"],
    ["Sound Engineering Technicians", "63,500", "33,260", "79,770"],
    ["Advertising Sales Agents", "63,360", "34,700", "76,840"],
    ["Healthcare Practitioners and Technical Workers, All Other", "63,340", "37,070", "84,850"],
    ["Survey Researchers", "63,240", "41,200", "78,360"],
    ["Boilermakers", "63,240", "51,070", "76,870"],
    ["Construction and Building Inspectors", "63,150", "45,920", "77,400"],
    ["Foresters", "63,150", "50,400", "73,550"],
    ["Special Education Teachers, Kindergarten and Elementary School", "63,110", "47,520", "75,940"],
    ["Subway and Streetcar Operators", "62,970", "50,510", "76,670"],
    ["Gas Compressor and Gas Pumping Station Operators", "62,900", "50,620", "75,660"],
    ["Geological and Petroleum Technicians", "62,890", "36,990", "78,220"],
    ["Career/Technical Education Teachers, Secondary School", "62,810", "49,220", "74,930"],
    ["Social Workers, All Other", "62,660", "45,380", "78,680"],
    ["Career/Technical Education Teachers, Middle School", "62,570", "47,770", "74,770"],
    ["Special Education Teachers, All Other", "62,500", "44,030", "76,110"],
    ["Respiratory Therapists", "62,500", "51,430", "72,760"],
    ["Forensic Science Technicians", "62,490", "44,340", "76,440"],
    ["Court Reporters", "62,390", "40,460", "81,460"],
    ["Lodging Managers", "62,270", "39,270", "74,350"],
    ["Elementary School Teachers, Except Special Education", "62,200", "46,120", "75,330"],
    ["Elementary and Middle School Teachers", "62,150", "46,340", "75,090"],
    ["Fire Inspectors", "62,030", "43,180", "76,370"],
    ["Middle School Teachers, Except Special and Career/Technical Education", "62,030", "46,840", "74,600"],
    ["Appraisers and Assessors of Real Estate", "61,870", "40,310", "77,290"],
    ["Plant and System Operators", "61,820", "43,570", "78,360"],
    ["Camera Operators, Television, Video, and Motion Picture", "61,750", "36,520", "77,290"],
    ["Real Estate Sales Agents", "61,720", "33,020", "74,530"],
    ["Fundraisers", "61,610", "43,540", "74,860"],
    ["Special Education Teachers, Preschool", "61,610", "44,020", "72,570"],
    ["Chemical Plant and System Operators", "61,570", "46,450", "76,630"],
    ["Executive Secretaries and Executive Administrative Assistants", "61,550", "46,530", "74,460"],
    ["Radiologic Technologists", "61,540", "48,400", "73,200"],
    ["Librarians", "61,530", "46,130", "74,740"],
    ["Electrical and Electronics Installers and Repairers, Transportation Equipment", "61,460", "47,930", "74,480"],
    ["Engineering Technicians, Except Drafters", "61,380", "45,030", "74,690"],
    ["Dietitians and Nutritionists", "61,210", "48,880", "73,740"],
    ["Law Enforcement Workers", "61,190", "41,150", "76,910"],
    ["Set and Exhibit Designers", "61,020", "39,650", "74,830"],
    ["Miscellaneous Legal Support Workers", "60,710", "39,110", "72,880"],
    ["News Analysts, Reporters and Correspondents", "60,530", "30,380", "72,520"],
    ["Tax Examiners and Collectors, and Revenue Agents", "60,500", "41,420", "75,820"],
    ["Occupational Therapy Assistants", "60,410", "50,510", "71,820"],
    ["Electro-Mechanical Technicians", "60,240", "45,720", "72,560"],
    ["Miscellaneous Postsecondary Teachers", "60,200", "34,650", "74,730"],
    ["Educational, Guidance, School, and Vocational Counselors", "60,160", "43,210", "73,740"],
    ["Preschool, Primary, Secondary, and Special Education School Teachers", "59,980", "43,770", "73,830"],
    ["Entertainers and Performers, Sports and Related Workers", "59,900", "25,500", "70,800"],
    ["Music Directors and Composers", "59,790", "30,950", "69,770"],
    ["Arts, Design, Entertainment, Sports, and Media Occupations", "59,780", "32,010", "73,690"],
    ["Health Educators", "59,660", "39,800", "74,660"],
    ["Patternmakers, Wood", "59,650", "46,870", "73,330"],
    ["Judicial Law Clerks", "59,540", "41,740", "70,800"],
    ["Commercial Divers", "59,470", "37,840", "66,570"],
    ["Drafters, Engineering Technicians, and Mapping Technicians", "59,410", "43,530", "72,290"],
    ["Supervisors of Office and Administrative Support Workers", "59,340", "42,750", "71,550"],
    ["First-Line Supervisors of Office and Administrative Support Workers", "59,340", "42,750", "71,550"],
    ["Fish and Game Wardens", "59,260", "47,080", "69,900"],
    ["Electrical and Electronics Repairers, Commercial and Industrial Equipment", "59,210", "46,190", "69,950"],
    ["Electricians", "59,190", "41,260", "72,780"],
    ["Interior Designers", "59,120", "40,080", "71,430"],
    ["Roof Bolters, Mining", "59,090", "50,370", "67,580"],
    ["Mechanical Drafters", "59,010", "44,230", "70,190"],
    ["Telecommunications Line Installers and Repairers", "59,000", "38,460", "77,820"],
    ["Food Service Managers", "58,960", "40,650", "71,200"],
    ["Railroad Brake, Signal, and Switch Operators", "58,890", "45,170", "72,620"],
    ["Industrial Engineering Technicians", "58,860", "43,690", "70,380"],
    ["Probation Officers and Correctional Treatment Specialists", "58,790", "41,950", "71,240"],
    ["Cardiovascular Technologists and Technicians", "58,730", "38,170", "75,240"],
    ["Vocational Education Teachers, Postsecondary", "58,520", "40,880", "70,340"],
    ["Miscellaneous Rail Transportation Workers", "58,490", "41,330", "73,770"],
    ["Rail Transportation Workers, All Other", "58,490", "41,330", "73,770"],
    ["Curators", "58,490", "39,580", "72,830"],
    ["Healthcare Social Workers", "58,470", "43,530", "70,280"],
    ["Fine Artists, Including Painters, Sculptors, and Illustrators", "58,370", "31,750", "70,190"],
    ["Kindergarten Teachers, Except Special Education", "58,370", "44,310", "69,420"],
    ["Plant and System Operators, All Other", "58,300", "44,450", "69,790"],
    ["Mechanical Engineering Technicians", "58,240", "43,800", "70,630"],
    ["Drafters", "58,180", "43,850", "69,280"],
    ["Structural Iron and Steel Workers", "58,170", "39,390", "74,120"],
    ["Crane and Tower Operators", "58,160", "39,750", "70,940"],
    ["Plumbers, Pipefitters, and Steamfitters", "58,150", "40,250", "71,140"],
    ["First-Line Supervisors of Transportation and Material Moving Workers, Except Aircraft Cargo Handling Supervisors", "58,140", "41,260", "71,280"],
    ["Adult Basic and Secondary Education and Literacy Teachers and Instructors", "58,110", "42,200", "71,010"],
    ["Supervisors of Transportation and Material Moving Workers", "58,070", "41,160", "71,220"],
    ["Precision Instrument and Equipment Repairers, All Other", "58,060", "45,390", "68,420"],
    ["Miscellaneous Health Practitioners and Technical Workers", "58,000", "38,580", "71,390"],
    ["Wind Turbine Service Technicians", "58,000", "44,430", "69,550"],
    ["Computer Support Specialists", "57,950", "40,950", "70,380"],
    ["Control and Valve Installers and Repairers, Except Mechanical Door", "57,890", "39,990", "74,410"],
    ["Physical Therapist Assistants", "57,750", "46,800", "68,700"],
    ["Therapists, All Other", "57,680", "39,720", "70,000"],
    ["Morticians, Undertakers, and Funeral Directors", "57,620", "38,770", "70,500"],
    ["Tapers", "57,340", "40,930", "71,660"],
    ["Rail-Track Laying and Maintenance Equipment Operators", "57,120", "44,210", "69,460"],
    ["Telecommunications Equipment Installers and Repairers, Except Line Installers", "57,080", "42,400", "71,780"],
    ["Radio and Telecommunications Equipment Installers and Repairers", "57,030", "42,300", "71,730"],
    ["Pipelayers, Plumbers, Pipefitters, and Steamfitters", "56,980", "38,990", "69,650"],
    ["Model Makers, Metal and Plastic", "56,920", "40,920", "71,350"],
    ["Private Detectives and Investigators", "56,810", "37,060", "68,400"],
    ["Airfield Operations Specialists", "56,760", "37,310", "69,720"],
    ["Rotary Drill Operators, Oil and Gas", "56,740", "41,090", "68,250"],
    ["Architectural and Civil Drafters", "56,700", "43,660", "67,120"],
    ["Flight Attendants", "56,630", "42,320", "68,850"],
    ["Education, Training, and Library Occupations", "56,620", "32,550", "70,530"],
    ["Archivists", "56,400", "38,090", "71,250"],
    ["Hoist and Winch Operators", "56,390", "34,190", "74,840"],
    ["Radio, Cellular, and Tower Equipment Installers and Repairers", "56,340", "40,600", "70,590"],
    ["Computer Numerically Controlled Machine Tool Programmers, Metal and Plastic", "56,300", "42,100", "66,280"],
    ["Millwrights", "56,250", "42,550", "68,760"],
    ["Occupational Therapy Assistants and Aides", "56,150", "43,450", "69,670"],
    ["Mine Shuttle Car Operators", "56,150", "49,560", "61,920"],
    ["Legal Support Workers", "56,130", "38,920", "66,420"],
    ["Rail Car Repairers", "56,020", "42,710", "69,240"],
    ["Media and Communication Equipment Workers", "55,970", "31,210", "69,200"],
    ["Hearing Aid Specialists", "55,650", "38,380", "67,090"],
    ["Model Makers and Patternmakers, Wood", "55,540", "39,700", "67,650"],
    ["Aircraft Structure, Surfaces, Rigging, and Systems Assemblers", "55,530", "40,570", "70,010"],
    ["Reporters and Correspondents", "55,530", "29,730", "66,680"],
    ["Occupational Health and Safety Technicians", "55,270", "39,390", "66,300"],
    ["Art and Design Workers", "55,230", "31,080", "69,120"],
    ["Interpreters and Translators", "55,230", "36,470", "67,660"],
    ["Aircraft Cargo Handling Supervisors", "55,110", "38,260", "68,290"],
    ["Computer User Support Specialists", "55,050", "39,310", "65,640"],
    ["Environmental Engineering Technicians", "54,800", "38,510", "65,460"],
    ["Exercise Physiologists", "54,760", "40,770", "61,950"],
    ["Graphic Designers", "54,680", "37,550", "66,300"],
    ["Reinforcing Iron and Rebar Workers", "54,670", "37,600", "67,760"],
    ["Civil Engineering Technicians", "54,670", "40,160", "65,370"],
    ["Rail Yard Engineers, Dinkey Operators, and Hostlers", "54,640", "42,080", "63,220"],
    ["Miscellaneous Electrical and Electronic Equipment Mechanics, Installers, and Repairers", "54,570", "38,550", "67,230"],
    ["Paralegals and Legal Assistants", "54,500", "38,860", "65,080"],
    ["Miscellaneous Media and Communication Workers", "54,490", "35,390", "67,230"],
    ["Continuous Mining Machine Operators", "54,470", "44,090", "64,710"],
    ["Brickmasons and Blockmasons", "54,430", "39,710", "64,140"],
    ["Miscellaneous Extraction Workers", "54,300", "40,800", "65,450"],
    ["Extraction Workers, All Other", "54,300", "40,800", "65,450"],
    ["Drafters, All Other", "54,240", "40,310", "63,740"],
    ["Marriage and Family Therapists", "54,150", "38,170", "63,300"],
    ["Industrial Machinery Mechanics", "54,000", "41,610", "63,740"],
    ["Education Administrators, Preschool and Childcare Center/Program", "53,990", "37,610", "62,500"],
    ["Brokerage Clerks", "53,940", "42,180", "62,610"],
    ["Clinical Laboratory Technologists and Technicians", "53,880", "38,310", "66,920"],
    ["Wellhead Pumpers", "53,870", "38,700", "66,500"],
    ["Meeting, Convention, and Event Planners", "53,730", "36,720", "64,910"],
    ["Tool and Die Makers", "53,650", "41,370", "63,820"],
    ["Fire Fighting and Prevention Workers", "53,630", "34,110", "67,760"],
    ["Choreographers", "53,560", "30,970", "68,760"],
    ["Control and Valve Installers and Repairers", "53,460", "36,860", "67,740"],
    ["Model Makers and Patternmakers, Metal and Plastic", "53,430", "38,650", "65,610"],
    ["Mobile Heavy Equipment Mechanics, Except Engines", "53,370", "41,700", "62,830"],
    ["Industrial Machinery Installation, Repair, and Maintenance Workers", "53,330", "40,860", "63,330"],
    ["Clergy", "53,290", "35,480", "63,930"],
    ["Archivists, Curators, and Museum Technicians", "53,280", "35,890", "65,310"],
    ["Firefighters", "53,240", "33,720", "67,100"],
    ["Electrical and Electronic Equipment Mechanics, Installers, and Repairers", "53,190", "37,580", "66,570"],
    ["Insulation Workers, Mechanical", "53,180", "36,860", "64,070"],
    ["Mining Machine Operators", "53,090", "42,080", "64,090"],
    ["Social Workers", "53,060", "37,550", "63,910"],
    ["Operating Engineers and Other Construction Equipment Operators", "53,030", "37,230", "63,540"],
    ["Derrick, Rotary Drill, and Service Unit Operators, Oil, Gas, and Mining", "52,950", "38,580", "62,130"],
    ["Life, Physical, and Social Science Technicians, All Other", "52,940", "37,940", "63,340"],
    ["Farm Labor Contractors", "52,930", "32,800", "73,630"],
    ["Tax Examiners, Collectors and Preparers, and Revenue Agents", "52,930", "32,170", "66,860"],
    ["Mining Machine Operators, All Other", "52,920", "41,210", "63,020"],
    ["Brickmasons, Blockmasons, and Stonemasons", "52,810", "38,030", "62,790"],
    ["Explosives Workers, Ordnance Handling Experts, and Blasters", "52,780", "41,190", "62,050"],
    ["Service Unit Operators, Oil, Gas, and Mining", "52,780", "37,990", "61,630"],
    ["Miscellaneous First-Line Supervisors, Protective Service Workers", "52,730", "35,710", "63,280"],
    ["First-Line Supervisors of Protective Service Workers, All Other", "52,730", "35,710", "63,280"],
    ["Sheet Metal Workers", "52,710", "35,390", "64,970"],
    ["Medical Equipment Repairers", "52,710", "36,520", "64,630"],
    ["Farm and Home Management Advisors", "52,700", "37,110", "65,250"],
    ["Model Makers, Wood", "52,590", "37,400", "63,750"],
    ["Pumping Station Operators", "52,510", "37,650", "65,470"],
    ["Refractory Materials Repairers, Except Brickmasons", "52,510", "40,870", "61,400"],
    ["Media and Communication Workers, All Other", "52,430", "31,840", "65,930"],
    ["Audio-Visual and Multimedia Collections Specialists", "52,270", "35,410", "66,630"],
    ["Construction Equipment Operators", "52,190", "36,620", "62,670"],
    ["Chefs and Head Cooks", "52,160", "35,570", "64,500"],
    ["All Occupations", "51,960", "25,740", "62,510"],
    ["Supervisors of Sales Workers", "51,930", "32,710", "60,760"],
    ["First-Line Supervisors of Sales Workers", "51,930", "32,710", "60,760"],
    ["Counselors", "51,920", "35,860", "62,880"],
    ["Postal Service Mail Carriers", "51,780", "37,000", "62,500"],
    ["Heavy Vehicle and Mobile Equipment Service Technicians and Mechanics", "51,780", "39,680", "62,010"],
    ["Chemical Technicians", "51,670", "36,900", "62,860"],
    ["Radio and Television Announcers", "51,630", "23,470", "52,140"],
    ["Postal Service Workers", "51,540", "37,000", "62,490"],
    ["Loading Machine Operators, Underground Mining", "51,450", "39,160", "61,080"],
    ["Respiratory Therapy Technicians", "51,380", "40,810", "61,910"],
    ["Title Examiners, Abstractors, and Searchers", "51,380", "36,040", "61,550"],
    ["Other Sales and Related Workers", "51,380", "24,740", "61,260"],
    ["Riggers", "51,330", "37,420", "61,810"],
    ["Librarians, Curators, and Archivists", "51,290", "33,690", "64,010"],
    ["First-Line Supervisors of Landscaping, Lawn Service, and Groundskeeping Workers", "51,280", "37,690", "61,750"],
    ["Postal Service Mail Sorters, Processors, and Processing Machine Operators", "51,250", "38,180", "60,100"],
    ["Construction and Extraction Occupations", "51,220", "34,420", "62,760"],
    ["Precision Instrument and Equipment Repairers", "51,120", "35,410", "62,800"],
    ["Carpenters", "51,120", "35,820", "61,530"],
    ["Chemical Equipment Operators and Tenders", "51,070", "36,750", "63,380"],
    ["Life, Physical, and Social Science Technicians", "50,990", "35,580", "61,230"],
    ["Postal Service Clerks", "50,860", "42,770", "60,110"],
    ["Supervisors of Farming, Fishing, and Forestry Workers", "50,790", "35,570", "62,470"],
    ["First-Line Supervisors of Farming, Fishing, and Forestry Workers", "50,790", "35,570", "62,470"],
    ["Miscellaneous Life, Physical, and Social Science Technicians", "50,720", "35,090", "61,080"],
    ["Traffic Technicians", "50,700", "35,860", "62,270"],
    ["Recreational Therapists", "50,640", "37,220", "61,630"],
    ["Social Science Research Assistants", "50,420", "35,450", "60,830"],
    ["Drywall Installers, Ceiling Tile Installers, and Tapers", "50,420", "35,120", "60,680"],
    ["Motorboat Operators", "50,350", "32,180", "68,100"],
    ["Environmental Science and Protection Technicians, Including Health", "50,350", "35,420", "61,240"],
    ["First-Line Supervisors of Gaming Workers", "50,250", "36,650", "62,240"],
    ["Other Construction and Related Workers", "50,240", "34,570", "60,820"],
    ["Heating, Air Conditioning, and Refrigeration Mechanics and Installers", "50,160", "36,520", "60,900"],
    ["Occupational Therapy and Physical Therapist Assistants and Aides", "50,130", "30,790", "64,740"],
    ["Statistical Assistants", "50,110", "38,660", "59,810"],
    ["Legal Secretaries", "50,040", "35,000", "62,060"],
    ["Designers", "50,020", "29,660", "62,420"],
    ["Production, Planning, and Expediting Clerks", "50,020", "36,740", "61,010"],
    ["Broadcast and Sound Engineering Technicians and Radio Operators", "49,960", "31,540", "61,890"],
    ["Bailiffs", "49,870", "34,180", "64,830"],
    ["Construction Trades Workers", "49,850", "34,050", "61,110"],
    ["Credit Counselors", "49,820", "36,230", "57,800"],
    ["Religious Workers", "49,790", "31,180", "61,470"],
    ["Child, Family, and School Social Workers", "49,760", "36,140", "59,400"],
    ["Mental Health and Substance Abuse Social Workers", "49,630", "34,600", "60,300"],
    ["Forest Fire Inspectors and Prevention Specialists", "49,610", "32,310", "64,400"],
    ["Miscellaneous Health Technologists and Technicians", "49,540", "34,540", "59,420"],
    ["Water and Wastewater Treatment Plant and System Operators", "49,490", "35,980", "60,080"],
    ["Bailiffs, Correctional Officers, and Jailers", "49,320", "36,100", "59,750"],
    ["Correctional Officers and Jailers", "49,300", "36,180", "59,550"],
    ["Community and Social Service Occupations", "49,280", "33,910", "60,390"],
    ["Athletic Trainers", "49,280", "39,140", "58,320"],
    ["Counselors, Social Workers, and Other Community and Social Service Specialists", "49,270", "33,990", "60,350"],
    ["Fabric and Apparel Patternmakers", "49,180", "29,990", "65,230"],
    ["Drywall and Ceiling Tile Installers", "49,170", "34,580", "58,350"],
    ["Bus and Truck Mechanics and Diesel Engine Specialists", "49,150", "37,710", "59,210"],
    ["Mine Cutting and Channeling Machine Operators", "49,080", "35,940", "61,830"],
    ["Surgical Technologists", "49,040", "38,910", "58,040"],
    ["Installation, Maintenance, and Repair Occupations", "48,960", "33,590", "60,950"],
    ["Announcers", "48,960", "22,990", "50,260"],
    ["Audio and Video Equipment Technicians", "48,940", "32,770", "60,290"],
    ["Dredge, Excavating, and Loading Machine Operators", "48,790", "35,750", "57,200"],
    ["Maintenance Workers, Machinery", "48,720", "36,810", "59,010"],
    ["Excavating and Loading Machine and Dragline Operators", "48,680", "35,580", "56,810"],
    ["Glaziers", "48,620", "33,810", "58,870"],
    ["Protective Service Occupations", "48,580", "28,000", "62,090"],
    ["Security and Fire Alarm Systems Installers", "48,540", "36,450", "59,370"],
    ["Fallers", "48,520", "34,800", "58,610"],
    ["Chemical Processing Machine Setters, Operators, and Tenders", "48,470", "34,680", "60,580"],
    ["Health Technologists and Technicians", "48,380", "33,860", "59,550"],
    ["Biological Technicians", "48,060", "35,190", "57,400"],
    ["Teachers and Instructors, All Other, Except Substitute Teachers", "48,040", "26,680", "60,520"],
    ["Physical Therapist Assistants and Aides", "47,980", "28,800", "62,940"],
    ["Substance Abuse, Behavioral Disorder, and Mental Health Counselors", "47,920", "34,950", "57,580"],
    ["Extraction Workers", "47,760", "35,090", "57,520"],
    ["Counselors, All Other", "47,740", "32,490", "59,340"],
    ["Surveying and Mapping Technicians", "47,690", "34,300", "58,250"],
    ["Bridge and Lock Tenders", "47,660", "36,210", "57,920"],
    ["Derrick Operators, Oil and Gas", "47,630", "38,580", "55,420"],
    ["Legislators", "47,620", "18,840", "72,800"],
    ["Plasterers and Stucco Masons", "47,610", "34,720", "57,210"],
    ["Earth Drillers, Except Oil and Gas", "47,570", "35,960", "56,230"],
    ["Pump Operators, Except Wellhead Pumpers", "47,510", "34,740", "58,000"],
    ["Dredge Operators", "47,500", "36,630", "55,100"],
    ["Health Technologists and Technicians, All Other", "47,450", "33,980", "56,780"],
    ["Layout Workers, Metal and Plastic", "47,380", "36,480", "57,380"],
    ["Cement Masons and Concrete Finishers", "47,350", "34,550", "56,230"],
    ["Cement Masons, Concrete Finishers, and Terrazzo Workers", "47,340", "34,540", "56,230"],
    ["Terrazzo Workers and Finishers", "47,230", "34,260", "56,330"],
    ["Patternmakers, Metal and Plastic", "47,130", "35,920", "57,510"],
    ["Hazardous Materials Removal Workers", "47,050", "33,880", "57,230"],
    ["Licensed Practical and Licensed Vocational Nurses", "47,050", "39,470", "54,110"],
    ["Directors, Religious Activities and Education", "46,980", "27,890", "59,090"],
    ["Insulation Workers", "46,910", "33,220", "55,690"],
    ["Museum Technicians and Conservators", "46,870", "32,580", "56,730"],
    ["Tax Preparers", "46,860", "26,280", "60,120"],
    ["Broadcast Technicians", "46,770", "27,200", "60,920"],
    ["Floor Layers, Except Carpet, Wood, and Hard Tiles", "46,760", "32,060", "57,800"],
    ["Desktop Publishers", "46,750", "31,290", "58,500"],
    ["Computer Operators", "46,750", "35,260", "58,150"],
    ["Miscellaneous Education, Training, and Library Workers", "46,680", "28,030", "58,860"],
    ["Education, Training, and Library Workers, All Other", "46,680", "28,030", "58,860"],
    ["Dancers and Choreographers", "46,670", "24,000", "60,240"],
    ["Embalmers", "46,640", "33,970", "56,740"],
    ["Eligibility Interviewers, Government Programs", "46,480", "36,970", "55,180"],
    ["Automotive Body and Related Repairers", "46,460", "33,090", "56,480"],
    ["Painters, Transportation Equipment", "46,460", "33,460", "54,610"],
    ["Other Installation, Maintenance, and Repair Occupations", "46,380", "32,450", "57,790"],
    ["Supervisors of Building and Grounds Cleaning and Maintenance Workers", "46,380", "33,460", "56,670"],
    ["First-Line Supervisors of Building and Grounds Cleaning and Maintenance Workers", "46,380", "33,460", "56,670"],
    ["Vehicle and Mobile Equipment Mechanics, Installers, and Repairers", "46,200", "32,160", "57,670"],
    ["Sailors and Marine Oilers", "46,180", "29,790", "54,860"],
    ["Payroll and Timekeeping Clerks", "46,110", "36,000", "55,320"],
    ["Cargo and Freight Agents", "46,070", "33,870", "55,430"],
    ["Community and Social Service Specialists, All Other", "46,050", "33,040", "55,790"],
    ["Costume Attendants", "46,010", "28,880", "52,550"],
    ["Solar Photovoltaic Installers", "46,010", "35,310", "52,410"],
    ["Agricultural Inspectors", "45,970", "33,810", "53,780"],
    ["Tile and Marble Setters", "45,950", "31,930", "55,450"],
    ["Massage Therapists", "45,880", "28,450", "57,730"],
    ["Electric Motor, Power Tool, and Related Repairers", "45,770", "33,760", "54,950"],
    ["Heavy and Tractor-Trailer Truck Drivers", "45,570", "35,040", "54,400"],
    ["Tire Builders", "45,530", "34,550", "57,270"],
    ["Engine and Other Machine Assemblers", "45,330", "34,350", "56,340"],
    ["Carpet, Floor, and Tile Installers and Finishers", "45,330", "31,180", "55,330"],
    ["Machinists", "45,250", "34,130", "54,830"],
    ["First-Line Supervisors of Retail Sales Workers", "45,080", "31,210", "53,060"],
    ["Athletes, Coaches, Umpires, and Related Workers", "45,080", "22,990", "52,940"],
    ["Self-Enrichment Education Teachers", "44,960", "26,760", "56,390"],
    ["Watch Repairers", "44,830", "29,600", "55,950"],
    ["Radio Operators", "44,710", "29,480", "57,510"],
    ["Bus Drivers, Transit and Intercity", "44,650", "31,870", "56,680"],
    ["Meter Readers, Utilities", "44,640", "30,010", "55,820"],
    ["Fitness Trainers and Aerobics Instructors", "44,580", "25,780", "56,760"],
    ["Carpet Installers", "44,550", "29,560", "55,640"],
    ["Milling and Planing Machine Setters, Operators, and Tenders, Metal and Plastic", "44,490", "33,380", "54,090"],
    ["Stonemasons", "44,370", "32,230", "53,900"],
    ["Welders, Cutters, Solderers, and Brazers", "44,360", "34,180", "51,430"],
    ["Paving, Surfacing, and Tamping Equipment Operators", "44,360", "32,570", "51,320"],
    ["Agricultural and Food Science Technicians", "44,170", "33,050", "51,170"],
    ["Separating, Filtering, Clarifying, Precipitating, and Still Machine Setters, Operators, and Tenders", "44,140", "32,060", "53,790"],
    ["Automotive Technicians and Repairers", "44,030", "30,640", "54,720"],
    ["Medical Records and Health Information Technicians", "44,010", "32,070", "52,730"],
    ["Computer Control Programmers and Operators", "43,940", "33,450", "52,000"],
    ["Welding, Soldering, and Brazing Workers", "43,930", "33,930", "50,950"],
    ["Coaches and Scouts", "43,870", "23,180", "52,760"],
    ["Roofers", "43,870", "31,910", "52,140"],
    ["Locksmiths and Safe Repairers", "43,740", "30,910", "55,140"],
    ["Automotive Service Technicians and Mechanics", "43,730", "30,220", "54,720"],
    ["Miscellaneous Financial Clerks", "43,670", "33,740", "50,590"],
    ["Financial Clerks, All Other", "43,670", "33,740", "50,590"],
    ["Jewelers and Precious Stone and Metal Workers", "43,570", "29,860", "52,210"],
    ["Pipelayers", "43,500", "31,610", "52,590"],
    ["Community Health Workers", "43,480", "32,280", "51,650"],
    ["Miscellaneous Communications Equipment Operators", "43,410", "31,860", "51,800"],
    ["Communications Equipment Operators, All Other", "43,410", "31,860", "51,800"],
    ["Mechanical Door Repairers", "43,350", "33,280", "50,750"],
    ["Police, Fire, and Ambulance Dispatchers", "43,290", "32,710", "51,480"],
    ["Dental Laboratory Technicians", "43,180", "31,080", "52,100"],
    ["Procurement Clerks", "43,180", "34,700", "50,590"],
    ["First-Line Supervisors of Housekeeping and Janitorial Workers", "43,150", "31,020", "52,280"],
    ["Animal Breeders", "43,080", "28,930", "52,550"],
    ["Installation, Maintenance, and Repair Workers, All Other", "43,050", "30,550", "52,830"],
    ["Painters, Construction and Maintenance", "43,050", "31,790", "51,530"],
    ["Dispatchers", "43,050", "31,470", "51,340"],
    ["Painters and Paperhangers", "43,030", "31,780", "51,450"],
    ["Miscellaneous Construction and Related Workers", "43,000", "31,850", "50,500"],
    ["Dispatchers, Except Police, Fire, and Ambulance", "42,940", "30,790", "51,270"],
    ["Miscellaneous Transportation Workers", "42,870", "27,000", "60,170"],
    ["Transportation Workers, All Other", "42,870", "27,000", "60,170"],
    ["Miscellaneous Community and Social Service Specialists", "42,820", "29,750", "50,620"],
    ["Photographers", "42,770", "23,970", "53,520"],
    ["Travel Agents", "42,720", "28,730", "51,950"],
    ["Supervisors of Personal Care and Service Workers", "42,710", "30,290", "51,860"],
    ["Camera and Photographic Equipment Repairers", "42,540", "30,360", "50,670"],
    ["Logging Workers", "42,340", "32,360", "50,050"],
    ["Motorboat Mechanics and Service Technicians", "42,330", "31,490", "51,760"],
    ["Tank Car, Truck, and Ship Loaders", "42,330", "29,930", "50,340"],
    ["Secretaries and Administrative Assistants", "42,320", "30,710", "50,740"],
    ["Prepress Technicians and Workers", "42,240", "31,560", "51,010"],
    ["Parking Enforcement Workers", "42,200", "30,890", "51,970"],
    ["Farm Equipment Mechanics and Service Technicians", "42,190", "32,800", "50,410"],
    ["Medical Appliance Technicians", "42,180", "30,280", "50,370"],
    ["Insurance Claims and Policy Processing Clerks", "42,150", "33,250", "49,330"],
    ["Bookkeeping, Accounting, and Auditing Clerks", "42,110", "32,220", "50,480"],
    ["Insulation Workers, Floor, Ceiling, and Wall", "42,070", "31,020", "49,400"],
    ["Inspectors, Testers, Sorters, Samplers, and Weighers", "42,010", "29,390", "50,980"],
    ["Computer-Controlled Machine Tool Operators, Metal and Plastic", "41,960", "32,690", "49,900"],
    ["Proofreaders and Copy Markers", "41,950", "29,910", "51,130"],
    ["Miscellaneous Information and Record Clerks", "41,900", "31,970", "49,950"],
    ["Information and Record Clerks, All Other", "41,900", "31,970", "49,950"],
    ["Transportation Security Screeners", "41,860", "37,820", "44,260"],
    ["Logging Equipment Operators", "41,840", "32,260", "49,980"],
    ["Metal-Refining Furnace Operators and Tenders", "41,810", "31,820", "50,670"],
    ["Sales and Related Occupations", "41,790", "22,120", "47,570"],
    ["Reservation and Transportation Ticket Agents and Travel Clerks", "41,730", "27,910", "57,000"],
    ["First-Line Supervisors of Personal Service Workers", "41,710", "29,830", "50,260"],
    ["Structural Metal Fabricators and Fitters", "41,640", "32,160", "48,870"],
    ["Human Resources Assistants, Except Payroll and Timekeeping", "41,620", "33,550", "48,680"],
    ["Septic Tank Servicers and Sewer Pipe Cleaners", "41,530", "31,410", "50,050"],
    ["Drilling and Boring Machine Tool Setters, Operators, and Tenders, Metal and Plastic", "41,490", "29,860", "49,930"],
    ["Metal Workers and Plastic Workers", "41,480", "30,910", "49,780"],
    ["Highway Maintenance Workers", "41,440", "32,250", "49,330"],
    ["Loan Interviewers and Clerks", "41,310", "32,370", "49,510"],
    ["Cooks, Private Household", "41,240", "29,170", "50,870"],
    ["Metal Furnace Operators, Tenders, Pourers, and Casters", "41,160", "31,520", "49,630"],
    ["Word Processors and Typists", "41,160", "32,810", "48,210"],
    ["Funeral Service Workers", "41,130", "24,570", "51,020"],
    ["Court, Municipal, and License Clerks", "41,100", "30,660", "48,560"],
    ["Lathe and Turning Machine Tool Setters, Operators, and Tenders, Metal and Plastic", "41,090", "31,270", "48,970"],
    ["Home Appliance Repairers", "41,020", "30,410", "50,100"],
    ["Maintenance and Repair Workers, General", "41,020", "29,560", "50,100"],
    ["Logging Workers, All Other", "40,940", "32,640", "48,920"],
    ["Driver/Sales Workers and Truck Drivers", "40,920", "28,480", "50,640"],
    ["Tool Grinders, Filers, and Sharpeners", "40,890", "30,700", "49,270"],
    ["Computer, Automated Teller, and Office Machine Repairers", "40,880", "30,200", "49,380"],
    ["Paperhangers", "40,840", "31,330", "47,170"],
    ["Rolling Machine Setters, Operators, and Tenders, Metal and Plastic", "40,790", "30,650", "49,760"],
    ["Forging Machine Setters, Operators, and Tenders, Metal and Plastic", "40,770", "31,170", "49,590"],
    ["Credit Authorizers, Checkers, and Clerks", "40,670", "31,670", "48,430"],
    ["Furnace, Kiln, Oven, Drier, and Kettle Operators and Tenders", "40,610", "31,470", "48,870"],
    ["Refuse and Recyclable Material Collectors", "40,560", "28,280", "49,260"],
    ["Tree Trimmers and Pruners", "40,510", "30,300", "49,680"],
    ["Forest, Conservation, and Logging Workers", "40,500", "29,600", "48,840"],
    ["Craft Artists", "40,490", "25,990", "48,720"],
    ["Sales and Related Workers, All Other", "40,480", "24,460", "48,800"],
    ["Construction Laborers", "40,350", "28,520", "47,910"],
    ["Paper Goods Machine Setters, Operators, and Tenders", "40,320", "29,510", "49,200"],
    ["Other Teachers and Instructors", "40,250", "24,260", "48,440"],
    ["Roustabouts, Oil and Gas", "40,220", "31,500", "47,200"],
    ["Forest and Conservation Technicians", "40,110", "29,840", "48,460"],
    ["Recreational Vehicle Service Technicians", "40,090", "31,320", "48,280"],
    ["Preschool and Kindergarten Teachers", "40,070", "25,510", "49,820"],
    ["Miscellaneous Installation, Maintenance, and Repair Workers", "40,040", "27,610", "49,350"],
    ["Rehabilitation Counselors", "39,930", "27,490", "48,420"],
    ["Opticians, Dispensing", "39,930", "29,190", "48,160"],
    ["Miscellaneous Sales and Related Workers", "39,910", "24,190", "48,190"],
    ["Floor Sanders and Finishers", "39,890", "30,930", "46,820"],
    ["Painting Workers", "39,850", "29,270", "47,220"],
    ["Medical, Dental, and Ophthalmic Laboratory Technicians", "39,840", "28,550", "48,100"],
    ["Semiconductor Processors", "39,810", "30,390", "46,960"],
    ["Electronic Home Entertainment Equipment Installers and Repairers", "39,800", "29,760", "49,120"],
    ["Dental Assistants", "39,770", "32,200", "47,070"],
    ["Pourers and Casters, Metal", "39,670", "31,080", "47,170"],
    ["Financial Clerks", "39,660", "29,950", "47,670"],
    ["Billing and Posting Clerks", "39,520", "31,870", "46,350"],
    ["Motor Vehicle Operators", "39,400", "27,030", "48,980"],
    ["Telephone Operators", "39,360", "28,290", "49,520"],
    ["Mixing and Blending Machine Setters, Operators, and Tenders", "39,360", "29,740", "47,190"],
    ["Motorcycle Mechanics", "39,260", "29,220", "47,640"],
    ["Production Occupations", "39,190", "26,820", "47,470"],
    ["Small Engine Mechanics", "39,180", "29,120", "47,670"],
    ["Welding, Soldering, and Brazing Machine Setters, Operators, and Tenders", "39,160", "31,050", "46,160"],
    ["Musical Instrument Repairers and Tuners", "39,110", "28,000", "47,500"],
    ["Healthcare Support Workers, All Other", "39,110", "29,060", "47,330"],
    ["Log Graders and Scalers", "39,060", "29,760", "46,930"],
    ["Heat Treating Equipment Setters, Operators, and Tenders, Metal and Plastic", "39,050", "30,260", "46,750"],
    ["Public Address System and Other Announcers", "39,040", "21,850", "44,170"],
    ["Office and Administrative Support Occupations", "38,990", "27,280", "47,320"],
    ["Correspondence Clerks", "38,990", "30,330", "46,680"],
    ["Animal Control Workers", "38,490", "29,020", "46,130"],
    ["Printing Press Operators", "38,470", "28,010", "46,980"],
    ["Transportation and Material Moving Occupations", "38,290", "24,700", "45,670"],
    ["Printing Workers", "38,260", "27,870", "46,740"],
    ["Ophthalmic Medical Technicians", "38,220", "29,530", "45,530"],
    ["Bill and Account Collectors", "38,220", "29,420", "44,710"],
    ["Pesticide Handlers, Sprayers, and Applicators, Vegetation", "38,210", "28,740", "42,630"],
    ["Forming Machine Setters, Operators, and Tenders, Metal and Plastic", "38,170", "29,070", "46,240"],
    ["Metal Workers and Plastic Workers, All Other", "38,140", "27,810", "46,620"],
    ["Supervisors of Food Preparation and Serving Workers", "38,070", "26,190", "46,780"],
    ["Crushing, Grinding, and Polishing Machine Setters, Operators, and Tenders", "38,060", "29,020", "45,700"],
    ["Secretaries and Administrative Assistants, Except Legal, Medical, and Executive", "38,030", "28,930", "46,230"],
    ["Medical Equipment Preparers", "37,990", "29,590", "44,750"],
    ["Crushing, Grinding, Polishing, Mixing, and Blending Workers", "37,960", "28,400", "45,620"],
    ["Pest Control Workers", "37,950", "28,400", "45,830"],
    ["Miscellaneous Metal Workers and Plastic Workers", "37,940", "28,220", "46,030"],
    ["Miscellaneous Teachers and Instructors", "37,840", "23,530", "43,950"],
    ["Emergency Medical Technicians and Paramedics", "37,760", "27,300", "44,640"],
    ["Psychiatric Technicians", "37,760", "26,940", "43,680"],
    ["Helpers--Extraction Workers", "37,660", "29,850", "44,720"],
    ["Fence Erectors", "37,650", "28,330", "42,870"],
    ["Multiple Machine Tool Setters, Operators, and Tenders, Metal and Plastic", "37,510", "28,210", "44,800"],
    ["Miscellaneous Office and Administrative Support Workers", "37,480", "24,920", "46,310"],
    ["Office and Administrative Support Workers, All Other", "37,480", "24,920", "46,310"],
    ["Timing Device Assemblers and Adjusters", "37,460", "28,790", "43,950"],
    ["Machine Tool Cutting Setters, Operators, and Tenders, Metal and Plastic", "37,450", "28,480", "44,700"],
    ["Other Production Occupations", "37,270", "26,420", "45,060"],
    ["Medical Secretaries", "37,090", "29,580", "43,200"],
    ["New Accounts Clerks", "37,000", "30,300", "42,050"],
    ["Outdoor Power Equipment and Other Small Engine Mechanics", "36,940", "27,960", "44,520"],
    ["Health Practitioner Support Technologists and Technicians", "36,930", "27,770", "43,700"],
    ["Light Truck or Delivery Services Drivers", "36,920", "24,520", "45,610"],
    ["Foundry Mold and Coremakers", "36,820", "28,860", "43,860"],
    ["Extruding, Forming, Pressing, and Compacting Machine Setters, Operators, and Tenders", "36,800", "28,310", "44,460"],
    ["Electronic Equipment Installers and Repairers, Motor Vehicles", "36,750", "28,390", "43,980"],
    ["Grinding, Lapping, Polishing, and Buffing Machine Tool Setters, Operators, and Tenders, Metal and Plastic", "36,690", "28,260", "43,440"],
    ["Extruding and Drawing Machine Setters, Operators, and Tenders, Metal and Plastic", "36,620", "28,290", "44,050"],
    ["Coil Winders, Tapers, and Finishers", "36,610", "27,350", "44,180"],
    ["Cabinetmakers and Bench Carpenters", "36,580", "27,590", "43,650"],
    ["Coating, Painting, and Spraying Machine Setters, Operators, and Tenders", "36,510", "28,160", "43,210"],
    ["Other Healthcare Support Occupations", "36,500", "28,440", "42,180"],
    ["Industrial Truck and Tractor Operators", "36,480", "28,710", "42,360"],
    ["Customer Service Representatives", "36,470", "26,730", "42,970"],
    ["Umpires, Referees, and Other Sports Officials", "36,440", "21,220", "41,880"],
    ["Graduate Teaching Assistants", "36,390", "21,640", "46,670"],
    ["Coin, Vending, and Amusement Machine Servicers and Repairers", "36,390", "26,180", "44,290"],
    ["Helpers--Brickmasons, Blockmasons, Stonemasons, and Tile and Marble Setters", "36,390", "27,700", "40,070"],
    ["Automotive Glass Installers and Repairers", "36,370", "27,760", "43,260"],
    ["Skincare Specialists", "36,350", "23,210", "44,580"],
    ["Medical Transcriptionists", "36,350", "26,410", "43,490"],
    ["Bus Drivers", "36,280", "26,320", "44,360"],
    ["Gaming Surveillance Officers and Gaming Investigators", "36,200", "26,910", "41,420"],
    ["First-Line Supervisors of Food Preparation and Serving Workers", "36,190", "25,690", "44,050"],
    ["Cutting, Punching, and Press Machine Setters, Operators, and Tenders, Metal and Plastic", "36,180", "27,900", "42,440"],
    ["Fiberglass Laminators and Fabricators", "36,170", "28,390", "40,810"],
    ["Library Technicians", "36,080", "25,550", "44,670"],
    ["Material Recording, Scheduling, Dispatching, and Distributing Workers", "35,950", "24,440", "43,730"],
    ["Other Office and Administrative Support Workers", "35,940", "25,790", "43,190"],
    ["Upholsterers", "35,920", "27,240", "42,360"],
    ["Electrical, Electronics, and Electromechanical Assemblers", "35,910", "27,110", "42,020"],
    ["Recreation and Fitness Workers", "35,890", "22,440", "44,150"],
    ["Electrical, Electronic, and Electromechanical Assemblers, Except Coil Winders, Tapers, and Finishers", "35,880", "27,100", "41,930"],
    ["Miscellaneous Religious Workers", "35,860", "22,620", "40,680"],
    ["Religious Workers, All Other", "35,860", "22,620", "40,680"],
    ["Adhesive Bonding Machine Operators and Tenders", "35,850", "27,260", "43,300"],
    ["Social and Human Service Assistants", "35,830", "26,960", "42,050"],
    ["Miscellaneous Healthcare Support Occupations", "35,830", "28,440", "41,390"],
    ["Order Clerks", "35,790", "26,520", "42,760"],
    ["Rock Splitters, Quarry", "35,760", "29,040", "40,830"],
    ["Information and Record Clerks", "35,750", "26,100", "42,380"],
    ["Miscellaneous Textile, Apparel, and Furnishings Workers", "35,740", "26,280", "41,720"],
    ["Grounds Maintenance Workers, All Other", "35,710", "25,660", "43,240"],
    ["Cutting and Slicing Machine Setters, Operators, and Tenders", "35,700", "27,990", "42,450"],
    ["Assemblers and Fabricators", "35,650", "26,130", "42,030"],
    ["Miscellaneous Motor Vehicle Operators", "35,640", "22,910", "46,650"],
    ["Motor Vehicle Operators, All Other", "35,640", "22,910", "46,650"],
    ["Veterinary Technologists and Technicians", "35,560", "28,210", "40,710"],
    ["Phlebotomists", "35,560", "28,830", "40,500"],
    ["Interviewers, Except Eligibility and Loan", "35,520", "27,510", "41,690"],
    ["Extruding and Forming Machine Setters, Operators, and Tenders, Synthetic and Glass Fibers", "35,500", "28,010", "41,730"],
    ["Data Entry and Information Processing Workers", "35,470", "27,730", "41,170"],
    ["Animal Trainers", "35,260", "23,310", "40,710"],
    ["Office Clerks, General", "35,200", "25,090", "42,050"],
    ["Molders, Shapers, and Casters, Except Metal and Plastic", "35,190", "27,000", "41,090"],
    ["Conveyor Operators and Tenders", "35,110", "27,780", "40,230"],
    ["Cutting Workers", "35,090", "27,300", "41,550"],
    ["Miscellaneous Material Moving Workers", "35,000", "24,450", "40,960"],
    ["Material Moving Workers, All Other", "35,000", "24,450", "40,960"],
    ["Shipping, Receiving, and Traffic Clerks", "34,980", "27,040", "40,580"],
    ["Photographic Process Workers and Processing Machine Operators", "34,910", "23,900", "39,580"],
    ["Print Binding and Finishing Workers", "34,850", "25,910", "41,990"],
    ["Plating and Coating Machine Setters, Operators, and Tenders, Metal and Plastic", "34,830", "26,710", "40,330"],
    ["Protective Service Workers, All Other", "34,650", "23,730", "40,460"],
    ["Etchers and Engravers", "34,550", "24,620", "39,790"],
    ["Medical Assistants", "34,540", "28,400", "39,190"],
    ["Miscellaneous Woodworkers", "34,530", "23,930", "44,110"],
    ["Woodworkers, All Other", "34,530", "23,930", "44,110"],
    ["Office Machine Operators, Except Computer", "34,530", "26,520", "40,170"],
    ["Cooling and Freezing Equipment Operators and Tenders", "34,520", "26,390", "41,700"],
    ["Production Workers, All Other", "34,490", "23,860", "40,840"],
    ["Ophthalmic Laboratory Technicians", "34,490", "26,120", "39,660"],
    ["Preschool Teachers, Except Special Education", "34,410", "24,040", "39,060"],
    ["Agricultural Workers, All Other", "34,340", "23,500", "40,980"],
    ["Tailors, Dressmakers, and Custom Sewers", "34,330", "24,390", "41,600"],
    ["Miscellaneous Assemblers and Fabricators", "34,300", "25,370", "40,080"],
    ["Assemblers and Fabricators, All Other, Including Team Assemblers", "34,270", "25,320", "40,060"],
    ["Molders and Molding Machine Setters, Operators, and Tenders, Metal and Plastic", "34,200", "26,000", "40,150"],
    ["Door-to-Door Sales Workers, News and Street Vendors, and Related Workers", "34,120", "22,370", "39,520"],
    ["Other Education, Training, and Library Occupations", "34,110", "22,700", "38,680"],
    ["Weighers, Measurers, Checkers, and Samplers, Recordkeeping", "34,100", "25,490", "40,360"],
    ["Parts Salespersons", "34,080", "23,840", "40,430"],
    ["Pharmacy Technicians", "34,020", "26,750", "39,290"],
    ["Painting, Coating, and Decorating Workers", "33,960", "25,180", "39,870"],
    ["Molding, Coremaking, and Casting Machine Setters, Operators, and Tenders, Metal and Plastic", "33,950", "25,760", "39,810"],
    ["Furniture Finishers", "33,850", "26,700", "38,930"],
    ["File Clerks", "33,810", "25,420", "39,670"],
    ["Data Entry Keyers", "33,740", "26,830", "38,910"],
    ["Woodworkers", "33,680", "25,690", "39,430"],
    ["Concierges", "33,550", "25,170", "39,250"],
    ["Fabric Menders, Except Garment", "33,550", "25,820", "37,860"],
    ["Bus Drivers, School or Special Client", "33,390", "24,670", "40,540"],
    ["Tailors, Dressmakers, and Sewers", "33,350", "24,210", "39,280"],
    ["Miscellaneous Production Workers", "33,320", "24,190", "39,350"],
    ["Demonstrators and Product Promoters", "33,260", "23,610", "39,720"],
    ["Barbers", "33,220", "22,370", "40,350"],
    ["Butchers and Meat Cutters", "33,210", "24,670", "40,080"],
    ["Models, Demonstrators, and Product Promoters", "33,200", "23,470", "39,600"],
    ["Cleaning, Washing, and Metal Pickling Equipment Operators and Tenders", "33,090", "25,250", "38,300"],
    ["Helpers--Electricians", "32,960", "26,120", "38,200"],
    ["Manufactured Building and Mobile Home Installers", "32,910", "26,010", "38,490"],
    ["Helpers, Construction Trades", "32,900", "26,060", "37,930"],
    ["Packaging and Filling Machine Operators and Tenders", "32,740", "24,570", "38,730"],
    ["Food and Tobacco Roasting, Baking, and Drying Machine Operators and Tenders", "32,730", "24,120", "38,970"],
    ["Building Cleaning Workers, All Other", "32,710", "23,840", "38,070"],
    ["Agricultural Equipment Operators", "32,620", "25,510", "38,100"],
    ["Occupational Therapy Aides", "32,580", "21,590", "37,290"],
    ["Helpers--Pipelayers, Plumbers, Pipefitters, and Steamfitters", "32,570", "26,390", "37,620"],
    ["Other Transportation Workers", "32,460", "21,840", "34,170"],
    ["Other Protective Service Workers", "32,430", "23,120", "37,920"],
    ["Healthcare Support Occupations", "32,380", "24,470", "37,520"],
    ["Helpers, Construction Trades, All Other", "32,370", "25,120", "37,550"],
    ["Substitute Teachers", "32,360", "22,510", "37,340"],
    ["Counter and Rental Clerks and Parts Salespersons", "32,280", "22,860", "38,080"],
    ["Communications Equipment Operators", "32,140", "24,700", "37,290"],
    ["Food Batchmakers", "32,090", "24,180", "38,610"],
    ["Security Guards and Gaming Surveillance Officers", "32,090", "23,420", "37,110"],
    ["Security Guards", "32,050", "23,400", "37,060"],
    ["Mail Clerks and Mail Machine Operators, Except Postal Service", "32,040", "24,550", "38,120"],
    ["Crossing Guards", "31,970", "23,330", "37,260"],
    ["Grinding and Polishing Workers, Hand", "31,900", "24,450", "37,170"],
    ["Helpers--Carpenters", "31,850", "25,180", "37,160"],
    ["Merchandise Displayers and Window Trimmers", "31,850", "23,500", "36,520"],
    ["Helpers--Roofers", "31,740", "25,830", "36,550"],
    ["Helpers--Painters, Paperhangers, Plasterers, and Stucco Masons", "31,720", "25,010", "37,160"],
    ["Machine Feeders and Offbearers", "31,710", "24,400", "37,670"],
    ["Food Cooking Machine Operators and Tenders", "31,690", "24,730", "37,310"],
    ["Cutters and Trimmers, Hand", "31,600", "24,140", "36,860"],
    ["Grounds Maintenance Workers", "31,600", "24,250", "36,710"],
    ["Models", "31,570", "21,230", "36,390"],
    ["Material Moving Workers", "31,560", "23,490", "36,890"],
    ["Helpers--Installation, Maintenance, and Repair Workers", "31,390", "23,780", "37,030"],
    ["Miscellaneous Food Processing Workers", "31,340", "23,890", "37,280"],
    ["Miscellaneous Protective Service Workers", "31,330", "21,820", "38,160"],
    ["Forest and Conservation Workers", "31,320", "22,170", "37,200"],
    ["Switchboard Operators, Including Answering Service", "31,290", "24,450", "36,260"],
    ["Gaming Service Workers, All Other", "31,260", "22,360", "36,200"],
    ["Sawing Machine Setters, Operators, and Tenders, Wood", "31,200", "24,440", "36,790"],
    ["Counter and Rental Clerks", "31,200", "22,430", "36,520"],
    ["Woodworking Machine Setters, Operators, and Tenders", "31,150", "24,490", "36,740"],
    ["Woodworking Machine Setters, Operators, and Tenders, Except Sawing", "31,110", "24,520", "36,710"],
    ["Psychiatric Aides", "31,090", "23,470", "37,230"],
    ["Textile, Apparel, and Furnishings Workers, All Other", "31,070", "22,930", "35,930"],
    ["Landscaping and Groundskeeping Workers", "30,940", "24,020", "35,910"],
    ["Laborers and Freight, Stock, and Material Movers, Hand", "30,890", "23,520", "35,960"],
    ["Miscellaneous Vehicle and Mobile Equipment Mechanics, Installers, and Repairers", "30,780", "23,810", "35,810"],
    ["Transportation Attendants, Except Flight Attendants", "30,640", "22,310", "36,880"],
    ["Couriers and Messengers", "30,620", "23,700", "35,780"],
    ["Textile Knitting and Weaving Machine Setters, Operators, and Tenders", "30,470", "24,170", "35,810"],
    ["Cooks, All Other", "30,360", "23,500", "35,470"],
    ["Receptionists and Information Clerks", "30,350", "24,120", "35,660"],
    ["Barbers, Hairdressers, Hairstylists and Cosmetologists", "30,340", "20,210", "35,260"],
    ["Bicycle Repairers", "30,290", "24,140", "35,320"],
    ["Hairdressers, Hairstylists, and Cosmetologists", "30,190", "20,130", "34,910"],
    ["Tellers", "30,140", "25,540", "34,360"],
    ["Farming, Fishing, and Forestry Occupations", "30,140", "22,980", "33,460"],
    ["Dietetic Technicians", "30,130", "22,450", "35,110"],
    ["Shoe Machine Operators and Tenders", "30,110", "22,740", "36,240"],
    ["Food Processing Workers", "30,090", "23,480", "35,220"],
    ["Baggage Porters, Bellhops, and Concierges", "30,070", "22,320", "34,900"],
    ["Personal Appearance Workers", "30,040", "21,000", "33,790"],
    ["Building and Grounds Cleaning and Maintenance Occupations", "30,020", "22,280", "34,970"],
    ["Residential Advisors", "29,970", "22,800", "34,930"],
    ["Textile Bleaching and Dyeing Machine Operators and Tenders", "29,930", "24,140", "34,960"],
    ["Butchers and Other Meat, Poultry, and Fish Processing Workers", "29,910", "23,730", "34,810"],
    ["Shoe and Leather Workers", "29,900", "22,760", "35,670"],
    ["Textile Machine Setters, Operators, and Tenders", "29,880", "24,530", "34,360"],
    ["Orderlies", "29,840", "23,570", "34,610"],
    ["Shoe and Leather Workers and Repairers", "29,800", "22,770", "35,380"],
    ["Laborers and Material Movers, Hand", "29,790", "22,870", "34,380"],
    ["Entertainment Attendants and Related Workers, All Other", "29,690", "21,220", "33,370"],
    ["Textile Winding, Twisting, and Drawing Out Machine Setters, Operators, and Tenders", "29,660", "25,530", "32,900"],
    ["Tour and Travel Guides", "29,630", "22,090", "34,570"],
    ["Driver/Sales Workers", "29,610", "19,540", "36,770"],
    ["Nursing Assistants", "29,580", "24,340", "33,580"],
    ["Tire Repairers and Changers", "29,530", "23,330", "34,020"],
    ["Sewers, Hand", "29,510", "23,540", "33,590"],
    ["Textile Cutting Machine Setters, Operators, and Tenders", "29,440", "23,200", "34,770"],
    ["Helpers--Production Workers", "29,380", "23,000", "34,540"],
    ["Miscellaneous Personal Appearance Workers", "29,380", "21,890", "31,120"],
    ["Pharmacy Aides", "29,190", "22,510", "33,280"],
    ["Ambulance Drivers and Attendants, Except Emergency Medical Technicians", "29,010", "21,870", "32,000"],
    ["Gaming Cage Workers", "28,980", "23,380", "33,500"],
    ["Library Assistants, Clerical", "28,960", "21,670", "34,500"],
    ["Janitors and Cleaners, Except Maids and Housekeeping Cleaners", "28,950", "21,990", "33,490"],
    ["Floral Designers", "28,900", "22,560", "33,760"],
    ["Farmworkers, Farm, Ranch, and Aquacultural Animals", "28,840", "21,610", "33,760"],
    ["Teacher Assistants", "28,750", "21,940", "34,190"],
    ["Veterinary Assistants and Laboratory Animal Caretakers", "28,690", "23,060", "32,730"],
    ["Bakers", "28,660", "22,610", "32,690"],
    ["Telemarketers", "28,550", "21,440", "31,900"],
    ["Stock Clerks and Order Fillers", "28,520", "22,220", "32,490"],
    ["Physical Therapist Aides", "28,500", "22,690", "31,570"],
    ["Funeral Attendants", "28,480", "21,650", "32,740"],
    ["Slaughterers and Meat Packers", "28,450", "23,900", "32,220"],
    ["Taxi Drivers and Chauffeurs", "28,450", "22,150", "32,180"],
    ["Recreation Workers", "28,310", "21,060", "32,510"],
    ["Retail Salespersons", "28,310", "20,910", "30,350"],
    ["Cooks, Institution and Cafeteria", "28,290", "22,000", "32,860"],
    ["Miscellaneous Personal Care and Service Workers", "28,270", "22,120", "32,760"],
    ["Personal Care and Service Workers, All Other", "28,270", "22,120", "32,760"],
    ["Building Cleaning and Pest Control Workers", "28,200", "21,620", "32,210"],
    ["Nursing, Psychiatric, and Home Health Aides", "28,180", "23,000", "31,700"],
    ["Personal Care and Service Occupations", "28,090", "21,240", "30,450"],
    ["Building Cleaning Workers", "27,960", "21,520", "31,850"],
    ["Agricultural Workers", "27,910", "22,760", "30,180"],
    ["Meat, Poultry, and Fish Cutters and Trimmers", "27,790", "23,110", "31,610"],
    ["Textile, Apparel, and Furnishings Workers", "27,690", "21,650", "31,400"],
    ["Gaming and Sports Book Writers and Runners", "27,640", "21,610", "31,100"],
    ["Food Processing Workers, All Other", "27,590", "22,760", "30,840"],
    ["Cooks, Restaurant", "27,580", "22,460", "31,240"],
    ["Miscellaneous Agricultural Workers", "27,290", "22,730", "29,730"],
    ["Gaming Change Persons and Booth Cashiers", "27,220", "21,840", "30,640"],
    ["Other Personal Care and Service Workers", "27,190", "21,570", "29,430"],
    ["Sewing Machine Operators", "26,990", "21,970", "30,590"],
    ["Baggage Porters and Bellhops", "26,990", "20,600", "30,240"],
    ["Cleaners of Vehicles and Equipment", "26,900", "21,230", "30,040"],
    ["Bartenders", "26,780", "19,180", "29,980"],
    ["Locker Room, Coatroom, and Dressing Room Attendants", "26,720", "20,340", "31,030"],
    ["Cooks", "26,560", "21,460", "30,370"],
    ["Animal Care and Service Workers", "26,540", "20,560", "29,400"],
    ["Retail Sales Workers", "26,520", "20,160", "28,540"],
    ["Graders and Sorters, Agricultural Products", "26,510", "22,140", "28,130"],
    ["Packers and Packagers, Hand", "26,490", "21,380", "29,760"],
    ["Farmworkers and Laborers, Crop, Nursery, and Greenhouse", "26,450", "22,720", "28,210"],
    ["Cooks and Food Preparation Workers", "26,120", "21,070", "29,930"],
    ["Automotive and Watercraft Service Attendants", "25,940", "21,260", "29,010"],
    ["Nonfarm Animal Caretakers", "25,890", "20,400", "28,780"],
    ["Manicurists and Pedicurists", "25,860", "21,940", "28,260"],
    ["Waiters and Waitresses", "25,830", "18,820", "28,280"],
    ["Motion Picture Projectionists", "25,820", "19,530", "28,170"],
    ["Food Preparation and Serving Related Occupations", "25,580", "19,410", "28,270"],
    ["Maids and Housekeeping Cleaners", "25,570", "20,340", "28,630"],
    ["Miscellaneous Food Preparation and Serving Related Workers", "25,430", "21,220", "28,060"],
    ["Food Preparation and Serving Related Workers, All Other", "25,430", "21,220", "28,060"],
    ["Home Health Aides", "25,330", "21,750", "28,030"],
    ["Cooks, Short Order", "25,140", "20,190", "28,500"],
    ["Hotel, Motel, and Resort Desk Clerks", "25,130", "20,430", "28,090"],
    ["Parking Lot Attendants", "25,130", "20,690", "27,970"],
    ["Personal Care Aides", "25,090", "21,810", "27,490"],
    ["Food Servers, Nonrestaurant", "24,980", "19,930", "27,630"],
    ["Food Preparation Workers", "24,830", "20,160", "28,270"],
    ["Childcare Workers", "24,610", "19,640", "27,900"],
    ["Laundry and Dry-Cleaning Workers", "24,480", "20,130", "27,010"],
    ["Lifeguards, Ski Patrol, and Other Recreational Protective Service Workers", "24,420", "19,320", "25,990"],
    ["Gaming Services Workers", "24,240", "18,610", "24,810"],
    ["Miscellaneous Entertainment Attendants and Related Workers", "24,140", "19,310", "25,820"],
    ["Pressers, Textile, Garment, and Related Materials", "24,060", "20,260", "26,940"],
    ["Entertainment Attendants and Related Workers", "24,050", "19,130", "25,600"],
    ["Dining Room and Cafeteria Attendants and Bartender Helpers", "23,950", "19,290", "25,370"],
    ["Food and Beverage Serving Workers", "23,940", "18,840", "25,420"],
    ["Ushers, Lobby Attendants, and Ticket Takers", "23,610", "19,120", "25,510"],
    ["Other Food Preparation and Serving Related Workers", "23,540", "19,410", "25,320"],
    ["Amusement and Recreation Attendants", "23,460", "19,230", "25,350"],
    ["Cashiers", "23,270", "19,440", "25,000"],
    ["Hosts and Hostesses, Restaurant, Lounge, and Coffee Shop", "23,260", "19,090", "25,280"],
    ["Counter Attendants, Cafeteria, Food Concession, and Coffee Shop", "23,240", "19,320", "25,280"],
    ["Dishwashers", "23,190", "19,750", "25,160"],
    ["Gaming Dealers", "23,070", "18,310", "23,800"],
    ["Cooks, Fast Food", "22,650", "19,180", "25,110"],
    ["Fast Food and Counter Workers", "22,260", "18,770", "24,560"],
    ["Shampooers", "22,160", "19,230", "24,380"],
    ["Combined Food Preparation and Serving Workers, Including Fast Food", "22,140", "18,700", "24,460"]
];

export default salaries;