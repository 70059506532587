import React, { useEffect, useMemo, useState } from "react";
import {AsideMenuList} from "./AsideMenuList";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import { DropdownMenu2 } from "../../../../_partials/dropdowns";
import {Dropdown} from "react-bootstrap";
import { useSelector } from "react-redux";
import { usePrevious } from "../../../../_helpers/Custom";
import { AlertPopup } from "../../../../_helpers/Alerts";
import _ from "lodash";
import { toAbsoluteUrl } from "../../../../_helpers";
import './asidemenu.css'
import { useRef } from "react";

export function AsideMenu({ disableScroll }) {
  const localUserData = useSelector((state) => state.profile.getLocalStorageUserData);
  const prevLocalUserData = usePrevious({ localUserData });
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);
  const [alert, setAlert] = useState({ show: false, title: null, message: null, errType: null });
  const [state, setState] = useState({ user_name: "", email: "", user_photourl: "" })
  const [toggle_arrow_custom, setToggle_arrow_custom] = useState(true)
  const [imageErrCount, setImageErrCount] = useState(0)

  const dropdownRef = useRef()
  const dropdownTRef = useRef()
  const dropdownMenuRef = useRef()
  // const [showMenu,setShowMenu] = useState(false);
  // const menuClass = showMenu ? "dropdown-menu dropdown-menu-right dropdown-action-sidebar show " : "dropdown-menu dropdown-menu-right dropdown-action-sidebar";
  // const handleMenu =  () => setShowMenu(!showMenu);
  // const dropdownStyles = {
  //   position:'absolute',
  //   top:'-47px'
  // }

  // const handleLogout = () => {
  //   removeStorage('ACCESS_TOKEN');
  //   window.location.href = '/';
  // }

  useEffect(() => {
    if(imageErrCount > 1000){
      window.location.reload()
    }
  }, [imageErrCount])

  useEffect(() => {
    if (prevLocalUserData && prevLocalUserData.localUserData !== localUserData) {
      if (localUserData && _.has(localUserData, "user_data") && localUserData.status === "success") {
        const userData = localUserData.user_data;
        setState({
          ...state,
          user_name: userData.user_name,
          user_firstname: userData.email,
          user_photourl: userData.user_photourl,
        });
      }
      if (localUserData && _.has(localUserData, "message") && localUserData.status === "error") {
        if (localUserData.open_popup === false) {
          setAlert({
            show: true,
            message: "Something went wrong",
            title: "Error",
            errType: "failure",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevLocalUserData, localUserData]);

  // handle close button of alert
  const handleClose = () => {
    setAlert({
      show: false,
    });
  };

  const handelUpdatedDropdownMenu =(val)=>{
    let updatedDropdownMenu = dropdownMenuRef.current
    if (val) {
      try {
        updatedDropdownMenu.className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu show dropdown-menu-right"
        updatedDropdownMenu.style="position: absolute; inset: auto 0px 0px auto; margin: 0px; transform: translate3d(0px, -41.6px, 0px);"
      } catch (error) {
        console.log(error);
      }
    }
    if(!val){
      try {
        updatedDropdownMenu.className="dropdown-menu dropdown-menu-sm dropdown-menu-right dropdown-menu dropdown-menu-right"
        updatedDropdownMenu.style.position='absolute'
      } catch (error) {
        console.log(error);
      }
      
    }
  }

  const toggelDropdown = ()=>{
    let updatedDropdownClass = dropdownRef.current
    let updatedDropdownAriaExpanded = dropdownTRef.current
    // let updatedDropdownMenu = dropdownMenuRef.current
    
    if (toggle_arrow_custom === true) {
      setTimeout(() => {
        setToggle_arrow_custom(false)
          updatedDropdownClass.className = 'dropdown user_profile_link show dropdown'
          updatedDropdownAriaExpanded.ariaExpanded = true
          handelUpdatedDropdownMenu(true)
        }, 2);
        
        
      }else if (toggle_arrow_custom === false) {
        setTimeout(() => {
          setToggle_arrow_custom(true)
          updatedDropdownClass.className = 'dropdown user_profile_link dropdown'
          updatedDropdownAriaExpanded.ariaExpanded = false
          handelUpdatedDropdownMenu(false)
        }, 2);
    }
  }

  return (
    <>
      {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />}
        {/* begin:: Meeting Science Aside Menu */}
        <div className="ms_aside-menu aside-menu">
        
          <AsideMenuList layoutProps={layoutProps} />

        </div>


      {/* begin::Menu Container */}
        <div id="kt_aside_menu" data-menu-vertical="1" className={`aside-menu d-none my-4 ${layoutProps.asideClassesFromConfig}`}
          {...layoutProps.asideMenuAttr}
        >
          <AsideMenuList layoutProps={layoutProps} />
        </div>
      {/* end::Menu Container */}

      {/* begin::Menu Container */}

        <div className="nav_footer d-hidden-991">

        {/* temporary comminted
          <div className="faq-link">
            <a href="https://meetingscience.io/betafaq/" rel="noopener noreferrer" onClick={() => logActivity('page_visit','https://meetingscience.io/betafaq/')} title='FAQ' target='_blank'><img  src="/media/ms-media/faqs-ico.svg" alt="FAQ"/> <span>FAQ</span></a>
          </div> */}


          {/* <div className="user_profile_link">
          <div className="btn-group d-block">
            <button type="button" className="btn btn-profile-nav w-100 dropdown-toggle" id='dropdown-profile' data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img title={getUser()} alt='contains svg icon' src="/media/ms-media/user-profile-pic.png"/> <span> Welcome { userData() && userData().user_firstname} </span>
            </button> */}
            <Dropdown ref={dropdownRef} onToggle={()=>{toggelDropdown()}} className="dropdown user_profile_link" drop="down" alignRight>
              <Dropdown.Toggle
                ref={dropdownTRef}
                className="btn-profile-nav btn btn-sm font-weight-bolder dropdown-toggle"
                variant="transparent"
                id="dropdown-toggle-top">
                  {
                    toggle_arrow_custom && <span className="toggle_arrow_custom toggle_up_custom"></span>
                  }
                  {
                    // eslint-disable-next-line
                    toggle_arrow_custom == false && <span className="toggle_arrow_custom1 toggle_down_custom"></span>
                  }
            <img className="profile-user-img rounded-circle" src={localUserData && localUserData.user_data && localUserData.user_data.user_photourl !== 'https://client.meetingscience.io/storage/null' ? localUserData.user_data.user_photourl : toAbsoluteUrl("/media/ms-media/user-avatar.jpg")} onError={({ currentTarget }) => {currentTarget.src="https://client.meetingscience.io/images/profile_images/ph_profile.png";setImageErrCount(imageErrCount + 1); currentTarget.onerror = null}} alt="" />
            <span>{localUserData && localUserData.user_data ? (localUserData.user_data.user_name !== "" ? localUserData.user_data.user_name : localUserData.user_data.email) : '-'}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu ref={dropdownMenuRef} className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <DropdownMenu2 />
              </Dropdown.Menu>
            </Dropdown>

          {/* </div>
          </div> */}
        </div>

      {/* end::Menu Container */}

    </>
  );
}
