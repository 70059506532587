/* eslint-disable */ 
import React from "react";
import { AttendedDashboard } from "../../_metronic/_partials/dashboards/Attended";
import { CoachingDashboard } from "../../_metronic/_partials/dashboards/Coaching";
import { CompanyDashboard } from "../../_metronic/_partials/dashboards/Company";
import { MeetingMastery } from "../../_metronic/_partials/dashboards/Meeting-Mastery";
import { OrganizedDashboard } from "../../_metronic/_partials/dashboards/Organized";
import { TimeMachine } from "../../_metronic/_partials/dashboards/Time-Machine";
import { UnratedDashboard } from "../../_metronic/_partials/dashboards/Unrated";
import { EditPersonalInfo } from "../../_metronic/_partials/dashboards/UserProfile/EditPersonalInfo";
import { FeedbackSettings } from "../../_metronic/_partials/dashboards/UserProfile/FeedbackSetting";
import { Notifications } from "../../_metronic/_partials/dashboards/UserProfile/Notifications";
import { PersonalInfo } from "../../_metronic/_partials/dashboards/UserProfile/PersonalInfo";
import { Privacy } from "../../_metronic/_partials/dashboards/UserProfile/Privacy";
import { Relationships } from "../../_metronic/_partials/dashboards/UserProfile/Relationships";
// import { ErrorPage1 } from "../modules/ErrorsExamples/ErrorPage1";
import LoginPage from "../pages/LoginPage";
import Logout from "../pages/Logout";
import Step1 from "../pages/signup/step1";
import Step2 from "../pages/signup/step2";
import Step3 from "../pages/signup/step3";
import Step4 from "../pages/signup/step4";
import Step5 from "../pages/signup/step5";
import Step6 from "../pages/signup/step6";
import UnsubscribePage from "../pages/unsubscribe";

export const routeDef = [{
    
    public:[
        {name:"/",component:LoginPage},
        {name:"/unsubscribe/:id",component:UnsubscribePage},
        {name:"/media",component:LoginPage},
        {name:"/css",component:LoginPage}
    ],
    private:[
        {
            registration:[
                {name:"/signup-page1",component:Step1, default:LoginPage },
                // {name:"/signup-page2",component:Step2, default:LoginPage },
                // {name:"/signup-page3",component:Step3, default:LoginPage },
                // {name:"/signup-page4",component:Step4, default:LoginPage },
                // {name:"/signup-page5",component:Step5, default:LoginPage },
                {name:"/signup-page2",component:Step6, default:LoginPage},
                {name:"/media",component:LoginPage, default:LoginPage},
                {name:"/css",component:LoginPage, default:LoginPage}
            ],
            pages:[
                {name:"/unrated",component:UnratedDashboard},
                {name:"/attended",component:AttendedDashboard },
                {name:"/organized",component:OrganizedDashboard},
                {name:"/company",component:CompanyDashboard},
                {name:"/score",component:AttendedDashboard},
                {name:"/coaching",component:CoachingDashboard},
                {name:"/meeting-mastery",component:MeetingMastery},
                {name:"/time-machine",component:TimeMachine},
                {name:"/leaderboard",component:AttendedDashboard},
                {name:"/user-profile/personal-info",component:PersonalInfo},
                {name:"/user-profile/edit-personal-info",component:EditPersonalInfo},
                {name:"/user-profile/relationship",component:Relationships},
                {name:"/user-profile/notification",component:Notifications},
                {name:"/user-profile/privacy",component:Privacy},
                {name:"/user-profile/feedback-settings",component:FeedbackSettings},       
                {name:"/logout",component:Logout},
                {name:"/media",component:MeetingMastery},
                {name:"/css",component:MeetingMastery},
            ]
        }
    ]
   
}]