/* eslint-disable */ 

import React, { useMemo, useState, useEffect } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { AnimateLoading } from "../../../_partials/controls";
import { DateRangePickerField } from "../../../_partials/controls/forms/DateRangePickerField/DateRangePickerField";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setFilterData} from "../../../../redux/duck/datepicker/picker.action";
import FilterLabel from "./FilterLabel";
import moment from "moment";
import './header.css'

function Header() {
  const history = useHistory();
  const pathName = history.location.pathname;
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      )
    };
  }, [uiService]);

  const dispatch = useDispatch();
  const { timePeriod } = useSelector(state => state.picker);
  const { getLocalStorageUserData } = useSelector(state => state.profile); 
  const [orderState, setOrderState] = useState(false);
  const [data, setData] = useState({
    filter: timePeriod.filter,
    order: timePeriod.order,
    sdate: timePeriod.sdate,
    edate: timePeriod.edate,
    custom: timePeriod.custom
  })
  const orderByHandler = (field) => {
    let order = field === 'DESC' ? 'ASC' : (field === 'ASC' ? "DESC" : 'DESC');
    setData({ ...data, sdate: timePeriod.sdate, edate: timePeriod.edate, order: order });
    setOrderState(false);
    if (data.filter === 'custom') {
      dispatch(setFilterData({ sdate: timePeriod.sdate, edate: timePeriod.edate, order: order, custom: true }));
      setOrderState(true);
    }
  }

 //--------------------------------new code for calander start ------------------
 let biRef = {
               // someParentFunction: someParentFunction
             }
 useEffect(()=>{
   if (data.custom === true) {
     biRef.onClicke()      
    }
  },[data])
 //--------------------------------new code for calander end ------------------

  const inputHandler = (e) => {
    const { value } = e.target;

    if (value === 'last_month') {

      const startOfMonth = moment().subtract(1,'month').startOf('month').format('YYYY-MM-DD');
      const endOfMonth   = moment().subtract(1,'month').endOf('month').format('YYYY-MM-DD');
      
      setData({
        ...data,
        sdate:startOfMonth,
        edate:endOfMonth,
        filter: value,
        custom: false
      })
      setOrderState(false);
    } else if (value === 'last_7_days') {
      setData({
        ...data,
        sdate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
        edate: moment().subtract(1,'d').format('YYYY-MM-DD'),
        filter: value,
        custom: false

      })
      setOrderState(false);
    } else if (value === 'last_30_days') {
      setData({
        ...data,
        sdate: moment().subtract(30, 'd').format('YYYY-MM-DD'),
        edate: moment().subtract(1,'d').format('YYYY-MM-DD'),
        filter: value,
        custom: false

      })
      setOrderState(false);
    }else if (value === 'last_week') {
      setData({
        ...data,
        sdate: moment().subtract(1,'week').startOf('week').format('YYYY-MM-DD'),
        edate: moment().subtract(1,'week').endOf('week').format('YYYY-MM-DD'),
        filter: value,
        custom: false

      })
      setOrderState(false);
    }else if (value === 'custom') {
          setData({
            ...data,
            sdate: '',
            edate: '',
            filter:value,
            custom:true
          })
       
      // setData({
      //   ...data,
      //   filter: value,
      //   custom: true
      // })
    }else if (value === 'yesterday') {
      setData({
        ...data,
        sdate: moment().subtract(1,'d').format('YYYY-MM-DD'),
        edate: moment().subtract(1,'d').format('YYYY-MM-DD'),
        filter: value,
        custom: false

      })
      setOrderState(false);
    }else if (value === 'today') {
      setData({
        ...data,
        sdate: moment().format('YYYY-MM-DD'),
        edate: moment().format('YYYY-MM-DD'),
        filter: value,
        custom: false

      })
      setOrderState(false);
    }else if (value === 'next_7_days') {
      setData({
        ...data,
        sdate: moment().add(1,'d').format('YYYY-MM-DD'),
        edate: moment().add(7,'d').format('YYYY-MM-DD'),
        filter: value,
        custom: false

      })
      setOrderState(false);
    }

  }

useEffect(() => {
  if (timePeriod.filter === 'custom') {
    setData({
      ...data,
      filter: 'custom',
      custom: true
    })
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[timePeriod.filter])

  useEffect(() => {  
    if (data.filter !== 'custom') {
       dispatch(setFilterData(data));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])


useEffect(() => {
  if(pathName == '/unrated' || pathName == '/meeting-mastery'){
    dispatch(setFilterData({
        sdate: moment().subtract(1,'week').startOf('week').format('YYYY-MM-DD'),
        edate: moment().subtract(1,'week').endOf('week').format('YYYY-MM-DD'),
        filter: 'last_week',  
        custom: false
    }))

    setData({
      ...data,
      sdate: moment().subtract(1,'week').startOf('week').format('YYYY-MM-DD'),
      edate: moment().subtract(1,'week').endOf('week').format('YYYY-MM-DD'),
      filter: 'last_week',  
      custom: false
    })
  
  }

 
},[pathName])


// useEffect(() => {
//   if(location.pathname !== '/unrated' && location.pathname !=='/meeting-mastery'){

//           let sdate = moment(timePeriod.sdate);
//           let edate = moment(timePeriod.edate);
//           let daysInPrevMonth = moment().subtract(1,'month').daysInMonth();
//           let lastDateOfPrevMonth = moment().subtract(1,'month').endOf('month').format('YYYY-MM-DD');
//           const differenceInDays = edate.diff(sdate,"days");
//           // console.log('difference in days is ' + differenceInDays);
//           let netDifference = differenceInDays + 1;
            
//           // console.log(moment(sdate).isSame(moment().add(1,'day').format('YYYY-MM-DD'),'year'));
//           // console.log(netDifference);  
          
//         if(netDifference == daysInPrevMonth && moment(edate).isSame(lastDateOfPrevMonth,'year')){
//           setData({
//             ...data,
//             filter:0,
//             custom:false
//           })
          
//         }else if(netDifference == 14 ){
//           setData({
//             ...data,
//             filter:1,
//             custom:false
//           })
//         }else if(netDifference == 7 &&  moment(sdate).isBefore(moment())){
//           console.log('in')
//           setData({
//             ...data,
//             filter:2,
//             custom:false
//           })
//         }else if(netDifference == 7 &&  moment(sdate).isSame(moment().add(1,'day').format('YYYY-MM-DD'),'year') ){
//           setData({
//             ...data,
//             filter:3,
//             custom:false
//           })
//         }
// }
// }, [location.pathname])


  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div className={`container d-flex align-items-stretch justify-content-between`}>
        {/* <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}> */}
          <AnimateLoading />
          {pathName.match(/user-profile/g) === null ?
            < div className="ms-header w-100 d-flex align-items-center justify-content-between">
              <div className="ms-header--title">
                <h1 className="header--title" style={{paddingLeft:'5px'}}> {pathName === "/time-machine" ? "Meeting Shortener" : (pathName === "/coaching" ? "Meeting Coaching Report" : < FilterLabel />)}</h1>
              </div>
              {pathName === "/time-machine" || pathName === "/coaching" || pathName === "/unrated" ? "" :
              <div className="ms-header--timeperiod d-flex align-items-center">
                {pathName === "/unrated"  ?
                  <span className="header-filter-icn custm_popover" onClick={() => orderByHandler(data.order)}>
                    {data.order === "DESC" ? <><i className="fas fa-arrow-up active"></i><i className="fas fa-arrow-down"></i></> :
                      <><i className="fas fa-arrow-up"></i><i className="fas fa-arrow-down active"></i></>}
                  </span>
                    : ""}
                <p className="m-0">Time Period</p>
                  {(pathName == "/meeting-mastery")  && getLocalStorageUserData && getLocalStorageUserData.user_data && getLocalStorageUserData.user_data.company_domain !== 'zoom.us' && getLocalStorageUserData.user_data.company_domain !== 'zoomappsec.us' && getLocalStorageUserData.user_data.email !== 'development@meetingscience.io' ?
                    <div className="ms-month">
                      <select className="form-control form-control-md form-control-solid" onChange={inputHandler} value={data.filter}>
                      <option value='custom'>Custom</option>
                        <option value='today'>Today</option>
                        <option value='yesterday'>Yesterday</option>                    
                        <option value='last_week'>Last week</option>
                        <option value='last_month'>Last month</option>
                        <option value='last_7_days'>Last 7 days</option>
                        <option value='last_30_days'>Last 30 days</option>
                      </select>
                    </div>
                    :
                    <div className="ms-month">
                      <select className="form-control form-control-md form-control-solid" onChange={inputHandler} value={data.filter}>
                        <option value='custom'>Custom</option>
                        <option value='today'>Today</option>
                        <option value='yesterday'>Yesterday</option>                    
                        <option value='last_week'>Last week</option>
                        <option value='last_month'>Last month</option>
                        <option value='last_7_days'>Last 7 days</option>
                        <option value='last_30_days'>Last 30 days</option>
                        <option value='next_7_days'>Next 7 days</option>
                      </select>
                    </div>
                  }
                <div className="ms-date">
                    <DateRangePickerField biRef={biRef} value={data} order={orderState} />
                </div>
                </div>}
            </div>
            :
            < div className="ms-header w-100 d-flex align-items-center justify-content-between">
              <div className="ms-header--title">
                <h1 className="header--title">Profile</h1>
              </div>
            </div>
          }


        </div>
        {/*end::Container*/}


      </div>
      {/*end::Header*/}
    </>
  );
}

export default Header;
