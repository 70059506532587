/* eslint-disable */ 
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
export const MeetingDensityPerDay = (props) => {
    let [seriesData, setSeriesData] = useState([]);
    const [shouldUpdate, setUpdate] = useState(false);
    const { timePeriod } = useSelector(state => state.picker);
    const { companyData } = useSelector(state => state.company);
    const [state, setState] = useState({
        series: [{
            name: 'Sun',
            data: [{
                x: '12am',
                y: 0
            }, {
                x: '1am',
                y: 0
            }, {
                x: '2am',
                y: 0
            }, {
                x: '3am',
                y: 0
            }, {
                x: '4am',
                y: 0
            }, {
                x: '5am',
                y: 0
            }, {
                x: '6am',
                y: 0
            }, {
                x: '7am',
                y: 0
            }, {
                x: '8am',
                y: 0
            }, {
                x: '9am',
                y: 0
            }, {
                x: '10am',
                y: 0
            }, {
                x: '11am',
                y: 0
            }, {
                x: '12pm',
                y: 0
            }, {
                x: '13pm',
                y: 0
            }, {
                x: '14pm',
                y: 0
            }, {
                x: '15pm',
                y: 0
            }, {
                x: '16pm',
                y: 0
            }, {
                x: '17pm',
                y: 0
            }, {
                x: '18pm',
                y: 0
            }, {
                x: '19pm',
                y: 0
            }, {
                x: '20pm',
                y: 0
            }, {
                x: '21pm',
                y: 0
            }, {
                x: '22pm',
                y: 0
            }, {
                x: '23pm',
                y: 0
            },
            ]
        }, {
            name: 'Sat',
            data: [{
                x: '12am',
                y: 0
            }, {
                x: '1am',
                y: 0
            }, {
                x: '2am',
                y: 0
            }, {
                x: '3am',
                y: 0
            }, {
                x: '4am',
                y: 0
            }, {
                x: '5am',
                y: 0
            }, {
                x: '6am',
                y: 0
            }, {
                x: '7am',
                y: 0
            }, {
                x: '8am',
                y: 0
            }, {
                x: '9am',
                y: 0
            }, {
                x: '10am',
                y: 0
            }, {
                x: '11am',
                y: 0
            }, {
                x: '12pm',
                y: 0
            }, {
                x: '13pm',
                y: 0
            }, {
                x: '14pm',
                y: 0
            }, {
                x: '15pm',
                y: 0
            }, {
                x: '16pm',
                y: 0
            }, {
                x: '17pm',
                y: 0
            }, {
                x: '18pm',
                y: 0
            }, {
                x: '19pm',
                y: 0
            }, {
                x: '20pm',
                y: 0
            }, {
                x: '21pm',
                y: 0
            }, {
                x: '22pm',
                y: 0
            }, {
                x: '23pm',
                y: 0
            },
            ]
        },
        {
            name: 'Fri',
            data: [{
                x: '12am',
                y: 0
            }, {
                x: '1am',
                y: 0
            }, {
                x: '2am',
                y: 0
            }, {
                x: '3am',
                y: 0
            }, {
                x: '4am',
                y: 0
            }, {
                x: '5am',
                y: 0
            }, {
                x: '6am',
                y: 0
            }, {
                x: '7am',
                y: 0
            }, {
                x: '8am',
                y: 0
            }, {
                x: '9am',
                y: 0
            }, {
                x: '10am',
                y: 0
            }, {
                x: '11am',
                y: 0
            }, {
                x: '12pm',
                y: 0
            }, {
                x: '13pm',
                y: 0
            }, {
                x: '14pm',
                y: 0
            }, {
                x: '15pm',
                y: 0
            }, {
                x: '16pm',
                y: 0
            }, {
                x: '17pm',
                y: 0
            }, {
                x: '18pm',
                y: 0
            }, {
                x: '19pm',
                y: 0
            }, {
                x: '20pm',
                y: 0
            }, {
                x: '21pm',
                y: 0
            }, {
                x: '22pm',
                y: 0
            }, {
                x: '23pm',
                y: 0
            },
            ]
        },

        {
            name: 'Thu',
            data: [{
                x: '12am',
                y: 0
            }, {
                x: '1am',
                y: 0
            }, {
                x: '2am',
                y: 0
            }, {
                x: '3am',
                y: 0
            }, {
                x: '4am',
                y: 0
            }, {
                x: '5am',
                y: 0
            }, {
                x: '6am',
                y: 0
            }, {
                x: '7am',
                y: 0
            }, {
                x: '8am',
                y: 0
            }, {
                x: '9am',
                y: 0
            }, {
                x: '10am',
                y: 0
            }, {
                x: '11am',
                y: 0
            }, {
                x: '12pm',
                y: 0
            }, {
                x: '13pm',
                y: 0
            }, {
                x: '14pm',
                y: 0
            }, {
                x: '15pm',
                y: 0
            }, {
                x: '16pm',
                y: 0
            }, {
                x: '17pm',
                y: 0
            }, {
                x: '18pm',
                y: 0
            }, {
                x: '19pm',
                y: 0
            }, {
                x: '20pm',
                y: 0
            }, {
                x: '21pm',
                y: 0
            }, {
                x: '22pm',
                y: 0
            }, {
                x: '23pm',
                y: 0
            },
            ]
        },
        {
            name: 'Wed',
            data: [{
                x: '12am',
                y: 0
            }, {
                x: '1am',
                y: 0
            }, {
                x: '2am',
                y: 0
            }, {
                x: '3am',
                y: 0
            }, {
                x: '4am',
                y: 0
            }, {
                x: '5am',
                y: 0
            }, {
                x: '6am',
                y: 0
            }, {
                x: '7am',
                y: 0
            }, {
                x: '8am',
                y: 0
            }, {
                x: '9am',
                y: 0
            }, {
                x: '10am',
                y: 0
            }, {
                x: '11am',
                y: 0
            }, {
                x: '12pm',
                y: 0
            }, {
                x: '13pm',
                y: 0
            }, {
                x: '14pm',
                y: 0
            }, {
                x: '15pm',
                y: 0
            }, {
                x: '16pm',
                y: 0
            }, {
                x: '17pm',
                y: 0
            }, {
                x: '18pm',
                y: 0
            }, {
                x: '19pm',
                y: 0
            }, {
                x: '20pm',
                y: 0
            }, {
                x: '21pm',
                y: 0
            }, {
                x: '22pm',
                y: 0
            }, {
                x: '23pm',
                y: 0
            },
            ]
        },
        {
            name: 'Tue',
            data: [{
                x: '12am',
                y: 0
            }, {
                x: '1am',
                y: 0
            }, {
                x: '2am',
                y: 0
            }, {
                x: '3am',
                y: 0
            }, {
                x: '4am',
                y: 0
            }, {
                x: '5am',
                y: 0
            }, {
                x: '6am',
                y: 0
            }, {
                x: '7am',
                y: 0
            }, {
                x: '8am',
                y: 0
            }, {
                x: '9am',
                y: 0
            }, {
                x: '10am',
                y: 0
            }, {
                x: '11am',
                y: 0
            }, {
                x: '12pm',
                y: 0
            }, {
                x: '13pm',
                y: 0
            }, {
                x: '14pm',
                y: 0
            }, {
                x: '15pm',
                y: 0
            }, {
                x: '16pm',
                y: 0
            }, {
                x: '17pm',
                y: 0
            }, {
                x: '18pm',
                y: 0
            }, {
                x: '19pm',
                y: 0
            }, {
                x: '20pm',
                y: 0
            }, {
                x: '21pm',
                y: 0
            }, {
                x: '22pm',
                y: 0
            }, {
                x: '23pm',
                y: 0
            },
            ]
        },
        {
            name: 'Mon',
            data: [{
                x: '12am',
                y: 0
            }, {
                x: '1am',
                y: 0
            }, {
                x: '2am',
                y: 0
            }, {
                x: '3am',
                y: 0
            }, {
                x: '4am',
                y: 0
            }, {
                x: '5am',
                y: 0
            }, {
                x: '6am',
                y: 0
            }, {
                x: '7am',
                y: 0
            }, {
                x: '8am',
                y: 0
            }, {
                x: '9am',
                y: 0
            }, {
                x: '10am',
                y: 0
            }, {
                x: '11am',
                y: 0
            }, {
                x: '12pm',
                y: 0
            }, {
                x: '13pm',
                y: 0
            }, {
                x: '14pm',
                y: 0
            }, {
                x: '15pm',
                y: 0
            }, {
                x: '16pm',
                y: 0
            }, {
                x: '17pm',
                y: 0
            }, {
                x: '18pm',
                y: 0
            }, {
                x: '19pm',
                y: 0
            }, {
                x: '20pm',
                y: 0
            }, {
                x: '21pm',
                y: 0
            }, {
                x: '22pm',
                y: 0
            }, {
                x: '23pm',
                y: 0
            },
            ]
        }
        ],
        options: {
            chart: {
                parentHeightOffset: 0,
                // offsetY: -25,
                toolbar: {
                    show: true,
                    tools:{
                      download:true // <== line to add
                    }
                  },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 1
            },
            // colors: ["#008FFB"],
            plotOptions: {
                heatmap: {
                    colorScale: {
                        ranges: [{
                            from: -1,
                            to: 0,
                            color: '#EDF1F5',
                            name: '- 0',
                        },
                        {
                            from: 1,
                            to: 75,
                            color: '#b3f0f7',
                            name: '- 1-75',
                        },
                        {
                            from: 75,
                            to: 150,
                            color: '#8FE0E9',
                            name: '- 75-150',
                        },
                        {
                            from: 150,
                            to: 225,
                            color: '#4ACBDA',
                            name: '- 150-225',
                        },
                        {
                            from: 225,
                            to: 300,
                            color: '#00ACBF',
                            name: '- 225-300',
                        },
                        {
                            from: 300,
                            to: 375,
                            color: '#008E9D',
                            name: '- 300-375',
                        },
                        {
                            from: 375,
                            to: 500,
                            color: '#00626C',
                            name: '- 375 and more',
                        },
                        ]
                    }
                }
            },
            states: {

                hover: {
                    filter: {
                        type: 'none',

                    }
                }
            },
            legend: {
                show: true,
                position: "bottom",
                horizontalAlign: 'center',
                floating: false,
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 400,
                offsetX: -50,
                offsetY: 0,
                onItemHover: {
                    highlightDataSeries: true
                },
                itemMargin: {
                    horizontal: 20,
                    vertical: 0
                },
                markers: {
                    width: 30,
                    height: 15,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    radius: 0,
                    offsetX: 0,
                    offsetY: 0
                },
            },
            xaxis: {
                show: true,
                position: 'top',
                labels: {
                    offsetY: 0,
                    style: {
                        colors: "#A2A7AD",
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                    },
                },
                title: {
                    text: "",
                    rotate: -90,
                    offsetX:-400 ,
                    offsetY: 271,
                    style: {
                        color: "black",
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 300,
                        // cssClass: 'apexcharts-yaxis-title',
                    },
                },
                axisTicks: {
                    show: false,
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                show: true,
                labels: {
                    style: {
                        colors: "#A2A7AD",
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                    },
                },
            }
        }
    })

    const renderLabels = (series) => {

        setSeriesData([])

        setSeriesData({
            ...seriesData,
            series
        })
    }

    useEffect(() => {

        let tempArr = [];
        let rangeGap = 2;

        seriesData && seriesData.series && seriesData.series.map(i =>
            i.data.map(element => {
                tempArr.push(element.y)
            })
        )

        let maxLength = Math.max(...tempArr);
        let diff = maxLength / 6;
        if (diff <= 2) {
            rangeGap = 2;
        } else {
            rangeGap = diff.toFixed(0);
        }


        seriesData && seriesData.length && setState({
            ...state,
            series: seriesData,
        })

        maxLength && setState({
            ...state,
            options: {
                ...state.options,
                plotOptions: {
                    ...state.options.plotOptions,
                    heatmap: {
                        ...state.options.plotOptions.heatmap,
                        colorScale: {
                            ...state.options.plotOptions.heatmap.colorScale,
                            ranges: [
                                {
                                    from: -1,
                                    to: 0,
                                    color: '#EDF1F5',
                                    name: '0',
                                },
                                {
                                    from: 1,
                                    to: rangeGap,
                                    color: '#b3f0f7',
                                    name: '1-' + rangeGap,
                                },
                                {
                                    from: parseInt(rangeGap) + 1,
                                    to: rangeGap * 2,
                                    color: '#8FE0E9',
                                    name: parseInt(rangeGap) + 1 + '-' + rangeGap * 2,
                                },
                                {
                                    from: parseInt(rangeGap * 2) + 1,
                                    to: rangeGap * 3,
                                    color: '#4ACBDA',
                                    name: parseInt(rangeGap * 2) + 1 + '-' + rangeGap * 3,
                                },
                                {
                                    from: parseInt(rangeGap * 3) + 1,
                                    to: rangeGap * 4,
                                    color: '#00ACBF',
                                    name: parseInt(rangeGap * 3) + 1 + '-' + rangeGap * 4,
                                },
                                {
                                    from: parseInt(rangeGap * 4) + 1,
                                    to: rangeGap * 5,
                                    color: '#008E9D',
                                    name: parseInt(rangeGap * 4) + 1 + '-' + rangeGap * 5,
                                },
                                {
                                    from: parseInt(rangeGap * 5) + 1,
                                    to: 500,
                                    color: '#06626C',
                                    name: parseInt(rangeGap * 5) + 1 + '-and more',
                                },
                            ]
                        }
                    }
                }
            }
        })


        setUpdate(true);

    }, [seriesData])

    useEffect(() => {
        setUpdate(false);

        let flag = 0;

        let stagingState = { ...state };

        companyData && companyData.events && companyData.events.map((i) => {
            let dateTime = i.event_start_datetime
            let eventStartDay = moment(dateTime).format('dddd');
            let eventStartTime = moment(dateTime).format('HH');
            // if(eventStartDay !== 'Saturday' && eventStartDay !== 'Sunday'){

            switch (eventStartDay) {

                case 'Monday':
                    stagingState.series.map((i, j) => {
                        if (i.name == 'Mon') {
                            const time = parseInt(eventStartTime);
                            stagingState.series[j].data[time].y = ++stagingState.series[j].data[time].y;

                        }
                    }
                    )
                    break;
                case 'Tuesday':
                    stagingState.series.map((i, j) => {
                        if (i.name == 'Tue') {
                            const time = parseInt(eventStartTime);
                            stagingState.series[j].data[time].y = ++stagingState.series[j].data[time].y;

                        }
                    }
                    )
                    break;
                case 'Wednesday':
                    stagingState.series.map((i, j) => {
                        if (i.name == 'Wed') {
                            const time = parseInt(eventStartTime);
                            stagingState.series[j].data[time].y = ++stagingState.series[j].data[time].y;

                        }
                    }
                    )
                    break;
                case 'Thursday':
                    stagingState.series.map((i, j) => {
                        if (i.name == 'Thu') {
                            const time = parseInt(eventStartTime);
                            stagingState.series[j].data[time].y = ++stagingState.series[j].data[time].y;

                        }
                    }
                    )
                    break;
                case 'Friday':
                    stagingState.series.map((i, j) => {
                        if (i.name == 'Fri') {
                            const time = parseInt(eventStartTime);
                            stagingState.series[j].data[time].y = ++stagingState.series[j].data[time].y;

                        }
                    }
                    )
                    break;
                case 'Saturday':
                    stagingState.series.map((i, j) => {
                        if (i.name == 'Sat') {
                            const time = parseInt(eventStartTime);
                            stagingState.series[j].data[time].y = ++stagingState.series[j].data[time].y;

                        }
                    }
                    )
                    break;
                case 'Sunday':
                    stagingState.series.map((i, j) => {
                        if (i.name == 'Sun') {
                            const time = parseInt(eventStartTime);
                            stagingState.series[j].data[time].y = ++stagingState.series[j].data[time].y;

                        }
                    }
                    )
                    break;
            }
            // }

            ++flag

            if (flag == companyData.events.length) {
                renderLabels(stagingState.series);

            }
        })
    }, [companyData && companyData.events])

    useEffect(() => {
        setState({
            ...state,
            series: [
                {
                    name: 'Sun',
                    data: [{
                        x: '12am',
                        y: 0
                    }, {
                        x: '1am',
                        y: 0
                    }, {
                        x: '2am',
                        y: 0
                    }, {
                        x: '3am',
                        y: 0
                    }, {
                        x: '4am',
                        y: 0
                    }, {
                        x: '5am',
                        y: 0
                    }, {
                        x: '6am',
                        y: 0
                    }, {
                        x: '7am',
                        y: 0
                    }, {
                        x: '8am',
                        y: 0
                    }, {
                        x: '9am',
                        y: 0
                    }, {
                        x: '10am',
                        y: 0
                    }, {
                        x: '11am',
                        y: 0
                    }, {
                        x: '12pm',
                        y: 0
                    }, {
                        x: '13pm',
                        y: 0
                    }, {
                        x: '14pm',
                        y: 0
                    }, {
                        x: '15pm',
                        y: 0
                    }, {
                        x: '16pm',
                        y: 0
                    }, {
                        x: '17pm',
                        y: 0
                    }, {
                        x: '18pm',
                        y: 0
                    }, {
                        x: '19pm',
                        y: 0
                    }, {
                        x: '20pm',
                        y: 0
                    }, {
                        x: '21pm',
                        y: 0
                    }, {
                        x: '22pm',
                        y: 0
                    }, {
                        x: '23pm',
                        y: 0
                    },
                    ]
                }, {
                    name: 'Sat',
                    data: [{
                        x: '12am',
                        y: 0
                    }, {
                        x: '1am',
                        y: 0
                    }, {
                        x: '2am',
                        y: 0
                    }, {
                        x: '3am',
                        y: 0
                    }, {
                        x: '4am',
                        y: 0
                    }, {
                        x: '5am',
                        y: 0
                    }, {
                        x: '6am',
                        y: 0
                    }, {
                        x: '7am',
                        y: 0
                    }, {
                        x: '8am',
                        y: 0
                    }, {
                        x: '9am',
                        y: 0
                    }, {
                        x: '10am',
                        y: 0
                    }, {
                        x: '11am',
                        y: 0
                    }, {
                        x: '12pm',
                        y: 0
                    }, {
                        x: '13pm',
                        y: 0
                    }, {
                        x: '14pm',
                        y: 0
                    }, {
                        x: '15pm',
                        y: 0
                    }, {
                        x: '16pm',
                        y: 0
                    }, {
                        x: '17pm',
                        y: 0
                    }, {
                        x: '18pm',
                        y: 0
                    }, {
                        x: '19pm',
                        y: 0
                    }, {
                        x: '20pm',
                        y: 0
                    }, {
                        x: '21pm',
                        y: 0
                    }, {
                        x: '22pm',
                        y: 0
                    }, {
                        x: '23pm',
                        y: 0
                    },
                    ]
                },
                {
                    name: 'Fri',
                    data: [{
                        x: '12am',
                        y: 0
                    }, {
                        x: '1am',
                        y: 0
                    }, {
                        x: '2am',
                        y: 0
                    }, {
                        x: '3am',
                        y: 0
                    }, {
                        x: '4am',
                        y: 0
                    }, {
                        x: '5am',
                        y: 0
                    }, {
                        x: '6am',
                        y: 0
                    }, {
                        x: '7am',
                        y: 0
                    }, {
                        x: '8am',
                        y: 0
                    }, {
                        x: '9am',
                        y: 0
                    }, {
                        x: '10am',
                        y: 0
                    }, {
                        x: '11am',
                        y: 0
                    }, {
                        x: '12pm',
                        y: 0
                    }, {
                        x: '13pm',
                        y: 0
                    }, {
                        x: '14pm',
                        y: 0
                    }, {
                        x: '15pm',
                        y: 0
                    }, {
                        x: '16pm',
                        y: 0
                    }, {
                        x: '17pm',
                        y: 0
                    }, {
                        x: '18pm',
                        y: 0
                    }, {
                        x: '19pm',
                        y: 0
                    }, {
                        x: '20pm',
                        y: 0
                    }, {
                        x: '21pm',
                        y: 0
                    }, {
                        x: '22pm',
                        y: 0
                    }, {
                        x: '23pm',
                        y: 0
                    },
                    ]
                },

                {
                    name: 'Thu',
                    data: [{
                        x: '12am',
                        y: 0
                    }, {
                        x: '1am',
                        y: 0
                    }, {
                        x: '2am',
                        y: 0
                    }, {
                        x: '3am',
                        y: 0
                    }, {
                        x: '4am',
                        y: 0
                    }, {
                        x: '5am',
                        y: 0
                    }, {
                        x: '6am',
                        y: 0
                    }, {
                        x: '7am',
                        y: 0
                    }, {
                        x: '8am',
                        y: 0
                    }, {
                        x: '9am',
                        y: 0
                    }, {
                        x: '10am',
                        y: 0
                    }, {
                        x: '11am',
                        y: 0
                    }, {
                        x: '12pm',
                        y: 0
                    }, {
                        x: '13pm',
                        y: 0
                    }, {
                        x: '14pm',
                        y: 0
                    }, {
                        x: '15pm',
                        y: 0
                    }, {
                        x: '16pm',
                        y: 0
                    }, {
                        x: '17pm',
                        y: 0
                    }, {
                        x: '18pm',
                        y: 0
                    }, {
                        x: '19pm',
                        y: 0
                    }, {
                        x: '20pm',
                        y: 0
                    }, {
                        x: '21pm',
                        y: 0
                    }, {
                        x: '22pm',
                        y: 0
                    }, {
                        x: '23pm',
                        y: 0
                    },
                    ]
                },
                {
                    name: 'Wed',
                    data: [{
                        x: '12am',
                        y: 0
                    }, {
                        x: '1am',
                        y: 0
                    }, {
                        x: '2am',
                        y: 0
                    }, {
                        x: '3am',
                        y: 0
                    }, {
                        x: '4am',
                        y: 0
                    }, {
                        x: '5am',
                        y: 0
                    }, {
                        x: '6am',
                        y: 0
                    }, {
                        x: '7am',
                        y: 0
                    }, {
                        x: '8am',
                        y: 0
                    }, {
                        x: '9am',
                        y: 0
                    }, {
                        x: '10am',
                        y: 0
                    }, {
                        x: '11am',
                        y: 0
                    }, {
                        x: '12pm',
                        y: 0
                    }, {
                        x: '13pm',
                        y: 0
                    }, {
                        x: '14pm',
                        y: 0
                    }, {
                        x: '15pm',
                        y: 0
                    }, {
                        x: '16pm',
                        y: 0
                    }, {
                        x: '17pm',
                        y: 0
                    }, {
                        x: '18pm',
                        y: 0
                    }, {
                        x: '19pm',
                        y: 0
                    }, {
                        x: '20pm',
                        y: 0
                    }, {
                        x: '21pm',
                        y: 0
                    }, {
                        x: '22pm',
                        y: 0
                    }, {
                        x: '23pm',
                        y: 0
                    },
                    ]
                },
                {
                    name: 'Tue',
                    data: [{
                        x: '12am',
                        y: 0
                    }, {
                        x: '1am',
                        y: 0
                    }, {
                        x: '2am',
                        y: 0
                    }, {
                        x: '3am',
                        y: 0
                    }, {
                        x: '4am',
                        y: 0
                    }, {
                        x: '5am',
                        y: 0
                    }, {
                        x: '6am',
                        y: 0
                    }, {
                        x: '7am',
                        y: 0
                    }, {
                        x: '8am',
                        y: 0
                    }, {
                        x: '9am',
                        y: 0
                    }, {
                        x: '10am',
                        y: 0
                    }, {
                        x: '11am',
                        y: 0
                    }, {
                        x: '12pm',
                        y: 0
                    }, {
                        x: '13pm',
                        y: 0
                    }, {
                        x: '14pm',
                        y: 0
                    }, {
                        x: '15pm',
                        y: 0
                    }, {
                        x: '16pm',
                        y: 0
                    }, {
                        x: '17pm',
                        y: 0
                    }, {
                        x: '18pm',
                        y: 0
                    }, {
                        x: '19pm',
                        y: 0
                    }, {
                        x: '20pm',
                        y: 0
                    }, {
                        x: '21pm',
                        y: 0
                    }, {
                        x: '22pm',
                        y: 0
                    }, {
                        x: '23pm',
                        y: 0
                    },
                    ]
                },
                {
                    name: 'Mon',
                    data: [{
                        x: '12am',
                        y: 0
                    }, {
                        x: '1am',
                        y: 0
                    }, {
                        x: '2am',
                        y: 0
                    }, {
                        x: '3am',
                        y: 0
                    }, {
                        x: '4am',
                        y: 0
                    }, {
                        x: '5am',
                        y: 0
                    }, {
                        x: '6am',
                        y: 0
                    }, {
                        x: '7am',
                        y: 0
                    }, {
                        x: '8am',
                        y: 0
                    }, {
                        x: '9am',
                        y: 0
                    }, {
                        x: '10am',
                        y: 0
                    }, {
                        x: '11am',
                        y: 0
                    }, {
                        x: '12pm',
                        y: 0
                    }, {
                        x: '13pm',
                        y: 0
                    }, {
                        x: '14pm',
                        y: 0
                    }, {
                        x: '15pm',
                        y: 0
                    }, {
                        x: '16pm',
                        y: 0
                    }, {
                        x: '17pm',
                        y: 0
                    }, {
                        x: '18pm',
                        y: 0
                    }, {
                        x: '19pm',
                        y: 0
                    }, {
                        x: '20pm',
                        y: 0
                    }, {
                        x: '21pm',
                        y: 0
                    }, {
                        x: '22pm',
                        y: 0
                    }, {
                        x: '23pm',
                        y: 0
                    },
                    ]
                }
            ]
        })

    }, [timePeriod.filter, timePeriod.sdate, timePeriod.edate, timePeriod.custom])

    return (

        <div className="col-xl-12">

            <div className="card mb-4 card-custom">
                {/* Header */}
                <div className="card-header align-items-center border-0">
                    <h3 className="card-title align-items-center d-flex w-100 justify-content-between"><span className="font-weight-bolder text-dark">Meeting Density per Day</span></h3>
                </div>

                {/* Body */}
                <div className="card-body loader-container22">
                    {!shouldUpdate &&
                        <div className='loader-container'>
                          { companyData == null ?  <Spinner animation="border" role="status">
                                <span className="visually-hidden"></span>
                            </Spinner> : (companyData && companyData.events.length == 0 ? "We're sorry, for the time you requested, we don't have any data to share with you." : null)}
                        </div>
                    }
                    {shouldUpdate && <ReactApexChart options={state.options} series={state.series} type="heatmap" height={400} />}
                </div>
            </div>
        </div>

    );
};
