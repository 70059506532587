import { RECEIVE_LEADERBOARD_DATA, RECEIVE_LEADERBOARD_FOR_MASTERY, RECEIVE_MEETING_MASTERY_INFO } from "./leaderboard.action";
import {DEFAULT_STATE} from './leaderboard.state'

export const leaderboardReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case RECEIVE_LEADERBOARD_DATA:
            return {
                ...state,
                data:action.data
            }
        case RECEIVE_MEETING_MASTERY_INFO:
            return {
                ...state,
                mastery_data:action.data
                }
        case RECEIVE_LEADERBOARD_FOR_MASTERY:
            return {
                ...state,
                mastery_leaderboard:action.data
                }            
        default:
            return state;
    }
}