import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { attendeesReducer } from "./duck/dashboard/attended/attended.reducer";
import { unratedMeetingsReducer } from "./duck/dashboard/unrated/unrated.reducer";
import { organizedReducer } from "./duck/dashboard/organized/organized.reducer";
import { userProfileReducer } from "./duck/userProfile/userProfile.reducer";
import { timeMachineReducer } from "./duck/reports/timeMachine/timeMachine.reducer";
import { pickerReducer } from "./duck/datepicker/picker.reducer";
import { meetingMasteryReducer } from "./duck/reports/meetingMastery/mastery.reducer";
import { scoreReducer } from "./duck/reports/scoreModal/score.reducer";
import { leaderboardReducer } from "./duck/reports/leaderboard/leaderboard.reducer";
import { companyReducer } from "./duck/dashboard/company/company.reducer";
import { coachingReducer } from "./duck/reports/coaching/coaching.reducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  picker: pickerReducer,
  profile: userProfileReducer,
  attended: attendeesReducer,
  unrated: unratedMeetingsReducer,
  organized: organizedReducer,
  company:companyReducer,
  timeMachine: timeMachineReducer,
  mastery: meetingMasteryReducer,
  score: scoreReducer,
  leaderboard: leaderboardReducer,
  coaching: coachingReducer
});
