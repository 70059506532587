/* eslint-disable */ 

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FilterRange } from "../../layout/components/header/FilterLabel";

export function HoursConsumedByMeetingDuration() {

    let seriesData = []
    const { companyData } = useSelector(state => state.company);
    const {timePeriod} = useSelector(state => state.picker)

    const [state, setState] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true,
                    tools:{
                      download:true // <== line to add
                    }
                  },
            },
            states: {

                hover: {
                    filter: {
                        type: 'none',

                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 0,
            },
            title: {
                text: 'Duration, min',
                align: 'left',
                style: {
                    fontSize: '12px',
                    color: '#BFC2C7'
                },
            },
            colors:['#D3F5F9','#8FE0E9','#4ACBDA','#00ACBF','#008E9D','#008E9D','#00626C','#00626C','#02444C','#02444C'],
            dataLabels: {
                enabled: false
            },
            grid: {
                show: true,
                borderColor: '#F8F9FB',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                row: {
                    colors: undefined,
                    opacity: 0.5
                },
                column: {
                    colors: undefined,
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: ['Other', '120 min', '90 min', '80 min', '60 min', '50 min', '45 min', '30 min', '25 min', '15 min' ],
                labels: {
                    style: {
                        colors: "#999EA5",
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                    formatter: function (val) {
                        return val
                    }
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: true,
                    borderType: 'dotted',
                    color: '#F4F5F8',
                    height: 10,
                },
                title: {
                    text: "Total company time (hours)",
                    style: {
                        fontSize: '12px',
                        color: '#999EA5',
                        fontWeight: "light"
                    },
                }
            },
            yaxis: {
                show: true,
                labels: {
                    style: {
                        colors: "#999EA5",
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                },
            },
            tooltip: {
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    return "<div style='padding:10px;width:300px;font-size:13px'>" + w.config.series[seriesIndex].name + "</div>" 
                  },
                y: {
                    show: false,
                    },
                x: {
                    show: false,
                },
                marker: {
                    show: false,
                },
            },
            fill: {
                opacity: 1
            },
            legend: {
                show: false,
                }
            }
    });


    const renderLabels = () => {

        seriesData.length && setState({
            ...state,
            series:seriesData
        })
    }

    const performCalculations = (data) => {
        let AvgPeople = {
            Fifteen:data.averagePeoples.fifteen.length ? ((data.averagePeoples.fifteen.reduce(addFunc))/data.totalMeetings.fifteen).toFixed() : 0,
            TwentyFive:data.averagePeoples.twentyfive.length ? ((data.averagePeoples.twentyfive.reduce(addFunc))/data.totalMeetings.twentyfive).toFixed() : 0,
            Thirty:data.averagePeoples.thirty.length ? ((data.averagePeoples.thirty.reduce(addFunc))/data.totalMeetings.thirty).toFixed() : 0,
            FortyFive:data.averagePeoples.fortyfive.length ? ((data.averagePeoples.fortyfive.reduce(addFunc))/data.totalMeetings.fortyfive).toFixed() : 0,
            Fifty:data.averagePeoples.fifty.length ? ((data.averagePeoples.fifty.reduce(addFunc))/data.totalMeetings.fifty ).toFixed(): 0,
            Sixty:data.averagePeoples.sixty.length ? ((data.averagePeoples.sixty.reduce(addFunc))/data.totalMeetings.sixty).toFixed() : 0,
            Eighty:data.averagePeoples.eighty.length ? ((data.averagePeoples.eighty.reduce(addFunc))/data.totalMeetings.eighty).toFixed() : 0,
            Ninety:data.averagePeoples.ninety.length ? ((data.averagePeoples.ninety.reduce(addFunc))/data.totalMeetings.ninety).toFixed() : 0,
            OneTwenty:data.averagePeoples.onetwenty.length ? ((data.averagePeoples.onetwenty.reduce(addFunc))/data.totalMeetings.onetwenty).toFixed() : 0,
            Other:data.averagePeoples.others.length ? ((data.averagePeoples.others.reduce(addFunc))/data.totalMeetings.others).toFixed() : 0
        }

        let totalCompanyTime = {
            Fifteen:data.companyTime.fifteen.length ? ((data.companyTime.fifteen.reduce(addFunc))).toFixed() : 0,
            TwentyFive:data.companyTime.twentyfive.length ? ((data.companyTime.twentyfive.reduce(addFunc))).toFixed() : 0,
            Thirty:data.companyTime.thirty.length ? ((data.companyTime.thirty.reduce(addFunc))).toFixed() : 0,
            FortyFive:data.companyTime.fortyfive.length ? ((data.companyTime.fortyfive.reduce(addFunc))).toFixed() : 0,
            Fifty:data.companyTime.fifty.length ? ((data.companyTime.fifty.reduce(addFunc))).toFixed() : 0,
            Sixty:data.companyTime.sixty.length ? ((data.companyTime.sixty.reduce(addFunc))).toFixed() : 0,
            Eighty:data.companyTime.eighty.length ? ((data.companyTime.eighty.reduce(addFunc))).toFixed(): 0,
            Ninety:data.companyTime.ninety.length ? ((data.companyTime.ninety.reduce(addFunc))).toFixed(): 0,
            OneTwenty:data.companyTime.onetwenty.length ? ((data.companyTime.onetwenty.reduce(addFunc))).toFixed() : 0,
            Other:data.companyTime.others.length ? ((data.companyTime.others.reduce(addFunc))).toFixed() : 0
        }

        let totalMeetingsTime = {
            Fifteen:data.meetingTime.fifteen.length ? ((data.meetingTime.fifteen.reduce(addFunc))).toFixed(): 0,
            TwentyFive:data.meetingTime.twentyfive.length ? ((data.meetingTime.twentyfive.reduce(addFunc))).toFixed(): 0,
            Thirty:data.meetingTime.thirty.length ? ((data.meetingTime.thirty.reduce(addFunc))).toFixed() : 0,
            FortyFive:data.meetingTime.fortyfive.length ? ((data.meetingTime.fortyfive.reduce(addFunc))).toFixed() : 0,
            Fifty:data.meetingTime.fifty.length ? ((data.meetingTime.fifty.reduce(addFunc))).toFixed(): 0,
            Sixty:data.meetingTime.sixty.length ? ((data.meetingTime.sixty.reduce(addFunc))).toFixed() : 0,
            Eighty:data.meetingTime.eighty.length ? ((data.meetingTime.eighty.reduce(addFunc))).toFixed() : 0,
            Ninety:data.meetingTime.ninety.length ? ((data.meetingTime.ninety.reduce(addFunc))).toFixed(): 0,
            OneTwenty:data.meetingTime.onetwenty.length ? ((data.meetingTime.onetwenty.reduce(addFunc))).toFixed() : 0,
            Other:data.meetingTime.others.length ? ((data.meetingTime.others.reduce(addFunc))).toFixed() : 0
        }

        seriesData = [
            { 
                name:'<span style="width:170px"> <strong>Total meetings: </strong>'+ data.totalMeetings.fifteen +'</span>' + '<br> <strong>Average people: </strong>'+ AvgPeople.Fifteen +'</span>' + '<br> <strong>Total company time:</strong>' + totalCompanyTime.Fifteen + '  hr(s)</span>' + '<br> <strong>Total meeting time:</strong>'+ totalMeetingsTime.Fifteen +' hr(s)</span>',
                data:[0, 0, 0, 0, 0, 0, 0, 0, 0, totalCompanyTime.Fifteen],
            },
    
            { 
                name:'<span style="width:170px"> <strong>Total meetings: </strong>'+ data.totalMeetings.twentyfive +'</span>' + '<br> <strong>Average people: </strong>'+ AvgPeople.TwentyFive +'</span>' + '<br> <strong>Total company time:</strong>' + totalCompanyTime.TwentyFive + '  hr(s)</span>' + '<br> <strong>Total meeting time:</strong>'+ totalMeetingsTime.TwentyFive +' hr(s)</span>',
                data:[0, 0, 0, 0, 0, 0, 0, 0, totalCompanyTime.TwentyFive, 0],
            },
    
            { 
                name:'<span style="width:170px"> <strong>Total meetings: </strong>'+ data.totalMeetings.thirty +'</span>' + '<br> <strong>Average people: </strong>'+ AvgPeople.Thirty +'</span>' + '<br> <strong>Total company time:</strong>' + totalCompanyTime.Thirty + '  hr(s)</span>' + '<br> <strong>Total meeting time:</strong>'+ totalMeetingsTime.Thirty +' hr(s)</span>',
                data:[0, 0, 0, 0, 0, 0, 0, totalCompanyTime.Thirty, 0,0],
            },
    
            { 
                name:'<span style="width:170px"> <strong>Total meetings: </strong>'+ data.totalMeetings.fortyfive +'</span>' + '<br> <strong>Average people: </strong>'+ AvgPeople.FortyFive +'</span>' + '<br> <strong>Total company time:</strong>' + totalCompanyTime.FortyFive + '  hr(s)</span>' + '<br> <strong>Total meeting time:</strong>'+ totalMeetingsTime.FortyFive +' hr(s)</span>',
                data:[0, 0, 0, 0, 0, 0, totalCompanyTime.FortyFive, 0, 0, 0],
            },
            { 
                name:'<span style="width:170px"> <strong>Total meetings: </strong>'+ data.totalMeetings.fifty +'</span>' + '<br> <strong>Average people: </strong>'+ AvgPeople.Fifty +'</span>' + '<br> <strong>Total company time:</strong>' + totalCompanyTime.Fifty + '  hr(s)</span>' + '<br> <strong>Total meeting time:</strong>'+ totalMeetingsTime.Fifty +' hr(s)</span>',
                data:[0, 0, 0, 0, 0, totalCompanyTime.Fifty, 0, 0, 0, 0],
            },
            { 
                name:'<span style="width:170px"> <strong>Total meetings: </strong>'+ data.totalMeetings.sixty +'</span>' + '<br> <strong>Average people: </strong>'+ AvgPeople.Sixty +'</span>' + '<br> <strong>Total company time:</strong>' + totalCompanyTime.Sixty + '  hr(s)</span>' + '<br> <strong>Total meeting time:</strong>'+ totalMeetingsTime.Sixty +' hr(s)</span>',
                data:[0, 0, 0, 0, totalCompanyTime.Sixty, 0, 0, 0, 0, 0],
            },
            { 
                name:'<span style="width:170px"> <strong>Total meetings: </strong>'+ data.totalMeetings.eighty +'</span>' + '<br> <strong>Average people: </strong>'+ AvgPeople.Eighty +'</span>' + '<br> <strong>Total company time:</strong>' + totalCompanyTime.Eighty + '  hr(s)</span>' + '<br> <strong>Total meeting time:</strong>'+ totalMeetingsTime.Eighty +' hr(s)</span>',
                data:[0, 0, 0, totalCompanyTime.Eighty, 0, 0, 0, 0, 0, 0],
            },
            { 
                name:'<span style="width:170px"> <strong>Total meetings: </strong>'+ data.totalMeetings.ninety +'</span>' + '<br> <strong>Average people: </strong>'+ AvgPeople.Ninety +'</span>' + '<br> <strong>Total company time:</strong>' + totalCompanyTime.Ninety + '  hr(s)</span>' + '<br> <strong>Total meeting time:</strong>'+ totalMeetingsTime.Ninety +' hr(s)</span>',
                data:[0, 0, totalCompanyTime.Ninety, 0, 0, 0, 0, 0, 0, 0],
            },
            { 
                name:'<span style="width:170px"> <strong>Total meetings: </strong>'+ data.totalMeetings.onetwenty +'</span>' + '<br> <strong>Average people: </strong>'+ AvgPeople.OneTwenty +'</span>' + '<br> <strong>Total company time:</strong>' + totalCompanyTime.OneTwenty + ' hr(s)</span>' + '<br> <strong>Total meeting time:</strong>'+ totalMeetingsTime.OneTwenty +' hr(s)</span>',
                data:[0, totalCompanyTime.OneTwenty, 0, 0, 0, 0, 0, 0, 0, 0],
            },
    
            { 
                name:'<span style="width:170px"> <strong>Total meetings: </strong>'+ data.totalMeetings.others +'</span>' + '<br> <strong>Average people: </strong>'+ AvgPeople.Other +'</span>' + '<br> <strong>Total company time:</strong>' + totalCompanyTime.Other + ' hr(s)</span>' + '<br> <strong>Total meeting time:</strong>'+ totalMeetingsTime.Other + 'hr(s)</span>',
                data:[totalCompanyTime.Other, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
    
            ]

            renderLabels();
    }

    const addFunc = (total,num) => {
        return total + num;
    }

    useEffect(() => {
        
        let flag = 0;
        let rawData = {
            meetingTime:{
                fifteen:[],
                twentyfive:[],
                thirty:[],
                fortyfive:[],
                fifty:[],
                sixty:[],
                eighty:[],
                ninety:[],
                onetwenty:[],
                others:[]
            },
            companyTime:{
                fifteen:[],
                twentyfive:[],
                thirty:[],
                fortyfive:[],
                fifty:[],
                sixty:[],
                eighty:[],
                ninety:[],
                onetwenty:[],
                others:[]
            },
            averagePeoples:{
                fifteen:[],
                twentyfive:[],
                thirty:[],
                fortyfive:[],
                fifty:[],
                sixty:[],
                eighty:[],
                ninety:[],
                onetwenty:[],
                others:[]
            },
            totalMeetings:{
                fifteen:0,
                twentyfive:0,
                thirty:0,
                fortyfive:0,
                fifty:0,
                sixty:0,
                eighty:0,
                ninety:0,
                onetwenty:0,
                others:0
            }
        }

       companyData && companyData.events && companyData.events.length && companyData.events.map(i => {
                    if(((i.event_duration*60).toFixed(0)) == 15){

                            rawData.companyTime.fifteen.push(i.event_duration * i.total_attendees);    
                            if(i.total_attendees !== "" && i.total_attendees!== null) {
                                rawData.averagePeoples.fifteen.push(i.total_attendees);
                            }
                            rawData.totalMeetings.fifteen = ++rawData.totalMeetings.fifteen;
                            rawData.meetingTime.fifteen.push(i.event_duration);
                   
                        }else if(((i.event_duration*60).toFixed(0)) == 25){

                            rawData.companyTime.twentyfive.push(i.event_duration * i.total_attendees);
                            if(i.total_attendees !== "" && i.total_attendees!== null) {
                            rawData.averagePeoples.twentyfive.push(i.total_attendees);
                            }
                            rawData.totalMeetings.twentyfive = ++rawData.totalMeetings.twentyfive;
                            rawData.meetingTime.twentyfive.push(i.event_duration);
                   
                        }else if(((i.event_duration*60).toFixed(0)) == 30){

                            rawData.companyTime.thirty.push(i.event_duration * i.total_attendees);
                            if(i.total_attendees !== "" && i.total_attendees!== null) {
                            rawData.averagePeoples.thirty.push(i.total_attendees);
                            }
                            rawData.totalMeetings.thirty = ++rawData.totalMeetings.thirty;
                            rawData.meetingTime.thirty.push(i.event_duration);
                   
                        }else if(((i.event_duration*60).toFixed(0)) == 45){

                            rawData.companyTime.fortyfive.push(i.event_duration * i.total_attendees);
                            if(i.total_attendees !== "" && i.total_attendees!== null) {
                            rawData.averagePeoples.fortyfive.push(i.total_attendees);
                            }
                            rawData.totalMeetings.fortyfive = ++rawData.totalMeetings.fortyfive;
                            rawData.meetingTime.fortyfive.push(i.event_duration);
                   
                        }else if(((i.event_duration*60).toFixed(0)) == 50){

                            rawData.companyTime.fifty.push(i.event_duration * i.total_attendees);
                            if(i.total_attendees !== "" && i.total_attendees!== null) {
                            rawData.averagePeoples.fifty.push(i.total_attendees);
                            }
                            rawData.totalMeetings.fifty = ++rawData.totalMeetings.fifty;
                            rawData.meetingTime.fifty.push(i.event_duration);
                   
                        }else if(((i.event_duration*60).toFixed(0)) ==  60){

                            rawData.companyTime.sixty.push(i.event_duration * i.total_attendees);
                            if(i.total_attendees !== "" && i.total_attendees!== null) {
                            rawData.averagePeoples.sixty.push(i.total_attendees);
                            }
                            rawData.totalMeetings.sixty = ++rawData.totalMeetings.sixty;
                            rawData.meetingTime.sixty.push(i.event_duration);
                   
                        }else if(((i.event_duration*60).toFixed(0)) == 80){

                            rawData.companyTime.eighty.push(i.event_duration * i.total_attendees);
                            if(i.total_attendees !== "" && i.total_attendees!== null) {
                            rawData.averagePeoples.eighty.push(i.total_attendees);
                            }
                            rawData.totalMeetings.eighty = ++rawData.totalMeetings.eighty;
                            rawData.meetingTime.eighty.push(i.event_duration);
                   
                        }else if(((i.event_duration*60).toFixed(0)) == 90){

                            rawData.companyTime.ninety.push(i.event_duration * i.total_attendees);
                            if(i.total_attendees !== "" && i.total_attendees!== null) {
                            rawData.averagePeoples.ninety.push(i.total_attendees);
                            }
                            rawData.totalMeetings.ninety = ++rawData.totalMeetings.ninety;
                            rawData.meetingTime.ninety.push(i.event_duration);
                   
                        }else if(((i.event_duration*60).toFixed(0)) == 120){

                            rawData.companyTime.onetwenty.push(i.event_duration * i.total_attendees);
                            if(i.total_attendees !== "" && i.total_attendees!== null) {
                            rawData.averagePeoples.onetwenty.push(i.total_attendees);
                            }
                            rawData.totalMeetings.onetwenty = ++rawData.totalMeetings.onetwenty;
                            rawData.meetingTime.onetwenty.push(i.event_duration);
                   
                        }else if( 
                            ((i.event_duration)*60).toFixed(0) !== '0'          
                            && ((i.event_duration)*60).toFixed(0) !== '15' 
                            && ((i.event_duration)*60).toFixed(0) !== '25'
                            && ((i.event_duration)*60).toFixed(0) !== '30'
                            && ((i.event_duration)*60).toFixed(0) !== '45'
                            && ((i.event_duration)*60).toFixed(0) !== '50'
                            && ((i.event_duration)*60).toFixed(0) !== '60'
                            && ((i.event_duration)*60).toFixed(0) !== '80'
                            && ((i.event_duration)*60).toFixed(0) !== '90'
                            && ((i.event_duration)*60).toFixed(0) !== '120'
                        ){
                            rawData.companyTime.others.push(i.event_duration * i.total_attendees);
                            if(i.total_attendees !== "" && i.total_attendees!== null) {
                            rawData.averagePeoples.others.push(i.total_attendees);
                            }
                            rawData.totalMeetings.others = ++rawData.totalMeetings.others;
                            rawData.meetingTime.others.push(i.event_duration);
                        }

                        ++flag;
        })

        setTimeout(() => {
            companyData && companyData.events && companyData.events.length && flag == companyData.events.length && performCalculations(rawData);
        }, 5000);

     

        // eslint-disable-next-line react-hooks/exhaustive-deps

        

    }, [companyData && companyData.events && companyData.events.length]);

    

    useEffect(() => {
        setState({
            ...state,
            series:[]
        })
        seriesData = [];
    },[timePeriod.filter,timePeriod.sdate,timePeriod.edate,timePeriod.custom])

    return (
        <div className="col-md-6">
        <div className="card mb-4 card-custom">
          {/* Header */}
          <div className="card-header align-items-center border-0">
              <span className="font-weight-bolder text-dark">Hours Consumed by Meeting Duration</span>
            <h3 className="card-title align-items-start flex-column summary_meeting_line1">
              <span className="text-muted mt-3 font-weight-bold font-size-sm summary_meeting_line">Summary meetings duration for the <FilterRange/></span>
            </h3>
          </div>

          {/* Body */}
                <div className="card-body position-relative overflow-hidden">
                    {companyData && companyData.events && companyData.events.length > 0 ?
                        <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
                        :
                        <div className='loader-container'>
                      { companyData == null ?  <Spinner animation="border" role="status">
                                <span className="visually-hidden"></span>
                            </Spinner> : (companyData && companyData.events.length == 0 ? "We're sorry, for the time you requested, we don't have any data to share with you." : null)}
                   </div>
                    }
            </div>
          </div>        
        </div>
    );
}
