/* eslint-disable */ 
import React,{useEffect, useState} from 'react';
import {Spinner } from "react-bootstrap";
import {useSelector} from 'react-redux';
import parse from 'html-react-parser';
import { capitalize } from '@material-ui/core';
import moment from 'moment';
import { toAbsoluteUrl } from '../../_helpers';
import {logActivity} from '../../../app/modules/Auth/_redux/authCrud';
import { MeetingSummaryModal } from '.';
export const MeetingWithMostPeopleList = () => {

    const {events,status} = useSelector(state => state.attended)
    const [sort,setSorting] = useState(false);
    const {timePeriod} = useSelector(state => state.picker);
    const [sortedDataset,setSortedData] = useState([]);
    // const [meetingModal, setMeetingModal] = useState(false);
  // const [meetingSummary,setSummary] = useState();

    const handleSummaryModal = (id) => {
      logActivity('modal','/meeting-summary',id,window.location.pathname);
      // console.log(events);
      const event_index =  events.findIndex((i) => i.event_id == id);  
      // console.log(event_index);
      if(event_index !== -1){
        setSummary(events[event_index]);
        setMeetingModal(true);
      }
  }

    useEffect(() => {
        
        let unsorted = [];
        setSortedData([]);

        if(events && status == 'success'){
                events.map(i => {
                    unsorted.push(i);
                })

           let sortedData =  unsorted.sort( function ( a, b ) { return sort ? a.total_attendees - b.total_attendees  :  b.total_attendees - a.total_attendees   ; } );
            setSortedData(sortedData);
        }
    },[events,timePeriod.filter,timePeriod.sdate,timePeriod.edate,timePeriod.custom,sort])

    return (
      <>
<table className="table expensive--meetings">
              <thead>
                <tr className='border-0'>
                    <th scope="col">Meeting Name</th>
                    <th scope="col" style={{display:'flex',justifyContent:'flex-end'}}>
                    <div className="table-filter"   onClick={() => setSorting(!sort)} style={{cursor:'pointer'}}>
                                            Number of people
                                            <span className="table-filter--icn custm_popover">
                                                {sort === false ? <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i> </> :  <> <i className="fas fa-caret-up "></i><i className="fas fa-caret-down active"></i> </>}
                                            </span>
                                        </div>
                    </th>
                </tr>
              </thead>
        </table>

      <div id="List" style={{display:'block',height:'280px',overflowY:'auto',marginTop:'-15px'}}>
            <table className="table expensive--meetings">
              <tbody>
              {events  ? 
                  status == 'success' && sortedDataset.length > 0 ?    
                  sortedDataset.map((key,index) => 
                  <tr title={(key.event_title!==null && key.event_title!== '' ? key.event_title : 'N/A') + (' with ' + key.total_attendees + ' people ')  } className="" key={index} style={{paddingRight:'10px'}}>
                    <td style={{verticalAlign:'middle',}}>{key.event_title!==null && key.event_title!== '' ? parse(capitalize(key.event_title)) : 'N/A'}  <b>{moment(key.event_start_datetime).format('MM/DD')}</b> </td>
                    <td style={{textAlign:'right',fontWeight:'bold',alignItems:'center',paddingRight:'10px',minWidth:'34px'}}>{key.total_attendees} <img style={{width:12,height:14}} src={toAbsoluteUrl('/media/ms-media/user-avatar.jpg')} /></td>
                  </tr>
                ) : <tr><td>We're sorry, for the time you requested, we don't have any data to share with you.</td></tr>
                
                :  
                ( status !== 'error' ? <tr>
                    <td className='loader-container'>
                      <Spinner animation="border" role="status" >
                        <span className="visually-hidden"></span>
                      </Spinner>
                    </td>
                  </tr>

                  :
                  <tr>
                    <td>We're sorry, for the time you requested, we don't have any data to share with you.</td>
                  </tr>
                )
              }
              
              </tbody>
            </table>
            </div>
            {/* {meetingModal && <MeetingSummaryModal data = {meetingSummary} show={meetingModal} handleClose={() => setMeetingModal(false)} />} */}
            </>
    );
};