import React, { useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { WeeklyScoreModal } from './widget9/WeeklyScoreModal';
import { HowYouCompareSlider } from './widget9/HowYouCompareSlider';
import { ExecutiveLevelSlider } from './widget9/ExecutiveLevelSlider';
// import { MeetingsLastWeek } from './widget9/MeetingsLastWeek';
import HowYouImprove from './widget9/HowYouImprove';
import { ProgressAndTrendsChart } from './widget9/ProgressAndTrendsChart';
import { useDispatch, useSelector } from 'react-redux';
import { getUserScoreDetail } from '../../../redux/duck/reports/scoreModal/score.action';
import { authTimezone } from '../../_helpers/functions';
import './widgets.css'

export const ScoreModal = (props) => {
    // const user_id = authUserId();
    const user_timezone = authTimezone();

    const dispatch = useDispatch();
    // redux state
    const { getUserScoreData } = useSelector((state) => state.score);

    useEffect(() => {
        if (props.show === true) {
       
            dispatch(getUserScoreDetail({   }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props,  user_timezone])



    return (
        <>
            {getUserScoreData.user_score !== "N/A" ?
            <Modal className="score-modal"
                    show={props.show} onHide={props.handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Score
                    </Modal.Title>
                    <button className="btn btn-close p-0 ml-auto" onClick={props.handleClose}><img src="/media/ms-media/modal-close-icn.svg" alt="" /></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card modal-card">
                                <div className="card-body d-flex flex-column">
                                    <div className="card-body--title">Your Weekly Score</div>
                                    <div className="flex-grow-1">
                                            <WeeklyScoreModal props={props} />
                                    </div>
                                    <p className="text-muted small mb-0 text-center">The % change values relate to the previous week</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card modal-card howCompare-card">
                                <div className="card-body">
                                        <div className="card-body--title mb-6">How You Compare</div>
                                        <HowYouCompareSlider />
                                        <ExecutiveLevelSlider />
                                    {/* <p className="mt-4">Meetings per last week:  <strong>7</strong></p>
                                    <div className="auth-selectgroup">
                                        <label className="auth-selectgroup-item">
                                            <input className="selectgroup-input" onChange={() => setData({ ...data, role: "Attended" })} type="radio" name="role" checked={data.role === "Attended" ? true : false} id="Attended" value="Attended" />
                                            <span className="selectgroup-button">Attended</span>
                                        </label>
                                        <label className="auth-selectgroup-item">
                                            <input className="selectgroup-input" onChange={() => setData({ ...data, role: "Organized" })} type="radio" name="role" checked={data.role === "Organized" ? true : false} id="Organized" value="Organized" />
                                            <span className="selectgroup-button">Organized</span>
                                        </label>
                                    </div>
                                    <div className="howCompare-slider howCompare-multicolor">
                                        <MeetingsLastWeek value={data.meetingsPerWeekValue} handleSlider={handleMeetingsPerWeekSlider} marks={meetingsPerWeekMarks} valuetext={valuetext} />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <HowYouImprove />
                        </div>
                        <div className="col-md-6">
                            <div className="card modal-card">
                                <div className="card-body custom-download-p-t">
                                    <div className="card-body--title">Your Progress & Trends</div>
                                    <ProgressAndTrendsChart />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
                :
                <Modal show={props.show} onHide={props.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header>
                        <button className="btn btn-close p-0 ml-auto" onClick={props.handleClose}><img src="/media/ms-media/modal-close-icn.svg" alt="" /></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert-modal">
                            <div className="modal-alert-icon"> <img src="/media/ms-media/meeting-cross-icn.svg" alt="" /> </div>
                            <p className="mb-0 text-center">You have no Score Details</p>
                        </div>
                    </Modal.Body>
                </Modal>}
        </>
    );
};