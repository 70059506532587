// View User Score Info Detail
export const GET_USER_SCORE_DETAIL = "GET_USER_SCORE_DETAIL";
export const getUserScoreDetail = (data) => ({ type: GET_USER_SCORE_DETAIL, data })
export const SUCCESS_GET_USER_SCORE_DETAIL = 'SUCCESS_GET_USER_SCORE_DETAIL';
export const ERROR_GET_USER_SCORE_DETAIL = 'ERROR_GET_USER_SCORE_DETAIL';
export const getUserScoreDetailResponse = (type, data) => ({ type, data });

// View User Score Comparison Info Detail
export const GET_USER_SCORE_COMPARISON_DETAIL = "GET_USER_SCORE_COMPARISON_DETAIL";
export const getUserScoreComparisonDetail = (data) => ({ type: GET_USER_SCORE_COMPARISON_DETAIL, data })
export const SUCCESS_GET_USER_SCORE_COMPARISON_DETAIL = 'SUCCESS_GET_USER_SCORE_COMPARISON_DETAIL';
export const ERROR_GET_USER_SCORE_COMPARISON_DETAIL = 'ERROR_GET_USER_SCORE_COMPARISON_DETAIL';
export const getUserScoreComparisonDetailResponse = (type, data) => ({ type, data });

// View User Score Graph Detail
export const GET_USER_SCORE_GRAPH_DETAIL = "GET_USER_SCORE_GRAPH_DETAIL";
export const getUserScoreGraphDetail = (data) => ({ type: GET_USER_SCORE_GRAPH_DETAIL, data })
export const SUCCESS_GET_USER_SCORE_GRAPH_DETAIL = 'SUCCESS_GET_USER_SCORE_GRAPH_DETAIL';
export const ERROR_GET_USER_SCORE_GRAPH_DETAIL = 'ERROR_GET_USER_SCORE_GRAPH_DETAIL';
export const getUserScoreGraphDetailResponse = (type, data) => ({ type, data });

// View How to improve User Score Detail
export const GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL = "GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL";
export const getUserScoreHowToImproveDetail = (data) => ({ type: GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL, data })
export const SUCCESS_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL = 'SUCCESS_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL';
export const ERROR_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL = 'ERROR_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL';
export const getUserScoreHowToImproveDetailResponse = (type, data) => ({ type, data });