/* eslint-disable */ 
import React,{useState,useEffect} from "react";
import "../../../_metronic/_assets/sass/pages/login/classic/sign-in.scss";
import { Link, useHistory } from "react-router-dom";
import { authTimezone, authUserId, redirectLink} from "../../../_metronic/_helpers/functions";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import {actions} from '../../modules/Auth/_redux/authRedux'
import { NoRefresh } from "../../../_metronic/layout/components/extras/NoRefresh";
import Axios from "axios";
import { Apistore } from "../../../redux/apiStore";
import { logActivity, sendApiError } from "../../modules/Auth/_redux/authCrud";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import getStorage from "redux-persist/lib/storage/getStorage";
import { parse } from "dotenv";
import { capitalize } from "lodash";


const Step6 = () => {

const [loading,setLoading] = useState(false);
const [highlighted,setButtonHighlighted] = useState(false);
const [isBackBtnHighlighted,setBackButtonHighlighted] = useState(false);
const [state,setState] = useState(null);
const dispatch = useDispatch();
const history = useHistory();
const { areEventsPulled,isMasteryCalculated,isScoreCalculated,isSignupComplete,isRecommendationCalculated } = useSelector(
  ({ auth }) => ({
    areEventsPulled: auth.areEventsPulled,
    isMasteryCalculated:auth.isMasteryCalculated,
    isScoreCalculated:auth.isScoreCalculated,
    isSignupComplete:auth.isSignupComplete,
    isRecommendationCalculated:auth.isRecommendationCalculated
  }),
  shallowEqual
  );
const [isFormSubmitted,setForSubmit] = useState(false);

const timezone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const ls = timeZone
  // const ls = localStorage.getItem('SELECTED_TIMEZONE');

  if(ls == null){
    return ''
  }else{
    return ls
  }

}

const handleSubmit = (e) => {
  e.preventDefault();
  setLoading(true);
  setForSubmit(true)  
} 


useEffect(() => {
  if(isFormSubmitted){
    setTimeout(() => {
      
      if(areEventsPulled && isMasteryCalculated && isScoreCalculated && isSignupComplete && isRecommendationCalculated ){
        dispatch(actions.register("true"))
        history.push(redirectLink());
      }

    }, 3000);
  }  
  
},[isFormSubmitted,areEventsPulled,isMasteryCalculated,isScoreCalculated,isSignupComplete,isRecommendationCalculated])

useEffect(() =>{

  setLoading(true);

  Axios.post(Apistore.GetMcrData,{
    // user_id:authUserId(),
    timezone:timezone()
  }).then(res => {
      if(res.data.data){
        setState(res.data.data)
      }
     
      setLoading(false)
  }).catch(err => {
    sendApiError(500,err,Apistore.GetMcrData)
  })
},[])

const handleEventPull = (event_id,type,user_id) => {
  Axios.post(Apistore.ActionTrack,{
      user_id,
      event_id,
      action_type:type
      }).then(res => {        })
}


    return(
    <>
            {<LayoutSplashScreen visible={loading}/>}
            <NoRefresh/>

          
             <div className="auth-container row m-0">
          <div className="left_col col-xl-5 col-lg-6">
            <div className="auth_section">
                <div className="auth_logo"><a href="/"><img height='50px' src="/media/logos/meetingscience-logo-mobile1.png" alt="Meeting Science"/></a></div>
                    <div className="auth_content">
                        <div className="auth_steps">
                        <div className="completed">span 1</div>
                        {/* <div className="completed">span 2</div>
                        <div className="completed">span 3</div>
                        <div className="completed">span 4</div>
                        <div className="completed">span 5</div> */}
                        <div className="active">span 6</div>
                    </div>                
                    <div className="auth_form">
                        <div className="auth_content">
                        <div className="auth_title">Let's build some healthy<span> meeting habits</span> together right now!</div>
                        <p></p>
                    </div>

                    {/* Add meeting agenda */}
                    {state!==null && state.recc_add_agenda_events && state.recc_add_agenda_events.length ?
                      
                      <div>
                      <div className='row'><p>{state.recc_add_agenda_events_text}</p></div>
                      <div className='meetings-container  pb-4'>
                        
                              {state.recc_add_agenda_events.map((event,index) => {
                                  return  <div title={capitalize(event.event_title)} className='recc-block row mb-10'>
                                  <div className='col-md-3 recc-timestamp-block'>
                                    <div className='event-day'>{moment(event.event_start_datetime).format('ddd')} </div>
                                    <div className='event-time'>at {moment(event.event_start_datetime).format('hh:mm a')}</div>
                                    <div className='event-date'>{moment(event.event_start_datetime).format('DD') + ' ' + moment(event.event_start_datetime).format('MMM')}</div>
                                  </div>
                                  <div className='col-md-9 recc-title-block  d-flex justify-content-between'>
                                        <div className='recc-title'> {event?.event_title_sht != '' ? capitalize(event.event_title_sht) : 'N/A'}</div>
                                        <div className='recc-action-btn-block '>
                                        <Link onClick={() => {logActivity('add_agenda',window.location.pathname,event.event_id,window.location.pathname);handleEventPull(event.event_id,'sing-up: Add Agenda',event.event_organizerid)}} to={{pathname:event.edit_link}} target='_blank' className="btn btn-next custom-recc-button">Add Agenda</Link>
                                        </div>
                                  </div>
                               </div>   

                              })}         
                        </div>
                      </div>                  
      
                    :
                    null  
                  }

                    {/* shorten 30/60/120/180 minutes meetings */}
                    {
                      state!==null && state.recc_shorten_this_meeting_events  && state.recc_shorten_this_meeting_events.length && state.recc_add_agenda_events.length <=1?

                      state.recc_add_agenda_events.length == 0 ?
                      
                      <div>
                      <div className='row'><p>{state.recc_shorten_this_meeting_text}
                      We noticed that you scheduled the following meeting/s next week for 30/60/120/180 minutes. Click on each meeting to open a new tab and reduce the end time by 5/10/15/20 minutes.
                      </p></div>
                      <div className='meetings-container text-center row d-flex justify-content-center pt-3'>
                        <div className='col-md-12 d-flex justify-content-center w-100 row'>
                              {state.recc_shorten_this_meeting_events.map((event,index) => {
                                  return  <div title={capitalize(event.event_title)} className='recc-block mb-10 row'>
                                  <div className='col-md-2 recc-timestamp-block'>
                                    <div className='event-day'>{moment(event.event_start_datetime).format('ddd')} </div>
                                    <div className='event-time'>at {moment(event.event_start_datetime).format('hh:mm a')}</div>
                                    <div className='event-date'>{moment(event.event_start_datetime).format('DD') + ' ' + moment(event.event_start_datetime).format('MMM')}</div>
                                  </div>
                                  <div className='col-md-10 recc-title-block  d-flex justify-content-between'>
                                        <div className='recc-title'>{capitalize(event.event_title_sht)}</div>
                                        <div className='recc-action-btn-block '>
                                        <Link onClick={() => {logActivity('shorten_meeting',window.location.pathname,event.event_id,window.location.pathname);handleEventPull(event.event_id,'sing-up: Shorten Meeting',event.event_organizerid)}} to={{pathname:event.edit_link}} target='_blank' className="btn btn-next custom-recc-button">Shorten Meeting</Link>
                                        </div>
                                  </div>
                               </div>   

                              })}         
                        </div>
                      </div>         
                    </div>

                       :
                       
                       <div>
                       <p>{state.recc_shorten_this_meeting_text}
                       We noticed that you scheduled the following meeting/s next week for 30/60/120/180 minutes. Click on each meeting to open a new tab and reduce the end time by 5/10/15/20 minutes.
                       </p>
                         <div title={capitalize(state.recc_shorten_this_meeting_events[0].event_title)} className='recc-block mb-10 row'>
                                  <div className='col-md-2 recc-timestamp-block'>
                                    <div className='event-day'>{moment(state.recc_shorten_this_meeting_events[0].event_start_datetime).format('ddd')} </div>
                                    <div className='event-time'>at {moment(state.recc_shorten_this_meeting_events[0].event_start_datetime).format('hh:mm a')}</div>
                                    <div className='event-date'>{moment(state.recc_shorten_this_meeting_events[0].event_start_datetime).format('DD') + ' ' + moment(state.recc_shorten_this_meeting_events[0].event_start_datetime).format('MMM')}</div>
                                  </div>
                                  <div className='col-md-10 recc-title-block  d-flex justify-content-between'>
                                        <div className='recc-title'>{capitalize(state.recc_shorten_this_meeting_events[0].event_title_sht)}</div>
                                        <div className='recc-action-btn-block '>
                                        <Link onClick={() => logActivity('shorten_meeting',window.location.pathname,state.recc_shorten_this_meeting_events[0].event_id,window.location.pathname)} to={{pathname:state.recc_shorten_this_meeting_events[0].edit_link}} target='_blank' className="btn btn-next custom-recc-button">Shorten Meeting</Link>
                                        </div>
                                  </div>
                               </div>   

                     </div>

                    :
                    null
                    }


                    {/* if there are no recommendations */}

                    {state !== null  ? 
                        state.recc_shorten_this_meeting_events.length == 0 && state.recc_add_agenda_events.length == 0 && <p>It looks like all your events have an agenda into them and there are no back to back meetings. Hooray! you are already following good meeting habits. Complete the signup process to see your insights.</p> 
                    :
                    null
                  }   
                      
                   {/* <div className='recc-block row'>
                      <div className='col-md-2 recc-timestamp-block'>
                        <div className='event-day'>Wed </div>
                        <div className='event-time'>at 16:45 pm</div>
                        <div className='event-date'>25 Nov</div>
                      </div>
                      <div className='col-md-10 recc-title-block  d-flex justify-content-between'>
                            <div className='recc-title'>QQ4 QBR Product Meeting</div>
                            <div className='recc-action-btn-block '>
                            <Link onFocus={() => setButtonHighlighted(true)} onBlur={() => setButtonHighlighted(false)} onClick={(e) => handleSubmit(e)} className={ highlighted ? "btn btn-next button-highlighted" : "btn btn-next"}>Add Agenda</Link>
                            </div>
                      </div>
                   </div>    */}

                     <div className="form-row pt-5" >
                      <div className="col-md-12 d-flex justify-content-end mt-6">
                      {/* <Link to="/signup-page5" className={ isBackBtnHighlighted ? "btn btn-back button-back-highlighted" : "btn btn-back"} onFocus={() => setBackButtonHighlighted(true)} onBlur={() => setBackButtonHighlighted(false)}>Back</Link> */}
                        <Link onFocus={() => setButtonHighlighted(true)} onBlur={() => setButtonHighlighted(false)} onClick={(e) => handleSubmit(e)} className={ highlighted ? "btn btn-next button-highlighted" : "btn btn-next"}>Finish</Link>
                      </div>
                    </div>

                </div>
              </div> 
            </div>
          </div>
          <div className="right_col col-xl-7 col-lg-6">
            <div className="step_view">
              <h1>You can stop the insanity and restore humanity in your meetings.</h1>
              <div className="step_view-img">
                <picture>
                  <source media="(min-width:560px)" srcset="/media/ms-media/score-design.png" type="image/png"/>
                  <img alt='svg' src="/media/ms-media/score-design.png" type="image/png"/>
                </picture>
              </div> 
            </div>                         
          </div>
        </div> 
        
    </>
    )
}

export default Step6;