/* eslint-disable */ 

import { call, put, takeLatest } from 'redux-saga/effects';
import { sendApiError } from '../../../../app/modules/Auth/_redux/authCrud';
import { Apistore } from '../../../apiStore';
import { RECEIVE_FEEDBACK_TREND_OVERTIME, RECEIVE_NEW_FEEDBACK_GRAPH_DATA, RECEIVE_ORGANIZED_DATA, RECEIVE_ORGANIZED_MEETINGS_KEY_DIFFERENCES, RECEIVE_ORGANIZED_SUMMARY_ERROR, RECEIVE_SINGLE_MEETING_RATING, RECEIVE_SINGLE_RATING_ATTRIBUTES, RECEIVE_USER_COMPANY_RANK, RECEIVE_USER_FEEDBACK_DATA, RECEIVE_USER_RATING, RECEIVE_USER_RATING_ATTRIBUTES, REQUEST_FEEDBACK_TREND_OVERTIME, REQUEST_NEW_FEEDBACK_GRAPH_DATA, REQUEST_ORGANIZED_DATA, REQUEST_ORGANIZED_MEETINGS_KEY_DIFFERENCES, REQUEST_SINGLE_MEETING_RATING, REQUEST_SINGLE_RATING_ATTRIBUTES, REQUEST_USER_COMPANY_RANK, REQUEST_USER_FEEDBACK_DATA, REQUEST_USER_RATING, REQUEST_USER_RATING_ATTRIBUTES, RESET_SCORE_FOR_SINGLE_EVENT, RESET_STATE } from './organized.action';
import { fetchDifferences, fetchFeedbackTrendApi, fetchNewFeedbackApi, fetchOrganizedData, fetchUserCompanyRanking, fetchUserFeedback, fetchUserRating, fetchUserRatingAttributes } from './organized.crud';

// Get organized page data
function* getOrganizedData(action) { 
    yield put({type:RESET_STATE})
    try{
            const result = yield call (fetchOrganizedData,action.payload);
            yield put({type:RECEIVE_ORGANIZED_DATA,data:result.data})

    } catch (e) {
        yield put({type:RECEIVE_ORGANIZED_SUMMARY_ERROR,data:e})
        sendApiError(500,e,Apistore.GetOrganizedEvents)
    }
}

function* getUserRating(action) { 
    try{
            const result = yield call (fetchUserRating,action.payload);
            yield put({type:RECEIVE_USER_RATING,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetUserRating)
    }
}

function* getSingleMeetingRating(action) { 
    yield put({type:RESET_SCORE_FOR_SINGLE_EVENT})     
    try{
            const result = yield call (fetchUserRating,action.payload);
            yield put({type:RECEIVE_SINGLE_MEETING_RATING,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetUserRating)
    }
}


function* getUserAttributes(action) { 
    try{
            const result = yield call (fetchUserRatingAttributes,action.payload);
            yield put({type:RECEIVE_USER_RATING_ATTRIBUTES,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetUserRatingAttributes)
    }
}

function* getSingleMeetingAttributes(action) { 
    try{
            const result = yield call (fetchUserRatingAttributes,action.payload);
            yield put({type:RECEIVE_SINGLE_RATING_ATTRIBUTES,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetUserRatingAttributes)
    }
}

function* getCompanyRanking(action) { 
    try{
            const result = yield call (fetchUserCompanyRanking,action.payload);
            yield put({type:RECEIVE_USER_COMPANY_RANK,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetUserCompanyRank)
    }
}

// function* getUserFeedback(action) { 
//     try{
//             const result = yield call (fetchUserFeedback,action.payload);
//             yield put({type:RECEIVE_USER_FEEDBACK_DATA,data:result.data})

//     } catch (e) {
//         sendApiError(500,e,Apistore.GetFeedback)
//     }
// }

function* getNewFeedbackData(action) {
    try{
        const result = yield call (fetchNewFeedbackApi,action.payload);
        yield put({type:RECEIVE_NEW_FEEDBACK_GRAPH_DATA,data:result.data})

} catch (e) {
    sendApiError(500,e,Apistore.GetFeedback)
}
}

function* getDifferences(action) {
    try {
        const result = yield call(fetchDifferences, action.payload);
        yield put({ type: RECEIVE_ORGANIZED_MEETINGS_KEY_DIFFERENCES, data: result.data })

    } catch (e) {
        sendApiError(500,e,Apistore.GetOrganizedKeyDifferences)
    }
}

function* getFeedbackTrends(action) {
    try {
        const result = yield call(fetchFeedbackTrendApi, action.payload);
        yield put({ type: RECEIVE_FEEDBACK_TREND_OVERTIME, data: result.data })

    } catch (e) {
        sendApiError(500,e,Apistore.GetFeedbackTrendOverTime)
    }
}


export function* organizedWatcher() {
    yield takeLatest(REQUEST_ORGANIZED_DATA, getOrganizedData);
    yield takeLatest(REQUEST_USER_RATING,getUserRating);
    yield takeLatest(REQUEST_USER_RATING_ATTRIBUTES,getUserAttributes);
    yield takeLatest(REQUEST_USER_COMPANY_RANK,getCompanyRanking);
   // yield takeLatest(REQUEST_USER_FEEDBACK_DATA,getUserFeedback);
    yield takeLatest(REQUEST_SINGLE_MEETING_RATING,getSingleMeetingRating);
    yield takeLatest(REQUEST_SINGLE_RATING_ATTRIBUTES,getSingleMeetingAttributes);
    yield takeLatest(REQUEST_ORGANIZED_MEETINGS_KEY_DIFFERENCES,getDifferences);
    yield takeLatest(REQUEST_NEW_FEEDBACK_GRAPH_DATA,getNewFeedbackData);
    yield takeLatest(REQUEST_FEEDBACK_TREND_OVERTIME, getFeedbackTrends);
}