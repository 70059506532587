/* eslint-disable */ 
import React,{useEffect} from "react";
import { REQUEST_USER_PROGRESS_SUMMARY } from "../../../redux/duck/reports/meetingMastery/mastery.action";
import { useSelector, useDispatch  } from "react-redux";
import { badgesUrl } from "../../_helpers";
import parse from 'html-react-parser';
import { Spinner } from "react-bootstrap";
import { FilterRange } from "../../layout/components/header/FilterLabel";

export function MeetingMasteryProgress() {

    const dispatch = useDispatch();
    const {user_progress} = useSelector(state => state.mastery)
    // const {getLocalStorageUserData} = useSelector(state => state.profile)
    const {timePeriod} = useSelector(state => state.picker);    
 
    const fixHeight = {
        "height":"400px",
        "display":"flex",
        'alignItems':'center',
        // 'align-items':'center',
        "justifyContent":"center"
        // "justify-content":"center"

    }

    useEffect(() => {
            dispatch({type:REQUEST_USER_PROGRESS_SUMMARY,payload:{
                // user_id:authUserId(),
                sdate:timePeriod.sdate,
                edate:timePeriod.edate
            }})
    }, [timePeriod.filter,timePeriod.sdate,timePeriod.edate])

    return (
        <>

            <div className="col-12">
                <div className="card mb-4 card-custom YourAchievementsCard">
                    {/* Header */}
                    <div className="card-header align-items-center border-0">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="font-weight-bolder text-dark">Your Progress for <FilterRange/></span>
                            
                        </h3>
                    </div>

                    {/* Body */}
                    <div className="card-body position-relative overflow-hidden">

                    {!user_progress  && 
                    <div style={fixHeight}>
                        <Spinner animation="border" role="status" >
                            <span className="visually-hidden"></span>
                        </Spinner>
                  </div>
                }     

                    
                      {user_progress && user_progress.data && user_progress.data.calculated && user_progress.data.calculated == false && <div className="card-overlay">{user_progress.data?.message}</div>}
                        <div className="row col--innercard-gutter">
                            
                      { user_progress &&  user_progress.data?.data.map(i => 

                         <div className="col-xl-4 col-sm-6" key={i.badge_category_id ? i.badge_category_id : '' }>
                         <div className="achieve--card">
                             <div className="achieve--icon"><img src={badgesUrl(i.icon_file && i.icon_file)} alt=""/></div>
                             <div className="achieve--card_body">
                                 <div className="achieve--card_title">{i.title ? i.title : ''}</div>
                                 <div className="achieve--card_content">{parse(i.message ? i.message : '')}</div>
                             </div>
                         </div>
                     </div>
                      )    
                      }    
                           

                        </div>

                    </div>
                </div>
            </div>

</>
  );
}
