/* eslint-disable */ 
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Flag } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

export function MeetingWithMostPeopleChart() {

    const {events,status} = useSelector(state => state.attended)

    const [state, setState] = useState({
    series: [
        {
            data:[0,0,0,0,0], // no of people
            name:[0,0,0,0,0] // no of meetings
        }
    ],
    options: {
      chart: {
        toolbar: {
          show: true,
          tools:{
            download:true // <== line to add
          }
        },
      },
      colors: ["#00ACBF"],
      stroke: {
        width: 1,
        curve: "smooth"
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.65,
          opacityTo: 0
        }
      },
      markers: {
        size: 4,
        colors: "#fff",
        fillColor: '#00ACBF',
        strokeColors: '#00ACBF',
        strokeWidth: 1,
        strokeOpacity: 0.9,
        fillOpacity: 10,
        shape: "circle",
        hover: {
          size: undefined,
          sizeOffset: 2,
        }
      },
      title: {
        text: 'People',
        align: 'left',
        style: {
          fontSize: '12px',
          color: '#BFC2C7'
        },
      },
      grid: {
        show: true,
        borderColor: '#F4F5F8',
        strokeDashArray: 6,
        position: 'back',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {  
            show: true
          }
        },
        row: {
          colors: undefined,
          opacity: 0.5
        },
        column: {
          colors: undefined,
          opacity: 0.5
        },
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box p-2">' +
            "<span>" +
            w.config.series[seriesIndex].name[dataPointIndex] + " meeting(s) with " +
             series[seriesIndex][dataPointIndex] + " people" +
            "</span>" +
            "</div>"
          );
        },
        y: {
          show: true,
        },
        x: {
          show: false
        }
      },
      xaxis: {
        title: {
          text: 'Days',
          offsetX: 0,
          offsetY: 0,
          style: {
              color: '#999EA5',
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-title',
          },
        },
        categories: ['Mon','Tue','Wed','Thu','Fri'],
        tooltip: {
          enabled: false,
        },
        crosshairs: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true,
          borderType: 'dotted',
          color: '#F4F5F8',
          height: 25,
        },
        tickPlacement: 'between',
        labels: {
          show: true,
          style: {
            colors: "#A2A7AD",
          },
        },
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          align: 'center',
          style: {
            colors: "#A2A7AD",
          },
          formatter: function (val) {
            return (val).toFixed(0);
          }
        },
        axisTicks: {
          show: true,
          borderType: 'dotted',
          color: '#F4F5F8',
        },
      }
    },
  });

  useEffect(() => {

    let flag = 0;

    setState({
        ...state,
        series:[]
    })

    let series = [
        {
            data:[0,0,0,0,0], // no of people
            name:[0,0,0,0,0] // no of meetings
        }
    ];
    events && events.map(i => {
            const attendees = i.total_attendees;
            const day = moment(i.event_start_datetime).format('dddd');

            switch (day) {
                case 'Monday':
                    series[0].name[0] = ++series[0].name[0];
                    series[0].data[0] =  parseInt(series[0].data[0]) + parseInt(attendees);
                break;
                case 'Tuesday':
                    series[0].name[1] = ++series[0].name[1];
                    series[0].data[1] =  parseInt(series[0].data[1]) + parseInt(attendees);
                break;
                case 'Wednesday':
                    series[0].name[2] = ++series[0].name[2];
                    series[0].data[2] = parseInt(series[0].data[2]) + parseInt(attendees);
                break;
                case 'Thursday':
                    series[0].name[3] = ++series[0].name[3];
                    series[0].data[3] =  parseInt(series[0].data[3]) + parseInt(attendees);
                break;
                case 'Friday':
                    series[0].name[4] = ++series[0].name[4];
                    series[0].data[4] = parseInt(series[0].data[4]) + parseInt(attendees);
                break;
            
            }

            ++flag;
    })
    
     setState({
                ...state,
                series:series
            })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events && events.length]);

  return (
    <>
      {events && status == 'success' ?<ReactApexChart
        options={state.options}
        series={state.series}
        type="area"
        height={300}
      />
        :
        ( status !== 'error' ? 
        <div className='loader-container'>
          <Spinner animation="border" role="status" >
            <span className="visually-hidden"></span>
          </Spinner>
        </div>
    
      :
      <div>
        We're sorry, for the time you requested, we don't have any data to share with you.
      </div>
    )

    }
    </>
  );
}
