/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MeetingSummaryModal } from "./widget8";
import { Html5Entities } from "html-entities";
import parse from "html-react-parser";
import { capitalize } from "@material-ui/core";
import moment from "moment";
import { logActivity } from "../../../app/modules/Auth/_redux/authCrud";
import { map } from "lodash";

export const MostExpensiveMeetings = () => {
  const htmlEntities = new Html5Entities();
  const { organized, status } = useSelector((state) => state.organized);
  const { getLocalStorageUserData } = useSelector((state) => state.profile);
  const [sort, setSorting] = useState(false);
  const [sortedDataset, setSortedData] = useState([]);
  const [meetingModal, setMeetingModal] = useState(false);
  const [meetingSummary, setSummary] = useState();
  let _ =
    getLocalStorageUserData &&
    getLocalStorageUserData.user_data &&
    getLocalStorageUserData.user_data.currency &&
    htmlEntities.decode(
      getLocalStorageUserData.user_data.currency.currency_symbol
    );

  const handleCost = (index) => {
    let a = <td className="value">{_ + _ + _ + _ + _}</td>;
    let b = (
      <td className="value">
        {_ + _ + _ + _}
        <p>{_}</p>
      </td>
    );
    let c = (
      <td className="value">
        {_ + _ + _ + _}
        <span>{_}</span>
      </td>
    );
    let d = (
      <td className="value">
        {_ + _ + _}
        <p>{_}</p>
        <span>{_}</span>
      </td>
    );
    let e = (
      <td className="value">
        {_ + _ + _}
        <span>{_ + _}</span>
      </td>
    );
    let f = (
      <td className="value">
        {_ + _}
        <p>{_}</p>
        <span>{_ + _}</span>
      </td>
    );
    let g = (
      <td className="value">
        {_ + _}
        <span>{_ + _ + _}</span>
      </td>
    );
    let h = (
      <td className="value">
        {_}
        <p>{_}</p>
        <span>{_ + _ + _}</span>
      </td>
    );
    let i = (
      <td className="value">
        {_}
        <span>{_ + _ + _ + _}</span>
      </td>
    );
    let j = (
      <td className="value">
        <p>{_}</p>
        <span>{_ + _ + _ + _}</span>
      </td>
    );

    if ( organized.organized_events.length == 1) {
      switch (index) {
        case 0:
          return a;
      }
    }
    //---------------------
    if (sort == false && organized.organized_events.length == 2) {
      switch (index) {
        case 0:
          return a;
        case 1:
          return b;
      }
    } else if (sort == true && organized.organized_events.length == 2) {
      switch (index) {
        case 0:
          return b;
        case 1:
          return a;
          break
      }
    }
    //---------------------
    if (sort == false && organized.organized_events.length == 3) {
      switch (index) {
        case 0:
          return a;
        case 1:
          return b;
        case 2:
          return c;
      }
    } else if (sort == true && organized.organized_events.length == 3) {
      switch (index) {
        case 0:
          return c;
        case 1:
          return b;
        case 2:
          return a;
          break
      }
    }
    //---------------------
    if (sort == false && organized.organized_events.length == 4) {
      switch (index) {
        case 0:
          return a;
        case 1:
          return b;
        case 2:
          return c;
        case 3:
          return d;
      }
    } else if (sort == true && organized.organized_events.length == 4) {
      switch (index) {
        case 0:
          return d;
        case 1:
          return c;
        case 2:
          return b;
        case 3:
          return a;
          break
      }
    }
    //---------------------
    if (sort == false && organized.organized_events.length == 5) {
      switch (index) {
        case 0:
          return a;
        case 1:
          return b;
        case 2:
          return c;
        case 3:
          return d;
        case 4:
          return e;
      }
    } else if (sort == true && organized.organized_events.length == 5) {
      switch (index) {
        case 0:
          return e;
        case 1:
          return d;
        case 2:
          return c;
        case 3:
          return b;
        case 4:
          return a;
          break
      }
    }

    //---------------------
    if (sort == false && organized.organized_events.length == 6) {
      switch (index) {
        case 0:
          return a;
        case 1:
          return b;
        case 2:
          return c;
        case 3:
          return d;
        case 4:
          return e;
        case 5:
          return f;
      }
    } else if (sort == true && organized.organized_events.length == 6) {
      switch (index) {
        case 0:
          return f;
        case 1:
          return e;
        case 2:
          return d;
        case 3:
          return c;
        case 4:
          return b;
        case 5:
          return a;
          break
      }
    }

    //--------------------------
    if (sort == false && organized.organized_events.length == 7) {
      switch (index) {
        case 0:
          return a;
        case 1:
          return b;
        case 2:
          return c;
        case 3:
          return d;
        case 4:
          return e;
        case 5:
          return f;
        case 6:
          return g;
      }
    } else if (sort == true && organized.organized_events.length == 7) {
      switch (index) {
        case 0:
          return g;
        case 1:
          return f;
        case 2:
          return e;
        case 3:
          return d;
        case 4:
          return c;
        case 5:
          return b;
        case 6:
          return a;
          break
      }
    }
    //--------------------------
    if (sort == false && organized.organized_events.length == 8) {
      switch (index) {
        case 0:
          return a;
        case 1:
          return b;
        case 2:
          return c;
        case 3:
          return d;
        case 4:
          return e;
        case 5:
          return f;
        case 6:
          return g;
        case 7:
          return h;
      }
    } else if (sort == true && organized.organized_events.length == 8) {
      switch (index) {
        case 0:
          return h;
        case 1:
          return g;
        case 2:
          return f;
        case 3:
          return e;
        case 4:
          return d;
        case 5:
          return c;
        case 6:
          return b;
        case 7:
          return a;
          break
      }
    }
    //--------------------------
    if (sort == false && organized.organized_events.length == 9) {
      switch (index) {
        case 0:
          return a;
        case 1:
          return b;
        case 2:
          return c;
        case 3:
          return d;
        case 4:
          return e;
        case 5:
          return f;
        case 6:
          return g;
        case 7:
          return h;
        case 8:
          return i;
      }
    } else if (sort == true && organized.organized_events.length == 9) {
      switch (index) {
        case 0:
          return i;
        case 1:
          return h;
        case 2:
          return g;
        case 3:
          return f;
        case 4:
          return e;
        case 5:
          return d;
        case 6:
          return c;
        case 7:
          return b;
        case 8:
          return a;
          break
      }
    }

    //--------------------------
    if (sort == false && organized.organized_events.length == 10) {
      switch (index) {
        case 0:
          return a;
        case 1:
          return b;
        case 2:
          return c;
        case 3:
          return d;
        case 4:
          return e;
        case 5:
          return f;
        case 6:
          return g;
        case 7:
          return h;
        case 8:
          return i;
        case 9:
          return j;
      }
    } else if (sort == true && organized.organized_events.length == 10) {
      switch (index) {
        case 0:
          return j;
        case 1:
          return i;
        case 2:
          return h;
        case 3:
          return g;
        case 4:
          return f;
        case 5:
          return e;
        case 6:
          return d;
        case 7:
          return c;
        case 8:
          return b;
        case 9:
          return a;
          break
      }
    }

  };
  //----------------------------------------------------------------------------
  // const handleCost = (index) => {
  //   if(sort == false){
  //     switch (index){
  //       case 0 :
  //         return <td  className="value">{_ + _ + _ + _ + _}</td>
  //       case 1 :
  //         return <td  className="value">{_ + _ + _ + _}<p>{_}</p></td>;
  //       case 2 :
  //         return <td  className="value">{_ + _ + _ + _}<span>{_}</span></td>;
  //       case 3 :
  //         return <td  className="value">{_ + _ + _}<p>{_}</p><span>{_}</span></td>;
  //       case 4 :
  //         return <td  className="value">{_ + _ + _}<span>{_ + _}</span></td>;
  //       case 5 :
  //         return <td  className="value">{_ + _}<p>{_}</p><span>{_ + _}</span></td>;
  //       case 6 :
  //         return <td  className="value">{_ + _}<span>{_ + _ + _}</span></td>;
  //       case 7 :
  //         return <td  className="value">{_}<p>{_}</p><span>{_ + _ + _}</span></td>;
  //       case 8 :
  //         return <td  className="value">{_}<span>{_ + _ + _ + _}</span></td>;
  //       case 9 :
  //         return <td  className="value"><p>{_}</p><span>{_ + _ + _ + _}</span></td>;
  //   }
  //   }else if(sort == true){
  //     switch (index){
  //       case 0 :
  //         return <td  className="value"><p>{_}</p><span>{_ + _ + _ + _}</span></td>;
  //       case 1 :
  //         return <td  className="value">{_}<span>{_ + _ + _ + _}</span></td>;
  //       case 2 :
  //         return <td  className="value">{_}<p>{_}</p><span>{_ + _ + _}</span></td>;
  //       case 3 :
  //         return <td  className="value">{_ + _}<span>{_ + _ + _}</span></td>;
  //       case 4 :
  //         return <td  className="value">{_ + _}<p>{_}</p><span>{_ + _}</span></td>;
  //       case 5 :
  //         return <td  className="value">{_ + _ + _}<span>{_ + _}</span></td>;
  //       case 6 :
  //         return <td  className="value">{_ + _ + _}<p>{_}</p><span>{_}</span></td>;
  //       case 7 :
  //         return <td  className="value">{_ + _ + _ + _}<span>{_}</span></td>;
  //       case 8 :
  //         return <td  className="value">{_ + _ + _ + _}<p>{_}</p></td>;
  //       case 9 :
  //         return <td  className="value">{_ + _ + _ + _ + _}</td>
  //   }
  //   }
  // }

  const handleSummaryModal = (id) => {
    setMeetingModal(true);
    logActivity("modal", "/meeting-summary", id, window.location.pathname);
    const eventData =
      organized && organized.organized_events.find((i) => i.event_id === id);
    if (eventData) {
      setSummary(eventData);
    }
  };

  const handleSorting = () => {
    // console.log('organized events',organized.organized_events);
    organized &&
      organized.organized_events &&
      organized.organized_events.reverse();
    setSorting(!sort);
    // console.log('organized events rev',organized.organized_events);
  };
  
  return (
    <>
      <div className="card mb-4 card-custom">
        {/* Header */}
        <div className="card-header align-items-center border-0">
          <h3 className="card-title align-items-center d-flex w-100 justify-content-between">
            <span className="font-weight-bolder text-dark">
              What Are My Most Expensive Meetings?
            </span>
          </h3>
        </div>

        {/* Body */}
        <div className="card-body position-relative overflow-hidden">
          <table className="table expensive--meetings">
            <thead>
              <tr>
                <th scope="col">Meeting Name</th>
                <th scope="col">
                  <div
                    className="table-filter"
                    onClick={(e) =>
                      organized &&
                      organized.organized_events &&
                      organized.organized_events.length > 1
                        ? handleSorting()
                        : e.preventDefault()
                    }
                    style={{ cursor: "pointer" }}
                  >
                    Cost
                    <span className="table-filter--icn custm_popover">
                      {sort === false ? (
                        <>
                          {" "}
                          <i className="fas fa-caret-up active"></i>
                          <i className="fas fa-caret-down"></i>{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <i className="fas fa-caret-up "></i>
                          <i className="fas fa-caret-down active"></i>{" "}
                        </>
                      )}
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {organized !== null ? (
                organized.status == "success" ? (
                  organized &&
                  getLocalStorageUserData &&
                  getLocalStorageUserData.user_data &&
                  getLocalStorageUserData.user_data.currency &&
                  organized.organized_events.map((key, index) => (
                    <tr
                      title={
                        _ +
                        "" +
                        key.total_event_cost +
                        " for " +
                        key.event_title
                      }
                      className="custm_popover"
                      key={index}
                      onClick={() => handleSummaryModal(key.event_id)}
                    >
                      <td>
                        {parse(capitalize(key.event_title))}{" "}
                        <b style={{paddingLeft:'3px' }}>
                          {moment(key.event_start_datetime).format("MM/DD")}
                        </b>{" "}
                      </td>
                      {getLocalStorageUserData &&
                        getLocalStorageUserData.user_data &&
                        handleCost(index)}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      We're sorry, for the time you requested, we don't have any
                      data to share with you.
                    </td>
                  </tr>
                )
              ) : status !== "error" ? (
                <tr>
                  <td className="loader-container">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden"></span>
                    </Spinner>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>
                    We're sorry, for the time you requested, we don't have any
                    data to share with you.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {meetingModal && (
        <MeetingSummaryModal
          data={meetingSummary}
          show={meetingModal}
          handleClose={() => setMeetingModal(false)}
        />
      )}
    </>
  );
};
