/* eslint-disable */ 
import Axios from "axios";
import { capitalize } from "lodash";
import React,{useState,useEffect} from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import { Apistore } from "../../redux/apiStore";
import { LayoutSplashScreen } from "../../_metronic/layout";
import { setStorage } from "../../_metronic/_helpers";
import { AlertPopup } from "../../_metronic/_helpers/Alerts";
import { actions } from "../modules/Auth";
import { fetchBearerToken } from "../modules/Auth/_redux/authCrud";


const UnsubscribePage = () => {

    const dispatch = useDispatch();

    const [loading,setLoading] =useState(false);
    const [state,setState] = useState(null);
    const [succMessage,setSuccMsg] = useState(null);
    const [alert,setAlert] = useState({
        show:null,
        title:null,
        message:null,
        errType:null
      })
    
      const handleClose = () =>{
        setAlert({
          show:false
        })
      };
    const handleResponse = (e,organizer_id) => {
        setLoading(true);
        const {value,name} = e.target;

        let user_id = value; 
        let action = name ;

        Axios.post(Apistore.SaveUnsubscribeUserData,null,{
            params:{
                // user_id:user_id,
                organizer_id:organizer_id,
                action:action
            }
        }).then(res => {
            if(res.data.code == 200){
                setLoading(false);
                  setSuccMsg(parse(res.data.message));
            }else{
                setLoading(false)
                  setSuccMsg(parse(res.data.message));
               
            }
        }).catch(err => {
            setLoading(false);
        })
    }

useEffect(() => {
    setLoading(true);
    const location = window.location.href;
    const trimmedArray = location.split(process.env.REACT_APP_PUBLIC_URL + '/unsubscribe/');
    const slug = trimmedArray[1];

    fetchBearerToken(process.env.REACT_APP_LOGIN_EMAIL,process.env.REACT_APP_LOGIN_PASSWORD)
    .then((res) => {
      if(res.status){
          const userVerifiedToken = res.data.access_token
          setStorage('ACCESS_TOKEN',userVerifiedToken);
          dispatch(actions.login(userVerifiedToken,"false","false")); // access_token


          if(slug !== undefined && slug!== null){
            
            Axios.post(Apistore.GetUnsubscribeLink,null,{
                params:{
                    slug:slug
                }
            }).then(res => {
                        if(res.data.code == 200 && res.data.status == true ){
                            setState(res.data.data)
                            setLoading(false)
                        }else{
                            setLoading(false)
                            setState(res.data.data)
                            setSuccMsg(parse(res.data.message))
                        }
            }).catch(err => {
                setLoading(false)
            })
        }




      }
    })
  

   

},[])
    return(
    <>
            {<LayoutSplashScreen visible={loading}/>}
            {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType}/>}

             <div className="auth-container row m-0">
          <div className="left_col col-xl-5 col-lg-6">
            <div className="auth_section">
                <div className="auth_logo"><a href="/"><img src="/media/logos/meeting-science-logo-2.svg" alt="Meeting Science"/></a></div>
                    <div className="auth_content">               
                    <div className="auth_form">
                        <div className="auth_content">
                        <div className="auth_title">{state !== null ? capitalize(state.action) : null}</div>
                       {state !== null ?  <p>Hey <strong>{state.user_firstname!==undefined && state.user_firstname !== null && state.user_firstname!=="" ? state.user_firstname : state.user_email}</strong>,</p> : null}
                       
                    </div>


                    {state !==null && succMessage == null ? 
                    <> <div className="form-row pt-5" >
                        <div className="col-md-12">
                         
                            <p>You've asked to {state.action} from feedback requests for all meetings organized by <strong>{state.organizer_firstname + " " + state.organizer_lastname}.</strong></p>
                            <p>Are you sure that you really want to do this? <strong>{state.organizer_firstname + " " + state.organizer_lastname}</strong> won't ever receive your valuable feedback {state.action == "snooze" ? "until next 30 days." : "again!"}</p>
                            {state.action !== "snooze" ?<p>We offer another option - you can choose to snooze requests for feedback for 30 days.</p> : null}
                            
                            {state.action == 'unsubscribe' ? 
                            
                            <div className='row' style={{marginTop:"60px"}}>
                                <div className='col-md-5 '>
                                    <button value={state.user_id}   name='snooze' onClick={(e) => handleResponse(e,state.organizer_id)} className="btn btn-next btn3 w-100">Snooze for 30 Days</button>
                                </div>
                                <div className='col-md-5 '>     
                                    <button value={state.user_id}   name='unsubscribe' onClick={(e) => handleResponse(e,state.organizer_id)} className="btn btn-next w-100">Unsubscribe</button>        
                                </div>
                            </div>
                            
                            :

                            <div className='row' style={{marginTop:"60px"}}>
                            <div className='col-md-5 '>     
                                <button value={state.user_id}   name='snooze' onClick={(e) => handleResponse(e,state.organizer_id)} className="btn btn-next w-100">Snooze for 30 Days</button>        
                            </div>
                        </div>
                        
                        }

                        </div>
                    </div>

                    <div className='row' style={{marginTop:"130px"}}>
                        <div className='col-md-5'>     
                            <Link to='/' className="btn btn-next btn4 w-100">I've changed my mind</Link>        
                        </div>
                    </div>   
                    </>
                    :
                    null
                    }

                    {
                        succMessage !== null ? 
                        <> <div className="form-row pt-5" >
                        <div className="col-md-12">
                            <p>{succMessage}</p>
                         </div>
                    </div>   
                    </>

                        : null
                    }    

                </div>

              </div> 
            </div>
          </div>
          <div className="right_col col-xl-7 col-lg-6" >
            <div className="step_view">
              <h1>We are really sad to see you go!</h1>
              <div className="step_view-img">
                <picture>
                  <source media="(min-width:560px)" srcset="/media/ms-media/SADNESS.png" type="image/png"/>
                  <img className='sadness-img' src="/media/ms-media/SADNESS.png" type="image/png" alt="It contains the image of a sad girl requesting you to not leave meetingscience"/>
                </picture>
              </div> 
            </div>                         
          </div>
        </div> 
        
    </>
    )
}

export default UnsubscribePage;