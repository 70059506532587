/* eslint-disable */ 
import React,{useEffect,useState} from "react";
import { Spinner } from "react-bootstrap";
import { useSelector,useDispatch } from "react-redux";
import { requeseHighestLevelAcheived, requestMasteryLevels } from "../../../redux/duck/reports/meetingMastery/mastery.action";


export function MeetingMasteryLevel() {


    const {mastery_levels,level_acheived} = useSelector(state => state.mastery)
    const dispatch = useDispatch();
    const [maxBadAcheived,setMaxBadge] = useState();


    const calculatePercentage = (levelId,badgeAchieved) => {

        for (let key in mastery_levels.data){
            // eslint-disable-next-line eqeqeq
             if (mastery_levels.data[key].meeting_mastery_level_id == levelId){
                    let maxAchievableBadges = mastery_levels.data[key].max_no_of_badge;
                    // console.log(maxAchievableBadges);
                    // console.log(badgeAchieved);
                    let badgeEarnedInCurrentLevel = badgeAchieved - mastery_levels.data[key].badge_start_from + 1;
                    let totalNoOfbadgesInCurrentlevel = mastery_levels.data[key].badge_count

                    let widthToBeCovered = ((badgeEarnedInCurrentLevel?badgeEarnedInCurrentLevel:1)/totalNoOfbadgesInCurrentlevel)*100;

                        return {"width":widthToBeCovered + "%"};


            }
        }
    }
    const calculateIndicator = (levelId,badgeAchieved) => {

        for (let key in mastery_levels.data){
            // eslint-disable-next-line eqeqeq
            if (mastery_levels.data[key].meeting_mastery_level_id == levelId){
                    let maxAchievableBadges = mastery_levels.data[key].max_no_of_badge;
                    // console.log(badgeAchieved);
                    let badgeEarnedInCurrentLevel = badgeAchieved - mastery_levels.data[key].badge_start_from + 1;
                    let totalNoOfbadgesInCurrentlevel = mastery_levels.data[key].badge_count

                    let widthToBeCovered = ((badgeEarnedInCurrentLevel?badgeEarnedInCurrentLevel:1)/totalNoOfbadgesInCurrentlevel)*100;
                    // eslint-disable-next-line eqeqeq
                    if(widthToBeCovered == 100 && badgeAchieved == 36){
                        return {"left":"200%"}
                    }else{
                        return {"left":widthToBeCovered + "%"};
                    }

            }
        }
    }



    const calculatePendingBadges = (levelId,badgeAchieved) => {

        for (let key in mastery_levels.data){
            // eslint-disable-next-line eqeqeq
            if (mastery_levels.data[key].meeting_mastery_level_id == levelId){
                    let maxAchievableBadges = mastery_levels.data[key].max_no_of_badge;
                    let pendingBadgeCount = maxAchievableBadges - badgeAchieved;
                        if(pendingBadgeCount == 0 && badgeAchieved !== 36){
                            return 1;
                            // eslint-disable-next-line eqeqeq
                        }else if(pendingBadgeCount == 0 && badgeAchieved == 36){
                            return pendingBadgeCount;
                        }else if(pendingBadgeCount !== 0 && badgeAchieved !== 36){
                            if(levelId == 7){
                                return pendingBadgeCount;
                            }else{
                                return pendingBadgeCount + 1;
                            }

                        }

            }
        }
    }

    useEffect(() => {
        dispatch(requestMasteryLevels());
    }, [])

        useEffect(() => {
            if( mastery_levels && mastery_levels.data){
                dispatch(requeseHighestLevelAcheived());
            }   
        },[mastery_levels])

        useEffect(() => {
            level_acheived && level_acheived.data &&
            setMaxBadge(level_acheived.data.total_badge_count)
        },[level_acheived])
    return (
        <>
            <div className="col-12">
                <div className="card mb-4 card-custom">
                    {/* Header */}
                    <div className="card-header align-items-center border-0">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="font-weight-bolder text-dark">Meeting Mastery Level - <u>{level_acheived && level_acheived.data == null && ' Level 0'}</u> <u>{level_acheived && level_acheived.data!==null &&  ' Level ' + level_acheived.data.meeting_mastery_level_id}</u> </span>
                           { maxBadAcheived !== 36 ? <p className="card-title-subheading font-weight-normal">Your level depends on the number of badges received. <strong>To reach the next level, you need to get {level_acheived && level_acheived.data!==null && mastery_levels && mastery_levels.data ? calculatePendingBadges(level_acheived.data.meeting_mastery_level_id,level_acheived.data.total_badge_count) + " more " : ' 1 '}  badge(s).</strong> Let’s do it, we believe in you!</p>
                           : <p className="card-title-subheading font-weight-normal">Congratulations! You have successfully achieved all the badges. Now you have mastered the meetings and you have earned a <strong>Super badge.</strong> We are proud of you!</p> }                          
                        </h3>
                    </div>

                                <div className='py-4 d-flex justify-content-center'>{
                                    !mastery_levels  && !level_acheived && 

                                       <Spinner animation="border" role="status">
                                            <span className="visually-hidden"></span>
                                        </Spinner>
                                }
                                </div>

                    {/* Body */}
                    <div className="card-body position-relative overflow-hidden">
                        <div className="badge-level-container d-flex">
                            <div className="badge-level-wrap d-flex align-items-center">

                                {   
                                    mastery_levels && mastery_levels.data  && level_acheived && 
                                    mastery_levels.data.map((key,index) =>          
                                        <div className={"badge-level " + (level_acheived.data == null ? index == 0 ? 'active' : ' '  : level_acheived && level_acheived.data!==null && level_acheived.data.meeting_mastery_level_id == index + 1 && ' active' )  + (level_acheived && level_acheived.data!==null && level_acheived.data.meeting_mastery_level_id > index +1 ? " completed" : " ") } key={key.meeting_mastery_level_id}>
                                            <p className="mb-0 text-left">{key.title}</p>
                                            <div className="badge-level-slider">
                                            <span className="level-progress" style={level_acheived.data == null ? {"width":"0%"} : level_acheived.data.meeting_mastery_level_id > index +1 ? {"width":"100%"} :   level_acheived.data.meeting_mastery_level_id == index + 1 ? calculatePercentage(level_acheived.data.meeting_mastery_level_id,level_acheived.data.total_badge_count)  : {"width":"0%"} }></span>
                                        </div>

                                {   // eslint-disable-next-line eqeqeq
                                    level_acheived.data == null ?  index == 0 && 
                                        <div className="level-indicater d-inline" style={{"left":"5px"}}>
                                           <i className="fas fa-caret-up"></i> 
                                           <span>You’re here</span>
                                        </div> : ""
                                }

                                {
                                    level_acheived && level_acheived.data !== null  && level_acheived.data.meeting_mastery_level_id == index + 1 &&
                                       <div className="level-indicater d-inline" style={ calculateIndicator(level_acheived.data.meeting_mastery_level_id,level_acheived.data.total_badge_count)}>
                                           <i className="fas fa-caret-up"></i> 
                                           <span>You’re here</span>
                                        </div> 
                                }
                                   <h5 className="m-0 text-right">{key.max_no_of_badge} badges</h5>
                                </div>
                                )}

                            {
                                mastery_levels && level_acheived &&
                                <div className={"badge-level " + (level_acheived.data !== null && level_acheived.data.total_badge_count == "36" ? ' completed' : '')}>
                                    <p className="mb-0 text-left">Super Badge!</p>
                                    <img className={"badge-icn" + (level_acheived.data !== null && level_acheived.data.total_badge_count == "36" ? ' ' : ' disabled') } src="/media/ms-media/super-badge-icn.svg" alt=""/>
                                    <div className="badge-level-slider">
                                        <span className="level-progress"></span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
</>
  );
}