import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from '../../_helpers/Custom';
import { MeetingStrainPerDayLineal } from './widget29';
import { MeetingStrainPerDayChart } from './widget30';
import parse from 'html-react-parser';
import _ from 'lodash';

export const CoachingObservationAndChartSection = (props) => {
    const chartSection = useRef(null);
    const [toggleCharts, setToggleCharts] = useState(false);
    const getMcrData = useSelector(state => state.coaching.getCoachingMcrData);
    const prevGetMcrData = usePrevious({ getMcrData });
    const getMcrGraphData = useSelector(state => state.coaching.getCoachingMcrGraphData);
    const prevGetMcrGraphData = usePrevious({ getMcrGraphData });
    const [observationData, setObservationData] = useState({ observationText: '', totalMeetings: '', totalOrganizedMeetings: '', meetingTimePercentage: '', totalParticipants: '', meetingTimePercentageDiff: '', peopleMeetingPercentageDiff: '', totalMeetingPercentageDiff: '', totalMeetingOrgPercentageDiff: '' })
    const [graphData, setGraphData] = useState("");

    useEffect(() => {
        if (prevGetMcrData && prevGetMcrData.getMcrData !== getMcrData) {
            if (getMcrData && _.has(getMcrData, "data") && getMcrData.code === 200) {
                if (_.has(getMcrData, "summery_data")) {
                    const summeryData = getMcrData.summery_data;
                    const diffKeysData = getMcrData.diff_keys;
                    setObservationData({
                        ...observationData, observationText: getMcrData.data.observation_text, totalMeetings: summeryData.total_meeting, totalOrganizedMeetings: summeryData.total_meeting_org, meetingTimePercentage: summeryData.meeting_time_percentage, totalParticipants: summeryData.total_participants,
                        meetingTimePercentageDiff: diffKeysData.diff_meeting_time_percentage, peopleMeetingPercentageDiff: diffKeysData.diff_people_meet_percentage, totalMeetingPercentageDiff: diffKeysData.diff_total_meeting_percentage, totalMeetingOrgPercentageDiff: diffKeysData.diff_total_meeting_org_percentage
                    });
                }
                props.loader(false);
            }
            if (getMcrData && _.has(getMcrData, "message") && getMcrData.status === "error") {
                props.loader(false);
                props.alert({
                    show: true,
                    message: "Something went wrong!",
                    title: "Error",
                    errType: "failure",
                });
            }
        }
        if (prevGetMcrGraphData && prevGetMcrGraphData.getMcrGraphData !== getMcrGraphData) {
            if (getMcrGraphData && _.has(getMcrGraphData, "graphText") && getMcrGraphData.code === 200) {
                setGraphData(getMcrGraphData.graphText);
                // props.loader(false);
            }
            if (getMcrGraphData && _.has(getMcrGraphData, "message") && getMcrGraphData.status === "error") {
                props.loader(false);
                props.alert({
                    show: true,
                    message: "Something went wrong!",
                    title: "Error",
                    errType: "failure",
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevGetMcrData, getMcrData, prevGetMcrGraphData, getMcrGraphData]);
    return (
        <>
            <div className="col-md-6 coaching-page-col-2">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4 card-custom h-auto">
                            <div className="card-header align-items-center border-0">
                                <h3 className="card-title align-items-center d-flex w-100 justify-content-between">
                                    <span className="font-weight-bolder text-dark">Observation</span>
                                    {/* <span className="text-muted font-weight-bold font-size-sm">The % change values relate to the {<FilterRange />}</span> */}
                                </h3>
                            </div>

                            <div className="card-body position-relative overflow-hidden">
                                <p>{observationData.observationText !== "" ? parse(observationData.observationText) : ""}</p>
                                <div className="row">
                                    <div className="col-md-6 col-6">
                                        <div className="api--card">
                                            <div className="api--card_title">Meetings</div>
                                            <div className="api--detail">
                                                <div className="detail-number">{observationData.totalMeetings}</div>
                                                {observationData.totalMeetings !== 0 && observationData.totalMeetingPercentageDiff !== 'N/A' ? <div className={observationData.totalMeetingPercentageDiff >= 0 ? "detail-percentage green" : "detail-percentage red"}>{observationData.totalMeetingPercentageDiff} % {observationData.totalMeetingPercentageDiff >= 0 ? <img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg" /> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg" />}</div> : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-6">
                                        <div className="api--card">
                                            <div className="api--card_title">Total Time, % of Working Hours</div>
                                            <div className="api--detail">
                                                <div className="detail-number">{observationData.meetingTimePercentage}</div>
                                                {observationData.meetingTimePercentage !== 0 && observationData.meetingTimePercentageDiff !== 'N/A' ? <div className={observationData.meetingTimePercentageDiff >= 0 ? "detail-percentage green" : "detail-percentage red"}>{observationData.meetingTimePercentageDiff} % {observationData.meetingTimePercentageDiff >= 0 ? <img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg" /> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg" />}</div> : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-6">
                                        <div className="api--card">
                                            <div className="api--card_title">Meetings Organized</div>
                                            <div className="api--detail">
                                                <div className="detail-number">{observationData.totalOrganizedMeetings}</div>
                                                {observationData.totalOrganizedMeetings !== 0 && observationData.totalMeetingOrgPercentageDiff !== 'N/A' ? <div className={observationData.totalMeetingOrgPercentageDiff >= 0 ? "detail-percentage green" : "detail-percentage red"}>{observationData.totalMeetingOrgPercentageDiff} % {observationData.totalMeetingOrgPercentageDiff >= 0 ? <img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg" /> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg" />}</div> : ''}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-6">
                                        <div className="api--card">
                                            <div className="api--card_title">Unique Meeting Participants</div>
                                            <div className="api--detail">
                                                <div className="detail-number">{observationData.totalParticipants}</div>
                                                {observationData.totalParticipants !== 0 && observationData.peopleMeetingPercentageDiff !== 'N/A' ? <div className={observationData.peopleMeetingPercentageDiff >= 0 ? "detail-percentage green" : "detail-percentage red"}>{observationData.peopleMeetingPercentageDiff} % {observationData.peopleMeetingPercentageDiff >= 0 ? <img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg" /> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg" />}</div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card card-custom h-100" ref={chartSection}>
                            <div className="card-header align-items-center border-0 justify-content-between">
                                <h3 className="card-title align-items-center">
                                    <span className="font-weight-bolder text-dark">Your Meeting Strain Per Day</span>
                                </h3>
                                <div className="d-inline-flex justify-content-between">
                                    <div className="card-subtitle-btn">
                                        <a href="#lineal" className={toggleCharts === true ? "btn btn-sm btn-light-primary" : "btn btn-sm btn-light"} onClick={() => { setToggleCharts(true) }}>Lineal</a>
                                        <a href="#chart" className={toggleCharts === false ? "btn btn-sm btn-light-primary" : "btn btn-sm btn-light"} onClick={() => { setToggleCharts(false) }}>Chart</a>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body position-relative overflow-hidden">
                                <p>{parse(graphData)}</p>
                                {toggleCharts === true ?
                                    <div id="lineal">
                                        <MeetingStrainPerDayLineal />
                                    </div>
                                    :
                                    <div id="chart">
                                        <MeetingStrainPerDayChart />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
