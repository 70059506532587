import { ERROR_GET_USER_SCORE_COMPARISON_DETAIL, ERROR_GET_USER_SCORE_DETAIL, ERROR_GET_USER_SCORE_GRAPH_DETAIL, ERROR_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL, SUCCESS_GET_USER_SCORE_COMPARISON_DETAIL, SUCCESS_GET_USER_SCORE_DETAIL, SUCCESS_GET_USER_SCORE_GRAPH_DETAIL, SUCCESS_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL } from "./score.action";
import { DEFAULT_STATE } from "./score.state";

export const scoreReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case SUCCESS_GET_USER_SCORE_DETAIL:
            const getUserScoreData = action.data;
            return { ...state, getUserScoreData };
        case ERROR_GET_USER_SCORE_DETAIL:
            const errorGetUserScoreData = action.data;
            return { ...state, getUserScoreData: errorGetUserScoreData };
        case SUCCESS_GET_USER_SCORE_COMPARISON_DETAIL:
            const getUserScoreComparisonData = action.data;
            return { ...state, getUserScoreComparisonData };
        case ERROR_GET_USER_SCORE_COMPARISON_DETAIL:
            const errorGetUserScoreComparisonData = action.data;
            return { ...state, getUserScoreComparisonData: errorGetUserScoreComparisonData };
        case SUCCESS_GET_USER_SCORE_GRAPH_DETAIL:
            const getUserScoreGraphData = action.data;
            return { ...state, getUserScoreGraphData };
        case ERROR_GET_USER_SCORE_GRAPH_DETAIL:
            const errorGetUserScoreGraphData = action.data;
            return { ...state, getUserScoreGraphData: errorGetUserScoreGraphData };
        case SUCCESS_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL:
            const getUserScoreHowToImproveData = action.data;
            return { ...state, getUserScoreHowToImproveData };
        case ERROR_GET_USER_SCORE_HOW_TO_IMPROVE_DETAIL:
            const errorGetUserScoreHowToImproveData = action.data;
            return { ...state, getUserScoreHowToImproveData: errorGetUserScoreHowToImproveData };
        default:
            return state;
    }
}