import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { capitalize, makeStyles, Slider } from '@material-ui/core';
import { usePrevious } from '../../../_helpers/Custom';
import { AlertPopup } from '../../../_helpers/Alerts';
import { Spinner } from 'react-bootstrap';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300
    },
    margin: {
        height: theme.spacing(10)
    },
    thumb: {
        background: "#00ACBF",
        "&~&": {
            background: "#00ACBF"
        }
    },
    mark: {
        background: "#00ACBF",
        "&>*": {
            background: "#00ACBF"
        }
    },
    rail: {
        background: "linear-gradient(to right top, #c4f0f5, #b5eaf0, #a6e4ea, #96dde5, #86d7e0, #76c8d2, #65bac4, #54acb6, #41929c, #2e7a84, #1a626b, #024b54);",
        height: "3px",
    },
    track: {
        background: "linear-gradient(to right top, #c4f0f5, #b5eaf0, #a6e4ea, #96dde5, #86d7e0, #76c8d2, #65bac4, #54acb6, #41929c, #2e7a84, #1a626b, #024b54);"
    },
    valueLabel: {
        "&>*": {
            background: "transparent",
            color: "black",
            position: "absolute",
            top: 12
        }
    }
}));

export const ExecutiveLevelSlider = () => {
    const fixedMargin = {
        marginTop: "20px",
        marginLeft: "calc(50% - 20px)",
        marginRight: "calc(50% - 20px)"
    };
    const classes = useStyles();
    // redux state
    const scoreData = useSelector((state) => state.score.getUserScoreData);
    const prevScoreData = usePrevious({ scoreData });
    const scoreCompareData = useSelector((state) => state.score.getUserScoreComparisonData);
    const prevScoreCompareData = usePrevious({ scoreCompareData });
    // Set Initial State
    const [isShown, setIsShown] = useState(false);
    const [alert, setAlert] = useState({ show: false, title: null, message: null, errType: null });
    const [data, setData] = useState({
        user_score: '',
        userScoreAverageValue: '',
        userJobLevel: ''

    });
    const hoverClass = isShown === true ? "compare-icon-blk active" : "compare-icon-blk";
    const leftClass = isShown === true ? "compare-icon-blk left active" : "compare-icon-blk left";
    const howYouCompareMarks = [
        {
            value: 0,
            label: 0
        },
        {
            value: data.userScoreAverageValue,
            label: <span className={data.userScoreAverageValue > 60 ? leftClass : hoverClass} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}><i className="fas fa-caret-up"></i><span className="compare-icon-blk-value">Average score in people with {scoreCompareData && scoreCompareData.job_level !== '' && scoreCompareData.job_level !== null ? scoreCompareData.job_level : 'Executive'} level: <strong>{data.userScoreAverageValue}</strong></span></span >
        },
        {
            value: 100,
            label: 100
        }
    ];
    // fetch data
    useEffect(() => {
        if (prevScoreData && prevScoreData.scoreData !== scoreData) {
            if (scoreData && _.has(scoreData, "user_score") && scoreData.code === "200") {
                setData({ ...data, user_score: scoreData.user_score !== "N/A" ? scoreData.user_score : 0 })
            }
            if (scoreData && _.has(scoreData, "message") && scoreData.status === "error") {
                if (scoreData.open_popup === false) {
                    setAlert({
                        show: true,
                        message: "Something went wrong!",
                        title: "Error",
                        errType: "failure",
                    });
                }
            }
        }
        if (prevScoreCompareData && prevScoreCompareData.scoreCompareData !== scoreCompareData) {
            if (scoreCompareData && _.has(scoreCompareData, "job_level_average_score") && scoreCompareData.code === "200") {
                setData({ ...data, userScoreAverageValue: scoreCompareData.job_level_average_score, userJobLevel: scoreCompareData.job_level })
            }
            if (scoreCompareData && _.has(scoreCompareData, "message") && scoreCompareData.status === "error") {
                if (scoreCompareData.open_popup === false) {
                    setAlert({
                        show: true,
                        message: "Something went wrong!",
                        title: "Error",
                        errType: "failure",
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevScoreData, scoreData, prevScoreCompareData, scoreCompareData]);

    // handle close button of alert
    const handleClose = () => {
        setAlert({
            show: false,
        });
    };
    return (
        <>
            {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />}
            {scoreCompareData.job_level_average_score !== undefined && data.user_score !== "" ?
                <>
                    <p className="mt-4">Level: <strong>{data.userJobLevel ? capitalize(data.userJobLevel) : 'Executive'}</strong></p>
                    <div className="howCompare-slider" key="executiveLevel">
                    <Slider
                        name="ExecutiveLevel"
                        key=""
                        classes={{
                            thumb: classes.thumb,
                            rail: classes.rail,
                            track: classes.track,
                            valueLabel: classes.valueLabel,
                            mark: classes.mark
                        }}
                        value={data.user_score}
                        step={1.0}
                        max={100}
                        min={0}
                        // valueLabelDisplay="auto"
                        marks={howYouCompareMarks}
                    />
                    </div>
                    <div>
                        <div className="color-label-wrap mt-5"><div className="color-label"><span className="color_box bg-primary"></span> Your value</div><div className="color-label"><span className="color_box dark"></span> Average value</div></div>
                    </div>
                </>
                :
                <Spinner animation="border" role="status" style={fixedMargin}>
                    <span className="visually-hidden"></span>
                </Spinner>
            }
        </>
    );
};
