import { ERROR_GET_UNRATED_MEETINGS_COUNT, ERROR_GET_UNRATED_MEETINGS_DETAIL, ERROR_SEND_UNRATED_MEETINGS_DETAIL, SUCCESS_GET_UNRATED_MEETINGS_COUNT, SUCCESS_GET_UNRATED_MEETINGS_DETAIL, SUCCESS_SEND_UNRATED_MEETINGS_DETAIL } from "./unrated.action";
import { DEFAULT_STATE } from "./unrated.state";

export const unratedMeetingsReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case SUCCESS_GET_UNRATED_MEETINGS_DETAIL:
            const getUnratedMeetingsData = action.data;
            return { ...state, getUnratedMeetingsData }
        case ERROR_GET_UNRATED_MEETINGS_DETAIL:
            const errorGetUnratedMeetingsData = action.data;
            return { ...state, getUnratedMeetingsData: errorGetUnratedMeetingsData }
        case SUCCESS_SEND_UNRATED_MEETINGS_DETAIL:
            const sendUnratedMeetingsData = action.data;
            return { ...state, sendUnratedMeetingsData }
        case ERROR_SEND_UNRATED_MEETINGS_DETAIL:
            const errorSendUnratedMeetingsData = action.data;
            return { ...state, sendUnratedMeetingsData: errorSendUnratedMeetingsData }
        case SUCCESS_GET_UNRATED_MEETINGS_COUNT:
            const count = action.data;
            return { ...state, count };
        case ERROR_GET_UNRATED_MEETINGS_COUNT:
            const errorCount = action.data;
            return { ...state, count: errorCount };
        default:
            return state;
    }
}