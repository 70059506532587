/* eslint-disable */ 
import React, { useEffect } from "react";
import { GenderOverlapReport } from "./widget17";
import { useSelector,useDispatch } from "react-redux";
import { UserCurrency } from "../../layout/components/currencychecker";
import { Spinner } from "react-bootstrap";
import { FilterRange } from "../../layout/components/header/FilterLabel";
import { REQUEST_COMPANY_KEY_DIFFERENCES } from "../../../redux/duck/dashboard/company/company.action";

export const CompanyKeyKpi = () => {
    const fixedMargin = {
        marginTop: "calc(50% - 234px)",
        marginLeft: "calc(50% - 20px)",
        marginRight: "calc(50% - 20px)"
    };
    const dispatch = useDispatch();

    const { companyData, company_differences } = useSelector((state) => state.company);
    const {getLocalStorageUserData} = useSelector((state) => state.profile )
    const {timePeriod} = useSelector((state) => state.picker )


        useEffect(() => {
            if(companyData && companyData.summary_data && companyData.summary_data.summaryStatus && getLocalStorageUserData && getLocalStorageUserData.user_data) {
                 dispatch({
                type:REQUEST_COMPANY_KEY_DIFFERENCES,
                payload:{
                    // user_id:authUserId(),
                    sdate:timePeriod.sdate,
                    edate:timePeriod.edate,
                    week_type:timePeriod.filter,
                    user_email:getLocalStorageUserData.user_data.email,
                    company_id:getLocalStorageUserData.user_data.company_id,
                    company_domain:getLocalStorageUserData.user_data.company_domain,
                    currency_conversion_rate:getLocalStorageUserData.user_data.currency.currency_conversion_rate,
                    timezone:getLocalStorageUserData.user_data.timezone,
                    summary_data:companyData.summary_data
                }
            })
        }
        },[companyData])

    return (
        <>
        <div className="col-xl-8 col-md-7">
            <div className="card mb-4 card-custom">
              
                        {/* Header */}
                        < div className="card-header align-items-center border-0">
                            <h3 className="card-title align-items-center d-flex w-100 justify-content-between">
                                <span className="font-weight-bolder text-dark">Key KPIs</span>
                                <span className="text-muted font-weight-bold font-size-sm">The % change values relate to the <FilterRange/></span>
                            </h3>
                        </div>

                        {/* Body */}
                        <div className="card-body position-relative overflow-hidden">
                            { companyData !== null && companyData.status == "success" ? (
                            <div className="row">
                                <div className="col-xl-4 col-md-6 col-6">
                                    <div className="api--card">
                                        <div className="api--card_title">Total # of Meetings</div>
                                        <div className="api--detail">
                                            <div className="detail-number">{companyData.summary_data && companyData.summary_data.total_meetings}</div>
                                            {companyData.summary_data && companyData.summary_data.total_meetings !== 0 && company_differences && company_differences.difference.diff_total_meeting_percentage !== "N/A" && company_differences.difference.diff_total_meeting_percentage !== 0 ? (
                                                <div className={company_differences && company_differences.difference.diff_total_meeting_percentage >= 0 ? "detail-percentage green" : "detail-percentage red"}>
                                                    {company_differences && company_differences.difference.diff_total_meeting_percentage} % {company_differences && company_differences.difference.diff_total_meeting_percentage >= 0 ? <img alt="contains svg icon" src="/media/ms-media/percent-arrow-up.svg" /> : <img alt="contains svg icon" src="/media/ms-media/percent-arrow-down.svg" />}
                                                </div>
                                            )  : <div className='detail-percentage green'>--%</div> }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 col-6">
                                    <div className="api--card">
                                        <div className="api--card_title">Different People</div>
                                        <div className="api--detail">
                                            <div className="detail-number">{companyData.summary_data && companyData.summary_data.different_people}</div>
                                            {companyData.summary_data && companyData.summary_data.different_people !== 0 && company_differences && company_differences.difference.diff_people_meet_percentage !== "N/A"  && company_differences.difference.diff_people_meet_percentage !== 0 ? (
                                                <div className={company_differences && company_differences.difference.diff_people_meet_percentage >= 0 ? "detail-percentage green" : "detail-percentage red"}>
                                                    {company_differences && company_differences.difference.diff_people_meet_percentage} % {company_differences && company_differences.difference.diff_people_meet_percentage >= 0 ? <img alt="contains svg icon" src="/media/ms-media/percent-arrow-up.svg" /> : <img alt="contains svg icon" src="/media/ms-media/percent-arrow-down.svg" />}
                                                </div>
                                            )  : <div className='detail-percentage green'>--%</div> }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 col-6">
                                    <div className="api--card">
                                        <div className="api--card_title">Total Time, hrs</div>
                                        <div className="api--detail">
                                            <div className="detail-number">{companyData.summary_data && companyData.summary_data.total_time}</div>
                                            {companyData.summary_data && companyData.summary_data.total_time !== 0 && company_differences && company_differences.difference.diff_total_time_percentage !== "N/A" && company_differences.difference.diff_total_time_percentage !== 0 ? (
                                                <div className={company_differences && company_differences.difference.diff_total_time_percentage >= 0 ? "detail-percentage green" : "detail-percentage red"}>
                                                    {company_differences && company_differences.difference.diff_total_time_percentage} % {company_differences && company_differences.difference.diff_total_time_percentage >= 0 ? <img alt="contains svg icon" src="/media/ms-media/percent-arrow-up.svg" /> : <img alt="contains svg icon" src="/media/ms-media/percent-arrow-down.svg" />}
                                                </div>
                                            )  : <div className='detail-percentage green'>--%</div> }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 col-6">
                                    <div className="api--card">
                                        <div className="api--card_title">Average People Per Meeting</div>
                                        <div className="api--detail">
                                            <div className="detail-number">{companyData.summary_data && companyData.summary_data.averagePersonPerMeeting}</div>
                                            {companyData.summary_data && companyData.summary_data.averagePersonPerMeeting !== 0 && company_differences && company_differences.difference.diff_average_person_per_meeting_percentage !== "N/A" && company_differences.difference.diff_average_person_per_meeting_percentage !== 0 ? (
                                                <div className={company_differences && company_differences.difference.diff_average_person_per_meeting_percentage >= 0 ? "detail-percentage green" : "detail-percentage red"}>
                                                    {company_differences && company_differences.difference.diff_average_person_per_meeting_percentage} % {company_differences && company_differences.difference.diff_average_person_per_meeting_percentage >= 0 ? <img alt="contains svg icon" src="/media/ms-media/percent-arrow-up.svg" /> : <img alt="contains svg icon" src="/media/ms-media/percent-arrow-down.svg" />}
                                                </div>
                                            )  : <div className='detail-percentage green'>--%</div> }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 col-6">
                                    <div className="api--card">
                                        <div className="api--card_title">Meeting Acceptable Rate, %</div>
                                        <div className="api--detail">
                                            <div className="detail-number">{companyData.summary_data && companyData.summary_data.meeting_acceptable_rate}</div>
                                            {companyData.summary_data && companyData.summary_data.meeting_acceptable_rate !== 0 && company_differences && company_differences.difference.diff_acceptance_rate !== "N/A" && company_differences.difference.diff_acceptance_rate !== 0 ? (
                                                <div className={company_differences && company_differences.difference.diff_acceptance_rate >= 0 ? "detail-percentage green" : "detail-percentage red"}>
                                                    {company_differences && company_differences.difference.diff_acceptance_rate} % {company_differences && company_differences.difference.diff_acceptance_rate >= 0 ? <img alt="contains svg icon" src="/media/ms-media/percent-arrow-up.svg" /> : <img alt="contains svg icon" src="/media/ms-media/percent-arrow-down.svg" />}
                                                </div>
                                            )  : <div className='detail-percentage green'>--%</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-6 col-6">
                                    <div className="api--card">
                                        <div className="api--card_title">Presence of Agenda %</div>
                                        <div className="api--detail">
                                            <div className="detail-number">{companyData.summary_data && companyData.summary_data.agenda_meetings_percentage}</div>
                                            {companyData.summary_data && companyData.summary_data.agenda_meetings_percentage !== 0 && company_differences && company_differences.difference.diff_agenda_meetings_percentage !== "N/A" && company_differences.difference.diff_agenda_meetings_percentage !== 0 ? (
                                                <div className={company_differences && company_differences.difference.diff_agenda_meetings_percentage >= 0 ? "detail-percentage green" : "detail-percentage red"}>
                                                    {company_differences && company_differences.difference.diff_agenda_meetings_percentage} % {company_differences && company_differences.difference.diff_agenda_meetings_percentage >= 0 ? <img alt="contains svg icon" src="/media/ms-media/percent-arrow-up.svg" /> : <img alt="contains svg icon" src="/media/ms-media/percent-arrow-down.svg" />}
                                                </div>
                                            )  : <div className='detail-percentage green'>--%</div> }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6 col-6">
                                    <div className="api--card">
                                        <div className="api--card_title">
                                            Total Meetings Cost, <UserCurrency />
                                        </div>
                                        <div className="api--detail">
                                            <div className="detail-number">{companyData.summary_data && companyData.summary_data.total_cost}</div>
                                            {companyData.summary_data && companyData.summary_data.total_cost !== 0 && company_differences && company_differences.difference.diff_total_cost !== "N/A" && company_differences.difference.diff_total_cost !== 0 ? (
                                                <div className={company_differences && company_differences.difference.diff_total_cost >= 0 ? "detail-percentage green" : "detail-percentage red"}>
                                                    {company_differences && company_differences.difference.diff_total_cost} % {company_differences && company_differences.difference.diff_total_cost >= 0 ? <img alt="contains svg icon" src="/media/ms-media/percent-arrow-up.svg" /> : <img alt="contains svg icon" src="/media/ms-media/percent-arrow-down.svg" />}
                                                </div>
                                            )  : <div className='detail-percentage green'>--%</div> }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6 col-6">
                                    <div className="api--card d-flex flex-row">
                                        <div className="flex-grow-1">
                                            <div className="api--card_title">
                                                Gender Overlap Report <span className="font-weight-normal">(by meeting)</span>
                                            </div>
                                            <div className="color-label-wrap">
                                                <div className="color-label">
                                                    <span className="color_box primary"></span> Male, {companyData.summary_data && companyData.summary_data.male_percentage}%
                                                </div>
                                                <div className="color-label">
                                                    <span className="color_box primary-light"></span> Female, {companyData.summary_data && companyData.summary_data.female_percentage}%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="api--card_chart" style={{marginRight:30}}>
                                            <GenderOverlapReport type="company" />
                                        </div>
                                    </div>
                                </div>
                            </div>      
                       )
                            
                         : (
                             !companyData && <Spinner animation="border" role="status" style={fixedMargin}>
                                <span className="visually-hidden"></span>
                            </Spinner>
                        )}
                        {companyData && companyData.status == 'error' && companyData.summaryStatus == 0 && <div>We're sorry, for the time you requested, we don't have any data to share with you.</div>}
                        </div>
                    </div>
                    </div>
                    </>
    )};
