/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useHistory } from "react-router-dom";

export function DropdownMenu2() {

    const history = useHistory()

    const handelLogout =()=>{
        history.push({
            pathname: '/logout',
            state: {
              by : 'code'
            }
          });
    }
    
    return <>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover">
    
            <li className="navi-item">
                <Link to="/user-profile/personal-info" className="navi-link">
                    <span className="navi-text">Profile</span>
                </Link>
            </li>
            <li className="navi-item" style={{cursor:'pointer'}}>
                <p onClick={handelLogout} className="navi-link">
                    <span className="navi-text">Sign Out</span>
                </p>
            </li>
        </ul>
        {/*end::Navigation*/}
    </>
}
