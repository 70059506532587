/* eslint-disable */ 
// import getStorage from "redux-persist/lib/storage/getStorage";
import { setStorage } from "./LocalStorageHelpers";
import {default as salaries} from "../../app/datasets/salaries";
import moment from "moment";

export const allFirstLettersCapitalize = (string) => {

    if(string !== ''){
        return  string.split(' ')
        .map((element) => element.charAt(0).toUpperCase() + element.substring(1))
        .join(" ") 
    }
    else {
        return '';
    }

}

    export const checkObjectSize = (object) => {
            var size =0,key;

            for (key in object) {
                if(object.hasOwnProperty(key)) size++;
            }
            return size;
}

export const deleteAllCookies = () => {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export const clearAllStorage = () => {
       if(checkCookie()) {localStorage.clear();
        sessionStorage.clear();
        deleteAllCookies();}
}

export const WordCount = (str) => { 
    return str.split(" ").length;
  }

  export const letterCount = (str) => { 
    return str.length;
  }


  export const authUser  = () => {
    const ls = localStorage.getItem('USER_NAME');
    if(ls == null){
      return 'test'
    }else{
      return window.atob(ls);
    }
     
  } 

  export const authUserLastname  = () => {
    const ls = localStorage.getItem('USER_LASTNAME');
    if(ls == null){
      return ''
    }else{
      return window.atob(ls);
  } 
}

export const newUserCheck  = () => {
  const ls = localStorage.getItem('IS_NEW_USER');
  if(ls == null){
    return 0
  }else{
    return ls;
} 
}

export const newSigninCheck  = () => {
  const ls = localStorage.getItem('FRESH_SIGN_IN');
  if(ls == null){
    return 0
  }else{
    return ls;
} 
}

export const step5Check = () => {
  const ls = localStorage.getItem('STEP5_CHECK');
  if(ls == null){
    return 0
  }else{
    return ls;
} 
}

export const redirectLink = () => {
  const ls = localStorage.getItem('REDIRECT');
  if(ls == null){
    return 0
  }else{
    return ls;
} 
}


// export const calculateLastSignInTime  = () => {
//   const lastVisitTime = localStorage.getItem('VISIT_TIME');
//   const currentTime = moment();
//   const duration = moment.duration(currentTime.diff(lastVisitTime))
//   const diff = duration.asHours();
//   if(lastVisitTime !==null && diff >= 24){
//     return 1
//   }else if(lastVisitTime == null){
//     return 1;
// }else{
//     return 0;
// } 
// }



  export const userData = () => {
    return JSON.parse(localStorage.getItem('USER_DATA'))
  }

  export const authAccessToken  = () => {
    // console.log(authAccessToken);
    return JSON.parse(localStorage.getItem('ACCESS_TOKEN'));
  } 

  export const authEmail  = () => {

    const em = localStorage.getItem('USER_EMAIL');
    if(em){
            return window.atob(em);
    }
        
  } 
  
  export const authUserId  = () => {
    let id = localStorage.getItem('USER_ID');
    if (id !== null){
      return window.atob(id);
    }else{
      return 0
    }
  } 

  export const authCompanyId  = () => {
    return window.atob(localStorage.getItem('COMPANY_ID'));
  } 


//   export const authUserId  = () => {
//    // return '1037'; //eric porres - meetignscience
//     return '6429'; //philippe depallens - logitech
//     //return '150406' // annie wang - ivanti
// } 

// export const authCompanyId  = () => {
//   //return '11'; // meetingscience
//    return '22'; // logitech
//    //return '8010' // ivanti
// } 

  export const authTimezone  = () => {
    return localStorage.getItem('TIMEZONE');
  } 

  export const mcrCheck = () => {
    return localStorage.getItem('MCR');
  } 

  export const fetchEventDataFromLocal  = () => {
    return JSON.parse(localStorage.getItem('event_percent'));
  } 

  export const addServiceProvider  = (data) => {
    return setStorage('PROVIDER',data);
  } 

  export const getServiceProvider  = () => {
    return localStorage.getItem('PROVIDER');
  } 

  export const getEnvUrl = () => {
         return process.env.REACT_APP_PUBLIC_URL
  }

  export const getUserAgent = () => {
    return navigator.userAgent;
  }

  export const getEventCount = () => {
    return localStorage.getItem('EVENTS_FETCHED');
  }

  export const getSuccessfulEvents = () => {
    return localStorage.getItem('EVENTS_SAVED');
  }

// export const fetchSalaries = (title) => {
//   const salaryData =  salaries.filter((element) => element[0].match(title));
//   if (salaryData && salaryData.length ){
//       setStorage('SALARY',salaryData[0][1]);
//   }else{
//     setStorage('SALARY','0');
//   }
// }

// export const authUserSalary = () => {
//   return localStorage.getItem('SALARY');
// }

export const formatTime = (value) => {
    if(value == 12){
      return value + ' pm'
    }else if(value >= 12 && value < 24 ){
      return value - 12 + ' pm'
    }else if(value == 0){
      return   ''
      // return   '12:01 am'
    } else if(value == 24){
      return ''
      // return '11:59 pm'
    }
    else if(value > 0 < 12){
      return value + ' am'
    }

}

export function checkCookie(evt){
  var cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled && evt && evt.data === 'MM:3PCunsupported'){ 
      document.cookie = "testcookie";
      cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
  }
  return cookieEnabled;
}


export function  getDateRangeArray(startDate, endDate) {
  var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(endDate);
    while (currentDate <= stopDate) {
        dateArray.push( moment(currentDate).format('DD/MM') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
};

export const countNoOfDays = (start,end) => {
  var Difference_In_Time = end.getTime() - start.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
}

export const getZoomStatus = () => {
  return localStorage.getItem('ZOOM_STATUS')
}

export const setZoomStatus = (status) => {
   return localStorage.setItem('ZOOM_STATUS',status)
}

export const getWeekType = (filter) => {
  if(filter == '0'){
    return "last_month"
  }else if(filter == '1'){
    return "last_two_weeks"
  }else if(filter == '2'){
    return "last_week"
  }else if(filter == "3"){
    return "next_week"
  }else if(filter == "4"){
    return "custom"
  }
}

export const getLoginType = () => {
  const ls = localStorage.getItem('LOGIN_TYPE')
  if (ls!==null && ls!== undefined){
    return ls;
  }else{
    return 0;
  }
}

export const checkPlusSymbol = (phone) => {
  let isAdded = phone.includes("+");
    if(isAdded){
      return phone
    }else{
      return "+" + phone
    }
}

export const checkPhoneValidity = (phone,country) => {
  const validNumberCount = (country.format.match(/\./g) || []).length;
  if(phone.length == validNumberCount){
    return 1
  }else{
    return 0
  }
}

export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};