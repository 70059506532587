import { call, put, takeLatest } from 'redux-saga/effects';
import { sendApiError } from '../../../../app/modules/Auth/_redux/authCrud';
import { Apistore } from '../../../apiStore';
import { RECEIVE_COMPANY_DATA, REQUEST_COMPANY_DATA, RESET_STATE, RECEIVE_COMPANY_RATING_ATTRIBUTES, REQUEST_COMPANY_RATING_ATTRIBUTES, REQUEST_COMPANY_KEY_DIFFERENCES, RECEIVE_COMPANY_KEY_DIFFERENCES, RECEIVE_COMPANY_OVERALL_FEEDBACK, REQUEST_COMPANY_OVERALL_FEEDBACK } from './company.action';
import { fetchCompanyData, fetchCompanyDifferences, fetchCompanyOverallFeedback, fetchCompanyRatingsData } from './company.crud';

function* getCompanyData(action) { 
    yield put({type:RESET_STATE})
    try{
            const result = yield call (fetchCompanyData,action.payload);
            yield put({type:RECEIVE_COMPANY_DATA,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetCompanyEvents)
    }
}
function* getCompanyAttributes(action) {
    try {
        const result = yield call(fetchCompanyRatingsData, action.payload);
        yield put({ type: RECEIVE_COMPANY_RATING_ATTRIBUTES, data: result.data })

    } catch (e) {
        sendApiError(500,e,Apistore.GetCompanyRatingAndAttributes)
    }
}

function* getDifferences(action) {
    try {
        const result = yield call(fetchCompanyDifferences, action.payload);
        yield put({ type: RECEIVE_COMPANY_KEY_DIFFERENCES, data: result.data })

    } catch (e) {
        sendApiError(500,e,Apistore.GetCompanyKeyDifferences)
    }
}

function* getOverallFeedback(action) {
    try {
        const result = yield call(fetchCompanyOverallFeedback, action.payload);
        yield put({ type: RECEIVE_COMPANY_OVERALL_FEEDBACK, data: result.data })

    } catch (e) {
        sendApiError(500,e,Apistore.GetCompanyAttendeesFeedback)
    }
}



export function* companyWatcher() {
    yield takeLatest(REQUEST_COMPANY_DATA, getCompanyData);
    yield takeLatest(REQUEST_COMPANY_RATING_ATTRIBUTES, getCompanyAttributes);
    yield takeLatest(REQUEST_COMPANY_KEY_DIFFERENCES,getDifferences);
    yield takeLatest(REQUEST_COMPANY_OVERALL_FEEDBACK, getOverallFeedback);
}