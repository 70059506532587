import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AlertPopup } from '../../../_helpers/Alerts';
import { usePrevious } from '../../../_helpers/Custom';

const HowYouImprove = () => {
    const fixedMargin = {
        margin: "calc(50% - 20px)"
    }
    // redux state
    const scoreHowToImproveData = useSelector((state) => state.score.getUserScoreHowToImproveData);
    const prevScoreHowToImproveData = usePrevious({ scoreHowToImproveData });
    // Set Initial State
    const [alert, setAlert] = useState({ show: false, title: null, message: null, errType: null });
    const [message, setMessage] = useState("");
    const [howToImproveData, setHowToImproveData] = useState([]);
    const [showAgendas, setShowAgendas] = useState(true);
    const [showShortMeetings, setShowShortMeetings] = useState(true);
    const [showSpaceMeetings, setShowSpaceMeetings] = useState(true);

    // fetch data
    useEffect(() => {
        if (prevScoreHowToImproveData && prevScoreHowToImproveData.scoreHowToImproveData !== scoreHowToImproveData) {
            if (scoreHowToImproveData && _.has(scoreHowToImproveData, "how_to_improve") && scoreHowToImproveData.code === "200") {
                if (scoreHowToImproveData.how_to_improve.length > 0) {
                    let allData = scoreHowToImproveData.how_to_improve;
                    setHowToImproveData(allData);
                    setMessage("");
                } else {
                    setMessage("We don't have any data to share with you.")
                }
            }
            if (scoreHowToImproveData && _.has(scoreHowToImproveData, "message") && scoreHowToImproveData.status === "error") {
                if (scoreHowToImproveData.open_popup === false) {
                    setAlert({
                        show: true,
                        message: "Something went wrong!",
                        title: "Error",
                        errType: "failure",
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevScoreHowToImproveData, scoreHowToImproveData]);

    // handle close button of alert
    const handleClose = () => {
        setAlert({
            show: false,
        });
    };
    return (
        <>
            {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />}
            <div className="card modal-card">
                <div className="card-body">
                    <div className="card-body--title">How You Improve</div>
                    {howToImproveData.length > 0 ?
                    <div className="improve-agenda">
                        <div className="agenda-row">
                            <div className="agenda-no d-flex align-items-center justify-content-between">
                                <div className="agenda-no-col flex-grow-1 d-flex align-items-top">
                                    <span className="d-inline-block text-center">1</span>{howToImproveData.length > 0 ? howToImproveData[0].title : ""}<sup className="high"><img src="/media/ms-media/star-high-icn.svg" alt="" /><img src="/media/ms-media/star-high-icn.svg" alt="" /><img src="/media/ms-media/star-high-icn.svg" alt="" /></sup>
                                </div>
                                <button className="btn btn-sm btn-show--hide" onClick={() => { setShowAgendas(!showAgendas) }}>{showAgendas ? 'Show details' : 'Hide details'}</button>
                            </div>
                            {!showAgendas ?
                                <div className="agenda-body">
                                    <p>{howToImproveData.length > 0 ? howToImproveData[0].text : ""}</p>
                                </div>
                                : ''}
                        </div>
                        <div className="agenda-row">
                            <div className="agenda-no d-flex align-items-center justify-content-between">
                                <div className="agenda-no-col flex-grow-1 d-flex align-items-top">
                                        <span className="d-inline-block text-center">2</span> {howToImproveData.length > 0 ? howToImproveData[1].title : ""}<sup className="medium"><img src="/media/ms-media/star-mid-icn.svg" alt="" /><img src="/media/ms-media/star-mid-icn.svg" alt="" /></sup>
                                </div>
                                <button className="btn btn-sm btn-show--hide" onClick={() => { setShowShortMeetings(!showShortMeetings) }}>{showShortMeetings ? 'Show details' : 'Hide details'}</button>
                            </div>
                            {!showShortMeetings ?
                                <div className="agenda-body">
                                    <p>{howToImproveData.length > 0 ? howToImproveData[1].text : ""}</p>
                                </div>
                                : ''}
                        </div>
                        <div className="agenda-row">
                            <div className="agenda-no d-flex align-items-center justify-content-between">
                                <div className="agenda-no-col flex-grow-1 d-flex align-items-top">
                                        <span className="d-inline-block text-center">3</span>  {howToImproveData.length > 0 ? howToImproveData[2].title : ""}<sup className="low"><img src="/media/ms-media/star-low-icn.svg" alt="" /></sup>
                                </div>
                                <button className="btn btn-sm btn-show--hide" onClick={() => { setShowSpaceMeetings(!showSpaceMeetings) }}>{showSpaceMeetings ? 'Show details' : 'Hide details'}</button>
                            </div>
                            {!showSpaceMeetings ?
                                <div className="agenda-body">
                                    <p>{howToImproveData.length > 0 ? howToImproveData[2].text : ""}</p>
                                </div>
                                : ''}
                        </div>
                        <div className="stars-decl">
                            <div>
                                <span><img src="/media/ms-media/star-high-icn.svg" alt="" /><img src="/media/ms-media/star-high-icn.svg" alt="" /><img src="/media/ms-media/star-high-icn.svg" alt="" /></span>
                                - High priority level
                            </div>
                            <div>
                                <span><img src="/media/ms-media/star-mid-icn.svg" alt="" /><img src="/media/ms-media/star-mid-icn.svg" alt="" /></span>
                                - Medium priority level
                            </div>
                            <div>
                                <span><img src="/media/ms-media/star-low-icn.svg" alt="" /></span>
                                - Low priority level
                            </div>
                        </div>
                        </div> :
                        message === "" ?
                        <Spinner animation="border" role="status" style={fixedMargin}>
                            <span className="visually-hidden"></span>
                        </Spinner>
                            : <p className="text-center">{message}</p>
                    }
                </div>
            </div>
        </>
    );
};

export default HowYouImprove;