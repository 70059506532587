/* eslint-disable */ 
import React,{useEffect, useState} from "react";
import {
  KeyKpiWidget, LeadBoardModal, MeetingsHeavy, MeetingsTimeConsuming, MeetingsWithMostPeople, ScoreModal,
} from "../widgets";
import { useDispatch, useSelector } from 'react-redux';
import { requestAttendedData } from "../../../redux/duck/dashboard/attended/attended.action";
import { useLocation } from "react-router-dom";
import { REQUEST_LEADERBOARD_DATA, REQUEST_MEETING_MASTERY_INFO } from "../../../redux/duck/reports/leaderboard/leaderboard.action";


export function AttendedDashboard() {

  const dispatch = useDispatch();
  // const user_id = authUserId();
  const {timePeriod} = useSelector(state => state.picker);
  const {getLocalStorageUserData} = useSelector(state => state.profile)
  const location = useLocation();
  const [scoreModal,setScoreModal] = useState(false);
  const [leadBoardModal,setLeadBoardModal] = useState(false);

  useEffect(() => {
    if(getLocalStorageUserData){
        dispatch(requestAttendedData({
          // user_id:authUserId(),
          // timezone:getLocalStorageUserData.user_data.timezone,
          // currency_conversion_rate:getLocalStorageUserData.user_data.currency.currency_conversion_rate,
          sdate:timePeriod.sdate,
          edate:timePeriod.edate,
        }));
}
},[timePeriod.filter,timePeriod.sdate,timePeriod.edate,timePeriod.custom])


const updateUserProfileImage = useSelector(state => state.profile.saveUserProfileImageData);

useEffect(() => {
  if(location.pathname == '/score'){
    setScoreModal(true);
  }

  if(location.pathname == '/leaderboard'){
    setLeadBoardModal(true);
    
    dispatch({ type: REQUEST_LEADERBOARD_DATA, payload: {
      // user_id:authUserId(),
      sort:'percent_rating_submitted',
      order:'DESC'      
    }})
    
    if(getLocalStorageUserData && getLocalStorageUserData.user_data){ dispatch({
      type: REQUEST_MEETING_MASTERY_INFO, payload: {
        // user_id: getLocalStorageUserData && getLocalStorageUserData.user_data.user_id,
        start_date: timePeriod.sdate,
        end_date: timePeriod.edate
      }
    })}
  }

},[])

  return (
    <>
      <div className="row col-card-gutter">
        <div className="col-lg-6 col-xl-6">
          <KeyKpiWidget className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6 col-xl-6">
          <MeetingsHeavy className="card-stretch gutter-b" />
        </div>

        <div className="col-xl-6">
          <MeetingsTimeConsuming
            className="card-stretch gutter-b"
            symbolShape="circle"
            baseColor="success"
          />
        </div>    
        <div className="col-md-6">
                <MeetingsWithMostPeople/>          
        </div>    
      </div>
      {<ScoreModal show={scoreModal} handleClose={() => setScoreModal(false)} />}
      {<LeadBoardModal show={leadBoardModal} handleClose={() => setLeadBoardModal(false)} />}
    </>
  );
}
