/* eslint-disable */ 

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from 'react-apexcharts'
import { Spinner } from "react-bootstrap";
import { FilterRange } from "../../layout/components/header/FilterLabel";
import { countNoOfDays } from "../../_helpers/functions";
import { MeetingSummaryModal } from ".";
import { logActivity } from "../../../app/modules/Auth/_redux/authCrud";

export function MeetingsHeavy({ className }) {

  const {events,code,initialState} = useSelector(state => state.attended)
  const {timePeriod} = useSelector(state => state.picker)
  // const [meetingModal, setMeetingModal] = useState(false);
  // const [meetingSummary,setSummary] = useState();
  const [eventsData,setEventsData] = useState([]);
  
  const labels = {
    'Mon':0,
    'Tue':0,
    'Wed':0,
    'Thu':0,
    'Fri':0
  };

  const seriesData = []
  let newData = []

  const [state,setState] = useState({
    series:[],
    options: {
      states: {

        hover: {
            filter: {
                type: 'none',

            }
        }
    },
      noData: {
        text: undefined,
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      },
      dataLabels:{
        enabled:false
      },
      legends:{
        show:false
      },
        colors: [function({ value, seriesIndex, w }) {
             value = (value * 60).toFixed(0);
          if(value <= 15) {
              return '#D3F5F9'
          } else if (value>15 && value <= 25) {
              return '#8FE0E9'
          }else if (value>25 && value <= 30) {
            return '#4ACBDA'
        }
        else if (value>30 && value <= 45) {
          return '#00ACBF'
      }
      else if (value>45 && value <= 50) {
        return '#008E9D'
    }
    else if (value>50 && value <= 60) {
      return '#00626C'
  }else if (value>60 && value <= 80) {
    return '#02444C'
  }else{
    if(value>80){
      return '#02444C'
    }
  
  }
        }],
        grid: {
          show: true,
          borderColor: '#f8f9fb',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
              lines: {
                  show: true
              }
          },   
          yaxis: {
              lines: {
                  show: true
              }
          },  
          row: {
              colors: undefined,
              opacity: 0.5
          },  
          column: {
              colors: undefined,
              opacity: 0.5
          },  
          padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
          },  
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
          tools:{
            download:true // <== line to add
          }
        },
        events: {
          // click: function(event, chartContext, config) {
          //   if(config.seriesIndex !== -1 && config.dataPointIndex !== -1){
          //     let event_title_hashed = config.globals.initialSeries[config.seriesIndex].name;
          //     let event_id = event_title_hashed.split('#');
          //      handleSummaryModal(event_id[0]);
          //   }
          // },
          dataPointMouseEnter: function(event) {
            try {
              // event.path[0].style.cursor = "pointer";
            } catch (error) {
              console.log(error);
            }
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          colors:{
            backgroundBarColors: ["#f8f9fb"],
          }
        },
      },
      stroke: {
        width: 0.5,
        colors: ['#fff']
      },
      xaxis: {
        title: {
          text: 'Total meeting % of the day',
          offsetX: 0,
          offsetY: 0,
          style: {
              color: '#999EA5',
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-title',
          },
        },
        categories: ['Mon','Tue','Wed','Thu','Fri'],
        labels: {
          style:{
            colors:"#999EA5",
            fontSize:'12px',
            fontWeight: 400,
          },
          formatter: function(val) {
            return val;
          }
        }
      },
      yaxis: {
        show: true,
        showAlways: true,
        showForNullSeries: true,
        seriesName: "",
        opposite: false,
        reversed: false,
        logarithmic: false,
        min:0,
        max:8,
        forceNiceScale: true,
        floating: false,
        decimalsInFloat: undefined,
        labels: {
          offsetX: -10,
      },
        title: { 
          text: 'hr',
          align: 'top',
          margin: "10px",
          offsetX: 2,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '14px',
            fontWeight:  500,
            fontFamily:  undefined,
            color:  '#999EA5'
          },
        }
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function(val) {
            return calcTime(val);
          },
          title:{
            formatter : function(seriesName) {
                  var title = seriesName.split('#');
                return  title[1];
            },
          },
        },
        x:{
          show: false,
        },
        marker: {
          show: false,
      },
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "top",
        show:false,
        horizontalAlign: "left",
        offsetX: 40
      }
    }
  });

  const getPercent = (num) => {
    
    let total = 8 ;

    const {filter} = timePeriod;
        
    if(filter == 'today' || filter == 'yesterday'){
        total = 8;
    }else{
        const {sdate,edate} = timePeriod;
        const date1 = new Date(sdate);
        const date2 = new Date(edate);
        let noOfDays = countNoOfDays(date1,date2);
        let noOfWeeks = (noOfDays/7).toFixed(0);
        if(noOfWeeks == 0){
        total = 8;
        }else{
          total = noOfWeeks*8;
        }        
  }
    return ((num/total)*100).toFixed(0) + ' %';
  }

  const calcMaxHours = () => {
    
    const {filter} = timePeriod;

    if(filter == 'today' || filter == 'yesterday'){
        return 8;
    }else{
  const {sdate,edate} = timePeriod;
  const date1 = new Date(sdate);
  const date2 = new Date(edate);
  let noOfDays = countNoOfDays(date1,date2);
  let noOfWeeks = (noOfDays/7).toFixed(0);
      if(noOfWeeks == 0){
      return 8;
      }else{
        return noOfWeeks*8;
      }
}
  }

  const handleSummaryModal = (id) => {
    logActivity('modal','/meeting-summary',id,window.location.pathname);
    const event_index =  eventsData.findIndex((i) => i.event_id == id);  
    if(event_index !== -1){
      setSummary(eventsData[event_index]);
      setMeetingModal(true);
    }
}


  const renderLabels = () => {
        let finalData = [];
            finalData.push('Mon ' + getPercent(labels.Mon));
            finalData.push('Tue ' + getPercent(labels.Tue));
            finalData.push('Wed ' + getPercent(labels.Wed));
            finalData.push('Thu ' + getPercent(labels.Thu));
            finalData.push('Fri ' + getPercent(labels.Fri));


            let color15 = [];
            let color25 = [];
            let color30 = [];
            let color45 = [];
            let color50 = [];
            let color60 = [];
            let color80 = [];
            let color90 = [];
            let color120 = [];
            let colorOthers = [];

            seriesData.map(i =>{  
              i.data.map((elem) =>  {
                let mins = ((elem * 60 ).toFixed(0));
               
                if(mins > 0 && mins <= 15 ) {
                  color15.push(i)
              } else if (mins>15 && mins <= 25) {
                color25.push(i)
              }else if (mins> 25  && mins <=  30 ) {
                color30.push(i)
            }
            else if (mins> 30 && mins <=  45 ) {
              color45.push(i)
          }
          else if (mins> 45  && mins <=  50 ) {
            color50.push(i)
        }
        else if (mins> 50  && mins <=  60 ) {
          color60.push(i)
      }else if(mins>  60  && mins <=  80 ){
            color80.push(i)
      }else if(mins>  80  && mins <=  90 ){
        color90.push(i)
  }else if(mins>  90  && mins <=  120 ){
    color120.push(i)
}else if(mins >  120 ){
  colorOthers.push(i)
}

              })
            })

             newData = [
              ...color15,
              ...color25,
              ...color30,
              ...color45,
              ...color50,
              ...color60,
              ...color80,
              ...color90,
              ...color120,
              ...colorOthers,
              
            ]

  

            setState({
              ...state,
                options:{
                  ...state.options,
                        xaxis:{
                          ...state.options.xaxis,
                            categories:finalData
                        },
                        yaxis:{
                          ...state.options.yaxis,
                          max:calcMaxHours()
                        }
                },
              series:newData
               
          })

          


      }

      const calcTime = (time) => {
        let currentTime = (time * 60).toFixed(0);
        if(currentTime <= 60) {
          return currentTime + " min";
        }else{
          const hours = (currentTime/60).toFixed(0);
          const mins = (currentTime%60).toFixed(0);
          return hours + ' h ' + mins + ' min' 
        }
      }
     
  useEffect(() => {
    
    let flag = 0;
    newData = [];

    events && events.map((i) => {
            let dateTime = i.event_start_datetime
            let title = i.event_title !== null ? i.event_title : 'N/A'
            let eventStartDay = moment(dateTime).format('dddd');
            let eventStartDate = moment(dateTime).format('YYYY-MM-DD');
         
            if(eventStartDay !== 'Saturday' && eventStartDay !== 'Sunday'){
              eventsData.push(i);
                  switch (eventStartDay){

                        case 'Monday':
                          labels.Mon = labels.Mon + i.event_duration
                          seriesData.push({name:i.event_id  + "# " + title +  ' - ' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[i.event_duration,0,0,0,0]}) 
                        break;
                        case 'Tuesday':
                          labels.Tue = labels.Tue + i.event_duration
                          seriesData.push({name:i.event_id  + "# " + title +  ' - ' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,i.event_duration,0,0,0]}) 
                        break;
                        case 'Wednesday':
                          labels.Wed = labels.Wed + i.event_duration
                          seriesData.push({name:i.event_id  + "# " + title +  ' - ' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,i.event_duration,0,0]}) 
                        break;
                        case 'Thursday':
                          labels.Thu = labels.Thu + i.event_duration
                          seriesData.push({name:i.event_id  + "# " + title +  ' - ' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,0,i.event_duration,0]}) 
                        break;
                        case 'Friday':
                          labels.Fri = labels.Fri + i.event_duration
                          seriesData.push({name:i.event_id  + "# " + title +  ' - ' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,0,0,i.event_duration]}) 
                        break;
                  }
            }

            ++flag

            if(flag == events.length){
                renderLabels();
            }
    })
  },[events])

  return (
    <>
      <div className={`card card-custom  ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">What days are meeting heavy?</span>
          </h3>
          <div className="card-subtitle d-flex justify-content-between">
            <h5>Average % of meetings per day for the {<FilterRange/>}</h5>
          </div>
       </div>
          <div className="card-body">
          {events &&  initialState !== null  ? 
            <ReactApexChart options={state.options} series={state.series} type="bar" height={300} />
            :
            !code ?  
            <div className='loader-container'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner> 
            </div>
          :
          <div>We're sorry, for the time you requested, we don't have any data to share with you.</div>
          }  
          
           </div>
       </div> 
       {/* {meetingModal && <MeetingSummaryModal data = {meetingSummary} show={meetingModal} handleClose={() => setMeetingModal(false)} />} */}
    </>
  );
}
