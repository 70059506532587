/* eslint-disable */ 
import React from 'react';
import { makeStyles, Slider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300
    },
    margin: {
        height: theme.spacing(10)
    },
    thumb: {
        background: "#00ACBF",
        "&~&": {
            background: "#00ACBF"
        }
    },
    mark: {
        background: "#00ACBF",
    },
    rail: {
        background: "linear-gradient(to right top, #46bb3b, #6fc63d, #91d041, #b1d948, #cfe352, #e0da4a, #f0d148, #fec849, #feaa42, #fa8b42, #f16d48, #e34f4f);"
    },
    track: {
        background: "linear-gradient(to right top, #46bb3b, #6fc63d, #91d041, #b1d948, #cfe352, #e0da4a, #f0d148, #fec849, #feaa42, #fa8b42, #f16d48, #e34f4f);"
    },
    valueLabel: {
        "&>*": {
            background: "transparent",
            color: "black",
            position: "absolute",
            top: 22,
        }
    }
}));

export const CompanyRatingsSliderComponent = (props) => {
    const classes = useStyles();
    return (
        <div key={3}>
            <Slider
                classes={{
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel: classes.valueLabel,
                    mark: classes.mark
                }}
                value={Number(props.values)}
                valueLabelDisplay="on"
                step={0.1}
                max={5}
                min={0}
                name='CompanyRatings'
                marks={props.marks}
            />
        </div>
    );
};
