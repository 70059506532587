/* eslint-disable */ 

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from 'react-apexcharts'
import { Spinner } from "react-bootstrap";

export const DoubleBarGraph = () => {


  const {feedbackTrends} = useSelector(state => state.organized);

  const [state,setState] = useState({
    series: [],
      options: {
        chart: {
          type: 'bar',
          height: 430
        },
        colors: ['#008E9D', 'rgba(102, 236, 251, 0.85)'],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels:{
          enabled:false,
        formatter: function(val, opt) {
          return val;
        }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val
            }
          },
          y: {
            formatter: function (val) {
              return val + "%"
            }
          }
        },
        xaxis: {
          categories: ["Accountability","Participation","Preparation","Presence","Quality","Time Management"],
        },
        yaxis:{
          show:false
        }
      },
    
    
    });

    useEffect(() => {

      let series = [];
      
      if( feedbackTrends && feedbackTrends.status == "success" && feedbackTrends.message == "Data found" ){
        for(let item in feedbackTrends.data){
          series.push({name:item,data:[]})

              series.map((element, index) => {
                  if(element.name == item){
                    series[index].data.push(feedbackTrends.data[item]["Accountability"].positive_percentage);
                    series[index].data.push(feedbackTrends.data[item]["Participation"].positive_percentage);
                    series[index].data.push(feedbackTrends.data[item]["Preparation"].positive_percentage);
                    series[index].data.push(feedbackTrends.data[item]["Presence"].positive_percentage);
                    series[index].data.push(feedbackTrends.data[item]["Quality"].positive_percentage);
                    series[index].data.push(feedbackTrends.data[item]["Time Management"].positive_percentage);
                  }            
              })
      }
    
    }
    // new added
    //sorting array by months
    var months = { January: 0, February: 1, March: 2, April: 3, May: 4, June: 5, July: 6, August: 7, September: 8, October: 9, November: 10, December: 11 };
    const sortedArray = () => {
      let res;
      if (months[series[0]?.name] === 0 && months[series[1]?.name] === 11) {
        return series.reverse();
      } else if (months[series[0]?.name] === 11 && months[series[1]?.name] === 0) {
        return series;
      } else {
        res = series.sort(function (a, b) {
          return months[a.name] - months[b.name];
        });
        return res;
      }
    };

    setState({
      ...state,
      series:sortedArray()
    })

  },[feedbackTrends])

  return (

<div className="card mb-4 card-custom">
        {/* Header */}
        <div className="card-header align-items-center border-0">
            <h3 className="card-title align-items-center d-flex w-100 justify-content-between"><span className="font-weight-bolder text-dark"> Your Meeting Feedback Over Time </span></h3>
            <div className="card-subtitle d-flex justify-content-between">
                <h5>This graph shows how you're progressing according to participant feedback from your meetings</h5>
                <div className="card-subtitle-btn">
                    {/* <a className="btn btn-sm btn-light">You</a>
                    <a className="btn btn-sm btn-light-primary">Other people</a> */}
                </div>
            </div>
        </div>

        <div className="card-body">    
            { feedbackTrends && feedbackTrends.status == "success" && feedbackTrends.message == "Data found" ? <ReactApexChart options={state.options} series={state.series} type="bar" height="450" />

            :

            <div className='loader-container'>
            { !feedbackTrends ? <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner> 
            :
            <div>We're sorry, for the time you requested, we don't have any data to share with you.</div>  
            }
            </div>
          
            }
        </div>
    </div>


  );
}
