import { RECEIVE_COMPANY_DATA, RESET_STATE, RECEIVE_COMPANY_RATING_ATTRIBUTES, RECEIVE_COMPANY_KEY_DIFFERENCES, RECEIVE_COMPANY_OVERALL_FEEDBACK } from "./company.action";
import { DEFAULT_STATE } from "./company.state";

export const companyReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case RECEIVE_COMPANY_DATA:
            const data = action.data;
            return {
                ...state,
                    companyData:data
            }; 
        case RESET_STATE:
            return {
                ...state,
                companyData:null,
                companyRatings:null,
                company_differences:null
            };
        case RECEIVE_COMPANY_RATING_ATTRIBUTES:
            return {
                ...state,
                companyRatings: action.data
            }
            case RECEIVE_COMPANY_KEY_DIFFERENCES:
                return {
                    ...state,
                    company_differences: action.data
                }
                case RECEIVE_COMPANY_OVERALL_FEEDBACK:
                    return {
                        ...state,
                        company_overall_feedback: action.data
                    }
        default:
            return state;
    }
}