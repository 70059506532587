import axios from 'axios';
import { Apistore } from '../../../apiStore';

export function getUserScoreDataApi(data) {
    return axios.post(Apistore.GetUserScoreData, data)
}

export function getUserScoreComparisonDataApi(data) {
    return axios.post(Apistore.GetUserScoreComparisonData, data)
}

export function getUserScoreGraphDataApi(data) {
    return axios.post(Apistore.GetUserScoreGraphData, data)
}

export function getUserScoreHowToImproveDataApi(data) {
    return axios.post(Apistore.GetUserScoreHowToImproveData, data)
}