export const DEFAULT_STATE = {
    getProfileInfoDetailData: {},
    updateProfileInfoDetailData: {},
    saveUserProfileImageData: {},
    updateUserRelationsData: {},
    updateUserNotificationsData: {},
    updateUserFeedbackSettings: {},
    updateUserPrivacyData: {},
    checkUniqueNicknameData: {},
    getLocalStorageUserData: {},
    send_otp_succ:null,
    send_otp_err:null,
    verify_otp_succ:null,
    verify_otp_err:null,
    resend_otp_succ:null,
    resend_otp_err:null,
    error:null
}