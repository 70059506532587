/* eslint-disable */ 

import React, { useEffect,useState } from 'react';
import { makeStyles, Slider } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300
    },
    margin: {
        height: theme.spacing(10)
    },
    thumb: {
        background: "#00ACBF",
        "&~&": {
            background: "#00ACBF"
        }
    },
    mark: {
        background: "#00ACBF",
        "&>*": {
            background: "#00ACBF"
        }
    },
    rail: {
        background: "#999EA5"
    },
    track: {
        background: "#00ACBF"
    },
    valueLabel: {
        "&>*": {
            background: "transparent",
            color: "black",
            position: "absolute",
            top: 12
        }
    }
}));


export const TimeMinSlider = (props) => {
    const classes = useStyles();

    const [marks,setMarks] = useState([
        {
            value:1,
            label:'12:00 am'
        },
        {
            value:100,
            label:'12:00 pm'
        }
    ])

    useEffect(() => {
        const {event_start_time,event_end_time} = props.data

            if(event_start_time && event_end_time){
                setMarks([
                    {
                        value:1,
                        label: event_start_time
                    },
                    {
                        value:100,
                        label:event_end_time
                    }
                ])
            }

    },[props])

    return (
        <>
            <Slider
                classes={{
                    thumb: "time-min-slider-thumb",
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel:classes.valueLabel,
                    mark: "time-min-slider-mark"
                }}
                value={[1,100]}
                aria-label="Small"
                max={100}
                min={1}
                valueLabelDisplay="off"
                aria-labelledby="range-slider"
                //getAriaValueText={props.valuetext}
                marks={marks}
            />
        </>
    );
};