import React from 'react';
import { Modal } from "react-bootstrap";

export const SuccessTimeMachineModal = (props) => {
    return (
        <>
            <Modal className="time_machine-modal-small"
                show={props.show} onHide={props.handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body className="text-center">
                    <h4 className="modal-sm-title">Hooray!</h4>
                    <img src="/media/ms-media/meeting-tick-icn.svg" alt=""/>
                    <p>{props.message}<br/>What would you like to do next?</p>
                    <div className="modal_btns d-flex align-items-center">
                        <button className="btn btn-bordred flex-grow-1" onClick={props.handleClose}>Return to Meeting Shortener</button>
                        <button className="btn btn-primary flex-grow-1" onClick={props.dashboard}>Return to Application</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
