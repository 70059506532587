import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getProfileInfoDetail, updateUserNotificationsDetail, UPDATE_MEETING_MASTERY_NOTIFICATIONS } from '../../../../redux/duck/userProfile/userProfile.action';
import { usePrevious } from '../../../_helpers/Custom';
import { authUserId } from '../../../_helpers/functions';
import { Navbar } from "./Navbar";
import _ from 'lodash';
import { AlertPopup } from "../../../_helpers/Alerts";
import { LayoutSplashScreen } from "../../../layout";
import { Link } from "react-router-dom";
// import { UserCurrency } from "../../../layout/components/currencychecker";
import "../../../_assets/sass/pages/Notifications.scss"
import './dashboard.css'

export const Notifications = () => {
  // Set Initial Values
  const user_id = authUserId();
  const dispatch = useDispatch();
  const profileData = useSelector(state => state.profile.getProfileInfoDetailData);
  const prevProfileData = usePrevious({ profileData });
  const notificationData = useSelector(state => state.profile.updateUserNotificationsData);
  const prevNotificationData = usePrevious({ notificationData });
  // Loader State
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [alert, setAlert] = useState({ show: false, title: null, message: null, errType: null });
  const [isRefVisible,setIsRefVisible]=useState(false)
  const [focusStyle,setFocusStyle]=useState({
    monday:{},
    tuesday:{},
    wednesday:{},
    thursday:{},
    friday:{},
  })
  const [reportFocusStyle,setReportFocusStyle]=useState({
    yes:{},
    no:{}
  })
  const [alertFocusStyle,setAlertFocusStyle]=useState({
    yes:{},
    no:{}
  })
  const [state, setState] = useState({
    user_id: "",
    user_name: "",
    user_firstname: "",
    user_phone: "",
    country_code: "",
    coachingReport: [],
    fitnessReport: "0",
    // meeting_mastery_on:"0"
  });
  const mondayRef = useRef()
  const tuesdayRef =useRef()
  const wednesdayRef =useRef()
  const thursdayRef = useRef()
  const fridayRef = useRef()
  const reportYesRef =useRef()
  const reportNoRef =useRef()
  const alertYesRef =useRef()
  const alertNoRef =useRef()
  const saveButtonRef=useRef()

  const [relations, setRelations] = useState({
    data: []
  });



  const { new_badge_notify } = useSelector(state => state.profile)

  //pass id as param
  useEffect(() => {
    setLoader(true);
    dispatch(getProfileInfoDetail({ }))
    dispatch({
      type: "FETCH_MEETING_MASTERY_NOTIFICATIONS",
      data: {
       
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 


  // fetch Notification data and handle error popup
  useEffect(() => {
    if (prevProfileData && prevProfileData.profileData !== profileData) {
      if (profileData && _.has(profileData, 'notification_setting') && profileData.status === "success") {
        const userData = profileData.user_data;
        const userNotificationData = profileData.notification_setting;
        setState({
          ...state,
          user_id: userData.user_id,
          user_name: userData.user_name,
          user_firstname: userData.user_firstname,
          user_phone: userData.user_phone,
          country_code: userData.country_code,
          coachingReport: userNotificationData.mcr_days,
          fitnessReport: userNotificationData.mfr,
          meeting_mastery_on: userNotificationData.meeting_mastery,
          sms: userNotificationData.sms_send_on === false ? 0 : userNotificationData.sms_send_on
        });
        setLoader(false);
      }
      if (profileData && _.has(profileData, 'message') && profileData.status === "error") {
        if (profileData.open_popup === false) {
          setLoader(false);
          setAlert({
            show: true,
            message: "Something went wrong!",
            title: 'Error',
            errType: "failure"
          })
        }
      }
    }
    if (prevNotificationData && prevNotificationData.notificationData !== notificationData) {
      if (notificationData && _.has(notificationData, 'message') && notificationData.status === "success") {
        dispatch(getProfileInfoDetail({  }))
        setLoader(false);
        setDisabled(true);
      }
      if (notificationData && _.has(notificationData, 'message') && notificationData.status === "error") {
        if (notificationData.open_popup === false) {
          setLoader(false);
          setAlert({
            show: true,
            message: "Something went wrong!",
            title: 'Error',
            errType: "failure"
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProfileData, profileData, prevNotificationData, notificationData]);


  useEffect(() => {
    let temp = [];

    if (new_badge_notify && new_badge_notify.status === "success") {
      for (let item in new_badge_notify.user_relationship_data) {
        new_badge_notify.user_relationship_data[item].map(user => {
          return temp.push({
            "relationId": user.relationship_user_id,
            "status": user.status
          })
        })
      }
    }

    setRelations({
      ...relations,
      data: temp
    });
    // eslint-disable-next-line
  },[new_badge_notify])


  // handle close for alert popup
  const handleClose = () => {
    setAlert({
      show: false
    })
  };

  const handleNotificationToggle = (e) => {

    const { id } = e.target;

    // setSaveRelation([...saveRelation,name])
    let newArr = relations.data;
    // eslint-disable-next-line eqeqeq
    let checkid = newArr.filter(item =>  item.relationId == id);
    // eslint-disable-next-line eqeqeq
    let checkedIndex = newArr.findIndex(item => item.relationId == id);

    // eslint-disable-next-line eqeqeq
    if (checkid[0]?.status == 0 ) {
      newArr[checkedIndex].status = 1;
      setRelations({
        ...relations,
        data: newArr
      })

    } else {
      

        //-------------------------------------
        // handelAboveElse(newArr,checkedIndex)
        newArr[checkedIndex].status = 0
        //-------------------------------------
        
        
        //  newArr[checkedIndex].status = 0
        
        setRelations({
          ...relations,
          data: newArr
        })
      }
      
  }
  // handel above else
  // const handelAboveElse =(newArr,checkedIndex)=>{
  //   if (newArr[checkedIndex].status) {
  //       newArr[checkedIndex]?.status == 0
  //     }else{
  //       return null
  //     }
  // }


  // handle notification input data
  const handleNotificationInput = e => {
    const { value } = e.target;
    let newArr = [...state.coachingReport];
    let checkValue = newArr.includes(value);
    const dayVal = newArr.findIndex(checkVal)
    if (checkValue === false) {
      newArr.push(value);
      setState({ ...state, coachingReport: newArr })
    } else {
      newArr.splice(dayVal, 1)
      setState({ ...state, coachingReport: newArr })
    }
    function checkVal(val) {
      // eslint-disable-next-line eqeqeq
      return val == value;
    }
  }

  const isChecked = (id) => {
    // eslint-disable-next-line eqeqeq
    let data = relations.data.filter(item => item.relationId == id)
    // eslint-disable-next-line eqeqeq
    if (data.length && data[0].status == 1) {
      return true
    } else {
      return false
    }

  }

  //handel edit button
  const handelEditButton = ()=>{
    setDisabled(false) 
    setIsRefVisible(true)
  }

  // handle Cancel button
  const handleCancel = () => {
    setIsRefVisible(false)
    setDisabled(true);
    setLoader(true);
    dispatch(getProfileInfoDetail({  }));
  }

  // save notification data
  const saveNotificationData = () => {
    const mcr_days = [...state.coachingReport];
    const notificationData = {
      user_id,
      mcr_days,
      mfr: state.fitnessReport,
      meeting_mastery: state.meeting_mastery_on
    };
    setIsRefVisible(false)
    setLoader(true);
    dispatch(updateUserNotificationsDetail(notificationData))

    // const rDublicate = saveRelation.filter((elem,i)=>saveRelation.indexOf(elem)===i)

    // let finalData = [];

    // if (relations.data && relations.data.length) {

     

    //   rDublicate.map((i,index) => {
    //     if (i.status == 1) {
          
    //       finalData.push(i)
    //     }
    //   })
    // }

    let finalData = [];

    // eslint-disable-next-line
    if (relations.data && relations.data.length) {
      // eslint-disable-next-line 
      relations.data.map((i) => {
        // eslint-disable-next-line eqeqeq
        if (i.status == 1) {
          finalData.push(i.relationId)
        }
      })
    }
     
    

    
    dispatch({
      type: UPDATE_MEETING_MASTERY_NOTIFICATIONS,
      data: {
        // user_id: authUserId(),
        relationship_user_ids: finalData
      }
    })
  }

  const checkDataExists = (key) => {
    if (new_badge_notify &&
      new_badge_notify.user_relationship_data &&
      new_badge_notify.user_relationship_data[key] &&
      new_badge_notify.user_relationship_data[key].length) {
      return true
    } else {
      return false
    }
  }

  //handel focus transfer from "coaching" to "report" section
  const focusTransfer =()=>{
    setTimeout(() => {
      // eslint-disable-next-line eqeqeq
      if(state.fitnessReport =='0'){
        setReportFocusStyle({...reportFocusStyle,no:{background:'#008D9D',color:'white'}})
        setTimeout(() => {
          reportNoRef.current.focus()
        }, 2);
      }else{
        setReportFocusStyle({...reportFocusStyle,yes:{background:'#008D9D',color:'white'}})
        setTimeout(() => {
          reportYesRef.current.focus()
        }, 2);
      }
    }, 150);
  }

  //handel ref on didmount (it will make focus on monday when edit button clicked)
  useEffect(()=>{
    if (isRefVisible) {
      mondayRef.current.focus()
      setFocusStyle({...focusStyle,monday:{background:'#008D9D',color:'white'}})
    }
    // eslint-disable-next-line
  },[isRefVisible])

  //handel monday key down
  const handelKeyDown =(e)=>{
    if (e.key === 'Enter') {
      setFocusStyle({...focusStyle,monday:{}})
      handleNotificationInput(e)
    }else if (e.key === 'ArrowRight'){
      setTimeout(() => {
        tuesdayRef.current.focus()
      }, 2);
      setFocusStyle({...focusStyle,monday:{},tuesday:{background:'#008D9D',color:'white'}})
    }
    else if(e.key === 'Tab'){
      setFocusStyle({...focusStyle,monday:{}})
      setTimeout(() => {
        focusTransfer()
      }, 2);
    }
  }

  //handel tuesday key down
  const handelKeyDown2 =(e)=>{
    if (e.key === 'Enter') {
      setFocusStyle({...focusStyle,tuesday:{}})
      handleNotificationInput(e)
    }else if (e.key === 'ArrowRight'){
      setTimeout(() => {
        wednesdayRef.current.focus()
      }, 2);
      setFocusStyle({...focusStyle,tuesday:{},wednesday:{background:'#008D9D',color:'white'}})
    }
    else if (e.key === 'ArrowLeft'){
      setTimeout(() => {
        mondayRef.current.focus()
      }, 2);
      setFocusStyle({...focusStyle,monday:{background:'#008D9D',color:'white'},tuesday:{}})
    }
   
    else if(e.key === 'Tab'){
      setFocusStyle({...focusStyle,tuesday:{}})
      setTimeout(() => {
        focusTransfer()
      }, 2);
      
    }
  }

  //handel wednesday input keydown
  const handelKeyDown3 =(e)=>{
    if (e.key === 'Enter') {
      setFocusStyle({...focusStyle,wednesday:{}})
      handleNotificationInput(e)
    }else if (e.key === 'ArrowRight'){
      setTimeout(() => {
        thursdayRef.current.focus()
      }, 2);
      setFocusStyle({...focusStyle,wednesday:{},thursday:{background:'#008D9D',color:'white'}})
    }
    else if (e.key === 'ArrowLeft'){
      setTimeout(() => {
        tuesdayRef.current.focus()
      }, 2);
      setFocusStyle({...focusStyle,wednesday:{},tuesday:{background:'#008D9D',color:'white'}})
    }
    else if(e.key === 'Tab'){
      setFocusStyle({...focusStyle,wednesday:{}})
      setTimeout(() => {
        focusTransfer()
      }, 2);
      
    }
  }

  //handel thursday input keydown
  const handelKeyDown4 =(e)=>{
    if (e.key === 'Enter') {
      setFocusStyle({...focusStyle,thursday:{}})
      handleNotificationInput(e)
    }else if (e.key === 'ArrowRight'){
      setTimeout(() => {
        fridayRef.current.focus()
      }, 2);
      setFocusStyle({...focusStyle,thursday:{},friday:{background:'#008D9D',color:'white'}})
    }
    else if (e.key === 'ArrowLeft'){
      setTimeout(() => {
        wednesdayRef.current.focus()
      }, 2);
      setFocusStyle({...focusStyle,wednesday:{background:'#008D9D',color:'white'},thursday:{}})
    }
    else if(e.key === 'Tab'){
      setFocusStyle({...focusStyle,thursday:{}})
      setTimeout(() => {
        focusTransfer()
      }, 2);
    }
  }

  //handel friday input keydown
  const handelKeyDown5 =(e)=>{
    if (e.key === 'Enter') {
      setFocusStyle({...focusStyle,friday:{}})
      handleNotificationInput(e)
    }
    else if (e.key === 'ArrowLeft'){
      setTimeout(() => {
        thursdayRef.current.focus()
      }, 2);
      setFocusStyle({...focusStyle,thursday:{background:'#008D9D',color:'white'},friday:{}})
    }
    else if(e.key === 'Tab'){
      setFocusStyle({...focusStyle,friday:{}})
      setTimeout(() => {
        focusTransfer()
      }, 2);
    }
  }

  // handel report section keydown
  const handelReportKeyDown =(e)=>{
    if (e.key === 'Enter') {
      setReportFocusStyle({yes:{},no:{}})
      
    }else if(e.key === 'Tab'){
      if (state.user_phone === "") {
        setTimeout(() => {
          saveButtonRef.current.focus()
        }, 2);
      }else{
        if (state.meeting_mastery_on === "0" ) {
          setAlertFocusStyle({...alertFocusStyle,no:{background:'#008D9D',color:'white'}})
          setTimeout(() => {
            alertNoRef.current.focus()
          }, 2);
        }else {
          setAlertFocusStyle({...alertFocusStyle,yes:{background:'#008D9D',color:'white'}})
          setTimeout(() => {
            alertYesRef.current.focus()
          }, 2);
        }
      }
    }
  }

  //handel alert section keydown
  const handelAlertKeyDown =(e)=>{
    if (e.key === 'Enter'){
      setAlertFocusStyle({no:{},yes:{}})
    } else if(e.key === 'Tab'){
      setTimeout(() => {
        saveButtonRef.current.focus()
      }, 2);
    }
  }

  return (
    <>
      <LayoutSplashScreen visible={loader} />
      {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />}
      <div className="profile-container flex-column h-100 d-flex">
        <div className="nav-container d-flex justify-content-between notification_navigation">
          <Navbar getMainRoute={"notification"} />
          <div className="edit-button">
            {disabled ? <button className="btn btn-edit" onClick={handelEditButton}>
              Edit
            </button> :
              <>
                <button className="btn btn-edit mr-2" onClick={() => handleCancel()}>
                  Cancel
                </button>
                <input ref={saveButtonRef} type='button' className="btn btn-primary" onClick={() => saveNotificationData()} value='Save Changes' />
                {/* <button className="btn btn-primary" onClick={() => saveNotificationData()}>
                  Save Changes
                </button> */}
              </>
            }
          </div>
        </div>

        <div className="tab-content bg-transparent flex-grow-1 notification_profileTab" id="profileTab">
          <div className="card card-custom">
            <div className="card-body">
              <div className="tab-pane fade show active" id="Notifications" role="tabpanel" aria-labelledby="Notifications-tab">
                <div className="Notifications-wrap">
                  <p>
                    <strong>{state.user_firstname ? state.user_firstname : state.user_name},</strong> you can adjust the frequency of your notifications from MeetingScience here.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <h5>Meeting Coaching Report</h5>
                          <p className="notification_meetingreport_text">
                            Please choose which days of the week you'd like your report sent to you. The report changes in real-time as your meetings change throughout the week. Some of our power Members with heavy meeting schedules choose to have
                            their report sent to them every morning so that they can prepare for peak performance.
                          </p>
                          <div className="auth-selectgroup">
                            <label onKeyDown={handelKeyDown} className="auth-selectgroup-item">
                              <input
                                onBlur={()=>{ setFocusStyle({...focusStyle,monday:{}})}}
                                ref={mondayRef}
                                className="selectgroup-input"
                                onChange={(e) => handleNotificationInput(e)}
                                type="checkbox"
                                name="Monday"
                                disabled={disabled ? true : false}
                                checked={state.coachingReport.includes("1") ? true : false}
                                id="1"
                                value="1"
                              />
                              <span style={focusStyle.monday} className="selectgroup-button">Monday</span>
                            </label>
                            <label onKeyDown={handelKeyDown2} className="auth-selectgroup-item">
                              <input
                                ref={tuesdayRef}
                                className="selectgroup-input"
                                onChange={(e) => handleNotificationInput(e)}
                                type="checkbox"
                                name="Tuesday"
                                disabled={disabled ? true : false}
                                checked={state.coachingReport.includes("2") ? true : false}
                                id="2"
                                value="2"
                              />
                              <span style={focusStyle.tuesday} className="selectgroup-button">Tuesday</span>
                            </label>
                            <label onKeyDown={handelKeyDown3} className="auth-selectgroup-item">
                              <input
                                ref={wednesdayRef}
                                className="selectgroup-input"
                                onChange={(e) => handleNotificationInput(e)}
                                type="checkbox"
                                name="Wednesday"
                                disabled={disabled ? true : false}
                                checked={state.coachingReport.includes("3") ? true : false}
                                id="3"
                                value="3"
                              />
                              <span style={focusStyle.wednesday} className="selectgroup-button">Wednesday</span>
                            </label>
                            <label onKeyDown={handelKeyDown4} className="auth-selectgroup-item">
                              <input
                                className="selectgroup-input"
                                ref={thursdayRef}
                                onChange={(e) => handleNotificationInput(e)}
                                type="checkbox"
                                name="Thursday"
                                disabled={disabled ? true : false}
                                checked={state.coachingReport.includes("4") ? true : false}
                                id="4"
                                value="4"
                              />
                              <span style={focusStyle.thursday} className="selectgroup-button">Thursday</span>
                            </label>
                            <label onKeyDown={handelKeyDown5} className="auth-selectgroup-item">
                              <input
                                className="selectgroup-input"
                                ref={fridayRef}
                                onChange={(e) => handleNotificationInput(e)}
                                type="checkbox"
                                name="Friday"
                                disabled={disabled ? true : false}
                                checked={state.coachingReport.includes("5") ? true : false}
                                id="5"
                                value="5"
                              />
                              <span style={focusStyle.friday} className="selectgroup-button">Friday</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <h5>Meeting Fitness Report</h5>
                          <p className="notification_meetingreport_text" >
                            Please choose whether you wish to receive your Meeting Fitness Report weekly. Our Power Members use their Meeting Fitness Report to reflect on how they performed the previous week and what they could do to
                            save time and improve their future meetings.
                          </p>
                          <div className="auth-selectgroup">
                            <label onKeyDown={handelReportKeyDown} className="auth-selectgroup-item">
                              <input ref={reportYesRef} onBlur={()=>{setReportFocusStyle({...reportFocusStyle,yes:{}})}} className="selectgroup-input" disabled={disabled ? true : false} onChange={() => setState({ ...state, fitnessReport: "1" })} type="radio" name="fitnessReport" checked={state.fitnessReport === "1" ? true : false} id="Yes" value="1" />
                              <span style={reportFocusStyle.yes} className="selectgroup-button">Yes</span>
                            </label>
                            <label onKeyDown={handelReportKeyDown} className="auth-selectgroup-item">
                              <input ref={reportNoRef} onBlur={()=>{setReportFocusStyle({...reportFocusStyle,no:{}})}} className="selectgroup-input " disabled={disabled ? true : false} onChange={() => setState({ ...state, fitnessReport: "0" })} type="radio" name="fitnessReport" checked={state.fitnessReport === "0" ? true : false} id="No" value="0" />
                              <span style={reportFocusStyle.no} className="selectgroup-button">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12" style={{ marginTop: "20px" }} >
                      <div className="card">
                        <div className="card-body">
                          <h5>Meeting Mastery Notifications</h5>
                          <p>
                            Alert these members when I receive a new meeting mastery badge.
                          </p>
                          <div className="notification-section row">

                          {new_badge_notify && new_badge_notify.status === "success" && relations.data.length ?
                          // eslint-disable-next-line 
                              Object.keys(new_badge_notify.user_types).map((key, index) => {
                                // eslint-disable-next-line 
                                if (checkDataExists(key)) {
                                  return <div key={key} className="col-md-3">
                                    <div className="setting_title">{new_badge_notify.user_types[key]}</div>
                                    {
                                      // eslint-disable-next-line 
                                      Object.keys(new_badge_notify.user_relationship_data).map((key2, index2) => {
                                        // eslint-disable-next-line eqeqeq
                                        if (key2 == key) {
                                          return new_badge_notify.user_relationship_data[key].map((user, index3) => {
                                            return <div className="setting_item" key={user.relationship_user_id}>
                                              <span className="switch switch-sm setting_switch ml-0">
                                                <label title={user.full_name} htmlFor={user.relationship_user_id}>
                                                  <input type="checkbox" disabled={disabled} checked={isChecked(user.relationship_user_id) ? true : false} name={user.relationship_user_id} onChange={(e) => handleNotificationToggle(e)} id={user.relationship_user_id} />
                                                  <span></span>
                                                  {user.full_name?.length>=20 ? user.full_name.substr(0, 20) + '...' : user.full_name }
                                                </label>
                                              </span>
                                            </div>
                                          })
                                        }
                                      })
                                    }
                                  </div>
                                }
                              })
                              :

                              <p className="col-md-12">Sorry, you have not selected any relations from <Link to={"/user-profile/relationship"}> Relationships </Link> tab. Kindly specify them and try again. Thanks!</p>

                            }

                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {state.user_phone !== "" ? */}
                      <div className="col-md-6">
                        <div className="card h-auto mt-7">
                          <div className="card-body">
                            <h5>Meeting Mastery Badge Alert</h5>
                            <p>
                              Whenever you earn a new <Link to='/meeting-mastery'>meeting
                                mastery badge</Link> or improve your <Link to='/meeting-mastery'>level of mastery</Link>, we'll let you know with a quick email. However, perhaps you don't want to be showered with meeting love. You can choose not to receive badge or mastery notifications here.
                            </p>
                            <div className="auth-selectgroup">
                              <label onKeyDown={handelAlertKeyDown} className="auth-selectgroup-item">
                                <input ref={alertYesRef} onBlur={()=>{setAlertFocusStyle({...alertFocusStyle,yes:{}})}} className="selectgroup-input" disabled={disabled ? true : false} onChange={() => setState({ ...state, meeting_mastery_on: "1" })} type="radio" name="meeting_mastery_on" checked={state.meeting_mastery_on === "1" ? true : false} id="1" value={"1"} />
                                <span style={alertFocusStyle.yes} className="selectgroup-button">Yes</span>
                              </label>
                              <label onKeyDown={handelAlertKeyDown} className="auth-selectgroup-item">
                                <input ref={alertNoRef} onBlur={()=>{setAlertFocusStyle({...alertFocusStyle,no:{}})}} className="selectgroup-input" disabled={disabled ? true : false} onChange={() => setState({ ...state, meeting_mastery_on: "0" })} type="radio" name="meeting_mastery_on" checked={state.meeting_mastery_on === "0" ? true : false} id="0" value={"0"} />
                                <span style={alertFocusStyle.no} className="selectgroup-button">No</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* // : ""} */}

                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
