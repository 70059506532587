import axios from 'axios';
import { Apistore } from '../../../apiStore';

export  function fetchMasteryList (user_id){
   return  axios.post(Apistore.GetMeetingMasteryLevelsList) 
//    return  axios.post(Apistore.GetMeetingMasteryLevelsList,user_id) 
}


export  function fetchHigestLevel (user_id){
    return  axios.post(Apistore.GetHighestlevelAcheived,null,{
        params:{
            // user_id:user_id
        }
    }) 
 }

export  function fetchBadgeSummary (user_id){
    return  axios.post(Apistore.GetUserBadgeSummary,null,{
        params:{
            // user_id:user_id
        }
    }) 
 }

 export  function fetchUserProgress (data){
    return  axios.post(Apistore.GetUserProgressSummary,null,{
        params:{
            // user_id:data.user_id,
            start_date:data.sdate,
            end_date:data.edate
        }
    }) 
 }