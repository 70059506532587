/* eslint-disable */ 

import React, { useState,useEffect, useRef } from 'react';
import { Modal, Spinner } from "react-bootstrap";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { GenderBalanceAttendeesChart } from './widget8/GenderBalanceAttendeesChart';
import { AttendeeTimeSlider } from './widget8/AttendeeTimeSlider';
import { RatingScoreSlider } from './widget8/RatingScoreSlider';
import { CostSlider } from './widget8/CostSlider';
import parse from 'html-react-parser';
import moment from 'moment';
import { UserCurrency } from '../../layout/components/currencychecker';
import { useDispatch,useSelector } from 'react-redux';
import { requestSingleMeetingRating, requestSingleRatingAttributes } from '../../../redux/duck/dashboard/organized/organized.action';
import { authUserId } from '../../_helpers/functions';
import { capitalize } from '@material-ui/core';
import { TimeMinSlider } from './widget8/TimeMinSlider';

export const MeetingSummaryModal = (props) => {

    const [disabled, setDisabled] = useState(false);
    const [positiveAttr,setPositiveAttr] = useState([]);
    const [negetiveAttr,setNegetiveAttr] = useState([]);
    const [morePositiveMeetings,showMorePositiveMeetings] = useState(false);
    const [moreNegetiveMeetings,showMoreNegetiveMeetings] = useState(false);
    const [genderData,setGenderData] = useState({
        malePercentage:0,
        femalePercentage:0
    })
  
    const dispatch = useDispatch();
    const {single_rating,single_attributes} = useSelector(state => state.organized);

    const popover =  () => (
        <Popover id="popover-basic" className="mr-4">
          <Popover.Content className="p-2">
            <div className="badge--card">
              <div className="badge--card-body">
              <span className="">Balanced means at least 25% of male and female participants.</span>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      );
   


    // const popover = (
    //     <Popover id="popover-basic" className="mr-4">
    //       <Popover.Content className="p-2">
    //         <div className="badge--card">
    //           <div className="badge--card-body">
    
    //         {positiveAttr.length>5 && positiveAttr.slice(5,positiveAttr.length).map((i,j) =>
    //           <span className="label label-lg label-light-primary mr-2 label-inline">{i.title}</span>
    //             )
    //         }
    
    //           </div>
    //         </div>
    //       </Popover.Content>
    //     </Popover>
    //   );
    
    //   const popover2 = (
    //     <Popover id="popover-basic" className="mr-4">
    //       <Popover.Content className="p-2">
    //         <div className="badge--card">
    //           <div className="badge--card-body">
    
    //         {negetiveAttr.length>5 && negetiveAttr.slice(5,negetiveAttr.length).map((i,j) =>
    //           <span className="label label-lg label-light-danger mr-2 label-inline">{i.title}</span>
    //             )
    //         }
    
    //           </div>
    //         </div>
    //       </Popover.Content>
    //     </Popover>
    //   );

    const ratingScoreMarks = [{
        value: 1,
    }, {
        value: 2.3,
    }, {
        value: 3.6,
    }, {
        value: 5,
    }];
    const attendeeTimeMarks = [{
        value: 20,
    }, {
        value: 40,
    }, {
        value: 60,
    }];
    const costMarks = [{
        value: 1000,
    }, {
        value: 2000,
    }, {
        value: 3000,
    },];
  
   
    const handleOrganizerAndDate = (eventStartDate) => {
        
            const userName  = props.data.event_organizer_firstname + ' ' + props.data.event_organizer_lastname;
            const date = moment(eventStartDate).format('YYYY/MM/DD');
            //const time = moment(eventStartDate).format("hh:mm A");
            return userName + ' | ' + date ;    
    }



    const InviteduserList = () => {
        
    let accepted_ids = props.data.event_accepted_ids;    
    let users_list = props.data.event_users;
    let accepted_ids_arr = accepted_ids.split(',');
    let matchedData = [];

        for (let key in users_list){
            accepted_ids_arr.forEach((i) => {
                 if(i == key){
                        matchedData.push(users_list[key]);
                }
            })
        }
        return matchedData.map((i,index) => 
            <div key={index} className="avatar avatar-sm">
                <img  alt="..." src={'https://client.meetingscience.io/images/profile_images/ph_profile.png'} title={i.user_firstname ? i.user_firstname + ' ' + i.user_lastname : i.user_email}  onError={({ currentTarget }) => {console.log('Error:',currentTarget)}} className="avatar-img rounded-circle border border-white" />
            </div>
        )
    }

    const malePercent = (count,total) => {
        return ((count/total)*100).toFixed(0);
    }

    const femalePercent = (count,total) => {
        return ((count/total)*100).toFixed(0);
       
    }

    useEffect(() => {
   if(props && props.data){ 
        let maleCount = 0;
        let femaleCount = 0;
        let accepted_ids = props.data.event_accepted_ids;    
        //let accepted_ids_count = props.data.event_accepted_ids_count;
        let users_list = props.data.event_users;
        let accepted_ids_arr = accepted_ids.split(',');
    
    
        for (let key in users_list){
                    accepted_ids_arr.map(i => {
                        if(i === key){
                                if(users_list[key].user_gender === 1){
                                    ++maleCount;
                                }else if(users_list[key].user_gender === 2){
                                    ++femaleCount
                                }
                        }
                    })
                    setGenderData({
                        ...genderData,
                        malePercentage:malePercent(maleCount,props.data.total_attendees),
                        femalePercentage:femalePercent(femaleCount,props.data.total_attendees)    
                    })
    
                    
        }    }
    
    }, [props])

useEffect(() => {
    showMorePositiveMeetings(false);
    showMoreNegetiveMeetings(false);  
},[])

    useEffect(() => {
    
        if(props && props.data){
            dispatch(requestSingleMeetingRating({organizer_id:props.data.event_organizerid,event_ids:[props.data.event_id]}))
            dispatch(requestSingleRatingAttributes({organizer_id:props.data.event_organizerid,event_ids:[props.data.event_id]}))
        }
      
    }, [props && props.data])

    useEffect(() => {
        setPositiveAttr([]);
        setNegetiveAttr([]);
        single_attributes && single_attributes.status == 'success' && single_attributes.meeting_attributes.low.rating_attributes.length !==0  &&
            setNegetiveAttr(single_attributes.meeting_attributes.low.rating_attributes);
      
            single_attributes && single_attributes.status == 'success' && single_attributes.meeting_attributes.high.rating_attributes.length !==0  &&
            setPositiveAttr(single_attributes.meeting_attributes.high.rating_attributes);
            
      },[single_attributes])


    return (
        <>
            <Modal
                show={props.show} onHide={props.handleClose}
                size="lg"
                getAriaLabelledby="contained-modal-title-vcenter"
                // aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className="flex-wrap">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.data &&  props.data.event_title!==null && props.data.event_title!=='' ? parse(capitalize(props.data.event_title)) : 'N/A' + ' - ' + moment(props.data.event_start_datetime).format('YYYY/MM/DD')}
                    </Modal.Title>
                    <button className="btn btn-close p-0 ml-auto" onClick={props.handleClose}><img src="/media/ms-media/modal-close-icn.svg" alt="" /></button>
                    <div className="w-100 flex-100"></div>
                    <p className="w-100 mb-0 modal-sub--title">Organized by {props.data && handleOrganizerAndDate(props.data.event_start_datetime)}</p>
                    <div className="info-purpose d-flex align-items-center">Purpose: {props.data && props.data.event_having_agenda ? <span className="d-flex align-items-center justify-content-center"><img src="/media/ms-media/small-tick.svg" alt="" /></span> : "N/A"}</div>
                </Modal.Header>
                <Modal.Body>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="multicolor-slider">
                                <div className="multicolor-slider-numr"> {single_rating && single_rating!==null ? single_rating.average_rating  :  <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner> } <span>Score, of 5.0</span></div>
                                <div className="multicolor-slider-wrap">
                                    <RatingScoreSlider value={single_rating && single_rating.average_rating ? (single_rating.average_rating == 'N/A' ? 0 : single_rating.average_rating) : 0 }  marks={ratingScoreMarks} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="multicolor-slider">
                                <div className="multicolor-slider-numr">{props.data && (props.data.total_attendees_only * props.data.event_duration).toFixed(1)} <span>Attendee Time, hrs</span></div>
                                <div className="multicolor-slider-wrap reverse">
                                    <AttendeeTimeSlider values={props.data && (props.data.total_attendees_only * props.data.event_duration).toFixed(1)} marks={attendeeTimeMarks} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="multicolor-slider">
                                <div className="multicolor-slider-numr">{props.data && props.data.total_event_cost} <span>Cost, {<UserCurrency />} </span></div>
                                <div className="multicolor-slider-wrap reverse">
                                    <CostSlider value={props.data && props.data.total_event_cost} marks={costMarks} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                      
                        <div className="col-md-6">
                            <div className="card modal-card">
                                <div className="card-body">
                                    <div className="card-body--title">Gender Balance</div>
                                    <div className="showhide-accordion">
                                        <div className="showhide--btn d-flex align-items-center justify-content-between">
                                            <h4><img style={{paddingBottom:'3px'}} src={genderData && genderData.malePercentage >= 25 && genderData.femalePercentage >= 25 ? "/media/ms-media/balanced-icn.svg" : "/media/ms-media/imbalanced-icn.svg"} alt="" /> {genderData && genderData.malePercentage >= 25 && genderData.femalePercentage >= 25 ? <span>Balanced</span> : <span>Imbalanced</span>}  <OverlayTrigger  placement="top" overlay={popover()}>
                                            <span style={{cursor:'pointer'}} className="label label-lg label-light-primary2 mr-2 label-inline ml-2">?</span>
                                    </OverlayTrigger>
                                    </h4>
                                            <button className="btn btn-sm btn-show--hide" onClick={() => { setDisabled(!disabled) }}>{disabled ? 'Show details' : 'Hide details'}</button>
                                        </div>
                                        {!disabled ?
                                            <div className="showhide--body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <GenderBalanceAttendeesChart data={props && props.data} />
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <GenderBalanceTalktimeChart />
                                                    </div> */}
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>

                     <div className='col-md-6'>
                     <div className="row">
                     <div className="col-md-12">
                            <div className="card modal-card">
                                <div className="card-body px-10 row">
                                    
                                    <div className='col-md-5'>
                                    <p className="w-100 mb-0 modal-sub--title">Time, min</p>
                                           <span className='pt-3 d-block font-weight-bolder'> <span style={{fontSize:22,fontWeight:'bolder'}}>{props.data && (props.data.event_duration*60).toFixed()} </span> min</span>        
                                    </div>
                                    
                                    <div className='col-md-7 pt-3'>
                                    <TimeMinSlider data={props.data}/>
                                    </div>
                                    
                                </div>
                            </div>   
                        </div>
                        <div className="col-md-12">
                            <div className="card modal-card">
                                <div className="card-body">
                                    <div className="badge--card">
                                        <div className="badge--card-title">Positive Meeting Attributes</div>
                                        <div className="badge--card-body">
                                        {positiveAttr.length == 0 && !single_attributes ? <Spinner animation="border" role="status" >
                    <span className="visually-hidden"></span>
                  </Spinner> : 
                  null }
            
           {single_attributes && single_attributes.status == 'success' && single_attributes.meeting_attributes.high.rating_attributes.length !==0  ? positiveAttr.slice(0,5).map((i,j) =>
             <span className="label label-lg label-light-primary mr-2 label-inline">{i.title}</span>
            )   
            :single_attributes && single_attributes.status == 'success' && single_attributes.meeting_attributes.high.rating_attributes.length ==0 && <strong>N/A</strong>
          }
            
           {positiveAttr.length >5 && !morePositiveMeetings && 
              <button className="btn btn-sm btn-show--hide" onClick={() => showMorePositiveMeetings(true)}>Show more</button>
          }

          {morePositiveMeetings && positiveAttr && positiveAttr.length>5 && positiveAttr.slice(5,positiveAttr.length).map((i,j) =>
              <span className="label label-lg label-light-primary mr-2 label-inline">{i.title}</span>
                )}
           {morePositiveMeetings &&   <button className="btn btn-sm btn-show--hide" onClick={() => showMorePositiveMeetings(false)}>Show Less</button>}       
                                        </div>
                                    </div>
                                </div>
                            </div>                               
                        </div>
                        <div className="col-md-12">
                            <div className="card modal-card">
                                <div className="card-body">
                                    <div className="badge--card">
                                        <div className="badge--card-title">Negative Meeting Attributes</div>
                                        <div className="badge--card-body">
                                        {negetiveAttr.length == 0 && !single_attributes ? <Spinner animation="border" role="status" >
                    <span className="visually-hidden"></span>
                  </Spinner> : 
                  null }
            
           {single_attributes && single_attributes.status == 'success' && single_attributes.meeting_attributes.low.rating_attributes.length !==0  ? negetiveAttr.slice(0,5).map((i,j) =>
             <span className="label label-lg label-light-danger mr-2 label-inline">{i.title}</span>
            )   
            :single_attributes && single_attributes.status == 'success' && single_attributes.meeting_attributes.low.rating_attributes.length ==0 && <strong>N/A</strong>

          }
            
           {negetiveAttr.length >5 && !moreNegetiveMeetings &&
              <button className="btn btn-sm btn-show--hide" onClick={() => showMoreNegetiveMeetings(true)}>Show More</button>
            }

            {moreNegetiveMeetings && negetiveAttr && negetiveAttr.length>5 && negetiveAttr.slice(5,negetiveAttr.length).map((i,j) =>
              <span className="label label-lg label-light-danger mr-2 label-inline">{i.title}</span>
                )}

{moreNegetiveMeetings &&   <button className="btn btn-sm btn-show--hide" onClick={() => showMoreNegetiveMeetings(false)}>Show Less</button>}       


                                        </div>
                                    </div>
                                </div>
                            </div>                               
                        </div>
                    </div>                            
                    </div>                                   

                    </div>

                    {/* <div className="row">
                        <div className="col-md-6">
                            <div className="card modal-card">
                                <div className="card-body">
                                    <div className="card-body--title">Talk Time</div>
                                    <div className="showhide-accordion">
                                        <div className="showhide--btn d-flex align-items-center justify-content-between">
                                            <h4><img src="/media/ms-media/imbalanced-icn.svg" alt="" /> Imbalanced</h4>
                                            <button className="btn btn-sm btn-show--hide" onClick={() => { setShow(!show) }}>{show ? 'Show details' : 'Hide details'}</button>
                                        </div>
                                        {!show ?
                                        <div className="showhide--body">
                                                <TalktimeChart />
                                        </div>
                                            : ''}
                                    </div>
                                </div>
                            </div>   
                        </div>
                         <div className="col-md-6">
                        <div className="card modal-card">
                                <div className="card-body">
                                    <div className="card-body--title">Talk Time</div>
                                    <div className="showhide-accordion">
                                        <div className="showhide--btn d-flex align-items-center justify-content-between">
                                            <h4><img src="/media/ms-media/balanced-icn.svg" alt="" /> Balanced</h4>
                                            <button className="btn btn-sm btn-show--hide" onClick={() => { setDisabled(!disabled) }}>{disabled ? 'Show details' : 'Hide details'}</button>
                                        </div>
                                        {!disabled ?
                                        <div className="showhide--body">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <GenderBalanceAttendeesChart />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <GenderBalanceTalktimeChart />
                                                    </div>
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>*/}

                  

                    <div className="row">
                        <div className="col-12">
                            <div className="card modal-card mb-0">
                                <div className="card-body">
                                    <div className="card-body--title">Invited & Attended</div>
                                    <div className="invite-attended d-flex align-items-center overflow-auto">
                                        <div className="invite-attended-no">
                                            <div className="averagemood-nmbr">{props && props.data && props.data.total_attendees} <small>of {props && props.data && props.data.total_attendees_all}</small></div>
                                        </div>
                                        <div className="invite-attended-users flex-grow-1">
                                            <div className="avatar-group">

                                                    {props && props.data && <InviteduserList/>}    
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>


                    
                </Modal.Body>
            </Modal>
        </>
    );
};