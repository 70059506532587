/* eslint-disable */ 

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {LayoutSplashScreen} from "../../_metronic/layout";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../modules/Auth/_redux/authRedux";
import { logActivity } from "../modules/Auth/_redux/authCrud";
import { removeStorage } from "../../_metronic/_helpers";
import { ERROR_GET_LOCALSTORAGE_USER_DETAIL, getLocalStorageUserDetail, getLocalStorageUserDetailResponse, updateProfileInfoDetail } from "../../redux/duck/userProfile/userProfile.action";
import storage from "redux-persist/lib/storage";
import { googleLogout } from '@react-oauth/google';

const Logout = (props) => {

const [loading, setLoading] = useState(true);
const dispatch = useDispatch();
const history = useHistory();
const location = useLocation();

const tokenExpiredError = useSelector((state) => state.profile.error);

  // useEffect(() => {
    
  //   logActivity('logout','/logout');
  //   // dispatch(getLocalStorageUserDetail(null))
  //   localStorage.clear();
  //   dispatch(actions.logout());
  //   // storage.removeItem("persist:userData");
  //   history.push('/');     
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])


  useEffect(() => {
    if (location?.state?.by == 'code') {
      // location.state.by = null
      history.replace({ state: {} })
      if(tokenExpiredError && tokenExpiredError.code === 440){
        dispatch(getLocalStorageUserDetailResponse(ERROR_GET_LOCALSTORAGE_USER_DETAIL, null))
        localStorage.clear();
        dispatch(actions.logout());
        history.push('/'); 
        googleLogout();   
        setTimeout(() => {
          window.location.reload()
        }, 30);  
      }else if(tokenExpiredError == null){
        localStorage.clear();
        dispatch(actions.logout());
        history.push('/');
        googleLogout();
        setTimeout(() => {
          window.location.reload()
        }, 30);    
      }
    }else{
        history.go(1);
    }
  },[tokenExpiredError])

    return <LayoutSplashScreen visible={loading} />

}

export default Logout;
