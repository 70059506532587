import React from 'react';
import { Modal } from "react-bootstrap";

export const ConfirmationTimeMachineModal = (props) => {

    return (
        <>
            <Modal className="time_machine-modal-small"
                show={props.show} onHide={props.handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Body className="text-center">
                    <h4 className="modal-sm-title">Please Confirm</h4>
                    <img src="/media/ms-media/wait-icn.svg" alt=""/>
                    <p>Are you sure you want to submit your changes to Meeting Shortener? Selecting yes will reschedule all of the meeting you selected and send updated meeting details to the participants with the new duration per meeting.</p>
                    <div className="modal_btns d-flex align-items-center">
                        <button className="btn btn-bordred flex-grow-1" onClick={props.handleClose}>No</button>
                        <button className="btn btn-primary flex-grow-1" onClick={props.handleSave}>Yes</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
