import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocalStorageUserDetail,
  getProfileInfoDetail,
  updateUserPrivacyDetail,
} from "../../../../redux/duck/userProfile/userProfile.action";
import { usePrevious } from "../../../_helpers/Custom";
import { Navbar } from "./Navbar";
import _ from "lodash";
import { AlertPopup } from "../../../_helpers/Alerts";
import { LayoutSplashScreen } from "../../../layout";
import { Apistore } from "../../../../redux/apiStore";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from '@react-oauth/google';

export const Privacy = () => {
  //Set initial States and values
  const dispatch = useDispatch();
  const profileData = useSelector(state => state.profile.getProfileInfoDetailData);
  const prevProfileData = usePrevious({profileData});
  const privacyData = useSelector(state => state.profile.updateUserPrivacyData);
  const prevPrivacyData = usePrevious({privacyData});
  // Loader State
  const [optToggel, setOptToggel] = useState('');
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [alert, setAlert] = useState({
    show: false,
    title: null,
    message: null,
    errType: null,
  });
  const [state, setState] = useState({ discover_name: 0 });
  const [isVisible, setIsVisible] = useState(false);
  const [isAllowed, setIsAllowed] = useState(0);
  const [deleteConText,setDeleteConText]=useState('')
  const radioRef1 = useRef();
  const radioRef2 = useRef();
  const saveButtonRef = useRef();
  const history =useHistory()

  useEffect(() => {
    setOptToggel(
      profileData.privacy_setting?.discover_name ? "Opt-In" : "Opt-Out"
    );
  }, [profileData]);

  useEffect(() => {
    setLoader(true);
    dispatch(getProfileInfoDetail({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Fetch Privacy Setting data and Set into State
  useEffect(() => {
    if (prevProfileData && prevProfileData.profileData !== profileData) {
      if (
        profileData &&
        _.has(profileData, "privacy_setting") &&
        profileData.status === "success"
      ) {
        const userPrivacyData = profileData.privacy_setting;
        setState({ ...state, discover_name: userPrivacyData.discover_name });
        setIsAllowed(userPrivacyData?.scopes);
        setLoader(false);
      }
      if (
        profileData &&
        _.has(profileData, "message") &&
        profileData.status === "error"
      ) {
        if (profileData.open_popup === false) {
          setLoader(false);
          setAlert({
            show: true,
            message: "Something went wrong!",
            title: "Error",
            errType: "failure",
          });
        }
      }
    }
    if (prevPrivacyData && prevPrivacyData.privacyData !== privacyData) {
      if (
        privacyData &&
        _.has(privacyData, "message") &&
        privacyData.status === "success"
      ) {
        dispatch(getProfileInfoDetail({}));
        setLoader(false);
        setDisabled(true);
      }
      if (
        privacyData &&
        _.has(privacyData, "message") &&
        privacyData.status === "error"
      ) {
        if (privacyData.open_popup === false) {
          setLoader(false);
          setAlert({
            show: true,
            message: "Something went wrong!",
            title: "Error",
            errType: "failure",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProfileData, profileData, prevPrivacyData, privacyData]);

  // handle close for alert popup
  const handleClose = () => {
    setAlert({
      show: false,
    });
  };

  //handel edit button
  const handelEditButton = () => {
    setDisabled(false);
    setIsVisible(true);
  };

  useEffect(() => {
    if (isVisible) {
      // eslint-disable-next-line eqeqeq
      if (state.discover_name == 0) {
        radioRef1.current.focus();
      } else {
        radioRef2.current.focus();
      }
    }
    // eslint-disable-next-line
  }, [isVisible]);

  // handle Cancel button
  const handleCancel = () => {
    setIsVisible(false);
    setDisabled(true);
    setLoader(true);
    dispatch(getProfileInfoDetail({}));
  };

  // save Privacy Data
  const savePrivacyData = () => {
    const privacyData = { discover_name: state.discover_name };
    setIsVisible(false);
    setLoader(true);
    dispatch(updateUserPrivacyDetail(privacyData));
  };

  //  handel key down of radio button section
  const handelKeyDown = (e) => {
    if (e.key === "Tab") {
      setIsVisible(false);
      setTimeout(() => {
        saveButtonRef.current.focus();
      }, 2);
    }
  };

  const updateScopeInDB = (success, msg) => {
    axios
      .post(Apistore.UpdateGoogleScope, {
        email:profileData?.user_data.email,
        scope:success.scope,
        access_token:success.access_token
      })
      .then((res) => {
        // eslint-disable-next-line eqeqeq
        if (res.data.code == 200) {
          setLoader(false);
          setAlert({
            show: true,
            message: msg,
            title: "Success",
            errType: "success",
          });
          dispatch(getLocalStorageUserDetail({}));
          dispatch(getProfileInfoDetail({}));
        }else if (res.data.code !== 200) {
          setAlert({
            show: true,
            message: res?.data.message,
            errType: "failure",
          });
        }
      })
      .catch((e) => {
        setAlert({
          show: true,
          message: "Something went wrong",
          errType: "failure",
        });
        console.log(e);
      });
  }

  const [confirmAlertOptions, setConfirmAlertOptions] = useState({
    show: null,
    title: "We're sorry to see you go",
    description:"This deletion is final and there will be no way to recover your account if you choose to continue.",
  });

  const closeConfirmAlert = () => {
    // setLoader(false);
    setDeleteConText('')
    setConfirmAlertOptions({
      ...confirmAlertOptions,
      show: false,
    });
  };

  const openConfirmAlert = () => {
    setConfirmAlertOptions({
      ...confirmAlertOptions,
      show: true,
    });
  };

const handelDelete =()=>{
  try {
    window.google.accounts.id.revoke(profileData?.user_data.email, done => {
      console.log('consent revoked');
    });
  } catch (error) {
    console.log(error);
  }
  
setLoader(true)
  axios.post(Apistore.deactivateUser,{
  }).then((res)=>{
    if (res.data.status === 'success') {
      history.push({
        pathname: "/logout",
        state: {
          by: "code",
        },
      });
    }else if (res.data.status === 'error' ) {
        setLoader(false)
    }
  })
}

const login = useGoogleLogin({
  onSuccess: codeResponse =>updateScopeInDB(codeResponse, "Permissions added successfully"),
  // ux_mode:'redirect'
  // select_account:true,
  flow: 'implicit',
  scope:'https://www.googleapis.com/auth/calendar.events'
});


  return (
    <>
      <div style={{visibility:'hidden',height:'0px'}}>
        <GoogleLogin
          onSuccess={credentialResponse => {
          console.log(credentialResponse);
          }}
          onError={() => {
          console.log('Login Failed');
        }}
        />
      </div>
      <LayoutSplashScreen visible={loader} />
      {alert.show && (
        <AlertPopup
          show={alert.show}
          handleClose={handleClose}
          title={alert.title}
          message={alert.message}
          errType={alert.errType}
        />
      )}

<Modal className="time_machine-modal-small"
  show={confirmAlertOptions.show} 
  size="md"
  aria-labelledby="contained-modal-title-vcenter"
  centered>
  <Modal.Body className="text-center">
      <h4 className="modal-sm-title">{confirmAlertOptions.title}</h4>
      <p> {confirmAlertOptions.description} </p>
      <div className="delete-confirmation">
      <label className="delete-confirmation-label">Type "delete" to proceed:</label>
      <input type='text' onPaste={(e)=>{e.preventDefault()}} onChange={(e)=>{setDeleteConText(e.target.value)}} className="form-control form-control-solid form-control-lg" />
      </div>
      <div className="modal_btns d-flex align-items-center">
          <button disabled={deleteConText === 'delete' ? false : true} className="btn flex-grow-1 yes_delete" onClick={handelDelete} >Yes, delete my account</button>
          <button className="btn btn-primary flex-grow-1" onClick={closeConfirmAlert} >Stay</button>
      </div>
  </Modal.Body>
</Modal>
      <div className="profile-container flex-column h-100 d-flex">
        <div className="nav-container d-flex justify-content-between privacy_navigation">
          <Navbar getMainRoute={"privacy"} />
          <div className="edit-button">
            {disabled ? (
              <button className="btn btn-edit" onClick={handelEditButton}>
                Edit
              </button>
            ) : (
              <>
                <button
                  className="btn btn-edit mr-2"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
                <input
                  type="button"
                  ref={saveButtonRef}
                  className="btn btn-primary"
                  onClick={() => savePrivacyData()}
                  value="Save Changes"
                />
              </>
            )}
          </div>
        </div>
        <div className="tab-content bg-transparent flex-grow-1 privacy_profileTab" id="profileTab">
          <div className="card card-custom">
            <div className="card-body">
              <div
                className="tab-pane fade show active"
                id="Notifications"
                role="tabpanel"
                aria-labelledby="Notifications-tab"
              >
                <div className="privacy-wrap">
                  <div className="row">
                    <div className="col-xl-9 col-lg-10">
                      <p>
                        You are currently {optToggel} of disclosing your name in
                        team or company reports. Team or Company reports show:
                      </p>
                      <ul className="privacy--points">
                        <li>The total amount of time (in hours) your organized meetings take (inclusive of all participant time)</li>
                        <li>How many meetings you attend (and how many are internal vs. external)</li>
                        <li>How much time you spend in meetings (and what percentage of that time is internal vs. external)</li>
                      </ul>
                      <p>
                        Some MeetingScience members choose to disclose their names so that they can work with their manager to more accurately identify their meeting load and strain relative to their peers or function, and then proactively work
                        together to optimize their time for peak meeting performance.
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-auto mt-6">
                      <div className="card">
                        <div onKeyDown={handelKeyDown} className="card-body">
                          <h5 className="mb-6">Disclosing Name</h5>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input
                              ref={radioRef1}
                              className="custom-control-input"
                              disabled={disabled ? true : false}
                              onChange={() =>
                                setState({ ...state, discover_name: 0 })
                              }
                              type="radio"
                              name="options"
                              checked={state.discover_name === 0 ? true : false}
                              id="out"
                              value={0}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="out"
                            >
                              Opt-Out
                            </label>
                          </div>
                          <div className="custom-control custom-radio custom-control-inline">
                            <input ref={radioRef2} className="custom-control-input" disabled={disabled ? true : false} onChange={() => setState({ ...state, discover_name: 1 })} type="radio" name="options" checked={state.discover_name === 1 ? true : false} id="in" value={1} />
                            <label className="custom-control-label" htmlFor="in">
                              Opt-In
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {profileData?.user_data?.provider_id == '1'  ?

                    <div className="col-sm-12 mt-6">
                      <div className="card">
                        <div className="card-body">
                              <div className="meeting-feedback privacy-permission" >
                            <div className="Meeting-label" >
                          <h5 className="mb-6">Calendar Permission for the Meeting Shortener </h5>
                              <span className="help-block">
                              Meeting Shortener is one of the key features of SmartMeetings by MeetingScience. You can make modifications to the meetings like shortening them, spacing out meetings, etc.
                              <br/><br/>
                              In order to utilize the Meeting Shortener capability, you need to grant permission to the application to update your calendar and events. Your calendar data is safe, and secure, we do not use the write permissions to make any other kind of modifications to your calendar. 
                              <br/><br/>
                              </span>
                            </div>
                            <span
                              className="switch switch-sm ml-3"
                            >
                              <label title={isAllowed === 1 ? "Permission already granted":''}>
                                <input
                                  type="checkbox"
                                  disabled={isAllowed === 1 ? true : false}
                                  checked={isAllowed === 1 ? true : false}
                                  onChange={(e) => {
                                    login();
                                  }}
                                />
                                <span ></span>
                              </label>
                            </span>
                          </div>

                        </div>
                      </div>
                    </div>
                  : null}

                  </div>
                </div>
              </div>
            </div>

            {profileData?.user_data?.provider_id == '1'||profileData?.user_data?.provider_id == '1' ? <p className="delete_account_text_p"> <span className="delete_account_text" onClick={openConfirmAlert}> Delete Account</span></p>:''}

          </div>
        </div>
      </div>
    </>
  );
};
