import { put, takeLatest } from 'redux-saga/effects';
import { ERROR_LIST_ATTENDED_MEETINGS_DETAIL, ERROR_LIST_ORGANIZED_MEETINGS_DETAIL, ERROR_SAVE_ORGANIZED_MEETINGS_DETAIL, ERROR_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME, listAttendedMeetingsDetailResponse, listOrganizedMeetingsDetailResponse, LIST_ATTENDED_MEETINGS_DETAIL, LIST_ORGANIZED_MEETINGS_DETAIL, saveOrganizedMeetingsDetailResponse, SAVE_ORGANIZED_MEETINGS_DETAIL, sendAttendedMeetingsProposalTimeResponse, SEND_ATTENDED_MEETINGS_PROPOSAL_TIME, SUCCESS_LIST_ATTENDED_MEETINGS_DETAIL, SUCCESS_LIST_ORGANIZED_MEETINGS_DETAIL, SUCCESS_SAVE_ORGANIZED_MEETINGS_DETAIL, SUCCESS_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME } from './timeMachine.action';
import { listAttendedMeetingsDataApi, listOrganizedMeetingsDataApi, saveOrganizedMeetingsDataApi, sendAttendedMeetingsProposalTimeApi } from './timeMachine.api';
import _ from 'lodash';
import { sendApiError } from '../../../../app/modules/Auth/_redux/authCrud';
import { Apistore } from '../../../apiStore';

// List Organized Meetings Details
function* listOrganizedMeetingsDataRequest(data) {
    let getData = yield listOrganizedMeetingsDataApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(listOrganizedMeetingsDetailResponse(SUCCESS_LIST_ORGANIZED_MEETINGS_DETAIL, getData.data));
    } else {
        yield put(listOrganizedMeetingsDetailResponse(ERROR_LIST_ORGANIZED_MEETINGS_DETAIL, getData.data));
        sendApiError(500,getData.data,Apistore.ListTimeMachineOrganizedMeetingsData)
    }
}

export function* listOrganizedMeetingsDataWatcher() {
    yield takeLatest(LIST_ORGANIZED_MEETINGS_DETAIL, listOrganizedMeetingsDataRequest);
}

// List Attended Meetings Details
function* listAttendedMeetingsDataRequest(data) {
    let getData = yield listAttendedMeetingsDataApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(listAttendedMeetingsDetailResponse(SUCCESS_LIST_ATTENDED_MEETINGS_DETAIL, getData.data));
    } else {
        yield put(listAttendedMeetingsDetailResponse(ERROR_LIST_ATTENDED_MEETINGS_DETAIL, getData.data));
        sendApiError(500,getData.data,Apistore.ListTimeMachineAttendedMeetingsData)
    }
}

export function* listAttendedMeetingsDataWatcher() {
    yield takeLatest(LIST_ATTENDED_MEETINGS_DETAIL, listAttendedMeetingsDataRequest);
}

// Save Organized Meetings Details
function* saveOrganizedMeetingsDataRequest(data) {
    let getData = yield saveOrganizedMeetingsDataApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(saveOrganizedMeetingsDetailResponse(SUCCESS_SAVE_ORGANIZED_MEETINGS_DETAIL, getData.data));
    } else {
        yield put(saveOrganizedMeetingsDetailResponse(ERROR_SAVE_ORGANIZED_MEETINGS_DETAIL, getData.data));
        sendApiError(500,getData.data,Apistore.SaveTimeMachineOrganizedMeetingsData)
    }
}

export function* saveOrganizedMeetingsDataWatcher() {
    yield takeLatest(SAVE_ORGANIZED_MEETINGS_DETAIL, saveOrganizedMeetingsDataRequest);
}

// Send Proposal Time Attended Meetings Details
function* sendAttendedMeetingsProposalTimeRequest(data) {
    let getData = yield sendAttendedMeetingsProposalTimeApi(data.data);
    if (getData.status === 200 && _.has(getData, 'data')) {
        yield put(sendAttendedMeetingsProposalTimeResponse(SUCCESS_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME, getData.data));
    } else {
        yield put(sendAttendedMeetingsProposalTimeResponse(ERROR_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME, getData.data));
        sendApiError(500,getData.data,Apistore.SendTimeMachineAttendedProposeTime)
    }
}

export function* sendAttendedMeetingsProposalTimeWatcher() {
    yield takeLatest(SEND_ATTENDED_MEETINGS_PROPOSAL_TIME, sendAttendedMeetingsProposalTimeRequest);
}