import { call, put, takeLatest } from 'redux-saga/effects';
import { sendApiError } from '../../../../app/modules/Auth/_redux/authCrud';
import { Apistore } from '../../../apiStore';
import { RECEIVE_BADGE_SUMMARY, RECEIVE_HIGHEST_LEVEL_ACHEIVED, RECEIVE_MASTERY_LEVELS_LIST, RECEIVE_USER_PROGRESS_SUMMARY, REQUEST_BADGE_SUMMARY, REQUEST_HIGHEST_LEVEL_ACHEIVED, REQUEST_MASTERY_LEVELS_LIST, REQUEST_USER_PROGRESS_SUMMARY, RESET_USER_PROGRESS_STATE } from './mastery.action';
import { fetchBadgeSummary, fetchHigestLevel, fetchMasteryList, fetchUserProgress } from './mastery.crud';

function* getMasteryList(action) { 
    try{
            const result = yield call (fetchMasteryList,action.payload);
            yield put({type:RECEIVE_MASTERY_LEVELS_LIST,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetMeetingMasteryLevelsList)
    }
}

function* getHighestLevel(action) { 
    try{
            const result = yield call (fetchHigestLevel,action.payload);
            yield put({type:RECEIVE_HIGHEST_LEVEL_ACHEIVED,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetHighestlevelAcheived)
    }
}

function* getUserProgress(action) { 
        yield put({type:RESET_USER_PROGRESS_STATE,data:null})
    try{
            const result = yield call (fetchUserProgress,action.payload);
            yield put({type:RECEIVE_USER_PROGRESS_SUMMARY,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetUserProgressSummary)
    }
}

function* getBadgeSummary(action) { 
    try{
            const result = yield call (fetchBadgeSummary,action.payload);
            yield put({type:RECEIVE_BADGE_SUMMARY,data:result.data})

    } catch (e) {
        sendApiError(500,e,Apistore.GetUserBadgeSummary)
    }
}

export function* meetingMasteryWatcher() {
    yield takeLatest(REQUEST_MASTERY_LEVELS_LIST, getMasteryList);
    yield takeLatest(REQUEST_HIGHEST_LEVEL_ACHEIVED, getHighestLevel);
    yield takeLatest(REQUEST_BADGE_SUMMARY,getBadgeSummary);
    yield takeLatest(REQUEST_USER_PROGRESS_SUMMARY,getUserProgress);
}