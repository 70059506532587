/* eslint-disable */ 

/* eslint-disable eqeqeq, no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from 'react-apexcharts'
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { FilterRange } from "../../layout/components/header/FilterLabel";
import { MeetingSummaryModal } from ".";
import { logActivity } from "../../../app/modules/Auth/_redux/authCrud";

export function MeetingsTimeConsuming({ className }) {

  const {events,code,initialState} = useSelector(state => state.attended)
  // const [meetingModal, setMeetingModal] = useState(false);
  // const [meetingSummary,setSummary] = useState();
  const [eventsData,setEventsData] = useState([]);
  
  const [state,setState] = useState({
    series:[],
    options: {
      states: {

        hover: {
            filter: {
                type: 'none',

            }
        }
    },
      grid: {
        show: true,
        borderColor: '#f8f9fb',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
            lines: {
                show: true
            }
        },   
        yaxis: {
            lines: {
                show: true
            }
        },  
        row: {
            colors: undefined,
            opacity: 0.5
        },  
        column: {
            colors: undefined,
            opacity: 0.5
        },  
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
        },  
    },
      noData: {
        text: undefined,
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      },
      dataLabels:{
        enabled:false
      },
      legends:{
        show:false
      },
        colors: [function({ value, seriesIndex, w }) {

          if(value >= 0 && value <= 15) {
              return '#D3F5F9'
          } else if (value > 15 && value <= 25) {
              return '#8FE0E9'
          }else if (value > 25 && value <= 30) {
            return '#4ACBDA'
        }
        else if (value > 30 && value <= 45) {
          return '#00ACBF'
      }
      else if (value > 45 && value <= 50) {
        return '#008E9D'
    }
    else if (value > 50 && value <= 60) {
      return '#00626C'
  }else if (value>60 && value <= 80) {
    return '#02444C'
  }else{
    return "#02444C"
  }
        }],
      chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: true,
          tools:{
            download:true // <== line to add
          }
        },
        events: {
          // click: function(event, chartContext, config) {
          //   if(config.seriesIndex !== -1 && config.dataPointIndex !== -1){
          //     let event_title_hashed = config.globals.initialSeries[config.seriesIndex].name;
          //     let event_id = event_title_hashed.split('#');
          //      handleSummaryModal(event_id[0]);
          //   }
          // },
          dataPointMouseEnter: function(event) {
            try {
              // event.path[0].style.cursor = "pointer";
            } catch (error) {
              console.log(error);
            }
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      yaxis:{
        labels: {
          formatter: function(val) {
            return val
          }
        },
        title: { 
          text: '',
          style: {
            fontSize:  '12px',
            fontWeight:  'normal',
            fontFamily:  undefined,
            color:  '#ddd'
          },
        }
      },
      xaxis: {
        categories:['Other','120 Min','90 Min','80 Min','60 Min','50 Min','45 Min','30 Min','25 Min','15 Min'],
        labels: {
          formatter: function(val) {
            return (val / 60).toFixed(2);
          }
        },
        title: { 
          text: 'Meeting Duration Sum ( in hours )',
          style: {
            fontSize:  '12px',
            fontWeight:  'normal',
            fontFamily:  undefined,
            // color:  'rgb(182, 182, 182)',
            color:  '#999EA5'
          },
        }
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function(val) {
            return (val) + " min";
          },
          title:{
            formatter : function(seriesName) {
                  var title = seriesName.split('#');
                return  title[1];
            },
          },
        },
        x:{
          show: false,
        },
        marker: {
          show: false,
        },
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "top",
        show:false,
        horizontalAlign: "left",
        offsetX: 40
      }
    }
  });

 
  let flag = 0;  

  const handleSummaryModal = (id) => {
      logActivity('modal','/meeting-summary',id,window.location.pathname);
    const event_index =  eventsData.findIndex((i) => i.event_id == id);  
    if(event_index !== -1){
      setSummary(eventsData[event_index]);
      setMeetingModal(true);
    }
}

useEffect(() => {
    
  let seriesData = []

  events && events.map((i) => {
    eventsData.push(i);
    let title = i.event_title !== null ? i.event_title : 'N/A'
    let time = i.event_duration; 
    time = (time * 60).toFixed(0);
          if( time <= 15 ){
            seriesData.push({name:i.event_id  + "# " + title +  '-' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,0,0,0,0,0,0,0,15],id:i.event_id})    
          }else if( time == 25  ){
            seriesData.push({name:i.event_id  + "# " + title +  '-' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,0,0,0,0,0,0,25,0],id:i.event_id})
          }else if(time == 30) {
            seriesData.push({name:i.event_id  + "# " + title +  '-' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,0,0,0,0,0,30,0,0],id:i.event_id})
          }else if(time == 45){
            seriesData.push({name:i.event_id  + "# " + title +  '-' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,0,0,0,0,45,0,0,0],id:i.event_id})
          }else if( time == 50 ) {
            seriesData.push({name:i.event_id  + "# " + title +  '-' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,0,0,0,50,0,0,0,0],id:i.event_id})
          }else if(time == 60 ){
            seriesData.push({name:i.event_id  + "# " + title +  '-' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,0,0,60,0,0,0,0,0],id:i.event_id})
          }else if(time == 80 ) {
            seriesData.push({name:i.event_id  + "# " + title +  '-' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,0,80,0,0,0,0,0,0],id:i.event_id})
          }else if( time == 90) {
            seriesData.push({name:i.event_id  + "# " + title +  '-' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,0,90,0,0,0,0,0,0,0],id:i.event_id})
          }else if( time ==120 ){
            seriesData.push({name:i.event_id  + "# " + title +  '-' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[0,120,0,0,0,0,0,0,0,0],id:i.event_id})
          }else{
            seriesData.push({name:i.event_id  + "# " + title +  '-' + moment(i.event_start_datetime).format('MM/DD') + ' ' ,data:[time,0,0,0,0,0,0,0,0,0],id:i.event_id})
          }
          
        ++flag;
        if(flag == events.length){
          setState({
            ...state,
            series:seriesData
          })
      }
        
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[events]);


  return (
    <>
      <div className={`card card-custom  ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">What meetings are most time-consuming for me?</span>
          </h3>
          <div className="card-subtitle d-flex justify-content-between">
            <h5>Summary meetings duration for the {<FilterRange/>}</h5>
          </div>
        </div>
          <div className="card-body">
            {events &&  initialState !== null  ? 
                 <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />     
              :  
              !code ?  
              <div className='loader-container'>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden"></span>
                </Spinner> 
              </div>
            :
            <div>We're sorry, for the time you requested, we don't have any data to share with you.</div>
          }
       
           </div>
       </div> 
       {/* {meetingModal && <MeetingSummaryModal data = {meetingSummary} show={meetingModal} handleClose={() => setMeetingModal(false)} />} */}
    </>
  );
}
