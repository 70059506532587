/* eslint-disable */ 

import axios from "axios";
import { Apistore } from "../../../../redux/apiStore";
import { setStorage } from "../../../../_metronic/_helpers";
import { authUserId, getLoginType, getServiceProvider, getUserAgent } from "../../../../_metronic/_helpers/functions";

export async function tryLogin(code,serviceProvider,masterToken){
  
  
  if(serviceProvider == 1){
    setStorage('LOGIN_TYPE', 0);
    return await axios.post(Apistore.GoogleLoginURL, { code:code });
  }else if(serviceProvider == 2){
    setStorage('LOGIN_TYPE',0);
    return await axios.post(Apistore.MicrosoftLoginURL, null ,{ 
      params:{
        code:code 
      }
      });
  }else if(serviceProvider == null && masterToken !== null){
    setStorage('LOGIN_TYPE',1);
    // setStorage('ACCESS_TOKEN',masterToken)
    return await axios.post(Apistore.MasterLogin, null);
  }
}

export async function fetchBearerToken(email,password){
  return await axios.post(Apistore.BearerTokenUrl,{email:email,password:password})
} 

export async function getTimezones(country){
  return await axios.post(Apistore.Timezone,null,{params:{country_name:country}  });
}

export async function getUserEvents(data){
  return await axios.post(Apistore.GetUserEvents,data);
}

export async function getEventsData(user_id,min,max){
  return await axios.post(Apistore.GetSignupEventData,{
    //  user_id:user_id,
    sdate:min,
    edate:max
  });

}


export async function getUserRelations(user_id,user_email){
  return await axios.post(Apistore.FetchUserRelations,null,{
    params:{
      // user_id:user_id,
      user_email:user_email
      
    }  
  });
}


export async function addGoogleCalendarEvents(data){
  return await axios.post(Apistore.SaveGoogleEvents,{events:data});
  // return await axios.post(Apistore.SaveGoogleEvents,{user_id:window.atob(localStorage.getItem('USER_ID')),events:data});
}

export async function addMicrosoftCalendarEvents(data){
  return await axios.post(Apistore.SaveMicrosoftEvents,{events:data});
  // return await axios.post(Apistore.SaveMicrosoftEvents,{user_id:window.atob(localStorage.getItem('USER_ID')),events:data});
}

export async function getUserInfo(user_id){
  return await axios.post(Apistore.GetUserData, {  })
              .then((res) => {
                setStorage('USER_DATA',JSON.stringify(res.data.user_data))
              })
              .catch((err) =>{
                console.log(err)
              });
}


export async function sendSignupNotifications(data){
  return await axios.post(Apistore.TriggerSignupNotification,data);
}

export async function logActivity (activityType,pageName,event_id,origin) {
  if(process.env.REACT_APP_PUBLIC_URL == 'https://app.meetingscience.io'){ 
    axios.post(Apistore.UserLogAPI,null,
     {
       params:{
        //  user_id:authUserId(),
         action:activityType,
         page_name:pageName,
         user_agent:getUserAgent(),
         event_id:event_id,
         origin:origin,
         is_master_login:getLoginType()                         
       }
     }).catch(err => {
       // this will send an error report into database
       sendApiError(500,err,Apistore.UserLogAPI)
     })
    
    }
}

export async function sendApiError (statusCode,status_response,module_name){
   axios.post(Apistore.ErrorLogApi,{
    // user_id:authUserId(),
    service_provider:getServiceProvider(),
    user_agent:getUserAgent(),
    status_code:statusCode,
    status_response:status_response,
    module_name:module_name
   }) 
}
