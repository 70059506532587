import React, { useState, useEffect } from "react";
// import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileInfoDetail,
  updateUserRelationsDetail,
  updateCutomUserRelationsDetail,
  updateCustomUserRelationsDetailResponse,
  SUCCESS_CUSTOM_UPDATE_USER_RELATIONS_DETAIL,
} from "../../../../redux/duck/userProfile/userProfile.action";
import { usePrevious } from "../../../_helpers/Custom";
import { authUserId } from "../../../_helpers/functions";
import { Navbar } from "./Navbar";
import _ from "lodash";
import { AlertPopup } from "../../../_helpers/Alerts";
import { LayoutSplashScreen } from "../../../layout";
import './dashboard.css'

export const Relationships = () => {
  // set initial states and values

  // const user_id = authUserId();
  const dispatch = useDispatch();
  const profileData = useSelector(
    (state) => state.profile.getProfileInfoDetailData
  );
  const customprofileData = useSelector(
    (state) => state.profile.getCustomProfileInfoDetailData
  );

  const prevProfileData = usePrevious({ profileData });
  const updateProfileRelationsData = useSelector(
    (state) => state.profile.updateUserRelationsData
  );
  const prevProfileRelationsData = usePrevious({ updateProfileRelationsData });
  // Loader State
  // const [SelectList, setSelectList] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState("");
  // const [data, setData] = useState([]); //  Api Data will be stored here
  // const [optionsss, setOptionsss] = useState([{ null: null }]); //this options wiil be shown in select box and it getting data from data
  const [text, setText] = useState(""); // this is used in handelSubmit function
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [alert, setAlert] = useState({
    show: false,
    title: null,
    message: null,
    errType: null,
  });
  const [userRelationList, setUserRelationList] = useState([]);

  // relation messages
  const messages = {
    relationsFound:
      "We’ve noticed that you’ve met with the following people recently and frequently. Can you tell us if they are peers, your manager, or your direct report/s?",
    relationsNotFound:
      "We’ve noticed that you’ve not met with people recently and frequently.",
  };

  //pass id as param
  useEffect(() => {
    setLoader(true);
    dispatch(getProfileInfoDetail({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch Relationship data and handle error popup
  useEffect(() => {
    if (prevProfileData && prevProfileData.profileData !== profileData) {
      if (
        profileData &&
        _.has(profileData, "user_relation_list") &&
        profileData.status === "success"
      ) {
        const userRelationData = profileData.user_relation_list;
        setUserRelationList(userRelationData);
        setLoader(false);
      }
      if (
        profileData &&
        _.has(profileData, "message") &&
        profileData.status === "error"
      ) {
        if (profileData.open_popup === false) {
          setAlert({
            show: true,
            message: "Something went wrong!",
            title: "Error",
            errType: "failure",
          });
          setLoader(false);
        }
      }
    }
    if (
      prevProfileRelationsData &&
      prevProfileRelationsData.updateProfileRelationsData !==
        updateProfileRelationsData
    ) {
      if (
        updateProfileRelationsData &&
        _.has(updateProfileRelationsData, "message") &&
        updateProfileRelationsData.status === "success"
      ) {
        dispatch(getProfileInfoDetail({}));
        setLoader(false);
        setDisabled(true);
      }
      if (
        updateProfileRelationsData &&
        _.has(updateProfileRelationsData, "message") &&
        updateProfileRelationsData.status === "error"
      ) {
        if (updateProfileRelationsData.open_popup === false) {
          setLoader(false);
          setAlert({
            show: true,
            message: "Something went wrong!",
            title: "Error",
            errType: "failure",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prevProfileData,
    profileData,
    prevProfileRelationsData,
    updateProfileRelationsData,
  ]);

  // handle Close button on popup
  const handleClose = () => {
    setAlert({
      show: false,
    });
  };

  // handel submit button (this function will get call throw redux if respounce api not empty)
  const hitCancel = async () => {
    const res = await customprofileData;
    if (res?.status === "success") {
      saveUserRelationsData();
      setSelectedEmail("");
    } else if (res?.status === "error" && !disabled ) {
      setText(res?.message);
    } else {
      return null;
    }
  };

  useEffect(() => {
    hitCancel();
    return setText("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customprofileData]);

  useEffect(() => {
    return () => {
      setText('')
    }
  },[] )
  
  useEffect(() => {
   setText('')
  },[] )

  useEffect(() => {
    dispatch(
      updateCustomUserRelationsDetailResponse(
        SUCCESS_CUSTOM_UPDATE_USER_RELATIONS_DETAIL,
        null
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle Cancel button
  const handleCancel = () => {
    setText("");
    setDisabled(true);
    setLoader(true);
    setText("");
    dispatch(getProfileInfoDetail({}));
  };

  // handle relation input data
  const handleRelationInput = (e) => {
    let { name, value } = e.target;
    const List = userRelationList.map((el) => {
      if (el.id === parseInt(name)) {
        // eslint-disable-next-line eqeqeq
        if (el.relationship_user_status == 0) {
          el.relationship_user_status = parseInt(value);
        } else if (el.relationship_user_status !== parseInt(value)) {
          el.relationship_user_status = parseInt(value);
        } else {
          el.relationship_user_status = 0;
        }
        return el;
      } else {
        return el;
      }
    });
    setUserRelationList(List);
  };

  const saveUserRelationsData = () => {
    setText("");
    let relation_users_list = [];
    userRelationList.map((value, key) => {
      return (relation_users_list[key] = {
        ...relation_users_list[key],
        relationship_status: value.relationship_user_status,
        relationship_user_id: value.id
      });
    });
    if (relation_users_list && relation_users_list.length > 0) {
      const relationsData = { relation_users_list };
      setLoader(true);
      dispatch(updateUserRelationsDetail(relationsData));
    }
  };

  // handel edit button of top right corner
  const handelEditButton = () => {
    setDisabled(false);
   setSelectedEmail('')
  };

  // post api for select box
  // const getApi = (value) => {
  //   const apiUrl = "user/search_user";
  //   axios
  //     .post(apiUrl, {
  //       // user_id: authUserId(),
  //       email: value,
  //     })
  //     .then((res) => {
  //       setData(res.data.users);
  //     });
  // };

  // putting options in select box which will be shown after edit button cliked
  // useEffect(() => {
  //   let temp = [];
  //   if (data && data.length) {
  //     data.map((element) => {
  //       temp.push({
  //         label: element.user_email,
  //         // value: element.user_id,
  //       });
  //     });
  //     setOptionsss(temp);
  //   }
  // }, [data]);

  // handel Submit Button
  const handelSubmit = () => {
    const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
    const tSelectedEmail = selectedEmail.trim();

    if (tSelectedEmail !== "") {
      if (tSelectedEmail) {
        emailRegex.test(tSelectedEmail);
        if (emailRegex.test(tSelectedEmail)) {
          const data = {
            user_id: authUserId(),
            custom_relationship_email: tSelectedEmail ? tSelectedEmail : null,
            relationship_user_id: null,
            relationship_user_status: 1,
          };

          dispatch(updateCutomUserRelationsDetail(data));
        } else {
          setText("Please enter a valid format of email.");
          setTimeout(() => {
            setText("");
          }, 3000);
        }
      } else {
        const data = {
          user_id: authUserId(),
          custom_relationship_email: tSelectedEmail ? tSelectedEmail : null,
          relationship_user_id: null,
          relationship_user_status: 1,
        };
        dispatch(updateCutomUserRelationsDetail(data));
      }
    } else {
      setText("Please enter the email.");
      setTimeout(() => {
        setText("");
      }, 3000);
    }
    // setTimeout(() => {
    // setSelectedEmail("")
    // }, 1000);
  };

  

  // const handleChange = (newValue) => {
  //   setSelectedEmail(newValue);
  // };

  // const handleInputChange = (inputValue, actionMeta) => {
  //   getApi(inputValue);
  //   setText("");
  // };

  // const [checked, setChecked] = useState(true)

  return (
    <>
      <LayoutSplashScreen visible={loader} />
      {alert.show && (
        <AlertPopup
          show={alert.show}
          handleClose={handleClose}
          title={alert.title}
          message={alert.message}
          errType={alert.errType}
        />
      )}
      <div className="profile-container flex-column h-100 d-flex">
        <div className="nav-container d-flex justify-content-between relationship_navigation">
          <Navbar getMainRoute={"relationship"} />

          <div className="edit-button">
            {disabled ? (
              <button className="btn btn-edit" onClick={handelEditButton}>
                Edit
              </button>
            ) : (
              <>
                <button
                  className="btn btn-edit mr-2"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => saveUserRelationsData()}
                >
                  Save Changes
                </button>
              </>
            )}
          </div>
        </div>
        <div className="tab-content bg-transparent flex-grow-1 relationship_profileTab" id="profileTab">
          <div className="card card-custom">
            <div className="card-body">
              <div
                className="tab-pane fade show active"
                id="Relationships"
                role="tabpanel"
                aria-labelledby="Relationships-tab"
              >
                <div className="relationship-wrap">
                  <div className="form-group mb-0">
                    <p>
                      {userRelationList.length
                        ? messages.relationsFound
                        : messages.relationsNotFound}
                    </p>
                    {userRelationList.length ? (
                      userRelationList &&
                      _.map(userRelationList, (user, key) => {
                        return (
                          <div key={key}>
                            <div  className="rel--id text-primary mb-4">
                              {
                              user.user_firstname ? user.user_firstname + " " + user.user_lastname
                              :user.email.length>=35 ? user.email :user.email
                              }
                            </div>
                            <div className="auth-selectgroup">
                              <label className="auth-selectgroup-item">
                                <input
                                  className="selectgroup-input"
                                  disabled={disabled ? true : false}
                                  type="checkbox"
                                  // onClick={()=>setChecked(!checked)}
                                  name={user.id}
                                  // name="Manager"
                                  onChange={(e) => handleRelationInput(e)}
                                  checked={
                                    user.relationship_user_status === 1
                                      ? true
                                      : false
                                  }
                                  id="Manager"
                                  value={1}
                                />
                                <span style={{cursor: disabled ?'unset':'pointer'}} className="selectgroup-button">
                                  Manager
                                </span>
                              </label>
                              <label className="auth-selectgroup-item">
                                <input
                                  className="selectgroup-input"
                                  disabled={disabled ? true : false}
                                  // type="radio"
                                  type="checkbox"
                                  // name="Peers"
                                  name={user.id}
                                  onChange={(e) => handleRelationInput(e)}
                                  checked={
                                    user.relationship_user_status === 2
                                      ? true
                                      : false
                                  }
                                  id="Peers"
                                  value={2}
                                />
                                <span style={{cursor: disabled ?'unset':'pointer'}} className="selectgroup-button">
                                  Peers
                                </span>
                              </label>
                              <label className="auth-selectgroup-item">
                                <input
                                  className="selectgroup-input"
                                  disabled={disabled ? true : false}
                                  // type="radio"
                                  type="checkbox"
                                  // name="DirectReports"
                                  name={user.id}
                                  onChange={(e) => handleRelationInput(e)}
                                  checked={
                                    user.relationship_user_status === 3
                                      ? true
                                      : false
                                  }
                                  id="DirectReports"
                                  value={3}
                                />
                                <span style={{cursor: disabled ?'unset':'pointer'}} className="selectgroup-button">
                                  Direct Reports
                                </span>
                              </label>
                              <label className="auth-selectgroup-item">
                                <input
                                  className="selectgroup-input"
                                  disabled={disabled ? true : false}
                                  // type="radio"
                                  type="checkbox"
                                  // name="Others"
                                  name={user.id}
                                  onChange={(e) => handleRelationInput(e)}
                                  checked={
                                    user.relationship_user_status === 4
                                      ? true
                                      : false
                                  }
                                  id="Others"
                                  value={4}
                                />
                                <span style={{cursor: disabled ?'unset':'pointer'}} className="selectgroup-button">
                                  Others
                                </span>
                              </label>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>No Relationships Found</div>
                    )}

                    <div style={{ paddingTop: "20px" }}>
                      {!disabled && (
                        <div>
                          <div
                            style={{
                              width: "26vw",
                            }}
                          >
                            <p>
                              Can't find your manager ? Please enter his/her
                              email and let us know.
                            </p>
                          </div>

                          <div>
                            <div
                              style={{
                                float: "left",
                                width: "26vw",
                                marginRight: "10px",
                                height: "50px",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control form-control-solid form-control-lg"
                                name="selectedEmail"
                                value={selectedEmail}
                                placeholder="Enter Email"
                                onChange={(e) =>
                                  setSelectedEmail(e.target.value)
                                }
                              />

                              {/* <CreatableSelect
                                isClearable
                                onChange={(value) => handleChange(value)}
                                onInputChange={(value) => handleInputChange(value)}
                                options={optionsss ? optionsss : null}
                                setValue={selectedEmail}
                              /> */}
                            </div>

                            <div>
                              <button
                                style={{
                                  height: "52px",
                                  width: "80px",
                                }}
                                className="btn btn-primary"
                                onClick={() => handelSubmit()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      <p
                        style={{
                          color: "red",
                          marginTop: "12px",
                        }}
                      >
                        {text}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
