/* eslint-disable */ 
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect,useState } from "react";
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';

export function GenderOverlapReport({type}) {
        
   // const {summary_data} = useSelector(state => state.company);

    const {companyData} = useSelector(state => state.company);
    const organizedData = useSelector(state => state.organized);

    // const [state,setState] = useState({
    //     series: [],
    //     options: {
    //         chart: {
    //             toolbar: {
    //                 show: false
    //             },
    //             dropShadow: {
    //                 enabled: false,
    //             },
    //         },
    //         plotOptions: {
    //             pie: {
    //                 startAngle: 90,
    //                 endAngle: 360,
    //                 expandOnClick: false,
    //                 customScale: 1,
    //             }
    //         },
    //         dataLabels: {
    //             enabled: false,
    //         },
    //         legend: {
    //             show: false
    //         },
    //         states: {
    //             hover: {
    //                 filter: {
    //                     type: 'none',
    //                 }
    //             },
    //         },
    //         stroke: {
    //             width: 0,
    //         },
    //         tooltip: {
    //             enabled: true,
    //             custom: function({series, seriesIndex,w}) {
    //                 // console.log(w);                    
    //                 return '<div class="arrow_box p-2" style="width:auto">' +
    //                   '<span>' + w.config.labels[seriesIndex] + " : " + series[seriesIndex] + '%</span>' +
    //                   '</div>'
    //               }
    //         },
    //         colors: ['#00626C', '#4ACBDA'],
    //         labels: ['Male','Female'],
    //     }
    // });

//---------------------------------------------------------------------------
const [state, setState] = useState({
    series: [],
    options: {
      
      chart: {
        width: 380,
        type: "pie"
      },

      dataLabels: {
        enabled: false
      },
      plotOptions: {
                    pie: {
                        // startAngle: 90,
                        // endAngle: 360,
                        expandOnClick: false,
                    }
                },
      legend: {
        show: false
      },
      responsive: [
        {
        //   breakpoint: 480,
          options: {

            chart: {
              width: 200
            },
            stroke: {
              width: 0
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      colors: ['#00626C', '#4ACBDA'],
      labels: ['Male','Female'],
    }
  });

useEffect(() => {

    if(type === "company" && companyData!== null){ 
    companyData && companyData.summary_data &&
    setState({
        ...state,
        series:[companyData.summary_data.male_percentage,companyData.summary_data.female_percentage]
    })
} 
else if (type === "organized" && organizedData !== null) {
    organizedData && organizedData.organized && 
    setState({
        ...state,
        series:[organizedData.organized.org_summery.male_percentage,organizedData.organized.org_summery.female_percentage]
    })
}

},[companyData, organizedData])

    return (
        <>
    <ReactApexChart
        options={state.options}
        series={state.series}
        type="pie"
        width={100}
      />
        </>
    );
}
