// List Organized Meetings Details
export const LIST_ORGANIZED_MEETINGS_DETAIL = "LIST_ORGANIZED_MEETINGS_DETAIL";
export const listOrganizedMeetingsDetail = (data) => ({ type: LIST_ORGANIZED_MEETINGS_DETAIL, data });
export const SUCCESS_LIST_ORGANIZED_MEETINGS_DETAIL = 'SUCCESS_LIST_ORGANIZED_MEETINGS_DETAIL';
export const ERROR_LIST_ORGANIZED_MEETINGS_DETAIL = 'ERROR_LIST_ORGANIZED_MEETINGS_DETAIL';
export const listOrganizedMeetingsDetailResponse = (type, data) => ({ type, data });

// List Attended Meetings Details
export const LIST_ATTENDED_MEETINGS_DETAIL = "LIST_ATTENDED_MEETINGS_DETAIL";
export const listAttendedMeetingsDetail = (data) => ({ type: LIST_ATTENDED_MEETINGS_DETAIL, data });
export const SUCCESS_LIST_ATTENDED_MEETINGS_DETAIL = 'SUCCESS_LIST_ATTENDED_MEETINGS_DETAIL';
export const ERROR_LIST_ATTENDED_MEETINGS_DETAIL = 'ERROR_LIST_ATTENDED_MEETINGS_DETAIL';
export const listAttendedMeetingsDetailResponse = (type, data) => ({ type, data });

// save Organized reduce duration Meetings Details
export const SAVE_ORGANIZED_MEETINGS_DETAIL = "SAVE_ORGANIZED_MEETINGS_DETAIL";
export const saveOrganizedMeetingsDetail = (data) => ({ type: SAVE_ORGANIZED_MEETINGS_DETAIL, data });
export const SUCCESS_SAVE_ORGANIZED_MEETINGS_DETAIL = 'SUCCESS_SAVE_ORGANIZED_MEETINGS_DETAIL';
export const ERROR_SAVE_ORGANIZED_MEETINGS_DETAIL = 'ERROR_SAVE_ORGANIZED_MEETINGS_DETAIL';
export const saveOrganizedMeetingsDetailResponse = (type, data) => ({ type, data });

// Send Proposal time for Attended Meetings.
export const SEND_ATTENDED_MEETINGS_PROPOSAL_TIME = "SEND_ATTENDED_MEETINGS_PROPOSAL_TIME";
export const sendAttendedMeetingsProposalTime = (data) => ({ type: SEND_ATTENDED_MEETINGS_PROPOSAL_TIME, data });
export const SUCCESS_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME = 'SUCCESS_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME';
export const ERROR_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME = 'ERROR_SEND_ATTENDED_MEETINGS_PROPOSAL_TIME';
export const sendAttendedMeetingsProposalTimeResponse = (type, data) => ({ type, data });