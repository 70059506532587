/* eslint-disable */ 
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React,{useEffect,useState} from "react";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { requestUserRating, requestUserRatingAttributes } from "../../../redux/duck/dashboard/organized/organized.action";
import { authUserId } from "../../_helpers/functions";
import { Spinner } from "react-bootstrap";
import { FilterRange } from "../../layout/components/header/FilterLabel";
import { RatedMeetings } from "./widget42";
import './widgets.css'

export function FeedbackSummary() {

  const [positiveAttr,setPositiveAttr] = useState([]);
  const [negetiveAttr,setNegetiveAttr] = useState([]);
  const [height,setHeight] = useState(0);
  const [cursor,setCursor] = useState('');

  const {organized,rating,attributes,status} = useSelector(state => state.organized)
  const dispatch = useDispatch();

  const popover = (
    <Popover id="popover-basic" className="mr-4">
      <Popover.Content className="p-2">
        <div className="badge--card">
          <div className="badge--card-body">

        {positiveAttr.length>5 && positiveAttr.slice(5,positiveAttr.length).map((i,j) =>
          <span key={j} className="label label-lg label-light-primary mr-2 label-inline">{i.title}</span>
            )
        }

          </div>
        </div>
      </Popover.Content>
    </Popover>
  );

  const popover2 = (
    <Popover id="popover-basic" className="mr-4">
      <Popover.Content className="p-2">
        <div className="badge--card">
          <div className="badge--card-body">

        {negetiveAttr.length>5 && negetiveAttr.slice(5,negetiveAttr.length).map((i,j) =>
          <span key={j} className="label label-lg label-light-danger mr-2 label-inline">{i.title}</span>
            )
        }

          </div>
        </div>
      </Popover.Content>
    </Popover>
  );


useEffect(() => {
  setPositiveAttr([]);
  setNegetiveAttr([]);  

  if(organized && organized.user_event_ids && organized.user_event_ids.length && organized.userData){
    const data = {event_ids:organized.user_event_ids,organizer_id:organized.userData.user_id };
    dispatch(requestUserRating(data));
    dispatch(requestUserRatingAttributes(data));
  }
  
  if (organized && organized.status == 'success' && organized.org_summery.total_rated_meetings) {
    setCursor('pointer')
  }else{
    setCursor('')
  }

}, [organized])


useEffect(() => {

  attributes && attributes.status == 'success' && attributes.meeting_attributes.low.rating_attributes.length !==0  &&
      setNegetiveAttr(attributes.meeting_attributes.low.rating_attributes);

  attributes && attributes.status == 'success' && attributes.meeting_attributes.high.rating_attributes.length !==0  &&
      setPositiveAttr(attributes.meeting_attributes.high.rating_attributes);
      
},[attributes])

const handleClick =()=>{
if (organized && organized.status == 'success' && organized.org_summery.total_rated_meetings) {
  height === 0 ? setHeight(14) : setHeight(0)
}
}

  return (
    <div className="card mb-4 card-custom">
      {/* Header */}
      <div className="card-header align-items-center border-0">
        <h3 className="card-title align-items-center d-flex w-100 justify-content-between"><span className="font-weight-bolder text-dark">My Feedback Summary</span></h3>
        <div className="card-subtitle d-flex justify-content-between">
                <h5>Your feedback summary for <FilterRange/></h5>
                <div className="card-subtitle-btn">
                    {/* <a className="btn btn-sm btn-light">You</a>
                    <a className="btn btn-sm btn-light-primary">Other people</a> */}
                </div>
            </div>
      </div>
    

      {/* Body */}
      {organized  ? 
      <div className="card-body position-relative overflow-hidden">
        <table className="table feedback--list mt-3">
          <tbody>
            <tr style={{height:'43px'}}>
              <td>Total Meetings Organized</td>
              <td className="value">
                {/* {!organized && <Spinner animation="border" role="status" ><span className="visually-hidden"></span></Spinner> } */}
                {organized && organized.status =='error' && 'N/A' }
                {status =='error' && 'N/A' }
                {organized && organized.status =='success' &&  organized.org_summery.total_meeting  }
              </td>
            </tr>
            <tr style={{height:'43px',cursor:cursor}} onClick={()=>{handleClick()}}>
              <td>Total Meetings Rated 
                
                {organized && organized.status == 'success' && organized.org_summery.total_rated_meetings > 0 ?
                  height > 0 ? <span><i class="org_rated_arrow org_rated_arrow_up"></i></span>
                  : <span><i class="org_rated_arrow org_rated_arrow_down"></i></span>
                  :''
                }
              </td>
            
              <td className="value">  
                  {organized && organized.status =='error' && 'N/A' }  
                  {status =='error' && 'N/A' }
                  {organized && organized.status == 'success' && organized.org_summery.total_rated_meetings }
                  </td>
            </tr>
            {/* <tr>
              <td>My MeetingScience Score</td>
              <td className="value">4.8</td>
              </tr>
              <tr>
              <td>Total MeetingScience Rank</td>
              <td className="value">27</td>
            </tr> */}
          </tbody>
        </table>
            <div style={{maxHeight:height+'rem',transition:'0.6s' , overflow: 'auto'}}>
                <RatedMeetings />
            </div> 
        <div className="badge--card mt-4">
          <div className="badge--card-title">Positive Meeting Attributes</div>
          <div className="badge--card-body">
          
          {/* {positiveAttr.length == 0 && !attributes && !organized  ? <Spinner animation="border" role="status" >
                    <span className="visually-hidden"></span>
                  </Spinner> : 
                  null } */}
                   {status =='error' && 'N/A' }
            
            {organized && organized.status === 'success' ? attributes && attributes.status == 'success' && attributes.meeting_attributes.high.rating_attributes.length !== 0 ? positiveAttr.slice(0, 5).map((i, j) =>
             <span key={j} className="label label-lg label-light-primary mr-2 label-inline">{i.title}</span>
            )   
              : organized && organized.status === 'success' && attributes && attributes.status == 'success' && attributes.meeting_attributes.high.rating_attributes.length === 0 && <strong>N/A</strong> : organized && organized.status === 'error' && <strong>N/A</strong>
          }

            
           {positiveAttr.length >5 && <OverlayTrigger placement={positiveAttr.length <= 20 ? "top" : 'bottom'} overlay={popover}>
              <span className="label label-lg label-light mr-2 label-inline custm_popover"> + {positiveAttr.length - 5 + ' more'}</span>
            </OverlayTrigger>}
          </div>
        </div>
        <div className="badge--card mt-4">
          <div className="badge--card-title">Negative Meeting Attributes</div>
          <div className="badge--card-body">

              
          {/* {negetiveAttr.length == 0 && !attributes && !organized ? <Spinner animation="border" role="status" >
                    <span className="visually-hidden"></span>
                  </Spinner> : 
                  null } */}
             {status =='error' && 'N/A' }
            {organized && organized.status === 'success' ? organized && organized.status === 'success' && attributes && attributes.status == 'success' && attributes.meeting_attributes.low.rating_attributes.length !== 0 ? negetiveAttr.slice(0, 5).map((i, j) =>
             <span key={j} className="label label-lg label-light-danger mr-2 label-inline">{i.title}</span>
            )   
              : organized && organized.status === 'success' && attributes && attributes.status == 'success' && attributes.meeting_attributes.low.rating_attributes.length === 0 && <strong>N/A</strong> : organized && organized.status === 'error' && <strong>N/A</strong>
          }
            
           {negetiveAttr.length >5 && <OverlayTrigger placement={negetiveAttr.length < 25 ?"top" : 'bottom'} overlay={popover2}>
              <span className="label label-lg label-light mr-2 label-inline custm_popover"> + {negetiveAttr.length - 5 + ' more'}</span>
            </OverlayTrigger>}

          </div>
        </div>
      </div>
      : 
      !organized && status !== 'error' ? <div className='d-flex justify-content-center ' style={{height:"300px",alignItems:"center"}}><Spinner animation="border" role="status" >
      <span className="visually-hidden"></span>
  </Spinner></div> : 
      <div className='p-4'>We're sorry, for the time you requested, we don't have any data to share with you.</div>
    }
    </div>

          
  );
}
