/* eslint-disable */ 

/**
 * Entry application component used to compose providers and render Routes.
 * */

import React,{useState} from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { IntercomProvider } from "react-use-intercom";
import withClearCache from "../ClearCache";
import { getBuildDate } from "../_metronic/_helpers/functions";
import { ErrorBoundary } from "react-error-boundary";
import { GoogleOAuthProvider } from '@react-oauth/google';


const ClearCacheComponent = withClearCache(MainApp);

export default function App({ store, persistor, basename }) {
  return <ClearCacheComponent store={store} persistor={persistor} basename={basename} />;
}


function MainApp(props) {

  require('dotenv').config();

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  // const onHide = () => console.log('Intercom did hide the Messenger');
  // const onShow = () => console.log('Intercom did show the Messenger');
  const [showIntercom,setShowIntercom] = useState(true);
  const onUnreadCountChange = (amount) => {
    // console.log('Intercom has a new unread message');
    setUnreadMessagesCount(amount);
  };
  
  const OurFallbackComponent = (e) => {
    let stack = e.error.stack
    console.log(stack);
    let message = e.error.message
    return (
      <div
      style={{
        position:'absolute',
        left:'50%',
        top:'10%',
        transform:'translate(-50%)'
      }}
      >
        <h1 style={{paddingBottom:'10px',fontFamily: 'Shift, sans-serif'}}>Something went wrong!</h1>
        <h1 style={{color:'red',paddingBottom:'10px',fontFamily: 'Shift, sans-serif'}}>Error:  {message}</h1>
      </div>
    );
  };

return (
    <Provider store={props.store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={props.persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={props.basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Intercom chat service provider */}
                  <IntercomProvider
                                appId={process.env.REACT_APP_INTERCOM_APP_ID}
                                // onHide={onHide}
                                // onShow={onShow}
                                onUnreadCountChange={onUnreadCountChange}
                                autoBoot={showIntercom}>
                                        {/* Render routes with provided `Layout`. */}
                                        {/* <AuthInit> */}
                                        <ErrorBoundary FallbackComponent={OurFallbackComponent}>
                                          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                                              <Routes />
                                          </GoogleOAuthProvider>;
                                        </ErrorBoundary>
                                            {/* <p style={{zIndex:"500", textAlign:"right", fontSize:"12px"}}>Build Version : 2.7.3</p> */}
                                        {/* </AuthInit> */}
                    </IntercomProvider>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
)
}
