import { put, takeLatest } from "redux-saga/effects";
import {
  getProfileInfoDetailResponse,
  SUCCESS_GET_PROFILE_INFO_DETAIL,
  ERROR_GET_PROFILE_INFO_DETAIL,
  GET_PROFILE_INFO_DETAIL,
  updateProfileInfoDetailResponse,
  SUCCESS_UPDATE_PROFILE_INFO_DETAIL,
  ERROR_UPDATE_PROFILE_INFO_DETAIL,
  UPDATE_PROFILE_INFO_DETAIL,
  saveUserProfileImageResponse,
  SUCCESS_SAVE_USER_PROFILE_IMAGE,
  ERROR_SAVE_USER_PROFILE_IMAGE,
  SAVE_USER_PROFILE_IMAGE,
  SUCCESS_UPDATE_USER_RELATIONS_DETAIL,
  ERROR_UPDATE_USER_RELATIONS_DETAIL,
  UPDATE_USER_RELATIONS_DETAIL,
  updateUserRelationsDetailResponse,
  SUCCESS_UPDATE_USER_NOTIFICATIONS_DETAIL,
  ERROR_UPDATE_USER_NOTIFICATIONS_DETAIL,
  UPDATE_USER_NOTIFICATIONS_DETAIL,
  updateUserNotificationsDetailResponse,
  SUCCESS_UPDATE_USER_PRIVACY_DETAIL,
  ERROR_UPDATE_USER_PRIVACY_DETAIL,
  UPDATE_USER_PRIVACY_DETAIL,
  updateUserPrivacyDetailResponse,
  SUCCESS_GET_LOCALSTORAGE_USER_DETAIL,
  ERROR_GET_LOCALSTORAGE_USER_DETAIL,
  GET_LOCALSTORAGE_USER_DETAIL,
  checkUserUniqueNicknameResponse,
  CHECK_USER_UNIQUE_NICKNAME,
  SUCCESS_CHECK_USER_UNIQUE_NICKNAME,
  ERROR_CHECK_USER_UNIQUE_NICKNAME,
  UPDATE_FEEDBACK_SETTINGS,
  updateFeedbackSettingsResponse,
  SUCCESS_UPDATE_FEEDBACK_SETTINGS,
  ERROR_UPDATE_FEEDBACK_SETTINGS,
  SUCCESS_SEND_OTP,
  updateSendOtpResponse,
  ERROR_SEND_OTP,
  SEND_OTP,
  SUCCESS_VERIFY_OTP,
  ERROR_VERIFY_OTP,
  updateVerifyOtpResponse,
  VERIFY_OTP,
  updateResendOtpResponse,
  SUCCESS_RESEND_OTP,
  ERROR_RESEND_OTP,
  RESEND_OTP,
  FETCH_MEETING_MASTERY_NOTIFICATIONS,
  getMeetingMasteryNotificationsResponse,
  SUCCESS_FETCH_MEETING_MASTERY_NOTIFICATIONS,
  ERROR_FETCH_MEETING_MASTERY_NOTIFICATIONS,
  updateMeetingMasteryNotificationsResponse,
  SUCCESS_UPDATE_MEETING_MASTERY_NOTIFICATIONS,
  ERROR_UPDATE_MEETING_MASTERY_NOTIFICATIONS,
  UPDATE_MEETING_MASTERY_NOTIFICATIONS,
  SUCCESS_CUSTOM_UPDATE_USER_RELATIONS_DETAIL,
  ERROR_CUSTOM_UPDATE_USER_RELATIONS_DETAIL,
  updateCustomUserRelationsDetailResponse,
  UPDATE_CUSTOM_USER_RELATIONS_DETAIL,
  // updateUserPhoneNumber,
  updateUserPhoneNumberResponse,
  SUCCESS_UPDATE_USER_PHONE_NUMBER,
  ERROR_UPDATE_USER_PHONE_NUMBER,
  UPDATE_USER_PHONE_NUMBER
} from "./userProfile.action";
import {
  checkUserUniqueNicknameDataApi,
  saveCustomNotificationDataApi,
  saveUserPhoneNumber,
  fetchMeetingMasteryNotificationsApi,
  getLocalUserDataApi,
  getProfileInfoDetailApi,
  resendOtpApi,
  saveUserNotificationDataApi,
  saveUserPrivacyDataApi,
  saveUserProfileImageApi,
  saveUserRelationDataApi,
  sendOtpApi,
  updateFeedbackSettingsApi,
  updateMeetingMasteryNotificationsApi,
  updateProfileInfoDetailApi,
  verifyOtpApi,
} from "./userProfile.api";
import _ from "lodash";
import { sendApiError } from "../../../app/modules/Auth/_redux/authCrud";
import { Apistore } from "../../apiStore";

// Get User Profile Detail
function* getProfileInfoDetailRequest(data) {
  let getData = yield getProfileInfoDetailApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(
      getProfileInfoDetailResponse(
        SUCCESS_GET_PROFILE_INFO_DETAIL,
        getData.data
      )
    );
  } else {
    yield put(
      getProfileInfoDetailResponse(ERROR_GET_PROFILE_INFO_DETAIL, getData.data)
    );
    sendApiError(500, getData.data, Apistore.GetUserProfileData);
  }
}

export function* getProfileInfoDetailWatcher() {
  yield takeLatest(GET_PROFILE_INFO_DETAIL, getProfileInfoDetailRequest);
}

// Update User Profile Detail
function* updateProfileInfoDetailRequest(data) {
  let getData = yield updateProfileInfoDetailApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(
      updateProfileInfoDetailResponse(
        SUCCESS_UPDATE_PROFILE_INFO_DETAIL,
        getData.data
      )
    );
  } else {
    yield put(
      updateProfileInfoDetailResponse(
        ERROR_UPDATE_PROFILE_INFO_DETAIL,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.SaveUserProfileData);
  }
}

export function* updateProfileInfoDetailWatcher() {
  yield takeLatest(UPDATE_PROFILE_INFO_DETAIL, updateProfileInfoDetailRequest);
}

// Update User Profile Detail
function* saveUserProfileImageDataRequest(data) {
  let getData = yield saveUserProfileImageApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(
      saveUserProfileImageResponse(
        SUCCESS_SAVE_USER_PROFILE_IMAGE,
        getData.data
      )
    );
  } else {
    yield put(
      saveUserProfileImageResponse(ERROR_SAVE_USER_PROFILE_IMAGE, getData.data)
    );
    sendApiError(500, getData.data, Apistore.SaveUserProfileImage);
  }
}

export function* saveUserProfileImageDataWatcher() {
  yield takeLatest(SAVE_USER_PROFILE_IMAGE, saveUserProfileImageDataRequest);
}

// Update User Profile Relations Detail
function* updateProfileRelationsDetailRequest(data) {
  let getData = yield saveUserRelationDataApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(
      updateUserRelationsDetailResponse(
        SUCCESS_UPDATE_USER_RELATIONS_DETAIL,
        getData.data
      )
    );
  } else {
    yield put(
      updateUserRelationsDetailResponse(
        ERROR_UPDATE_USER_RELATIONS_DETAIL,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.SaveUserRelationData);
  }
}
export function* updateProfileRelationsDetailWatcher() {
  yield takeLatest(
    UPDATE_USER_RELATIONS_DETAIL,
    updateProfileRelationsDetailRequest
  );
}
//--------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------
// update custom email
function* updateCustomRelationsDetailRequest(data) {
  let getData = yield saveCustomNotificationDataApi(data.data);
  // let i = 1;
//   if (i == 1) {
    if (getData.status === 200) {
    yield put(
      updateCustomUserRelationsDetailResponse(
        SUCCESS_CUSTOM_UPDATE_USER_RELATIONS_DETAIL,
        getData.data
      )
    );
  } else {
    yield put(
      updateCustomUserRelationsDetailResponse(
        ERROR_CUSTOM_UPDATE_USER_RELATIONS_DETAIL,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.SaveUserRelationData);
  }
}

export function* updateCustomProfileRelationsApiWatcher() {
  yield takeLatest(
    UPDATE_CUSTOM_USER_RELATIONS_DETAIL,
    updateCustomRelationsDetailRequest
  );
}
//-------------------------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------
// update user phonr number
function* UpdatesaveUserPhoneNumberRequest(data) {
  let getData = yield saveUserPhoneNumber(data.data);
  // let i = 1;
//   if (i == 1) {
    if (getData.status === 200) {
    yield put(
      updateUserPhoneNumberResponse(
        SUCCESS_UPDATE_USER_PHONE_NUMBER,
        getData.data
      )
    );
  } else {
    yield put(
      updateUserPhoneNumberResponse(
        ERROR_UPDATE_USER_PHONE_NUMBER,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.CheckIsPhoneVerified);
  }
}

export function* updateUserPhoneApiWatcher() {
  yield takeLatest(
    UPDATE_USER_PHONE_NUMBER,
    UpdatesaveUserPhoneNumberRequest
  );
}
//-------------------------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------------------------

// Update User Profile Notifications Detail
function* updateProfileNotificationDetailRequest(data) {
  let getData = yield saveUserNotificationDataApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(
      updateUserNotificationsDetailResponse(
        SUCCESS_UPDATE_USER_NOTIFICATIONS_DETAIL,
        getData.data
      )
    );
  } else {
    yield put(
      updateUserNotificationsDetailResponse(
        ERROR_UPDATE_USER_NOTIFICATIONS_DETAIL,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.SaveUserNotificationData);
  }
}

// get User Profile Meeting Mastery Notifications
function* getMeetingMasteryNotificationRequest(data) {
  let getData = yield fetchMeetingMasteryNotificationsApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(
      getMeetingMasteryNotificationsResponse(
        SUCCESS_FETCH_MEETING_MASTERY_NOTIFICATIONS,
        getData.data
      )
    );
  } else {
    yield put(
      getMeetingMasteryNotificationsResponse(
        ERROR_FETCH_MEETING_MASTERY_NOTIFICATIONS,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.GetMasteryNotification);
  }
}

// UPDATE User Profile Meeting Mastery Notifications
function* updateMeetingMasteryNotificationRequest(data) {
  let getData = yield updateMeetingMasteryNotificationsApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(
      updateMeetingMasteryNotificationsResponse(
        SUCCESS_UPDATE_MEETING_MASTERY_NOTIFICATIONS,
        getData.data
      )
    );
  } else {
    yield put(
      updateMeetingMasteryNotificationsResponse(
        ERROR_UPDATE_MEETING_MASTERY_NOTIFICATIONS,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.UpdateMeetingMasteryNotifications);
  }
}

export function* updateProfileNotificationDetailWatcher() {
  yield takeLatest(
    UPDATE_USER_NOTIFICATIONS_DETAIL,
    updateProfileNotificationDetailRequest
  );
  yield takeLatest(
    FETCH_MEETING_MASTERY_NOTIFICATIONS,
    getMeetingMasteryNotificationRequest
  );
  yield takeLatest(
    UPDATE_MEETING_MASTERY_NOTIFICATIONS,
    updateMeetingMasteryNotificationRequest
  );
}

// Update User Profile Privacy Detail
function* updateProfilePrivacyDetailRequest(data) {
  let getData = yield saveUserPrivacyDataApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(
      updateUserPrivacyDetailResponse(
        SUCCESS_UPDATE_USER_PRIVACY_DETAIL,
        getData.data
      )
    );
  } else {
    yield put(
      updateUserPrivacyDetailResponse(
        ERROR_UPDATE_USER_PRIVACY_DETAIL,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.SaveUserPrivacyData);
  }
}

export function* updateProfilePrivacyDetailWatcher() {
  yield takeLatest(
    UPDATE_USER_PRIVACY_DETAIL,
    updateProfilePrivacyDetailRequest
  );
}

// Get Updated Local Storage User Detail
function* checkUserUniqueNicknameRequest(data) {
  let getData = yield checkUserUniqueNicknameDataApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(
      checkUserUniqueNicknameResponse(
        SUCCESS_CHECK_USER_UNIQUE_NICKNAME,
        getData.data
      )
    );
  } else {
    yield put(
      checkUserUniqueNicknameResponse(
        ERROR_CHECK_USER_UNIQUE_NICKNAME,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.CheckUniqueNickName);
  }
}

export function* checkUserUniqueNicknameWatcher() {
  yield takeLatest(CHECK_USER_UNIQUE_NICKNAME, checkUserUniqueNicknameRequest);
}

// Get Updated Local Storage User Detail
function* getLocalStorageUserDetailRequest(data) {
  let getData = yield getLocalUserDataApi(data.data);
  if (getData.status === 200 && _.has(getData, "data") && getData.data.code !== 440 ) {
        yield put(
          updateUserPrivacyDetailResponse(
            SUCCESS_GET_LOCALSTORAGE_USER_DETAIL,
            getData.data
          )
        );
  } else {
    yield put(
      updateUserPrivacyDetailResponse(
        ERROR_GET_LOCALSTORAGE_USER_DETAIL,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.GetUserData);
  }
}

export function* getLocalStorageUserDetailWatcher() {
  yield takeLatest(
    GET_LOCALSTORAGE_USER_DETAIL,
    getLocalStorageUserDetailRequest
  );
}

// send otp
function* sendOtp(data) {
  let getData = yield sendOtpApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(updateSendOtpResponse(SUCCESS_SEND_OTP, getData.data));
  } else {
    yield put(updateSendOtpResponse(ERROR_SEND_OTP, getData.data));
    sendApiError(500, getData.data, Apistore.SendOtp);
  }
}

function* verifyOtp(data) {
  let getData = yield verifyOtpApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(updateVerifyOtpResponse(SUCCESS_VERIFY_OTP, getData.data));
  } else {
    yield put(updateVerifyOtpResponse(ERROR_VERIFY_OTP, getData.data));
    sendApiError(500, getData.data, Apistore.VerifyOtp);
  }
}

function* resendOtp(data) {
  let getData = yield resendOtpApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(updateResendOtpResponse(SUCCESS_RESEND_OTP, getData.data));
  } else {
    yield put(updateResendOtpResponse(ERROR_RESEND_OTP, getData.data));
    sendApiError(500, getData.data, Apistore.ResendOtp);
  }
}

export function* otpWatcher() {
  yield takeLatest(SEND_OTP, sendOtp);
  yield takeLatest(VERIFY_OTP, verifyOtp);
  yield takeLatest(RESEND_OTP, resendOtp);
}

//verify otp

// Update feedback settings
function* updateFeedbackSettings(data) {
  let getData = yield updateFeedbackSettingsApi(data.data);
  if (getData.status === 200 && _.has(getData, "data")) {
    yield put(
      updateFeedbackSettingsResponse(
        SUCCESS_UPDATE_FEEDBACK_SETTINGS,
        getData.data
      )
    );
  } else {
    yield put(
      updateFeedbackSettingsResponse(
        ERROR_UPDATE_FEEDBACK_SETTINGS,
        getData.data
      )
    );
    sendApiError(500, getData.data, Apistore.SaveUserPrivacyData);
  }
}

export function* feedbackSettingsWatcher() {
  yield takeLatest(UPDATE_FEEDBACK_SETTINGS, updateFeedbackSettings);
}
