// Get Coaching Report MCR Data
export const GET_COACHING_MCR_DATA = "GET_COACHING_MCR_DATA";
export const getCoachingMcrData = (data) => ({ type: GET_COACHING_MCR_DATA, data })
export const SUCCESS_GET_COACHING_MCR_DATA = 'SUCCESS_GET_COACHING_MCR_DATA';
export const ERROR_GET_COACHING_MCR_DATA = 'ERROR_GET_COACHING_MCR_DATA';
export const getCoachingMcrDataResponse = (type, data) => ({ type, data });

// Get Coaching Report MCR Graph Data
export const GET_COACHING_MCR_GRAPH_DATA = "GET_COACHING_MCR_GRAPH_DATA";
export const getCoachingMcrGraphData = (data) => ({ type: GET_COACHING_MCR_GRAPH_DATA, data })
export const SUCCESS_GET_COACHING_MCR_GRAPH_DATA = 'SUCCESS_GET_COACHING_MCR_GRAPH_DATA';
export const ERROR_GET_COACHING_MCR_GRAPH_DATA = 'ERROR_GET_COACHING_MCR_GRAPH_DATA';
export const getCoachingMcrGraphDataResponse = (type, data) => ({ type, data });