/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../_helpers";

export function DropdownMenu4() {
    const [imageErrCount, setImageErrCount] = useState(0)
    const localUserData = useSelector((state) => state.profile.getLocalStorageUserData);
    const history = useHistory()

    useEffect(() => {
        if(imageErrCount > 1000){
          window.location.reload()
        }
      }, [imageErrCount])


    const handelLogout =()=>{
        history.push({
            pathname: '/logout',
            state: {
              by : 'code'
            }
          });
    }

    return <>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover py-1">
            <li className="navi-item">
                <Link to='/user-profile/personal-info' className="navi-link">
                    <span className="navi-icon">  <img className="profile-user-img rounded-circle" style={{width:"25px",height:"25px"}} src={localUserData && localUserData.user_data ? localUserData.user_data.user_photourl : toAbsoluteUrl("/media/ms-media/user-avatar.jpg")} onError={({ currentTarget }) => {currentTarget.src="https://client.meetingscience.io/images/profile_images/ph_profile.png";setImageErrCount(imageErrCount + 1); currentTarget.onerror = null}} alt="" /></span>
                    <span className="navi-text ml-2">Profile</span>
                </Link>
            </li>

            <li className="navi-separator my-3"></li>

            {/* <li className="navi-item">
                <Link to={{ pathname:'https://www.meetingscience.io/betafaq/'}} target='_blank' className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-magnifier-tool"></i></span>
                    <span className="navi-text">FAQ</span>
                </Link>
            </li> */}
            
            <li className="navi-item" style={{cursor:'pointer'}}>
                <p onClick={handelLogout} className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-user-1"></i></span>
                    <span className="navi-text">Logout</span>
                </p>
            </li>
        </ul>
        {/*end::Navigation*/}

    </>
}
