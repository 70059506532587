/* eslint-disable */ 

import { RECEIVE_FEEDBACK_TREND_OVERTIME, RECEIVE_NEW_FEEDBACK_GRAPH_DATA, RECEIVE_ORGANIZED_DATA, RECEIVE_ORGANIZED_MEETINGS_KEY_DIFFERENCES, RECEIVE_ORGANIZED_SUMMARY_ERROR, RECEIVE_SINGLE_MEETING_RATING, RECEIVE_SINGLE_RATING_ATTRIBUTES, RECEIVE_USER_COMPANY_RANK, RECEIVE_USER_FEEDBACK_DATA, RECEIVE_USER_RATING, RECEIVE_USER_RATING_ATTRIBUTES, RECEIVE_YOU_VS_ATTENDEES, RESET_SCORE_FOR_SINGLE_EVENT, RESET_STATE } from "./organized.action";
import { DEFAULT_STATE } from "./organized.state";

export const organizedReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case RECEIVE_ORGANIZED_DATA:
            return {
                ...state,
                    organized:action.data
            } 
        case RECEIVE_ORGANIZED_SUMMARY_ERROR:
            return {
                    ...state,
                    status:'error',
                    data:action.data    
                    };        
        case RECEIVE_USER_RATING:
            return {
                ...state,
                rating:action.data
            }
            case RECEIVE_SINGLE_MEETING_RATING:
                return {
                    ...state,
                    single_rating:action.data
                }   
                case RESET_SCORE_FOR_SINGLE_EVENT:
                    return {
                        ...state,
                        single_rating:action.data
                    }   
        case RECEIVE_USER_RATING_ATTRIBUTES:
            return {
                ...state,
                attributes:action.data
            }
            case RECEIVE_SINGLE_RATING_ATTRIBUTES:
                return {
                    ...state,
                    single_attributes:action.data
                }    
        case RECEIVE_USER_COMPANY_RANK:
            return {
                ...state,
                companyRank:action.data
            }
        // case RECEIVE_USER_FEEDBACK_DATA:
        //     return {
        //         ...state,
        //         feedback:action.data
        //     } 
            case RECEIVE_ORGANIZED_MEETINGS_KEY_DIFFERENCES:
                return {
                    ...state,
                    differences: action.data
                }
            case RECEIVE_NEW_FEEDBACK_GRAPH_DATA:
                return {
                    ...state,
                    memberFeedback:action.data
                }
            case RECEIVE_FEEDBACK_TREND_OVERTIME:
                return {
                    ...state,
                    feedbackTrends:action.data
                }
            case RESET_STATE:
                return {
                    ...state,
                        organized:null
                    };                                 
        default:
            return state;
    }
}