import { call, put, takeLatest } from 'redux-saga/effects';
import { sendApiError } from '../../../../app/modules/Auth/_redux/authCrud';
import { Apistore } from '../../../apiStore';
import {REQUEST_ATTENDEES_DATA,RECEIVE_ATTENDEES_DATA, RESET_STATE, RECEIVE_ATTENDED_MEETINGS_KEY_DIFFERENCES, REQUEST_ATTENDED_MEETINGS_KEY_DIFFERENCES} from './attended.action';
import { fetchAttendeesData, fetchDifferences } from './attended.crud';

// Get attended page data
function* getAttendedData(action) { 
    yield put({type:RESET_STATE})
    try{
            const result = yield call (fetchAttendeesData,action.payload);
            yield put({type:RECEIVE_ATTENDEES_DATA,data:result.data})

    } catch (error) {
        sendApiError(500,error,Apistore.GetAttendedEvents)
    }
}

function* getDifferences(action) {
    try {
        const result = yield call(fetchDifferences, action.payload);
        yield put({ type: RECEIVE_ATTENDED_MEETINGS_KEY_DIFFERENCES, data: result.data })

    } catch (error) {
        sendApiError(500,error,Apistore.GetAttendedEvents)
    }
}

export function* attendeesWatcher() {
    yield takeLatest(REQUEST_ATTENDEES_DATA, getAttendedData);
    yield takeLatest(REQUEST_ATTENDED_MEETINGS_KEY_DIFFERENCES,getDifferences)

}