export const Apistore = {

    // -------------------------admin--------------------------------------//
    MasterLogin:'user/master_login', // master login to any user account
    UserLogAPI:'user-activity/save', //basic user activity logging
    ErrorLogApi:'user/error_log',
    deactivateUser:'user/delete_user',
    ActivateUser:'user/restore_user',
    //--------------------------signup--------------------------------------//

    BearerTokenUrl: "login", // get bearer token which is mandatory to pass in Header to fetch data from db
    GoogleLoginURL: 'google/login',  // authentication with google //
    MicrosoftLoginURL: 'authentication/microsoft',  // authentication with microsoft //
    Timezone: 'utility/timezone',  //get user timezone on signup process //
    SaveGoogleEvents: 'event/save-one-event-from-google-signup', // save google calendar events
    SaveMicrosoftEvents: 'event/save-one-event-from-microsoft-signup', // save microsoft calendar events
    RegisterUrl: 'user/save_signup_data', // register user data from signup process
    GetSignupEventData: 'event/sign_up_events', // get event data for screen 4 .
    FetchUserRelations: 'user/fetch_user_relations', // for signup step 5
    TriggerSignupNotification: 'user/signup_notification_email', // for sending notification to admin
    GetMcrData:'user/signup_mcr_data',

    // ---------------------new apis in signup-----------------//

    TriggerGoogleEventPull:'event/google-signup-pull',
    TriggerMsftEventPull:'event/microsoft-signup-pull',
    TriggerMeetingMasteryCalculation:"meeting-mastery/calculate-at-signup",
    TriggerScoreCalculation:"user/calculate_user_score",

    // after login
    GetUserData: 'user/get_login_user_data', // get registered user data
    GetSmartBreakCheck: 'user/get_smart_break_data', //get the check for smart break
    TriggerSmartBreakClose:'user/smart_break',//hit on smart brak close func
    UpdateGoogleScope:'user/get_user_scope',
    // ----------------------- dashboard--------------------------------------//

    //attended
    GetAttendedEvents: 'user/get_events', // api for fetching events on attended dashboard
    GetAttendeeKeyDifferences:'/user/attended_event_differences',

    //organized
    GetOrganizedEvents: 'user/get_org_events', // api for fetching events on organized dashboard
    GetOrganizedKeyDifferences:'user/organised_event_differences',
    GetNewFeedback:'/user/feedback_rating',
    GetYouVsAttendees:"/user/feedback_rating_comparison",
    GetFeedbackTrendOverTime:"/user/feedback_rating_trends",

    //unrated
    GetUnratedEvents: 'rating/get-all-unrated-events', // fetch unrated meetings data using get api
    SendUnratedMeetingData: 'rating/post-rating-v2', // post data for unrated meetings
    GetUnratedCount: 'rating/get-unrated-event-count', // get unrated meetings count api

    //company
    GetCompanyEvents: "user/get_company_events", // get company events
    GetCompanyRatingAndAttributes: "user/company-ratings", // get company ratings and attributes
    GetCompanyKeyDifferences:'user/company_differences', // get company differences,
    GetCompanyAttendeesFeedback:"user/company_feedback_rating",


    //------------------------------profile------------------------//

    //user profile
    GetUserProfileData: 'user/get_user_profile_data', // get user profile data after login
    SaveUserProfileData: 'user/save_user_profile_data', // save user profile data
    SaveUserProfileImage: 'user/save_profile_image',    // save user profile Image data
    SaveUserRelationData: 'user/save_user_relations', // save user relations data
    SaveUserNotificationData: 'user/save_notification_data', // save user notification data
    SaveUserPrivacyData: 'user/save_user_privacy_data', // save user privacy data
    CheckUniqueNickName: 'user/check_nickname', // check api for unique nickname
    SaveUserFeedbackSettings:'user/save_feedback_data', // save user feedback settings
    CheckIsPhoneUnique:'user/check_phone_number', // check if user's phone number is unique,
    GetMasteryNotification:"user/meeting_mastery_notification_data",
    UpdateMeetingMasteryNotifications:"user/save_meeting_mastery_notification_data",
    updateCustomNotificationEmail: "user/save_custom_notification_request",  //newChange: save custome email in relationship 
    CheckIsPhoneVerified:"user/check_verify_number",
    
    // ----------------------- reports -------------------------------//
    
    //meeting summary (organized page)
    GetUserRatingAttributes: "user/user-rating-attribute", // positive and negetive
    GetUserRating: "user/get-user-rating", // get user rating
    GetUserCompanyRank: "user/user-rank-company", // get login user's company ranking 
    GetFeedback: '/user-feedback-summary', // get data for feedback graph

    //time machine report
    ListTimeMachineOrganizedMeetingsData: "user/get_timemachine_org_events", // get list of organized meetings data
    ListTimeMachineAttendedMeetingsData: "user/get_timemachine_attended_events", // get list of attended meetings data
    SaveTimeMachineOrganizedMeetingsData: "user/time_machine_org_event_update", // save reduce time in organized meetings.
    SendTimeMachineAttendedProposeTime: "user/time_machine_purpose_time_update", // send propose time of attended meetings.

    //meeting mastery report
    GetMeetingMasteryLevelsList: "meeting-mastery-level/list", // first section - meeting mastery level
    GetHighestlevelAcheived: "user-level/highest", // first section - get current level
    GetUserProgressSummary:"user-progress-summary", // second section
    GetUserBadgeSummary: "user-badge-summary", // third section (your badges)

    //score modal report
    GetUserScoreData: "user/user_score", // get user score details
    GetUserScoreComparisonData: "user/user_score_comparison", // get user score comparison details
    GetUserScoreGraphData: "user/user_score_graph", // get user score graph details
    GetUserScoreHowToImproveData: "user/user_score_how_to_improve", // get user score how to improve details

    // leaderboard report
    GetLeaderboards:"rating/get-leader-board", // leaderboard report on dashboard
    GetMeetingMasterySummary:"user-progress-random", // summary for meeting mastery level
    GetMasteryLeaderboard:"meeting-mastery/get-leader-board", // leaderboard for meeting mastery

    // coaching report
    GetCoachingMcrData: "user/mcr_data", // get mcr data for coaching report
    GetCoachingMcrGraphData: "user/mcr_graph", // get mcr graph data for coaching report
    EventPull:"/user/event_pull", // trigger on the spot event pull 
    ActionTrack:"/user/mcr_action", // trigger on the spot event pull 
 
    // --------------------------------- menu actions ------------------------ //
    // Sidemenu
    RemoveZoomApp:"/user/remove_zoom", // remove zoom app for user  

    // unsubscribe notifications/feedback
    GetUnsubscribeLink:"notification/get-unsubscribe-link",
    SaveUnsubscribeUserData:"notification/save-unsubscribe-action",

    // otp

    SendOtp:"user/send_otp",
    VerifyOtp:"user/verify_otp",
    ResendOtp:"user/resend_otp"
}