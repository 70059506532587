/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserScoreComparisonDetail, getUserScoreGraphDetail, getUserScoreHowToImproveDetail } from "../../../../redux/duck/reports/scoreModal/score.action";
import { AlertPopup } from "../../../_helpers/Alerts";
import { usePrevious } from "../../../_helpers/Custom";
import { authTimezone } from "../../../_helpers/functions";

export function WeeklyScoreModal({ className }) {
    // const user_id = authUserId();
    const user_timezone = authTimezone();
    const dispatch = useDispatch();
    const fixedMargin = {
        margin: "calc(50% - 25px)"
    };
    let seriesData = [];
    // redux state
    const scoreData = useSelector((state) => state.score.getUserScoreData);
    const prevScoreData = usePrevious({ scoreData });
    // Set Initial State
    const [alert, setAlert] = useState({ show: false, title: null, message: null, errType: null });
    const [userScore, setUserScore] = useState({ user_score: "", diff: "" });
    const [state, setState] = useState({
        series: [],
        options: {
            chart: {
                height: 350,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '85%',
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            color: "black",
                            fontSize: "40px",
                            show: true,
                            fontWeight: "bolder",
                            formatter: function (val) {
                                return val;
                            }
                        }
                    }
                },
            },
            colors: ['#00ACBF'],
            labels: ['Total Score'],
        },
    });

    //fetch Data
    useEffect(() => {
        if (prevScoreData && prevScoreData.scoreData !== scoreData) {
            if (scoreData && _.has(scoreData, "user_score") && scoreData.code === "200") {
                if (scoreData.user_score !== "N/A") {
                    seriesData.push(scoreData.user_score);
                    setState({ ...state, series: seriesData })
                    setUserScore({ user_score: scoreData.user_score, diff: scoreData.diff })
                    dispatch(getUserScoreComparisonDetail({ user_timezone }));
                    dispatch(getUserScoreHowToImproveDetail({  user_timezone }));
                    dispatch(getUserScoreGraphDetail({  user_timezone }));
                }
            }
            if (scoreData && _.has(scoreData, "message") && scoreData.status === "error") {
                if (scoreData.open_popup === false) {
                    setAlert({
                        show: true,
                        message: "Something went wrong!",
                        title: "Error",
                        errType: "failure",
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevScoreData, scoreData]);

    // handle close button of alert
    const handleClose = () => {
        setAlert({
            show: false,
        });
    };
    return (
        <>
            {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />}
            {scoreData.user_score !== undefined && userScore.user_score !== "" ?
                <div className={`row justify-content-center align-items-center h-100 ${className}`}>
                {/* Header */}
                <div className="col-xl-9 col-lg-10 col-md-11">
                    <div className="Weekly-Score-chart">
                            <ReactApexChart options={state.options} series={state.series} type="radialBar" height={250} />
                            {userScore.user_score !== 0 && userScore.diff !== "N/A" ? <div className={userScore && userScore.diff >= 0 ? "detail-percentage green" : "detail-percentage text-danger"}>{userScore && userScore.diff ? Math.round(userScore.diff) : 0} % {userScore && userScore.diff >= 0 ? <img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg" /> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg" />}</div> : ""}
                    </div>
                </div>
            </div>
                :
                <Spinner animation="border" role="status" style={fixedMargin}>
                    <span className="visually-hidden"></span>
                </Spinner>
            }
        </>
    );
}


