/* eslint-disable */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MeetingSummaryModal } from "./widget8";
import { Html5Entities } from "html-entities";
import parse from "html-react-parser";
import { capitalize } from "@material-ui/core";
import moment from "moment";
import { logActivity } from "../../../app/modules/Auth/_redux/authCrud";
import './widgets.css'

export const RatedMeetings = () => {
  const htmlEntities = new Html5Entities();
  const { organized, status } = useSelector((state) => state.organized);
  const { getLocalStorageUserData } = useSelector((state) => state.profile);
  const [meetingModal, setMeetingModal] = useState(false);
  const [meetingSummary, setSummary] = useState();
  let _ =
    getLocalStorageUserData &&
    getLocalStorageUserData.user_data &&
    getLocalStorageUserData.user_data.currency &&
    htmlEntities.decode(
      getLocalStorageUserData.user_data.currency.currency_symbol
    );


    const handleSummaryModal = (id) => {
      setMeetingModal(true);
      logActivity("modal", "/meeting-summary", id, window.location.pathname);
      const eventData =
      organized && organized.organized_events_all.find((i) => i.event_id === id);
      if (eventData) {
        setSummary(eventData);
        }
    };

    return (
    <>
        <div style={{boxShadow:'none'}} className="">
            <div style={{paddingLeft:'2px',}} className="position-relative overflow-hidden">
                {organized !== null ? (
                    organized.status == "success" ? (
                    organized &&
                    getLocalStorageUserData &&
                    getLocalStorageUserData.user_data &&
                    getLocalStorageUserData.user_data.currency &&
                    organized?.ratedEvents?.map((key, index) => (
                        <div
                        className="custm_popover rated-meetings_custom_popover"
                        key={index}
                        onClick={() => handleSummaryModal(key[0].event_id)}
                        >
                        <div style={{border:'none'}}>
                            {parse(key[0].event_title !== '' && key[0].event_title !== null && key[0].event_title !== undefined ? capitalize(key[0].event_title) : 'N/A')}
                            <b style={{paddingLeft:'3px' }}>
                                {moment(key[0].event_start_datetime).format("MM/DD")}
                            </b>
                        </div>
                        </div>
                    ))
                ) : null
                ) : null
                }
            </div>
        </div>
        {meetingModal && (
        <MeetingSummaryModal
          data={meetingSummary}
          show={meetingModal}
          handleClose={() => setMeetingModal(false)}
        />
      )}
    </>
    );
};
