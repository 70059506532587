// View data in attended section 
export const REQUEST_ATTENDEES_DATA = "REQUEST_ATTENDEES_DATA";
export const RECEIVE_ATTENDEES_DATA = "RECEIVE_ATTENDEES_DATA";
export const RESET_STATE = "RESET_STATE";
export const requestAttendedData = (data) => ({type : REQUEST_ATTENDEES_DATA,payload:data});
export const receiveAttendedData = (data) => ({ type: RECEIVE_ATTENDEES_DATA, data:data })
export const resetData = (data) => ({type:'RESET_STATE',data:data});

export const REQUEST_ATTENDED_MEETINGS_KEY_DIFFERENCES = "REQUEST_ATTENDED_MEETINGS_KEY_DIFFERENCES";
export const RECEIVE_ATTENDED_MEETINGS_KEY_DIFFERENCES = "RECEIVE_ATTENDED_MEETINGS_KEY_DIFFERENCES";
export const requestKeyDifferences = (data) => ({ type: REQUEST_ATTENDED_MEETINGS_KEY_DIFFERENCES, payload: data });
export const receiveKeyDifferences = (data) => ({ type: RECEIVE_ATTENDED_MEETINGS_KEY_DIFFERENCES, data: data });