import React from 'react';
import { makeStyles, Slider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300
    },
    margin: {
        height: theme.spacing(3)
    },
    thumb: {
        background: "#0A2129",
        "&~&": {
            background: "green"
        }
    },
    mark: {
        background: "#0A2129",
        borderLeft: "1px solid",
        height: "9px",
        position: "absolute",
        top: 10
    },
    rail: {
        background: "linear-gradient(to right, green, yellow, red);"
    },
    track: {
        background: "transparent"
    },
    valueLabel: {
        "&>*": {
            background: "transparent",
            position: "absolute",
            top: 12
        }
    }
}));

export const AttendeeTimeSlider = (props) => {
    const classes = useStyles();
    return (
        <>
            <Slider
                classes={{
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    valueLabel: classes.valueLabel,
                    mark: classes.mark
                }}
                value={Number(props.values)}
                onChange={props.handleSlider}
                step={0.1}
                min={0.1}
                valueLabelDisplay="auto"
                max={60}
                marks={props.marks}
            />
        </>
    );
};