import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { sendAttendedMeetingsProposalTime } from '../../../redux/duck/reports/timeMachine/timeMachine.action';
import { listAttendedMeetingsDetail } from '../../../redux/duck/reports/timeMachine/timeMachine.action';
import { LayoutSplashScreen } from '../../layout';
import { AlertPopup } from '../../_helpers/Alerts';
import { usePrevious } from '../../_helpers/Custom';
import { SuccessTimeMachineModal } from './widget24';
import { ProposeConfirmationTimeMachineModal } from './widget25';

export const ProposeTimeMachineModal = (props) => {
    // const user_id = authUserId();
    const dispatch = useDispatch();
    // Loader State
    const [loader, setLoader] = useState(false);
    // Set Initial State
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [alert, setAlert] = useState({ show: false, title: null, message: null, errType: null });
    const [state, setState] = useState({ note: "" });
    const sendProposalTimeData = useSelector((state) => state.timeMachine.sendAttendedMeetingsProposalTimeData);
    const prevSendProposalTimeData = usePrevious({ sendProposalTimeData });

    const succMessage = "We have successfully sent an email to the meeting organizer.";


    // fetch response after send proposal time for attend meetings
    useEffect(() => {
        if (prevSendProposalTimeData && prevSendProposalTimeData.sendProposalTimeData !== sendProposalTimeData) {
            if (sendProposalTimeData && _.has(sendProposalTimeData, "message") && sendProposalTimeData.status === "success") {
                setConfirmationModal(false);
                setSuccessModal(true);
                setLoader(false)
                dispatch(listAttendedMeetingsDetail({ orderby: "event_title", sort: "DESC" }))
            }
            if (sendProposalTimeData && _.has(sendProposalTimeData, "message") && sendProposalTimeData.status === "error") {
                setConfirmationModal(false);
                setLoader(false);
                setAlert({
                    show: true,
                    message: sendProposalTimeData.message,
                    title: 'Error',
                    errType: "failure"
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevSendProposalTimeData, sendProposalTimeData]);

    // handle on send Proposal Time Data
    const handleOnSendProposalTimeData = (event_id) => {
        const { note } = state
        const sendProposalTimeData = {  event_id, note }
        setConfirmationModal(false);
        setLoader(true);
        dispatch(sendAttendedMeetingsProposalTime(sendProposalTimeData));
    }
    // handle on close
    const handleOnClose = () => {
        props.handleClose();
        setState({ ...state, note: "" });
    }
    // handle on send Proposal Time Button
    const handleSendProposalTime = () => {
        props.handleClose();
        setConfirmationModal(true);
    }
    // handle close button of alert
    const handleClose = () => {
        setAlert({
            show: false,
        });
    };
    return (
        <>
            <LayoutSplashScreen visible={loader} />
            {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />}
            <Modal
                show={props.show} onHide={() => handleOnClose()}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.proposeModalData.title}
                    </Modal.Title>
                    <button className="btn btn-close p-0 ml-auto" onClick={() => handleOnClose()}><img src="/media/ms-media/modal-close-icn.svg" alt="" /></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="time_machine-modal">
                        <h5>Your Proposal</h5>
                        <div className="row col--innercard-gutter">
                            <div className="col-md-6">
                                <div className="card modal-card border-0">
                                    <div className="card-body p-0">
                                        <div className="card-body--title mb-2">Start Time</div>
                                        <p>{props.proposeModalData.startTime}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card modal-card border-0">
                                    <div className="card-body p-0">
                                        <div className="card-body--title mb-2">End Time</div>
                                        <p>{props.proposeModalData.endTime}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <p className="mb-2 text-muted"><strong>NOTE</strong></p>
                                <textarea className="form-control" rows="3" placeholder="Add Optional Message" name="note" value={state.note} onChange={(event) => { setState({ ...state, note: event.target.value }) }}></textarea>
                            </div>
                        </div>
                        <div className="modal_btns mt-6 mb-4 d-flex align-items-center">
                            <button className="btn btn-bordred flex-grow-1 mr-3" onClick={() => handleOnClose()}>Return to TimeMachine</button>
                            <button className="btn btn-primary flex-grow-1 ml-3" onClick={() => handleSendProposalTime()}>Send Proposal Time</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ProposeConfirmationTimeMachineModal show={confirmationModal} handleClose={() => setConfirmationModal(false)} handleSave={() => handleOnSendProposalTimeData(props.proposeModalData.event_id)} />
            <SuccessTimeMachineModal show={successModal} message={succMessage} handleClose={() => setSuccessModal(false)} dashboard={props.dashboard} />
        </>
    );
};