/* eslint-disable */ 
import React,{useEffect} from "react";
import { Spinner } from "react-bootstrap";
import { useSelector,useDispatch } from "react-redux";
import { requestBadgeSummary } from "../../../redux/duck/reports/meetingMastery/mastery.action";
import { badgesUrl } from "../../_helpers";
import { authUserId } from "../../_helpers/functions";
import { Popover, OverlayTrigger } from 'react-bootstrap';


export function MeetingMasteryBadges() {

    const {badge_summary} = useSelector(state => state.mastery)
    const dispatch = useDispatch();
    const fixHeight = {
        "height":"400px",
        "display":"flex",
        'alignItems':'center',
        // 'align-items':'center',
        "justifyContent":"center"
        // "justify-content":"center"
    }

    const popover =  (desc) => (
        <Popover id="popover-basic" className="mr-4">
          <Popover.Content className="p-2">
            <div className="badge--card">
              <div className="badge--card-body">
              <span className="">{desc}</span>
              </div>
            </div>
          </Popover.Content>
        </Popover>
      );

    useEffect(() => {
        dispatch(requestBadgeSummary(authUserId()));
    }, [])

    return (
        <>
            <div className="col-12">
            <div className="card mb-4 card-custom">
            <p className="font-weight-bolder text-dark pl-3 mt-4 mb-0">Your Badges</p>
                {!badge_summary && 
                    <div style={fixHeight}>
                        <Spinner animation="border" role="status" >
                            <span className="visually-hidden"></span>
                        </Spinner>
                  </div>
                }
                {badge_summary && badge_summary.data &&
                        badge_summary.data.map((key0, index) => 
                    key0.categories.length > 1  &&
                    <div key={index}>
                            
                    <div className="card-header align-items-center border-0 pt-0">
                        <h3 className="card-title align-items-start flex-column">
                            <p className="card-title-subheading">{key0.title}</p>
                        </h3>
                    </div>

                    <div className="card-body position-relative overflow-hidden">
                        <div className="your-badges--container">
                            <div className="your-badge--row d-flex">
                                {key0.categories.map((key1,i) => 
                                        <div key={i} className="your-badges--col">
                                        <div className="your-badges--heading d-flex align-items-center justify-content-between">
                                            {key1.title} 
                                            
                                                <OverlayTrigger trigger="hover"  placement="top" overlay={popover(key1.description)}>
                                                <img  src="/media/ms-media/info-circle-icn.svg" alt="info" style={{cursor:"pointer"}} />
                                                </OverlayTrigger>
                                        </div>
                                        <div className="your-badges--body">
                                            <div className="row col--innercard-gutter">
                                                {
                                                    key1.badges.map((key2,i) => 
                                                        <div key={i} className="col-lg-6 col-sm-3 col-6">                                                    
                                                    <div   className={key2.achieved ? "your-badges--card text-center":"your-badges--card text-center disabled"}>
                                                        <img src={badgesUrl(key2.icon_file)} alt={key2.icon_file} />
                                                        <p>{key2.title}</p>
                                                    </div>
                                                </div>
                                                    )
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                
                                )}
                            
                            </div>
                        </div>
                    </div>


                </div>
)}

<div className='row'>
    {
        badge_summary && badge_summary.data &&
                        badge_summary.data.map((key0,i) => 
                    key0.categories.length == 1  &&
                    <div key={i} className='col-lg-4 col-sm-12 col-12'>
                    <div className="card-header align-items-center border-0 pb-0">
                        <h3 className="card-title align-items-start flex-column mb-0">
                            <p className="card-title-subheading">{key0.title}</p>
                        </h3>
                    </div>

                    <div className="card-body position-relative overflow-hidden">
                        <div className="your-badges--container">
                            <div className="your-badge--row d-flex">
                                {key0.categories.map((key1,i) => 
                                <div key={i} className="your-badges--col2">
                                    <div className="your-badges--heading d-flex align-items-center justify-content-between">{key1.title} 
                                    <OverlayTrigger trigger="hover"  placement="top" overlay={popover(key1.description)}>
                                                <img  src="/media/ms-media/info-circle-icn.svg" alt="info" style={{cursor:"pointer"}} />
                                    </OverlayTrigger>
                                    </div>
                                        <div className="your-badges--body">
                                            <div className="row col--innercard-gutter">
                                                {
                                                    key1.badges.map((key2,i) => 
                                                        <div key={i} className="col-lg-6 col-sm-3 col-6">                                                    
                                                            <div className={key2.achieved ? "your-badges--card text-center":"your-badges--card text-center disabled"}>
                                                                <img src={badgesUrl(key2.icon_file)} alt={key2.icon_file} />
                                                                <p>{key2.title}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
)}
</div>




                    </div>
                </div>
</>

  );
}
