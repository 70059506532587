/* eslint-disable */ 

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

export function WhoAttendsMostMeeting() {

    const { companyData } = useSelector(state => state.company);

    const [state, setState] = useState({
        series: [
            {
                name: 'External',
                data: [],
            }, {
                name: 'Internal',
                data: [],
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true,
                    tools:{
                      download:true // <== line to add
                    }
                  },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 0,
            },
            title: {
                text: 'Attendees',
                align: 'left',
                style: {
                    fontSize: '12px',
                    color: '#BFC2C7'
                },
            },
            colors: ['#F6F062', '#FCAC34'],
            dataLabels: {
                enabled: false
            },
            legends: {
                show: true
            },
            grid: {
                show: true,
                borderColor: '#F8F9FB',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                row: {
                    colors: undefined,
                    opacity: 0.5
                },
                column: {
                    colors: undefined,
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: [],
                labels: {
                    style: {
                        colors: "#999EA5",
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                    offsetX:0,
                    formatter: function (val) {
                        return val;
                    }
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: true,
                    borderType: 'dotted',
                    color: '#F4F5F8',
                    height: 25,
                },
                title: {
                    text: "# of meetings",
                    offsetX: -60,
                    style: {
                        fontSize: '12px',
                        color: '#999EA5',
                        fontWeight: "light"
                    },
                }
            },
            yaxis: {
                min:0,
                show: true,
                forceNiceScale: true,
                floating: false,
                labels: {
                    style: {
                        colors: "#999EA5",
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                    formatter: function (val) {
                        return val;
                    }
                },
            },
            tooltip: {
                fillSeriesColor: false,
                y: {
                    show: true,
                    formatter: function (val) {
                        return val + " meeting(s)";
                    }
                    },
                x: {
                    show: true,
                    
                },
                marker: {
                    show: true,
                },
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: "top",
                show: true,
                horizontalAlign: "right",
                offsetX: 40
                }
            }
    });

    useEffect(() => {
            
            let rawData = [];
            let categories = [];
            let series = [
                {
                    name: 'External',
                    data: [],
                }, {
                    name: 'Internal',
                    data: [],
                }
        ]
            
            if(companyData && companyData.events && companyData.events.length){

                companyData.events.map(meeting => {

                    let accepted_users = meeting.event_accepted_ids;
                    let accepted_ids_arr = accepted_users.split(',');

                    accepted_ids_arr.map(accepted_user_id => {

                    let eventUsersList = [];

                    for (let key in meeting.event_users){
                                if(meeting.event_users[key].user_id == accepted_user_id){
                                    eventUsersList.push(meeting.event_users[key]);
                                }
                    }

                        eventUsersList.map(eventUser => { 
                                    let user_exists_index = rawData.findIndex(element => element.user_id == eventUser.user_id);
                                        if(user_exists_index == -1){
                                                rawData.push({
                                                                user_id:eventUser.user_id,
                                                                user:eventUser.user_fullname,
                                                                totalMeetings:1,
                                                                meetingType:[{
                                                                                internal:meeting.event_type == "internal" ? 1 : 0,
                                                                                external:meeting.event_type == "external" ? 1 : 0
                                                                }]
                                                })
                                        }else{
                                                if(meeting.event_type == "internal"){
                                                    rawData[user_exists_index].totalMeetings = rawData[user_exists_index].totalMeetings + 1; 
                                                    rawData[user_exists_index].meetingType[0].internal = rawData[user_exists_index].meetingType[0].internal + 1;
                                                }else if(meeting.event_type == "external" ){
                                                    rawData[user_exists_index].totalMeetings = rawData[user_exists_index].totalMeetings + 1;
                                                    rawData[user_exists_index].meetingType[0].external = rawData[user_exists_index].meetingType[0].external + 1;
                                                }      
                                        }
                            })      
                })
            })
            }
            
            // console.log('raw data for unique users in meetings');

            let sortedData =  rawData.sort( function ( a, b ) { return  b.totalMeetings - a.totalMeetings   } );
            let slicedData = sortedData.slice(0,10);
            // console.log('data sliced for top ten people with maximum meetings');

          if(slicedData.length){
                for (let key in slicedData){
                categories.push(slicedData[key].user);
                series[0].data.push(slicedData[key].meetingType[0].external)
                series[1].data.push(slicedData[key].meetingType[0].internal)
            }
            // console.log(categories);
            // console.log(series);
        
        }

        
            setState({
                ...state,
                  options:{
                    ...state.options,
                          xaxis:{
                            ...state.options.xaxis,
                              categories:categories
                          },
                          yaxis:{
                              ...state.options.yaxis,
                              max:(series[0].data[0] + series[1].data[0] + 5)
                          }
                  },
                series:series
                 
            })


    },[companyData && companyData.events])

    return (
        <>
         {companyData && companyData.events && companyData.events.length > 0 ?
            <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
            :
            <div className='loader-container'>
          { companyData == null ?  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                </Spinner> : (companyData && companyData.events.length == 0 ? "We're sorry, for the time you requested, we don't have any data to share with you." : null)}
       </div>
        }
        </>
    );
}
