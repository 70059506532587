/* eslint-disable */

import React, { Suspense, useEffect, useState } from "react";
import {
  Switch,
  useLocation,
  useHistory,
  Link,
  Redirect,
} from "react-router-dom";
import { Modal } from "react-bootstrap";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useDispatch, useSelector } from "react-redux";
import { getUnratedMeetingsCount } from "../redux/duck/dashboard/unrated/unrated.action";
import {
  getLocalStorageUserDetail,
  getProfileInfoDetail,
  GET_LOCALSTORAGE_USER_DETAIL,
} from "../redux/duck/userProfile/userProfile.action";
import { getStorage, removeStorage } from "../_metronic/_helpers";
import { AlertPopup } from "../_metronic/_helpers/Alerts";
import { routeDef } from "./routes/routeDef";
import "./base_page.css";
import axios from "axios";
import { Apistore } from "../redux/apiStore";
import { useGoogleLogin } from '@react-oauth/google';

export default function BasePage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const localUserData = useSelector(
    (state) => state.profile.getLocalStorageUserData
  );
  const tokenExpiredError = useSelector((state) => state.profile.error);
  const [alert, setAlert] = useState({
    show: null,
    title: null,
    message: null,
    errType: null,
  });

  const [isNew, setNewUser] = useState(false);
  const [showPermissionPopup, setShowPermissionPopup] = useState(false);

  useEffect(()=>{
    if (getStorage('WELCOME_BACK') == 1) {
      axios.post(Apistore.ActivateUser).then((res)=>console.log(''))
    }
  },[])

  useEffect(() => {
    if (location.pathname !== "/unrated") {
      dispatch(getUnratedMeetingsCount({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      localUserData?.user_data?.user_read_scope == 0 &&
      localUserData?.user_data?.provider_id == "1"
    ) {
      setShowPermissionPopup(true);
    }
    if (localUserData?.user_data?.user_read_scope == 1) {
      setShowPermissionPopup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localUserData]);


  useEffect(() => {
    dispatch({ type: GET_LOCALSTORAGE_USER_DETAIL, data: {} });
  }, []);

  useEffect(() => {
    if (tokenExpiredError && tokenExpiredError.code == "440") {
      history.push({
        pathname: "/logout",
        state: {
          by: "code",
        },
      });
    }
  }, [tokenExpiredError]);
  useEffect(() => {
    let newUserCheck = getStorage("IS_NEW_USER");
    if (
      localUserData?.user_data?.provider_id == "1" &&
      localUserData?.user_data?.provider_id != undefined &&
      localUserData?.user_data?.provider_id != null
    ) {
      if (
        newUserCheck !== null &&
        newUserCheck !== undefined &&
        newUserCheck == 1 &&
        localUserData?.user_data?.user_read_scope !== undefined &&
        localUserData?.user_data?.user_read_scope != 0
      ) {
        setTimeout(() => {
          setNewUser(true);
        }, 5000);
      }
    } else if(
      localUserData?.user_data?.provider_id == "2" &&
      localUserData?.user_data?.provider_id != undefined &&
      localUserData?.user_data?.provider_id != null) {
      
      if (
        newUserCheck !== null &&
        newUserCheck !== undefined &&
        newUserCheck == 1
      ) {
        setTimeout(() => {
          setNewUser(true);
        }, 5000);
      }
    }
  }, [localUserData]);

  const handleClose = () => {
    setNewUser(false);
    removeStorage("IS_NEW_USER");
  };

  const updateScopeInDB = (success, msg) => {
    axios.post(Apistore.UpdateGoogleScope, {
        email:localUserData?.user_data.email,
        scope:success.scope,
        access_token:success.access_token
      })
      .then((res) => {
        if (res.data.code == 200) {
          dispatch(getLocalStorageUserDetail({}));
          dispatch(getProfileInfoDetail({}));
          setShowPermissionPopup(false);
        }else if (res.data.code !== 200) {
          setAlert({
            show: true,
            message: res?.data.message,
            errType: "failure",
          });
        }
      })
      .catch((e) => {
        setAlert({
          show: true,
          message: "Something went wrong",
          errType: "failure",
        });
        console.log(e);
      });
  };

  const handelLogoutButton = () => {
    history.push({
      pathname: "/logout",
      state: {
        by: "code",
      },
    });
  };

  const handleCloseAlert = () => {
    setAlert({
      show: false,
      message: "",
      title: "",
      errType: "",
    });
  };

  const login = useGoogleLogin({
    onSuccess: codeResponse =>updateScopeInDB(codeResponse, "Permissions added successfully"),
    // ux_mode:'redirect'
    // select_account:true,
    flow: 'implicit',
    scope:'https://www.googleapis.com/auth/calendar.events.readonly'
  });

  return (
    <>
      {alert.show && (
        <AlertPopup
        show={alert.show}
          handleClose={handleCloseAlert}
          title={alert.title}
          message={alert.message}
          errType={alert.errType}
        />
      )}

      <Modal
        className="time_machine-modal-small"
        // show={ false }
        show={showPermissionPopup}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Body className="text-center">
          <h4 className="modal-sm-title">
            Hi{" "}
            {localUserData &&
              localUserData.user_data &&
              localUserData.user_data.user_firstname}
          </h4>
          <p>
            Welcome to SmartMeetings by MeetingScience! In order to make your
            meetings smart, you need to grant permission to the application to
            analyze your calendar and provide recommendations for how to improve
            your meeting health. Your calendar data is safe, secure, and the
            recommendations are only shared with you.
          </p>
          <div className="modal_btns d-flex align-items-center">
            <p
              className="btn btn-primary flex-grow-1"
              onClick={login}
            >
              Click here to add permission
            </p>
          </div>
          <p className="logout-button" onClick={handelLogoutButton}>
            Logout
          </p>
        </Modal.Body>
      </Modal>

      {localUserData &&
      localUserData.user_data &&
      localUserData.user_data.user_phone &&
      localUserData.user_data.user_firstname &&
      isNew ? (
        <Modal
          className="time_machine-modal-small"
          show={isNew}
          onHide={handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <p className="cross_icon" onClick={handleClose}>
            &#10006;
          </p>
          <Modal.Body className="text-center">
            <h4 className="modal-sm-title">
              Hi{" "}
              {localUserData &&
                localUserData.user_data &&
                localUserData.user_data.user_firstname}
            </h4>
            <img src="/media/ms-media/wait-icn.svg" alt="" />
            <p>
              Welcome to Meeting Science! We request you to complete one last
              step. Kindly verify your phone number.
            </p>
            <div className="modal_btns d-flex align-items-center">
              <Link
                to="/user-profile/edit-personal-info"
                className="btn btn-primary flex-grow-1"
                onClick={handleClose}
              >
                Click to Verify!
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}

      {localUserData &&
      localUserData.user_data &&
      localUserData.user_data.user_phone == "" &&
      localUserData.user_data.user_firstname &&
      isNew ? (
        <Modal
          className="time_machine-modal-small"
          show={isNew}
          onHide={handleClose}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <p className="cross_icon" onClick={handleClose}>
            &#10006;
          </p>
          <Modal.Body className="text-center">
            <h4 className="modal-sm-title">
              Hi{" "}
              {localUserData &&
                localUserData.user_data &&
                localUserData.user_data.user_firstname}
            </h4>
            <img src="/media/ms-media/wait-icn.svg" alt="" />
            <p>Welcome to Meeting Science!</p>
          </Modal.Body>
        </Modal>
      ) : null}

      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {routeDef.map((routelist) => {
            return routelist.private.map((privatelist) => {
              return privatelist.pages.map((routes) => {
                if (
                  routes.name !== "/company" &&
                  routes.name !== "/time-machine"
                ) {
                  return (
                    <ContentRoute
                      path={routes.name}
                      component={routes.component}
                    />
                  );
                } else if (routes.name === "/company") {
                  return (
                    <ContentRoute
                      path={routes.name}
                      component={
                        ((localUserData &&
                          localUserData.user_data &&
                          localUserData.user_data.user_executive) ||
                          (localUserData &&
                            localUserData.user_data &&
                            localUserData.user_data.company_domain ==
                              "zoom.us") ||
                            (localUserData &&
                              localUserData.user_data &&
                              localUserData.user_data.company_domain ==
                                "zoomappsec.us") ||
                            (localUserData &&
                              localUserData.user_data &&
                              localUserData.user_data.email ==
                                "development@meetingscience.io")) &&
                        routes.component
                      }
                    />
                  );
                } else if (routes.name === "/time-machine") {
                  return (
                    <ContentRoute
                      path={routes.name}
                      component={
                        localUserData &&
                        localUserData.user_data &&
                        localUserData.user_data.user_write_scope &&
                        routes.component
                      }
                    />
                  );
                }
              });
            });
          })}

          {<Redirect from="/" to={"/meeting-mastery"} />}
        </Switch>
      </Suspense>
    </>
  );
}
