/* eslint-disable */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileInfoDetail,
  GET_LOCALSTORAGE_USER_DETAIL,
  updateFeedbackSettingsAction,
} from "../../../../redux/duck/userProfile/userProfile.action";
import { usePrevious } from "../../../_helpers/Custom";
import { Navbar } from "./Navbar";
import _, { isArray } from "lodash";
import { AlertPopup, ConfirmAlert } from "../../../_helpers/Alerts";
import { LayoutSplashScreen } from "../../../layout";
import Axios from "axios";
import { sendApiError } from "../../../../app/modules/Auth/_redux/authCrud";
import { Apistore } from "../../../../redux/apiStore";
import { checkIsActive } from "../../../_helpers";
import { Spinner } from "react-bootstrap";
import { authUserId, formatTime } from "../../../_helpers/functions";
import "./feedback.css";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Select from "react-select";
import './dashboard.css'

export const FeedbackSettings = () => {
  // Set Initial Values
  // const user_id = authUserId();
  const dispatch = useDispatch();
  const localUserData = useSelector(
    (state) => state.profile.getLocalStorageUserData
  );

  const profileData = useSelector(
    (state) => state.profile.getProfileInfoDetailData
  );
  const prevProfileData = usePrevious({ profileData });
  const notificationData = useSelector(
    (state) => state.profile.updateFeedbackSettingsData
  );
  const prevNotificationData = usePrevious({ notificationData });
  const defauInputltRef = useRef();
  const defauInputltRef2 = useRef();
  const defauInputltRef3 = useRef();
  const meetingFeedbackRef = useRef();
  const internalRef = useRef();
  const saveButtonRef = useRef();
  const MondayRef = useRef();
  const TuesdayRef = useRef();
  const WednesdayRef = useRef()
  const ThursdayRef = useRef()
  const FridayRef = useRef()
  // Loader State
  const [loader, setLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [alert, setAlert] = useState({
    show: false,
    title: null,
    message: null,
    errType: null,
  });
  const [isVisible, setIsVisible] = useState(false);
  const [focusStyle, setFocusStyle] = useState({});
  const [focusStyle5, setFocusStyle5] = useState({
    borderColor: "#00ACBF",
    color: "#00ACBF",
    background: "#D3F5F9",
  });
  const [focusStyle2, setFocusStyle2] = useState({
    one: {},
    two: {},
    three: {},
    four: {},
    five: {},
  });
  const [dayFocusStyle,setDayFocusStyle]=useState({
    Monday:{},
    Tuesday:{},
    Wednesday:{},
    Thursday:{},
    Friday:{},
  })
  const [styles, setStyles] = useState({
    meetingFeedback: {},
    internalBox: {},
    externalBox: {},
    internalExternalBox: {},
    privateMeetingY: {},
    privateMeetingN: {},
  });
  const [notLastField, setNotLastField] = useState({
    meetingFeedback: false,
    whichMeeting: false,
    timeForMF: false,
    meetingSize: false,
    excludeKey:false
  });
  const [styleAllowed, setStyleAllowed] = useState({
    whichMeeting: true,
    privateMeeting: true,
  });
  const [excludeKeywords, setExcludeKeyword] = useState("");
  const [warningKey,setWarningKey]=useState('');
  const [isExDisabled, setIsExDisabled] = useState(false)
  const [state, setState] = useState({
    user_id: "",
    user_name: "",
    user_firstname: "",
    user_phone: "",
    userEmail: "",
    country_code: "",
    feedback_sms: "0",
    snooze_feedback_evaluation: "0",
    snooze_feedback_notification: "0",
    feedback_weekly_reminder: "0",
    feedback_event_type: "",
    feedback_private_meeting: "",
    feedback_meeting_size: "",
    feedback_meeting_time_of_day: {start: '', end: ''},
    workingHour: [9, 18],
    coachingReport: [],
    feedback_evaluation: "",
    feedback_excluded_keyword:[],
    user_id:''
  });
  const [defaultValues, setDefaultValues]= useState({
    default_feedback_excluded_keyword:[],
    default_feedback_event_type:'',
    default_feedback_meeting_size:'',
    feedback_meeting_time_of_day:[]
  })

  //pass id as param
  useEffect(() => {
    setLoader(true);
    dispatch(getProfileInfoDetail({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // fetch Notification data and handle error popup
  useEffect(() => {
    if (prevProfileData && prevProfileData.profileData !== profileData) {     
      if (
        profileData &&
        _.has(profileData, "feedback_setting") &&
        profileData.status == "success"
        ) {
          const userData = profileData.user_data;
          const userNotificationData = profileData.feedback_setting;
          const defaultValues = profileData.default
          
        setState({
          ...state,
          user_name: userData.user_name,
          user_firstname: userData.user_firstname,
          user_phone: userData.user_phone,
          userEmail:  userData.email,
          country_code: userData.country_code,
          feedback_sms:
            userNotificationData.feedback_sms == false ||
            userNotificationData.feedback_sms == 0
              ? 0
              : userNotificationData.feedback_sms,
          snooze_feedback_evaluation:
            userNotificationData.snooze_feedback_evaluation,
          snooze_feedback_notification:
            userNotificationData.snooze_feedback_notification,
          feedback_weekly_reminder:
            userNotificationData.feedback_weekly_reminder,

          feedback_event_type:          userNotificationData.feedback_event_type,
          feedback_meeting_size:        userNotificationData.feedback_meeting_size,
          feedback_meeting_time_of_day: userNotificationData.feedback_meeting_time_of_day,
          // feedback_private_meeting:     userNotificationData.feedback_private_meeting,
          workingHour:                  Object.values(userNotificationData?.feedback_meeting_time_of_day).map((e) => e?.replace(":00", "")).map((e)=>Number(e)),
          feedback_evaluation:          userNotificationData.feedback_evaluation,
          // feedback_excluded_keyword:    userNotificationData.feedback_excluded_keyword
          feedback_excluded_keyword:    userNotificationData?.feedback_excluded_keyword?.length > 1 ? userNotificationData?.feedback_excluded_keyword :defaultValues.feedback_excluded_keyword,
          user_id:                      userData?.user_id
        });
        setDefaultValues({
          ...defaultValues,
          // default_feedback_excluded_keyword:[],
          default_feedback_event_type:defaultValues.feedback_event_type,
          default_feedback_meeting_size:defaultValues.feedback_meeting_size,
          feedback_meeting_time_of_day:Object.values(defaultValues.feedback_meeting_time_of_day).map((e) => e?.replace(":00", "")).map((e)=>Number(e))
        })
        setLoader(false);
      }
      if (
        profileData &&
        _.has(profileData, "message") &&
        profileData.status == "error"
      ) {
        if (profileData.open_popup == false) {
          setLoader(false);
          setAlert({
            show: true,
            message: "Something went wrong!",
            title: "Error",
            errType: "failure",
          });
        }
      }
    }
    if (
      prevNotificationData &&
      prevNotificationData.notificationData !== notificationData
    ) {
      if (
        notificationData &&
        _.has(notificationData, "message") &&
        notificationData.status == "success"
      ) {
        dispatch(getProfileInfoDetail({}));
        setLoader(false);
        setDisabled(true);
      }
      if (
        notificationData &&
        _.has(notificationData, "message") &&
        notificationData.status == "error"
      ) {
        if (notificationData.open_popup == false) {
          setLoader(false);
          setAlert({
            show: true,
            message: "Something went wrong!",
            title: "Error",
            errType: "failure",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProfileData, profileData, prevNotificationData, notificationData]);

  useEffect(()=>{
    if (profileData && profileData?.override) {
      let overrideChecks = []
      overrideChecks.push(profileData?.override?.feedback_evaluation)
      overrideChecks.push(profileData?.override?.feedback_event_type)
      overrideChecks.push(profileData?.override?.feedback_meeting_time_of_day)
      overrideChecks.push(profileData?.override?.feedback_meeting_size)
      overrideChecks.push(profileData?.override?.rating_excluded_keyword)
      let meetingFeedback = overrideChecks.slice(-4)?.includes(1)
      let whichMeeting = overrideChecks.slice(-3)?.includes(1)
      let timeForMF = overrideChecks.slice(-2)?.includes(1)
      let meetingSize = overrideChecks.slice(-1)?.includes(1)
        setNotLastField({
          meetingFeedback: meetingFeedback,
          whichMeeting: whichMeeting,
          timeForMF: timeForMF,
          meetingSize: meetingSize,
          excludeKey:false
        })
      // if (two) {
      //   setNotLastField({
      //     ...notLastField,whichMeeting : true
      //   })
      // }
      // if (timeForMF.includes(1)) {
      //   setNotLastField({
      //     ...notLastField,timeForMF : true
      //   })
      // }
    }

  },[profileData])


  // handle close for alert popup
  const handleClose = () => {
    setAlert({
      show: false,
    });
  };

  // handle Cancel button
  const handleCancel = () => {
    setStyleAllowed({ whichMeeting: true, privateMeeting: true });
    setIsVisible(false);
    setDisabled(true);
    setLoader(true);
    dispatch(getProfileInfoDetail({}));
  };

  //handel edit button
  const handelEdit = () => {
    setDisabled(false);
    setIsVisible(true);
    setWarningKey(``)
    setExcludeKeyword('')
  };

  useEffect(() => {
    if (isVisible) {
      if (state.feedback_sms == 0) {
        defauInputltRef2.current.focus();
        setFocusStyle2({ two: { background: "#008D9D", color: "white" } });
        // setFocusStyle2({two:{background:'#8cced6',color:'#68798f'}})
      } else {
        defauInputltRef.current.focus();
        // setFocusStyle({background:'#8cced6',color:'828b95'})
        setFocusStyle({ background: "#008D9D", color: "white" });
      }
    }
  }, [isVisible]);

  const handelKeyDown1 = (e) => {
    if (e.key === "Tab") {
      if (state.snooze_feedback_evaluation == 0) {
        setFocusStyle2({ one: { background: "#008D9D", color: "white" } });
      } else {
        setFocusStyle2({ three: { background: "#008D9D", color: "white" } });
      }
    } else if (e.key === "Enter") {
      setFocusStyle({});
      setFocusStyle2({ two: {} });
    }
  };
  const handelKeyDown2 = (e) => {
    if (e.key === "Tab") {
      if (state.snooze_feedback_notification == 0) {
        setFocusStyle2({
          ...focusStyle2,
          four: { background: "#008D9D", color: "white" },
        });
      } else {
        setFocusStyle2({
          ...focusStyle2,
          five: { background: "#008D9D", color: "white" },
        });
      }
    } else if (e.key === "Enter") {
      setFocusStyle2({ three: {} });
      setFocusStyle2({ one: {} });
    }
  };

  // Meetings You Attend section keyDown
  const handelKeyDown3 = (e) => {
    if (e.key === "Tab") {
      setTimeout(() => {
        defauInputltRef3.current.focus();
      }, 2);
      setFocusStyle5({ background: "#008D9D", color: "white" });
    } else if (e.key === "Enter") {
      setFocusStyle2({});
      // setFocusStyle3({});
    }
  };

  //Zoom setion
  const handelKeyDown4 = (e) => {
    // if (e.key === "Tab") {
    //   setIsVisible(false);
    //   setTimeout(() => {
    //     saveButtonRef.current.focus();
    //   }, 2);
    // }
    if (e.key === "Tab") {
      // setIsVisible(false);
      setStyles({ meetingFeedback: { outline: "solid #008e9d" } });
      setTimeout(() => {
        meetingFeedbackRef.current.focus();
      }, 2);
    }
  };
  const handelKeyDown5 = (e) => {
    if (e.key === "Tab") {
      // setIsVisible(false);
      // setStyles({meetingFeedback:{outline:'solid'}})
      setTimeout(() => {
        saveButtonRef.current.focus();
      }, 2);
    }
  };

  // save notification data
  const saveSettings = () => {
    let workingHours = state?.workingHour
    if (workingHours[0] == 0 && workingHours[1] == 0 ) {
      workingHours = [0,1]
      
    } else if (workingHours[0] == 23 && workingHours[1] == 23 ){
      workingHours = [22,23]
    }else if (workingHours[0] == workingHours[1]) {
      workingHours[0]= workingHours[1] - 1
    }
    // state.feedback_meeting_time_of_day
   let objWH = {start: workingHours[0] + ':00', end: workingHours[1]+ ':00'}
    const notificationData = {
      feedback_sms: state.feedback_sms,
      snooze_feedback_evaluation: state.snooze_feedback_evaluation,
      snooze_feedback_notification: state.snooze_feedback_notification,
      feedback_weekly_reminder: state.feedback_weekly_reminder,


      // feedback_private_meeting: state.feedback_private_meeting,
      feedback_event_type         : state.feedback_event_type ,
      feedback_meeting_size       : state.feedback_meeting_size,
      feedback_meeting_time_of_day: objWH,
      // feedback_meeting_time_of_day: state.feedback_meeting_time_of_day != '' ? state.feedback_meeting_time_of_day : [9,18],
      feedback_evaluation         : state.feedback_evaluation,
      feedback_excluded_keyword   : state?.feedback_excluded_keyword 
      };
    setIsVisible(false);
    setLoader(true);
    setStyleAllowed({ whichMeeting: true, privateMeeting: true });
    dispatch(updateFeedbackSettingsAction(notificationData));
  };

  //day for meeting feedback section onchange
  const handleNotificationInput = (e) => {
    const { value } = e.target;
    let newArr = [...state.coachingReport];
    let checkValue = newArr.includes(value);
    const dayVal = newArr.findIndex(checkVal);
    if (checkValue === false) {
      newArr.push(value);
      setState({ ...state, coachingReport: newArr });
    } else {
      newArr.splice(dayVal, 1);
      setState({ ...state, coachingReport: newArr });
    }
    function checkVal(val) {
      // eslint-disable-next-line eqeqeq
      return val == value;
    }
  };

  const submitRequestForZoomUninstall = () => {
    closeConfirmAlert();
    Axios.post(Apistore.RemoveZoomApp, {
      user_id: state?.user_id,
    })
      .then((res) => {
        setAlert({
          show: true,
          message: res.data.msg,
          title: "Zoom App",
          errType: "success",
        });
        setLoader(false);
        dispatch({ type: GET_LOCALSTORAGE_USER_DETAIL, data: {} });
      })
      .catch((err) => {
        sendApiError(500, err, Apistore.RemoveZoomApp);
        setAlert({
          show: true,
          message: err.data.msg,
          title: "Zoom App",
          errType: "failure",
        });
        setLoader(false);
      });
  };

  const closeConfirmAlert = () => {
    setLoader(false);
    setConfirmAlertOptions({
      ...confirmAlertOptions,
      show: false,
    });
  };

  const [confirmAlertOptions, setConfirmAlertOptions] = useState({
    show: null,
    title: "Please Confirm",
    description:"Are you sure you want to uninstall Zoom ? Don't worry, you can come back and install it again anytime.",
  });

  const openConfirmAlert = () => {
    setConfirmAlertOptions({
      ...confirmAlertOptions,
      show: true,
    });
  };

  const openZoom = (e) => {
    e.preventDefault();
    if (localUserData && localUserData.user_data.install_zoom == 0) {
      window.open(process.env.REACT_APP_ZOOM_URL);
    } else {
      setLoader(true);
      openConfirmAlert();
    }
  };

  const [data2, setData2] = useState({
    workingHour: [9, 18],
    meeting_start_time: "9",
    meeting_end_time: "6pm",
  });
  const useStyles = makeStyles({
    root: {
      width: 500,
    },
  });
  const classes = useStyles();

  const marks = [
    {
      value: 0,
      label: "12:01 am",
    },
    {
      value: 24,
      label: "11:59 pm",
    },
  ];
  function valuetext(value) {
    return `${value}`;
  }
  const handleSlider = (event, newValue) => {
    setState({
      ...state,
      workingHour: newValue,
      // feedback_meeting_time_of_day: newValue,
    });
  };

  //--------------- dropdoen code start-------------------------
  let MeetingSizeOption = [];
  for (let i = 2; i <= 50; i++) {
    MeetingSizeOption.push({ value: i, label: i + "+" });
  }

  //--------------- dropdown code end-------------------------

  useEffect(()=>{

    if (state.feedback_excluded_keyword.length >99) {
      setIsExDisabled(true)
      setWarningKey(`Can't save more than 100 keyword.`)
      let arrLength = state.feedback_excluded_keyword.length - 100
      for (let i =0; i <= arrLength ; i++) {
        if (state.feedback_excluded_keyword.length > 100) {
          state.feedback_excluded_keyword.pop()
        }
      }
    } else if (state.feedback_excluded_keyword.length === 1){
      // setWarningKey(``)
    } else{
      setWarningKey(``)
      setIsExDisabled(false)
    }
  },[state.feedback_excluded_keyword])

  const handelExcludeKeywords = (e) => {

    let tempExcludeKeywords =excludeKeywords
    if (excludeKeywords !=='' && tempExcludeKeywords.trim().length >0) {
      //converting input value into an array
      let step1 = excludeKeywords.split(",");
      //removing special charectrs from step1 and merging with feedback_excluded_keywords array
      let step2 = [...state.feedback_excluded_keyword, ...step1.map((e)=>
        {
          return(
          {title:e?.replace(/[^a-zA-Z0-9\-,/ ]/g, "")?.trim(),type:'user'}
        )
        })];
      //removing same words from step2 
      let step3 = step2.reduce((unique, o) => {
        if(!unique.some(obj => obj?.title.toLowerCase() === o?.title.toLowerCase() && o?.title !='')) {
          unique.push(o);
        }
        return unique;
    },[]);
      const unique = [];
      step3.filter((e) => e?.title.trim() !=''&& e.title.length > 2)
      step3.map((x) =>
      unique.filter((a) => a.title.trim().toLowerCase() == x.title.trim().toLowerCase()).length > 0 ? null : unique.push(x)
  );

    setState({...state,feedback_excluded_keyword:unique.filter((e) => e?.title.trim() !=''&& e.title.length > 2 && e.title.length < 21)});
    setExcludeKeyword("");
    }else{
      setWarningKey(`Can't save empty keyword.`)
      setExcludeKeyword("");
  }
  };

  const removeExcludeKeyword = (index) => {
		setState({...state,feedback_excluded_keyword:state.feedback_excluded_keyword.filter((o, i) => index !== i)});
	};

  let daysRef = [MondayRef,TuesdayRef,WednesdayRef,ThursdayRef,FridayRef]

  // handel days keydoun
  // const handelDaysKeyDown = (e)=>{
  //   let index = Number(e.target.value)
  //   let name = e.target.name
  //   if (e.key === 'Enter') {
  //     setDayFocusStyle({...dayFocusStyle,[name]:{}})
  //     handleNotificationInput(e)
  //   }
  //   else if (e.key === 'ArrowRight') {
      
  //     setTimeout(() => {
  //       daysRef[index === 5 ? 0 : index].current.focus()
  //     }, 3);
  //   }
  //   else if (e.key === 'ArrowLeft') {
  //     let preIndex = index-2
  //     setTimeout(() => {
  //       daysRef[preIndex === -1 ? 4 : preIndex].current.focus()
  //     }, 3);
  //   }
  //   else if(e.key==='Tab'){
  //     e.target.blur();
  //   }
  // }
  

  return (
    <>
      <LayoutSplashScreen visible={loader} />
      {alert.show && (
        <AlertPopup
          show={alert.show}
          handleClose={handleClose}
          title={alert.title}
          message={alert.message}
          errType={alert.errType}
        />
      )}
      <div className="profile-container flex-column h-100 d-flex">
        <div className="nav-container d-flex justify-content-between feedback_navigation">
          <Navbar getMainRoute={"feedback"} />
          <div className="edit-button">
            {disabled ? (
              <button className="btn btn-edit" onClick={handelEdit}>
                Edit
              </button>
            ) : (
              <>
                <button
                  className="btn btn-edit mr-2"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
                <input
                  type="button"
                  ref={saveButtonRef}
                  className="btn btn-primary"
                  onClick={saveSettings}
                  value="Save Changes"
                />

                {/* <button className="btn btn-primary" onClick={() => saveSettings()}>
              Save Changes
            </button> */}
              </>
            )}
          </div>
        </div>
        <div className="tab-content bg-transparent flex-grow-1 feedback_profileTab" id="profileTab">
          <div className="card card-custom">
            <div className="card-body">
              <div
                className="tab-pane fade show active"
                id="Notifications"
                role="tabpanel"
                aria-labelledby="Notifications-tab"
              >
                <div className="Notifications-wrap">
                  <p>
                    <strong>
                      {state.user_firstname
                        ? state.user_firstname
                        : state.user_name}
                      ,
                    </strong>{" "}
                    you can adjust how often you send or receive requests for
                    feedback on the meetings you organize or attend here.
                  </p>
                  <div className="row">
                    {/* {state.user_phone !== "" ?  */}
                    <div className="col-md-6">
                      <div className="card mt-7">
                        <div className="card-body">
                          <h5>Meetings You Organize</h5>
                          <p></p>
                          <div className="row">
                            <div className="col-xl-8">
                              <p>
                                Receive a reminder SMS in the morning to provide
                                feedback on your meetings from the previous day.
                                We'll only send you a maximum of one text per
                                morning.
                              </p>
                            </div>

                            <div className="col-xl-4">
                              <div
                                onKeyDown={handelKeyDown1}
                                className="auth-selectgroup"
                              >
                                <label
                                  onBlur={() => {
                                    setFocusStyle({ ...focusStyle2, two: {} });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                    className="selectgroup-input"
                                    ref={defauInputltRef}
                                    disabled={disabled ? true : false}
                                    onChange={() =>
                                      setState({ ...state, feedback_sms: 1 })
                                    }
                                    type="radio"
                                    name="feedback_sms"
                                    checked={
                                      state.feedback_sms == 1 ? true : false
                                    }
                                    id="1"
                                    value={1}
                                  />
                                  <span
                                  style={{...focusStyle,cursor: disabled ?'unset':'pointer'}}
                                    // style={focusStyle}
                                    className="selectgroup-button"
                                  >
                                    Yes
                                  </span>
                                </label>
                                <label
                                  onBlur={() => {
                                    setFocusStyle2({ ...focusStyle2, two: {} });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                    ref={defauInputltRef2}
                                    className="selectgroup-input"
                                    disabled={disabled ? true : false}
                                    onChange={() =>
                                      setState({ ...state, feedback_sms: 0 })
                                    }
                                    type="radio"
                                    name="feedback_sms"
                                    checked={
                                      state.feedback_sms == 0 ? true : false
                                    }
                                    id="0"
                                    value={0}
                                  />
                                  <span
                                  style={{...focusStyle2.two,cursor: disabled ?'unset':'pointer'}}
                                    // style={focusStyle2.two}
                                    className="selectgroup-button"
                                  >
                                    No
                                  </span>
                                </label>
                              </div>
                            </div>

                            <div className="col-xl-8">
                              <p className="feedback_meetingreport_text">
                                Temporarily snooze feedback on your meetings for
                                30 days. This means that MeetingScience will
                                pause sending emails to your meeting
                                participants after your meetings to ask for
                                their feedback.
                              </p>
                            </div>

                            <div
                              onKeyDown={handelKeyDown2}
                              className="col-xl-4"
                            >
                              <div className="auth-selectgroup">
                                <label
                                  onBlur={() => {
                                    setFocusStyle2({
                                      ...focusStyle2,
                                      three: {},
                                    });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                    // onBlur={() => {
                                    //   setFocusStyle2({...focusStyle2,three:{}});
                                    // }}
                                    className="selectgroup-input"
                                    disabled={disabled ? true : false}
                                    onChange={() =>
                                      setState({
                                        ...state,
                                        snooze_feedback_evaluation: 1,
                                      })
                                    }
                                    type="radio"
                                    name="snooze_feedback_evaluation"
                                    checked={
                                      state.snooze_feedback_evaluation == 1
                                        ? true
                                        : false
                                    }
                                    id="1"
                                    value={1}
                                  />
                                  <span
                                  style={{...focusStyle2.three,cursor: disabled ?'unset':'pointer'}}
                                    // style={focusStyle2.three}
                                    className="selectgroup-button"
                                  >
                                    Yes
                                  </span>
                                </label>
                                <label
                                  onBlur={() => {
                                    setFocusStyle2({ ...focusStyle2, one: {} });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                    // onBlur={() => {
                                    //   setFocusStyle2({focusStyle2,one:{}});
                                    // }}
                                    className="selectgroup-input"
                                    disabled={disabled ? true : false}
                                    onChange={() =>
                                      setState({
                                        ...state,
                                        snooze_feedback_evaluation: 0,
                                      })
                                    }
                                    type="radio"
                                    name="snooze_feedback_evaluation"
                                    checked={
                                      state.snooze_feedback_evaluation == 0
                                        ? true
                                        : false
                                    }
                                    id="0"
                                    value={0}
                                  />
                                  <span
                                  style={{...focusStyle2.one,cursor: disabled ?'unset':'pointer'}}
                                    // style={focusStyle2.one}
                                    className="selectgroup-button"
                                  >
                                    No
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* : ""
                  } */}
                    {/* {state.user_phone !== "" ?  */}
                    <div className="col-md-6 ">
                      <div className="card mt-7">
                        <div className="card-body">
                          <h5>Meetings You Attend</h5>
                          <p></p>
                          <div className="row">
                            <div className="col-xl-8">
                              <p className="feedback_meetingreport_text" >
                                Temporarily snooze feedback notifications from
                                other organizers for 30 days. This means that
                                you will not receive email requests for meeting
                                feedback from any MeetingScience members in that
                                time period. You can still provide feedback for
                                these meetings in the app.
                              </p>
                            </div>

                            <div
                              onKeyDown={handelKeyDown3}
                              className="col-xl-4"
                            >
                              <div className="auth-selectgroup">
                                <label
                                  onBlur={() => {
                                    setFocusStyle2({
                                      ...focusStyle2,
                                      five: {},
                                    });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                    // onBlur={() => {
                                    //   setFocusStyle2({});
                                    // }}
                                    className="selectgroup-input"
                                    disabled={disabled ? true : false}
                                    onChange={() =>
                                      setState({
                                        ...state,
                                        snooze_feedback_notification: 1,
                                      })
                                    }
                                    type="radio"
                                    name="snooze_feedback_notification"
                                    checked={
                                      state.snooze_feedback_notification == 1
                                        ? true
                                        : false
                                    }
                                    id="1"
                                    value={1}
                                  />
                                  <span
                                  style={{...focusStyle2.five,cursor: disabled ?'unset':'pointer'}}
                                    // style={focusStyle2.five}
                                    className="selectgroup-button"
                                  >
                                    Yes
                                  </span>
                                </label>
                                <label
                                  onBlur={() => {
                                    setFocusStyle2({
                                      ...focusStyle2,
                                      four: {},
                                    });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                    // onBlur={() => {
                                    //   setFocusStyle2({});
                                    // }}
                                    className="selectgroup-input"
                                    disabled={disabled ? true : false}
                                    onChange={() =>
                                      setState({
                                        ...state,
                                        snooze_feedback_notification: 0,
                                      })
                                    }
                                    type="radio"
                                    name="snooze_feedback_notification"
                                    checked={
                                      state.snooze_feedback_notification == 0
                                        ? true
                                        : false
                                    }
                                    id="0"
                                    value={0}
                                  />
                                  <span
                                  style={{...focusStyle2.four,cursor: disabled ?'unset':'pointer'}}
                                    // style={focusStyle2.four}
                                    className="selectgroup-button"
                                  >
                                    No
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* : ""
                  } */}

                    <div
                      style={{ marginBottom: "2rem" }}
                      className="col-md-6 mt-7"
                    >
                      {/* <div className="card mt-7">
                        <div className="card-body">
                          <h5>Meeting Feedback via Zoom</h5>
                          <p></p>
                          <div className="row">
                            <div className="col-xl-8">
                              <p>
                                Automate meeting feedback via zoom. Use our Zoom
                                marketplace app to generate meeting feedback
                                polls automatically for each meeting you
                                organized.
                              </p>
                            </div>

                            <div className="col-xl-4">
                              <div className="auth-selectgroup">
                                <div
                                  onKeyDown={handelKeyDown4}
                                  onBlur={() => {
                                    setFocusStyle5({
                                      borderColor: "#00ACBF",
                                      color: "#00ACBF",
                                      background: "#D3F5F9",
                                    });
                                  }}
                                >
                                  <label className="auth-selectgroup-item">
                                    <input
                                      type="button"
                                      ref={defauInputltRef3}
                                      className="selectgroup-input"
                                      onClick={(e) => openZoom(e)}
                                      to="/zoom"
                                    />
                                    <span
                                      style={focusStyle5}
                                      className="selectgroup-button zoom-selectgroup-button "
                                    >
                                      {localUserData &&
                                      localUserData.user_data &&
                                      localUserData.user_data.install_zoom ==
                                        "1"
                                        ? "Uninstall Zoom App"
                                        : "Install Zoom App"}
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    {/* : ""
                  } */}
                   {profileData?.override?.feedback_evaluation ===1  &&
                    <div className="col-md-12">
                      <div className="meeting-controle">
                        <div className="card-body">
                          <div className="meeting-feedback" style={{borderBottom: state.feedback_evaluation == 1 && notLastField.meetingFeedback ? '1px solid #e4eaf0' : ''}}>
                            <div className="Meeting-label">
                              <h5>Meeting Feedback</h5>
                              <span className="help-block">
                              Turning on feedback will enable it for your account immediately once you save your settings.
                              </span>
                            </div>
                            <span
                              onBlur={() => {
                                setStyles({ ...styles, meetingFeedback: {} });
                              }}
                              className="switch switch-sm ml-3"
                            >
                              <label>
                                <input
                                  ref={meetingFeedbackRef}
                                  // onFocus={() => {
                                  // }}
                                  onKeyDown={(e)=>{
                                    e.key == ' ' || e.key == '' ? e.preventDefault() :null
                                  }}
                                  checked={
                                    state.feedback_evaluation != 0 ? true : false
                                  }
                                  onChange={(e) => {
                                    setState({
                                      ...state,
                                      feedback_evaluation: e.target.checked
                                        ? 1
                                        : 0,
                                    });
                                  }}
                                  type="checkbox"
                                  disabled={disabled ? true : false}
                                />
                                <span style={styles.meetingFeedback}></span>
                              </label>
                            </span>
                          </div>
                          {state.feedback_evaluation != 0 && profileData?.override?.feedback_event_type == 1 ? (
                            <div className="which-meeting" style={{borderBottom: notLastField.whichMeeting ?'1px solid #e4eaf0' : ''}}>
                              <div className="Meeting-label">
                                <h5>Which Meeting(s)</h5>
                                <span className="help-block">
                               <strong>Internal:</strong> Meeting feedback requests will only be delivered to colleagues with a <b style={{fontWeight:450}}>{state?.userEmail?.substring(state.userEmail.lastIndexOf("@"))}</b> email address.  
                                </span>
                                <span className="help-block">
                                <strong>Internal+External:</strong> Meeting feedback requests will be delivered to colleagues and any external participants in your meeting/s.</span>
                              </div>
                              <div className="which-meeting-labels">
                                <label
                                  onBlur={() => {
                                    setStyles({ ...styles, internalBox: {} });
                                    setStyleAllowed({
                                      ...styleAllowed,
                                      whichMeeting: false,
                                    });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                    // ref={internalRef}
                                    onClick={()=>{setStyles({ ...styles, internalBox: {} });}}
                                    onFocus={() => {
                                      setStyles({
                                        ...styles,
                                        internalBox: {background:"#008D9D", color:"white"}
                                      });
                                    }}
                                    // onFocus={() => {
                                    //   setStyles({
                                    //     ...styles,
                                    //     internalBox: {
                                    //       background: styleAllowed.whichMeeting
                                    //         ? "#008D9D"
                                    //         : "",
                                    //       color: styleAllowed.whichMeeting
                                    //         ? "white"
                                    //         : "",
                                    //     },
                                    //   });
                                    // }}
                                    className="selectgroup-input"
                                    type="radio"
                                    name="wich_meeting"
                                    id="internal"
                                    disabled={disabled ? true : false}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        feedback_event_type: e.target.value,
                                      });
                                    }}
                                    value="internal"
                                    checked={
                                      state.feedback_event_type == "internal"
                                        ? true
                                        : state.feedback_event_type !== "all" && defaultValues?.default_feedback_event_type == 'internal' ? true : false
                                    }
                                  />
                                  <span
                                  style={{...styles.internalBox,cursor: disabled ?'unset':'pointer'}}
                                    // style={styles.internalBox}
                                    className="selectgroup-button"
                                  >
                                    Internal
                                  </span>
                                </label>
                                {/* <label
                                  onBlur={() => {
                                    setStyles({ ...styles, externalBox: {} });
                                    setStyleAllowed({
                                      ...styleAllowed,
                                      whichMeeting: false,
                                    });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                  onClick={()=>{setStyles({ ...styles, externalBox: {} });}}
                                    onFocus={() => {
                                      setStyles({
                                        ...styles,
                                        externalBox: {background:"#008D9D", color:"white"}
                                      });
                                    }}
                                    // onFocus={() => {
                                    //   setStyles({
                                    //     ...styles,
                                    //     externalBox: {
                                    //       background: styleAllowed.whichMeeting
                                    //         ? "#008D9D"
                                    //         : "",
                                    //       color: styleAllowed.whichMeeting
                                    //         ? "white"
                                    //         : "",
                                    //     },
                                    //   });
                                    // }}
                                    className="selectgroup-input"
                                    type="radio"
                                    name="wich_meeting"
                                    disabled={disabled ? true : false}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        feedback_event_type: e.target.value,
                                      });
                                    }}
                                    value="external"
                                    checked={
                                      state.feedback_event_type == "external"
                                        ? true
                                        : false
                                    }
                                  />
                                  <span
                                    style={styles.externalBox}
                                    className="selectgroup-button"
                                  >
                                    External
                                  </span>
                                </label> */}
                                <label
                                  onBlur={() => {
                                    setStyles({
                                      ...styles,
                                      internalExternalBox: {},
                                    });
                                    setStyleAllowed({
                                      ...styleAllowed,
                                      whichMeeting: false,
                                    });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                    onClick={()=>{ setStyles({...styles, internalExternalBox: {}})}}
                                    onFocus={() => {
                                      setStyles({...styles,internalExternalBox: {background:"#008D9D",color:"white"}});
                                    }}
                                    // onFocus={() => {
                                    //   setStyles({
                                    //     ...styles,
                                    //     internalExternalBox: {
                                    //       background: styleAllowed.whichMeeting
                                    //         ? "#008D9D"
                                    //         : "",
                                    //       color: styleAllowed.whichMeeting
                                    //         ? "white"
                                    //         : "",
                                    //     },
                                    //   });
                                    // }}
                                    className="selectgroup-input"
                                    type="radio"
                                    name="wich_meeting"
                                    disabled={disabled ? true : false}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        feedback_event_type: e.target.value,
                                      });
                                    }}
                                    checked={
                                      state.feedback_event_type == "all"
                                        ? true
                                        : false
                                    }
                                    value="all"
                                  />
                                  <span
                                  style={{...styles.internalExternalBox,cursor: disabled ?'unset':'pointer'}}
                                    // style={styles.internalExternalBox}
                                    className="selectgroup-button"
                                  >
                                    Internal + External
                                  </span>
                                </label>
                              </div>
                            </div>
                          ) : null}

{/* -------------------------------temporarily hidden start------------------------------ */}
                          {/* {state.feedback_evaluation ? (
                            <div className="private-meetings">
                              <div className="Meeting-label">
                                <h5>Include Private Meetings</h5>
                                <span className="help-block">
                                  Include or exclude meetings that you label
                                  'private' in your calendar.
                                </span>
                              </div>
                              <div className="private-meetings-labels">
                                <label
                                  onBlur={() => {
                                    setStyles({
                                      ...styles,
                                      privateMeetingY: {},
                                    });
                                    setStyleAllowed({
                                      ...styleAllowed,
                                      privateMeeting: false,
                                    });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                  onClick={()=>{setStyles({...styles, privateMeetingY: {}})}}
                                  onFocus={() => {
                                    setStyles({
                                      ...styles,
                                      privateMeetingY:{background:"#008D9D",color:"white"}
                                    });
                                  }}
                                    // onFocus={() => {
                                    //   setStyles({
                                    //     ...styles,
                                    //     privateMeetingY: {
                                    //       background: styleAllowed.privateMeeting
                                    //         ? "#008D9D"
                                    //         : "",
                                    //       color: styleAllowed.privateMeeting
                                    //         ? "white"
                                    //         : "",
                                    //     },
                                    //   });
                                    // }}
                                    className="selectgroup-input"
                                    type="radio"
                                    name="private-meetings"
                                    value={1}
                                    disabled={disabled ? true : false}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        feedback_private_meeting:
                                          e.target.value,
                                      });
                                    }}
                                    checked={
                                      state.feedback_private_meeting == 1
                                        ? true
                                        : false
                                    }
                                  />
                                  <span
                                    style={styles.privateMeetingY}
                                    className="selectgroup-button"
                                  >
                                    Yes
                                  </span>
                                </label>
                                <label
                                  onBlur={() => {
                                    setStyles({
                                      ...styles,
                                      privateMeetingN: {},
                                    });
                                    setStyleAllowed({
                                      ...styleAllowed,
                                      privateMeeting: false,
                                    });
                                  }}
                                  className="auth-selectgroup-item"
                                >
                                  <input
                                  onClick={()=>{setStyles({...styles, privateMeetingN: {}})}}
                                    onFocus={() => {
                                      setStyles({
                                        ...styles,
                                        privateMeetingN:{background:"#008D9D",color:"white"}
                                      });
                                    }}
                                    // onFocus={() => {
                                    //   setStyles({
                                    //     ...styles,
                                    //     privateMeetingN: {
                                    //       background: styleAllowed.privateMeeting
                                    //         ? "#008D9D"
                                    //         : "",
                                    //       color: styleAllowed.privateMeeting
                                    //         ? "white"
                                    //         : "",
                                    //     },
                                    //   });
                                    // }}
                                    className="selectgroup-input"
                                    type="radio"
                                    name="private-meetings"
                                    value={0}
                                    disabled={disabled ? true : false}
                                    onChange={(e) => {
                                      setState({
                                        ...state,
                                        feedback_private_meeting:
                                          e.target.value,
                                      });
                                    }}
                                    checked={
                                      state.feedback_private_meeting == 0
                                        ? true
                                        : false
                                    }
                                  />
                                  <span
                                    style={styles.privateMeetingN}
                                    className="selectgroup-button"
                                  >
                                    No
                                  </span>
                                </label>
                              </div>
                            </div>
                          ) : null} */}
{/* -------------------------------temporarily hidden end------------------------------ */}



{/* -------------------------------temporarily hidden start------------------------------ */}
                          {/* {state.feedback_evaluation ? (
                            <div className="private-meetings">
                              <div className="Meeting-label">
                                <h5>Day for Meeting Feedback</h5>
                                <span className="help-block">
                                  Select which days of the week you would like
                                  to enable for meeting feedback.
                                </span>
                              </div>
                              <div className="auth-selectgroup">
                                <label onBlur={()=>{setDayFocusStyle({...dayFocusStyle,Monday:{}})}} className="auth-selectgroup-item">
                                  <input
                                  onClick={()=>{setDayFocusStyle({...dayFocusStyle,Monday:{}})}}
                                  ref={MondayRef}
                                  onKeyDown={(e)=>{handelDaysKeyDown(e)}}
                                  onChange={(e) => handleNotificationInput(e)}
                                  className="selectgroup-input"
                                  type="checkbox"
                                  name="Monday"
                                  disabled={disabled ? true : false}
                                  checked={state.coachingReport.includes("1") ? true : false}
                                  id="1"
                                  value="1"
                                  onFocus={()=>{setDayFocusStyle({...dayFocusStyle,Monday:{background:'#008D9D',color:'white'}})}}
                                  />
                                  <span style={dayFocusStyle.Monday} className="selectgroup-button">
                                    Monday
                                  </span>
                                </label>
                                <label onBlur={()=>{setDayFocusStyle({...dayFocusStyle,Tuesday:{}})}} className="auth-selectgroup-item">
                                  <input
                                    onClick={()=>{setDayFocusStyle({...dayFocusStyle,Tuesday:{}})}}
                                    ref={TuesdayRef}
                                    onKeyDown={(e)=>{handelDaysKeyDown(e)}}
                                    onChange={(e) => handleNotificationInput(e)}
                                    className="selectgroup-input"
                                    type="checkbox"
                                    name="Tuesday"
                                    disabled={disabled ? true : false}
                                    checked={state.coachingReport.includes("2") ? true : false}
                                    id="2"
                                    value="2"
                                    onFocus={()=>{setDayFocusStyle({...dayFocusStyle,Tuesday:{background:'#008D9D',color:'white'}})}}
                                  />
                                  <span style={dayFocusStyle.Tuesday} className="selectgroup-button">
                                    Tuesday
                                  </span>
                                </label>
                                <label onBlur={()=>{setDayFocusStyle({...dayFocusStyle,Wednesday:{}})}} className="auth-selectgroup-item">
                                  <input
                                    onClick={()=>{setDayFocusStyle({...dayFocusStyle,Wednesday:{}})}}
                                    ref={WednesdayRef}
                                    onKeyDown={(e)=>{handelDaysKeyDown(e)}}
                                    onChange={(e) => handleNotificationInput(e)}
                                    className="selectgroup-input"
                                    type="checkbox"
                                    name="Wednesday"
                                    disabled={disabled ? true : false}
                                    checked={state.coachingReport.includes("3") ? true : false}
                                    id="3"
                                    value="3"
                                    onFocus={()=>{setDayFocusStyle({...dayFocusStyle,Wednesday:{background:'#008D9D',color:'white'}})}}
                                  />
                                  <span style={dayFocusStyle.Wednesday} className="selectgroup-button">
                                    Wednesday
                                  </span>
                                </label>
                                <label onBlur={()=>{setDayFocusStyle({...dayFocusStyle,Thursday:{}})}} className="auth-selectgroup-item">
                                  <input
                                  onClick={()=>{setDayFocusStyle({...dayFocusStyle,Thursday:{}})}}
                                  ref={ThursdayRef}
                                  onKeyDown={(e)=>{handelDaysKeyDown(e)}}
                                    onChange={(e) => handleNotificationInput(e)}
                                    className="selectgroup-input"
                                    type="checkbox"
                                    name="Thursday"
                                    disabled={disabled ? true : false}
                                    checked={state.coachingReport.includes("4") ? true : false}
                                    id="4"
                                    value="4"
                                    onFocus={()=>{setDayFocusStyle({...dayFocusStyle,Thursday:{background:'#008D9D',color:'white'}})}}
                                  />
                                  <span style={dayFocusStyle.Thursday} className="selectgroup-button">
                                    Thursday
                                  </span>
                                </label>
                                <label onBlur={()=>{setDayFocusStyle({...dayFocusStyle,Friday:{}})}} className="auth-selectgroup-item">
                                  <input
                                  onClick={()=>{setDayFocusStyle({...dayFocusStyle,Friday:{}})}}
                                  ref={FridayRef}
                                  onKeyDown={(e)=>{handelDaysKeyDown(e)}}
                                    onChange={(e) => handleNotificationInput(e)}
                                    className="selectgroup-input"
                                    type="checkbox"
                                    name="Friday"
                                    disabled={disabled ? true : false}
                                    checked={state.coachingReport.includes("5") ? true : false}
                                    id="5"
                                    value="5"
                                    onFocus={()=>{setDayFocusStyle({...dayFocusStyle,Friday:{background:'#008D9D',color:'white'}})}}
                                  />
                                  <span style={dayFocusStyle.Friday} className="selectgroup-button">
                                    Friday
                                  </span>
                                </label>
                              </div>
                            </div>
                          ) : null} */}
{/* -------------------------------temporarily hidden end------------------------------ */}


                          {/* <div className="receive-reminder">
                          <div class="Meeting-label"><h5>Receive a reminder SMS</h5><span class="help-block">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod  tempor incididunt ut labore et dolore .</span></div>                          
                          <div className="receive-reminder-labels">
                          <label className="auth-selectgroup-item">
                                  <input
                                    className="selectgroup-input"
                                    type="radio"
                                    name="receive-reminder"
                                    disabled={disabled ? true : false}
                                  />
                                  <span className="selectgroup-button">
                                    Yes
                                  </span>
                                </label>
                            <label className="auth-selectgroup-item">
                                  <input
                                    className="selectgroup-input"
                                    type="radio"
                                    name="receive-reminder"
                                    disabled={disabled ? true : false}
                                  />
                                  <span className="selectgroup-button">
                                    No
                                  </span>
                                </label>
                             </div>
                        </div> */}
                          {/* ----------------------------------------------- */}
                          {state.feedback_evaluation != 0 && profileData?.override?.feedback_meeting_time_of_day ? (
                            <div className="meeting-slider" style={{borderBottom : notLastField.timeForMF ? '1px solid #e4eaf0' : ''}}>
                              <div className="Meeting-label">
                                <h5>Time for Meeting Feedback</h5>
                                <span className="help-block">
                                  Select which time of day your meetings most
                                  often take place. The default values are 9am -
                                  6pm in your local time zone as the meeting
                                  organizer.
                                </span>
                              </div>
                              <div className="meeting-slider-line">
                                <div className="auth-slider mt-6 pt-6">
                                  <div className={classes.root}>
                                    <Slider
                                      disabled={disabled ? true : false}
                                      // thumbLabel
                                      className="slider-custom-styles"
                                      value={state.feedback_meeting_time_of_day != ''? state.workingHour : defaultValues?.feedback_meeting_time_of_day}
                                      onChange={handleSlider}
                                      step={1}
                                      max={24}
                                      min={0}
                                      valueLabelDisplay='on'
                                      aria-labelledby="range-slider"
                                      getAriaValueText={valuetext}
                                      marks={marks}
                                      valueLabelFormat={(value) => (
                                        <div>{formatTime(value)}</div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {/* ----------------------------------------------- */}

                          {state.feedback_evaluation != 0 && profileData?.override?.feedback_meeting_size ? (
                            <div className="meeting-size" style={{borderBottom :state.feedback_evaluation == 1 && notLastField.meetingSize ? '1px solid #e4eaf0':'' }}>
                              <div className="Meeting-label">
                                <h5>Minimum Meeting Size</h5>
                                <span className="help-block">
                                  Select a value that includes you as the
                                  organizer (for example, a 1:1 meeting would be
                                  2+).
                                </span>
                              </div>

                              <div
                                // onKeyDown={handelKeyDown5}
                                className="meeting-size-dropdown"
                              >
                                <select
                                  disabled={disabled ? true : false}
                                  value={state.feedback_meeting_size != 0 ? state.feedback_meeting_size : defaultValues?.default_feedback_meeting_size}
                                  onChange={(e) => {
                                    setState({
                                      ...state,
                                      feedback_meeting_size: e.target.value,
                                    });
                                  }}
                                  name=""
                                  id=""
                                >
                                  <option value="" disabled>
                                    select
                                  </option>
                                  {defaultValues?.default_feedback_meeting_size != 3 && <option value="2">2+</option> }
                                  <option value="3">3+</option>
                                  <option value="4">4+</option>
                                </select>
                              </div>
                            </div>
                          ) : null}

                          {state.feedback_evaluation !=0 && disabled && profileData.override.rating_excluded_keyword ==1 ? (
                            <div className="meeting-size">
                              <div className="Meeting-label">
                                <h5>Exclude Meetings with the Following Keywords</h5>
                                <span className="help-block">
                                Please enter additional keywords, separated by commas, to exclude them from your requests for feedback. Keywords are not case sensitive.
                                </span>
                              </div>

                              <div className="sugComp">
                                <div className="">
                                  <div className="suggest keySkillSuggCont">
                                    <div className="sWrap">
                                      <div className="inpWrap">
                                        <div className="chipsContainer">
                                        {state?.feedback_excluded_keyword.length >= 1 && state.feedback_excluded_keyword.map((e,i)=>{
                                        // {excludeKeywordsArray?.map((e,i)=>{
                                            return(
                                          <div
                                          style={{backgroundColor:e.type == 'global'?'#eee':'#daf1f4',color:e.type=='global'?'#666':'#6b8f93'}}
                                            key={i}
                                            className="waves-effect chip"
                                            data-id="Express_express"
                                            title=""
                                          >
                                              <span  className="tagTxt">{e.title?.toLowerCase()}</span>
                                          </div>
                                            ) 
                                          })}
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                  <div className="inputInfo">
                                    <span
                                      className="erLbl"
                                      id="chipsVal_err"
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          

                          { state?.feedback_evaluation !=0 && !disabled && profileData?.override?.rating_excluded_keyword ? (
                            <div className="meeting-slider">
                              <div className="Meeting-label">
                                <h5>Exclude Meetings with the Following Keywords</h5>
                                <span className="help-block">
                                Please enter additional keywords, separated by commas, to exclude them from your requests for feedback. Keywords are not case sensitive.
                                </span>
                                
                              </div>

                              <div className="sugComp">
                                <div className="">
                                  <div  className="suggest keySkillSuggCont">
                                    <div className="sWrap">
                                      <div  className="inpWrap">
                                        <div className="chipsContainer">
                                        {state?.feedback_excluded_keyword.length >= 1 && state.feedback_excluded_keyword.map((e,i)=>{
                                        // {excludeKeywordsArray?.map((e,i)=>{
                                            return(
                                          <div
                                          style={{backgroundColor:e.type == 'global'?'#eee':'#daf1f4',color:e.type=='global'?'#666':'#6b8f93'}}
                                            key={i}
                                            className="waves-effect chip"
                                            data-id="Express_express"
                                            title=""
                                          >
                                              <span className="tagTxt">{e.title !==' '?e.title?.toLowerCase() : null}</span>
                                              <p
                                              style={{color:e.type!='global'?'#121212':'#999',textShadow:e.type !='global'?'0 1px 0 #8f8f8f':'0 1px 0 #ffffff'}}
                                              className="material-icons close"
                                              onClick={() => {
                                                removeExcludeKeyword(i)
                                              }}
                                            >
                                              &#10006;
                                            </p>
                                          </div>
                                            ) 
                                          })}
                                
                                        </div>
                                        <input
                                          disabled={isExDisabled}
                                          // onChange={(e)=>{setState({...state,feedback_excluded_keyword:e.target.value});setWarningKey('')}}
                                          onChange={(e)=>{setExcludeKeyword(e.target.value);setWarningKey('')}}
                                          // value={state.feedback_excluded_keyword}
                                          value={excludeKeywords}
                                          type="text"
                                          placeholder="Enter additional keywords, separated by commas and press Enter to add."
                                          className="sugInp"
                                          name="suggestor"
                                          id="keySkillSugg"
                                          onKeyDown={(e)=>{e.key === 'Enter' ? handelExcludeKeywords():null }}
                                          // maxLength="235"
                                          autoComplete="off"
                                          // trackparams='{"id":"keySkillSugg","sourceId":"","platform":"","appId":"105","trackingSrc":""}'
                                        />
                                        <p style={{color:'rgb(227,79,79)',fontSize:'14px',marginTop:'-15px',marginLeft:'3px'}}>{warningKey}</p>
                                    {/* <button style={{marginBottom:'8px'}} onKeyDown={handelKeyDown5} className="btn btn-primary" onClick={handelExcludeKeywords}>save</button> */}
                                      </div>
                                    </div>
                                    <div
                                      className="sugCont"
                                      id="sugDrp_keySkillSugg"
                                    >
                                      <ul className="Sdrop"></ul>
                                    </div>
                                  </div>
                                  <input
                                    type="hidden"
                                    id="chipsVal"
                                    rel="custom:1000"
                                    // value=""
                                  />
                                  <div className="inputInfo">
                                    <span
                                      className="erLbl"
                                      id="chipsVal_err"
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <span className="help-block " style={{fontSize:'12px'}}>
                              <strong>Note:</strong> Duplicate Keywords, Keywords with less than 3 or more than 20 characters, and Keywords above 100 will be removed.
                                </span>
                            </div>
                          ) : null} 

                         

                          {/* ----------- */}
                        </div>
                      </div>
                    </div>
                    }
                    {/* ------------------ */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmAlert
        data={confirmAlertOptions}
        close={closeConfirmAlert}
        save={submitRequestForZoomUninstall}
      />
    </>
  );
};
