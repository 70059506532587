/* eslint-disable */ 
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useEffect} from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import {Dropdown, Spinner} from "react-bootstrap";
import {useHtmlClassService} from "../../layout";
import {DropdownMenu2} from "../dropdowns";
import { useSelector,useDispatch } from "react-redux";
import { FilterRange } from "../../layout/components/header/FilterLabel";
import { REQUEST_ATTENDED_MEETINGS_KEY_DIFFERENCES } from "../../../redux/duck/dashboard/attended/attended.action";
import { authUserId } from "../../_helpers/functions";

export function KeyKpiWidget({ className }) {
  const uiService = useHtmlClassService();

  const {differences,summary_data,initialState,code,working_days} = useSelector(state => state.attended)
  const {getLocalStorageUserData} = useSelector((state) => state.profile )
  const {timePeriod} = useSelector((state) => state.picker )
  const dispatch = useDispatch();
  

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);

  

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_1_chart");
    if (!element) {
      return;
    }

    const options = getChartOptions(layoutProps);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  useEffect(() => {
    if(summary_data && summary_data.userSummaryStatus && getLocalStorageUserData && getLocalStorageUserData.user_data) {
         dispatch({
        type:REQUEST_ATTENDED_MEETINGS_KEY_DIFFERENCES,
        payload:{
            // user_id:authUserId(),
            user_email:getLocalStorageUserData.user_data.email,
            company_id:getLocalStorageUserData.user_data.company_id,
            company_domain:getLocalStorageUserData.user_data.company_domain,
            sdate:timePeriod.sdate,
            edate:timePeriod.edate,
            week_type:timePeriod.filter,
            currency_conversion_rate:getLocalStorageUserData.user_data.currency.currency_conversion_rate,
            timezone:getLocalStorageUserData.user_data.timezone,
            summary_data:summary_data,
            working_days:working_days
        }
    })
}
},[summary_data])

  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-danger d-none py-5">
        <h3 className="card-title font-weight-bolder text-white">Sales Stat</h3>
        <div className="card-toolbar">
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              className="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5"
              variant="transparent"
              id="dropdown-toggle-top">
              Export
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu2 />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="card-header align-items-center border-0">
        <h3 className="card-title align-items-center d-flex w-100 justify-content-between"><span className="font-weight-bolder text-dark">Key KPIs</span><span className="text-muted font-weight-bold font-size-sm">The % change values relate to the {<FilterRange/>}</span></h3>
      </div>

      {/* Body */}
      <div className="card-body position-relative overflow-hidden">

       {summary_data && initialState !== null  ? <div className="row">
          <div className="col-xl-6 col-md-6 col-6">
            <div className="api--card">
              <div className="api--card_title">Meetings</div>
              <div className="api--detail">
                <div className="detail-number">{summary_data && summary_data?.total_meeting}</div>
{  differences && differences.differences?.diff_total_meeting_percentage!== 'N/A' && differences.differences?.diff_total_meeting_percentage!== 0 ?  <div className={differences && differences.differences?.diff_total_meeting_percentage >=0 ?"detail-percentage green" :"detail-percentage red"}>{differences && differences.differences?.diff_total_meeting_percentage} % {differences && differences.differences?.diff_total_meeting_percentage >= 0 ?<img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg"/> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg"/> }</div> : <div className='detail-percentage green'>--%</div>
}              </div>
            </div>
          </div>
          
          <div className="col-xl-6 col-md-6 col-6">
            <div className="api--card">
              <div className="api--card_title">Total Time, % of Working Hours</div>
              <div className="api--detail">
                <div className="detail-number">{summary_data && summary_data.totalTimePercentage}</div>
{ differences && differences.differences.diff_total_time_percentage!=='N/A' && differences.differences.diff_total_time_percentage!== 0 ?  <div className={differences && differences.differences.diff_total_time_percentage >=0 ?"detail-percentage green" :"detail-percentage red"}>{differences && differences.differences.diff_total_time_percentage} %  {differences && differences.differences.diff_total_time_percentage  >= 0 ?<img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg"/> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg"/> }</div>: <div className='detail-percentage green'>--%</div>
}              </div>
            </div>
          </div>
          
          <div className="col-xl-6 col-md-6 col-6">
            <div className="api--card">
              <div className="api--card_title">Time, hrs</div>
              <div className="api--detail">
                <div className="detail-number">{summary_data && summary_data.total_time}</div>
{ differences && differences.differences.diff_total_time!=='N/A'  && differences.differences.diff_total_time!== 0 ?  <div className={differences && differences.differences.diff_total_time >=0 ?"detail-percentage green" :"detail-percentage red"}>{differences && differences.differences.diff_total_time} % {differences && differences.differences.diff_total_time  >= 0 ?<img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg"/> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg"/> }</div>: <div className='detail-percentage green'>--%</div>
}              </div>
            </div>
          </div>
          
          <div className="col-xl-6 col-md-6 col-6">
            <div className="api--card">
              <div className="api--card_title">Average Daily Time In Meetings, hrs</div>
              <div className="api--detail">
                <div className="detail-number">{summary_data && summary_data.averageTotalTime}</div>
{      differences && differences.differences.diff_average_total_time_percentage!=='N/A' && differences.differences.diff_average_total_time_percentage!== 0 ?   <div className={differences && differences.differences.diff_average_total_time_percentage >=0 ?"detail-percentage green" :"detail-percentage red"}>{differences && differences.differences.diff_average_total_time_percentage} % {differences && differences.differences.diff_average_total_time_percentage  >= 0 ?<img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg"/> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg"/> }</div>: <div className='detail-percentage green'>--%</div>
}              </div>
            </div>
          </div>
          
          <div className="col-xl-6 col-md-6 col-6">
            <div className="api--card">
              <div className="api--card_title">Different People</div>
              <div className="api--detail">
                <div className="detail-number">{summary_data && summary_data.people_meet}</div>
{ differences && differences.differences.diff_people_meet_percentage!=='N/A' && differences.differences.diff_people_meet_percentage!== 0  ?  <div className={differences && differences.differences.diff_people_meet_percentage >=0 ?"detail-percentage green" :"detail-percentage red"}>{differences && differences.differences.diff_people_meet_percentage} % {differences && differences.differences.diff_people_meet_percentage  >= 0 ?<img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg"/> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg"/> }</div>: <div className='detail-percentage green'>--%</div>
}              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-6 col-6">
            <div className="api--card">
              <div className="api--card_title">Average People Per Meeting</div>
              <div className="api--detail">
                <div className="detail-number">{summary_data && summary_data.averagePersonPerMeeting}</div>
{      differences && differences.differences.diff_average_person_per_meeting_percentage!=='N/A'  && differences.differences.diff_average_person_per_meeting_percentage!== 0  ?   <div className={differences && differences.differences.diff_average_person_per_meeting_percentage >=0 ?"detail-percentage green" :"detail-percentage red"}>{differences && differences.differences.diff_average_person_per_meeting_percentage} % {differences && differences.differences.diff_average_person_per_meeting_percentage  >= 0 ?<img alt='contains svg icon' src="/media/ms-media/percent-arrow-up.svg"/> : <img alt='contains svg icon' src="/media/ms-media/percent-arrow-down.svg"/> }</div>  : <div className='detail-percentage green'>--%</div>
}              </div>
            </div>
          </div>

        </div>

        :
       
            !code ?  
            <div className='loader-container'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner> 
            </div>
          :
          <div>We're sorry, for the time you requested, we don't have any data to share with you.</div>
  
     
  }
      </div>
    </div>
  );
}

function getChartOptions(layoutProps) {
  const strokeColor = "#D13647";

  const options = {
    series: [
      {
        name: "Net Profit",
        data: [30, 45, 32, 70, 40, 40, 40]
      }
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 0
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor]
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      }
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function(val) {
          return "$" + val + " thousands";
        }
      },
      marker: {
        show: false
      }
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3
    }
  };
  return options;
}
