import React from 'react';
import { Modal } from "react-bootstrap";

export const TimeManagementModal = (props) => {
    return (
        <>
            <Modal
                show={props.show} onHide={() => props.handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Time Management on Apr 7: 
                    </Modal.Title>
                    <button className="btn btn-close p-0 ml-auto" onClick={props.handleClose}><img src="/media/ms-media/modal-close-icn.svg" alt="" /></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="badge--card">
                                <div className="badge--card-title">Positive Meeting Attributes</div>
                                <div className="badge--card-body">
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Clear Purpose</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Organizer Prepared</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Successful Solutions</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Lorem ipsum</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Dolor asit amet</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Accomplished Goals</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Clear Purpose</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Organizer Prepared</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Successful Solutions</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Lorem ipsum</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Dolor asit amet</span>
                                    <span className="label label-lg label-light-primary mr-2 mb-2 label-inline">Accomplished Goals</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="badge--card">
                                <div className="badge--card-title">Negative Meeting Attributes</div>
                                <div className="badge--card-body">
                                    <span className="label label-lg label-light-danger mr-2 mb-2 label-inline">Started Late</span>
                                    <span className="label label-lg label-light-danger mr-2 mb-2 label-inline">Tech Problems</span>
                                    <span className="label label-lg label-light-danger mr-2 mb-2 label-inline">Uncleaer next steps</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
