/* eslint-disable */ 
import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { countNoOfDays } from "../../_helpers/functions";

export function WhoSpendsMostTimeinMeeting() {

    const {companyData} = useSelector(state => state.company);
    const {timePeriod} = useSelector(state => state.picker)
    const [state, setState] = useState({
        series: [
            {
                name: 'External',
                data: [],
            }, {
                name: 'Internal',
                data: [],
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: true,
                    tools:{
                      download:true // <== line to add
                    }
                  },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 0,
            },
            title: {
                text: 'Attendees',
                align: 'left',
                style: {
                    fontSize: '12px',
                    color: '#BFC2C7'
                },
            },
            colors: ['#F6F062', '#FCAC34'],
            dataLabels:{
                enabled:true,
                
                style: {
                  colors: ['#000'],
                  fontSize: '10px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'normal',
              },
              offsetX:0,
              formatter: function(val, opt) {
                return getPercent(val)
              }
              },
            legends: {
                show: true
            },
            grid: {
                show: true,
                borderColor: '#F8F9FB',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                row: {
                    colors: undefined,
                    opacity: 0.5
                },
                column: {
                    colors: undefined,
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: [],
                labels: {
                    style: {
                        colors: "#999EA5",
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                    offsetX: 8,
                    formatter: function (val) {
                        return val;
                    }
                },
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: true,
                    borderType: 'dotted',
                    color: '#F4F5F8',
                    height: 25,
                },
                title: {
                    text: "# of hours",
                    offsetX: -60,
                    style: {
                        fontSize: '12px',
                        color: '#999EA5',
                        fontWeight: "light"
                    },
                }
            },
            yaxis: {
                show: true,
                min:0,
                // max:8,
                forceNiceScale: true,
                floating: false,
                labels: {
                    style: {
                        colors: "#999EA5",
                        fontSize: '12px',
                        fontWeight: 400,
                    },
                    formatter: function (val) {
                        return val;
                    }
                },
            },
            tooltip: {
                fillSeriesColor: false,
                y: {
                    show: true,
                    formatter: function (val) {
                        return val + " hr(s)";
                    }
                    },
                x: {
                    show: true,
                    
                },
                marker: {
                    show: true,
                },
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: "top",
                show: true,
                horizontalAlign: "right",
                offsetX: 40
                }
            }
    });

    const getPercent = (num) => {

        let total = 8 ;
         
        if(timePeriod.filter == 'today' || timePeriod.filter == 'yesterday'){
            total = 8;
        }else{
            
            let date1 = new Date(timePeriod.sdate);
            let date2 = new Date(timePeriod.edate);
            // console.log(timePeriod.filter)
            let noOfDays = countNoOfDays(date1,date2);
            
              total = noOfDays*8;
            //   console.log('total',total);
            //   console.log('percentage',((num/total)*100).toFixed(0));
            //   console.log('--------------end----------')
            }             
            // return ((num/total)*100).toFixed(0) + ' %'
            return ((num/total)*100).toFixed(0) > 100 ? 100 + ' %':((num/total)*100).toFixed(0) + ' %' ;
      }

    // const getPercent = (num) => {
    
    //     let total = 8 ;
    
    //     const {filter} = timePeriod;
            
    //     if(filter == '0'){
    //         total = 31*8;
    //     }else if(filter == '1'){
    //         total = 14*8
    //     }else if(filter == '2'){
    //       total = 7*8
    //   }else if(filter == '3'){
    //     total = 7*8
    // }else if(filter == '4'){
      
    //         const {sdate,edate} = timePeriod;
    //         const date1 = new Date(sdate);
    //         const date2 = new Date(edate);
    //         let noOfDays = countNoOfDays(date1,date2);
    //         //  console.log(noOfDays);
            
    //         total = noOfDays*8;
                     
    //   }
    //     return ((num/total)*100).toFixed(0) + ' %';
    //   }


      const calcMaxHours = () => {
        const {filter} = timePeriod;
            
        if(filter == '0'){
            return 31*8;
        }else if(filter == '1'){
            return 14*8
        }else if(filter == '2'){
          return 7*8
      }else if(filter == '3'){
        return 7*8
    }else if(filter == '4'){
      const {sdate,edate} = timePeriod;
      const date1 = new Date(sdate);
      const date2 = new Date(edate);
      let noOfDays = countNoOfDays(date1,date2);
    //   let noOfWeeks = (noOfDays/7).toFixed(0);

    return noOfDays * 8;
       
    }
    
      }

    useEffect(() => {
            
            let rawData = [];
            let categories = [];
            let series = [
                {
                    name: 'External',
                    data: [],
                }, {
                    name: 'Internal',
                    data: [],
                }
        ]
            
            if(companyData && companyData.events && companyData.events.length){

                companyData.events.map(meeting => {

                    let accepted_users = meeting.event_accepted_ids;
                    let accepted_ids_arr = accepted_users.split(',');

                    accepted_ids_arr.map(accepted_user_id => {

                    let event_users_list = [];

                    for (let key in meeting.event_users){
                            if(meeting.event_users[key].user_id == accepted_user_id){
                                event_users_list.push(meeting.event_users[key]);
                            }
                    }

                        event_users_list.map(eventUser => { 
                                    let user_exists_index = rawData.findIndex(element => element.user_id == eventUser.user_id);
                                        if(user_exists_index == -1){
                                                rawData.push({
                                                                user_id:eventUser.user_id,
                                                                user:eventUser.user_fullname,
                                                                totalMeetingTime:meeting.event_duration,
                                                                meetingType:[{
                                                                                internal:meeting.event_type == "internal" ? meeting.event_duration : 0,
                                                                                external:meeting.event_type == "external" ?  meeting.event_duration : 0
                                                                }]
                                                })
                                        }else{
                                                if(meeting.event_type == "internal"){
                                                    rawData[user_exists_index].totalMeetingTime = rawData[user_exists_index].totalMeetingTime + meeting.event_duration; 
                                                    rawData[user_exists_index].meetingType[0].internal = rawData[user_exists_index].meetingType[0].internal + meeting.event_duration;
                                                }else if(meeting.event_type == "external" ){
                                                    rawData[user_exists_index].totalMeetingTime = rawData[user_exists_index].totalMeetingTime + meeting.event_duration;
                                                    rawData[user_exists_index].meetingType[0].external = rawData[user_exists_index].meetingType[0].external +  meeting.event_duration;
                                                }      
                                        }
                            })      
                })
            })
            }
            
            //  console.log('raw data for unique users in meetings');

            let sortedData =  rawData.sort( function ( a, b ) { return  b.totalMeetingTime - a.totalMeetingTime   } );
            let slicedData = sortedData.slice(0,10);
            //  console.log('data sliced for top ten people with maximum meeting time');

          if(slicedData.length){
                for (let key in slicedData){
                categories.push(slicedData[key].user);
                series[0].data.push((slicedData[key].meetingType[0].external).toFixed(1))
                series[1].data.push((slicedData[key].meetingType[0].internal).toFixed(1))
            }
            //  console.log('categories');
            //  console.log(categories);
            //  console.log('series');
            //  console.log(series);
        
        }

        
            setState({
                ...state,
                  options:{
                    ...state.options,
                          xaxis:{
                            ...state.options.xaxis,
                              categories:categories
                          },
                        //   yaxis:{
                        //     ...state.options.yaxis,
                        //      max:calcMaxHours()
                        //   }
                          yaxis:{
                              ...state.options.yaxis,
                              max:parseInt(series[0].data[0]) + parseInt(series[1].data[0]) + 5
                          },
                          dataLabels:{
                              ...state.options.dataLabels,
                              formatter: function(val, opt) {
                                return getPercent(val)
                              },
                          }
                  },
                series:series
                 
            })


    },[companyData && companyData.events && companyData.events.length])

    return (
        <>
         {companyData && companyData.events && companyData.events.length > 0 ?
            <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
            :
            <div className='loader-container'>
          { companyData == null ?  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                </Spinner> : (companyData && companyData.events.length == 0 ? "We're sorry, for the time you requested, we don't have any data to share with you." : null)}
       </div>
        }
        </>
    );
}
