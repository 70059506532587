export const REQUEST_LEADERBOARD_DATA = "REQUEST_LEADERBOARD_DATA";
export const RECEIVE_LEADERBOARD_DATA = "RECEIVE_LEADERBOARD_DATA";
export const requestLeaderboardData = (data) => ({type : REQUEST_LEADERBOARD_DATA,payload:data});
export const receiveLeaderboardData = (data) => ({ type: RECEIVE_LEADERBOARD_DATA, data:data })

export const REQUEST_MEETING_MASTERY_INFO = "REQUEST_MEETING_MASTERY_INFO";
export const RECEIVE_MEETING_MASTERY_INFO = "RECEIVE_MEETING_MASTERY_INFO";
export const requestMasteryInfo = (data) => ({type : REQUEST_MEETING_MASTERY_INFO,payload:data});
export const receiveMasteryInfo = (data) => ({ type: RECEIVE_MEETING_MASTERY_INFO, data:data })

export const  REQUEST_LEADERBOARD_FOR_MASTERY = "REQUEST_LEADERBOARD_FOR_MASTERY";
export const RECEIVE_LEADERBOARD_FOR_MASTERY = "RECEIVE_LEADERBOARD_FOR_MASTERY";
export const requestMasteryLeaderboard = (data) => ({type : REQUEST_LEADERBOARD_FOR_MASTERY,payload:data});
export const receiveMasteryLeaderboard = (data) => ({ type: RECEIVE_LEADERBOARD_FOR_MASTERY, data:data });