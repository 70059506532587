/* eslint-disable */ 

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "../../_helpers/Custom";
import { LayoutSplashScreen } from "../../layout";
import { AlertPopup } from "../../_helpers/Alerts";
import { ConfirmationTimeMachineModal, ProposeTimeMachineModal, SuccessTimeMachineModal } from "../widgets";
import { listAttendedMeetingsDetail, listOrganizedMeetingsDetail, saveOrganizedMeetingsDetail } from "../../../redux/duck/reports/timeMachine/timeMachine.action";
import { Spinner } from "react-bootstrap";
import parse from 'html-react-parser';
import _ from "lodash";
import { capitalize } from "@material-ui/core";
import './dashboard.css';

export function TimeMachine(props) {
    // const user_id = authUserId();
    const dispatch = useDispatch();
    //Redux State
    const listOrganizedMeetingData = useSelector((state) => state.timeMachine.listOrganizedMeetingsData);
    const prevListOrganizedMeetingData = usePrevious({ listOrganizedMeetingData });
    const listAttendedMeetingData = useSelector((state) => state.timeMachine.listAttendedMeetingsData);
    const prevListAttendedMeetingData = usePrevious({ listAttendedMeetingData });
    const saveOrganizedMeetingData = useSelector((state) => state.timeMachine.saveOrganizedMeetingsData);
    const prevSaveOrganizedMeetingData = usePrevious({ saveOrganizedMeetingData });
    // Loader State
    const [loader, setLoader] = useState(false);
    // Set Initial State
    const [alert, setAlert] = useState({ show: false, title: null, message: null, errType: null });
    const [organizedEventsData, setOrganizedEventsData] = useState([]);
    const [attendedEventsData, setAttendedEventsData] = useState([]);
    const [sortingOrganizedOrder, setSortingOrganizedOrder] = useState('DESC');
    const [sortingOrganizedField, setSortingOrganizedField] = useState('event_title');
    const [sortingAttendedOrder, setSortingAttendedOrder] = useState('DESC');
    const [sortingAttendedField, setSortingAttendedField] = useState('event_title');
    const [state, setState] = useState({ hours: "", minutes: "" });
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [proposeTimeModal, setProposeTimeModal] = useState(false);
    const [allCheckedValue, setAllCheckedValue] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [time, setTime] = useState({});
    const [proposeModalData, setProposeModalData] = useState({ title: "", note: "", startTime: "", endTime: "", event_id: "" });
    const succMessage = "We have successfully made changes to your calendar.";

    // list Data for organized and attended meetings
    useEffect(() => {
        setLoader(true);
        dispatch(listOrganizedMeetingsDetail({  orderby: sortingOrganizedField, sort: sortingOrganizedOrder }));
        dispatch(listAttendedMeetingsDetail({  orderby: sortingAttendedField, sort: sortingAttendedOrder }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // fetch response for organized and attended meetings
    useEffect(() => {
        if (prevListOrganizedMeetingData && prevListOrganizedMeetingData.listOrganizedMeetingData !== listOrganizedMeetingData) {
            if (listOrganizedMeetingData && _.has(listOrganizedMeetingData, "events") && listOrganizedMeetingData.status === "success") {
                const organizedEventsData = listOrganizedMeetingData.events;
                setOrganizedEventsData(organizedEventsData);
                setLoader(false);
                setTime({})
                setState({ ...state, hours: "", minutes: "" });
            }
            setAllCheckedValue([]);
            if (listOrganizedMeetingData && _.has(listOrganizedMeetingData, "message") && listOrganizedMeetingData.status === "error") {
                    setLoader(false);
                    setAlert({
                        show: true,
                        message: "Something went wrong!",
                        title: 'Notice',
                        errType: "failure"
                    })
            }
        }
        if (prevListAttendedMeetingData && prevListAttendedMeetingData.listAttendedMeetingData !== listAttendedMeetingData) {
            if (listAttendedMeetingData && _.has(listAttendedMeetingData, "events") && listAttendedMeetingData.status === "success") {
                const attendedEventsData = listAttendedMeetingData.events;
                setAttendedEventsData(attendedEventsData);
                setLoader(false);
            }
            if (listAttendedMeetingData && _.has(listAttendedMeetingData, "message") && listAttendedMeetingData.status === "error") {
                    setLoader(false);
                    setAlert({
                        show: true,
                        message: "Something went wrong!",
                        title: 'Notice',
                        errType: "failure"
                    })
            }
        }
        if (prevSaveOrganizedMeetingData && prevSaveOrganizedMeetingData.saveOrganizedMeetingData !== saveOrganizedMeetingData) {
            if (saveOrganizedMeetingData && _.has(saveOrganizedMeetingData, "message") && saveOrganizedMeetingData.status === "success") {
                setLoader(false);
                setConfirmationModal(false);
                setSuccessModal(true);
                dispatch(listOrganizedMeetingsDetail({  orderby: sortingOrganizedField, sort: sortingOrganizedOrder }));
            }
            if (saveOrganizedMeetingData && _.has(saveOrganizedMeetingData, "message") && saveOrganizedMeetingData.status === "error") {
                setLoader(false);
                setConfirmationModal(false);
                setAlert({
                    show: true,
                    message: "Something went wrong!",
                    title: 'Notice',
                    errType: "failure"
                })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevListOrganizedMeetingData, listOrganizedMeetingData, prevListAttendedMeetingData, listAttendedMeetingData, prevSaveOrganizedMeetingData, saveOrganizedMeetingData]);

    const handleConfirmationModal = () => {
        if (allCheckedValue.length > 0) {
            setConfirmationModal(true)
        } else {
            setConfirmationModal(false);
            setAlert({
                show: true,
                message: "Please choose atleast one meeting option to reduce the time.",
                title: 'Notice',
                errType: "failure"
            })
        }
    }
    const handleProposeTimeModal = (e, status, title, startTime, endTime, event_id) => {
        if (status !== 1) {
            setProposeTimeModal(true);
            setProposeModalData({ ...proposeModalData, title, note: "", startTime, endTime, event_id })
        } else {
            setAlert({
                show: true,
                message: "You have already proposed time for this meeting.",
                title: 'Notice',
                errType: "success"
            })
        }
    }
    // Sort The Organized Meetings List 
    const sortOrganizedData = (field, orderValue) => {
        let order = orderValue === 'DESC' ? 'ASC' : (orderValue === 'ASC' ? "DESC" : 'DESC');
        setSortingOrganizedField(field);
        setSortingOrganizedOrder(order);
        setLoader(true);
        dispatch(listOrganizedMeetingsDetail({  orderby: field, sort: order }))
    }
    // Sort The Attended Meetings List 
    const sortAttendedData = (field, orderValue) => {
        let order = orderValue === 'DESC' ? 'ASC' : (orderValue === 'ASC' ? "DESC" : 'DESC');
        setSortingAttendedField(field);
        setSortingAttendedOrder(order);
        setLoader(true);
        dispatch(listAttendedMeetingsDetail({  orderby: field, sort: order }))
    }

    const checkedAllOrganizedMeetings = (data) => {
        if (data) {
            let allCheck = _.map(organizedEventsData, 'event_id');
            setAllCheckedValue(allCheck);
            setTime({})
            _.map(organizedEventsData, (data) => {
                calculateTime(data.event_id, data.event_mhours, true)
            });
        } else {
            setAllCheckedValue([])
            setTime({});
            setState({ ...state, hours: 0, minutes: 0 })
        }
        setCheckedAll(data)
    };
    // On change select toggle value
    const onCheckedValue = (id, min, checked) => {
        let newArr;
        if (_.includes(allCheckedValue, id)) {
            newArr = _.filter(allCheckedValue, (data) => data !== id)
        } else {
            newArr = [...allCheckedValue, id]
        }
        if (newArr.length === organizedEventsData.length) {
            setCheckedAll(true)
            setAllCheckedValue(newArr)
            calculateTime(id, min, checked)
        } else {
            setCheckedAll(false)
            setAllCheckedValue(newArr)
            calculateTime(id, min, checked)
        }
    };

    // calculate Time on toggle switch
    const calculateTime = (id, min, checked) => {
        let minArr = time;
        minArr[id] = minArr && minArr[id] ? minArr[id] : {};
        let sum = 0, hours = 0, minutes = 0;
        if (checked === false) {
            delete minArr[id];
        } else {
            if (min <= 30) {
                minArr[id] = 5;
            } else if (min <= 90) {
                minArr[id] = 10;
            } else if (min <= 105) {
                minArr[id] = 15;
            } else {
                minArr[id] = 20;
            }
        }
        setTime(minArr);
        for (var el in minArr) {
            if (minArr.hasOwnProperty(el)) {
                sum += parseFloat(minArr[el]);
            }
        }
        minutes = sum % 60;
        hours = sum / 60;
        setState({ ...state, hours, minutes })
    }
    // handle close button of alert
    const handleClose = () => {
        setAlert({
            show: false,
        });
    };
    // handle Save data
    const handleSaveData = () => {
        const saveOrganizedData = { event_ids: allCheckedValue };
        setConfirmationModal(false);
        setLoader(true);
        dispatch(saveOrganizedMeetingsDetail(saveOrganizedData))
    }
    return (
        <>
            <LayoutSplashScreen visible={loader} />
            {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />}
            <div className="time-machine">
                <div className="card mb-4 card-custom">
                    {/* Header */}
                    <div className="card-header align-items-center border-0">
                        <h3 className="card-title align-items-center d-flex w-100 justify-content-between">
                            <span className="font-weight-bolder text-dark">Meetings you have organized for the next 30 days</span>
                        </h3>
                    </div>

                    {/* Body */}
                    <div className="card-body position-relative overflow-hidden">
                        <p>
                            Here are all of the <strong>30, 60, 90, 120, 180, and 240-minute meetings you've organized for the next 30 days.</strong> Select each meeting to reduce its end time by ~ 10% (for example a 60-minute meeting will become a 50-minute
                            meeting), or choose the ALL option to reduce the time of all meetings listed below.(Note: your selection will not be saved until you click the SAVE button and re-confirm your submission).
                        </p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <div className="table-filter ">
                                            Day
                                            <span className="table-filter--icn custm_popover " onClick={() => sortOrganizedData("day_name", sortingOrganizedOrder)}>
                                                {sortingOrganizedField === "day_name" ? (sortingOrganizedOrder === "DESC" ? <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></> : (sortingOrganizedOrder === 'ASC' ? <><i className="fas fa-caret-up sorth_heads1"></i><i className="fas fa-caret-down active sorth_heads1"></i></> : <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></>)) : <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></>}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="table-filter sorth_heads">
                                            Date
                                            <span className="table-filter--icn custm_popover" onClick={() => sortOrganizedData("date_format", sortingOrganizedOrder)}>
                                                {sortingOrganizedField === "date_format" ? (sortingOrganizedOrder === "DESC" ? <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></> : (sortingOrganizedOrder === 'ASC' ? <><i className="fas fa-caret-up sorth_heads1"></i><i className="fas fa-caret-down active sorth_heads1"></i></> : <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></>)) : <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></>}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="table-filter sorth_heads">
                                            Title
                                            <span className="table-filter--icn custm_popover" onClick={() => sortOrganizedData("event_title", sortingOrganizedOrder)}>
                                                {sortingOrganizedField === "event_title" ? (sortingOrganizedOrder === "DESC" ? <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></> : (sortingOrganizedOrder === 'ASC' ? <><i className="fas fa-caret-up sorth_heads1"></i><i className="fas fa-caret-down active sorth_heads1"></i></> : <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></>)) : <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></>}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="table-filter sorth_heads">
                                            Duration
                                            <span className="table-filter--icn custm_popover" onClick={() => sortOrganizedData("event_mhours", sortingOrganizedOrder)}>
                                                {sortingOrganizedField === "event_mhours" ? (sortingOrganizedOrder === "DESC" ? <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></> : (sortingOrganizedOrder === 'ASC' ? <><i className="fas fa-caret-up sorth_heads1"></i><i className="fas fa-caret-down active sorth_heads1"></i></> : <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></>)) : <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></>}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="table-filter sorth_heads">
                                            Attendees
                                            <span className="table-filter--icn custm_popover" onClick={() => sortOrganizedData("events_attandees", sortingOrganizedOrder)}>
                                                {sortingOrganizedField === "events_attandees" ? (sortingOrganizedOrder === "DESC" ? <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></> : (sortingOrganizedOrder === 'ASC' ? <><i className="fas fa-caret-up sorth_heads1"></i><i className="fas fa-caret-down active sorth_heads1"></i></> : <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></>)) : <> <i className="fas fa-caret-up active sorth_heads1"></i><i className="fas fa-caret-down sorth_heads1"></i></>}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col" >
                                        <div className="d-flex align-items-center sorth_heads2 ">
                                            Reduce ~ 10%
                                            <span className="switch switch-sm ml-3">
                                                <label htmlFor={'checkAll'}>
                                                    <input type="checkbox" name="check_all" onChange={(e) => checkedAllOrganizedMeetings(e.target.checked)} checked={checkedAll} id={'checkAll'} />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {organizedEventsData.length > 0 ? organizedEventsData &&
                                    _.map(organizedEventsData, (data, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{data.day_name}</td>
                                                <td>{data.date_format}</td>
                                                <td>{data.event_title !== "" && data.event_title !== null ? parse(capitalize(data.event_title)) : "N/A"}</td>
                                                <td>{data.event_mhours} min</td>
                                                <td>{data.events_attandees}</td>
                                                <td>
                                                    <span className="switch switch-sm">
                                                        <label htmlFor={'check' + data.event_id}>
                                                            <input type="checkbox" name={data.event_id} onChange={(e) => onCheckedValue(data.event_id, data.event_mhours, e.target.checked)} checked={_.includes(allCheckedValue, data.event_id)} id={'check' + data.event_id} />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    }) : Object.keys(listOrganizedMeetingData).length === 0 ?
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            <Spinner animation="border" role="status" >
                                                <span className="visually-hidden"></span>
                                            </Spinner>
                                        </td>
                                    </tr>
                                        : <tr><td colSpan="6" className="text-center">We don't have any meetings to share with you.</td></tr>
                                }
                        </tbody>
                        </table>

                    <div className="card-footer px-0 py-3 d-flex align-items-center justify-content-between position-relative overflow-hidden">
                            <p className="m-0">
                                You reduced time in <strong>{allCheckedValue && allCheckedValue.length > 0 ? allCheckedValue.length : 0} meetings.</strong>
                            </p>
                        <div className="saveTime d-flex align-items-center">
                            <p className="m-0">You're about to save your attendees:</p>
                                <div className="saveTime_box">{state.hours !== "" ? Math.floor(state.hours) : "0"}<small>h</small> {state.minutes !== "" ? (state.minutes < 9 ? "0" + state.minutes : state.minutes) : "00"}<small>min</small></div>
                                <div className="saveTime-btn"><button className="btn btn-primary" disabled={allCheckedValue.length ? false : true} onClick={() => handleConfirmationModal()}>Save</button></div>
                        </div>
                    </div>
                </div>
                </div>
            {/* <div className="card mb-4 card-custom rate--buttons">
              
                <div className="card-header align-items-center border-0">
                        <h3 className="card-title align-items-center d-flex w-100 justify-content-between">
                            <span className="font-weight-bolder text-dark">Meetings you have been invited to in the next 30 days</span>
                        </h3>
                </div>

              
                <div className="card-body position-relative overflow-hidden">
                        <p>
                            Here are all of the <strong>30, 60, 90, 120, 180, and 240-minute meetings you've been invited to the next 30 days.</strong> Select each meeting to request that each organizer end each meeting time by ~ 10% (for example a 60-minute meeting
                            will become a 50-minute meeting), or choose the ALL option to request that they all be reduced.(Note: your selection will not be saved until you click the SAVE button and re-confirm your submission).
                        </p>
                    <table className="table">
                        <thead>
                            <tr>
                                    <th scope="col">
                                        <div className="table-filter">
                                            Day
                                            <span className="table-filter--icn custm_popover" onClick={() => sortAttendedData("day_name", sortingAttendedOrder)}>
                                                {sortingAttendedField === "day_name" ? (sortingAttendedOrder === "DESC" ? <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></> : (sortingAttendedOrder === 'ASC' ? <><i className="fas fa-caret-up"></i><i className="fas fa-caret-down active"></i></> : <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></>)) : <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></>}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="table-filter">
                                            Date
                                            <span className="table-filter--icn custm_popover" onClick={() => sortAttendedData("date_format", sortingAttendedOrder)}>
                                                {sortingAttendedField === "date_format" ? (sortingAttendedOrder === "DESC" ? <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></> : (sortingAttendedOrder === 'ASC' ? <><i className="fas fa-caret-up"></i><i className="fas fa-caret-down active"></i></> : <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></>)) : <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></>}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="table-filter">
                                            Title
                                            <span className="table-filter--icn custm_popover" onClick={() => sortAttendedData("event_title", sortingAttendedOrder)}>
                                                {sortingAttendedField === "event_title" ? (sortingAttendedOrder === "DESC" ? <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></> : (sortingAttendedOrder === 'ASC' ? <><i className="fas fa-caret-up"></i><i className="fas fa-caret-down active"></i></> : <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></>)) : <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></>}
                                            </span>
                                        </div>
                                    </th>
                                    <th scope="col">
                                        <div className="table-filter">
                                            Duration
                                            <span className="table-filter--icn custm_popover" onClick={() => sortAttendedData("event_mhours", sortingAttendedOrder)}>
                                                {sortingAttendedField === "event_mhours" ? (sortingAttendedOrder === "DESC" ? <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></> : (sortingAttendedOrder === 'ASC' ? <><i className="fas fa-caret-up"></i><i className="fas fa-caret-down active"></i></> : <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></>)) : <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></>}
                                            </span>
                                        </div>
                                    </th>
                                <th scope="col">
                                        <div className="table-filter">
                                            Organizer
                                            <span className="table-filter--icn custm_popover" onClick={() => sortAttendedData("event_organizerid", sortingAttendedOrder)}>
                                                {sortingAttendedField === "event_organizerid" ? (sortingAttendedOrder === "DESC" ? <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></> : (sortingAttendedOrder === 'ASC' ? <><i className="fas fa-caret-up"></i><i className="fas fa-caret-down active"></i></> : <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></>)) : <> <i className="fas fa-caret-up active"></i><i className="fas fa-caret-down"></i></>}
                                            </span>
                                    </div>
                                </th>
                                    <th scope="col">
                                        <div className="table-filter">
                                            Actions
                                        </div>
                                    </th>
                            </tr>
                        </thead>
                        <tbody>
                                {attendedEventsData.length > 0 ? attendedEventsData &&
                                    _.map(attendedEventsData, (data, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{data.day_name}</td>
                                                <td>{data.date_format}</td>
                                                <td>{data.event_title !== "" && data.event_title !== null ? parse(capitalize(data.event_title)) : "-"}</td>
                                                <td>{data.event_mhours} min</td>
                                                <td>{data.user_firstname === "" || data.user_firstname === null || data.user_lastname === null ? data.user_email : capitalize(data.user_firstname) + " " + capitalize(data.user_lastname)}</td>
                                                <td>
                                                    <a  href="#propose" onClick={(e) => handleProposeTimeModal(e, data.time_already_purposed, data.event_title, data.event_formatted_start_datetime, data.event_formatted_proposed_datetime, data.event_id)}>{data.time_already_purposed ? "Proposal sent" : "Propose time"}</a>
                                                </td>
                                            </tr>
                                        );
                                    }) : Object.keys(listAttendedMeetingData).length === 0 ?
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            <Spinner animation="border" role="status" >
                                                <span className="visually-hidden"></span>
                                            </Spinner>
                                        </td>
                                    </tr>
                                        :
                                        <tr><td colSpan="6" className="text-center">We don't have any meetings to share with you.</td></tr>
                                }
                        </tbody>
                        </table>
                    </div>
                </div> */}
            </div>
            <ConfirmationTimeMachineModal show={confirmationModal} handleClose={() => setConfirmationModal(false)} handleSave={() => handleSaveData()} />
            <SuccessTimeMachineModal show={successModal} message={succMessage} handleClose={() => setSuccessModal(false)} dashboard={() => props.history.push('/attended')} />
            <ProposeTimeMachineModal show={proposeTimeModal} handleClose={() => setProposeTimeModal(false)} proposeModalData={proposeModalData} dashboard={() => props.history.push('/attended')} />
      </>
    );
}