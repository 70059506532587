import axios from 'axios';
import { Apistore } from '../../../apiStore';

export function getUnratedMeetingsApi(data) {
    return axios.post(Apistore.GetUnratedEvents, data)
}
export function sendUnratedMeetingDataApi(data) {
    return axios.post(Apistore.SendUnratedMeetingData, data)
}
export function getUnratedMeetingsCountApi(data) {
    return axios.post(Apistore.GetUnratedCount,data)
}