// View Profile Info Detail
export const GET_PROFILE_INFO_DETAIL = "GET_PROFILE_INFO_DETAIL";
export const getProfileInfoDetail = (data) => ({ type: GET_PROFILE_INFO_DETAIL, data })
export const SUCCESS_GET_PROFILE_INFO_DETAIL = 'SUCCESS_GET_PROFILE_INFO_DETAIL';
export const ERROR_GET_PROFILE_INFO_DETAIL = 'ERROR_GET_PROFILE_INFO_DETAIL';
export const getProfileInfoDetailResponse = (type, data) => ({ type, data });

// Update Profile Info Detail
export const UPDATE_PROFILE_INFO_DETAIL = "UPDATE_PROFILE_INFO_DETAIL";
export const updateProfileInfoDetail = (data) => ({ type: UPDATE_PROFILE_INFO_DETAIL, data })
export const SUCCESS_UPDATE_PROFILE_INFO_DETAIL = 'SUCCESS_UPDATE_PROFILE_INFO_DETAIL';
export const ERROR_UPDATE_PROFILE_INFO_DETAIL = 'ERROR_UPDATE_PROFILE_INFO_DETAIL';
export const updateProfileInfoDetailResponse = (type, data) => ({ type, data });

// Update Profile Image
export const SAVE_USER_PROFILE_IMAGE = "SAVE_USER_PROFILE_IMAGE";
export const saveUserProfileImage = (data) => ({ type: SAVE_USER_PROFILE_IMAGE, data })
export const SUCCESS_SAVE_USER_PROFILE_IMAGE = 'SUCCESS_SAVE_USER_PROFILE_IMAGE';
export const ERROR_SAVE_USER_PROFILE_IMAGE = 'ERROR_SAVE_USER_PROFILE_IMAGE';
export const saveUserProfileImageResponse = (type, data) => ({ type, data });

// Update Relationship Detail
export const UPDATE_USER_RELATIONS_DETAIL = "UPDATE_USER_RELATIONS_DETAIL";
export const updateUserRelationsDetail = (data) => ({ type: UPDATE_USER_RELATIONS_DETAIL, data })
export const SUCCESS_UPDATE_USER_RELATIONS_DETAIL = 'SUCCESS_UPDATE_USER_RELATIONS_DETAIL';
export const ERROR_UPDATE_USER_RELATIONS_DETAIL = 'ERROR_UPDATE_USER_RELATIONS_DETAIL';
export const updateUserRelationsDetailResponse = (type, data) => ({ type, data });

//----------------------------------------------------------------------------------------------------------------------------------
// Update custom Relationship Detail

export const UPDATE_CUSTOM_USER_RELATIONS_DETAIL = "UPDATE_CUSTOM_USER_RELATIONS_DETAIL";
export const updateCutomUserRelationsDetail = (data) => ({ type: UPDATE_CUSTOM_USER_RELATIONS_DETAIL, data })
export const SUCCESS_CUSTOM_UPDATE_USER_RELATIONS_DETAIL = 'SUCCESS_CUSTOM_UPDATE_USER_RELATIONS_DETAIL';
export const ERROR_CUSTOM_UPDATE_USER_RELATIONS_DETAIL = 'ERROR_CUSTOM_UPDATE_USER_RELATIONS_DETAIL';
export const updateCustomUserRelationsDetailResponse = (type, data) => ({ type, data });
//----------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------
// Update user phone no

export const UPDATE_USER_PHONE_NUMBER = "UPDATE_USER_PHONE_NUMBER";
export const updateUserPhoneNumber = (data) => ({ type: UPDATE_USER_PHONE_NUMBER, data })
export const SUCCESS_UPDATE_USER_PHONE_NUMBER = 'SUCCESS_UPDATE_USER_PHONE_NUMBER';
export const ERROR_UPDATE_USER_PHONE_NUMBER = 'ERROR_UPDATE_USER_PHONE_NUMBER';
export const updateUserPhoneNumberResponse = (type, data) => ({ type, data });
//----------------------------------------------------------------------------------------------------------------------------------

// Update Notification Detail
export const UPDATE_USER_NOTIFICATIONS_DETAIL = "UPDATE_USER_NOTIFICATIONS_DETAIL";
export const updateUserNotificationsDetail = (data) => ({ type: UPDATE_USER_NOTIFICATIONS_DETAIL, data })
export const SUCCESS_UPDATE_USER_NOTIFICATIONS_DETAIL = 'SUCCESS_UPDATE_USER_NOTIFICATIONS_DETAIL';
export const ERROR_UPDATE_USER_NOTIFICATIONS_DETAIL = 'ERROR_UPDATE_USER_NOTIFICATIONS_DETAIL';
export const updateUserNotificationsDetailResponse = (type, data) => ({ type, data });

// Update Privacy Detail
export const UPDATE_USER_PRIVACY_DETAIL = "UPDATE_USER_PRIVACY_DETAIL";
export const updateUserPrivacyDetail = (data) => ({ type: UPDATE_USER_PRIVACY_DETAIL, data })
export const SUCCESS_UPDATE_USER_PRIVACY_DETAIL = 'SUCCESS_UPDATE_USER_PRIVACY_DETAIL';
export const ERROR_UPDATE_USER_PRIVACY_DETAIL = 'ERROR_UPDATE_USER_PRIVACY_DETAIL';
export const updateUserPrivacyDetailResponse = (type, data) => ({ type, data });

// Check Unique NickName
export const CHECK_USER_UNIQUE_NICKNAME = "CHECK_USER_UNIQUE_NICKNAME";
export const checkUserUniqueNickname = (data) => ({ type: CHECK_USER_UNIQUE_NICKNAME, data })
export const SUCCESS_CHECK_USER_UNIQUE_NICKNAME = 'SUCCESS_CHECK_USER_UNIQUE_NICKNAME';
export const ERROR_CHECK_USER_UNIQUE_NICKNAME = 'ERROR_CHECK_USER_UNIQUE_NICKNAME';
export const checkUserUniqueNicknameResponse = (type, data) => ({ type, data });


// Get User Data in Local Storage
export const GET_LOCALSTORAGE_USER_DETAIL = "GET_LOCALSTORAGE_USER_DETAIL";
export const getLocalStorageUserDetail = (data) => ({ type: GET_LOCALSTORAGE_USER_DETAIL, data })
export const SUCCESS_GET_LOCALSTORAGE_USER_DETAIL = 'SUCCESS_GET_LOCALSTORAGE_USER_DETAIL';
export const ERROR_GET_LOCALSTORAGE_USER_DETAIL = 'ERROR_GET_LOCALSTORAGE_USER_DETAIL';
export const getLocalStorageUserDetailResponse = (type, data) => ({ type, data });

// Update feedback settings
export const UPDATE_FEEDBACK_SETTINGS = "UPDATE_FEEDBACK_SETTINGS";
export const updateFeedbackSettingsAction = (data) => ({ type: UPDATE_FEEDBACK_SETTINGS, data })
export const SUCCESS_UPDATE_FEEDBACK_SETTINGS = 'SUCCESS_UPDATE_FEEDBACK_SETTINGS';
export const ERROR_UPDATE_FEEDBACK_SETTINGS = 'ERROR_UPDATE_FEEDBACK_SETTINGS';
export const updateFeedbackSettingsResponse = (type, data) => ({ type, data });

// send otp
export const SEND_OTP = "SEND_OTP";
export const updateSendOtpAction = (data) => ({ type: SEND_OTP, data })
export const SUCCESS_SEND_OTP = 'SUCCESS_SEND_OTP';
export const ERROR_SEND_OTP = 'ERROR_SEND_OTP';
export const updateSendOtpResponse = (type, data) => ({ type, data });

// verify otp
export const VERIFY_OTP = "VERIFY_OTP";
export const updateVerifyOtpAction = (data) => ({ type: VERIFY_OTP, data })
export const SUCCESS_VERIFY_OTP = 'SUCCESS_VERIFY_OTP';
export const ERROR_VERIFY_OTP = 'ERROR_VERIFY_OTP';
export const updateVerifyOtpResponse = (type, data) => ({ type, data });

export const RESEND_OTP = "RESEND_OTP";
export const updateResendOtpAction = (data) => ({ type: RESEND_OTP, data })
export const SUCCESS_RESEND_OTP = 'SUCCESS_RESEND_OTP';
export const ERROR_RESEND_OTP = 'ERROR_RESEND_OTP';
export const updateResendOtpResponse = (type, data) => ({ type, data });

export const FETCH_MEETING_MASTERY_NOTIFICATIONS = "FETCH_MEETING_MASTERY_NOTIFICATIONS";
export const getMeetingMasteryNotificationsAction = (data) => ({ type: FETCH_MEETING_MASTERY_NOTIFICATIONS, data })
export const SUCCESS_FETCH_MEETING_MASTERY_NOTIFICATIONS = 'SUCCESS_FETCH_MEETING_MASTERY_NOTIFICATIONS';
export const ERROR_FETCH_MEETING_MASTERY_NOTIFICATIONS = 'ERROR_FETCH_MEETING_MASTERY_NOTIFICATIONS';
export const getMeetingMasteryNotificationsResponse = (type, data) => ({ type, data });

export const UPDATE_MEETING_MASTERY_NOTIFICATIONS = "UPDATE_MEETING_MASTERY_NOTIFICATIONS";
export const updateMeetingMasteryNotificationsAction = (data) => ({ type: UPDATE_MEETING_MASTERY_NOTIFICATIONS, data })
export const SUCCESS_UPDATE_MEETING_MASTERY_NOTIFICATIONS = 'SUCCESS_UPDATE_MEETING_MASTERY_NOTIFICATIONS';
export const ERROR_UPDATE_MEETING_MASTERY_NOTIFICATIONS = 'ERROR_UPDATE_MEETING_MASTERY_NOTIFICATIONS';
export const updateMeetingMasteryNotificationsResponse = (type, data) => ({ type, data });


// reset keys for verify/send otp
export const RESET_OTP_KEYS = "RESET_OTP_KEYS";
