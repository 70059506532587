/* eslint-disable */ 
import React,{useEffect, useState} from 'react';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './dateRangePickerField.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setFilterData } from '../../../../../redux/duck/datepicker/picker.action';
import moment from 'moment';

export const DateRangePickerField = (props) => {
    const history = useHistory();
    const pathName = history.location.pathname;
    const dispatch = useDispatch();
    const {timePeriod} = useSelector(state => state.picker)
    const { getLocalStorageUserData } = useSelector(state => state.profile); 
    const [custom, setCustom] = useState(false)
    const [data,setData] = useState({
        sdate: null,
        edate: null
    })    

    const handleDate = (e) => {
    let {value} = e.target;
        if (value) {
        dispatch(setFilterData({
            sdate:moment(value[0]).format('YYYY-MM-DD'),
            edate:moment(value[1]).format('YYYY-MM-DD'),
            filter: "custom",
            order:'DESC',
            custom: true
        }));
            setCustom(true)
      setData({
        ...data,
        sdate:moment(value[0]).format('YYYY-MM-DD'),
        edate:moment(value[1]).format('YYYY-MM-DD')
    })

}
}

useEffect(() => {
    if (timePeriod.filter !== "custom") {
        setCustom(false)
    }
    if (props.value.custom === true && custom === false && props.order === false) {
        setData({
            ...data,
            sdate: timePeriod.sdate,
            edate: timePeriod.edate
        })
    } else {
        setData({
            ...data,
            sdate: timePeriod.sdate,
            edate: timePeriod.edate
        })
    }


// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props, timePeriod.custom, timePeriod.filter])

//--------------------------------new added start ------------------
let dateRangeRef = (dateRange) => (dateRangeRef = dateRange);
  function onClicke() {
    dateRangeRef.show();
}
props.biRef.onClicke = onClicke;
//--------------------------------new added end ------------------


    let mindate = moment().subtract(2, 'months').format('YYYY-MM-DD');
    let maxdate = pathName == "/meeting-mastery" && getLocalStorageUserData &&  getLocalStorageUserData.user_data && getLocalStorageUserData.user_data.company_domain !== 'zoom.us' && getLocalStorageUserData.user_data.company_domain !== 'zoomappsec.us' && getLocalStorageUserData.user_data.email !== 'development@meetingscience.io' ? moment().format('YYYY-MM-DD') : moment().add(1, 'months').format('YYYY-MM-DD');

    return (
        <DateRangePickerComponent    
            onChange={(e) => handleDate(e)}
            allowEdit={false}
            startDate={data.sdate}
            endDate={data.edate}
            min={mindate}
            max={maxdate}
            ref={dateRangeRef} //new added
            // maxDays={45}
            openOnFocus={true}
            showClearButton={false}
            id="daterangepicker"
            placeholder='Select a Date Range'
            format="yyyy/MM/dd"
        />
    );
}