/* eslint-disable */ 

import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

const FilterdateLabel = () => {
    const {timePeriod} = useSelector(state => state.picker);
    const [dateLabel,setdateLabel] = useState('')
    const [title,setPageTitle]=useState('')
    const location = useLocation();

    useEffect(() => {
        
        if (timePeriod.filter === 'today') {
            setdateLabel('Today')
        } else if (timePeriod.filter == 'yesterday') {
            setdateLabel('Yesterday')
        } else if (timePeriod.filter == 'last_week') {
            setdateLabel('Last week')
        } else if (timePeriod.filter == 'last_month') {
            setdateLabel('Last month')
        } else if (timePeriod.filter == 'last_7_days') {
            setdateLabel('Last 7 days')
        } else if (timePeriod.filter == 'last_30_days') {
            setdateLabel('Last 30 days')
        } else if (timePeriod.filter == 'next_7_days') {
            setdateLabel('Next 7 days')
        } else if (timePeriod.filter == 'custom') {
            setdateLabel("date " + moment(timePeriod.sdate).format('MM/DD') + " to " + moment(timePeriod.edate).format('MM/DD'))
        }

        // eslint-disable-next-line default-case
        switch(location.pathname){
            case '/attended'  :
                if(timePeriod.filter == 'next_7_days'){
                    setPageTitle("Meetings You Will Attend")
                }else{
                    setPageTitle("Meetings You've Attended")
                }
                
                break;
                case '/score'  : // This route opens a modal on attended page
                if(timePeriod.filter == 'next_7_days'){
                    setPageTitle("Meetings You Will Attend")
                }else{
                    setPageTitle("Meetings You've Attended")
                }
                break;
                case '/leaderboard'  : // This route opens a modal on attended page
                if(timePeriod.filter == 'next_7_days'){
                    setPageTitle("Meetings You Will Attend")
                }else{
                    setPageTitle("Meetings You've Attended")
                }
                break;
            case '/organized' :
                setPageTitle("Meetings You've Organized")
                break;
            case '/unrated' :
                setPageTitle("Meetings You Didn't Rate")
                break;
            case '/company' :
                setPageTitle("Meetings in Your Company")
                break;
            case '/meeting-mastery' :
                setPageTitle("Meeting Mastery Report")
                break;
            case '/coaching':
                setPageTitle("Meeting Coaching Report")
                break;
        }

    },[timePeriod.filter,location.pathname,timePeriod.sdate,timePeriod.edate,location.pathname])

    if(location.pathname !== '/meeting-mastery' && location.pathname !== '/unrated'){
        return title + ' - ' + dateLabel;
    }else{
        return title;
    }
    
}

export default FilterdateLabel;


export const FilterRange = () => {
    const {timePeriod} = useSelector(state => state.picker);
    const [dateLabel,setdateLabel] = useState('')
    const location = useLocation();
    useEffect(() => {
        
       
        if (timePeriod.filter === 'today') {
            setdateLabel('date - ' + moment(timePeriod.sdate).format('MM/DD'))
        } else if (timePeriod.filter == 'yesterday') {
            setdateLabel('date - ' + moment(timePeriod.sdate).format('MM/DD'))
        } else if (timePeriod.filter == 'last_week') {
            setdateLabel('last week')
        } else if (timePeriod.filter == 'last_month') {
            setdateLabel('last month')
        } else if (timePeriod.filter == 'last_7_days') {
            setdateLabel('last 7 days')
        } else if (timePeriod.filter == 'last_30_days') {
            setdateLabel('last 30 days')
        } else if (timePeriod.filter == 'next_7_days') {
            setdateLabel('next 7 days')
        } else if (timePeriod.filter == 'custom') {
            setdateLabel("date " + moment(timePeriod.sdate).format('MM/DD') + " to " + moment(timePeriod.edate).format('MM/DD'))
        }

    },[timePeriod.filter,timePeriod.sdate,timePeriod.edate,location.pathname])

    return dateLabel;
}

