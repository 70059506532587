import { all } from 'redux-saga/effects';

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { attendeesWatcher } from './duck/dashboard/attended/attended.saga';
import { organizedWatcher } from './duck/dashboard/organized/organized.saga';
import { getUnratedMeetingsWatcher, sendUnratedMeetingsWatcher, getUnratedMeetingsCountWatcher } from './duck/dashboard/unrated/unrated.saga';
import { listAttendedMeetingsDataWatcher, listOrganizedMeetingsDataWatcher, saveOrganizedMeetingsDataWatcher, sendAttendedMeetingsProposalTimeWatcher } from './duck/reports/timeMachine/timeMachine.saga';
import { pickerWatcher } from './duck/datepicker/picker.saga';
import { checkUserUniqueNicknameWatcher, feedbackSettingsWatcher, getLocalStorageUserDetailWatcher, getProfileInfoDetailWatcher, otpWatcher, saveUserProfileImageDataWatcher, updateProfileInfoDetailWatcher, updateProfileNotificationDetailWatcher, updateProfilePrivacyDetailWatcher, updateProfileRelationsDetailWatcher ,updateCustomProfileRelationsApiWatcher,updateUserPhoneApiWatcher} from './duck/userProfile/userProfile.saga';
                                                                                                                                                                                                                                                                                                                             //^^^^^^^^^^^  newChange  ^^^^^^^^^^^^^                                       
import { meetingMasteryWatcher } from './duck/reports/meetingMastery/mastery.saga';
import { getUserScoreComparisonDataWatcher, getUserScoreDataWatcher, getUserScoreGraphDataWatcher, getUserScoreHowToImproveDataWatcher } from './duck/reports/scoreModal/score.saga';
import { leaderBoardWatcher } from './duck/reports/leaderboard/leaderboard.saga';
import { companyWatcher } from './duck/dashboard/company/company.saga';
import { getCoachingMcrDataWatcher, getCoachingMcrGraphDataWatcher } from './duck/reports/coaching/coaching.saga';

export function* rootSaga() {
        yield all([
                auth.saga(),
                attendeesWatcher(),
                organizedWatcher(),
                pickerWatcher(),
                getProfileInfoDetailWatcher(),
                updateProfileInfoDetailWatcher(),
                saveUserProfileImageDataWatcher(),
                updateProfileRelationsDetailWatcher(),
                updateUserPhoneApiWatcher(),
                updateCustomProfileRelationsApiWatcher(),
                updateProfileNotificationDetailWatcher(),
                updateProfilePrivacyDetailWatcher(),
                checkUserUniqueNicknameWatcher(),
                getLocalStorageUserDetailWatcher(),
                getUnratedMeetingsWatcher(),
                sendUnratedMeetingsWatcher(),
                getUnratedMeetingsCountWatcher(),
                listOrganizedMeetingsDataWatcher(),
                listAttendedMeetingsDataWatcher(),
                saveOrganizedMeetingsDataWatcher(),
                sendAttendedMeetingsProposalTimeWatcher(),
                meetingMasteryWatcher(),
                getUserScoreDataWatcher(),
                getUserScoreComparisonDataWatcher(),
                getUserScoreGraphDataWatcher(),
                getUserScoreHowToImproveDataWatcher(),
                leaderBoardWatcher(),
                companyWatcher(),
                getCoachingMcrDataWatcher(),
                getCoachingMcrGraphDataWatcher(),
                feedbackSettingsWatcher(),
                otpWatcher()
        ])
}