/* eslint-disable */ 

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePrevious } from "../../_helpers/Custom";
import parse from 'html-react-parser';
import _ from "lodash";
import { toAbsoluteUrl } from "../../_helpers";
import { FilterRange } from "../../layout/components/header/FilterLabel";
import Axios from "axios";
import { Apistore } from "../../../redux/apiStore";

export const CoachingRecommendationSection = (props) => {
    const getMcrData = useSelector(state => state.coaching.getCoachingMcrData);
    const prevGetMcrData = usePrevious({ getMcrData });
    // Set Initial State
    const { getLocalStorageUserData } = useSelector(state => state.profile);
    const [recommendationCount, setRecommendationCount] = useState();
    const [agendaReccomendationsState, setAgendaReccomendationsState] = useState({ agendaReccomendationsTitle: '', agendaReccomendationsText: '', agendaReccomendationsData: [] });
    const [addMinsReccomendationsState, setAddMinsReccomendationsState] = useState({ addMinsReccomendationsTitle: '', addMinsReccomendationsText: '', addMinsReccomendationsData: [] });
    const [declineReccomendationsState, setDeclineReccomendationsState] = useState({ declineReccomendationsTitle: '', declineReccomendationsText: '', declineReccomendationsData: [] });
    const { agendaReccomendationsTitle, agendaReccomendationsText, agendaReccomendationsData } = agendaReccomendationsState;
    const { addMinsReccomendationsTitle, addMinsReccomendationsText, addMinsReccomendationsData } = addMinsReccomendationsState;
    const { declineReccomendationsTitle, declineReccomendationsText, declineReccomendationsData } = declineReccomendationsState;

    const handleEventPull = (event_id,type) => {
        Axios.post(Apistore.ActionTrack,{
            user_id:props?.userId,
            event_id,
            action_type:type
            }).then(res => {        })

        setTimeout(() => {
               Axios.post(Apistore.EventPull,{
                //    user_id:getLocalStorageUserData.user_data.user_id,
                   timezone:getLocalStorageUserData.user_data.timezone
               }).then(res => {
                    setTimeout(() => {
                        window.location.reload();         
                    }, 1200);
            }) 
        }, 45000);

    }

    useEffect(() => {
        if (prevGetMcrData && prevGetMcrData.getMcrData !== getMcrData) {
            if (getMcrData && _.has(getMcrData, "data") && getMcrData.code === 200) {
                const recommendationsData = getMcrData.data;
                setRecommendationCount(recommendationsData.recommendation_count);
                setAgendaReccomendationsState({ ...agendaReccomendationsState, agendaReccomendationsTitle: recommendationsData.recc_add_agenda_events_title, agendaReccomendationsText: recommendationsData.recc_add_agenda_events_text, agendaReccomendationsData: recommendationsData.recc_add_agenda_events });
                setAddMinsReccomendationsState({ ...addMinsReccomendationsState, addMinsReccomendationsTitle: recommendationsData.recc_add_15_mins_events_title, addMinsReccomendationsText: recommendationsData.recc_add_15_mins_events_text, addMinsReccomendationsData: recommendationsData.recc_add_15_mins_events });
                setDeclineReccomendationsState({ ...declineReccomendationsState, declineReccomendationsTitle: recommendationsData.recc_shorten_this_meeting_title, declineReccomendationsText: recommendationsData.recc_shorten_this_meeting_text, declineReccomendationsData: recommendationsData.recc_shorten_this_meeting_events });
                props.loader(false);
            }
            if (getMcrData && _.has(getMcrData, "message") && getMcrData.status === "error") {
                props.loader(false);
                props.alert({
                    show: true,
                    message: "Something went wrong!",
                    title: "Error",
                    errType: "failure",
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevGetMcrData, getMcrData]);

    return (
        <>
            <div className="col-md-6 coaching-page-col-1">
                <div className="card card-custom card-coaching h-100">
                    <div className="card-header align-items-center border-0">
                        <h3 className="card-title align-items-center d-flex w-100 justify-content-between">
                            <span className="font-weight-bolder text-dark">Recommendation</span>
                            {/* <span className="text-muted font-weight-bold font-size-sm">The % change values relate to the {<FilterRange />}</span> */}
                        </h3>
                    </div>

                    <div className="card-body position-relative overflow-hidden">
                        {recommendationCount !== 0 ?
                            <div className="coaching_card-scroll">
                                <h5>{agendaReccomendationsTitle !== "" ? parse(agendaReccomendationsTitle) : ''}</h5>
                                <p>{agendaReccomendationsText !== "" ? parse(agendaReccomendationsText) : ''}</p>
                                {agendaReccomendationsData && agendaReccomendationsData.length > 0 ? _.map(agendaReccomendationsData, (data, key) => {
                                    return (
                                        <div className="coaching_card" key={key}>
                                            <div className="coaching_card-header d-flex align-items-center justify-content-between">
                                                <div className="time"><strong>{data.event_week_day}</strong> at {data.event_time}</div>
                                                <div className="date-btn"><span>{data.event_date_month}</span><a href={data.edit_link} target="blank" onClick={()=>{handleEventPull(data.event_id,'Coaching: Add Agenda')}}  className="btn btn-add-agenda">Add Agenda</a></div>
                                            </div>
                                            <div className="coaching_card-body">
                                                <div className="coaching-detail d-flex align-items-center justify-content-between">
                                                    <h4>{data.event_title.length ? data.event_title : 'N/A'}</h4>
                                                    <div className="agenda-detail">
                                                        <span><img src="/media/ms-media/hourglass-icn.svg" alt="" />{data.event_duration}<small>m</small></span>
                                                        <span><img src="/media/ms-media/desktop-computer-icn.svg" alt="" />{data.total_attendees}<small>of {data.total_attendees_all}</small></span>
                                                    </div>
                                                </div>
                                                <div className="avatar-group flex-wrap">
                                                    {Object.values(data.event_users).map((data, key) => {
                                                        return (
                                                            <div key={key} className="avatar avatar-sm">
                                                                <img style={{cursor:'pointer'}} title={data.user_firstname && data.user_firstname!== null &&  data.user_firstname !=='' ? data.user_firstname + ' ' + data.user_lastname : data.user_email} src={'https://client.meetingscience.io/images/profile_images/ph_profile.png'} alt="..." onError={({ currentTarget }) => {console.log('Error',currentTarget)}} className="avatar-img rounded-circle border border-white" />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ''}
                                <h5>{addMinsReccomendationsTitle !== "" ? parse(addMinsReccomendationsTitle) : ''}</h5>
                                <p>{addMinsReccomendationsText !== "" ? parse(addMinsReccomendationsText) : ''}</p>
                                {addMinsReccomendationsData && addMinsReccomendationsData.length > 0 ? _.map(addMinsReccomendationsData, (data, key) => {
                                    return (
                                        <div className="coaching_card" key={key}>
                                            <div className="coaching_card-header d-flex align-items-center justify-content-between">
                                                <div className="time"><strong>{data.event_week_day}</strong> at {data.event_time}</div>
                                                <div className="date-btn"><span>{data.event_date_month}</span><a href={data.edit_link} target="blank" onClick={()=>{handleEventPull(data.event_id,'Coaching: Add 15mins')}} className="btn btn-add-mins">Add 15mins</a></div>
                                            </div>
                                            <div className="coaching_card-body">
                                                <div className="coaching-detail d-flex align-items-center justify-content-between">
                                                    <h4>{data.event_title.length ? data.event_title : 'N/A'}</h4>
                                                    <div className="agenda-detail">
                                                        <span><img src="/media/ms-media/hourglass-icn.svg" alt="" />{data.event_duration}<small>m</small></span>
                                                        <span><img src="/media/ms-media/desktop-computer-icn.svg" alt="" />{data.total_attendees}<small>of {data.total_attendees_all}</small></span>
                                                    </div>
                                                </div>
                                                <div className="avatar-group flex-wrap">
                                                    {Object.values(data.event_users).map((data, key) => {
                                                        return (
                                                            <div key={key} className="avatar avatar-sm">
                                                                <img style={{cursor:'pointer'}} title={data.user_firstname && data.user_firstname!== null &&  data.user_firstname !=='' ? data.user_firstname + ' ' + data.user_lastname : data.user_email} src={'https://client.meetingscience.io/images/profile_images/ph_profile.png'} alt="..." onError={({ currentTarget }) => {console.log('Error',currentTarget)}} className="avatar-img rounded-circle border border-white" />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ''}
                                <h5>{declineReccomendationsTitle !== "" ? parse(declineReccomendationsTitle) : ''}</h5>
                                <p>{declineReccomendationsText !== "" ? parse(declineReccomendationsText) : ''}</p>
                                {declineReccomendationsData && declineReccomendationsData.length > 0 ? _.map(declineReccomendationsData, (data, key) => {
                                    return (
                                        <div className="coaching_card" key={key}>
                                            <div className="coaching_card-header d-flex align-items-center justify-content-between">
                                                <div className="time"><strong>{data.event_week_day}</strong> at {data.event_time}</div>
                                                <div className="date-btn"><span>{data.event_date_month}</span><a href={data.edit_link} target="blank" onClick={()=>{handleEventPull(data.event_id,'Coaching: Shorten this Meeting')}} className="btn btn-decline">Shorten this Meeting</a></div>
                                            </div>
                                            <div className="coaching_card-body">
                                                <div className="coaching-detail d-flex align-items-center justify-content-between">
                                                    <h4>{data.event_title.length ? data.event_title : 'N/A'}</h4>
                                                    <div className="agenda-detail">
                                                        <span><img src="/media/ms-media/hourglass-icn.svg" alt="" />{data.event_duration}<small>m</small></span>
                                                        <span><img src="/media/ms-media/desktop-computer-icn.svg" alt="" />{data.total_attendees}<small>of {data.total_attendees_all}</small></span>
                                                    </div>
                                                </div>
                                                <div className="avatar-group flex-wrap">
                                                    {Object.values(data.event_users).map((data, key) => {
                                                        return (
                                                            <div key={key} className="avatar avatar-sm">
                                                                <img style={{cursor:'pointer'}} title={data.user_firstname && data.user_firstname!== null &&  data.user_firstname !=='' ? data.user_firstname + ' ' + data.user_lastname : data.user_email} src={'https://client.meetingscience.io/images/profile_images/ph_profile.png'} alt="..." onError={({ currentTarget }) => {console.log('Error',currentTarget)}} className="avatar-img rounded-circle border border-white" />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ''}
                            </div>
                            : <p className="text-center">There is no recommendation for this week</p>}
                    </div>
                </div>
            </div>
        </>
    );
};
