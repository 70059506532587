/* eslint-disable */ 

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const MemberFeedbackChart = ({dataset}) => {

    const categories = [];
    const positiveValues = [];
    const negativeValues = [];
    const newSeries = [];

    // const {dataset} = useSelector(state => state.organized);

    const [state, setState] = useState({
        series: [{
            name: 'Positive feedback',
            data: []
          },
          {
            name: 'Negetive feedback',
            data: []
          }
          ],
          options: {
            chart: {
              toolbar: {
                show: true,
                offsetY:-10,
                tools:{
                  download:true // <== line to add
                }
              },
              type: 'bar',
              height: 440,
              stacked: true
            },
            colors: ['#66ecfb', '#E34F4F'], // red - #E34F4F , lightblue - #d3f5f9, darkBlue - #66ecfb
            plotOptions: {
              bar: {
                horizontal: true,
                barHeight: '80%',
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 1,
              colors: ["#fff"]
            },
            
            grid: {
              xaxis: {
                lines: {
                  show: false
                }
              }
            },
            yaxis: {
              min: -100,
              max: 100,
              title: {
                // text: 'Age',
              },
            },
            tooltip: {
              shared: false,
              x: {
                formatter: function (val) {
                  return val
                }
              },
              y: {
                formatter: function (val) {
                  return Math.abs(val) + "%"
                }
              }
            },
            xaxis: {
              categories: [],
              title: {
                text: ''
              },
              labels: {
                formatter: function (val) {
                  return Math.abs(Math.round(val)) + "%"
                }
              }
            },
            legend:{
              inverseOrder:"true"
            }
          },
        }
    )

    useEffect(() => {
        if(dataset && dataset.status == "success"){

        for(let item in dataset.data){
          categories.push(item)
          positiveValues.push(dataset.data[item].positive_percentage);
          negativeValues.push("-" + dataset.data[item].negative_percentage);
        }

        newSeries.push(
          {
            name:'Positive Feedback',
            data:positiveValues
          },
          {
            name:'Negative Feedback',
            data:negativeValues
          }
        )
   
        setState({
          ...state,
            options:{
              ...state.options,
                    xaxis:{
                      ...state.options.xaxis,
                        categories:categories
                    } 
            },
          series:newSeries
           
      })     
        }

    },[dataset])

    return (
    <div className="card mb-4 card-custom">
        {/* Header */}
        <div className="card-header align-items-center border-0">
            <h3 className="card-title align-items-center d-flex w-100 justify-content-between"><span className="font-weight-bolder text-dark">Meeting Attendee Feedback </span></h3>
            <div className="card-subtitle d-flex justify-content-between">
                <h5>See how you're doing from your recent meetings according to attendee feedback.</h5>
                <div className="card-subtitle-btn">
                    {/* <a className="btn btn-sm btn-light">You</a>
                    <a className="btn btn-sm btn-light-primary">Other people</a> */}
                </div>
            </div>
        </div>

        {/* Body */}
        <div className="card-body">
        {dataset && dataset.status !== 'error'  ?  
              
            ( dataset.rated_meetings_count >= dataset.min_rating_count ? <ReactApexChart options={state.options} series={state.series} type="bar" height={400} /> : <div> Please encourage your meeting attendees to provide feedback at the end of your meetings from <Link to={"/unrated"}>unrated meetings</Link>. The more feedback they share, the more you will learn and improve. </div>)


          : 
            ( dataset && dataset.status == 'error' ?
            <div style={{marginBottom:'200px'}} className='loader-container'>
            {/* <div className='loader-container'> */}
            <div>We're sorry, for the time you requested, we don't have any data to share with you.</div>
            </div>
              :
              <div className='loader-container'>
                  <Spinner animation="border" role="status" >
                    <span className="visually-hidden"></span>
                 </Spinner> 
              </div> 

              )
            }

        </div>
    </div>
    )
}