// View data in attended section 
export const REQUEST_COMPANY_DATA = "REQUEST_COMPANY_DATA";
export const RECEIVE_COMPANY_DATA = "RECEIVE_COMPANY_DATA";
export const RESET_STATE = "RESET_STATE";
export const requestCompanySummaryData = (data) => ({type : REQUEST_COMPANY_DATA,payload:data});
export const receiveCompanySummaryData = (data) => ({ type: RECEIVE_COMPANY_DATA, data:data })
export const resetData = (data) => ({type:'RESET_STATE',data:data});

export const REQUEST_COMPANY_RATING_ATTRIBUTES = "REQUEST_COMPANY_RATING_ATTRIBUTES";
export const RECEIVE_COMPANY_RATING_ATTRIBUTES = "RECEIVE_COMPANY_RATING_ATTRIBUTES";
export const requestCompanyRatingAttributes = (data) => ({ type: REQUEST_COMPANY_RATING_ATTRIBUTES, payload: data });
export const receiveCompanyRatingAttributes = (data) => ({ type: RECEIVE_COMPANY_RATING_ATTRIBUTES, data: data });

export const REQUEST_COMPANY_KEY_DIFFERENCES = "REQUEST_COMPANY_KEY_DIFFERENCES";
export const RECEIVE_COMPANY_KEY_DIFFERENCES = "RECEIVE_COMPANY_KEY_DIFFERENCES";
export const requestCompanyKeyDifferences = (data) => ({ type: REQUEST_COMPANY_KEY_DIFFERENCES, payload: data });
export const receiveCompanyKeyDifferences = (data) => ({ type: RECEIVE_COMPANY_KEY_DIFFERENCES, data: data });

export const REQUEST_COMPANY_OVERALL_FEEDBACK = "REQUEST_COMPANY_OVERALL_FEEDBACK";
export const RECEIVE_COMPANY_OVERALL_FEEDBACK = "RECEIVE_COMPANY_OVERALL_FEEDBACK";
export const requestCompanyOverallFeedback = (data) => ({ type: REQUEST_COMPANY_OVERALL_FEEDBACK, payload: data });
export const receiveCompanyOverallFeedback = (data) => ({ type: RECEIVE_COMPANY_OVERALL_FEEDBACK, data: data });