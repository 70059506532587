/* eslint-disable */ 

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../layout";
import { AlertPopup } from "../../_helpers/Alerts";
import { usePrevious } from "../../_helpers/Custom";
import { authUserId } from "../../_helpers/functions";
import { getUnratedMeetingsDetail, sendUnratedMeetingDetail } from "../../../redux/duck/dashboard/unrated/unrated.action";
import { capitalize } from "@material-ui/core";
import parse from 'html-react-parser';
import moment from "moment";
import _ from "lodash";

export function UnratedDashboard() {
  const user_id = authUserId();
  const dispatch = useDispatch();
  // Redux State
  const getUnratedMeetingData = useSelector((state) => state.unrated.getUnratedMeetingsData);
  const data = getUnratedMeetingData;
  const prevGetUnratedMeetingsData = usePrevious({ getUnratedMeetingData });
  const sendMeetingData = useSelector((state) => state.unrated.sendUnratedMeetingsData);
  const prevSendMeetingData = usePrevious({ sendMeetingData });

  // Loader State
  const [loader, setLoader] = useState(false);
  // Initial State
  const [alert, setAlert] = useState({ show: false, title: null, message: null, errType: null });
  const [unratedOrganizedEvents, setUnratedOrganizedEvents] = useState([]);
  const [unratedAttendedEvents, setUnratedAttendedEvents] = useState([]);
  const [ratingAttribute, setRatingAttribute] = useState({});
  const [activeEventId, setActiveEventId] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {

      setLoader(true);
      dispatch(getUnratedMeetingsDetail({ grouped : 1 }));

  }, [])

  // Fetch Unrated Meetings data and Set into State
  useEffect(() => {
    if (prevGetUnratedMeetingsData && prevGetUnratedMeetingsData.getUnratedMeetingData !== getUnratedMeetingData) {
      if (getUnratedMeetingData && _.has(getUnratedMeetingData, 'data') && getUnratedMeetingData.status === true) {
        const unratedOrganizedEvents = getUnratedMeetingData.data.organized;
        const unratedAttendedEvents = getUnratedMeetingData.data.attended;
        
        // set organized events
        if (getUnratedMeetingData && getUnratedMeetingData.data && getUnratedMeetingData.data.organized && unratedOrganizedEvents.length === 0) {
          setLoader(false);
          // setAlert({
          //   show: true,
          //   message: getUnratedMeetingData.message,
          //   title: 'Success',
          //   errType: "success"
          // })
          setMessage(getUnratedMeetingData.message);
        } else {
          setUnratedOrganizedEvents(unratedOrganizedEvents);
          setMessage("");
          setLoader(false);
        }

        //set attended events

        if (getUnratedMeetingData && getUnratedMeetingData.data && getUnratedMeetingData.data.attended && unratedAttendedEvents.length === 0) {
          setLoader(false);
          // setAlert({
          //   show: true,
          //   message: getUnratedMeetingData.message,
          //   title: 'Success',
          //   errType: "success"
          // })
          setMessage(getUnratedMeetingData.message);
        } else {
          setUnratedAttendedEvents(unratedAttendedEvents);
          setMessage("");
          setLoader(false);
        }

      }
      if (getUnratedMeetingData && _.has(getUnratedMeetingData, 'message') && getUnratedMeetingData.status === false) {
          setLoader(false);
          setAlert({
            show: true,
            message: "Something went wrong!",
            title: 'Error',
            errType: "failure"
          })
      }
    }
    if (prevSendMeetingData && prevSendMeetingData.sendMeetingData !== sendMeetingData) {
      if (sendMeetingData && _.has(sendMeetingData, 'message') && sendMeetingData.status === true) {
        setUnratedOrganizedEvents([]);
        setUnratedAttendedEvents([]);
        setMessage("");
        setLoader(true);
        dispatch(getUnratedMeetingsDetail({  grouped : 1 }));
      }
      if (sendMeetingData && _.has(sendMeetingData, 'message') && sendMeetingData.status === false) {
        setLoader(false);
          setAlert({
            show: true,
            message: sendMeetingData?.message,
            title: 'Error',
            errType: "failure"
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevGetUnratedMeetingsData, getUnratedMeetingData, prevSendMeetingData, sendMeetingData]);

  // handle Input Change
  const handleInputChange = (e, event_id,Rname) => {
    setActiveEventId("");
    let quesId = ratingAttribute;
    quesId[event_id] = quesId && quesId[event_id] ? quesId[event_id] : [];
    let { name, value } = e.target;
    let concatData = name + value;
    let yes = Rname + "__yes";
    let no = Rname + "__no";
    let indexYes = quesId[event_id].indexOf(yes);
    let indexNo = quesId[event_id].indexOf(no);

    if (indexYes !== -1) {
      quesId[event_id].splice(indexYes, 1)
    }
    if (indexNo !== -1) {
      quesId[event_id].splice(indexNo, 1)
    }
    let rmEventId =concatData.substring( concatData.indexOf('.') +1)
    quesId[event_id].push(rmEventId)
    setRatingAttribute(quesId);
  }

  // handle Did not Attend Button
  const handleDidnotAttend = (event_id) => {
    const didnotAttendData = new FormData();
    // didnotAttendData.append('user_id', user_id);
    didnotAttendData.append('event_id', event_id);
    didnotAttendData.append('attended', 'no');
    setLoader(true);
    dispatch(sendUnratedMeetingDetail(didnotAttendData));
  }

  // handle Meetings Submit Button
  const handleSubmit = (event_id) => {
    let submitData = new FormData();
    // submitData.append('user_id', user_id);
    submitData.append('event_id', event_id);
    submitData.append('source_id', 1);
    submitData.append('rating_attribute', ratingAttribute[event_id]);
    // submitData.append('rating_attribute', JSON.stringify(ratingAttribute[event_id]));
    submitData.append('rating_version', 2);
    if (Object.keys(ratingAttribute).length !== 0 && ratingAttribute[event_id] !== undefined) {
      setLoader(true)
      dispatch(sendUnratedMeetingDetail(submitData))
    } else {
      setActiveEventId(event_id);
    }
  }
  // handle Close button on popup
  const handleClose = () => {
    setAlert({
      show: false
    })
  };
  return (
    <>
      <LayoutSplashScreen visible={loader} />
      {alert.show && <AlertPopup show={alert.show} handleClose={handleClose} title={alert.title} message={alert.message} errType={alert.errType} />}


      {/* Attended events */}
     
      <div className="col-md-12 card row col-card-gutter ml-2 mt-5">

<div className="card-header align-items-center border-0 pb-0 pl-1">
                <h5 className="card-title align-items-center d-flex w-100 justify-content-between mb-2">
                    <span className="font-weight-bolder text-dark">Attended Meetings - ({unratedAttendedEvents && unratedAttendedEvents.length ? unratedAttendedEvents.length : 0})</span>
                </h5>
                <p className="pb-4">Here are all those meetings that you have attended in this week. Kindly provide your valuable feedback for them.</p>

            </div>
            <div className="row " style={  (unratedAttendedEvents && unratedAttendedEvents.length == 0) || (getUnratedMeetingData && getUnratedMeetingData.data.length == 0 ) ? {height:"150px"} : null } >

{unratedAttendedEvents && unratedAttendedEvents.length !== 0 ? unratedAttendedEvents && _.map(unratedAttendedEvents, (data, key) => {
  return (
    <div className="col-xl-4 col-md-6" key={key}>
      {/* <div className="card mb-4 card-custom unrated-meeting-cards rate--buttons" style={{"height":"auto"}}> */}
      <div className={`card ${unratedAttendedEvents.length === 1 ? '' : 'mb-4'} card-custom unrated-meeting-cards rate--buttons`} style={{"height":"auto",marginBottom:unratedAttendedEvents.length === 1 ? '-18rem' : ''}}>
        {/* Header */}
        <div className="card-header align-items-center border-0">
          <h3 title={data?.event_title} className="card-title align-items-center d-flex w-100 justify-content-between"><span className="font-weight-bolder text-dark">{parse(capitalize(data.event_title !== null ? data.event_title?.length > 40 ? data.event_title.substr(0, 40) + '...' : data.event_title :'N/A'))}</span></h3>
          <div className="card-subtitle text-left">
          <h5 className="d-block"><strong>Organizer: </strong> {data.organizer_full_name} </h5>
            <h5>{moment(data.event_start_datetime).format('L')} at {moment(data.event_start_datetime).format('h A')}</h5>
          </div>
        </div>

        {/* Body */}
        <div className="card-body position-relative overflow-hidden">
          <div className="rate--buttons_table">
            <table>
              <tbody>
                {data && _.map(data.questions, (item, key) => {
                  return (
                    <tr key={key+data?.event_id}>
                      <td>{item.title}</td>
                      <td className="rate--btns">
                        <div className="auth-selectgroup">
                          
                        <label className="auth-selectgroup-item">
                            <input className="selectgroup-input" type="radio" name={data.event_id +'.'+ item.rating_attribute_master_id} id="2" value="__yes" onChange={(e) => handleInputChange(e, data.event_id,item.rating_attribute_master_id)} />
                            <span className="selectgroup-button btn--yes">Yes</span>
                          </label>

                          <label className="auth-selectgroup-item">
                            <input className="selectgroup-input" type="radio" name={data.event_id +'.'+ item.rating_attribute_master_id} id="1" value="__no" onChange={(e) => handleInputChange(e, data.event_id,item.rating_attribute_master_id)} />
                            <span className="selectgroup-button btn--no">No</span>
                          </label>
                          
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          
          <div className="card-footer d-flex position-relative">
            {data.event_id === activeEventId ? <div className="unrated-error"><div className="text-center unrated-error-msg" id={data.event_id} ><img src="/media/ms-media/info-sm-icn.svg" alt="" />To send advice, please share your feedback</div></div> : ""}
            <button className="btn btn-bordered btn--ddnt-attend" onClick={() => handleDidnotAttend(data.event_id)}>Didn’t Attend</button> <button className="btn btn--submit" onClick={() => handleSubmit(data.event_id)}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  )
})
: <div className="col-12 text-center" style={{"display":"flex","alignItems":"center","justifyContent":"center"}}>  We're sorry, we don't have any data to share with you. </div>
}
</div>
</div>


      {/*Organized events*/}

      <div className="col-md-12 card row col-card-gutter ml-2 mt-15" >

      <div className="card-header align-items-center border-0 pb-0 pl-1">
                        <h5 className="card-title align-items-center d-flex w-100 justify-content-between mb-2">
                            <span className="font-weight-bolder text-dark">Organized Meetings - ({unratedOrganizedEvents && unratedOrganizedEvents.length ? unratedOrganizedEvents.length : 0}) </span>
                        </h5>
                        <p className="pb-4">Here are all those meetings that you have organized in this week. Kindly provide your valuable feedback for them.</p>
                    </div>
                    

                    <div className="row " style={ (unratedOrganizedEvents && unratedOrganizedEvents.length == 0) || (getUnratedMeetingData && getUnratedMeetingData.data.length == 0 ) ? {height:"150px"} : null } >

        
        { unratedOrganizedEvents && unratedOrganizedEvents.length !== 0 ? unratedOrganizedEvents && _.map(unratedOrganizedEvents, (data, key) => {
          return (
            <div className="col-xl-4 col-md-6" key={key}>
              <div className={`card ${unratedOrganizedEvents.length === 1 ? '' : 'mb-4'} card-custom unrated-meeting-cards rate--buttons`} style={{"height":"auto",marginBottom:unratedOrganizedEvents.length === 1 ? '-18rem':''}}>
                {/* Header */}
                <div className="card-header align-items-center border-0">
                  <h3 title={data?.event_title} className="card-title align-items-center d-flex w-100 justify-content-between"><span className="font-weight-bolder text-dark">{parse(capitalize(data.event_title !== null ?data.event_title?.length > 40 ? data.event_title.substr(0, 40) + '...' : data.event_title :'N/A' ))}</span></h3>
                  <div className="card-subtitle d-flex justify-content-between">
                    <h5>{moment(data.event_start_datetime).format('L')} at {moment(data.event_start_datetime).format('h A')}</h5>
                  </div>
                </div>

                {/* Body */}
                <div className="card-body position-relative overflow-hidden">
                  <div className="rate--buttons_table">
                    <table>
                      <tbody>
                        {data && _.map(data.questions, (item, key) => {
                          return (
                            <tr key={key+data?.event_id}>
                              <td>{item.title}</td>
                              <td className="rate--btns">
                                <div className="auth-selectgroup">
                                  
                                <label className="auth-selectgroup-item">
                                    <input className="selectgroup-input" type="radio" name={data.event_id +'.'+item.rating_attribute_master_id} id="2" value="__yes" onChange={(e) => handleInputChange(e, data.event_id,item.rating_attribute_master_id)} />
                                    <span className="selectgroup-button btn--yes">Yes</span>
                                  </label>

                                  <label className="auth-selectgroup-item">
                                    <input className="selectgroup-input" type="radio" name={data.event_id +'.'+item.rating_attribute_master_id} id="1" value="__no" onChange={(e) => handleInputChange(e, data.event_id,item.rating_attribute_master_id)} />
                                    <span className="selectgroup-button btn--no">No</span>
                                  </label>
                                  
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  
                  <div className="card-footer d-flex position-relative">
                    {data.event_id === activeEventId ? <div className="unrated-error"><div className="text-center unrated-error-msg" id={data.event_id} ><img src="/media/ms-media/info-sm-icn.svg" alt="" />To send advice, please share your feedback</div></div> : ""}
                    <button className="btn btn-bordered btn--ddnt-attend" onClick={() => handleDidnotAttend(data.event_id)}>Didn’t Attend</button> <button className="btn btn--submit" onClick={() => handleSubmit(data.event_id)}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          )
        })
          : <div className="col-12 text-center" style={{"display":"flex","alignItems":"center","justifyContent":"center"}}>  We're sorry, we don't have any data to share with you. </div>
        }
      </div>
      </div>


           
    </>
  );
}