/* eslint-disable */ 
import React, {useEffect } from "react";
import { requestOrganizedData, REQUEST_FEEDBACK_TREND_OVERTIME, REQUEST_NEW_FEEDBACK_GRAPH_DATA } from "../../../redux/duck/dashboard/organized/organized.action";
import { FeedbackSummary, KeyKpiWidget2, WhoseTimeDoIRequestTheMost, YouVsAttendeesChart, DoubleBarGraph, MemberFeedbackChart } from "../widgets";
import { MostExpensiveMeetings } from "../widgets/widget21";
import { useDispatch, useSelector } from 'react-redux';
import { authUserId } from "../../_helpers/functions";

export function OrganizedDashboard() {


  const dispatch = useDispatch();
  const {timePeriod} = useSelector(state => state.picker);
  const {getLocalStorageUserData} = useSelector(state => state.profile)
  const {memberFeedback} = useSelector(state => state.organized);


  const commonParamters = {
    // organizer_id:authUserId(),
    sdate:timePeriod.sdate,
    edate:timePeriod.edate
  }

useEffect(() => {
  if(getLocalStorageUserData && getLocalStorageUserData.user_data){
      
    dispatch(requestOrganizedData({
          timezone:getLocalStorageUserData.user_data.timezone,
          currency_conversion_rate:getLocalStorageUserData.user_data.currency.currency_conversion_rate,
          organizer_id:getLocalStorageUserData.user_data.user_id,
          ...commonParamters
        }));
        // dispatch(requestUserFeedback({
        //   user_id:authUserId(),
        //   sdate:timePeriod.sdate,
        //   edate:timePeriod.edate,
        // }));
        
  }
},[timePeriod.filter,timePeriod.sdate,timePeriod.edate,timePeriod.custom])


useEffect(() => {
  dispatch({type:REQUEST_NEW_FEEDBACK_GRAPH_DATA,payload:{
    // user_id:authUserId()
  }})

  dispatch({type:REQUEST_FEEDBACK_TREND_OVERTIME, payload:{
    // user_id:authUserId()

  }})

},[])

  return (
    <>
      <div className="row col-card-gutter">

      
        <div className="col-xl-8 col-md-7">
              <MemberFeedbackChart dataset={memberFeedback}/>
            </div>



        <div className="col-xl-4 col-md-5">
            <FeedbackSummary />    
        </div>

        <div className="col-xl-8 col-md-8">
            <div>
              <DoubleBarGraph/>
            </div>
        <div >
            <KeyKpiWidget2/> 
        </div>

        </div>
        
        <div className="col-xl-4">
              <div>
                <YouVsAttendeesChart/>
              </div>

              <div>
                <MostExpensiveMeetings />
              </div> 
        </div>

        <div className="col-xl-8 col-md-7">
          <WhoseTimeDoIRequestTheMost/>
        </div>

        {/* <div className="col-xl-4 col-md-5">
          <MostExpensiveMeetings />
        </div>  */}


            {/* <button className="btn btn-primary" onClick={handleYourAchievementModal}>Your Achievements Modal</button>
        <TimeManagementModal show={timeModal} handleClose={() => setTimeModal(false)} />
        <YourAchievementsModal show={yourAchievementsModal} handleClose={() => setYourAchievementsModal(false)}/> */}
      </div>
    </>
  );
}
