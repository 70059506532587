/* eslint-disable */ 
import React, { useEffect } from "react";
import { MeetingsPerDay, HoursConsumedByMeetingDuration, MeetingDensityPerDay, CompanyKeyKpi, CompanyRatings, WhoAttendsMostMeeting, WhoSpendsMostTimeinMeeting, TopOrganizersUsingCompanyTime, MemberFeedbackChart } from "../widgets";
import { useDispatch,useSelector} from "react-redux";
import { REQUEST_COMPANY_DATA, REQUEST_COMPANY_OVERALL_FEEDBACK } from "../../../redux/duck/dashboard/company/company.action";
import { authCompanyId} from "../../_helpers/functions";
import { FilterRange } from "../../layout/components/header/FilterLabel";

export function CompanyDashboard() {
  
    const dispatch = useDispatch();
    const {timePeriod} = useSelector(state => state.picker)
    const {company_overall_feedback} = useSelector(state => state.company);

    useEffect(() => {
            dispatch({type:REQUEST_COMPANY_DATA,payload:{
                // user_id:authUserId(),
                company_id:authCompanyId(),
                sdate:timePeriod.sdate,
                edate:timePeriod.edate,
            }})

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timePeriod.sdate, timePeriod.edate])

    useEffect(() => {
        dispatch({type:REQUEST_COMPANY_OVERALL_FEEDBACK,payload:{
            // user_id:authUserId(),
            company_id:authCompanyId()
        }})
    },[])
   
  return (
    <>

    <div className="row col-card-gutter">        
         <CompanyKeyKpi/>   
         <CompanyRatings/>
         <div className="col-md-12">
             <MemberFeedbackChart dataset={company_overall_feedback}/>
         </div>
         <MeetingDensityPerDay />
         <MeetingsPerDay />
         <HoursConsumedByMeetingDuration />    
         <div className="col-12">
            <div className="row">
                <div className="col-lg-7 col-md-6">
                    <div className="row">
                        <div className="col-lg-12 col-md-6">
                            <div className="card mb-4 card-custom">

                                <div className="card-header align-items-center border-0">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="font-weight-bolder text-dark">Who Attends the Most Meetings</span>
                                        <span className="text-muted mt-3 font-weight-bold font-size-sm">Number of meetings attended for each person for the <FilterRange/></span>
                                    </h3>
                                </div>

                                <div className="card-body position-relative overflow-hidden">
                                          <WhoAttendsMostMeeting />
                                </div>
                            </div>        
                        </div>

                        <div className="col-lg-12 col-md-6">
                                  <div className="card mb-4 card-custom">
                                <div className="card-header align-items-center border-0">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="font-weight-bolder text-dark">Who Spends the Most Time in Meetings</span>
                                        <span className="text-muted mt-3 font-weight-bold font-size-sm">Time's percent spent on meetings for each person in relation to working time for the <FilterRange/></span>
                                    </h3>
                                </div>

                                <div className="card-body position-relative overflow-hidden">
                                    
                                          <WhoSpendsMostTimeinMeeting />
                                </div>
                            </div>        
                        </div>
                    </div>
                </div>            
                <div className="col-lg-5 col-md-6">
                          <div className="card mb-4 card-custom">
                        <div className="card-header align-items-center border-0">
                            <h3 className="card-title align-items-start flex-column">
                                <span className="font-weight-bolder text-dark">Top Organizers Using Company Time</span>
                                <span className="text-muted mt-3 font-weight-bold font-size-sm">Top-10 people who organized the most meetings for the <FilterRange/></span>
                            </h3>
                        </div>

                        <div className="card-body position-relative overflow-hidden">
                                  <TopOrganizersUsingCompanyTime />

                        </div>
                    </div>
                </div>

            </div>
        </div>
      </div>

    </>
  );
}