import { SET_FILTER_DAYS } from './picker.action';
import {DEFAULT_STATE} from './picker.state';

export const pickerReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case SET_FILTER_DAYS:
            return {
                ...state,
                timePeriod:action.data
            }; 
        default:
            return state;
    }
}