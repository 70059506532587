import React from "react";
import { NavLink } from "react-router-dom";

export const Navbar = (props) => {
  
  return (
    <>
          <div className="nav-scrollable">
            <ul className="nav flex-grow-1" id="profileTab" role="tablist">
              <li className="nav-item">
                <NavLink
                  to="/user-profile/personal-info"
                  className={
                    props && props.getMainRoute === "personalInfo"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Personal info
                </NavLink>
              </li>
              <li className="nav-item">

                <NavLink
                  to="/user-profile/relationship"
                  className={
                    props && props.getMainRoute === "relationship"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Relationships
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/user-profile/notification"
                  className={
                    props && props.getMainRoute === "notification"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Notifications
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/user-profile/feedback-settings"
                  className={
                    props && props.getMainRoute === "feedback"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Feedback
                </NavLink>
              </li>    
              <li className="nav-item">
                <NavLink
                  to="/user-profile/privacy"
                  className={
                    props && props.getMainRoute === "privacy"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Privacy
                </NavLink>
              </li>
            </ul>
          </div>
          {/* <div className="edit-button"><a className="btn btn-primary" href="#">Save Changes</a> <a className="btn btn-edit" href="#">Edit</a></div> */}
        
    </>
  );
};
