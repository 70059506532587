export const REQUEST_MASTERY_LEVELS_LIST = "REQUEST_MASTERY_LEVELS_LIST";
export const RECEIVE_MASTERY_LEVELS_LIST = "RECEIVE_MASTERY_LEVELS_LIST";
export const requestMasteryLevels = (data) => ({type : REQUEST_MASTERY_LEVELS_LIST,payload:data});
export const receiveMasteryLevels = (data) => ({ type: RECEIVE_MASTERY_LEVELS_LIST, data:data })

export const REQUEST_HIGHEST_LEVEL_ACHEIVED = "REQUEST_HIGHEST_LEVEL_ACHEIVED";
export const RECEIVE_HIGHEST_LEVEL_ACHEIVED = "RECEIVE_HIGHEST_LEVEL_ACHEIVED";
export const requeseHighestLevelAcheived = (data) => ({type : REQUEST_HIGHEST_LEVEL_ACHEIVED,payload:data});
export const receiveHighestLevelAcheived = (data) => ({ type: RECEIVE_HIGHEST_LEVEL_ACHEIVED, data:data })

export const REQUEST_BADGE_SUMMARY = "REQUEST_BADGE_SUMMARY";
export const RECEIVE_BADGE_SUMMARY = "RECEIVE_BADGE_SUMMARY";
export const requestBadgeSummary = (data) => ({type : REQUEST_BADGE_SUMMARY,payload:data});
export const receiveBadgeSummary = (data) => ({ type: RECEIVE_BADGE_SUMMARY, data:data })


export const REQUEST_USER_PROGRESS_SUMMARY = "REQUEST_USER_PROGRESS_SUMMARY";
export const RECEIVE_USER_PROGRESS_SUMMARY = "RECEIVE_USER_PROGRESS_SUMMARY";
export const requestUserProgress = (data) => ({type : REQUEST_USER_PROGRESS_SUMMARY,payload:data});
export const receiveUserProgress = (data) => ({ type: RECEIVE_USER_PROGRESS_SUMMARY, data:data })

export const RESET_USER_PROGRESS_STATE = "RESET_USER_PROGRESS_STATE";
export const resetUserProgress = (data) => ({type:RESET_USER_PROGRESS_STATE,data:data})