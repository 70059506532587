// View data in attended section 
export const RESET_STATE = "RESET_STATE";
export const resetData = (data) => ({type:'RESET_STATE',data:data});

export const RECEIVE_ORGANIZED_SUMMARY_ERROR = "RECEIVE_ORGANIZED_SUMMARY_ERROR";
export const error = (data) => ({type:'RECEIVE_ORGANIZED_SUMMARY_ERROR',data:data});

export const REQUEST_ORGANIZED_DATA = "REQUEST_ORGANIZED_DATA";
export const RECEIVE_ORGANIZED_DATA = "RECEIVE_ORGANIZED_DATA";
export const requestOrganizedData = (data) => ({type : REQUEST_ORGANIZED_DATA,payload:data});
export const receiveOrganizedData = (data) => ({ type: RECEIVE_ORGANIZED_DATA, data:data });

export const REQUEST_USER_RATING = "REQUEST_USER_RATING";
export const RECEIVE_USER_RATING = "RECEIVE_USER_RATING";
export const requestUserRating = (data) => ({type : REQUEST_USER_RATING,payload:data});
export const receiveUserRating = (data) => ({ type: RECEIVE_USER_RATING, data:data });

export const REQUEST_USER_RATING_ATTRIBUTES = "REQUEST_USER_RATING_ATTRIBUTES";
export const RECEIVE_USER_RATING_ATTRIBUTES = "RECEIVE_USER_RATING_ATTRIBUTES";
export const requestUserRatingAttributes = (data) => ({type : REQUEST_USER_RATING_ATTRIBUTES,payload:data});
export const receiveUserRatingAttributes = (data) => ({ type: RECEIVE_USER_RATING_ATTRIBUTES, data:data });

export const REQUEST_USER_COMPANY_RANK = "REQUEST_USER_COMPANY_RANK";
export const RECEIVE_USER_COMPANY_RANK = "RECEIVE_USER_COMPANY_RANK";
export const requestUserCompanyRank = (data) => ({type : REQUEST_USER_COMPANY_RANK,payload:data});
export const receiveUserCompanyRank = (data) => ({ type: RECEIVE_USER_COMPANY_RANK, data:data });

// export const REQUEST_USER_FEEDBACK_DATA = "REQUEST_USER_FEEDBACK_DATA";
// export const RECEIVE_USER_FEEDBACK_DATA = "RECEIVE_USER_FEEDBACK_DATA";
// export const requestUserFeedback = (data) => ({type : REQUEST_USER_FEEDBACK_DATA,payload:data});
// export const receiveUserFeedback = (data) => ({ type: RECEIVE_USER_FEEDBACK_DATA, data:data });

export const REQUEST_NEW_FEEDBACK_GRAPH_DATA = "REQUEST_NEW_FEEDBACK_GRAPH_DATA";
export const RECEIVE_NEW_FEEDBACK_GRAPH_DATA = "RECEIVE_NEW_FEEDBACK_GRAPH_DATA";
export const requestNewFeedbackData = (data) => ({type : REQUEST_NEW_FEEDBACK_GRAPH_DATA,payload:data});
export const receiveNewFeedbackData = (data) => ({ type: RECEIVE_NEW_FEEDBACK_GRAPH_DATA, data:data });


export const REQUEST_SINGLE_MEETING_RATING = "REQUEST_SINGLE_MEETING_RATING";
export const RECEIVE_SINGLE_MEETING_RATING = "RECEIVE_SINGLE_MEETING_RATING";
export const RESET_SCORE_FOR_SINGLE_EVENT ="RESET_SCORE_FOR_SINGLE_EVENT"
export const requestSingleMeetingRating = (data) => ({type : REQUEST_SINGLE_MEETING_RATING,payload:data});
export const receiveSingleMeetingRating = (data) => ({ type: RECEIVE_SINGLE_MEETING_RATING, data:data });
export const resetScore = (data) => ({ type: RESET_SCORE_FOR_SINGLE_EVENT, data:null });

export const REQUEST_SINGLE_RATING_ATTRIBUTES = "REQUEST_SINGLE_RATING_ATTRIBUTES";
export const RECEIVE_SINGLE_RATING_ATTRIBUTES = "RECEIVE_SINGLE_RATING_ATTRIBUTES";
export const requestSingleRatingAttributes = (data) => ({type : REQUEST_SINGLE_RATING_ATTRIBUTES,payload:data});
export const receiveSingleRatingAttributes = (data) => ({ type: RECEIVE_SINGLE_RATING_ATTRIBUTES, data:data });

export const REQUEST_ORGANIZED_MEETINGS_KEY_DIFFERENCES = "REQUEST_ORGANIZED_MEETINGS_KEY_DIFFERENCES";
export const RECEIVE_ORGANIZED_MEETINGS_KEY_DIFFERENCES = "RECEIVE_ORGANIZED_MEETINGS_KEY_DIFFERENCES";
export const requestKeyDifferences = (data) => ({ type: REQUEST_ORGANIZED_MEETINGS_KEY_DIFFERENCES, payload: data });
export const receiveKeyDifferences = (data) => ({ type: RECEIVE_ORGANIZED_MEETINGS_KEY_DIFFERENCES, data: data });

export const REQUEST_FEEDBACK_TREND_OVERTIME = "REQUEST_FEEDBACK_TREND_OVERTIME";
export const RECEIVE_FEEDBACK_TREND_OVERTIME = "RECEIVE_FEEDBACK_TREND_OVERTIME";
export const requestFeedbackTrend = (data) => ({ type: REQUEST_FEEDBACK_TREND_OVERTIME, payload: data });
export const receiveFeedbackTrend = (data) => ({ type: RECEIVE_FEEDBACK_TREND_OVERTIME, data: data });


