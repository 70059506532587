import {DEFAULT_STATE} from './attended.state'
import {RECEIVE_ATTENDED_MEETINGS_KEY_DIFFERENCES, RECEIVE_ATTENDEES_DATA, RESET_STATE} from './attended.action'

export const attendeesReducer = (state = DEFAULT_STATE, action = { type: {}, data: {} }) => {
    switch (action.type) {
        case RECEIVE_ATTENDEES_DATA:
            const data = action.data;
            return data; 
        case RESET_STATE:
            return {
                initialState:null
            };
            case RECEIVE_ATTENDED_MEETINGS_KEY_DIFFERENCES:
                return {
                    ...state,
                    differences: action.data
                }
        default:
            return state;
    }
}