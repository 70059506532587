/* eslint-disable */ 

import React,{useState,useEffect} from "react";
import { Link, useHistory } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/classic/sign-in.scss";
import {default as jobslist} from "../../datasets/jobTitles";
import { allFirstLettersCapitalize, authUser, authUserLastname, checkObjectSize, checkPhoneValidity, checkPlusSymbol, fetchSalaries, getEnvUrl, getEventCount, getServiceProvider, getSuccessfulEvents, getUserAgent, mcrCheck, step5Check } from "../../../_metronic/_helpers/functions";
import { getStorage, setStorage } from "../../../_metronic/_helpers";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import Select from 'react-select';
import { NoRefresh } from "../../../_metronic/layout/components/extras/NoRefresh";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { Apistore } from "../../../redux/apiStore";
import Axios from "axios";
import { number } from "prop-types";
import { sendApiError, sendSignupNotifications } from "../../modules/Auth/_redux/authCrud";
import { actions } from "../../modules/Auth/_redux/authRedux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SVGComponent from "./SVGComponent";
import './step1.css'
import { AlertPopup } from "../../../_metronic/_helpers/Alerts";


const Step1 = () => {      

        const [highlighted,setButtonHighlighted] = useState(false);
        const [isFormSubmitted,setForSubmit] = useState(false);
  const [loading2,setLoading2] = useState(false);
  const [phoneData,setPhoneData]=useState(false)



        const [data,setData] = useState({
          jobTitle:null,
          jobLevel:null,
          yearsExp:null,
          job_title_array:[] ,
        });
        const [data2,setData2] = useState({
         countryObj:'',
          user_phone_number:'',
          country: '',
          // user_phone_number_area_code:'',
          user_phone_number_area_code:'',
          isPhoneValid: false,

        });
        const [toggelDisable,setToggelDisable]=useState(false)
        const [options,setOptions] = useState([]);
        const [loading,setLoading] = useState(false);
        const [phoneErrors, setPhoneErrors] = useState('')
        const [showError,setShowError]=useState({
          jobTitle:false,
          jobLevel:false,
          yearsExp:false    
        })
        const [alert,setAlert] = useState({
          show:false,
          title:null,
          message:null,
          errType:null
        })
        
        const { areEventsPulled,isMasteryCalculated,isScoreCalculated,isSignupComplete,isRecommendationCalculated,isRecommendationPresent } = useSelector(
          ({ auth }) => ({
           areEventsPulled: auth.areEventsPulled, //1
           isMasteryCalculated:auth.isMasteryCalculated,
           isScoreCalculated:auth.isScoreCalculated,
           isSignupComplete:auth.isSignupComplete,
           isRecommendationCalculated:auth.isRecommendationCalculated, //2
           isRecommendationPresent:auth.isRecommendationPresent //3
          }),
          shallowEqual
        );
const dispatch = useDispatch()
        const errors={
                 jobTitle:'Job title field is required.',
                 jobLevel:'Job level field is required.',
                 yearsExp:'Years of experience field is required.',
        };

        let emptyFields = {};

        const autocompleteStyles = {
          container:{
              "position":"relative"
          },
          autocompleteblock:{
            "height": "150px",
            "background":" #fff",
            "z-index": "999",
            "overflow": "auto",
            "right": "0",
            "left": "0",
            "position":"absolute"
          }
        }

       

        const {jobLevel,yearsExp} = data;
        const [jobLevelValue, setJobLevelValue] = useState('')
        const [yearExpValue, setYearExpValue] = useState('')
        useEffect(() => {
          if (jobLevel) {
            setJobLevelValue([{value:jobLevel,label:jobLevel}])
          }
           if (yearsExp) {
            setYearExpValue([{value:yearsExp,label:yearsExp}])
          }
         }, [jobLevel,yearsExp])
        const history = useHistory();

        const jobLevels =[
          {value:'Executive',label:'Executive'},
          {value:'VP',label:'VP'},
          {value:'Director',label:'Director'},
          {value:'Supervisor',label:'Supervisor'},
          {value:'Manager',label:'Manager'},
          {value:'Other',label:'Other'},
        ]
         
        const exp_years =[
          {value:'1-4',label:'1-4'},
          {value:'5-7',label:'5-7'},
          {value:'7-10',label:'7-10'},
          {value:'11-15',label:'11-15'},
          {value:'16-20',label:'16-20'},
          {value:'20+',label:'20+'},
        ]

        const inputHandler = (e,n) => {
          const {label,value} = e; 
          let name = n
          //  emptyFields= {};
          
           // handle input change and validation //
          
          switch(name){
              case 'jobTitle':
                  if (value == ''){
                    setShowError({...showError,[name]:true})             
                  }else{
                      value.length > 50 && value.substring(0,50);
                    setShowError({...showError,[name]:false}) 
                  }
                  break;
                  case 'yearsExp':
                  if (value == ''){
                    setShowError({...showError,[name]:true})             
                  }else{
                    setShowError({...showError,[name]:false}) 
                  }
                  break;
                  case 'jobLevel':
                  if (value == ''){
                    setShowError({...showError,[name]:true})             
                  }else{
                    setShowError({...showError,[name]:false}) 
                  }
                  break;
              }
              if( value.length < 80){
                setData({ ...data, [name]: allFirstLettersCapitalize(value) })
              }else{
                e.preventDefault()
              }
        }



        const checkEmptyFields = () => {
              for ( let i in data) {
                  if(data[i] == '' || data[i] == null) {
                      Object.assign(emptyFields,{[i]:true})
                  }
              }
              if(checkObjectSize(emptyFields)) {
                return 1;
              }else{

                return 0;
              }
        }

        const handlePhoneCheck = () => {
          setLoading(true);
          Axios.post(Apistore.CheckIsPhoneUnique, {
            // user_id:authUserId(),
            user_phone_number: checkPlusSymbol(data2.user_phone_number),
            user_phone_number_area_code: checkPlusSymbol(data2.user_phone_number_area_code)
          }).then(res => {
            if (res.data.status == "error") {
              setLoading(false);
              setData2({
                ...data2,
                isPhoneValid: false
              })
        
              setPhoneErrors( res.data.message)
        
            } else {
              setStorage('ST1Phone',JSON.stringify(data2));
              setStorage('ST1',JSON.stringify(data));

              setPhoneData(true)
              setLoading(false)
             setForSubmit(true)
              setLoading2(true)
  
                        setTimeout(() => {
                          dispatch(actions.fulfillUser(1));
                         }, 3000); 
            }
        
          }).catch(err => {
            console.log(err);
          })
       
        }


        
    


        const submitData = () => {

          // setLoading(true);
          const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
          const timeZoneFormated = timeZone.replace("Calcutta","Kolkata")
          let formData = {}
          // const salary = authUserSalary();
          if(phoneData){
            formData = {
              ...data,
              ...data2,
              user_firstname: authUser(),
              user_lastname: authUserLastname(),
              user_timezone:timeZoneFormated
            }
          }else{
            formData = {
              ...data,
              user_firstname: authUser(),
              user_lastname: authUserLastname(),
              user_timezone:timeZoneFormated
            }
          }
           
        
           Axios.post(Apistore.RegisterUrl,formData)
            .then((res) => {
             
              const {code,redirect} = res.data
         if(code == 200){
            setStorage('SIGNUP_END_TIME',moment().format('YYYY-MM-DD hh:mm:ss')) 
          let endTime =localStorage.getItem('SIGNUP_END_TIME')
            sendSignupNotifications({
              // user_id:authUserId(),
              event_saved:getSuccessfulEvents(),
              total_events:getEventCount(),
              environment_url:getEnvUrl(),
              service_provider:getServiceProvider(),
              user_agent:getUserAgent(),
              signup_start_time:localStorage.getItem('SIGNUP_START_TIME'),
              signup_end_time:endTime ? endTime : localStorage.getItem('SIGNUP_END_TIME')
            })
          
            .then((res) => {

                      if(res.data.code == 200){
                              setLoading(false);
                                            if(isRecommendationPresent){
                                              dispatch(actions.login(getStorage('ACCESS_TOKEN'),"false","true"))
                                              setStorage('STEP5_CHECK',1);

                                              if(redirect == 'dashboard'){
                                              setStorage('REDIRECT','/attended')
                                            }else if(redirect == 'meeting-mastery'){
                                                setStorage('REDIRECT','/meeting-mastery')
                                            }
                                              history.push('/signup-page2');
                                            }
                                            else {
                                              
                                              dispatch(actions.login(getStorage('ACCESS_TOKEN'),"true","true"))

                                              if(redirect == 'dashboard'){
                                                history.push('/attended')
                                            }else if(redirect == 'meeting-mastery'){
                                                history.push('/meeting-mastery')
                                            }
                                            }
                   
                            
                        }else{
                          setLoading(false);
                                            if(isRecommendationPresent){
                                              dispatch(actions.login(getStorage('ACCESS_TOKEN'),"false","true"))
                                              setStorage('STEP5_CHECK',1);

                                              if(redirect == 'dashboard'){
                                              setStorage('REDIRECT','/attended')
                                            }else if(redirect == 'meeting-mastery'){
                                                setStorage('REDIRECT','/meeting-mastery')
                                            }
                                              history.push('/signup-page2');
                                            }
                                            else {
                                              
                                              dispatch(actions.login(getStorage('ACCESS_TOKEN'),"true","true"))

                                              if(redirect == 'dashboard'){
                                                history.push('/attended')
                                            }else if(redirect == 'meeting-mastery'){
                                                history.push('/meeting-mastery')
                                            }
                                            }
                        }
                      
            })
           .catch((err) => {
             sendApiError(500,err,Apistore.TriggerSignupNotification)
            setLoading(false);
            if(isRecommendationPresent){
              dispatch(actions.login(getStorage('ACCESS_TOKEN'),"false","true"))
              setStorage('STEP5_CHECK',1);
              if(redirect == 'dashboard'){
                                              setStorage('REDIRECT','/attended')
                                            }else if(redirect == 'meeting-mastery'){
                                                setStorage('REDIRECT','/meeting-mastery')
                                            }
              history.push('/signup-page2');
            }
            else {
              
              dispatch(actions.login(getStorage('ACCESS_TOKEN'),"true","true"))

              if(redirect == 'dashboard'){
                history.push('/attended')
            }else if(redirect == 'meeting-mastery'){
                history.push('/meeting-mastery')
            }
            }
            // setAlert({
            //   show:true,
            //   message:"Signup successful, Welcome to Meeting Science",
            //   title:'Error',
            //   errType:"success"
            // }) 
          })
        }else if(code == 500){
          setLoading(false);  
          setLoading2(false)
          setAlert({
            show:true,
            message:"We were unable to submit your data, maybe some fields are missing, please go through all the steps, fill the correct data and try again.",
            title:'Error',
            errType:"error"
          })                   
        }
        
      })
      .catch((err) => {
        sendApiError(500,err,Apistore.RegisterUrl)
        setLoading(false);
        setLoading2(false)

        setAlert({
          show:true,
          message:"Something went wrong while sending your data,please check your data and try again.",
          title:'Error',
          errType:"error"
        })                   
      })
        }


        ////------------------------------------working start ---------------------------
        // const submitData = () => {
        //   // setLoading(true);
        //   const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

        //   // const salary = authUserSalary();
        //   const formData = {
        //     ...data,
        //     user_firstname: authUser(),
        //     user_lastname: authUserLastname(),
        //     timezone:timeZone
            
            
        //     //user_salary:salary
        //   }
        
        //    Axios.post(Apistore.RegisterUrl,formData)
        //     .then((res) => {
             
        //       const {code,redirect} = res.data
        //       if(code == 200){

        //         // setStorage('SIGNUP_END_TIME',moment().format('YYYY-MM-DD hh:mm:ss')) 
        //         dispatch(actions.login(getStorage('ACCESS_TOKEN'),"true","true"))

        //     if (redirect == 'dashboard') {
        //           history.push('/attended')
        //     }else if(redirect == 'meeting-mastery'){
        //       setTimeout(() => {
                
        //         history.push('/meeting-mastery')
        //       }, 2000);
        //     }else{
        //       return null
        //     }       
        //           //  let   i = 1
                
        //           //     if(i = 1){
        //           //             setLoading(false);
        //           //                           if(isRecommendationPresent){
        //           //                             dispatch(actions.login(getStorage('ACCESS_TOKEN'),"false","true"))
        //           //                             setStorage('STEP5_CHECK',1);
        //           //                             if(redirect == 'dashboard'){
        //           //                             setStorage('REDIRECT','/attended')
        //           //                           }else if(redirect == 'meeting-mastery'){
        //           //                               setStorage('REDIRECT','/meeting-mastery')
        //           //                           }
        //           //                             history.push('/signup-page6');
        //           //                           }
        //           //                           else {
        //           //                             dispatch(actions.login(getStorage('ACCESS_TOKEN'),"true","true"))
        //           //                             if(redirect == 'dashboard'){
        //           //                               history.push('/attended')
        //           //                           }else if(redirect == 'meeting-mastery'){
        //           //                               history.push('/meeting-mastery')
        //           //                           }
        //           //                           }
        //           //       }else{
        //           //             setLoading(false);                          
        //           //             if(isRecommendationPresent){
        //           //               dispatch(actions.login(getStorage('ACCESS_TOKEN'),"false","true"))
        //           //               setStorage('STEP5_CHECK',1);
        //           //               if(redirect == 'dashboard'){
        //           //                             setStorage('REDIRECT','/attended')
        //           //                           }else if(redirect == 'meeting-mastery'){
        //           //                               setStorage('REDIRECT','/meeting-mastery')
        //           //                           }
        //           //               history.push('/signup-page6');
        //           //             }
        //           //             else {
        //           //               dispatch(actions.login(getStorage('ACCESS_TOKEN'),"true","true"))
        //           //               if(redirect == 'dashboard'){
        //           //                 history.push('/attended')
        //           //             }else if(redirect == 'meeting-mastery'){
        //           //                 history.push('/meeting-mastery')
        //           //             }
        //           //             }
        //           //       }
        //           //  sendApiError(500,err,Apistore.TriggerSignupNotification)
        //           // setLoading(false);
        //           // if(isRecommendationPresent){
        //           //   dispatch(actions.login(getStorage('ACCESS_TOKEN'),"false","true"))
        //           //   setStorage('STEP5_CHECK',1);
        //           //   if(redirect == 'dashboard'){
        //           //                                   setStorage('REDIRECT','/attended')
        //           //                                 }else if(redirect == 'meeting-mastery'){
        //           //                                     setStorage('REDIRECT','/meeting-mastery')
        //           //                                 }
        //           //   history.push('/signup-page6');
        //           // }
        //           // else {
                    
        //           //   dispatch(actions.login(getStorage('ACCESS_TOKEN'),"true","true"))
      
        //           //   if(redirect == 'dashboard'){
        //           //     history.push('/attended')
        //           // }else if(redirect == 'meeting-mastery'){
        //           //     history.push('/meeting-mastery')
        //           // }
        //           // }
        //           // setAlert({
        //           //   show:true,
        //           //   message:"Signup successful, Welcome to Meeting Science",
        //           //   title:'Error',
        //           //   errType:"success"
        //           // }) 
        //       }else if(code == 500){
        //         setLoading(false);  
        //         // setAlert({
        //         //   show:true,
        //         //   message:"We were unable to submit your data, maybe some fields are missing, please go through all the steps, fill the correct data and try again.",
        //         //   title:'Error',
        //         //   errType:"error"
        //         // })                   
        //       }
        //     })
        //     .catch((err) => {
        //       sendApiErrorpiError(500,err,Apistore.RegisterUrl)
        //       setLoading(false);
        //       setAlert({
        //         show:true,
        //         message:"Something went wrong while sending your data,please check your data and try again.",
        //         title:'Error',
        //         errType:"error"
        //       })                   
        //     })
        // }
  ///--------------------------------------------------------working end--------------------      


  useEffect(() => {
    if(isFormSubmitted){

    if(areEventsPulled && isMasteryCalculated  && isSignupComplete && isRecommendationCalculated){
      submitData();
    }
  }
  // else if(isFormSubmitted && step5Check() == 1){
    
  //   setTimeout(() => {
  //      history.push('/signup-page6');
  //   }, 4000);
   
  // }
    // else if(!areEventsPulled && !isMasteryCalculated && !isScoreCalculated && isSignupComplete){
    //   submitData();
    // }
  
  },[isFormSubmitted,areEventsPulled,isMasteryCalculated,isSignupComplete,isRecommendationCalculated])




        const handleSubmit = (e) => {
            e.preventDefault();
                  if(checkEmptyFields()){
                  
                        setShowError(emptyFields);
                  }else{    

                    if (data2.user_phone_number?.length > 2) {
                      handlePhoneCheck();

                      
                    }else{
                      setStorage('ST1Phone',JSON.stringify(data2));
                      setStorage('ST1',JSON.stringify(data));
                      setForSubmit(true)
            setLoading2(true)

                      setTimeout(() => {
                        dispatch(actions.fulfillUser(1));
                       }, 3000); 
                    }
                          // fetchSalaries(data.jobTitle);
                  }
        }

        const jobTitleHandler = (event) => {
            setData({
              ...data,
              jobTitle:event.label,
              job_title_array:event
            })
            setShowError({...showError,'jobTitle':false}) 
        }

        useEffect(() => {
          let newOptions = [];
          for(let i = 0; i< jobslist.length ; i++){
            newOptions.push({
              value:i,
              label:jobslist[i]
            })
          }

          setOptions(newOptions);
        
        },[jobslist])

useEffect(() => {

      setLoading(true)
      setTimeout(() => {setLoading(false);},1200) 

      const localData = getStorage('ST1');
      const localData2 = getStorage('ST1Phone');

        if(localData){
          setData(JSON.parse(localData));
        }
        if(localData2){
          setData2(JSON.parse(localData2));
        }
    
},[])





const handelPhoneKeyDown = (e)=>{
  if (e.key === 'Tab') {
    setToggelDisable(true)
    setTimeout(() => {
      setToggelDisable(false)
      
    }, 2000);
    
  }
  
}

  
const handlePhoneInput = (phone, country) => {
  setPhoneErrors('')
  const isValid = checkPhoneValidity(phone, country);
  setToggelDisable(false)
  setData2({
    ...data2,
    countryObj:country,
    user_phone_number: checkPlusSymbol(phone),
    country: country.name,

    user_phone_number_area_code: checkPlusSymbol(country.dialCode),
    isPhoneValid: false
    // user_timezone:null,
    // user_timezone_array:[],
  })

}

const dropdownIndicatorStyles = (base, state) => {
  let changes = {
    // all your override styles
    backgroundColor: '#F8F9FB',
    height:'55px',
    borderRadius:'3px'

  };
  return Object.assign(base, changes);
};

const handleAlertClose = () =>{
  setAlert({
    show:false
  })
};

const colourStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "1px solid rgb(204, 204, 204)" : "1px solid rgb(204, 204, 204)",
    boxShadow: state.isFocused ? " 0 0 0 1px #00ACBF" : "none",
    "&:hover": {
      border: " 1px solid rgb(179, 179, 179)",
      // boxShadow: " 0 0 0 1px #00ACBF"
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#00ACBF'
        : isFocused
        ? '#bdf0f6'
        : undefined,
      // color: isDisabled
      //   ? '#ccc'
      //   : isSelected
      //   ? chroma.contrast(color, 'white') > 2
      //     ? 'white'
      //     : 'black'
      //   : data.color,
      // cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? '#00ACBF'
            : 'none'
          : undefined,
      },
    }
  },
  // multiValue: (styles, { data }) => {
  //   const color = chroma(data.color);
  //   return {
  //     ...styles,
  //     backgroundColor: color.alpha(0.1).css(),
  //   };
  // },
  // multiValueLabel: (styles, { data }) => ({
  //   ...styles,
  //   color: data.color,
  // }),
  // multiValueRemove: (styles, { data }) => ({
  //   ...styles,
  //   color: data.color,
  //   ':hover': {
  //     backgroundColor: data.color,
  //     color: 'white',
  //   },
  // }),
};


    return(
    <>
        {<LayoutSplashScreen visible={loading}/>}
        <NoRefresh/>
        {alert.show && <AlertPopup show={alert.show} handleClose={handleAlertClose} title={alert.title} message={alert.message} errType={alert.errType}/>}


        {loading2 ? 
          <div className="animated-loader">
          <div className="loader-content text-center">
            <div className="loader-logo-rounded">
              <SVGComponent />
              <p>Hooray! Signup successful. Getting things ready for you.</p>
            </div>
          </div>
        </div>
              :
              null
          }


         <div className="auth-container row m-0">
          <div className="left_col col-xl-5 col-lg-6">
            <div className="auth_section">
              <div className="auth_logo"><a href="/"><img height='50px' src="/media/logos/meetingscience-logo-mobile1.png" alt="Meeting Science"/></a></div>
              <div className="auth_content">
                <div className="auth_steps">
                  <div className="active">span 1</div>
                  {/* <div>span 2</div>
                  <div>span 3</div>
                  <div>span 4</div> */}
                  {/* <div>span 5</div> */}
                  <div>span 6</div>
                </div>
                <div className="auth_title">Welcome to <span>MeetingScience!</span></div>
                <p>While we're importing your calendar, please tell us about yourself.</p> 
                <p className="form-group">First, start by typing in what best describes your occupation; please choose the closest match:</p>
                <div className="auth_form">
                 
                    <div className="form-group autocomplete-container" style={autocompleteStyles.container}>
                      <label>Job title <span className="required-astrik-sing" >*</span></label>
                      <Select className="react-select" styles={{dropdownIndicator: dropdownIndicatorStyles,...colourStyles}} classNamePrefix="react-select1" options={options} name="user_timezone" placeholder='Select' value={data.job_title_array} onChange={(e) => jobTitleHandler (e)}/>  
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{showError.jobTitle ? errors.jobTitle : null }</div>
                      </div>       
                    </div>

                    <div className="form-row">
                      <div className="col-md-6 form-group ">                        
                        <label>Your level <span className="required-astrik-sing" >*</span></label>                        
                        {/* <select className="form-control form-control-lg form-control-solid" name='jobLevel' onChange={inputHandler} value={jobLevel ? jobLevel : 'Select'}> */}
                        {/* <option disabled >Select</option> */}
                        {/* <select className="form-control form-control-lg form-control-solid" name='jobLevel' onChange={inputHandler} value={jobLevel}>

                        <option value='Select' disabled selected >Select</option> */}
                      <Select  isSearchable={false} styles={{dropdownIndicator: dropdownIndicatorStyles,...colourStyles}} classNamePrefix="react-select1" options={jobLevels} placeholder='Select' onChange={(e)=>{inputHandler(e,'jobLevel')}}  />  
                         
                          {/* {
                            jobLevels.map((i,j) => { return <option key={j} value={i}>{i}</option> }) 
                          }                           */}
                        {/* </select> */}
                        <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{showError.jobLevel ? errors.jobLevel : null }</div>
                      </div>
                      </div>                      
                      <div className="col-md-6 form-group ">                        
                        <label>Years Experience <span className="required-astrik-sing" >*</span></label>
                        {/* <select className="form-control form-control-lg form-control-solid" name='yearsExp' onChange={inputHandler} value={yearsExp ? yearsExp :'Select'}> */}
                        {/* <option disabled >Select</option> */}
                        {/* <select className="form-control form-control-lg form-control-solid" name='yearsExp' onChange={inputHandler} value={yearsExp ? yearsExp :'Select'}>
                        <option value='Select' disabled selected >Select</option> */}
                      <Select value={yearExpValue} isSearchable={false}  styles={{dropdownIndicator: dropdownIndicatorStyles,...colourStyles}} classNamePrefix="react-select1" options={exp_years} placeholder='Select' onChange={(e)=>{inputHandler(e,'yearsExp')}}  />  

                        {/* {
                            exp_years.map((i,j) => { return <option key={j} value={i}>{i}</option> }) 
                          }   */}
                        {/* </select> */}
                        <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{showError.yearsExp ? errors.yearsExp : null }</div>
                      </div>
                      </div>
                          <div className="form-row">
                  <div className="col-12">
                    <p>Please enter your mobile number here, starting with the country code. We will only send you messages via SMS if you opt-in to receive them.</p>
                  </div>
                  <div className="col-12 form-group custom_border_shadow_react_phone-input">
                    <label>PHONE NUMBER(optional)</label>
                    <PhoneInput
                      enableSearch="true"
                      country={'us'}
                      value={data2.user_phone_number}
                      placeholder="+1 _ ____ ____ "
                      onChange={(phone, country) => handlePhoneInput(phone, country)}
                      inputClass='country-code-input'
                      dropdownClass='dropdown-country-code'
                      disableDropdown={toggelDisable}
                      onKeyDown={handelPhoneKeyDown}
                    //onBlur={handlePhoneCheck}

                    />
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{phoneErrors ? phoneErrors : null}</div>
                    </div>
                  </div>

                </div>
                      
                      <div className="col-md-12 text-right form-group mt-6">
                      <button onFocus={() => setButtonHighlighted(true)} onBlur={() => setButtonHighlighted(false)} onClick={(e) => handleSubmit(e)} className={ highlighted ? "btn btn-next button-highlighted" : "btn btn-next"}>Next</button>
                      {/* <Link onFocus={() => setButtonHighlighted(true)} onBlur={() => setButtonHighlighted(false)} onClick={(e) => handleSubmit(e)} className={ highlighted ? "btn btn-next button-highlighted" : "btn btn-next"}>Next</Link> */}
                      </div>
                    </div>
                
                </div>
              </div> 
            </div>
          </div>
          <div className="right_col col-xl-7 col-lg-6">
            <div className="step_view">
              <h1>Bad meetings are the leading cause of employee burnout and turnover.</h1>
              <div className="step_view-img">
                <picture>
                  <source media="(min-width:1920px)" srcSet="/media/ms-media/step-1-img@2x.png" type="image/png"/>
                  <source media="(min-width:1200px)" srcSet="/media/ms-media/step-1-img@1x.png" type="image/png"/>
                  <img src="/media/ms-media/step-1-img.png" type="image/png" alt="Step-1"/>
                </picture>
              </div> 
            </div>                         
          </div>
        </div>
    </>
        
    )
}

export default Step1;

